import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { State, DeliveryServiceProvider, User } from '../store/reduxStoreState';

import { getDSPFromProps } from '../selectors/dspSelectors';
import { getManagersForDSP } from '../selectors/dspManagerSelectors';
import { getActiveDSPRsForDSP } from '../selectors/dsprSelectors';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getUsersByName } from '../selectors/userSelectors';

import { getAllDSPRsForDSP, createDSPR, getDSPR, updateDSPR } from '../actions/dsprActions';
import { getDSP, toggleDSPManagerActiveStatus, assignDSPManager } from "../actions/dspActions";
import { updateLoggedInUserInfo, getAllUsers } from "../actions/userActions";
import { interceptAutocompleteSelected } from "../actions/formHelperActions";
import { addProductToBrand } from '../actions/brandActions';

import {
    Card,
    CardContent,
    Switch,
    List,
    ListItem,
    ListItemSecondaryAction,
    Dialog,
    Button,
    DialogTitle,
} from "@material-ui/core";

import DSPForm from "./DSPForm";

import DSPRs from '../components/DSPRs';

interface DeliveryServiceProviderProps extends RouteComponentProps {
    dsp: DeliveryServiceProvider;
    dspManagers: any[];
    users: { value: number, text: string }[];
    loggedInUser: User;
    dsprs: { id: number, name: string, deliveryServiceProvider: number }[];
    getAllDSPRsForDSP: (dspId: number) => any;
    getDSP: (dspId: number) => any;
    toggleDSPManagerActiveStatus: (dspManagerId: number) => any;
    getAllUsers: () => any;
    assignDSPManager: (dspId: number, userId: number) => any;
    updateLoggedInUserInfo: () => any;
    interceptAutocompleteSelected: (id: any) => any;
    createDSPR: (name: string, userId: number, dspId: number, minimumOrderSize: number, numberOrdersPerRoute: number, centralLatitude: any, centralLongitude: any) => any
    getDSPR: (dsprId: string) => any;
    updateDSPR: (name: string, displayName: string, dspId: number, minimumOrderSize: number, numberOrdersPerRoute: number, centralLatitude: any, centralLongitude: any, id: number, imageFile) => any
    addProductToBrand: (productId: number, brandId: number) => any;
}

function DeliveryServiceProviderPage(props: DeliveryServiceProviderProps) {
    const { dsprs, dsp, getAllDSPRsForDSP, getDSP, getAllUsers, users, createDSPR, interceptAutocompleteSelected, getDSPR, updateDSPR } = props;
    const { dspId } = props.match.params as { dspId: number };

    const [showNewDSPManagerForm, setShowNewDSPManagerForm] = useState(false);
    const [allUsersLoaded, setAllUsersLoaded] = useState(true);
    const [selectedDSPRLoaded, setSelectedDSPRLoaded] = useState(true);
    const [selectedDSPRId, setSelectedDSPRId] = useState(undefined);
    const [selectedDSPR, setSelectedDSPR] = useState(undefined);

    function handleNewDSPManagerFormOpen() {
        setAllUsersLoaded(false);
        getAllUsers().then(() => {
            setAllUsersLoaded(true);
        }); //TODO: Logic so we don't get all users every time a modal is clicked
        setShowNewDSPManagerForm(true);
    }

    function handleNewDSPRFormClick() {
        setAllUsersLoaded(false);
        getAllUsers().then(() => {
            setAllUsersLoaded(true);
        }); //TODO: Logic so we don't get all users every time a modal is clicked
    }

    function handleNewDSPManagerFormClose() {
        setShowNewDSPManagerForm(false);
    }

    function handleNewDSPManagerSubmit(newDSPManagerValues) {
        props.assignDSPManager(dspId, newDSPManagerValues.userId)
            .then(() => props.updateLoggedInUserInfo());
        handleNewDSPManagerFormClose();
    };

    function isLoggedInUserSystemAdministrator() {
        return props.loggedInUser ? props.loggedInUser.systemAdministrator : false;
    }

    function isLoggedInUserThisDSPManager() {
        return props.loggedInUser && props.dspManagers && props.dspManagers
            .filter(dspManager => dspManager.user.id === props.loggedInUser.id)
            .length === 1;
    }

    function handleToggleDSPManager(dspManagerId) {
        props.toggleDSPManagerActiveStatus(dspManagerId);
    };

    function managerListElement(manager) {
        const toggler = isLoggedInUserSystemAdministrator() ?
            <Switch checked={manager.active} onChange={() => handleToggleDSPManager(manager.id)} /> :
            null;
        return <ListItem key={manager.id}>
            {manager.user.firstName} {manager.user.lastName} - {manager.user.email}
            <ListItemSecondaryAction>
                {toggler}
            </ListItemSecondaryAction>
        </ListItem>
    }

    function handleDSPRSettingsClick(dsprId, index) {
        setSelectedDSPR(dsprs[index]);
        setSelectedDSPRId(dsprId);
    }

    useEffect(() => {
        setSelectedDSPRLoaded(false);
        if (selectedDSPRId) getDSPR(selectedDSPRId)
            .then(response => {
                setSelectedDSPR(response.response.entities.DSPRs[selectedDSPRId]);
                setSelectedDSPRLoaded(true);
            });
    }, [getDSPR, selectedDSPRId]);

    // Fetch the full DSP information on mount, exactly once
    useEffect(() => {
        getDSP(dspId);
    }, [getDSP, getAllUsers, dspId]);

    // Fetch additional DSPR information if the number of DSPRs have changed
    useEffect(() => {
        getAllDSPRsForDSP(dspId);
    }, [getAllDSPRsForDSP, dspId]);


    return (
        <main className="delivery-service-provider">
            <h2>{dsp ? dsp.name : null}</h2>

            <section>
                <h4>DSPRs</h4>

                <DSPRs
                    dspId={dspId}
                    users={users}
                    dsprs={dsprs}
                    createDSPR={createDSPR}
                    handleAutoCompleteSelected={interceptAutocompleteSelected}
                    isDSPManager={isLoggedInUserThisDSPManager()}
                    allUsersLoaded={allUsersLoaded}
                    newDSPRCallback={handleNewDSPRFormClick}
                    dsprSettingsCallback={handleDSPRSettingsClick}
                    selectedDSPR={selectedDSPR}
                    selectedDSPRLoaded={selectedDSPRLoaded}
                    updateDSPR={updateDSPR}
                />
            </section>
            <section>
                <h4>Managers</h4>
                <Card className="card">
                    <CardContent>
                        <List>
                            {props.dspManagers ? props.dspManagers.map(manager => managerListElement(manager)) : null}
                        </List>
                        {(isLoggedInUserSystemAdministrator() || isLoggedInUserThisDSPManager()) && props.users &&
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="raised-button"
                                    onClick={handleNewDSPManagerFormOpen}
                                >
                                    Add DSP Manager
                                </Button>
                                <Dialog
                                    title="New Delivery Service Provider Manager"
                                    open={showNewDSPManagerForm}
                                    onClose={handleNewDSPManagerFormClose}
                                >
                                    <DialogTitle>Add A New DSP Manager</DialogTitle>
                                    <DSPForm editMode={true}
                                        users={props.users}
                                        onSubmit={handleNewDSPManagerSubmit}
                                        onCancel={handleNewDSPManagerFormClose}
                                        handleAutoCompleteSelected={interceptAutocompleteSelected}
                                        usersLoaded={allUsersLoaded}
                                    />
                                </Dialog>
                            </div>
                        }
                    </CardContent>
                </Card>
            </section>
        </main>
    )
}

const mapStateToProps = (state: State, ownProps: DeliveryServiceProviderProps) => {
    const { dspId } = ownProps.match.params as { dspId: number };
    return {
        loggedInUser: getLoggedInUser(state),
        dsp: getDSPFromProps(state, { dspId }),
        dspManagers: getManagersForDSP(state, { dspId }),
        users: getUsersByName(state),
        dsprs: getActiveDSPRsForDSP(state, { dspId }),
    };
}

const mapDispatchToProps = {
    getAllUsers,
    // createDSP, 
    createDSPR,
    updateLoggedInUserInfo,
    interceptAutocompleteSelected,
    assignDSPManager,
    toggleDSPManagerActiveStatus,
    getAllDSPRsForDSP,
    getDSP,
    getDSPR,
    updateDSPR,
    addProductToBrand,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryServiceProviderPage);