import { createSelector } from 'reselect';
import { DSPRDriverServiceAreaProfile, State } from "../store/reduxStoreState"
import { getActiveDSPRServiceAreasForDSPRWithVertices, getActiveDSPRServiceAreasForDSPRWithVerticesInMapForm } from './dsprDriverServiceAreaSelectors';

export const getDSPRDriverServiceAreaProfilesFromProps = (state:State) => state.api.entities.dsprDriverServiceAreaProfiles

export const getActiveDSPRDriverServiceAreaProfilesForDSPRFromProps = (state:State, props) => Object.values(state.api.entities.dsprDriverServiceAreaProfiles).filter((serviceAreaProfile:DSPRDriverServiceAreaProfile) => serviceAreaProfile.active && (serviceAreaProfile.dspr === parseInt(props.dsprId)))

export const getActiveDSPRDriverServiceAreaProfilesForDSPRWithActiveServiceAreasWithVertices = createSelector(
    [getActiveDSPRDriverServiceAreaProfilesForDSPRFromProps, getActiveDSPRServiceAreasForDSPRWithVerticesInMapForm], (profiles, activeServiceAreas) => {
        const profileMap = {}
        if (profiles) {
            if (activeServiceAreas) {
                profiles.forEach(profile => {
                    profileMap[profile.id] = { ...profile, dsprDriverServiceAreas: profile.dsprDriverServiceAreas.map(serviceAreaId=> activeServiceAreas[serviceAreaId])}
                })
                return profileMap;
            } else {
                profiles.forEach(profile => {
                    profileMap[profile.id] = profile
                });
                return profileMap;
            }
        } else {
            return undefined;
        }
    }
)

export const getActiveDSPRDriverServiceAreaProfilesForDSPRWithActiveServiceAreasForDSPRDriverServiceAreaProfileWithVertices = createSelector(
    [getActiveDSPRDriverServiceAreaProfilesForDSPRFromProps, getActiveDSPRServiceAreasForDSPRWithVertices], (profiles, activeServiceAreas) => {
        const profileMap = {}
        if (profiles) {
            if (activeServiceAreas) {
                profiles.forEach(profile => {
                    profileMap[profile.id] = { ...profile, dsprDriverServiceAreas: activeServiceAreas.filter((serviceArea)=> serviceArea.dsprDriverServiceAreaProfile === profile.id)}
                })
                return profileMap;
            } else {
                profiles.forEach(profile => {
                    profileMap[profile.id] = profile
                });
                return profileMap;
            }
        } else {
            return undefined;
        }
    }
)