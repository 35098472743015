import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State, Brand, BrandAnalytics, DSPR, DeliveryServiceProvider } from '../store/reduxStoreState';
import { getAllActiveBrandsForDSPFromProps } from "../selectors/brandSelectors";
import { getDSPIdForDSPRFromProps, getDSPRFromProps } from "../selectors/dsprSelectors";
import {
    getAllBrandsForDSP,
    getBrandDSPAnalytics,
    getBrandDSPRAnalytics,
    rebuildDSPRBrandAnalytics
} from "../actions/brandActions";
import {
    getMysqlDateString1SecondBeforeEndOfDayFromTimestamp,
    getMySQLDateString1SecondBehindStartOfDayFromTimestamp,
    parseDate
} from "../util/util";
import {
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItem,
    MenuItem,
    TableCell,
    TableRow
} from "@material-ui/core";
import AnalyticsDisplay from "../components/AnalyticsDisplay";
import List from "@material-ui/core/List";
import ChartistGraph from "react-chartist";
import { straightLinesChart } from '../variables/charts';
import AnalyticsUtil from "../util/AnalyticsUtil";
import { getDSPFromProps } from "../selectors/dspSelectors";
import AnalyticsReportForm from "./AnalyticsReportForm";
import useBrandAnalyticsDialog from "../util/hooks/useBrandAnalyticsDialog";
import {API_ROOT} from "../middleware/api";
import {LOCAL_STORAGE_ACCESS_TOKEN_KEY} from "../actions/oauthActions";


function BrandAnalyticsContainer() {
    const {formatDateForTable, formatDateForChart} = AnalyticsUtil;
    const FILTER_TYPE = {
        DAY: 'days',
        WEEK: 'weeks',
        MONTH: 'months',
        QUARTER: 'quarters',
        YEAR: 'years'
    };

    const dispatch = useDispatch();

    let {dsprId, dspId} = useParams<{ dsprId: string, dspId: string }>();
    const dspIdFromSelector = useSelector<State, number>(state => getDSPIdForDSPRFromProps(state, {dsprId}), shallowEqual);
    dspId = dsprId ? dspIdFromSelector.toString() : dspId;
    const dspBrands = useSelector<State, Brand[]>(state => getAllActiveBrandsForDSPFromProps(state, {dspId}), shallowEqual);
    const dspr = useSelector<State, DSPR>(state => dsprId && getDSPRFromProps(state, {dsprId: parseInt(dsprId)}), shallowEqual);
    const dsp = useSelector<State, DeliveryServiceProvider>(state => dspId && getDSPFromProps(state, {dspId: parseInt(dspId)}));

    const [isLoading, setIsLoading] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState<number | string>('');
    const [currentFilterType, setCurrentFilterType] = useState(FILTER_TYPE.DAY);
    const [showRevenueDetail, setShowRevenueDetail] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [sortedAnalytics, setSortedAnalytics] = useState(null);
    const [formattedData, setFormattedData] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [tableBody, setTableBody] = useState([]);
    // const {
    //     showCustomAnalyticsReportDialog,
    //     closeCustomAnalyticsReportDialog,
    //     handleShowCustomAnalyticsForm,
    //     handleCustomAnalyticsReport,
    //     handleBrandAnalyticsReport
    // } = useBrandAnalyticsDialog();
    const [showCustomAnalyticsReportDialog, setShowCustomAnalyticsReportDialog] = useState(false);
    const closeCustomAnalyticsReportDialog = () => {
        setShowCustomAnalyticsReportDialog(false);
    }
    const handleShowCustomAnalyticsForm = () => {
        setShowCustomAnalyticsReportDialog(true);
    }
    const handleCustomAnalyticsReport = (formValues) => {
        let rows = undefined;
        let timeframe = undefined;
        let beginningTimestamp = undefined
        let endTimestamp = undefined;

        Object.entries(formValues);

        Object.entries(formValues).map(([key, value]) => {
            if(value) {
                switch (key) {
                    case "endDayTimestamp":
                        endTimestamp=getMysqlDateString1SecondBeforeEndOfDayFromTimestamp(value);
                        break;
                    case "beginDayTimestamp":
                        beginningTimestamp=getMySQLDateString1SecondBehindStartOfDayFromTimestamp(value)
                        break;
                    case "timeframe":
                        timeframe=value;
                        break;
                    default:
                        if(rows) rows += "," + key;
                        else rows = key;
                        break;
                }
            }
        });

        let url = API_ROOT +
            'brand' +
            `/analytics/download_analytics_report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}` +
            `&brand_id=${selectedBrandId}`;
        // let url = API_ROOT + (dspId ? `delivery_service_provider` : `dspr`)
        //     + `/analytics/download_analytics_report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}&begin_day_timestamp=${encodeURIComponent(beginningTimestamp)}&end_day_timestamp=${encodeURIComponent(endTimestamp)}`;
        url += `&begin_day_timestamp=${encodeURIComponent(beginningTimestamp)}&end_day_timestamp=${encodeURIComponent(endTimestamp)}`;
        if(rows != undefined) url += "&rows=" + encodeURIComponent(rows);
        // if(dspId) url += "&dsp_id=" + dspId;
        // else if(dsprId) url += "&dspr_id=" + dsprId;
        if(timeframe) url+="&timeframe=" + timeframe;
        window.open(url + "&_blank");
        closeCustomAnalyticsReportDialog();
    }

    /**Retrieve all brands for a given DSP from backend whenever dspId changes */
    useEffect(() => {
        setIsLoading(true);
        dispatch<any>(getAllBrandsForDSP(parseInt(dspId)))
            .then(() => setIsLoading(false));
    }, [dispatch, dspId]);

    /**Request Brand Analytics for a given brandId */
    const handleRequestBrandAnalytics = (brandId = selectedBrandId) => {
        if (dsprId) {
            setIsLoading(true);
            dispatch<any>(getBrandDSPRAnalytics(brandId, parseInt(dsprId)))
                .then(() => setIsLoading(false))
        } else if (dspId) {
            setIsLoading(true);
            dispatch<any>(getBrandDSPAnalytics(brandId))
                .then(() => setIsLoading(false));
        }
    }

    /**Recreates analytic history based upon current context
     * -> useful to run after staging is updated
     * -> to run, uncomment out Rebuild button in AnalyticsDisplay.
     * ->AFTER RUNNING, COMMENT BUTTON OUT :) */
    const handleRebuildDSPRBrandAnalytics = (brandId) => {
        setIsLoading(true);
        dispatch<any>(rebuildDSPRBrandAnalytics(brandId))
            .then(() => setIsLoading(false))
    }

    /**Formats data for analytics charts using the library Chartist */
    const formatAnalyticsForCharts = useCallback((analytics: BrandAnalytics[]) => {
        const formattedData = {
            date: [],
            totalRevenue: [],
            flowerRevenue: [],
            edibleRevenue: [],
            concentrateRevenue: [],
            vaporizerRevenue: [],
            percentageNonFlowerUnits: null,
            percentageEighthsFlowerUnits: null,
            nonFlowerUnits: 0,
            flowerUnits: 0,
            totalRevenueChartData: null,
            categoryRevenueChartData: null,
            nonFlowerToFlowerBreakdownPie: null,
        };
        if (analytics && analytics.length > 0) {
            const mostRecent7AnalyticsEntries: BrandAnalytics[] = analytics.slice(0, 7).reverse();

            //Formatting Data for graphs
            if (mostRecent7AnalyticsEntries.length > 0) {
                mostRecent7AnalyticsEntries.forEach((analyticsEntry: BrandAnalytics, idx: number) => {
                    //data for line graphs
                    formattedData.date[idx] = parseDate(analyticsEntry.beginDate).getDate();
                    formattedData.totalRevenue[idx] = analyticsEntry.revenuesTotal;
                    formattedData.flowerRevenue[idx] = analyticsEntry.flowerRevenue;
                    formattedData.edibleRevenue[idx] = analyticsEntry.edibleRevenue;
                    formattedData.vaporizerRevenue[idx] = analyticsEntry.vaporizerRevenue;
                    formattedData.concentrateRevenue[idx] = analyticsEntry.concentrateRevenue;

                    formattedData.date[idx] = formatDateForChart(analyticsEntry, currentFilterType);

                    //data for pie chart
                    formattedData.nonFlowerUnits += analyticsEntry.numberOfNonFlowerUnits;
                    formattedData.flowerUnits += analyticsEntry.numberOfEighthsForFlowerUnits;

                });
                const sumFlowerAndNonFlowerUnits = formattedData.nonFlowerUnits + formattedData.flowerUnits;
                formattedData.percentageNonFlowerUnits = Math.round((formattedData.nonFlowerUnits / sumFlowerAndNonFlowerUnits) * 100);
                formattedData.percentageEighthsFlowerUnits = Math.round((formattedData.flowerUnits / sumFlowerAndNonFlowerUnits) * 100);
            }

            //Formatted Chart Data
            formattedData.totalRevenueChartData = {
                labels: formattedData.date,
                series: [formattedData.totalRevenue]
            };

            formattedData.categoryRevenueChartData = {
                labels: formattedData.date,
                series: [formattedData.flowerRevenue, formattedData.edibleRevenue, formattedData.vaporizerRevenue, formattedData.concentrateRevenue]
            };

            formattedData.nonFlowerToFlowerBreakdownPie = {
                labels: [`${formattedData.percentageEighthsFlowerUnits}%`, `${formattedData.percentageNonFlowerUnits}%`],
                series: [formattedData.percentageEighthsFlowerUnits, formattedData.percentageNonFlowerUnits]
            };

        }
        setFormattedData(formattedData);
    }, [currentFilterType, formatDateForChart])

    /**Sort daily, weekly, monthly, quarterly, or yearly analytics data by date*/
    const sortAnalytics = useCallback(brand => {
        let sortedAnalytics = null;
        if (dsprId && brand && brand.dsprAnalytics && brand.dsprAnalytics[dsprId]) {
            sortedAnalytics = brand.dsprAnalytics[dsprId][currentFilterType]
                .sort((a: BrandAnalytics, b: BrandAnalytics) => parseDate(b.beginDate).valueOf() - parseDate(a.beginDate).valueOf())
                .slice(0, 60);
            setSortedAnalytics(sortedAnalytics);
        } else if (!dsprId && brand && brand.dspAnalytics && brand.dspAnalytics[dspId]) {
            sortedAnalytics = brand.dspAnalytics[dspId][currentFilterType]
                .sort((a: BrandAnalytics, b: BrandAnalytics) => parseDate(b.beginDate).valueOf() - parseDate(a.beginDate).valueOf())
                .slice(0, 60);
            setSortedAnalytics(sortedAnalytics)
        }
    }, [currentFilterType, dsprId, dspId]);

    /** Set new selected brandId and request analytics for that brandId */
    const handleBrandChange = (event: React.ChangeEvent<{ name?: string, value: number }>) => {
        handleRequestBrandAnalytics(event.target.value);
        setSelectedBrandId(event.target.value);
    };

    /**Update state for the date range used to filter analytics */
    const handleFilterChange = (event: React.ChangeEvent<{ name?: string, value: string }>) => {
        setCurrentFilterType(event.target.value);
    };

    /**Updates selectedBrand state with the selected brand object we just received analytics data for*/
    useEffect(() => {
        if (dspBrands && selectedBrandId) {
            setSelectedBrand(dspBrands.find(brand => brand.id === selectedBrandId));
        }
    }, [selectedBrandId, currentFilterType, dspBrands])

    /**Render brand menu items for the brand select form
     * Only active brands are rendered. Brands are rendered in alphabetical order.
     * */
    const brandMenuItems = dspBrands &&
        dspBrands
            .filter(brand => brand.isActive === true)
            .sort((a, b) => a.name.localeCompare(b.name, 'en-US', {ignorePunctuation: true}))
            .map(brand => {
                return <MenuItem
                    button
                    key={brand.id}
                    value={brand.id}
                >
                    {brand.name}
                </MenuItem>
            });

    /**Sorts and formats analytics data for a brand object whenever selectedBrand state changes */
    useEffect(() => {
        if (selectedBrand) {
            sortAnalytics(selectedBrand)
        }
    }, [selectedBrand, currentFilterType, sortAnalytics])

    /**Format dates for use in tables*/
    const getDatesForAnalytics = useCallback((analytics: BrandAnalytics) => {
        if (!analytics) return '';
        return formatDateForTable(analytics, currentFilterType);
    }, [currentFilterType, formatDateForTable]);

    /**Renders table body for selected analytics */
    const renderAnalytics = useCallback((analytics: BrandAnalytics) => {
        const {amountOfDiscounts, revenuesTotal} = analytics;
        const discountsPercent = amountOfDiscounts * 100 / (amountOfDiscounts + revenuesTotal);
        const dateSpan = getDatesForAnalytics(analytics);

        return <TableRow hover key={analytics.beginDate} onClick={() => {
            setShowRevenueDetail(analytics)
        }} className="analytics-row">
            <TableCell>{dateSpan}</TableCell>
            <TableCell>${analytics.revenuesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.grossProfit.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{analytics.grossProfitMargin.toLocaleString('en-US', {maximumFractionDigits: 0})}%</TableCell>
            <TableCell>${analytics.flowerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.edibleRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.concentrateRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.vaporizerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.amountOfDiscounts.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{discountsPercent.toLocaleString('en-US', {maximumFractionDigits: 0})}%</TableCell>
            <TableCell>{analytics.numberOfNonFlowerUnits.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{analytics.numberOfEighthsForFlowerUnits.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
        </TableRow>
    }, [getDatesForAnalytics]);

    const tableHeaderNames = ['Period', 'Total Revenues', 'Gross Profit', 'Gross Profit Margin', 'Flower Revenue', 'Edible Revenue',
        'Concentrate Revenue', 'Vaporizer Revenue', 'Discounts', 'Discounts (% Rev)',
        'Number of Non-Flower Units', 'Number of Eighths of Flower Units'];

    /**Submit sorted analytics data to be rendered in a table */
    const tableBodyAnalytics = useCallback(() => {
        return sortedAnalytics.map((analytics: BrandAnalytics) => renderAnalytics(analytics));
    }, [sortedAnalytics, renderAnalytics]);

    /**Update tableBody state to be the most recently rendered table body & format data for charts*/
    useEffect(() => {
        if (sortedAnalytics) {
            setTableBody(tableBodyAnalytics());
            formatAnalyticsForCharts(sortedAnalytics);
        }
    }, [sortedAnalytics, tableBodyAnalytics, formatAnalyticsForCharts]);

    /**Renders popup window to display analytics details for a particular date */
    const renderAnalyticsDetailDialog = useCallback(() => {
        return (
            <Dialog
                open={!!showRevenueDetail}
                onClose={() => {
                    setShowRevenueDetail(null);
                }}>
                {showRevenueDetail ? <Fragment>
                    <DialogTitle>Period Beginning: {getDatesForAnalytics(showRevenueDetail)}</DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem>Total Revenue:
                                &nbsp;${showRevenueDetail.revenuesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Flowers:
                                &nbsp;${showRevenueDetail.flowerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Edibles:
                                &nbsp;${showRevenueDetail.edibleRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Concentrates:
                                &nbsp;${showRevenueDetail.concentrateRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Vaporizers:
                                &nbsp;${showRevenueDetail.vaporizerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Gross Profit:
                                &nbsp;${showRevenueDetail.grossProfit.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Gross Profit Margin:
                                &nbsp;{showRevenueDetail.grossProfitMargin.toLocaleString('en-US', {maximumFractionDigits: 0})}%</ListItem>
                        </List>
                    </DialogContent>
                </Fragment> : <p>Analytics Unavailable</p>}
            </Dialog>
        )
    }, [showRevenueDetail, getDatesForAnalytics])

    /**Render Analytics Charts*/
    useEffect(() => {
        if (formattedData && formattedData.totalRevenueChartData && formattedData.totalRevenueChartData.labels && formattedData.totalRevenueChartData.labels.length > 0) {
            setChartData([
                {
                    title: `Total Revenue in ${currentFilterType.charAt(0).toUpperCase() + currentFilterType.slice(1)}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Line'}
                        data={formattedData.totalRevenueChartData}
                        listener={straightLinesChart.animation}/>,
                    chartLegend: <div className={'legend-container'}>
                        <div className={'legend'}>
                            <span className={'legend-primary'}/><p> - Revenue</p>
                        </div>
                    </div>
                },
                {
                    title: `Revenue by Product Category in ${currentFilterType.charAt(0).toUpperCase() + currentFilterType.slice(1)}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Line'}
                        data={formattedData.categoryRevenueChartData}
                        listener={straightLinesChart.animation}/>,
                    chartLegend: <div className="legend-container">
                        <div className='legend'>
                            <span className="legend-primary"/><p> - Flower</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-secondary"/><p> - Edible</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-tertiary"/><p> - Vaporizer</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-quaternary"/><p> - Concentrate</p>
                        </div>
                    </div>
                },
                {
                    title: `Breakdown of Non-Flower to Flower Units Sold by Past 7 ${currentFilterType.charAt(0).toUpperCase() + currentFilterType.slice(1)}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Pie'}
                        data={formattedData.nonFlowerToFlowerBreakdownPie}/>,
                    chartLegend: <div className="legend-container">
                        <div className='legend'>
                            <span className="legend-primary"/><p> - Flower</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-secondary"/><p> - Non-Flower</p>
                        </div>
                    </div>
                }
            ])
        } else {
            setChartData([]);
        }
    }, [formattedData, currentFilterType])

    const renderTitle = () => {
        if (dsprId && selectedBrand && selectedBrand.name) {
            return <h2>{dspr.name} Analytics for {selectedBrand.name}</h2>
        }

        if (dsprId) {
            return <h2>{dspr.name} Brand Analytics</h2>
        }

        if (dspId && selectedBrand && selectedBrand.name) {
            return <h2>{dsp.name} Analytics for {selectedBrand.name}</h2>
        }

        if (dspId) {
            return <h2>{dsp.name} Brand Analytics</h2>
        }

        return <h2>Brand Analytics</h2>
    }

    return (
        <main className={'analytics-tab'}>

            {renderTitle()}

            <AnalyticsDisplay
                tableHeaderNames={tableHeaderNames}
                tableBody={tableBody}
                chartData={chartData}
                isLoading={isLoading}
                handleReloadAnalytics={handleRequestBrandAnalytics}
                filterTypes={FILTER_TYPE}
                currentFilterType={currentFilterType}
                handleFilterChange={handleFilterChange}
                renderAnalyticsDetailDialog={renderAnalyticsDetailDialog}
                brands={brandMenuItems}
                currentBrandId={selectedBrandId}
                handleBrandChange={handleBrandChange}
                rebuildAnalytics={handleRebuildDSPRBrandAnalytics}
                handleShowCustomAnalyticsForm={handleShowCustomAnalyticsForm}
            />
            <Dialog
                open={showCustomAnalyticsReportDialog}
                onClose={() => closeCustomAnalyticsReportDialog()}>
                <CardHeader title="Analytics Report" />
                <CardContent>
                    <AnalyticsReportForm onSubmit={handleCustomAnalyticsReport} initialValues={{
                        begin_date: true,
                        cash_collected: true,
                        total_revenues: true,
                        delivery_fees: true,
                        product_revenues: true,
                        taxes: true,
                        discounts: true,
                        number_completed_orders: true,
                        gross_profit: true,
                        gross_profit_margin: true,
                        timeframe: "day"
                    }}/>
                </CardContent>
            </Dialog>
        </main>
    )
}

export default BrandAnalyticsContainer