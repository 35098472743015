import React, { useState } from "react";
import { Checkbox, FormControlLabel, Button, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import VirtualizedTable from './VirtualizedTable';
import { BatchNumber, DspProduct, MetrcTag } from '../store/reduxStoreState';
import './DSPRMetrcTagProductLookupVirtualizedTableStyles.scss';

interface DSPRMetrcTagProductLookupFormVirtualizedTableProps {
    products: { [key: number]: DspProduct };
    metrcProductAssociations?: MetrcTag[];
    batchNumberProductAssociations?: BatchNumber[];
    handleClickModify: (metrcProductAssociation: MetrcTag) => void;
    tableHeight?: number;
    updateModifyButtonText: boolean;
    metrcAssociationRowToChange: string;
}

const DSPRMetrcTagProductLookupFormVirtualizedTable = ({
    products,
    metrcProductAssociations,
    batchNumberProductAssociations,
    tableHeight,
    handleClickModify,
    updateModifyButtonText,
    metrcAssociationRowToChange
}: DSPRMetrcTagProductLookupFormVirtualizedTableProps) => {

    const [showActiveOnly, setShowActiveOnly] = useState<boolean>(false);

    const generateMetrcProductLookupRows = (metrcProductRow: MetrcTag, style: React.CSSProperties) => {
        const productName = products[metrcProductRow.productId].name;

        return (
            <div
                style={style}
                className={'metrc-product-lookup-row'}
                key={metrcProductRow.id}
            >
                <span className={'row-tag-container'}>
                    {metrcProductRow.metrcTag}
                    <Button 
                        color='primary' 
                        disabled={(metrcProductRow.quantityOfEighths !== metrcProductRow.initialQuantityOfEighths) || (updateModifyButtonText && metrcAssociationRowToChange === metrcProductRow.metrcTag)}
                        onClick={
                            () => handleClickModify(metrcProductRow)}
                        disableRipple={true}
                        className='modify-button'
                    >
                        {(updateModifyButtonText && metrcAssociationRowToChange === metrcProductRow.metrcTag)
                            ? 'Modifying'
                            : 'Modify'
                        }
                    </Button>
                </span>
                <span>{productName}</span>
                <span>{metrcProductRow.initialQuantityOfEighths}</span>
                <span>{metrcProductRow.quantityOfEighths}</span>
                <span>{`${metrcProductRow.isActive}`}</span>
            </div>
        )
    }

    const generateBatchNumberProductLookupRows = (batchProductRow: BatchNumber, style: React.CSSProperties) => {
        const productName = products[batchProductRow.product].name;
        const productId = products[batchProductRow.product].id;
        return (
            <div
                style={{...style, cursor: 'pointer'}}
                className={'metrc-product-lookup-row'}
                onClick={() => {
                    const win = window.open(
                        `compliance/${btoa(batchProductRow.batchNumber)}/${productId}/order-history`, '_blank');
                    win.focus()
                }}
                key={batchProductRow.id}
            >
                <span className={'row-tag-container'}>
                    {batchProductRow.batchNumber}
                </span>
                <span>{productName}</span>
                <span>{batchProductRow.initialQuantity}</span>
                <span>{batchProductRow.quantity}</span>
                <span>{`${batchProductRow.isActive}`}</span>
            </div>
        )
    }

    const renderBatchNumberProductLookupRows = ({index, style}: { index: number, style: React.CSSProperties }) => {
        if (!batchNumberProductAssociations || batchNumberProductAssociations.length === 0) return null;
        return generateBatchNumberProductLookupRows(batchNumberProductAssociations[index], style);
    }

    const renderMetrcProductLookupRows = ({index, style}: { index: number, style: React.CSSProperties }) => {
        if (!metrcProductAssociations || metrcProductAssociations.length === 0) return null;
        return generateMetrcProductLookupRows(metrcProductAssociations[index], style);
    }

    return (
        <div className={'metrc-tag-product-lookup-form-virtualized-table'}>
            <FormControlLabel
                control={<Checkbox
                            checked={showActiveOnly}
                            onChange={() => setShowActiveOnly(!showActiveOnly)}
                            color='primary'
                        />}
                label={'ShowActiveOnly'}
            />
            {metrcProductAssociations && metrcProductAssociations.length > 0 && <VirtualizedTable
                    tableClasses={'metrc-product-lookup-table'}
                    headerClasses={'metrc-product-lookup-headers'}
                    tableContentClasses={'metrc-product-lookup-content'}
                    itemCount={metrcProductAssociations.length}
                    maxHeight={tableHeight ? tableHeight : 400}
                    header={(
                        <header>
                            <span>Metrc Tag</span>
                            <span>Product</span>
                            <span>Initial Quantity</span>
                            <span>
                                <Tooltip title={'To change quatity, use the Inventory Transaction Correction Form'}
                                    placement='top'
                                    arrow>
                                    <span className='quantity-header'>
                                    Quantity <HelpOutlineIcon />
                                    </span>
                                </Tooltip>
                            </span>
                            <span>Active</span>
                        </header>
                    )}
                    renderItems={renderMetrcProductLookupRows}
            />}
            {batchNumberProductAssociations && batchNumberProductAssociations.length > 0 && <VirtualizedTable
                    tableClasses={'metrc-product-lookup-table'}
                    headerClasses={'metrc-product-lookup-headers'}
                    tableContentClasses={'metrc-product-lookup-content'}
                    itemCount={batchNumberProductAssociations.length}
                    maxHeight={tableHeight ? tableHeight : 400}
                    header={(
                        <header>
                            <span>BatchNumber</span>
                            <span>Product</span>
                            <span>Initial Quantity</span>
                            <span>
                                <Tooltip title={'To change quatity, use the Inventory Transaction Correction Form'}
                                    placement='top'
                                    arrow>
                                    <span className='quantity-header'>
                                    Quantity <HelpOutlineIcon />
                                    </span>
                                </Tooltip>
                            </span>
                            <span>Active</span>
                        </header>
                    )}
                    renderItems={renderBatchNumberProductLookupRows}
            />}
        </div>
    )
}

export default DSPRMetrcTagProductLookupFormVirtualizedTable;