import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { getDSPR } from '../actions/dsprActions';
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import { changeZipCodeMin, createDSPRZipCode, getAllZipCodesForDSPR, toggleDSPRZipCodeActiveStatus } from "../actions/zipCodeActions";
import { getManagedDSPIdsForLoggedInUser } from "../selectors/dspManagerSelectors";
import { getZipCodesForDSPR } from "../selectors/dsprZipCodeSelector";
import { State, DsprZipCode, DSPR } from '../store/reduxStoreState';

import DSPRZipCodes from "../components/DSPRZipCodes";

const DSPRZipCodesContainer: React.FC<{}> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { dsprId } = useParams<{dsprId}>();
    const dispatch = useDispatch();

    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, {dsprId}), shallowEqual);
    const dsprZipCodes = useSelector<State, DsprZipCode[]>(state => getZipCodesForDSPR(state, { dsprId }), shallowEqual);
    const loggedInUserManagedDSPs = useSelector<State, number[]>(getManagedDSPIdsForLoggedInUser);

    useEffect(() => {
        setIsLoading(true);
        dispatch<any>(getDSPR(dsprId))
            .then(() => dispatch(getAllZipCodesForDSPR(dsprId)))
            .then(() => setIsLoading(false));
    }, [dispatch, dsprId]);

    const isLoggedInUserThisDSPManager = () => dspr ? loggedInUserManagedDSPs.includes(dspr.deliveryServiceProvider) : false;

    const handleToggleDSPRZipCode = (zipCodeId: number) => {
        return dispatch(toggleDSPRZipCodeActiveStatus(zipCodeId));
    };

    const handleSubmitZipCodeForm = (newZipcodeValues: { zipCode: string }) => {
        return dispatch<any>(createDSPRZipCode(dsprId, newZipcodeValues.zipCode))
    };

    return <main className="zipcode-tab">
        <h2>Zip Codes</h2>
        {isLoading ? <CircularProgress /> :
            <DSPRZipCodes
                dsprZipCodes={dsprZipCodes}
                loggedInUserIsDSPManager={isLoggedInUserThisDSPManager()}
                handleSubmitZipCodeForm={handleSubmitZipCodeForm}
                handleToggleDSPRZipCode={handleToggleDSPRZipCode}
                dspr={dspr}
                handleZipCodeMinimumChange={(id, min) => dispatch(changeZipCodeMin(id, min))}
            />}
    </main>
}

export default DSPRZipCodesContainer;
