import React from 'react';
import { Card, Grid } from '@material-ui/core'

import CardHeader from './Card/CardHeader';
import CardIcon from './Card/CardIcon';
import CardFooter from './Card/CardFooter';

import './StatCardStyles.scss';

type Color = "primary" | "info" | "success" | "warning" | "danger" | "rose";

interface StatCardProps extends Partial<React.HTMLAttributes<HTMLDivElement>> {
    grid?: boolean;
    mainIcon: JSX.Element;
    mainText: string;
    mainValue: number | string | JSX.Element;
    color: Color;
    footerIcon: JSX.Element;
    footerText: string | JSX.Element;
    className?: string;
}

const StatCard: React.FC<StatCardProps> = props => {
    const { mainIcon, mainText, mainValue, color, footerIcon, footerText, grid, className, ...rest } = props;
    const clickableCardStyle: React.CSSProperties = rest.onClick && { cursor: 'pointer' };

    return (
        grid ?
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={className ? className : "stat-card-grid"} {...rest} style={clickableCardStyle}>
                    <CardHeader color={color} stats icon className='header'>
                        <CardIcon color={color}>
                            {mainIcon}
                        </CardIcon>
                        <p>{mainText}</p>
                        {mainValue instanceof Object ? mainValue : <h3>{mainValue}</h3>}
                    </CardHeader>
                    <CardFooter stats>
                        <div>
                            {footerIcon}
                            {footerText}
                        </div>
                    </CardFooter>
                </Card>
            </Grid> :
            <Card className={className ? className : "stat-card"} {...rest} style={clickableCardStyle}>
                <CardHeader color={color} stats icon>
                    <CardIcon color={color}>
                        {mainIcon}
                    </CardIcon>
                    <p>{mainText}</p>
                    {mainValue instanceof Object ? mainValue : <h3>{mainValue}</h3>}
                </CardHeader>
                <CardFooter stats>
                    <div>
                        {footerIcon}
                        {footerText}
                    </div>
                </CardFooter>
            </Card>
    )
}

export default React.memo(StatCard);