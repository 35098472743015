import React, {Fragment, useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {orderStatus} from "../../middleware/api";
import {
    CANCEL_ORDER_SUCCESS,
    cancelOrder,
    IN_ROUTE_ORDER_FAILURE,
    IN_ROUTE_ORDER_SUCCESS,
    inRouteOrder
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";

export const CancelOrderButtons:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack,
        cancelOrderHandler,
        isManagerForDSPR,
        isDriverForOrder
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const dispatch = useDispatch();

    const handleInRouteOrder = () => {
        setDisableButtonCallBack(true)
        dispatch<any>(inRouteOrder(orderId)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === IN_ROUTE_ORDER_SUCCESS) {
                if(closeModal) closeModal();
            }else if(response.type === IN_ROUTE_ORDER_FAILURE){
                setExtraOrderFunctionalityErrorCallBack(response.error);
            }
        })
    }
    const handleCancelOrder = () => {
        setDisableButtonCallBack(true)
        if(cancelOrderHandler) cancelOrderHandler(orderId).then(response => {
            setDisableButtonCallBack(false)
        })
        else{
            setDisableButtonCallBack(true)
            dispatch<any>(cancelOrder(orderId)).then(response => {
                setDisableButtonCallBack(false)
                if (response.type === CANCEL_ORDER_SUCCESS) {
                    if(closeModal) closeModal();
                }
            })
        }
    }
    useEffect(() => {
        if(isManagerForDSPR || isDriverForOrder) return;

        if(order.orderStatus === orderStatus.received) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.allotmentConfirmed) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.cancelledInsufficientAllotment) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.packaged) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.queued) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.inProcess) setShowBasedOnOrderStatus(true);
        else if(order.orderStatus === orderStatus.inRoute) setShowBasedOnOrderStatus(true);
            }, []);

    return showBasedOnOrderStatus && <Fragment>
        <div style={{width: "100%", marginTop: "8px"}}>
            <Button variant="contained" color="secondary" disabled={disableExtraButtons} onClick={() => handleCancelOrder()}>
                Cancel Order
            </Button>
        </div>
        </Fragment>
}