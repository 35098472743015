import React, {Fragment} from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, DialogActions, DialogContent } from '@material-ui/core';
import AutoComplete from '../components/Autocomplete';
import { getImage, API_HOST } from "../middleware/api";
import Dropzone from 'react-dropzone';
import './DSPFormStyles.scss';

const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field) => {
    const { input: { value: files, onChange }, meta: { touched, error } } = field;
    const imgsrc = files !== '' ? typeof files === 'string' ? API_HOST + files : URL.createObjectURL(files[0]) : undefined;
    return (
        <Fragment>
            <Dropzone
                onDrop={filesToUpload => onChange(filesToUpload)}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        {imgsrc && <img src={imgsrc} alt="Preview" style={{ objectFit: 'contain' }} width="300px" height="200px" />}
                        <div {...getRootProps()} className="drop-zone">
                            <Fragment>
                                <input {...getInputProps()} />
                                <Button color='primary'>Select an image to upload.</Button>
                            </Fragment>
                        </div>
                    </section>
                )}
            </Dropzone>
            {touched && error && <span className="error" style={{display: "block", color: "red"}}>{error}</span>}
        </Fragment>
    );
};


const validate = (values) => {
    const errors: any = {};
    if (!values.promotionalText) {
        errors.promotionalText = 'Required'
    } else if (values.promotionalText.length > 255) {
        errors.promotionalText = `${values.promotionalText.length - 255} characters over limit`
    }

    if(!values[FILE_FIELD_NAME]) {
        errors[FILE_FIELD_NAME] = 'Required'
    }

    return errors
};

const renderField = ({ input, label, type, autocomplete, options, handleAutoCompleteSelected, meta: { touched, error, value, form }, ...custom }) => {
    return autocomplete ?
        <AutoComplete
            options={options}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            form={form}
            error={error}
            input={input}
            {...custom}
        />
        :
        <TextField label={label} type={type} {...input} multiline
            error={!!(touched && error)} rowsMax={3}
            helperText={touched && error ? error : ''} {...custom} />
};

interface DSPCreateProductCategoryPromotionFormProps {
    onCancel: () => any,
    handleHide: () => any;
}

const DSPCreateProductCategoryPromotionForm = (props: DSPCreateProductCategoryPromotionFormProps & InjectedFormProps<{imageLocation: string, promotionalText: string}, DSPCreateProductCategoryPromotionFormProps>) => {
    const { handleSubmit, onCancel, handleHide, initialValues } = props;
    return (
        <form onSubmit={handleSubmit} className="dspr-create-product-category-promotion-form">
            <DialogContent>
                {initialValues && <img src={getImage(initialValues.imageLocation)} alt={initialValues.promotionalText} style={{objectFit: 'contain', display: "block", marginBottom: "15px"}} width="300px" height="200px"/>}
                {!initialValues && <Field name={FILE_FIELD_NAME} component={renderDropzoneInput} type="dropzone" label="Upload Image" />}
                <Field name="promotionalText" component={renderField} label="Promotional Text" type="textarea" disabled={!!initialValues} style={{width: "300px"}}/>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onCancel}>Cancel</Button>
                {!initialValues && <Button variant='contained' color='primary' onClick={handleSubmit}>Create</Button>}
                {initialValues && <Button variant='contained' color='primary' onClick={handleHide}>hide</Button>}
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPCreateProductCategoryPromotionFormProps>({
    form: 'DSPCreateProductCategoryPromotionForm', // a unique identifier for this form
    validate
})(DSPCreateProductCategoryPromotionForm)