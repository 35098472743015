import React, {Fragment, useEffect, useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../middleware/api";
import {
    PACKAGE_ORDER_FAILURE,
    PACKAGE_ORDER_SUCCESS,
    packageOrder
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";

export const PackageButton:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack,
        isManagerForDSPR,
        isDriverForOrder
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);

    const dispatch = useDispatch();

    const handlePackageOrder = () => {
        setDisableButtonCallBack(true)
        dispatch<any>(packageOrder(orderId)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === PACKAGE_ORDER_SUCCESS) {
                if(closeModal) closeModal();
            }else if(response.type === PACKAGE_ORDER_FAILURE){
                setExtraOrderFunctionalityErrorCallBack(response.error);
            }
        })
    }

    useEffect(() => {

        if(isManagerForDSPR || isDriverForOrder) return;
        const isPreviousOrderStatusValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.packaged); // check if driver is still full menu driver on queued
        if(isPreviousOrderStatusValid) setShowBasedOnOrderStatus(true);
            }, []);

    return showBasedOnOrderStatus && <Fragment>
        <div style={{width: "100%", marginTop: "8px", marginBottom: "30px"}}>
            <Button variant="contained" color="primary" onClick={() => handlePackageOrder()}>
                Package Order
            </Button>
        </div>
        </Fragment>
}