import React from 'react';
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, DialogContent, DialogActions } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const validate = (values) => {
    const errors: any = {};
    if (!values.employeeIDExpirationDate) {
        errors.employeeIDExpirationDate = 'required';
    }
    if (!values.employeeNumber) {
        errors.employeeNumber = 'Required';
    }
    if (!values.vehicleDescriptiveName) {
        errors.vehicleDescriptiveName = 'Required';
    }
    if (!values.vehicleLicensePlateNumber) {
        errors.vehicleLicensePlateNumber = 'Required';
    }
    return errors
};

const renderField = ({ input, label, type="textfield", meta: { touched, error } }) => {
    switch(type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="med-rec-exp-date-picker"
                    label={label}
                    value={input.value || null}
                    autoOk
                    disablePast
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    // helperText={touched && error ? error : ''}
                    // error={!!(touched && error)}
                />
            </MuiPickersUtilsProvider>
        default :
            return <TextField label={label} type={type} {...input} helperText={touched && error ? error : ''} error={!!(touched && error)} />
    }
}

interface DriverInformationFormProps {
    driver: any;
    initialValues: any;
}

const DriverInformationForm = (props: DriverInformationFormProps & InjectedFormProps<{}, DriverInformationFormProps>) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit} className="driver-information-form">
            <DialogContent>
                <Field name="employeeNumber" component={renderField} label="Employee Number" />
                <Field name="employeeIDExpirationDate" type="date" component={renderField} label="Employee Number Expiration"/>
                <Field name="vehicleDescriptiveName" component={renderField} label="Vehicle Description" />
                <Field name="vehicleLicensePlateNumber" component={renderField} label="License Plate Number" />

                <Field name="driverLicenseNumber" component={renderField} label="Driver License" />
                <Field name="vehicleModel" component={renderField} label="Vehicle Model" />
                <Field name="vehicleMake" component={renderField} label="Vehicle Make" />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" className="raised-button" onClick={handleSubmit}>Update Driver Information</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DriverInformationFormProps>({
    form: 'DriverInformationForm',
    validate
})(DriverInformationForm);