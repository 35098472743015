import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle, Grid, MenuItem, Select,
    TextField, Typography
} from "@material-ui/core";
import CardBody from "./Card/CardBody";
import ApplicableTaxForm from "../containers/ApplicableTaxForm";
import {shallowEqual, useSelector} from "react-redux";
import {ApplicableTax, State} from "../store/reduxStoreState";
import {
     CREATE_NEW_APPLICABLE_TAX_FAILURE, CREATE_NEW_APPLICABLE_TAX_SUCCESS,
     DELETE_APPLICABLE_TAX_FAILURE, DELETE_APPLICABLE_TAX_SUCCESS,
     GET_ALL_APPLICABLE_TAXES_FAILURE,
    GET_ALL_APPLICABLE_TAXES_SUCCESS
} from "../actions/applicableTaxActions";
import VirtualizedTable from "./VirtualizedTable";
import ApplicableTaxWithDetails from "./ApplicableTaxWithDetails";
import SnackBarAlertError from "./SnackBarAlertError";
import SnackBarAlertSuccess from "./SnackBarAlertSuccess";
import {AutoSelectItemNumberValue} from "./Autocomplete";
import DSPRApplicableTaxAssociationForm from "../containers/DSPRApplicableTaxAssociationForm";
import {
    CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE,
    CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
    DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE,
    DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
    GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS
} from "../actions/dsprApplicableTaxAssociationActions";
import {getDSPRsByName} from "../selectors/dsprSelectors";

interface ApplicableTaxesProps {
    applicableTaxes: ApplicableTax[];
    interceptAutocompleteSelectedTaxes: (applicableTaxes: AutoSelectItemNumberValue[]) => any;
    getApplicableTaxesByDSPR_ID:(dsprId:number) => any;
}

const ApplicableTaxes = (props: ApplicableTaxesProps & any) => {
    const   FILTER_TYPE = {
        TAX: 'TAX',
        DSPR: 'DSPR'
    };

    const {getAllApplicableTaxes,
        handleNewApplicableTax,
        handleNewDSPRApplicableTaxAssociation,
        applicableTaxes,
        interceptAutocompleteSelectedTaxes,
        getApplicableTaxesByDSPR_ID,
        applicableTaxesAssociatedWithDSPRForSelect,
        handleDeleteDSPRApplicableTaxAssociationById,
        handleDeleteApplicableTaxById,
        isSystemAdmin} = props;

    const [isLoading, setIsLoading] = useState(true);
    const [showApplicableTaxForm, setShowApplicableTaxForm] = useState(false);
    const [showDSPRApplicableTaxForm, setShowDSPRApplicableTaxForm] = useState(false);
    const [showDeleteDSPRApplicableTaxForm, setShowDeleteDSPRApplicableTaxForm] = useState(false);
    const [showModifyApplicableTaxForm, setShowModifyApplicableTaxForm] = useState(false);
    const [currentSearchValue, setCurrentSearchValue] = useState("");
    const [currentApplicableTaxesToDisplay, setCurrentApplicableTaxesToDisplay] = useState(undefined);
    const abortController = useMemo(() => new AbortController(), []);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showApplicableTaxDetailDialog, setShowApplicableTaxDetailDialog] = useState(false);
    const [applicableTaxForDialog, setApplicableTaxForDialog] = useState(null);
    const [alertText, setAlertText] = useState<string>('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertTitle, setErrorAlertTitle] = useState<string>('Error Encountered!');
    const dsprsForSelect = useSelector<State, { value: number, text: string }[]>((state => getDSPRsByName(state)), shallowEqual);
    const [filterType, setFilterType] = useState(FILTER_TYPE.TAX);
    const [dsprSelect, setDSPRSelect] = useState(null);
    const [dsprSelectName, setDSPRSelectName] = useState(null);

    function typeOfUserYesOrNo(bool:boolean){
        return bool ? "Yes" : "No";
    }
    const openApplicableTaxDetailDialog = (applicableTax: ApplicableTax) => {
        setShowApplicableTaxDetailDialog(true);
        setApplicableTaxForDialog(applicableTax)
    }

    const closeApplicableTaxDetailDialog = () => {
        setShowApplicableTaxDetailDialog(false);
        setApplicableTaxForDialog(null);

    }
    const closeApplicableTaxForm = () => {
        if(showApplicableTaxForm){
            setShowApplicableTaxForm(false)
            }
        if(showModifyApplicableTaxForm){
            setShowModifyApplicableTaxForm(false);
        }
    }
    const closeDSPRApplicableTaxForm = () => {
        if(showDSPRApplicableTaxForm){
            setShowDSPRApplicableTaxForm(false);
        }
        if(showDeleteDSPRApplicableTaxForm){
            setShowDeleteDSPRApplicableTaxForm(false);
        }
    }

    /**Provides a clean-up function to cancel fetch requests when component unmounts*/
    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, [abortController])

    useEffect(() => {
        getAllApplicableTaxesResCheck();
    }, [])
    useEffect(() => {
        if(dsprSelect)findTaxesByDSPR_ID();
    }, [dsprSelect])


    const title = 'Applicable Taxes';
    function getAllApplicableTaxesResCheck(){
    getAllApplicableTaxes().then(response => {
        if (response.type === GET_ALL_APPLICABLE_TAXES_SUCCESS) {
            return true;
        }
        if (response.type === GET_ALL_APPLICABLE_TAXES_FAILURE) {
            setAlertText(response.error ? response.error : 'Something went wrong with loading all applicable taxes');
            setShowErrorAlert(true);

            return true;
        }

        setIsLoading(false);
    });
        }
    useEffect(() => {
        if(applicableTaxes && filterType == FILTER_TYPE.TAX) {
            setIsLoading(false)
            if(currentSearchValue && currentSearchValue !== "") {
                setCurrentApplicableTaxesToDisplay(applicableTaxes.filter(tax => currentSearchValue === "" ? true : tax.name.toLowerCase().includes(currentSearchValue.toLowerCase())))
            } else {
                setCurrentApplicableTaxesToDisplay(applicableTaxes);
            }
        }
    }, [applicableTaxes]);

    useEffect(() => {
        if(applicableTaxesAssociatedWithDSPRForSelect && filterType == FILTER_TYPE.DSPR) {
            const filteredUndefined = applicableTaxesAssociatedWithDSPRForSelect.applicableTaxes.filter(e => e !== undefined);
            setCurrentApplicableTaxesToDisplay(filteredUndefined);
        }
    }, [applicableTaxesAssociatedWithDSPRForSelect]);

    const renderApplicableTaxRow = (applicableTax: ApplicableTax, style: React.CSSProperties) => {
        return <div key={applicableTax.id} style={style} className="coupon-row" onClick={() => openApplicableTaxDetailDialog(applicableTax)}>
            <span>{applicableTax.name}</span>
            <span>{applicableTax.rate}</span>
            <span>{typeOfUserYesOrNo(applicableTax.appliedToRecUserOnly)}</span>
            <span>{typeOfUserYesOrNo(applicableTax.appliedToMedUserOnly)}</span>
            <span>{typeOfUserYesOrNo(applicableTax.appliedToNonStateIssuedCardOnly)}</span>
            <span>{typeOfUserYesOrNo(applicableTax.appliedToAllOtherTaxes)}</span>
        </div>
    }

    const renderApplicableTaxes = ({
                               index,
                               style
                           }) => (currentApplicableTaxesToDisplay && currentApplicableTaxesToDisplay.length > 0) ?  renderApplicableTaxRow(currentApplicableTaxesToDisplay[index], style): null

    const handleSearchChange = (event: any) => {
        setCurrentSearchValue(event.target.value)
        if(applicableTaxes) setCurrentApplicableTaxesToDisplay(applicableTaxes.filter(tax => currentSearchValue === "" ? true : tax.name.toLowerCase().includes(currentSearchValue.toLowerCase())))
    };

    const handleDeleteApplicableTaxByIdResponseCheck = (values) => {
        const confirmedToDelete = window.confirm('Are you sure you want to delete this tax?');
        if(confirmedToDelete) {
            handleDeleteApplicableTaxById(values).then(response => {
                if (response.type === DELETE_APPLICABLE_TAX_SUCCESS) {
                    setAlertText(`tax has been successfully deleted!`);
                    setShowSuccessAlert(true);
                    setShowApplicableTaxDetailDialog(false);
                    setShowModifyApplicableTaxForm(false);
                    setShowApplicableTaxForm(false);
                    setApplicableTaxForDialog(null);
                    setShowErrorAlert(false);
                    const dsprSelectRefresh = dsprSelect;
                    setDSPRSelect(null);
                    setDSPRSelect(dsprSelectRefresh);

                    return true;
                }
                if (response.type === DELETE_APPLICABLE_TAX_FAILURE) {
                    setAlertText(response.error ? response.error : 'Please try again');
                    setShowErrorAlert(true);
                    setShowSuccessAlert(false);
                    return true;
                }
            })
        }
    }

    const handleDeleteDSPRApplicableTaxAssociationByIdResponseCheck = (values) => {
        const confirmedToDelete = window.confirm('Are you sure you want to delete the following DSPR Tax Associations?');
        if(confirmedToDelete) {
            handleDeleteDSPRApplicableTaxAssociationById(values, dsprSelect).then(response => {
                if (response.type === DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS) {
                    setAlertText(`The tax association to the dspr has been successfully deleted!`);
                    setShowSuccessAlert(true);
                    setShowDeleteDSPRApplicableTaxForm(false);
                    setShowErrorAlert(false);
                    return true;
                }
                if (response.type === DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE) {
                    setAlertText(response.error ? response.error : 'Please try again');
                    setShowErrorAlert(true);
                    setShowSuccessAlert(false);
                    return true;
                }

                setIsLoading(false);
            })
        }
    }

    const handleNewApplicableTaxWithResponseCheck = (values) => {

        handleNewApplicableTax(values).then(response => {
            if (response.type === CREATE_NEW_APPLICABLE_TAX_SUCCESS) {
                setAlertText(`The tax has been successfully created!`);
                setShowSuccessAlert(true);
                setShowModifyApplicableTaxForm(false);
                setShowApplicableTaxDetailDialog(false);
                setApplicableTaxForDialog(null);
                setShowApplicableTaxForm(false);
                return true;
            }
            if (response.type === CREATE_NEW_APPLICABLE_TAX_FAILURE) {
                setAlertText(response.error ? response.error : 'Please try again');
                setShowErrorAlert(true);
                return true;
            }
            setIsLoading(false);
        }
        )
    }

    const handleFilterChange = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const filterType = event.target.value as string;
        setFilterType(filterType);
        if (filterType === FILTER_TYPE.TAX) {
            // setCurrentApplicableTaxesToDisplay(applicableTaxSaveForAll);
            setCurrentApplicableTaxesToDisplay(applicableTaxes);
            setCurrentSearchValue("");
        } else if (filterType === FILTER_TYPE.DSPR) {
            setCurrentApplicableTaxesToDisplay(null);
            setDSPRSelect(null);
        }
    };

    const handleDSPRSelectChange = (event: React.ChangeEvent<{dataset?, value: unknown }>) => {
        const dsprSelectChange = event.target.value as string;
        const { name } = event.currentTarget.dataset;
        setDSPRSelect(dsprSelectChange);
        setDSPRSelectName(name);
    };
    const handleNewDSPRApplicableTaxAssociationWithResponseCheck = (values) => {

        handleNewDSPRApplicableTaxAssociation(values,dsprSelect).then(response => {
            if (response.type === CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS) {
                setAlertText(`The dspr has been successfully associated with the provided taxes!`);
                setShowSuccessAlert(true);
                setShowErrorAlert(false);
                setShowDSPRApplicableTaxForm(false);
                return true;
            }
            if (response.type === CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE) {
                setAlertText(response.error ? response.error : 'Please try again');
                setShowErrorAlert(true);
                setShowSuccessAlert(false);
                return true;
            }

            setIsLoading(false);
        }
        )
    }

const clearSearch = (event) => {
        setCurrentSearchValue("");
        setCurrentApplicableTaxesToDisplay(applicableTaxes);
}
const findTaxesByDSPR_ID = () => {
    setCurrentApplicableTaxesToDisplay(undefined);
    getApplicableTaxesByDSPR_ID(dsprSelect).then(response =>{
        if (response.type === GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS) {
            return true;
        }
        if (response.type === CREATE_NEW_APPLICABLE_TAX_FAILURE) {
            setAlertText(response.error ? response.error : 'Please try again');
            setShowErrorAlert(true);
            return true;
        }
    });
}

return <div>
    <Card>
        <CardHeader title={title}/>
        <CardBody>
                <Grid
                    container
                    direction="row"
                    style={{ marginBottom:"1em",
                        maxWidth:"100%"
                    }}
                >
                        <Select style={{
                            marginRight:"1.5em",
                            paddingLeft:"0.2em",
                            paddingRight:"0.2em",
                            fontSize:"1.4em",
                        }}
                                className="coupon-filter-dropdown" value={filterType} onChange={handleFilterChange}>
                            <MenuItem value={FILTER_TYPE.TAX}>Create/View Taxes</MenuItem>
                            <MenuItem value={FILTER_TYPE.DSPR}>Associate Taxes With DSPR</MenuItem>
                        </Select>

            {filterType == FILTER_TYPE.TAX ? <TextField value={currentSearchValue}
                       onChange={handleSearchChange} name="applicableTaxSearch"></TextField> :
                <Select
                    value={dsprSelect}
                    onChange={handleDSPRSelectChange}
                    style={{minWidth: 120}}
                >
                    {dsprsForSelect.map(dsprForSelect =>
                        <MenuItem
                            data-name={dsprForSelect.text}
                            value={dsprForSelect.value}
                        >{dsprForSelect.text}</MenuItem>
                    )}
                </Select>
            }

                    {filterType == FILTER_TYPE.TAX ?
                <Button style={{marginLeft: 8}} color={"primary"} variant={"contained"} onClick={filterType == FILTER_TYPE.TAX ? clearSearch : findTaxesByDSPR_ID}>
                        Clear
                </Button> :''
                        }
                </Grid>

            {!isLoading && currentApplicableTaxesToDisplay &&  <VirtualizedTable
                itemCount={currentApplicableTaxesToDisplay.length}
                renderItems={renderApplicableTaxes}
                header={['Name','Rate','Recreational User Only','Medical User Only','Non State Issued Card Only','Applied To All Other Taxes']}
            />}
        </CardBody>
        <CardActions>
            {filterType == FILTER_TYPE.TAX ? <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowApplicableTaxForm(true)}
                >
                    Create An Applicable Tax
                </Button>
                </>
                :
                <>
                    <Button
                    variant="contained"
                    color="primary"
                    disabled={dsprSelect === null}
                    onClick={() => setShowDSPRApplicableTaxForm(true)}
                    >
                    Associate DSPR With Tax
                    </Button>
                    <Button
                    variant="contained"
                    disabled={dsprSelect === null}
                    style={{
                        backgroundColor: dsprSelect === null ? "" : "#FB0001",
                        color: dsprSelect === null ? "" : "white"
                    }}
                    onClick={() => setShowDeleteDSPRApplicableTaxForm(true)}
                    >
                    Delete DSPR With Tax
                    </Button>
                </>
            }
        </CardActions>
    </Card>
    <Dialog open={showApplicableTaxForm || showModifyApplicableTaxForm}  onClose={() => closeApplicableTaxForm()} className="new-coupon-form">
        <DialogTitle>{showApplicableTaxForm ? 'Create Applicable Tax' : 'Modify Applicable Tax'}</DialogTitle>
        <DialogContent>
            <ApplicableTaxForm
                onSubmit={(values) => handleNewApplicableTaxWithResponseCheck(values)}
                applicableTax={applicableTaxForDialog ? applicableTaxForDialog : null}
                showApplicableTaxForm={showApplicableTaxForm}
            />
        </DialogContent>
    </Dialog>
    <Dialog open={showDSPRApplicableTaxForm || showDeleteDSPRApplicableTaxForm}  onClose={() => closeDSPRApplicableTaxForm()} className="new-coupon-form">
        <DialogTitle>{showDSPRApplicableTaxForm ? 'Create DSPR Applicable Tax Association' : 'Delete DSPR Applicable Tax Association'}</DialogTitle>
        <DialogContent>
            {
                showDSPRApplicableTaxForm ?
                    <DSPRApplicableTaxAssociationForm
                    onSubmit={(values) => handleNewDSPRApplicableTaxAssociationWithResponseCheck(values)}
                    interceptAutocompleteSelectedTaxes={interceptAutocompleteSelectedTaxes}
                    showDSPRApplicableTaxForm={showDSPRApplicableTaxForm}
                    dsprSelect={dsprSelect}
                    dsprSelectName={dsprSelectName}
                    applicableTaxesAssociatedWithDSPRForSelect={applicableTaxesAssociatedWithDSPRForSelect}
                />
                    :
                    <DSPRApplicableTaxAssociationForm
                        onSubmit={(values) => handleDeleteDSPRApplicableTaxAssociationByIdResponseCheck(values)}
                        interceptAutocompleteSelectedTaxes={interceptAutocompleteSelectedTaxes}
                        showDSPRApplicableTaxForm={showDSPRApplicableTaxForm}
                        dsprSelect={dsprSelect}
                        dsprSelectName={dsprSelectName}
                        applicableTaxesAssociatedWithDSPRForSelect={applicableTaxesAssociatedWithDSPRForSelect}
                    />
            }
        </DialogContent>
    </Dialog>
    <Dialog
        open={showApplicableTaxDetailDialog}
        maxWidth={'md'}
        onClose={() => closeApplicableTaxDetailDialog()}>
        {showApplicableTaxDetailDialog
            ? <ApplicableTaxWithDetails
                applicableTax={applicableTaxForDialog}
                isSystemAdmin={isSystemAdmin}
                typeOfUserYesOrNo={typeOfUserYesOrNo}
                closeApplicableTaxDetailDialog={closeApplicableTaxDetailDialog}
                handleOpenApplicableTaxForm={() => setShowModifyApplicableTaxForm(true)}
                deleteApplicableTaxById={(values) => handleDeleteApplicableTaxByIdResponseCheck(values)}
            />
            : <Typography><p>Applicable Tax Detail is Unavailable</p></Typography>
        }
    </Dialog>
    <SnackBarAlertSuccess
        showSnackbarAlert={showSuccessAlert}
        setIsVisible={setShowSuccessAlert}
        alertText={alertText}
    />

    <SnackBarAlertError
        showSnackbarAlert={showErrorAlert}
        setIsVisible={setShowErrorAlert}
        alertTitle={errorAlertTitle}
        alertText={alertText}
        snackbarStyle={{width: 'fit-content'}}
    />
</div>
}

export default ApplicableTaxes;