import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
    createApplicableTax,
    deleteApplicableTaxById,
    getAllApplicableTaxesForAll
} from "../actions/applicableTaxActions";
import {
    createDSPRApplicableTaxAssociations, deleteDSPRApplicableTaxAssociationByDSPR_ID,
    getDSPRApplicableTaxAssociationGetter
} from "../actions/dsprApplicableTaxAssociationActions";
import {ApplicableTax, State} from "../store/reduxStoreState";
import {
    getAllApplicableTaxesFromStateInArrayFormat, getAllApplicableTaxesForDSPRByDSPRId,
} from "../selectors/applicableTaxesSelectors";
import ApplicableTaxes from "../components/ApplicableTaxes";
import {
    interceptDSPRForApplicableTaxAssociationFormAutocompleteSelected
} from "../actions/formHelperActions";


const ApplicableTaxContainer: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const abortController = useMemo(() => new AbortController(), []);
    const applicableTaxes = useSelector<State, { [key: number]:ApplicableTax}>(state => getAllApplicableTaxesFromStateInArrayFormat(state));
    //getCouponsForDSPR(state, { dsprId }), shallowEqual);
    const [selectedDSPRId, setSelectedDSPRId] = useState(null);
    const applicableTaxesAssociatedWithDSPRForSelect =
        // {applicableTaxes: ApplicableTax[], id: number, dsprId: number}
        useSelector<State, {applicableTaxes: ApplicableTax[], dsprId: number}>(state => getAllApplicableTaxesForDSPRByDSPRId(state,{dsprId: selectedDSPRId}),shallowEqual);
        // useSelector<State, {applicableTaxes: ApplicableTax[], dsprId: number}>(state => getAllApplicableTaxesForDSPRByDSPRId(state,{dsprId: 1}),shallowEqual);
    // , {dsprId: 1}


    /**Provides a clean-up function to cancel fetch requests when component unmounts*/
    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, [abortController])


    const getAllApplicableTaxes = () => {
        return dispatch(getAllApplicableTaxesForAll());
    };

    const handleNewApplicableTax = (values) => {
        return dispatch(createApplicableTax(values));
    }

    const handleNewDSPRApplicableTaxAssociation = (values,dsprSelect) => {
        return dispatch(createDSPRApplicableTaxAssociations(values,dsprSelect));
    }

    const getApplicableTaxesByDSPR_ID = (values) => {
        setSelectedDSPRId(values);
        return dispatch(getDSPRApplicableTaxAssociationGetter(values));
    }
    const handleDeleteDSPRApplicableTaxAssociationById = (values,dsprSelect) =>{
        return dispatch(deleteDSPRApplicableTaxAssociationByDSPR_ID(values,dsprSelect))
    }
    const handleDeleteApplicableTaxById = (values) =>{
        return dispatch(deleteApplicableTaxById(values))
    }
    return <>
        <ApplicableTaxes
            getAllApplicableTaxes={getAllApplicableTaxes}
            getApplicableTaxesByDSPR_ID={getApplicableTaxesByDSPR_ID}
            handleNewApplicableTax={handleNewApplicableTax}
            handleNewDSPRApplicableTaxAssociation={handleNewDSPRApplicableTaxAssociation}
            interceptAutocompleteSelectedTaxes={applicableTaxes => dispatch(interceptDSPRForApplicableTaxAssociationFormAutocompleteSelected(applicableTaxes))}
            applicableTaxes={applicableTaxes}
            applicableTaxesAssociatedWithDSPRForSelect={applicableTaxesAssociatedWithDSPRForSelect}
            handleDeleteDSPRApplicableTaxAssociationById={handleDeleteDSPRApplicableTaxAssociationById}
            handleDeleteApplicableTaxById={handleDeleteApplicableTaxById}
        />
    </>

}

export default ApplicableTaxContainer;
