import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField } from '@material-ui/core'
import { IdDocument } from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import "./UserIdDocumentVerificationFormStyles.scss"
const validate = (values) => {
    const errors = {};
    const requiredFields = ['expirationDate', 'idNumber', 'birthDate'];
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Required';
    });

    if (!parseDate(values['expirationDate'])) errors['expirationDate'] = "Invalid Date";
    if (!parseDate(values['birthDate'])) errors['birthDate'] = "Invalid Date";

    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error }, ...custom }) => {
    switch (type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="user-id-exp-date-picker"
                    label={label}
                    value={input.value || null}
                    autoOk
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...custom}
                />
            </MuiPickersUtilsProvider>
        default:
            return <TextField
                    label={label} placeholder={label} type={type} {...input} {...custom}
                    error={!!(touched && error)}
                    helperText={touched && error ? error : ''}
                />
    }
};

interface UserIdDocumentVerificationFormProps extends Partial<InjectedFormProps> {
    identificationDocument: IdDocument;
    handleHide: (id: number) => any;
    handleUnhide: (id: number) => any;
}

const UserIdDocumentVerificationForm = (props: UserIdDocumentVerificationFormProps) => {
    const { handleSubmit, identificationDocument, handleHide, handleUnhide } = props;
    return !identificationDocument.verified &&
        <form onSubmit={handleSubmit} className={"user-id-document-verification-form"}>
            <div className={'fields-container'}>
                <Field name="birthDate" type="date" component={renderField} label="Birth Date" className={'field'}/>
                <Field name="idNumber" component={renderField} label="ID Number" className={'field'}/>
                <Field name="expirationDate" type="date" component={renderField} label="Expiration Date" className={'field'}/>
            </div>
            <div className={'button-container'}>
                <div className={'verify-button-container'}>
                    <Button color="primary" variant="contained" onClick={handleSubmit}>Verify</Button>
                </div>
                <div className={'hide-unhide-button-container'}>
                    {identificationDocument.visible ? <Button color="secondary" variant="contained" onClick={() => handleHide(identificationDocument.id)}>Hide</Button> :
                        <Button color="secondary" variant="contained" onClick={() => handleUnhide(identificationDocument.id)}>Unhide</Button>}
                </div>
            </div>
        </form>
};

export default reduxForm<any, UserIdDocumentVerificationFormProps>({
    form: 'UserIdDocumentVerificationForm',
    validate
})(UserIdDocumentVerificationForm);