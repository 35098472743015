import React from 'react';
import './IncrementerStyles.scss';
import { ButtonBase } from '@material-ui/core';

interface IncrementerProps {
    updateValue: (value: number) => any;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    style?: React.CSSProperties;
    borderColor?: string;
}

const Incrementer: React.FC<IncrementerProps> = props => {
    const { updateValue, value, min = 0, max = Infinity, step = 1, style, borderColor = 'rgba(0, 0, 0, 0.47)' } = props;

    const handleIncrement = () => {
        const newValue = value + step;
        if (newValue <= max) updateValue(newValue);
    }

    const handleDecrement = () => {
        const newValue = value - step;
        if (newValue >= min) updateValue(newValue);
    }

    return <div className="incrementer" style={{ ...style, borderColor }}>
        <ButtonBase onClick={() => handleDecrement()}>-</ButtonBase>
        <span>{value}</span>
        <ButtonBase onClick={() => handleIncrement()}>+</ButtonBase>
    </div>
}

export default Incrementer;