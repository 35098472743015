import { CALL_API, Schemas } from '../middleware/api';
import { CouponLockType, DiscountType } from "../store/reduxStoreState";

const ENDPOINT = 'dspr/coupon';

export const GET_DSPR_COUPONS = 'GET_DSPR_COUPONS';
export const GET_DSPR_COUPONS_SUCCESS = 'GET_DSPR_COUPONS_SUCCESS';
export const GET_DSPR_COUPONS_FAILURE = 'GET_DSPR_COUPONS_FAILURE';

const dsprCouponGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_COUPONS, GET_DSPR_COUPONS_SUCCESS, GET_DSPR_COUPONS_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.COUPON_ARRAY,
            queryParamsMap: { dspr_id: dsprId }
        }
    }
};

export const getDSPRCoupons = (dsprId) => (dispatch, getState) => {
    return dispatch(dsprCouponGetter(dsprId));
};

export const SEARCH_DSPR_COUPONS = 'SEARCH_DSPR_COUPONS';
export const SEARCH_DSPR_COUPONS_SUCCESS = 'SEARCH_DSPR_COUPONS_SUCCESS';
export const SEARCH_DSPR_COUPONS_FAILURE = 'SEARCH_DSPR_COUPONS_FAILURE';

const dsprCouponSearcher = (dsprId, code) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [SEARCH_DSPR_COUPONS, SEARCH_DSPR_COUPONS_SUCCESS, SEARCH_DSPR_COUPONS_FAILURE],
            endPoint: ENDPOINT + "/search",
            schema: Schemas.COUPON_ARRAY,
            queryParamsMap: {
                dspr_id: dsprId,
                partial_code: code
            }
        }
    }
};

export const searchDSPRCoupons = (dsprId, code) => (dispatch, getState) => {
    return dispatch(dsprCouponSearcher(dsprId, code));
};

export const CREATE_NEW_COUPON = 'CREATE_NEW_COUPON';
export const CREATE_NEW_COUPON_SUCCESS = 'CREATE_NEW_COUPON_SUCCESS';
export const CREATE_NEW_COUPON_FAILURE = 'CREATE_NEW_COUPON_FAILURE';

export const MODIFY_COUPON = 'MODIFY_COUPON';
export const MODIFY_COUPON_SUCCESS = 'MODIFY_COUPON_SUCCESS';
export const MODIFY_COUPON_FAILURE = 'MODIFY_COUPON_FAILURE';

const couponCreatorOrModifier = (dsprId, code, isFlower, discountAmount, discountType, maxDiscountAmount, maxPerUserUsageTimes,
    isFirstTimeUserOnly, startTimestamp, endTimestamp, userIds, categories, days, quantityRequired, unit, id, transferUserUsage, couponToModifyFromDSPR) => {
    let coupon = {
        dspr: { id: dsprId },
        code,
        isFlower,
        discountAmount,
        discountType,
        maxDiscountAmount,
        maxPerUserUsageTimes,
        isFirstTimeUserOnly,
        startTimestamp,
        endTimestamp,
        id,
        dailyDealDays: days,
        quantityRequired,
        unit
    };

    if (userIds && userIds.length > 0) {
        coupon.isFirstTimeUserOnly = false;
        coupon['specificallyAllowedUsers'] = userIds.map(id => { return { id }});
    }
    if (categories) {
        coupon['specificallyAllowedProductCategories'] = categories.map(id => { return { id } })
    }

    if (coupon.isFirstTimeUserOnly !== undefined) {
        coupon.isFirstTimeUserOnly = coupon.isFirstTimeUserOnly.toString();
    }

    if (couponToModifyFromDSPR) {
        const couponToModify = {...couponToModifyFromDSPR};
        couponToModify.dspr = {id: couponToModify.dspr};

        if (couponToModify.specificallyAllowedUsers.length > 0) {
            couponToModify.specificallyAllowedUsers = couponToModify.specificallyAllowedUsers.map(id => { return { id } });
        }

        if (couponToModify.specificallyAllowedProductCategories.length > 0) {
            couponToModify.specificallyAllowedProductCategories = couponToModify.specificallyAllowedProductCategories.map(id => { return { id } });
        }

        if (couponToModify.specificallyAllowedProducts.length > 0) {
            couponToModify.specificallyAllowedProducts = couponToModify.specificallyAllowedProducts.map(id => { return { id } });
        }



        couponToModify.isFirstTimeUserOnly = couponToModify.isFirstTimeUserOnly !== undefined ? couponToModify.isFirstTimeUserOnly.toString() : undefined;
        couponToModify.discountAmount = couponToModify.discountAmount !== undefined ? couponToModify.discountAmount.toString() : undefined;
        couponToModify.maxDiscountAmount = couponToModify.maxDiscountAmount !== undefined ? couponToModify.maxDiscountAmount.toString() : undefined;
        couponToModify.maxPerUserUsageTimes = couponToModify.maxPerUserUsageTimes !== undefined ? couponToModify.maxPerUserUsageTimes.toString() : undefined;
        couponToModify.quantityRequired = couponToModify.quantityRequired !== undefined ? couponToModify.quantityRequired.toString() : undefined;
        //couponToModify.totalUsage = couponToModify.totalUsage ? couponToModify.totalUsage.toString() : undefined;
        delete couponToModify.lastUpdateDSPRManager;
        delete couponToModify.totalUsage;
        delete couponToModify.user;
        delete couponToModify.modifiedCoupon;
        delete couponToModify.isActive;

        coupon['transferUserUsageToNewCoupon'] = transferUserUsage === "true";
        coupon['modifiedCoupon'] = couponToModify;
        delete coupon.code;

        return {
            [CALL_API]: {
                httpAction: 'POST',
                types: [MODIFY_COUPON, MODIFY_COUPON_SUCCESS, MODIFY_COUPON_FAILURE],
                endPoint: ENDPOINT + '/modified',
                schema: Schemas.COUPON,
                body: coupon
            }
        }
    }

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_NEW_COUPON, CREATE_NEW_COUPON_SUCCESS, CREATE_NEW_COUPON_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.COUPON,
            body: coupon
        }
    }
};

export const createOrModifyCoupon = (dsprId, code, isFlower, discountAmount, discountType, maxDiscountAmount, maxPerUserUsageTimes,
                                     isFirstTimeUserOnly, startTimestamp, endTimestamp, userIds, categories, days, quantityRequired, unit, id, transferUserUsage, couponToModify) => (dispatch, getState) => {

        return dispatch(couponCreatorOrModifier(dsprId, code, isFlower, discountAmount, discountType, maxDiscountAmount, maxPerUserUsageTimes,
            isFirstTimeUserOnly, startTimestamp, endTimestamp, userIds, categories, days, quantityRequired, unit, id, transferUserUsage, couponToModify))
            .then(response => {
                getDSPRCoupons(dsprId);
                return response;
            });
    };

export const TOGGLE_COUPON_ACTIVE_STATUS = 'TOGGLE_COUPON_ACTIVE_STATUS';
export const TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS = 'TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS';
export const TOGGLE_COUPON_ACTIVE_STATUS_FAILURE = 'TOGGLE_COUPON_ACTIVE_STATUS_FAILURE';

const couponToggler = (couponId) => {
    const coupon = {
        id: couponId
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [TOGGLE_COUPON_ACTIVE_STATUS, TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS, TOGGLE_COUPON_ACTIVE_STATUS_FAILURE],
            endPoint: ENDPOINT + "/toggle-active",
            schema: Schemas.COUPON,
            body: coupon
        }
    }
};

export const toggleCouponActiveStatus = (couponId) => (dispatch, getState) => {
    return dispatch(couponToggler(couponId));
};

export const GET_DSPR_COUPON_LOCK_TYPES = 'GET_DSPR_COUPON_LOCK_TYPES';
export const GET_DSPR_COUPON_LOCK_TYPES_SUCCESS = 'GET_DSPR_COUPON_LOCK_TYPES_SUCCESS';
export const GET_DSPR_COUPON_LOCK_TYPES_FAILURE = 'GET_DSPR_COUPON_LOCK_TYPES_FAILURE';

const couponLockTypesGetter = (dsprId, signal) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_COUPON_LOCK_TYPES, GET_DSPR_COUPON_LOCK_TYPES_SUCCESS, GET_DSPR_COUPON_LOCK_TYPES_FAILURE],
            endPoint: ENDPOINT + "/lock",
            schema: Schemas.DSPR_COUPON_LOCKS,
            queryParamsMap: {
                dspr_id: dsprId,
            },
            signal
        }
    }
}

export const getCouponLockTypes = (dsprId, signal) => (dispatch) => {
    return dispatch(couponLockTypesGetter(dsprId, signal));
}


export const UPDATE_DSPR_COUPON_LOCK_TYPES = 'UPDATE_DSPR_COUPON_LOCK_TYPES';
export const UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS = 'UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS';
export const UPDATE_DSPR_COUPON_LOCK_TYPES_FAILURE = 'UPDATE_DSPR_COUPON_LOCK_TYPES_FAILURE';

const couponLockUpdater = (values: updateCouponLockTypesProps) => {
    const { dsprCouponLocks, dsprId } = values;
    const dsprCouponLocksForRequest = [];

    for (let key in dsprCouponLocks) {
        if (dsprCouponLocks[key] === 'none') {
            delete dsprCouponLocks[key];
        } else {
            const lockForRequest = {
                dspr: {id: dsprId},
                discountType: key,
                couponLockType: dsprCouponLocks[key],
            };

            dsprCouponLocksForRequest.push(lockForRequest);
        }
    }

    const requestBody = {
        dspr: { id: dsprId },
        dsprCouponLocks: dsprCouponLocksForRequest,
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [UPDATE_DSPR_COUPON_LOCK_TYPES, UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS, UPDATE_DSPR_COUPON_LOCK_TYPES_FAILURE],
            endPoint: ENDPOINT + "/lock",
            schema: Schemas.DSPR_COUPON_LOCKS,
            body: requestBody
        }
    }
}

export type updateCouponLockTypesProps = {
    dsprCouponLocks: { [key in DiscountType]: CouponLockType | 'none' };
    dsprId: number;

};

export const updateCouponLockTypes = (values: updateCouponLockTypesProps) => (dispatch) => {
    return dispatch(couponLockUpdater(values));
}