import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import {
    GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_FAILURE,
    GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS, getProductInventoryTransactionsByInvoice,
    productInventoryTransactionsByInvoiceInterface,
} from "../../actions/dsprProductInventoryActions";

import { useParams } from "react-router-dom";
import InvoicePresentational from "./InvoicePresentational";

const InvoiceContainer = () => {

    const dispatch = useDispatch();
    const { dsprId } = useParams<{ dsprId: string }>();
    const [showInvoiceTransactionHistory, setShowInvoiceTransactionHistory] = useState<boolean>(false);
    const [invoiceNumber, setInvoiceNumber] = useState<string>(null);

    const handleInvoiceSubmit = (values: { invoiceNumber: string }) => {
        const invoiceValues: productInventoryTransactionsByInvoiceInterface = {
            dsprId: parseInt(dsprId),
            invoiceNumber: values.invoiceNumber,
        }
        dispatch<any>(getProductInventoryTransactionsByInvoice(invoiceValues)).then(response => {
            if (response.type === GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS) {
                setShowInvoiceTransactionHistory(true);
                setInvoiceNumber(values.invoiceNumber);
            } else if (response.type === GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_FAILURE) {
            }
        })
    };

    return <InvoicePresentational
        handleInvoiceSubmit={handleInvoiceSubmit}
        showInvoiceTransactionHistory={showInvoiceTransactionHistory}
        invoiceNumber={invoiceNumber}
    />
}


export default InvoiceContainer;