import React, { useState, useEffect, Fragment, createRef, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { getDSPR } from "../actions/dsprActions";
import { getDSP } from "../actions/dspActions";
import {
    createOrModifyCoupon,
    getDSPRCoupons,
    toggleCouponActiveStatus,
    searchDSPRCoupons,
    updateCouponLockTypes, updateCouponLockTypesProps, getCouponLockTypes
} from '../actions/couponActions';
import {
    interceptUserForCouponFormAutocompleteSelected
} from "../actions/formHelperActions";

import { getDSPRFromProps } from "../selectors/dsprSelectors";
import { getCouponLocksForDSPRFromProps, getCouponsForDSPR } from '../selectors/couponSelectors';
import { getProductCategoriesForDSP } from '../selectors/dspProductSelector';
import { getLoggedInUser } from "../selectors/userSelectors";
import {
    State,
    DSPR,
    DspProductCategory,
    Coupon,
    User,
    DsprManager,
    DSPRCouponLockType
} from '../store/reduxStoreState';

import Coupons from "../components/Coupons";
import { canceledFetchErrorMessage } from "../middleware/api";
import { getActiveDSPRManagersForLoggedInUser } from "../selectors/dsprManagerSelectors";

type CouponsWithUser = (Omit<Coupon, 'user'> & { user: User })[];

const DSPRCouponsContainer: React.FC<{}> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableHeight, setTableHeight] = useState(400);
    const pageRef = createRef<HTMLElement>()
    const { dsprId } = useParams<{ dsprId: string }>();
    const dispatch = useDispatch();

    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, { dsprId }), shallowEqual);
    const coupons = useSelector<State, CouponsWithUser>(state => getCouponsForDSPR(state, { dsprId }), shallowEqual);
    const categories = useSelector<State, DspProductCategory[]>(state =>
        dspr && getProductCategoriesForDSP(state, { dspId: dspr.deliveryServiceProvider }), shallowEqual);
    const activeDSPRManagersForUser = useSelector<State, DsprManager[]>(getActiveDSPRManagersForLoggedInUser, shallowEqual);
    const dsprCouponLocks = useSelector<State,  DSPRCouponLockType[]>(state => getCouponLocksForDSPRFromProps(state, dsprId), shallowEqual);

    const isManagerForCurrentDSPR: boolean = activeDSPRManagersForUser && activeDSPRManagersForUser.filter(dsprManager => dsprManager.dspr === parseInt(dsprId)).length > 0
    const loggedInUser = useSelector<State, User>(getLoggedInUser);
    const isSystemAdmin: boolean = loggedInUser && loggedInUser.systemAdministrator;
    const dspId = dspr && dspr.deliveryServiceProvider;
    const abortController = useMemo(() => new AbortController(), []);
    const { signal } = abortController;

    useEffect(() => {
        dsprId && dspId && dispatch<any>(getDSPR(dsprId, signal))
            .then(() => dispatch(getDSP(dspId, signal)))
            .then(() => dispatch(getCouponLockTypes(dsprId, signal)))
            .then((response) => {
                if (!(response.error && response.error.includes(canceledFetchErrorMessage))) {
                    setIsLoading(false)
                }
            });

        return () => abortController.abort();
    }, [dsprId, dspId, dispatch, abortController, signal]);

    const searchCoupons = (partialCode: string) => {
        return dispatch(searchDSPRCoupons(dsprId, partialCode));
    };

    const getAllCoupons = () => {
        return dispatch(getDSPRCoupons(dsprId));
    };

    const toggleCoupon = (couponId:number) => {
        return dispatch(toggleCouponActiveStatus(couponId))
    }

    useEffect(()=> {
        const adjustedHeight = window.innerHeight - (pageRef.current.clientHeight + 220)
        setTableHeight((adjustedHeight < 400 ? 400 : adjustedHeight))
        // eslint-disable-next-line
    },[])

    return <main className="coupons-tab" ref={pageRef}>
        <h2>Coupons</h2>
        <Fragment>
            {isLoading ? <CircularProgress /> :
                <Coupons
                    createCoupon={(...args) => dispatch(createOrModifyCoupon(...args))}
                    updateCoupon={(values: updateCouponLockTypesProps) => dispatch(updateCouponLockTypes(values))}
                    dspr={dspr}
                    coupons={coupons}
                    toggler={toggleCoupon}
                    interceptAutocompleteSelected={users => dispatch(interceptUserForCouponFormAutocompleteSelected(users))}
                    searchCoupons={partialCode => searchCoupons(partialCode)}
                    getAllCoupons={getAllCoupons}
                    categories={categories}
                    tableHeight={tableHeight}
                    isManagerForCurrentDSPR={isManagerForCurrentDSPR}
                    isSystemAdmin={isSystemAdmin}
                    dsprCouponLocks={dsprCouponLocks}
                />}
        </Fragment>
    </main>
}

export default DSPRCouponsContainer;
