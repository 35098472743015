import {TransactionHistoryToDisplay} from "../components/DSPRProductInventoryTransactionHistory";
import {parseDate} from "./util";
import React from "react";

export enum desiredKeyForProductInventoryTransactions {
    invoiceBatchNumber
}
export interface DictionaryTransactionHistory {
    [key: string]: TransactionHistoryToDisplay[] | [] | null;
}
class TransactionsDisplayUtil {

    /**Desired key will become the transaction title, eg: invoiceBatchNumber will be transactionTitle */
    static makeTransactionTitleFromDesiredKey = (
        transaction: TransactionHistoryToDisplay,
        desiredKeyType: desiredKeyForProductInventoryTransactions
    ): TransactionHistoryToDisplay => {

        let desiredKey = "Failed To get Desired key";
        switch (desiredKeyType) {
            case desiredKeyForProductInventoryTransactions.invoiceBatchNumber: // this is used in invoiceNumber
                desiredKey = TransactionsDisplayUtil.returnDesiredKeyOrEmptyMessage(transaction.batchNumber, "No Batch Number");
                break;
        }
        transaction.transactionTitle = `${desiredKey}`;
        return transaction;

    }

    static returnDesiredKeyOrEmptyMessage = (key: string, messageIfEmpty: string) => {
        if (typeof key === "undefined") {
            return messageIfEmpty
        }
        return key;
    }

    static formatToHundrethsPlace = (num: number) => num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    static dateCellRenderer = ({cellData}) => {
        const date = parseDate(cellData);
        const dateToDisplay = date.toLocaleDateString('en-US');
        const timeAndDateToDisplay = date.toLocaleString('en-US');
        return <span title={timeAndDateToDisplay}>{dateToDisplay}</span>
    }

    /**Reversing causes time to show up from later date to earlier date*/
    static reverseTransactionDisplaysForDictionary = (
        transactionDisplaysDictAccumulator: DictionaryTransactionHistory
    ): DictionaryTransactionHistory => {
        const keyArray = Object.keys(transactionDisplaysDictAccumulator);
        keyArray.forEach(key => {
            transactionDisplaysDictAccumulator[key] = transactionDisplaysDictAccumulator[key].reverse();
        })
        return transactionDisplaysDictAccumulator;
    }

    /** This dictionary is used to separate transactions based on their key,
     * eg: here the key is a batch number
     * a new table will be created for each list of transactions with batch numbers
     * */
    static appendOrCreateTransactionDisplaysDictionary = (
        transactionDisplaysDictAccumulator: DictionaryTransactionHistory,
        transactionDisplaysAccumulator: TransactionHistoryToDisplay[],
        transaction: TransactionHistoryToDisplay,
        formatTransactionForDisplay: (transaction: TransactionHistoryToDisplay)=> TransactionHistoryToDisplay
    ): void => {

        let transactionDisplays = [] as TransactionHistoryToDisplay[];

        const key:string = transaction.transactionTitle;

        if (!transactionDisplaysDictAccumulator[key]) {
            transactionDisplaysAccumulator.push(formatTransactionForDisplay(transaction));
            transactionDisplaysDictAccumulator[key] = transactionDisplaysAccumulator;
        } else {
            transactionDisplays = transactionDisplaysDictAccumulator[key];
            transactionDisplays.push(formatTransactionForDisplay(transaction));
            transactionDisplaysDictAccumulator[key] = transactionDisplays;
        }
    }
}

export default TransactionsDisplayUtil;