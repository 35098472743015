import React, { useCallback, useEffect, createRef, useState, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DSPROrderHistory from "../components/DSPROrderHistory";

import { getDSPROrderHistory, getDSPR } from "../actions/dsprActions"
import { getOrderHistoryForDSPR } from "../selectors/dsprSelectors";
import { Order, Address, User, State, DspProduct, DsprDriver } from '../store/reduxStoreState';
import { modifyOrder, MODIFY_ORDER_SUCCESS, MODIFY_ORDER_FAILURE, getOrderCost, GET_ORDER_COST_FAILURE } from '../actions/orderActions';
import { getDSPProductFromProps } from '../selectors/dspProductSelector';
import { getProduct, GET_PRODUCT_FAILURE } from '../actions/dspProductActions';
import { getDriversForDSPR } from '../selectors/dsprDriverSelector';
import { getDSPRCurrentInventory } from '../actions/dsprProductInventoryActions';
import { getDSPRDriver, GET_DSPR_DRIVER_SUCCESS } from '../actions/driverActions';

type OrderHistory = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User, driver: any })[];
type DsprDriversWithUserAndOrders = (Omit<DsprDriver, 'user'> & { user: User })[];

const DSPROrderHistoryContainer: React.FC<{}> = () => {
    const { dsprId } = useParams<{dsprId: string}>();
    const dispatch = useDispatch();
    const dsprDrivers = useSelector<State, DsprDriversWithUserAndOrders>(state => getDriversForDSPR(state, { dsprId }), shallowEqual)
    const orderHistory = useSelector<State, OrderHistory>(state => getOrderHistoryForDSPR(state, { dsprId }), shallowEqual);
    const getProductById = useSelector<State, (productId: number) => DspProduct>(state => productId => getDSPProductFromProps(state, { productId }), shallowEqual);

    const [tableHeight, setTableHeight] = useState(400);
    const pageRef = createRef<HTMLElement>();
    const abortController = useMemo(() => new AbortController(), []);
    const { signal } = abortController;

    /**Provides a clean-up function to cancel fetch requests when component unmounts*/
    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, [abortController])

    const submitOrderHistoryQuery = useCallback((beginDayTimestamp: string, endDayTimestamp: string, orderStatus: string) => {
        return dispatch(getDSPROrderHistory(dsprId, beginDayTimestamp, endDayTimestamp, orderStatus, signal));
    }, [dispatch, dsprId, signal]);

    const handleModifyOrder = (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]): Promise<string> => {
        return dispatch<any>(modifyOrder(orderId, orderDetails, newDriverId,transferInventoryOnDriverchange, couponCodes))
            .then(response => {
                if (response.type === MODIFY_ORDER_SUCCESS) {
                    return false;
                } else if (response.type === MODIFY_ORDER_FAILURE) {
                    const errorMessage = response.error;
                    if (errorMessage) {
                        const notSufficientInventoryChecker = new RegExp(/Driver '[0-9]*\.*[0-9]*' does not have sufficient inventory of product '[0-9]*\.*[0-9]*' to fulfill the requested order./g)
                        const splittedErrorMessage = errorMessage.split("'");
                        const insufficientProductId = splittedErrorMessage && splittedErrorMessage[3];
                        if (insufficientProductId && notSufficientInventoryChecker.test(errorMessage)) {
                            return `The new driver does not have sufficient inventory of product "
                            ${getProductById(insufficientProductId).name}" to fulfill the requested order.`;
                        } else {
                            return errorMessage;
                        }
                    } else {
                        return 'An error occurred during the transfer';
                    }
                }
            });
    }

    const handleGetProductRequest = (productId: number) => {
        return dispatch<any>(getProduct(productId))
            .then(response => {
                if (response.type === GET_PRODUCT_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.dspProducts &&
                        response.response.entities.dspProducts[productId];
                }
            })
    }

    const handleOrderCostCalculateRequest = order => {
        return dispatch<any>(getOrderCost(order))
            .then(response => {
                if (response.type === GET_ORDER_COST_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.orders &&
                        response.response.entities.orders[0];
                }
            })
    }

    const handleGetDSPRDriverRequest = driverId => {
        return dispatch<any>(getDSPRDriver(driverId)).then(response => {
            if (response.type === GET_DSPR_DRIVER_SUCCESS) {
                return response;
            } else {
                return response.error;
            }
        })
    }

    useEffect(() => {
        dispatch(getDSPR(dsprId));
        dispatch(getDSPRCurrentInventory(dsprId));
    }, [dispatch, dsprId]);

    useEffect(()=> {
        const adjustedHeight = window.innerHeight - (pageRef.current.clientHeight + 160)
        setTableHeight((adjustedHeight < 400 ? 400 : adjustedHeight))
        // eslint-disable-next-line
    },[])

    return <main className="order-history-tab" ref={pageRef}>
        <h2>Orders</h2>
        <DSPROrderHistory
            dsprId={dsprId}
            submitOrderHistoryQuery={submitOrderHistoryQuery}
            orderHistory={orderHistory}
            calculateOrderTotal={handleOrderCostCalculateRequest}
            getProductWithId={handleGetProductRequest}
            modifyOrder={handleModifyOrder}
            dsprDrivers={dsprDrivers}
            getDSPRDriver={handleGetDSPRDriverRequest}
            availableInventory={[]}
            tableHeight={tableHeight}
        />
    </main>
}

export default DSPROrderHistoryContainer;
