import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import api from '../middleware/api'
import rootReducer from '../reducers/index';
import Raven from 'raven-js';

Raven.config(process.env.REACT_APP_ENABLE_SERVER_SENTRY_LOGGING === "true" ? 'https://385793bf258c4fceaf031d173e44fd5b@sentry.io/1249317': null, {
    environment: 'prod'
}).install();


const configureStore = preloadedState => createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, api)
);

export default configureStore
