import { CALL_API } from "../middleware/api"

export const SYSTEM_ADMIN_BUTTON = 'SYSTEM_ADMIN_BUTTON';
export const SYSTEM_ADMIN_BUTTON_SUCCESS = 'SYSTEM_ADMIN_BUTTON_SUCCESS';
export const SYSTEM_ADMIN_BUTTON_FAILURE = 'SYSTEM_ADMIN_BUTTON_FAILURE';

const specialAdminButton = () => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [SYSTEM_ADMIN_BUTTON, SYSTEM_ADMIN_BUTTON_SUCCESS, SYSTEM_ADMIN_BUTTON_FAILURE],
            endPoint: `data_consistency_controller`,
            schema: Boolean,
        }
    }
}

export const pressSpecialAdminButton = () => dispatch => {
    return dispatch(specialAdminButton());
}

export const SECOND_SYSTEM_ADMIN_BUTTON = 'SECOND_SYSTEM_ADMIN_BUTTON';
export const SECOND_SYSTEM_ADMIN_BUTTON_SUCCESS = 'SECOND_SYSTEM_ADMIN_BUTTON_SUCCESS';
export const SECOND_SYSTEM_ADMIN_BUTTON_FAILURE = 'SECOND_SYSTEM_ADMIN_BUTTON_FAILURE';

const secondSpecialAdminButton = () => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [SECOND_SYSTEM_ADMIN_BUTTON, SECOND_SYSTEM_ADMIN_BUTTON_SUCCESS, SECOND_SYSTEM_ADMIN_BUTTON_FAILURE],
            endPoint: `data_consistency_controller/second`,
            schema: Boolean,
        }
    }
}

export const pressSecondSpecialAdminButton = () => dispatch => {
    return dispatch(secondSpecialAdminButton());
}

export const THIRD_SYSTEM_ADMIN_BUTTON = 'THIRD_SYSTEM_ADMIN_BUTTON';
export const THIRD_SYSTEM_ADMIN_BUTTON_SUCCESS = 'THIRD_SYSTEM_ADMIN_BUTTON_SUCCESS';
export const THIRD_SYSTEM_ADMIN_BUTTON_FAILURE = 'THIRD_SYSTEM_ADMIN_BUTTON_FAILURE';

const thirdSpecialAdminButton = () => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [THIRD_SYSTEM_ADMIN_BUTTON, THIRD_SYSTEM_ADMIN_BUTTON_SUCCESS, THIRD_SYSTEM_ADMIN_BUTTON_FAILURE],
            endPoint: `data_consistency_controller/third`,
            schema: Boolean,
        }
    }
}

export const pressThirdSpecialAdminButton = () => dispatch => {
    return dispatch(thirdSpecialAdminButton());
}

export const CLEAN_DATABASE_ADMIN_BUTTON = 'CLEAN_DATABASE_ADMIN_BUTTON';
export const CLEAN_DATABASE_ADMIN_BUTTON_SUCCESS = 'CLEAN_DATABASE_ADMIN_BUTTON_SUCCESS';
export const CLEAN_DATABASE_ADMIN_BUTTON_FAILURE = 'CLEAN_DATABASE_ADMIN_BUTTON_FAILURE';

const cleanDatabaseAdminButton = () => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CLEAN_DATABASE_ADMIN_BUTTON, CLEAN_DATABASE_ADMIN_BUTTON_SUCCESS, CLEAN_DATABASE_ADMIN_BUTTON_FAILURE],
            endPoint: `data_consistency_controller/clean-database`,
            schema: Boolean,
        }
    }
}

export const pressCleanDatabaseAdminButton = () => dispatch => {
    return dispatch(cleanDatabaseAdminButton());
}