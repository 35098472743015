import { createSelector } from 'reselect';
import { getLoggedInUser, getUsers } from './userSelectors';
import { getDSPs, getDSPFromProps } from './dspSelectors';
import { State } from '../store/reduxStoreState';

export const getDSPManagers = (state: State) => {
    return state.api.entities.dspManagers;
};


export const getActiveDSPManagersForLoggedInUser = createSelector(
    [getDSPManagers, getLoggedInUser], (dspManagers, loggedInUser) => {
        return loggedInUser ?
            dspManagers ? loggedInUser.deliveryServiceProviderManagers.filter(managerId => dspManagers[managerId].active).map(managerId => dspManagers[managerId])
                : undefined
            : undefined
    }
);

export const getManagedDSPsForLoggedInUser = createSelector(
    [getActiveDSPManagersForLoggedInUser, getDSPs], (activeManagers, dsps) => {
        if (!activeManagers) return undefined;
        return activeManagers.map(manager => (dsps[manager.deliveryServiceProvider]));
    }
);

export const getManagedDSPIdsForLoggedInUser = createSelector(
    [getManagedDSPsForLoggedInUser], (managedDSPs) => {
        if (!managedDSPs) return [];
        return managedDSPs.map(dsp => dsp.id);
    }
);

export const getManagersForDSP = createSelector(
    [getDSPFromProps, getDSPManagers, getUsers], (deliveryServiceProvider, dspManagers, users) => {
        return deliveryServiceProvider ?
            deliveryServiceProvider.managers ?
                dspManagers ?
                    deliveryServiceProvider.managers.map(managerId => dspManagers[managerId])
                        .map(manager => {
                            return { user: users[manager.managerUser], active: manager.active, id: manager.id }
                        })
                    : []
                : []
            : undefined;
    }
);

export const getActiveManagersForDSP = createSelector(
    [getDSPFromProps, getDSPManagers, getUsers], (deliveryServiceProvider, dspManagers, users) => {
        return deliveryServiceProvider ?
            dspManagers ?
                deliveryServiceProvider.managers.map(managerId => dspManagers[managerId]).filter(manager => manager.active)
                    .map(manager => {
                        return { user: users[manager.managerUser], active: manager.active, id: manager.id }
                    })
                : []
            : undefined;
    }
);