import { useCallback, useEffect, useState } from "react";
import {
    DSPRProductInventoryTransactionHistory,
    DSPRProductInventoryTransactionHistory as DSPRProductTransactionHistory,
    DspProduct,
    State
} from "../../store/reduxStoreState";
import { TransactionHistoryToDisplay } from "../../components/DSPRProductInventoryTransactionHistory";
import { shallowEqual, useSelector } from "react-redux";
import { getDSPRProductInventoryTransactions } from "../../selectors/dsprProductInventoryTransactionSelector";
import TransactionsDisplayUtil, {
    desiredKeyForProductInventoryTransactions,
    DictionaryTransactionHistory
} from "../TransactionsDisplayUtil";
import { getDSPProducts } from "../../selectors/dspProductSelector";

interface FinalValuesInterface {
    finalQuantity: number;
    finalCost: number;
}

export const useProductInventoryTransactionDisplay2D =
    (desiredKeyType: desiredKeyForProductInventoryTransactions):
        [DictionaryTransactionHistory, FinalValuesInterface] => {
        const {
            makeTransactionTitleFromDesiredKey,
            formatToHundrethsPlace,
            reverseTransactionDisplaysForDictionary,
            appendOrCreateTransactionDisplaysDictionary
        } = TransactionsDisplayUtil;

        const getDSPRProductInventory = useSelector<State, { [key: string]: { [key: number]: DSPRProductTransactionHistory } }>(
            state => getDSPRProductInventoryTransactions(state
            ), shallowEqual);

        const dspProducts = useSelector<State, { [key: string]: DspProduct }>(getDSPProducts, shallowEqual);
        const [finalValues, setFinalValues] = useState<FinalValuesInterface>({
            finalQuantity: 0,
            finalCost: 0
        });

        const [transactionHistoryToDisplayDictionary, setTransactionHistoryToDisplayDictionary] = useState<DictionaryTransactionHistory>(null);

        /**for 2 dimensional object eg: invoice and batch number*/
        const createTransactionDisplays2DCallBack = useCallback((): DictionaryTransactionHistory => {

            let cumulativeQuantity = 0;
            let cumulativeCost = 0;

            const transactionDisplaysDictAccumulator: DictionaryTransactionHistory = {};
            //eg key2D is invoice number, we then separate by batch number with a dictionary
            for (let key2D in getDSPRProductInventory) {
                for (let idOfTransaction in getDSPRProductInventory[key2D]) {

                    const transactionWithoutTitle = { ...getDSPRProductInventory[key2D][Number(idOfTransaction)] };
                    const transaction = makeTransactionTitleFromDesiredKey(transactionWithoutTitle, desiredKeyType);

                    const transactionDisplaysAccumulator = [] as TransactionHistoryToDisplay[];
                    if (dspProducts[transaction.product]) transaction.productName = dspProducts[transaction.product].name;

                    transaction.unitCost = transaction.totalCost / transaction.quantity;

                    //If totalCost and Quantity are 0, there is most likely a problem with the data.
                    if (transaction.totalCost === 0 && transaction.quantity === 0) {
                        transaction.cumulativeCost = cumulativeCost
                        transaction.cumulativeQuantity = cumulativeQuantity;
                        transactionDisplaysAccumulator.push(formatTransactionForDisplay(transaction));
                        continue;
                    }

                    //Update cumulativeCost and cumulativeQuantity
                    cumulativeCost += transaction.totalCost;
                    transaction.cumulativeCost = cumulativeCost;

                    cumulativeQuantity += transaction.quantity;
                    transaction.cumulativeQuantity = cumulativeQuantity;

                    appendOrCreateTransactionDisplaysDictionary(
                        transactionDisplaysDictAccumulator,
                        transactionDisplaysAccumulator,
                        transaction,
                        formatTransactionForDisplay
                    );
                }
            }

            setFinalValues({
                finalQuantity: cumulativeQuantity,
                finalCost: cumulativeCost
            });

            return reverseTransactionDisplaysForDictionary(transactionDisplaysDictAccumulator);
        }, [getDSPRProductInventory]);

        const formatTransactionForDisplay = (transaction: TransactionHistoryToDisplay) => {
            //    Transaction Correction has the transaction type of stockAdjustment. This is used for both quantity and cost
            //    if transactionType === stockAdjustment and totalCost === 0 -> put this in the quantity column quantity, otherwise put it in totalCost
            //    if totalCost && quantity === 0, render '-' for totalCost, quantity,
            transaction.quantityToDisplay = '-';
            transaction.totalCostToDisplay = '-';
            transaction.unitCostToDisplay = '-';

            if (transaction.totalCost !== 0 && transaction.quantity !== 0) {
                transaction.quantityToDisplay = transaction.quantity;
                transaction.totalCostToDisplay = `$${formatToHundrethsPlace(transaction.totalCost)}`;
                transaction.unitCostToDisplay = `$${formatToHundrethsPlace(transaction.unitCost)}`;
            } else if (transaction.totalCost === 0 && transaction.transactionType === 'stockAdjustment') {
                //Transaction quantity correction
                transaction.quantityToDisplay = transaction.quantity;
            } else if (transaction.quantity === 0 && transaction.transactionType === 'stockAdjustment') {
                //Transaction cost correction
                transaction.totalCostToDisplay = `$${formatToHundrethsPlace(transaction.totalCost)}`;
            }
            return transaction;
        }

        useEffect(() => {
            if (getDSPRProductInventory) {
                setTransactionHistoryToDisplayDictionary(createTransactionDisplays2DCallBack());
            }
        }, [getDSPRProductInventory])

        return [transactionHistoryToDisplayDictionary, finalValues];

    }
