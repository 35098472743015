import React, { useState, useEffect, useCallback, Fragment } from 'react';

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import {
    Card, CardContent, Select, MenuItem, Button, Dialog,
    FormControl, InputLabel, CardActions, CircularProgress, FormControlLabel, Checkbox, Collapse, Typography, FormLabel,CardHeader
} from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';

import { currentDSPROrderHistoryCSVDownloadLink } from "../actions/dsprActions";
import {
    Order,
    Address,
    User,
    DspProduct,
    DsprDriver,
    DsprDriverInventoryItem,
    State,
    DSPR
} from '../store/reduxStoreState';

import { parseDate, getMySQLDateStringFromTimestamp } from '../util/util';

import VirtualizedTable from "./VirtualizedTable";
import OrderWithDetailsAndPrices from '../containers/OrderWithDetailsAndPrices';

import './dsprOrderHistoryStyles.scss';
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {API_ROOT, canceledFetchErrorMessage, orderStatus} from "../middleware/api";
import OrderReportForm from '../containers/OrderReportForm';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../actions/oauthActions';
import {shallowEqual, useSelector} from "react-redux";
import {getDSPRFromProps} from "../selectors/dsprSelectors";

type AvailableInventoryItem = Omit<DsprDriverInventoryItem, 'product'> & { product: DspProduct };
type DsprDriversWithUserAndOrders = (Omit<DsprDriver, 'user'> & { user: User })[];

interface DSPROrderHistoryProps {
    submitOrderHistoryQuery: (beginDayTimestamp: string, endDayTimestamp: string, orderStatus: string) => any;
    orderHistory: (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User, driver: any })[];
    dsprId: String;
    getDSPRDriver: (driverId: number) => Promise<boolean>;
    calculateOrderTotal: (order: any) => Promise<string | Order>;
    getProductWithId: (productId: number) => Promise<string | DspProduct>;
    dsprDrivers: DsprDriversWithUserAndOrders;
    availableInventory: AvailableInventoryItem[];
    modifyOrder: (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => Promise<string>;
    tableHeight: number;
}

type OrderForDialog = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User, driver: any });
const presetDateRanges = { TODAY: 'TODAY', LAST_7: 'LAST_7', CUSTOM: "CUSTOM" };


const DSPROrderHistory: React.FC<DSPROrderHistoryProps> = props => {
    const { submitOrderHistoryQuery, orderHistory, dsprId, getDSPRDriver,
        calculateOrderTotal, getProductWithId, dsprDrivers, availableInventory, modifyOrder, tableHeight
    } = props;
    const initialDate = new Date();

    const [dateRange, setDateRange] = useState({
        beginDayTimestamp: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
        endDayTimestamp: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate())
    });
    const dspr = useSelector<State, DSPR>(
        (state) => getDSPRFromProps(state, { dsprId }),
        shallowEqual
    );
    const [pickerBeginDate, setPickerBeginDate] = useState(new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()))
    const [pickerEndDate, setPickerEndDate] = useState(new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()))

    const [showDateRangeForm, setShowDateRangeForm] = useState(false);

    const [driversForDSPR, setDriversForDSPR] = useState<DsprDriversWithUserAndOrders>(dsprDrivers.length === 0 ? [] : dsprDrivers)
    const [filteredOrders, setFilteredOrders] = useState(undefined);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(orderStatus.all);
    const [dateRangeDropdownValue, setDateRangeDropdownValue] = useState(presetDateRanges.TODAY);
    const [showDialog, setShowDialog] = useState(false);
    const [orderForDialog, setOrderForDialog] = useState<OrderForDialog>(null);
    const [showLoader, setShowLoader] = useState(false);

    const [onlyShowQueuedOrInProcess, setOnlyShowQueuedOrInProcess] = useState(false);

    const [showFilters, setShowFilters] = useState(false);

    const [showModifySuccessModal, setShowModifySuccessModal] = useState(false);

    const initialFilterDiscountTypeState = {all: false, completed: false, modified: false,
        canceled: false, inProcess: false, queued: false,
        received:false, allotmentConfirmed:false, cancelledInsufficientAllotment:false,
        packaged:false, inRoute:false};
    const [filterDiscountTypeRadioSelection, setFilterDiscountTypeRadioSelection] = useState<'all' | 'some'>('all');
    const [filterDiscountTypeChecked, setFilterDiscountTypeChecked] = useState(initialFilterDiscountTypeState);
    const [filterOrderStatusType, setFilterOrderStatusType] = useState(initialFilterDiscountTypeState);
    const handleChangeCheckboxDiscountType = (evt) => {
        const selection = evt.target.value;
        const isChecked = evt.target.checked;

        if (filterDiscountTypeRadioSelection === 'all') setFilterDiscountTypeRadioSelection('some');

        //update checked state for the checkbox that was just checked
        setFilterDiscountTypeChecked({...filterDiscountTypeChecked, [selection]: isChecked});
        //update state to filter by selected discount value
        setFilterOrderStatusType({...filterOrderStatusType, [selection]: isChecked});
    }
    //todo:convert your order status to this taken from coupon, as there appears to be some no mixed operators and this appears to be a better way of doing it


    const [showCustomOrderReportDialog, setShowCustomOrderReportDialog] = useState(false);

    const closeCustomOrderReportDialog = () => {
        setShowCustomOrderReportDialog(false);
    }

    const handleShowCustomOrderForm = () => {
        setShowCustomOrderReportDialog(true);
    }

    const handleCustomOrderReport = (formValues) => {
        let rows = undefined;
        let orderStatuses = undefined;
        let beginningTimestamp = undefined
        let endTimestamp = undefined;

        Object.entries(formValues).map(([key, value]) => {
            if(value) {
                switch (key) {
                    case "endDayTimestamp":
                        endTimestamp=getMySQLDateStringFromTimestamp(value);
                        break;
                    case "beginDayTimestamp":
                        beginningTimestamp=getMySQLDateStringFromTimestamp(value)
                        break;
                    case "received":
                    case "allotment_confirmed":
                    case "cancelled_insufficient_allotment":
                    case "packaged":
                    case "queued":
                    case "in-process":
                    case "in_route":
                    case "completed":
                    case "canceled":
                    case "modified":
                        if(orderStatuses) orderStatuses += "," + key;
                        else orderStatuses = key;
                        break;
                    default:
                        if(rows) rows += "," + key;
                        else rows = key;
                        break;
                }
            }
        });



        let url = API_ROOT + `order/history/download-order-report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}&begin_day_timestamp=${encodeURIComponent(beginningTimestamp)}&end_day_timestamp=${encodeURIComponent(endTimestamp)}&dspr_id=${dsprId}`;
        if(rows != undefined) url += "&rows=" + encodeURIComponent(rows);
        if(orderStatuses != undefined) url += "&order_statuses=" + encodeURIComponent(orderStatuses)
        window.open(url + "&_blank");
        closeCustomOrderReportDialog();
    }

    const handleDateRangeChange = (event: React.ChangeEvent<{ name?: string, value: any }>) => setDateRangeDropdownValue(event.target.value);

    const handleStatusFilterChange = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        setSelectedOrderStatus(event.target.value);
    };
    const handleSubmitOrderHistoryQuery = useCallback(() => {
        setShowLoader(true);
        submitOrderHistoryQuery(getMySQLDateStringFromTimestamp(dateRange.beginDayTimestamp), getMySQLDateStringFromTimestamp(dateRange.endDayTimestamp), undefined)
            .then((response) => {
                if (!(response.error && response.error.includes(canceledFetchErrorMessage))) {
                    setShowLoader(false)
                }
            });
    }, [dateRange, submitOrderHistoryQuery]);

    const handleDownloadOrderHistoryCSV = useCallback(() => {
        const url = currentDSPROrderHistoryCSVDownloadLink(
            dsprId,
            getMySQLDateStringFromTimestamp(dateRange.beginDayTimestamp),
            getMySQLDateStringFromTimestamp(dateRange.endDayTimestamp),
            undefined
        );
        var win = window.open(url, '_blank');
        win.focus();
    }, [dateRange, dsprId]);

    const generateOrderRow = (order, style: any) => {
        const createdDate = parseDate(order.createdTime);
        const updatedDate = parseDate(order.updatedTime);
        const { user, driver } = order;
        const statusChangeMinutes = Math.round((parseDate(order.updatedTime).valueOf() - parseDate(order.createdTime).valueOf()) / 1000 / 60);
        const promoCodes = order.coupons ? order.coupons.map(coupon => coupon.code).join("\n") : "";

        const processOrderStatus = orderStatus => orderStatus === 'in_process' ? 'In Process' : orderStatus.substring(0, 1).toUpperCase() + orderStatus.substring(1);

        //old way of formatting data for 'Order Date (3rd column):
        //<span>{createdDate.toLocaleString("en-us", { month: "long" })} {createdDate.getDate()}, {createdDate.getFullYear()}</span>

        //old way of formatting order completed date (5th column):
        //<span>{order.orderStatus === "completed" ? updatedDate.toLocaleString("en-us", { month: "long" }) + " " + updatedDate.getDate() + ", " + updatedDate.getFullYear() : ""}</span>

        return <div
            style={style}
            className='order-row'
            key={order.id}
            onClick={() => { setShowDialog(true); setOrderForDialog(order) }}
        >
            <span>{user.firstName} {user.lastName}</span>
            <span>{order.id}</span>
            <span>{createdDate.toLocaleString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit'})}</span>
            <span>{createdDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
            <span>{order.orderStatus === "completed" ? updatedDate.toLocaleString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit'}) : "-"}</span>
            <span>{processOrderStatus(order.orderStatus)}</span>
            {dspr.currentFullMenuDriver !== driver.id ? <span>{driver.firstName} {driver.lastName}</span> : <span>To Assign</span>}
            <span>{statusChangeMinutes}</span>
            <span>${order.cashTotal}</span>
            <span>{order.orderStatus === 'completed' ? `$${order.grossProfit}` : '-'}</span>
            <span>{order.orderStatus === 'completed' ?  `${order.grossProfitMargin}%` : '-'}</span>
            <span>{promoCodes.toUpperCase() || '-'}</span>
            <span>${order.discountTotal}</span>
        </div>
    }

    const orderRows = ({ index, style }, filteredOrders, filteredOrderKeys) => {
        if (!filteredOrders || (filteredOrders && filteredOrderKeys.length === 0)) return null;

        return generateOrderRow(filteredOrders[filteredOrderKeys[index]], style);
    }

    const handleModifyOrder = (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => {
        return modifyOrder(orderId, orderDetails, newDriverId,transferInventoryOnDriverchange, couponCodes)
            .then(modificationFailedMessage => {
                if (!modificationFailedMessage) {
                    setShowModifySuccessModal(true);
                    closeOrderDetailsForm();
                } else {
                    return modificationFailedMessage;
                }
            });
    }

    const closeOrderDetailsForm = () => {
        setShowDialog(false);
        setOrderForDialog(null);
    }

    useEffect(() => {
        if (dateRangeDropdownValue === presetDateRanges.CUSTOM) {
            setShowDateRangeForm(true);
        } else {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const beginDate = dateRangeDropdownValue === presetDateRanges.LAST_7 ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7) : today;
            const newDateRange = {
                beginDayTimestamp: beginDate,
                endDayTimestamp: today,
            }

            // Avoid redundant calls to the API if the values within the date range are equal to what they were
            if (JSON.stringify(newDateRange) !== JSON.stringify(dateRange)) setDateRange(newDateRange);

            setPickerBeginDate(beginDate);
            setPickerEndDate(today);
            setShowDateRangeForm(false);
        }
    }, [dateRange, dateRangeDropdownValue]);

    useEffect(() => {
        handleSubmitOrderHistoryQuery();
    }, [handleSubmitOrderHistoryQuery]);

    useEffect(() => {

        const discountTypes = [];
        for (let key in filterOrderStatusType) {
            if (filterOrderStatusType[key] === true){
                discountTypes.push(key);
            }
        }

        if (orderHistory) {
            if(discountTypes.length > 0){
            const filteredOrderHistory = orderHistory.filter(order => {
                if(filterOrderStatusType.received && order.orderStatus === orderStatus.received){
                    return order;
                }
                if(filterOrderStatusType.allotmentConfirmed && order.orderStatus === orderStatus.allotmentConfirmed){
                    return order;
                }
                if(filterOrderStatusType.cancelledInsufficientAllotment && order.orderStatus === orderStatus.cancelledInsufficientAllotment){
                    return order;
                }
                if(filterOrderStatusType.queued && order.orderStatus === orderStatus.queued){
                    return order;
                }
                if(filterOrderStatusType.packaged && order.orderStatus === orderStatus.packaged){
                    return order;
                }
                if(filterOrderStatusType.inProcess && order.orderStatus === orderStatus.inProcess){
                    return order;
                }
                if(filterOrderStatusType.inRoute && order.orderStatus === orderStatus.inRoute){
                    return order;
                }
                if(filterOrderStatusType.completed && order.orderStatus === orderStatus.completed){
                    return order;
                }
            }
            )
            setFilteredOrders(filteredOrderHistory);
            }else{
                setFilteredOrders(
                        orderHistory.filter(order => selectedOrderStatus === orderStatus.all || order.orderStatus === selectedOrderStatus)
                    );
            }
        }

    }, [filterOrderStatusType,orderHistory, selectedOrderStatus, onlyShowQueuedOrInProcess]);

    useEffect(()=> {
        if (dsprDrivers.length !== 0) setDriversForDSPR(dsprDrivers)
    },[dsprDrivers])

    return <Fragment>
        <Card>
            <CardContent>
                <CardActions>
                    <div>
                        <Button color="primary" variant="contained" onClick={() => handleSubmitOrderHistoryQuery()}>Reload Order History</Button>
                        <Button color="primary" variant="contained" onClick={() => handleDownloadOrderHistoryCSV()}>Download Order History</Button>
                        <Button color="primary" variant="contained" onClick={() => handleShowCustomOrderForm()}>Download Custom Order Report</Button>
                    </div>
                    <div>
                        <FormControl>
                            <InputLabel id="date-range-change-input-label">Date Range</InputLabel>
                            <Select
                                labelId="date-range-change-input-label"
                                value={dateRangeDropdownValue}
                                onChange={handleDateRangeChange}>
                                <MenuItem value={presetDateRanges.TODAY}>Today</MenuItem>
                                <MenuItem value={presetDateRanges.LAST_7}>Last 7 Days</MenuItem>
                                <MenuItem value={presetDateRanges.CUSTOM}>Custom</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <InputLabel id="status-filter-change-input-label">Status</InputLabel>
                            <Select
                                labelId="status-filter-change-input-label"
                                value={selectedOrderStatus}
                                onChange={handleStatusFilterChange}>
                                <MenuItem value={orderStatus.all}>All</MenuItem>
                                <MenuItem value={orderStatus.completed}>Completed</MenuItem>
                                <MenuItem value={orderStatus.canceled}>Cancelled</MenuItem>
                                {/*<MenuItem value={orderStatus.modified} primaryText="Modified"/>*/}
                            </Select>
                        </FormControl>

                        {/*Order Filters for Table*/}
                        {/*{orderHistory && orderHistory.length > 0 &&*/}
                        <div className={'filters-container'}>

                            <div className={'filters-container-header'} onClick={() => setShowFilters(!showFilters)}>
                                <Typography>Filters</Typography>
                                {showFilters ? <ExpandLess/> : <ExpandMore/>}
                            </div>
                        <Collapse in={showFilters}>
                            <FormControl component={'fieldset'} className={'filter-fieldset'}>
                            <FormLabel component={'legend'}>Order Status</FormLabel>
                        {/*<FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.absolute} value={'absolute'} onChange={handleChangeCheckboxDiscountType} name='Absolute' />}
                                    label={'Absolute'}
                                />*/}
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.received} value={'received'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Received"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.allotmentConfirmed} value={'allotmentConfirmed'}onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Allotment Confirmed"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.cancelledInsufficientAllotment} value={'cancelledInsufficientAllotment'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Allotment Cancelled Insufficient"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.queued}  value={'queued'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Queued"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.packaged} value={'packaged'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Packaged"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.inProcess} value={'inProcess'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="In Process"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.inRoute}  value={'inRoute'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="In Route"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterDiscountTypeChecked.completed} value={'completed'} onChange={handleChangeCheckboxDiscountType} color="primary" />}
                            label="Complete"
                        />
                            </FormControl>
                        </Collapse>
                    </div>
                        {/*}*/}
                    </div>

                    {showDateRangeForm && <div className="date-form">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {/* KNOWN BUG: selecting a date from the popout may cause timezone issues. No issues with manually entered date */}
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="start-date"
                                label="Start Date"
                                className="start-date"
                                maxDate={pickerEndDate || null}
                                value={pickerBeginDate || null}
                                onChange={(newDate: any) => setPickerBeginDate(
                                    (!parseDate(newDate) || !pickerEndDate || newDate <= pickerEndDate) ? newDate : null,
                                )}
                                KeyboardButtonProps={{
                                    'aria-label': 'change start date',
                                }}
                            />
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="end-date"
                                label="End Date"
                                className="end-date"
                                minDate={pickerBeginDate || null}
                                maxDate={new Date()}
                                value={pickerEndDate || null}
                                onChange={(newDate: any) => setPickerEndDate(
                                    (!parseDate(newDate) || !pickerBeginDate || newDate >= pickerBeginDate) ? newDate : null,
                                )}
                                KeyboardButtonProps={{
                                    'aria-label': 'change end date',
                                }}
                            />
                        </MuiPickersUtilsProvider>

                        {pickerBeginDate && pickerEndDate && parseDate(pickerBeginDate) && parseDate(pickerEndDate) ? (
                            <Button variant="contained" color="primary" onClick={() => setDateRange({
                                beginDayTimestamp: pickerBeginDate,
                                endDayTimestamp: pickerEndDate
                            })}>
                                Load Orders
                            </Button>
                        ) : <Button variant="contained" color="primary" disabled>Dates Not Valid</Button>}
                    </div>}
                    {/* <Button variant="contained" color="primary" onClick={handleSubmitOrderHistoryQuery}>Reload Order History</Button> */}
                </CardActions>

                {showLoader ? <CircularProgress /> :
                    (filteredOrders && filteredOrders.length) ? <Fragment>
                        <h5>Click on an order to get the details. Delivery Minutes means time to cancellation if the order was canceled.</h5>
                        <VirtualizedTable
                            tableClasses="order-history-table"
                            headerClasses="order-history-headers"
                            tableContentClasses="order-history-content"
                            itemCount={filteredOrders ? Object.keys(filteredOrders).length : 0}
                            maxHeight={tableHeight ? tableHeight : 400}
                            header={[
                                "User",
                                "Order Number",
                                "Order Date",
                                "Time",
                                "Completed Date",
                                "Status",
                                "Driver",
                                "Delivery Minutes",
                                "Total",
                                "Gross Profit",
                                "Gross Profit Margin",
                                "Promo Code",
                                "Discount"
                            ]}
                            renderItems={(props) => orderRows(props, filteredOrders, Object.keys(filteredOrders).reverse())}
                        />
                    </Fragment>
                        : <p>No order history available for the selected date range and status</p>}

                <Dialog
                    open={showDialog}
                    onClose={() => closeOrderDetailsForm()}>
                    {orderForDialog ? (
                        <OrderWithDetailsAndPrices
                            showTripTicketOnly
                            order={orderForDialog}
                            user={orderForDialog.user}
                            address={orderForDialog.address}
                            closeModal={closeOrderDetailsForm}
                            modifyOrder={(orderForDialog.orderStatus === 'received' || orderForDialog.orderStatus === 'in_process' || orderForDialog.orderStatus === 'queued'||
                                orderForDialog.orderStatus === 'allotment_confirmed' || orderForDialog.orderStatus === 'cancelled_insufficient_allotment' || orderForDialog.orderStatus === 'packaged' ||
                                orderForDialog.orderStatus === 'in_route'
                            ) && {
                                show: true,
                                dsprDrivers: driversForDSPR,
                                modifyOrder: handleModifyOrder,
                                getDSPRDriver,
                                availableInventory,
                                calculateOrderTotal,
                                getProductWithId,
                            }}
                        />
                    )
                        : <p>Order history is unavailable</p>}
                </Dialog>
                <Dialog
                    open={showCustomOrderReportDialog}
                    onClose={() => closeCustomOrderReportDialog()}>
                    <CardHeader title="Order Report" />
                    <CardContent>
                        <OrderReportForm onSubmit={handleCustomOrderReport} initialValues={{
                            completed: true,
                            order_id: true,
                            completion_date: true,
                            completion_time: true,
                            first_name: true,
                            last_name: true,
                            dhs_number: true,
                            order_details: true,
                            taxes: true,
                            total: true,
                            address: true
                        }}/>
                    </CardContent>
                </Dialog>
            </CardContent>
        </Card>
        {showModifySuccessModal && <SweetAlert
            success
            timeout={2000}
            style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
            title="Order Modified"
            onConfirm={() => setShowModifySuccessModal(false)}
            showConfirm={false}
        >
            The order has been successfully modified!
        </SweetAlert>}
    </Fragment>
}

export default DSPROrderHistory;