import React, { Dispatch, SetStateAction } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import './SnackBarAlertStyles.scss';

interface SnackBarAlertSuccessProps {
    showSnackbarAlert: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    alertText?: string;
    autoHideDuration?: number;
    verticalAnchorOrigin?: 'top' | 'bottom';
    horizontalAnchorOrigin?: 'center' | 'left' | 'right';
}

const SnackBarAlertSuccess = ({
                                  showSnackbarAlert,
                                  setIsVisible,
                                  alertText = 'Success!',
                                  autoHideDuration = 3000, //in milliseconds
                                  verticalAnchorOrigin = 'top',
                                  horizontalAnchorOrigin = 'center',
                              }: SnackBarAlertSuccessProps) => {

    return (
        <Snackbar open={showSnackbarAlert}
                  autoHideDuration={autoHideDuration}
                  onClose={() => setIsVisible(false)}
                  anchorOrigin={{vertical: verticalAnchorOrigin, horizontal: horizontalAnchorOrigin}}
                  className={'snackbar-alert-container'}
        >
            <>
                {/*prevents alert from re-rendering*/}
                <Alert severity={'success'} onClose={() => setIsVisible(false)} className={'snackbar-alert'}>
                    <div className={'snackbar-alert-message'}>
                        {alertText}
                    </div>
                </Alert>
            </>
        </Snackbar>
    )
}

export default SnackBarAlertSuccess;