import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, DialogActions, DialogContent } from '@material-ui/core';
import AutoComplete from '../components/Autocomplete';

import './DSPFormStyles.scss';

const validate = (values) => {
    const errors: any = {};
    if (!values.userId) {
        errors.userId = 'Required'
    }
    if (!values.name) {
        errors.name = 'Required'
    }
    return errors
};

const renderField = ({ input, label, type, autocomplete, users, handleAutoCompleteSelected, meta: { touched, error, value, form }, ...custom }) => {
    return autocomplete ?
        <AutoComplete
            options={users}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            form={form}
            error={error}
            input={input}
            {...custom}
        />
        :
        <TextField label={label} type={type} {...input}
            error={!!(touched && error)}
            helperText={touched && error ? error : ''} {...custom} />
};

interface DSPFormProps {
    onCancel: () => any,
    handleAutoCompleteSelected: (id: string) => any
    editMode: boolean,
    users: { value: number, text: string }[]
    usersLoaded?: boolean;
}

const DSPForm = (props: DSPFormProps & InjectedFormProps<{}, DSPFormProps>) => {
    const { handleSubmit, onCancel, users, editMode, handleAutoCompleteSelected, usersLoaded } = props;
    return (
        <form onSubmit={handleSubmit} className="dsp-form">
            <DialogContent>
                {!editMode && <Field name="name" type="text" component={renderField} label="Name" />}
                <Field name="userId" component={renderField} autocomplete users={users} label="Name of Manager" handleAutoCompleteSelected={handleAutoCompleteSelected} dataLoaded={usersLoaded} className='field' />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onCancel}>Cancel</Button>
                <Button variant='contained' color='primary' onClick={handleSubmit}>Create</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPFormProps>({
    form: 'DSPForm', // a unique identifier for this form
    validate
})(DSPForm)