import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MedicalRecommendation } from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import './UserMedicalRecommendationVerificationFormStyles.scss'

const validate = (values) => {
    const errors = {};
    const requiredFields = ['expirationDate', 'idNumber'];
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Required';
    });

    if (!parseDate(values['expirationDate'])) errors['expirationDate'] = "Invalid Date";
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error, value }, ...custom }) => {
    switch (type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="med-rec-exp-date-picker"
                    label={label}
                    value={input.value || null}
                    autoOk
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...custom}
                />
            </MuiPickersUtilsProvider>
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} {...custom}/>} />;
        default:
            return <TextField
                    label={label} placeholder={label} type={type} {...input} {...custom}
                    error={!!(touched && error)}
                    helperText={touched && error ? error : ''}
                />
    }
};
interface UserMedicalRecommendationVerificationFormProps extends Partial<InjectedFormProps> {
    medicalRecommendation: MedicalRecommendation;
    handleHide: (id: number ) => any;
    handleUnhide: (id: number ) => any;
}

const UserMedicalRecommendationVerificationForm = (props: UserMedicalRecommendationVerificationFormProps) => {
    const { handleSubmit, medicalRecommendation, handleHide, handleUnhide } = props;

    return !medicalRecommendation.verified &&
        <form onSubmit={handleSubmit} className={'user-medical-recommendation-form'}>
            <div className={'fields-container'}>
                <Field name="expirationDate" type="date" component={renderField} label="Expiration Date" className={'field'}/>
                <Field name="idNumber" component={renderField} label="ID Number" className={'field'}/>
            </div>
            <FormControl component='fieldset' className={'state-issued-checkbox'}>
                <Field name="stateIssued" component={renderField} label="Is state issued?" type="checkbox" />
            </FormControl>
            <br/>
            <div className='button-container'>
                <div className="verify-button-container">
                    <Button color="primary" variant="contained" onClick={handleSubmit}>Verify</Button>
                </div>
                <div className="hide-unhide-button-container">
                    {medicalRecommendation.visible ? <Button color="secondary" variant="contained" onClick={() => handleHide(medicalRecommendation.id)}>Hide</Button> :
                        <Button color="secondary" variant="contained" onClick={() => handleUnhide(medicalRecommendation.id)}>Unhide</Button>}
                </div>
            </div>
        </form>
};

export default reduxForm<any, UserMedicalRecommendationVerificationFormProps>({
    form: 'UserMedicalRecommendationVerificationForm',
    validate
})(UserMedicalRecommendationVerificationForm);