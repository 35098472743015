import React, { useEffect, Fragment, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { createDSP, getAllDSPs } from "../actions/dspActions";
import { getAllUsers } from "../actions/userActions";
import { interceptAutocompleteSelected } from "../actions/formHelperActions";
import { getDSPRDriverWithUserAndOrdersFromProps } from '../selectors/dsprDriverSelector';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getDSPsForNavBar } from '../selectors/dspSelectors';
import { getDSPsWithStructureForHeaderMenuLinks, navLinks, navDSP, navDSPR } from '../selectors/headerSelectors'
import { DSPR, DsprManager, State, User } from '../store/reduxStoreState';

import UsersManagement from '../components/UsersManagement';
import DSPProduct from '../containers/DSPProduct';
import Navbar from '../components/Navbars/Navbar';
import DSPManagement from '../containers/DSPManagement';
import Sidebar from "../components/Sidebar";
import UserPage from '../containers/UserPage'
import DSPRPage from '../containers/DSPRPage'

import { history } from '../index';

import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import AnalyticsManager from "./AnalyticsManager";
import DSPManager from "./DSPManager";
import { getDSPRManagerFromProps } from "../selectors/dsprManagerSelectors";
import { getDSPRs } from "../selectors/dsprSelectors";
//import { type } from "os";

const useStyles = makeStyles(styles as any);

export interface DSP {
    id: string;
    name: string;
}

interface SystemAdminManager {
    loggedInUser: Partial<User>;
    dspsForNavBar: DSP[],
    getAllDSPs(): any;
    driverDsprIds?: number[];
    driverIds: number[];
    dsprManager: any;
}

const SystemAdminManager: React.FC<SystemAdminManager> = props => {
    const {loggedInUser, getAllDSPs, driverDsprIds, driverIds} = props;
    const classes = useStyles({});
    const isSystemAdmin = loggedInUser && loggedInUser.systemAdministrator;
    const isDsprManager = !!(loggedInUser && loggedInUser.dsprManagers && loggedInUser.dsprManagers.length);
    const isDspManager = !!(loggedInUser && loggedInUser.deliveryServiceProviderManagers && loggedInUser.deliveryServiceProviderManagers.length);
    const isNonManagerDriver = !!(driverIds && !isSystemAdmin && !isDsprManager && !isDspManager);
    const navSelector: navLinks = useSelector<State, navLinks>(getDSPsWithStructureForHeaderMenuLinks, shallowEqual);
    const dsprs = useSelector<State, { [key: string]: DSPR }>(getDSPRs, shallowEqual);
    const dsprManager = useSelector<State, DsprManager>(state => isDsprManager && getDSPRManagerFromProps(state, {dsprManagerId: loggedInUser.dsprManagers[0]}), shallowEqual);

    const [navOpen, setNavOpen] = useState(false);

    //Route user to appropriate page if user is not SuperAdmin or DSP Manager
    useEffect(() => {
        if (!loggedInUser) history.push('/login');
    }, [loggedInUser]);

    useEffect(() => {
        if (isDsprManager && !isDspManager && !isSystemAdmin) history.push(`/dspr/${dsprManager.dspr}`)
    }, [dsprManager.dspr, isDspManager, isDsprManager, isSystemAdmin]);

    useEffect(() => {
        if (isNonManagerDriver) history.push(`/dspr/${driverDsprIds[0]}/drivers/${driverIds[0]}`);
    }, [isNonManagerDriver, driverIds, driverDsprIds]);

    //Get all DSPs if user is a system admin
    useEffect(() => {
        if (isSystemAdmin) getAllDSPs();
    }, [isSystemAdmin, getAllDSPs]);

    const routes: any[] = [];

    if (loggedInUser && navSelector) {
        Object.values(navSelector).forEach((dsp: navDSP) => {
            if (typeof dsp === "object") {
                routes.push({
                    path: `dsp/${dsp.id}/`,
                    name: `${dsp.name}`,
                    mini: `${dsp.id}`,
                    component: DSPManager,
                    layout: "/"
                });

                if (dsp.dsprs) {
                    Object.values(dsp.dsprs).forEach((dspr: navDSPR) => {
                        if(dspr.isManager === true) {
                            routes.push({
                                path: `/${dspr.id}/dashboard/`,
                                name: `\xa0\xa0\xa0\xa0\xa0\xa0${dspr.name}`,
                                mini: "-",
                                component: DSPRPage,
                                layout: "/dspr"
                            });
                        }
                    })
                }
            }
        })
    }

    if (isSystemAdmin) {
        routes.unshift({
                path: "users/",
                name: "Manage Platform Users",
                mini: 'U',
                component: UsersManagement,
                layout: "/"
            },
            {
                path: "dspManagement/",
                name: "Manage Service Providers",
                mini: 'D',
                component: DSPManagement,
                layout: "/"
            })
    }

    if (driverIds) {
        driverIds.forEach((driverId, index) => {
            routes.push({
                path: `/${driverDsprIds[index]}/drivers/${driverIds[index]}`,
                name: `Driver - ${dsprs[driverDsprIds[index]].name}`,
                mini: 'D',
                layout: "/dspr",
            }); // Shortcut for drivers who are also managers
        })
    }


    return (
        <Fragment>
            <Navbar
                brandText=""
                handleDrawerToggle={() => setNavOpen(!navOpen)}
            />
            <Sidebar
                open={navOpen}
                logo="/assets/images/grasspLogo.svg"
                logoText={'Grassp Logistics'}
                routes={routes}
                handleDrawerToggle={() => setNavOpen(false)}
                color='blue'
                bgColor='black'
                isDriver={isNonManagerDriver}
            >
            </Sidebar>
            <div className={classes.mainPanel}>
                <Switch>
                    <Route path="/users/" component={UsersManagement}/>
                    <Route path="/dsp/:dspId/analytics/brands/" component={AnalyticsManager}/>
                    <Route path="/dsp/:dspId/" component={DSPManager}/>
                    <Route path="/user/:userId/" component={UserPage}/>
                    <Route path="/product/:productId/" component={DSPProduct}/>
                    <Route path="/dspManagement/" component={DSPManagement}/>
                    <Redirect from="/" to="/dspManagement/"/>
                </Switch>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state: State) => {
    const loggedInUser = getLoggedInUser(state);
    const driverIds = loggedInUser && loggedInUser.dsprDrivers.length > 0 && loggedInUser.dsprDrivers;
    const dsprDriverArray = []
    driverIds && driverIds.forEach((driverId, index) => {
        dsprDriverArray[index] = getDSPRDriverWithUserAndOrdersFromProps(state, {dsprDriverId: driverId});
    })
    const driverDsprIds = dsprDriverArray.length !== 0 ? dsprDriverArray.map(dsprDriver => dsprDriver.dspr) : undefined;

    return {
        loggedInUser,
        dspsForNavBar: getDSPsForNavBar(state),
        driverDsprIds,
        driverIds,
    };
};

const mapDispatchToProps = {
    getAllUsers,
    interceptAutocompleteSelected,
    createDSP,
    getAllDSPs,
}


export default connect(mapStateToProps, mapDispatchToProps)(SystemAdminManager);