import { parseDate } from "./util";
import { Analytic, BrandAnalytics } from "../store/reduxStoreState";

class AnalyticsUtil {

    /**Returns a formatted date string for analytics tables depending upon filter type*/
    static formatDateForTable(analytics: Analytic | BrandAnalytics, currentFilterType?: string) {
        const date = parseDate(analytics.beginDate);
        const month = date.toLocaleString("en-us", {month: "long"});

        switch (currentFilterType) {
            case 'quarters':
                switch(month) {
                    case "January":
                        return `Q1 ${date.getFullYear()}`
                    case "April":
                        return `Q2 ${date.getFullYear()}`
                    case "July":
                        return `Q3 ${date.getFullYear()}`
                    case "October":
                        return `Q4 ${date.getFullYear()}`
                    default:
                        return `${month.substr(0,3) + "."} ${date.getDate()}, ${date.getFullYear()}`
                }
            case 'months':
                return `${month.substr(0,3) + "."} ${date.getFullYear()}`;
            case 'years':
                return `${date.getFullYear()}`;
            default:
                return `${month.substr(0,3) + "."} ${date.getDate()}, ${date.getFullYear()}`
        }
    }

    /**Returns formatted date string for charts depending upon filter type*/
    static formatDateForChart(analyticsEntry: Analytic | BrandAnalytics, currentFilterType) {
        const dateString = parseDate(analyticsEntry.beginDate).toDateString();

        switch(currentFilterType) {
            case 'days':
                return dateString.slice(4, 10);
            case 'months':
                return dateString.slice(4, 7) + ' \'' + dateString.slice(-2);
            case 'quarters':
                switch(dateString.slice(4, 7)) {
                    case "Jan":
                        return `Q1 '${dateString.slice(-2)}`
                    case "Apr":
                        return `Q2 '${dateString.slice(-2)}`
                    case "Jul":
                        return `Q3 '${dateString.slice(-2)}`
                    case "Oct":
                        return `Q4 '${dateString.slice(-2)}`
                    default:
                        return `${dateString.slice(4, 7) + ". "} ${dateString.slice(-2)}`
                }
            case 'years':
                return dateString.slice(-4);
            default:
                return dateString.slice(4, 10);
        }

    }
}

export default AnalyticsUtil;