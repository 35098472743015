import React, { useState } from 'react';
import { TextField, Switch, TableRow, TableCell } from "@material-ui/core";
import { DsprZipCode } from '../store/reduxStoreState';

interface DSPRZipCodeComponentProps {
    zipCode: DsprZipCode;
    handleZipCodeMinimumChange: (dsprZipCodeId: number, newMin: string) => any;
    loggedInUserIsDSPManager: boolean;
    handleToggleDSPRZipCode: (zipCodeId: number) => void;
    minimumOrderSizeDefault: number;
}

const DSPRZipCodeComponent = (props: DSPRZipCodeComponentProps) => {
    const { zipCode, loggedInUserIsDSPManager, minimumOrderSizeDefault, handleToggleDSPRZipCode, handleZipCodeMinimumChange } = props;
    const [override, setOverride] = useState(zipCode.minimumOrderSizeOverride !== null ? zipCode.minimumOrderSizeOverride : "");

    const handleZipCodeMinChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
        const newMin = event.target.value;
        setOverride(newMin);
        handleZipCodeMinimumChange(id, newMin === '' ? null : newMin);
    }

    return <TableRow key={zipCode.id} className={zipCode.active ? "active" : "inactive"}>
        <TableCell>{zipCode.zipCode}</TableCell>
        <TableCell>
            <span className="min-order-size-dollar-sign">$</span>
            <TextField
                className="zipcode-min-order-size-input"
                type="number"
                name={`zipCode-${zipCode.id}`}
                placeholder={String(minimumOrderSizeDefault)}
                value={override}
                onChange={e => handleZipCodeMinChange(e, zipCode.id)}
            />
        </TableCell>
        {loggedInUserIsDSPManager && <TableCell>
            <Switch checked={zipCode.active} onChange={() => handleToggleDSPRZipCode(zipCode.id)} color="primary" />
        </TableCell>}
    </TableRow>
}

export default DSPRZipCodeComponent;
