import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardActions,
    CardHeader,
    Button,
    ButtonBase,
    Dialog,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    CardContent,
    Tooltip,
    DialogContentText,
    DialogActions,
    Typography,
    Fade,
} from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from "react-redux";

import {
    CREATE_NEW_INVENTORY_TRANSACTION_FAILURE,
    TRANSACTION_TYPES,
    TransactionValues
} from '../actions/dsprProductInventoryActions';
import { currentDSPRInventorySnapshotCSVDownloadLink } from '../actions/dsprActions';
import {
    DspProduct,
    DsprDriver,
    User,
    DsprCurrentInventoryItem,
    MetrcTag,
    BatchNumber,
    TransactionType
} from '../store/reduxStoreState';

import DSPRInventoryTransactionForm from '../containers/DSPRInventoryTransactionForm';
import DSPRProductPriceForm from '../containers/DSPRProductPriceForm';

import CardBody from './Card/CardBody';
import VirtualizedTable from './VirtualizedTable';

import './dsprInventoryStyles.scss';
import AutoComplete, { AutoSelectStringValueItems } from './Autocomplete';
import {
    GET_METRC_TAGS_FOR_DSPR_FROM_METRC_FAILURE,
    GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS,
    GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS,
    GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE,
    REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS,
    REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_FAILURE,
    getDSPRMetrcTagsFromMetrc,
    metrcTagPDFGetter,
    MetrcTagPDFGetterProps,
    MetrcTagProductAssociationsGetterProps,
    reviseDSPRMetrcTagProductAssociation,
} from "../actions/metrcTagActions";
import DSPRMetrcTagOrQRCodeDownloadForm from "../containers/DSPRMetrcTagOrQRCodeDownloadForm";
import DSPRMetrcTagProductLookupForm from './DSPRMetrcTagProductLookupForm';
import DSPRModifyProductMetrcTagForm from './DSPRModifyProductMetrcTagForm';
import DSPRMetrcTagProductLookupFormVirtualizedTable from './DSPRMetrcTagProductLookupVirtualizedTable';
import SnackBarAlertSuccess from "./SnackBarAlertSuccess";
import SnackBarAlertError from "./SnackBarAlertError";
import { BatchNumberProductAssocationGetterProps, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS } from '../actions/batchNumberActions';
import { history } from "../index";
import DsprInventoryAdditionalInformationCard
    from "./DSPRInventoryAdditionalInformation/DSPRInventoryAdditionalInformationCard";
import InventoryReportForm from '../containers/InventoryReportForm';
import { API_ROOT } from '../middleware/api';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../actions/oauthActions';

export const DRIVER_OR_STOCK_TYPE = {
    STOCK: 'STOCK',
    DRIVER: 'DRIVER'
};

export const COST_OR_QUANTITY = {
    COST: 'COST',
    QUANTITY: 'QUANTITY'
};

type DsprInventoryProduct = {
    id: number,
    deliveryServiceProvider: number,
    currentPrice: number,
    isFlower: boolean,
    name: string,
}

type productInventoryMap = {
    product: DsprInventoryProduct,
    dspr: number,
    [key: number]: number
}

export interface ProductInfoForModifyProductMetrcTag {
    product: DspProduct;
    metrcProductAssociation: MetrcTag;
    costOfInventory: number;
}

interface DSPRInventoryProps {
    dspProductsForAutoselect: { value: number, text: string }[];
    products: { [key: number]: DspProduct };
    interceptAutocompleteSelected: (id: number) => any;
    interceptDriverAutocompleteSelected: (id: number) => any;
    interceptMetrcTagAutoCompleteSelected: (tag: string) => any;
    interceptBatchNumberAutoCompleteSelected: (batch: string) => any;
    interceptMetrcReturnReasonsAutoCompleteSelected: (returnReason: string) => any;
    interceptMetrcTagForProductLookupFormAutoCompleteSelected: (tag: string) => any;
    interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected: (tag: string) => any;
    interceptBatchNumberForProductLookupFormAutoCompleteSelected: (batch: string) => any;
    interceptProductForProductLookupFormAutocompleteSelected: (id: number) => void;
    interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected: (id: number) => void;
    interceptMetrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected: (tag: string) => void;
    interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected: (batch: string) => void;
    interceptQRTypeForBarcodeDownloadFormAutocompleteSelected: (value: string) => void;
    handleSubmitInventoryTransactionForm: (transactionType: string, newInventoryTransactionValues) => any;
    currentInventory: (Omit<DsprCurrentInventoryItem, 'product'> & { product: DspProduct })[];
    productAvailabilityMap: { [key: number]: { product: DsprInventoryProduct, dspr: number } & Partial<{ [key: number]: number }> };
    drivers: (Partial<Omit<DsprDriver, 'user'> & { user: User }>)[];
    handleSubmitProductPricingForm: (dsprId: string, productId: number, priceObject: any) => any;
    dsprId: string;

    updateShowActiveOnly;

    isMetrcDspr: boolean;
    metrcTagsForAutoSelect: AutoSelectStringValueItems;
    unassignedMetrcTagsForAutoSelect: AutoSelectStringValueItems;
    isFetchingMetrcTags: boolean;
    metrcTagValueInMetrcTagProductLookupForm: string;
    productValueInMetrcTagProductLookupForm: number;
    fetchMetrcProductAssociation: (formValues: MetrcTagProductAssociationsGetterProps) => any;
    showActiveOnlyMetrcTagsInMetrcProductForm: boolean;
    isFetchingMetrcProductAssociations: boolean;

    isBatchDspr: boolean;
    batchNumbersForAutoSelect: AutoSelectStringValueItems;
    metrcReturnReasonsForAutoSelect: AutoSelectStringValueItems;
    unassignedBatchNumbersForAutoSelect: AutoSelectStringValueItems;
    batchNumberValueInBatchNumberProductLookupForm: string;
    productValueInBatchNumberProductLookupForm: number;
    fetchBatchNumberProductAssociation: (formValues: BatchNumberProductAssocationGetterProps) => any;
    isFetchingBatchNumbers;
    showActiveOnlyBatchNumbersInBatchNumberProductForm: boolean;
    isFetchingBatchNumberProductAssociations: boolean;

    isNonMetrcScanningDspr: boolean;
}

interface DSPRInventoryState {
    isPriceFormOpen: boolean;
    pricingProductId: number;
    productNameForPricing: string;
    pricingProductIsFlower: boolean;
    showInventoryTransactionForm: boolean;
    inventoryTransactionDriverOrStock: string;
    transactionType: string;
    correctCostOrQuantity: string;
    onlyShowCarriedInventoryProduct: boolean;
    onlyShowInStockProduct: boolean;
}

const DSPRInventory: React.FC<DSPRInventoryProps> = props => {
    const [state, setState] = useState<DSPRInventoryState>({
        isPriceFormOpen: false,
        pricingProductId: null,
        productNameForPricing: null,
        pricingProductIsFlower: false,
        showInventoryTransactionForm: false,
        inventoryTransactionDriverOrStock: null,
        transactionType: null,
        correctCostOrQuantity: null,
        onlyShowCarriedInventoryProduct: true,
        onlyShowInStockProduct: true,

    });

    const [showInventoryReportForm, setShowInventoryReportForm] = useState(false);
    const [searchProduct, setSearchProduct] = useState<string>(null);
    const [availableInventoryRows, setAvailableInventoryRows] = useState<any[]>([]);
    const [currentInventoryRows, setCurrentInventoryRows] = useState<any[]>([]);
    const [searchFilteredAvailableInventoryRows, setSearchFilteredAvailableInventoryRows] = useState<any[]>([]);
    const [searchFilteredCurrentInventoryRows, setSearchFilteredCurrentInventoryRows] = useState<any[]>([]);
    const [showMetrcPDFModal, setShowMetrcPDFModal] = useState<boolean>(false);
    const [showMetrcRedownloadPDFModal, setShowMetrcRedownloadPDFModal] = useState<boolean>(false);
    const [metrcPDFRequestBody, setMetrcPDFRequestBody] = useState<MetrcTagPDFGetterProps>(null);
    const [fetchMetrcPDF, setFetchMetrcPDF] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [errorModalTitle, setErrorModalTitle] = useState<string>('');
    const [errorModalText, setErrorModalText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSnackbarSuccessAlert, setShowSnackbarSuccessAlert] = useState<boolean>(false);
    const [snackbarSuccessAlertText, setSnackbarSuccessAlertText] = useState<string>("Success!");
    const [showSnackbarErrorAlert, setShowSnackbarErrorAlert] = useState<boolean>(false);
    const [snackbarErrorAlertTitle, setSnackbarErrorAlertTitle] = useState<string>('Error Encountered!')
    const [snackbarErrorAlertText, setSnackbarErrorAlertText] = useState<string>('Please Try Again');
    const [showModifyProductMetrcTagForm, setShowModifyProductMetrcTagForm] = useState<boolean>(false);
    const [productForModifyProductMetrcTagForm, setProductForModifyProductMetrcTagForm] = useState<ProductInfoForModifyProductMetrcTag>(null);

    const [fetchedMetrcProductAssociations, setFetchedMetrcProductAssociations] = useState<MetrcTag[]>(null);
    const [updateModifyButtonText, setUpdateModifyButtonText] = useState<boolean>(false);
    const [metrcAssociationRowToChange, setMetrcAssociationRowToChange] = useState<string>('');

    const [fetchedBatchNumberProductAssociations, setFetchedBatchNumberProductAssociations] = useState<BatchNumber[]>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (fetchMetrcPDF === true) {
            metrcTagPDFGetter(metrcPDFRequestBody)
                .then((response) => {
                    setFetchMetrcPDF(false);

                    if (response.error) {
                        setShowSnackbarErrorAlert(true);
                        setSnackbarErrorAlertText(response.error);
                    }
                })
        }
    }, [fetchMetrcPDF, metrcPDFRequestBody, dispatch]);

    interface ConstructMetrcRequestBodyProps {
        metrcTag?: string;
        productId: number,
        quantity: number;
        productName: string;
        batchNumber?: string;
        printLabelType: string;
    }
    const constructMetrcRequestBody = ({ metrcTag, productId, batchNumber, quantity, productName, printLabelType }: ConstructMetrcRequestBodyProps) => {
        const requestBody = {
            metrcTag,
            productId,
            productName,
            batchNumber,
            dsprId: parseInt(props.dsprId),
            requestedQuantityOfStickers: quantity,
            printLabelType
        }

        // if (!metrcTag || !printLabelType) delete requestBody['metrcTag'];
        // if (!batchNumber || !printLabelType) delete requestBody['batchNumber'];

        return requestBody;
    }

    const handleMetrcPDFModalClose = () => {
        setShowMetrcPDFModal(false);
        setState(currentState => ({
            ...currentState,
            showInventoryTransactionForm: true,
        }));
    }

    const handleMetrcPDFModalOpen = (productId: number, quantity: number, printLabelType, metrcTag?: string, batchNumber?: string) => {
        const productName = props.products[productId] && props.products[productId].name;
        setMetrcPDFRequestBody(constructMetrcRequestBody({ metrcTag, batchNumber, productId, quantity, productName, printLabelType: printLabelType || 'qrInchAndHalf' }));
        setShowMetrcPDFModal(true);
    }

    const handleQRCodeRedownloadPDFModalClose = () => setShowMetrcRedownloadPDFModal(false);

    const handleQRCodeRedownloadPDFModalOpen = () => setShowMetrcRedownloadPDFModal(true);

    const handleSubmitQRCodeRedownloadPDF = (values) => {
        const productName = props.products[values.productId] && props.products[values.productId].name;
        setMetrcPDFRequestBody(constructMetrcRequestBody({ metrcTag: values.metrcTag, batchNumber: values.batchNumber, productId: values.productId, quantity: values.quantity, productName: productName, printLabelType: values.printLabelType || 'qrInchAndHalf' }));
        setFetchMetrcPDF(true)
        handleQRCodeRedownloadPDFModalClose();
    }

    const handlePriceFormClose = () => {
        setState(currentState => ({
            ...currentState,
            isPriceFormOpen: false,
        }));
    };


    const handlePriceFormOpen = (pricingProductId: number) => {
        if (pricingProductId) {
            const product = props.products[pricingProductId];
            setState(currentState => ({
                ...currentState,
                isPriceFormOpen: true,
                pricingProductId,
                productNameForPricing: product.name,
                pricingProductIsFlower: product.isFlower,
            }));
        }
    };

    const handleSubmitPriceForm = (priceValues: { price: string }) => {
        props.handleSubmitProductPricingForm(props.dsprId, state.pricingProductId, priceValues);
        setState(currentState => ({
            ...currentState,
            isPriceFormOpen: false
        }));
    };

    const handleSubmitInventoryTransactionForm = (transactionType: string) => (values: TransactionValues) => {
        setState(currentState => ({
            ...currentState,
            showInventoryTransactionForm: false,
        }));

        if (props.metrcReturnReasonsForAutoSelect && props.metrcReturnReasonsForAutoSelect.length === 0 && props.isMetrcDspr) {
            values['metrcReturnReason'] = "No Reasons Provided By Metrc"
        }

        props.handleSubmitInventoryTransactionForm(transactionType, values)
            .then((response) => {
                if (response && response.type === CREATE_NEW_INVENTORY_TRANSACTION_FAILURE) {
                    setShowErrorModal(true);
                    setErrorModalTitle('Error Creating Inventory Transaction!');
                    setErrorModalText(response.error);
                    setState(currentState => ({
                        ...currentState,
                        showInventoryTransactionForm: true,
                    }))
                } else if (props.isMetrcDspr) {
                    if (transactionType === TRANSACTION_TYPES.STOCK || transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT)
                        handleMetrcPDFModalOpen(values.productId, parseInt(values.quantity), undefined, values.metrcTag);
                } else if (props.isBatchDspr) {
                    if (transactionType === TRANSACTION_TYPES.STOCK || transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT)
                        handleMetrcPDFModalOpen(values.productId, parseInt(values.quantity), undefined, undefined, values.batchNumber)
                } else {
                    setState(currentState => ({
                        ...currentState,
                        showInventoryTransactionForm: true,
                    }
                    ))
                }
            });
    };

    /**Fetch updated list of Metrc Tags from Metrc*/
    const handleUpdateMetrcTagsFromMetrc = () => {
        setIsLoading(true);
        dispatch<any>(getDSPRMetrcTagsFromMetrc(props.dsprId))
            .then((response) => {
                setIsLoading(false);
                if (response.type === GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS) {
                    setShowSnackbarSuccessAlert(true);
                    setSnackbarSuccessAlertText('Metrc Tags Updated!');
                }
                if (response.type === GET_METRC_TAGS_FOR_DSPR_FROM_METRC_FAILURE) {
                    setShowSnackbarErrorAlert(true);
                    setSnackbarErrorAlertTitle('Metrc Tags Failed to Update!');
                    setSnackbarErrorAlertText(response.error || 'Please try again or contact support');
                }
            })
    }

    //Clicking on product row will open a new window detailing the inventory transaction history for that product
    const generateDSPRInventoryTableRow = (item, style: React.CSSProperties) => {
        return <div key={item.id} style={style} className="inventory-detail-row">
            <span
                onClick={() => {
                    const win = window.open(
                        `product/${item.product.id}/transaction-history`, '_blank');
                    win.focus()
                }}
                style={{ cursor: 'pointer' }}
            >{
                    item.product.name}
            </span>
            <span>{item.quantity}</span>
            <span>${item.costOfInventory}</span>
        </div>
    };

    const generateInventoryAvailableRow = (productRow, style: React.CSSProperties) => {
        const price = productRow.product.currentPrice;
        const flowerPrice = price && (price.eighthPrice || price.quarterPrice || price.halfPrice || price.ozPrice) &&
            price.eighthPrice + ", " + price.quarterPrice + ", " + price.halfPrice + ", " + price.ozPrice;
        const priceString = price ?
            productRow.product.isFlower ? flowerPrice
                : price.price
            : "";

        const rowBackgroundClass = !priceString ? 'row-bg-no-price' : productRow.notCarried ? 'row-bg-not-carried-by-driver' : 'row-bg-default'

        return <ButtonBase
            style={{ ...style }}
            className={`availability-row ${rowBackgroundClass}`}
            key={productRow.product.id}
            onClick={() => handlePriceFormOpen(productRow.product.id)}
        >
            <Tooltip title={productRow.product.name}
                placement={'top'}
                arrow><span className={'availability-table-product-name'}>{productRow.product.name}</span></Tooltip>
            <span>{priceString}</span>
            <span>{productRow.dspr ? productRow.dspr : 0}</span>
            {props.drivers && props.drivers.map(driver => driver.active &&
                <span key={`driver${driver.id}`}>{productRow[driver.id] ? productRow[driver.id] : 0}</span>)}
        </ButtonBase>
    };

    const handleDriverOrStockSelection = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        if (state.inventoryTransactionDriverOrStock !== event.target.value)
            setState(currentState => ({
                ...currentState,
                inventoryTransactionDriverOrStock: event.target.value,
                showInventoryTransactionForm: false,
                transactionType: null,
                correctCostOrQuantity: null,
            }));
    };

    const handleTransactionTypeSelection = (event: React.ChangeEvent<{ name?: string, value: any }>) => setState(currentState => ({
        ...currentState,
        transactionType: event.target.value,
        showInventoryTransactionForm: event.target.value !== TRANSACTION_TYPES.STOCK_ADJUSTMENT,
        correctCostOrQuantity: null,
    }));

    const handleCostOrQuantitySelection = (event: React.ChangeEvent<{ name?: string, value: any }>) => setState(currentState => ({
        ...currentState,
        correctCostOrQuantity: event.target.value,
        showInventoryTransactionForm: true,
    }));

    const getDriversForInventoryCorrectionDropdown = () => {
        return props.drivers ? props.drivers.map(driver =>
            ({ value: driver.id, text: driver.user.firstName + " " + driver.user.lastName })) : [];
    };

    const dsprInventoryTableRows = ({ index, style }: { index: number, style: React.CSSProperties }) => {
        if (searchFilteredCurrentInventoryRows.length === 0) return null;
        return generateDSPRInventoryTableRow(searchFilteredCurrentInventoryRows[index], style);
    }

    const allInventoryAvailableHeaders = props.drivers ?
        props.drivers.map(driver => driver.active && <span key={driver.id}>
            <Link to={`/dspr/${driver.dspr}/drivers/${driver.id}`}>{driver.user.firstName} {driver.user.lastName}</Link>
        </span>)
        : <CircularProgress />;
    const numberDrivers = props.drivers ? props.drivers.length : 1;

    const productMap = props.productAvailabilityMap;

    const availableInventoryRowsWithOnlyCarriedInventoryProducts = useMemo(() => {
        const availableInventoryRows = [];
        productMap && Object.entries(productMap).forEach(([key, value]) => {
            const { product, dspr, ...rest } = value;
            const productIsCarried = !!(rest && Math.max(...Object.values(rest)) > 0);
            if (dspr) {
                availableInventoryRows.push(productIsCarried ? productMap[key] : {
                    ...productMap[key],
                    notCarried: true
                });
            } else {
                if (productIsCarried) availableInventoryRows.push(productMap[key]);
            }
        });
        return availableInventoryRows;
    }, [productMap]); // The useMemo ensures that this array is only recalculated when productMap changes

    const currentInventoryRowsWithOnlyInStockProduct = useMemo(() => props.currentInventory &&
        props.currentInventory.filter(item => item.quantity > 0), [props.currentInventory]);

    useEffect(() => {
        setAvailableInventoryRows(availableInventoryRowsWithOnlyCarriedInventoryProducts)
    }, [productMap, availableInventoryRowsWithOnlyCarriedInventoryProducts]);

    useEffect(() => {
        setCurrentInventoryRows(currentInventoryRowsWithOnlyInStockProduct)
    }, [productMap, currentInventoryRowsWithOnlyInStockProduct]);

    useEffect(() => {
        if (availableInventoryRows && availableInventoryRows.length > 0) {
            if (searchProduct) {
                setSearchFilteredAvailableInventoryRows(availableInventoryRows.filter((productMap: productInventoryMap) => productMap.product.name.toLowerCase().includes(searchProduct.toLowerCase())))
            } else {
                setSearchFilteredAvailableInventoryRows(availableInventoryRows)
            }

        }
    }, [availableInventoryRows, searchProduct])

    useEffect(() => {
        if (currentInventoryRows && currentInventoryRows.length > 0) {
            if (searchProduct) {
                setSearchFilteredCurrentInventoryRows(currentInventoryRows.filter((productMap: productInventoryMap) => productMap.product.name.toLowerCase().includes(searchProduct.toLowerCase())))
            } else {
                setSearchFilteredCurrentInventoryRows(currentInventoryRows)
            }

        }
    }, [currentInventoryRows, searchProduct])

    const handleAvailableInventoryToggle = () => {
        setState(currentState => ({
            ...currentState,
            onlyShowCarriedInventoryProduct: !currentState.onlyShowCarriedInventoryProduct,
        }));
        setAvailableInventoryRows(state.onlyShowCarriedInventoryProduct ? Object.values(productMap) : availableInventoryRowsWithOnlyCarriedInventoryProducts)
    }

    const handleCurrentInventoryToggle = () => {
        setState(currentState => ({
            ...currentState,
            onlyShowInStockProduct: !currentState.onlyShowInStockProduct,
        }))
        setCurrentInventoryRows(state.onlyShowInStockProduct ? props.currentInventory : currentInventoryRowsWithOnlyInStockProduct)
    }

    const availabilityRows = ({ index, style }: { index: number, style: React.CSSProperties }) => {
        if (searchFilteredAvailableInventoryRows.length === 0) return null;
        return generateInventoryAvailableRow(searchFilteredAvailableInventoryRows[index], style);
    }

    const handleSubmitMetrcProductLookupForm = (formValues: MetrcTagProductAssociationsGetterProps) => {
        props.fetchMetrcProductAssociation(formValues).then(response => {
            if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS) {
                if (response.response.entities.dsprMetrcTags) {
                    const metrcProductAssociations = Object.values<any>(response.response.entities.dsprMetrcTags)
                        .filter((metrcProductAssociation) => metrcProductAssociation.productId);
                    setFetchedMetrcProductAssociations(metrcProductAssociations);
                } else {
                    setFetchedMetrcProductAssociations([]);
                }
            }
            if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE) {
                setShowErrorModal(true);
                setErrorModalTitle('Error Encountered!');
                setErrorModalText(response.error || 'Unable to fetch Metrc Tag Product Associations');

                setFetchedMetrcProductAssociations([]);
            }
        })
    }

    const handleSubmitBatchNumberProductLookupForm = (formValues: BatchNumberProductAssocationGetterProps) => {
        const finalLookupObject = { ...formValues, dsprId: parseInt(props.dsprId) }
        props.fetchBatchNumberProductAssociation(finalLookupObject).then(response => {
            if (response.type === GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS) {
                if (response.response.entities.dsprBatchNumbers) {
                    const batchNumberProductAssociations = Object.values<any>(response.response.entities.dsprBatchNumbers)
                        .filter((batchNumberProductAssociation) => batchNumberProductAssociation.product);
                    setFetchedBatchNumberProductAssociations(batchNumberProductAssociations);
                } else {
                    setFetchedBatchNumberProductAssociations([]);
                }
            }
            if (response.type === GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE) {
                setShowErrorModal(true);
                setErrorModalTitle('Error Encountered!');
                setErrorModalText(response.error || 'Unable to fetch Batch Number Product Associations');

                setFetchedBatchNumberProductAssociations([]);
            }
        })
    }

    const handleClickModifyInMetrcProductLookupForm = (metrcProductAssociation: MetrcTag) => {
        const product = props.products[metrcProductAssociation.productId];

        const productInventoryInfo = props.currentInventory.find(inventoryItem => inventoryItem.product.id === metrcProductAssociation.productId);
        const costOfInventory = productInventoryInfo ? productInventoryInfo.costOfInventory : undefined;

        if (!product) {
            setProductForModifyProductMetrcTagForm(null);
            setShowModifyProductMetrcTagForm(true)
            return;
        }
        setProductForModifyProductMetrcTagForm({ product, metrcProductAssociation, costOfInventory });
        setShowModifyProductMetrcTagForm(true)
    }

    const handleSubmitModifyProductMetrcTag = (formValues: { metrcTag: string }) => {
        setShowModifyProductMetrcTagForm(false);

        // update button text if form values contain metrc tag or productId that is being modified
        // setUpdateModifyButtonText
        if (productForModifyProductMetrcTagForm.product.id === props.productValueInMetrcTagProductLookupForm
            || productForModifyProductMetrcTagForm.metrcProductAssociation.metrcTag === props.metrcTagValueInMetrcTagProductLookupForm) {
            setUpdateModifyButtonText(true);
            setMetrcAssociationRowToChange(productForModifyProductMetrcTagForm.metrcProductAssociation.metrcTag);
        }

        dispatch<any>(reviseDSPRMetrcTagProductAssociation({ metrcTagProductAssociation: productForModifyProductMetrcTagForm.metrcProductAssociation, newMetrcTag: formValues.metrcTag }))
            .then(response => {
                if (response.type === REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS) {

                    // if the user has not already submitted a new metrcProductAssociation search, update the virtualized table to show the new metrcProductAssociation
                    if (productForModifyProductMetrcTagForm.product.id === props.productValueInMetrcTagProductLookupForm
                        || productForModifyProductMetrcTagForm.metrcProductAssociation.metrcTag === props.metrcTagValueInMetrcTagProductLookupForm) {
                        const newMetrcTagId = response.response.result[0];
                        const newMetrcTagProductAssociation = response.response.entities.dsprMetrcTags[newMetrcTagId];
                        handleSubmitMetrcProductLookupForm({ productId: productForModifyProductMetrcTagForm.product.id, metrcTag: newMetrcTagProductAssociation.metrcTag });
                        setShowSnackbarSuccessAlert(true);
                        const productName = props.products[newMetrcTagProductAssociation.productId].name;
                        setSnackbarSuccessAlertText(`${productName} reassigned to Metrc Tag ${newMetrcTagProductAssociation.metrcTag}`);
                    }
                }

                if (response.type === REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_FAILURE) {
                    setShowSnackbarErrorAlert(true);
                    setSnackbarErrorAlertTitle('Metrc Tag Reassignment Failed');
                    setSnackbarErrorAlertText(response.error ? response.error : 'Please try again');

                }
                setUpdateModifyButtonText(false);
                setMetrcAssociationRowToChange('');
            })

    }

    function handleSubmitInventoryReportForm(formValues) {
        if (formValues.report_type === "batch_based") {
            formValues.batch_number = true;
            formValues.include_driver_inventories = null;
            formValues.quantity_lost = null;
            formValues.quantity_returned = null;
            formValues.inventory_value = null;
            formValues.item_value = null;
            formValues.lost_inventory_value = null;
            formValues.returned_inventory_value = null;
        }

        let rows = undefined;

        Object.entries(formValues).map(([key, value]) => {
            if (value) {
                switch (key) {
                    default:
                        if (rows) rows += "," + key;
                        else rows = key;
                        break;
                }
            }
        });
        let DSPROrDSPIdString
        if (props.dsprId) {
            DSPROrDSPIdString = `dspr_id=${props.dsprId}`
        }
        let url = API_ROOT + `dspr/inventory/download-inventory-report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}&${DSPROrDSPIdString}`;
        if (formValues.report_type === "batch_based") url += "&break_into_batches=true"
        if (formValues.include_driver_inventories === true || formValues.include_driver_inventories === "true") url += "&include_driver_inventories=true"
        if (rows != undefined) url += "&rows=" + encodeURIComponent(rows);
        window.open(url + "&_blank");
        setShowInventoryReportForm(false);

    }

    return <div className="dspr-inventory">
        <Card className={'card-container'}>
            <CardHeader title="Available Inventory" />

            <CardBody className="availability-table-container-cardbody">
                {props.products &&
                    <AutoComplete
                        options={Object.keys(props.products).map(productKey => ({
                            value: productKey,
                            text: props.products[productKey].name
                        }))}
                        className="inventory-search-autocomplete"
                        error=""
                        touched={false}
                        label="Search for Inventory by Product Name"
                        input={null}
                        handleAutoCompleteSelected={handlePriceFormOpen}
                        sendCurrentSearchValue={setSearchProduct}
                        clearOnBlur={false}
                    />
                }
                <FormControlLabel
                    control={<Checkbox checked={state.onlyShowCarriedInventoryProduct}
                        onChange={() => handleAvailableInventoryToggle()}
                        color="primary" />}
                    label="Hide out of stock products"
                />
                <VirtualizedTable
                    tableClasses="availability-table"
                    itemCount={searchFilteredAvailableInventoryRows.length}
                    rowHeight={80}
                    tableWidth={(((numberDrivers + 3) * 70 + 100) + "px")}
                    renderItems={availabilityRows}
                    header={(
                        <header>
                            <span className={'availability-table-product-name'}>Product</span>
                            <span>Price</span>
                            <span>Stock</span>
                            {allInventoryAvailableHeaders}
                        </header>
                    )} />
            </CardBody>
            <CardContent className={'availability-table-key'}>
                <fieldset>
                    <legend>Inventory Availability</legend>
                    <section className={'availability-table-key-container'}>
                        <div className={'availability-table-key-entry'}>
                            <span className={'not-carried-by-driver'} />
                            <p>- Not Carried By Drivers</p>
                        </div>
                        <div className={'availability-table-key-entry'}>
                            <span className={'no-price'} />
                            <p>- No Price</p>
                        </div>
                    </section>
                </fieldset>
            </CardContent>

            <CardHeader title="Reports and Actions" />
            <CardActions>
                <Button variant="contained" color="primary" className="available-inventory-csv-button">
                    <a href={currentDSPRInventorySnapshotCSVDownloadLink(props.dsprId)}>Download Available Inventory CSV</a>
                </Button>

                <Button variant={'contained'} color="primary"
                    onClick={() => setShowInventoryReportForm(true)}
                >
                    Create Inventory Report
                </Button>

                {(props.isMetrcDspr || props.isBatchDspr || props.isNonMetrcScanningDspr) &&
                    <Button variant={'outlined'}
                        onClick={handleQRCodeRedownloadPDFModalOpen}
                        disabled={!props.isFetchingMetrcTags && props.isMetrcDspr && props.metrcTagsForAutoSelect && props.metrcTagsForAutoSelect.length === 0}
                    >
                        {props.isMetrcDspr ? 'Print Metrc Tags' : 'Print QR Codes'}
                    </Button>
                }
            </CardActions>
        </Card>
        <Card className={'card-container'}>
            <CardHeader title="DSPR Current Inventory Details" />
            <CardBody className="current-inventory-table-container-cardbody">
                <FormControlLabel
                    control={<Checkbox checked={state.onlyShowInStockProduct}
                        onChange={() => handleCurrentInventoryToggle()}
                        color="primary"
                        style={{ marginBottom: 16 }}
                    />}
                    label="Hide out of stock products"
                />
                <VirtualizedTable
                    tableClasses="inventory-detail-table"
                    itemCount={searchFilteredCurrentInventoryRows.length}
                    renderItems={dsprInventoryTableRows}
                    header={[
                        'Product',
                        'Quantity Available',
                        'Total Cost of Inventory',
                    ]} />
            </CardBody>
        </Card>
        <Card className="inventory-transaction-forms card-container">
            <CardHeader title={
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant='h5'>New DSPR Inventory/Correction Transaction</Typography>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => history.push(`/dspr/${props.dsprId}/inventory/invoice/batch/transaction-history`)} //{/*InvoiceDSPRProductInventoryTransactionContainer*/}
                    >
                        Overview Invoices
                    </Button>
                </div>
            } />
            <CardBody>
                <FormControl>
                    <InputLabel id="driver-or-stock-select-label">Type</InputLabel>
                    <Select
                        labelId="driver-or-stock-select-label"
                        value={state.inventoryTransactionDriverOrStock || ''}
                        onChange={handleDriverOrStockSelection}
                        style={{ minWidth: '100px' }}
                    >
                        <MenuItem value={DRIVER_OR_STOCK_TYPE.STOCK}>DSPR Stock</MenuItem>
                        <MenuItem value={DRIVER_OR_STOCK_TYPE.DRIVER}>Driver</MenuItem>
                    </Select>
                </FormControl>
                {state.inventoryTransactionDriverOrStock === DRIVER_OR_STOCK_TYPE.STOCK &&
                    <FormControl>
                        <InputLabel id="stock-transaction-type-select-label">Subtype</InputLabel>
                        <Select
                            labelId="stock-transaction-type-select-label"
                            value={state.transactionType || ''}
                            onChange={handleTransactionTypeSelection}
                            style={{ minWidth: '100px' }}
                        >
                            <MenuItem value={TRANSACTION_TYPES.STOCK}>Inventory Purchase</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.STOCK_ADJUSTMENT}>Transaction Correction</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.LOST}>Record Lost Stock Inventory</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.FOUND_LOST}>Record Found Lost Inventory</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.RETURN_TO_VENDOR}>Return To Vendor</MenuItem>
                        </Select>
                    </FormControl>}
                {state.inventoryTransactionDriverOrStock === DRIVER_OR_STOCK_TYPE.STOCK
                    && state.transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT &&
                    <FormControl>
                        <InputLabel id="cost-or-quantity-select-label">Cost or Quantity</InputLabel>
                        <Select
                            className="cost-or-quantity-select"
                            labelId="cost-or-quantity-select-label"
                            value={state.correctCostOrQuantity || ''}
                            onChange={handleCostOrQuantitySelection}
                            style={{ minWidth: '100px' }}
                        >
                            <MenuItem value={COST_OR_QUANTITY.COST}>Correct Cost</MenuItem>
                            <MenuItem value={COST_OR_QUANTITY.QUANTITY}>Correct Quantity</MenuItem>
                        </Select>
                    </FormControl>}
                {state.inventoryTransactionDriverOrStock === DRIVER_OR_STOCK_TYPE.DRIVER &&
                    <FormControl>
                        <InputLabel id="driver-transaction-type-select-label">Subtype</InputLabel>
                        <Select
                            labelId="driver-transaction-type-select-label"
                            value={state.transactionType || ''}
                            onChange={handleTransactionTypeSelection}
                            style={{ minWidth: '100px' }}
                        >
                            <MenuItem value={TRANSACTION_TYPES.LOST}>Lost Inventory</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.FOUND_LOST}>Found Lost Inventory</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.FOUND_ORDER_MISTAKE}>Found From Order Mistake</MenuItem>
                            <MenuItem value={TRANSACTION_TYPES.RETURN_TO_VENDOR}>Return To Vendor</MenuItem>
                        </Select>
                    </FormControl>}
                {state.showInventoryTransactionForm &&
                    <DSPRInventoryTransactionForm
                        products={props.dspProductsForAutoselect}
                        onSubmit={handleSubmitInventoryTransactionForm(state.transactionType)}
                        handleMetrcTagAutoCompleteSelected={props.interceptMetrcTagAutoCompleteSelected}
                        handleBatchNumberAutoCompleteSelected={props.interceptBatchNumberAutoCompleteSelected}
                        handleProductAutoCompleteSelected={props.interceptAutocompleteSelected}
                        handleDriverAutoCompleteSelected={props.interceptDriverAutocompleteSelected}
                        drivers={getDriversForInventoryCorrectionDropdown()}
                        isStock={state.inventoryTransactionDriverOrStock === DRIVER_OR_STOCK_TYPE.STOCK}
                        transactionType={state.transactionType}
                        isCost={state.correctCostOrQuantity === COST_OR_QUANTITY.COST}
                        isMetrcDspr={props.isMetrcDspr}
                        isBatchDspr={props.isBatchDspr}
                        isLoading={isLoading}
                        handleUpdateMetrcTagsFromMetrc={handleUpdateMetrcTagsFromMetrc}
                        metrcTagsForAutoSelect={props.metrcTagsForAutoSelect}
                        batchNumbersForAutoSelect={props.batchNumbersForAutoSelect}
                        metrcReturnReasonsForAutoSelect={props.metrcReturnReasonsForAutoSelect}
                        handleMetrcReturnReasonsAutoCompleteSelected={props.interceptMetrcReturnReasonsAutoCompleteSelected}
                    />}
            </CardBody>
        </Card>


        {/* Metrc Tag Inventory Correction
        {props.isMetrcDspr &&
            <Card className="inventory-transaction-forms card-container">
                <CardHeader title='Metrc Tag Product Lookup' className='metrc-product-lookup-card-header' />
                <CardBody className='metrc-product-lookup-card-body'>
                    <DSPRMetrcTagProductLookupForm
                        productsForAutoSelect={props.dspProductsForAutoselect}
                        metrcTagsForAutoSelect={props.metrcTagsForAutoSelect}
                        handleMetrcTagAutoCompleteSelected={props.interceptMetrcTagForProductLookupFormAutoCompleteSelected}
                        handleProductAutoCompleteSelected={props.interceptProductForProductLookupFormAutocompleteSelected}
                        metrcTagValueInMetrcTagProductLookupForm={props.metrcTagValueInMetrcTagProductLookupForm}
                        productValueInMetrcTagProductLookupForm={props.productValueInMetrcTagProductLookupForm}
                        onSubmit={handleSubmitMetrcProductLookupForm}
                        isFetchingMetrcProductAssociations={props.isFetchingMetrcProductAssociations}
                    />
                    {fetchedMetrcProductAssociations !== null && props.products &&
                    <div style={{marginTop: 32}}>
                        {fetchedMetrcProductAssociations.length === 0 &&
                            <Typography variant='body2' style={{fontSize: '1rem'}}>No results found</Typography>
                        }
                        {props.isFetchingMetrcProductAssociations 
                            ?   <Fade
                            in={props.isFetchingMetrcProductAssociations }
                            style={{
                              transitionDelay: '400ms',
                            }}
                            unmountOnExit
                          >
                            <CircularProgress />
                          </Fade>
                          : <>
                            {fetchedMetrcProductAssociations.length > 0 && 
                                <>
                                    <Typography variant='subtitle1' style={{fontSize: '1.25rem'}}>Metrc Tag Product Association Table</Typography>
                                    <DSPRMetrcTagProductLookupFormVirtualizedTable
                                        products={props.products}
                                        metrcProductAssociations={fetchedMetrcProductAssociations}
                                        handleClickModify={handleClickModifyInMetrcProductLookupForm}
                                        updateModifyButtonText={updateModifyButtonText}
                                        metrcAssociationRowToChange={metrcAssociationRowToChange}
                                    />
                                </>
                            }
                            </>
                        }
                    </div>
                    }

                </CardBody>
            </Card>
        } */}
        {(props.isBatchDspr || props.isMetrcDspr) && <Card className="inventory-transaction-forms card-container">
            <CardHeader title='Batch Number Product Lookup' className='metrc-product-lookup-card-header' />
            <CardBody className='metrc-product-lookup-card-body'>
                <DSPRMetrcTagProductLookupForm
                    productsForAutoSelect={props.dspProductsForAutoselect}
                    batchNumbersForAutoSelect={props.batchNumbersForAutoSelect}
                    metrcTagsForAutoSelect={props.metrcTagsForAutoSelect}
                    handleBatchNumberAutoCompleteSelected={props.interceptBatchNumberForProductLookupFormAutoCompleteSelected}
                    handleMetrcTagAutoCompleteSelected={props.interceptMetrcTagForProductLookupFormAutoCompleteSelected}
                    handleProductAutoCompleteSelected={props.interceptProductForProductLookupFormAutocompleteSelected}
                    metrcTagValueInMetrcTagProductLookupForm={props.metrcTagValueInMetrcTagProductLookupForm}
                    batchNumberValueInMetrcTagProductLookupForm={props.batchNumberValueInBatchNumberProductLookupForm}
                    productValueInMetrcTagProductLookupForm={props.productValueInBatchNumberProductLookupForm}
                    onSubmit={props.isBatchDspr ? handleSubmitBatchNumberProductLookupForm : handleSubmitMetrcProductLookupForm}
                    isFetchingMetrcProductAssociations={props.isFetchingMetrcProductAssociations}
                    isFetchingBatchNumberProductAssociations={props.isFetchingBatchNumberProductAssociations}
                    isBatchBased={props.isBatchDspr}
                    isMetrcDspr={props.isMetrcDspr}
                />

                {/* Batch Number Product Lookup Virtualized Table */}
                {fetchedBatchNumberProductAssociations !== null && props.products &&
                    <div style={{ marginTop: 32 }}>
                        {fetchedBatchNumberProductAssociations.length === 0 &&
                            <Typography variant='body2' style={{ fontSize: '1rem' }}>No results found</Typography>
                        }
                        {props.isFetchingMetrcProductAssociations
                            ? <Fade
                                in={props.isFetchingMetrcProductAssociations}
                                style={{
                                    transitionDelay: '400ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress />
                            </Fade>
                            : <>
                                {fetchedBatchNumberProductAssociations.length > 0 &&
                                    <>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.25rem' }}>Batch Number Product Association Table</Typography>
                                        <DSPRMetrcTagProductLookupFormVirtualizedTable
                                            products={props.products}
                                            batchNumberProductAssociations={fetchedBatchNumberProductAssociations.reverse()}
                                            handleClickModify={handleClickModifyInMetrcProductLookupForm}
                                            updateModifyButtonText={updateModifyButtonText}
                                            metrcAssociationRowToChange={metrcAssociationRowToChange}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                }

                {/* Metrc Tag Product Lookup Virtualized Table */}
                {fetchedMetrcProductAssociations !== null && props.products &&
                    <div style={{ marginTop: 32 }}>
                        {fetchedMetrcProductAssociations.length === 0 &&
                            <Typography variant='body2' style={{ fontSize: '1rem' }}>No results found</Typography>
                        }
                        {props.isFetchingMetrcProductAssociations
                            ? <Fade
                                in={props.isFetchingMetrcProductAssociations}
                                style={{
                                    transitionDelay: '400ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress />
                            </Fade>
                            : <>
                                {fetchedMetrcProductAssociations.length > 0 &&
                                    <>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.25rem' }}>Metrc Tag Product Association Table</Typography>
                                        <DSPRMetrcTagProductLookupFormVirtualizedTable
                                            products={props.products}
                                            metrcProductAssociations={fetchedMetrcProductAssociations}
                                            handleClickModify={handleClickModifyInMetrcProductLookupForm}
                                            updateModifyButtonText={updateModifyButtonText}
                                            metrcAssociationRowToChange={metrcAssociationRowToChange}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                }

            </CardBody>
        </Card>}

        {/*Price Form Modal*/}
        <Dialog
            open={state.isPriceFormOpen}
            onClose={handlePriceFormClose}
        >
            <DialogTitle>Pricing</DialogTitle>
            <DialogContent>
                <DSPRProductPriceForm
                    name={state.productNameForPricing}
                    onSubmit={handleSubmitPriceForm}
                    isFlower={state.pricingProductIsFlower}
                />
            </DialogContent>
        </Dialog>

        {/*Download Metrc Tag Modal*/}
        <Dialog
            open={showMetrcPDFModal}
            onClose={handleMetrcPDFModalClose}
        >
            <DialogTitle>Download Barcodes For Products?</DialogTitle>
            <DialogContent>
                <DialogContentText>Make sure to have QR Inch and a Half label paper in the printer</DialogContentText>

            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                    onClick={handleMetrcPDFModalClose}
                    color={'secondary'}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        setFetchMetrcPDF(true)
                        handleMetrcPDFModalClose();
                    }}
                    color={'primary'}
                >
                    Download Tags
                </Button>
            </DialogActions>
        </Dialog>

        {/*Redownload Metrc Tag Modal*/}
        <Dialog
            open={showMetrcRedownloadPDFModal}
            onClose={handleQRCodeRedownloadPDFModalClose}
        >
            <DialogContent>
                <DSPRMetrcTagOrQRCodeDownloadForm
                    handleMetrcTagAutoCompleteSelected={props.interceptMetrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected}
                    handleBatchNumberAutoCompleteSelected={props.interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected}
                    closeRedownloadMetrcModal={handleQRCodeRedownloadPDFModalClose}
                    onSubmit={handleSubmitQRCodeRedownloadPDF}
                    products={props.dspProductsForAutoselect}
                    handleProductAutoCompleteSelected={props.interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected}
                    isMetrcDspr={props.isMetrcDspr}
                    isBatchDspr={props.isBatchDspr}
                    barcodeTypes={[{ value: "qrInchAndHalf", text: 'QR (1.5")' }, { value: "barcodeTwoThirds", text: 'Barcode (2/3")' }]}
                    handleQRTypeAutoCompleteSelected={props.interceptQRTypeForBarcodeDownloadFormAutocompleteSelected}
                    fetchMetrcProductAssociation={props.fetchMetrcProductAssociation}
                    isFetchingMetrcProductAssociations={props.isFetchingMetrcProductAssociations}
                    fetchBatchProductAssociation={props.fetchBatchNumberProductAssociation}
                    isFetchingBatchProductAssociations={props.isFetchingBatchNumberProductAssociations}
                    dsprId={props.dsprId}
                />
            </DialogContent>
        </Dialog>

        {/* Modify a Product's Metrc Tag Modal */}
        <Dialog
            open={showModifyProductMetrcTagForm}
            // for development:
            // open={true}
            onClose={() => setShowModifyProductMetrcTagForm(false)}
        >
            <DialogContent>
                <DSPRModifyProductMetrcTagForm
                    unassignedMetrcTagsForAutoSelect={props.unassignedMetrcTagsForAutoSelect}
                    handleMetrcTagAutoCompleteSelected={props.interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected}
                    handleUpdateMetrcTagsFromMetrc={handleUpdateMetrcTagsFromMetrc}
                    productInfo={productForModifyProductMetrcTagForm}
                    // for development:
                    // productInfo={{product: {name: 'Blue Dream'}, metrcTag: '1A4FF0300000262000000017', quantity: 100}}
                    isLoading={isLoading}
                    onSubmit={handleSubmitModifyProductMetrcTag}
                    onCancel={() => setShowModifyProductMetrcTagForm(false)}
                />
            </DialogContent>

        </Dialog>
        {/* <DsprInventoryAdditionalInformationCard dsprId={props.dsprId}/> */}

        {/*Error Alert Modal*/}
        {showErrorModal && <SweetAlert
            error
            style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
            title={errorModalTitle}
            onConfirm={() => setShowErrorModal(false)}
            showConfirm={false}
            allowEscape={true}
            closeOnClickOutside={true}
        >
            {errorModalText}
            <Button
                onClick={() => setShowErrorModal(false)}
                variant={'outlined'}
                color={'primary'}
                style={{ display: 'block', marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
            >
                Close
            </Button>
        </SweetAlert>}

        {/*Success Snackbar Alert for Updating Metrc Tags from Metrc*/}
        <SnackBarAlertSuccess
            showSnackbarAlert={showSnackbarSuccessAlert}
            setIsVisible={setShowSnackbarSuccessAlert}
            alertText={snackbarSuccessAlertText}
        />

        {/*Error Snackbar Alert for Updating Metrc Tags from Metrc*/}
        <SnackBarAlertError
            showSnackbarAlert={showSnackbarErrorAlert}
            setIsVisible={setShowSnackbarErrorAlert}
            alertTitle={snackbarErrorAlertTitle}
            alertText={snackbarErrorAlertText}
        />

        <Dialog
            open={showInventoryReportForm}
            onClose={() => setShowInventoryReportForm(false)}>
            <DialogTitle>Create A Product</DialogTitle>
            <DialogContent>
                <InventoryReportForm
                    onSubmit={handleSubmitInventoryReportForm}
                    initialValues={{
                        report_type: props.isBatchDspr || props.isMetrcDspr ? "" : "product_based",
                        product_id: true,
                        name: true,
                        brand: true,
                        quantity: true,
                        include_driver_inventories: true
                    }}
                    canBreakIntoBatches={props.isBatchDspr || props.isMetrcDspr}
                />
            </DialogContent>
        </Dialog>
    </div>;
}

export default DSPRInventory;