import React, {Fragment, useEffect, useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import OrderProductAutocompleteForm from "../../containers/OrderProductAutocompleteForm";
import {interceptBatchNumberAutoCompleteSelected, interceptOrderDetailsForProductAutoCompleteFormSelected} from "../../actions/formHelperActions";
import { interceptMetrcTagAutoCompleteSelected } from '../../actions/formHelperActions';
import {BatchNumber, MetrcTag, OrderDetail} from "../../store/reduxStoreState";
import {
    deactivateOrderScans,
    ORDER_SCAN_SUBMIT_FAILURE,
    ORDER_SCAN_SUBMIT_SUCCESS, RESET_ORDER_SCANS_FAILURE,
    submitBarcodeScan,
    SubmitBarcodeScanProps
} from "../../actions/scanActions";
import {useDispatch} from "react-redux";
import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../middleware/api";
import { getDSPRMetrcTagProductAssociations, GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE, GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS, MetrcTagProductAssociationsGetterProps } from '../../actions/metrcTagActions';
import { BatchNumberProductAssocationGetterProps, getBatchNumberProductAssociations, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS } from '../../actions/batchNumberActions';

export const ScanProductButtons:React.FC<any> = props => {
    const {
        disableExtraButtons,
        setExtraOrderFunctionalityErrorCallBack,
        setDisableButtonCallBack,
        closeModal,
        order,
        orderId,
        dspr,
        isManagerForDSPR,
        isDriverForOrder,
        orderDetails
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const [isFetchingMetrcProductAssociations, setIsFetchingMetrcProductAssociations] = useState<boolean>(false);
    const [fetchedMetrcProductAssociations, setFetchedMetrcProductAssociations] = useState<MetrcTag[]>(null);
    const [isFetchingBatchProductAssociations, setIsFetchingBatchProductAssociations] = useState<boolean>(false);
    const [fetchedBatchedNumberProductAssociations, setBatchNumberProductAssociations] = useState<BatchNumber[]>(null);

    const dispatch = useDispatch();
    const handleNewScanWithResponseCheck = (values: OrderDetail) => {

    const props: SubmitBarcodeScanProps = {
        orderId: order.id,
        productId: values["orderDetailId"].product.id,
        orderDetailId: values["orderDetailId"].id,
        metrcTag: values["metrcTag"],
        batchNumber: values["batchNumber"]
    };

    return dispatch<any>(submitBarcodeScan(props))
        .then((response) => {
            if (response.type === ORDER_SCAN_SUBMIT_SUCCESS) {
                // showSuccessAlert();
            }
            if (response.type === ORDER_SCAN_SUBMIT_FAILURE) {
                const errorMessage = response.error;
                setExtraOrderFunctionalityErrorCallBack(response.error);
                return errorMessage;
            }
            return response;
        })
    }

    const handleDeactivateOrderScans = () => {
        setDisableButtonCallBack(true);
        dispatch<any>(deactivateOrderScans(orderId)).then((response)=> {
            setDisableButtonCallBack(false);
            if(response.type === RESET_ORDER_SCANS_FAILURE) {
                setExtraOrderFunctionalityErrorCallBack(response.error)
            } else {
                setExtraOrderFunctionalityErrorCallBack("")
                if(closeModal) closeModal();
            }
        })
    }

    useEffect(() => {

        if(isManagerForDSPR || isDriverForOrder) return;

        let previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.packaged);
        if(previousOrderStatusIsValid && !dspr.allowDriverKits&& dspr.isScanOrderDetail)  setShowBasedOnOrderStatus(true);

        previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.completed);
        if(previousOrderStatusIsValid && dspr.allowDriverKits && dspr.isScanOrderDetail)  setShowBasedOnOrderStatus(true);

    }, []);

    const fetchMetrcProductAssociation = (formValues: MetrcTagProductAssociationsGetterProps) => {
        setIsFetchingMetrcProductAssociations(true);
        return dispatch<any>(getDSPRMetrcTagProductAssociations(formValues))
            .then(response => {
                setIsFetchingMetrcProductAssociations(false);
                return response
            });
    }

    const fetchBatchNumberProductAssociation = (formValues: BatchNumberProductAssocationGetterProps) => {
        setIsFetchingBatchProductAssociations(true);

        return dispatch<any>(getBatchNumberProductAssociations(formValues)).then(response => {
            setIsFetchingBatchProductAssociations(false);
            return response
        });
    }

    const handleSubmitMetrcProductLookupForm = (productId: number) => {
        const lookupObject: MetrcTagProductAssociationsGetterProps = { productId, }
        fetchMetrcProductAssociation(lookupObject).then(response => {
            if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS) {
                if (response.response.entities.dsprMetrcTags) {
                    const metrcProductAssociations = Object.values<any>(response.response.entities.dsprMetrcTags)
                        .filter((metrcProductAssociation) => metrcProductAssociation.productId);
                    setFetchedMetrcProductAssociations(metrcProductAssociations);
                } else {
                    setFetchedMetrcProductAssociations([]);
                }
            } 
            if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE) {
                // setShowErrorModal(true);
                // setErrorModalTitle('Error Encountered!');
                // setErrorModalText(response.error || 'Unable to fetch Metrc Tag Product Associations');

                setFetchedMetrcProductAssociations([]);
            }
        })
    }

    const handleSubmitBatchNumberProductLookupForm = (productId: number) => {
        const lookupObject: BatchNumberProductAssocationGetterProps = { productId, dsprId: dspr.id, activeOnly: true }
        fetchBatchNumberProductAssociation(lookupObject).then(response => {
            if (response.type === GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS) {
                if (response.response.entities.dsprBatchNumbers) {
                    const batchNumberProductAssociations = Object.values<any>(response.response.entities.dsprBatchNumbers)
                        .filter((batchNumberProductAssociations) => batchNumberProductAssociations.product);
                        setBatchNumberProductAssociations(batchNumberProductAssociations);
                } else {
                    setBatchNumberProductAssociations([]);
                }
            } 
            if (response.type === GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE) {
                // setShowErrorModal(true);
                // setErrorModalTitle('Error Encountered!');
                // setErrorModalText(response.error || 'Unable to fetch Metrc Tag Product Associations');

                setBatchNumberProductAssociations([]);
            }
        })
    }

    return showBasedOnOrderStatus && orderDetails
    && <Fragment>
        <Typography style={{textDecoration: 'underline'}} display="inline">Next Step Action </Typography>
        <Typography paragraph>
            Autocomplete for manually scanning products
            here, and the button below only becomes enabled
            after all products scanned
        </Typography>

        <OrderProductAutocompleteForm
            order={order}
            handleSubmitMetrcProductLookupForm={handleSubmitMetrcProductLookupForm}
            handleSubmitBatchNumberProductLookupForm={handleSubmitBatchNumberProductLookupForm}
            interceptOrderDetailsForProductAutoCompleteFormSelected={interceptOrderDetailsForProductAutoCompleteFormSelected}
            onSubmit={(values) => handleNewScanWithResponseCheck(values)}
            handleMetrcTagAutoCompleteSelected={interceptMetrcTagAutoCompleteSelected}
            handleBatchNumberAutoCompleteSelected={interceptBatchNumberAutoCompleteSelected}
            isLoading={isFetchingMetrcProductAssociations}
            metrcTagsForAutoSelect={fetchedMetrcProductAssociations ? fetchedMetrcProductAssociations.map((metrcTag:MetrcTag) => ({text: metrcTag.metrcTag, value: metrcTag.metrcTag})): []}
            batchNumbersForAutoSelect={fetchedBatchedNumberProductAssociations ? fetchedBatchedNumberProductAssociations.map((batchNumber: BatchNumber) => ({text: batchNumber.batchNumber, value: batchNumber.batchNumber})): []}
            orderDetails={orderDetails}
            dspr={dspr}
        />



            <div style={{width: "100%", marginTop: "8px"}}>
            <Button variant="contained" color="secondary" disabled={disableExtraButtons} onClick={() => handleDeactivateOrderScans()}>
            Reset Scans
            </Button>
            </div>

    </Fragment>

}