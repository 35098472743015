import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button } from "@material-ui/core";

import './SearchFormStyles.scss';

const validate = (values) => {
    const errors: any = {};
    if (!values.searchQuery) {
        errors.searchQuery = 'Must Enter a Search Query'
    }
    return errors;
};

const renderField = ({input, label, type, meta: {touched, error}}) => (
    <TextField
        label={label}
        type={type}
        {...input}
        error={!!(touched && error)}
        helperText={touched && error ? error : ''}
        style={{minWidth: '208px'}}/>
);

interface SearchFormProps {
    isCoupon?: boolean;
    isApplicableTax?: boolean;
}

const SearchForm = (props: SearchFormProps & InjectedFormProps<{}, SearchFormProps>) => {
    const {handleSubmit, isCoupon,isApplicableTax} = props;

    const label = isCoupon ? "Enter a Partial Coupon Code" : isApplicableTax ? "Search Applicable Tax" : "Search Users";

    return (
        <form onSubmit={handleSubmit} className="search-form">
            <Field name="searchQuery" type="text" component={renderField} label={label} className="field"/>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Search</Button>
        </form>
    )
};

export default reduxForm<any, SearchFormProps>({
    form: 'SearchForm',
    validate,
})(SearchForm)