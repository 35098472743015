import { State } from "../../store/reduxStoreState";
import { createSelector } from 'reselect';
import { getDSPFromProps } from "../dspSelectors";
import { getDSPRPromotionsForProductCategories } from "../dsprSelectors";

export const getDSPRProductCategoriesFromState = (state: State) => state.componentState.dsprMenuManagementContainer.dspProductCategories

export const getProductCategoriesForDSPFromProps = createSelector(
    [getDSPFromProps, getDSPRProductCategoriesFromState], (dsp, categories) => {
        return dsp ?
            categories ?
                Object.values(categories).filter((category) => category.deliveryServiceProvider === dsp.id)
                : undefined
            : undefined
    }
)

export const getActiveProductCategoriesForDSPFromComponentPropsWithProductCategoryPromotions = createSelector(
    [getProductCategoriesForDSPFromProps, getDSPRPromotionsForProductCategories], (productCategories, productCategoryPromotions) => {
        return productCategories ?
            productCategoryPromotions ?
                productCategories.map(productCategory => ({...productCategory, currentPromotion: productCategoryPromotions.find(promotion=> promotion.current && promotion.productCategory === productCategory.id)})) 
                :undefined
            :undefined
    } 
)