import React, {useRef, useCallback, useState, useEffect} from 'react'
import { Polygon } from '@react-google-maps/api';

interface PolygonControllerProps {
    serviceArea: any;
    currentServiceAreaId: number;
    handlePolygonClick: (id: number, driverId: number, polyRef: any) => any;
    setCurrentPolygonEdited: (value: boolean) => any;
    editable: boolean;
    //getVertices: (vertices) => void
}

const PolygonController: React.FC<PolygonControllerProps> = (props) => {
    const { serviceArea, currentServiceAreaId, handlePolygonClick, setCurrentPolygonEdited, editable } = props 
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    const [serviceAreaVertices, setServiceAreaVertices] = useState(null)

    useEffect(()=> {
        if(serviceArea && serviceArea.dsprDriverServiceAreaVertices) {
            setServiceAreaVertices(serviceArea.dsprDriverServiceAreaVertices.map(vertex => ({lat: vertex.latitude, lng: vertex.longitude})))
        }
    },[serviceArea])
    // Call setPath with new edited path
    const onEdit = useCallback(() => {
    if (polygonRef.current) {
        setCurrentPolygonEdited(true)
        const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });
        setServiceAreaVertices(nextPath);
    }
    // eslint-disable-next-line
    }, [setServiceAreaVertices]);
    
    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
    polygon => {
        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", () => {
            onEdit()
        }),
        path.addListener("remove_at", () => {
            onEdit()
        }))
    },
    [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
    }, []);

    return serviceAreaVertices && (
        <Polygon path={serviceAreaVertices} options={{
            fillColor: currentServiceAreaId=== serviceArea.id ? serviceArea.cacheable ? "#ffe100" : "#ff88ff" : serviceArea.cacheable ? "#000" : "#4f8dff",
            fillOpacity: 0.4,
            strokeColor: currentServiceAreaId=== serviceArea.id ? serviceArea.cacheable ? "#ffe100" : "#ff88ff": serviceArea.cacheable ? "#000" : "#4f8dff",
            strokeOpacity: 1,
            strokeWeight: 1
        }}
        editable={currentServiceAreaId===serviceArea.id && editable}
        draggable={currentServiceAreaId===serviceArea.id && editable}
        onMouseUp={(event:any)=> {
            if((event.path !== undefined) && (event.vertex || event.edge)) {
                onEdit()
            }
        }}
        onDragEnd={onEdit}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => {
            handlePolygonClick(serviceArea.id, serviceArea.currentDriver, polygonRef)
        }}
        ref={polygonRef}/>
    );
}

export default PolygonController