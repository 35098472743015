import React, { useState, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    CardContent,
    Card,
    Typography,
    Switch,
    FormControlLabel,
    FormControl, CardActions, Button, Tooltip
} from '@material-ui/core';

import { CouponWithUserDetails } from "./Coupons";
import { DspProductCategory, State, User } from "../store/reduxStoreState";
import { getDSPProductCategories } from '../selectors/dspProductSelector';
import { getUsers } from "../selectors/userSelectors";
import { getDSPRManagerWithUser } from "../selectors/dsprManagerSelectors";
import './CouponWithDetailsStyles.scss';

interface CouponWithDetailsProps {
    coupon: CouponWithUserDetails;
    toggler: (couponId: number) => any;
    isManagerForCurrentDSPR: boolean;
    isSystemAdmin: boolean;
    handleClose: () => void;
    handleOpenCouponForm: () => void;
}

const CouponWithDetails = (props: CouponWithDetailsProps) => {
    const { coupon, toggler, isManagerForCurrentDSPR, isSystemAdmin, handleClose, handleOpenCouponForm, } = props;
    const [couponIsActive, setCouponIsActive] = useState(coupon.isActive);

    const productCategories = useSelector<State, { [key: number]: DspProductCategory }>(getDSPProductCategories);
    const users = useSelector<State, { [key: number]: User }>(getUsers);
    const manager = useSelector<State, any>(state => coupon.lastUpdateDSPRManager && coupon.lastUpdateDSPRManager.id && getDSPRManagerWithUser(state, {dsprManagerId: coupon.lastUpdateDSPRManager.id}), shallowEqual);

    /**Returns a formatted discount string. Formatting varies based upon discount type*/
    const renderDiscountDisplay = () => {
        const {discountType} = coupon
        switch (discountType) {
            case 'bogo':
            case 'boghalf':
            case 'btgo':
                return discountType.toUpperCase();
            case 'percent':
                return `${coupon.discountAmount}% off`;
            case 'absolute':
                return `$${coupon.discountAmount} off`;
            default:
                return discountType;
        }
    }

    /**Returns either the string 'All Categories Allowed' or a list of categories the coupon can apply to*/
    const renderCategoriesAllowed = () => {
        const {specificallyAllowedProductCategories: couponCategories} = coupon;

        if (couponCategories.length === 0) {
            return 'All categories allowed';
        }

        return couponCategories.map(categoryId => (
            <Typography className={'coupon-list'}
                        component={'span'}
                        variant={'body2'}
                        display={'block'}
                        key={categoryId}>{productCategories[categoryId].name}</Typography>
        ));
    }

    /**Returns either the string 'All users allowed' or a list or allowed users first and last names
     *  - if the logged in user is a system admin, each listed user name links to the respective user page
     * */
    const renderUsersAllowed = () => {
        const {specificallyAllowedUsers: couponUsers} = coupon;

        if (couponUsers.length === 0) {
            return 'All users allowed';
        }

        return couponUsers.map(userId => {
            const {firstName, lastName, id} = users[userId];

            if (isSystemAdmin) {
                return (
                    <Link to={`/user/${id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={userId} style={{display: 'block', width: 'fit-content'}}
                    >
                        <Typography className={'user-list'}
                                    component={'span'}
                                    variant={'body2'}
                                    display={'block'}
                                    key={`${id}_${firstName}`}
                                    //gutterBottom={true}
                                    color={'primary'}>
                            {firstName} {lastName}
                        </Typography>
                    </Link>
                )
            } else {
                return <Typography className={'user-list'}
                                   key={`${id}_${firstName}`}
                                   display={'block'}
                                   component={'span'}
                                   variant={'body2'}>
                    {`${firstName} ${lastName}`}
                </Typography>
            }
        });
    }

    return <Fragment>
        <Card className={'CouponWithDetailsCard'}>

            <div className={'card-header'}>
                <Typography variant={'h5'}>{coupon.code}</Typography>
                <FormControl className={'active-switch-container'}>
                    <FormControlLabel
                        label={'Active'}
                        labelPlacement={'start'}
                        control={
                            <Switch checked={couponIsActive}
                                    color="primary"
                                    disabled={!isManagerForCurrentDSPR}
                                    onChange={() => {
                                        toggler(coupon.id);
                                        setCouponIsActive(couponIsActive => !couponIsActive);
                                    }}
                            />
                        }
                    />
                </FormControl>
            </div>

            <CardContent className={'coupon-detail-content'}>
                <List>

                    {/*Discount*/}
                    <ListItem>
                        <ListItemText primary={'Discount'} secondary={renderDiscountDisplay()}/>
                    </ListItem>

                    {/*Allowed Categories*/}
                    <ListItem>
                        <ListItemText primary={'Categories'} secondary={renderCategoriesAllowed()}/>
                    </ListItem>

                    {/*Allowed Products*/}
                    <ListItem>
                        <ListItemText
                            primary={'Products'}
                            secondary={coupon.specificallyAllowedProducts.length === 0
                                ? 'All products allowed'
                                : 'All products in assigned categories allowed'}
                        />
                    </ListItem>

                    {/*First Time User?*/}
                    <ListItem>
                        <ListItemText primary={'First Time User Only?'}
                                      secondary={coupon.isFirstTimeUserOnly ? 'Yes' : 'No'}/>
                    </ListItem>

                    {/*Allowed Users*/}
                    <ListItem>
                        <ListItemText primary={'Users'} secondary={renderUsersAllowed()}/>
                    </ListItem>

                    {/*Max uses per user */}
                    <ListItem>
                        <ListItemText primary={'Max Uses per User'} secondary={coupon.maxPerUserUsageTimes}/>
                    </ListItem>

                    {/*Max discount amount*/}
                    <ListItem>
                        <ListItemText primary={'Max Discount Amount'} secondary={`$${coupon.maxDiscountAmount}`}/>
                    </ListItem>

                    {/*Last modified by*/}
                    <ListItem>
                        <ListItemText primary={'Last Modified By'} secondary={
                            coupon.lastUpdateDSPRManager && manager.user
                                ? `${manager.user.firstName} ${manager.user.lastName}`
                                : 'Not previously modified'
                        }
                        />
                    </ListItem>

                </List>

                <CardActions className={'button-container'}>
                    <Button size="small" color="secondary" variant={'outlined'} onClick={handleClose}>
                        Close
                    </Button>
                    <Tooltip title={coupon.isModified ? <Typography>Only the most recent version of a coupon may be modified</Typography> : ''} placement={'top'} arrow>
                        <span>
                            <Button size="small" color="primary" variant={'contained'} onClick={handleOpenCouponForm} disabled={coupon.isModified}>
                                Modify Coupon
                            </Button>
                        </span>
                    </Tooltip>
                </CardActions>

            </CardContent>

        </Card>
    </Fragment>
}

export default CouponWithDetails;