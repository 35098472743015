import React, { Fragment } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, DialogActions } from '@material-ui/core';

const validate = (values) => {
    const errors: any = {};
    if (!values.price && !values.eighthPrice) {
        errors.price = 'Required';
        errors.eighthPrice = 'Required';
    }
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <TextField label={label} type={type} {...input} error={!!(touched && error)} helperText={touched && error ? error : ''} />
    </div>
)

interface DSPRProductPriceFormProps {
    name: string;
    isFlower: boolean;
}

const DSPRProductPriceForm = (props: DSPRProductPriceFormProps & InjectedFormProps<{}, DSPRProductPriceFormProps>) => {
    const { handleSubmit, name, isFlower } = props;
    return (
        <form onSubmit={handleSubmit}>
            <h3>Set Pricing for {name}</h3>
            {isFlower ? <Fragment>
                <Field name="eighthPrice" component={renderField} label="Eighth Price" />
                <Field name="quarterPrice" component={renderField} label="Quarter Price" />
                <Field name="halfPrice" component={renderField} label="Half Price" />
                <Field name="ozPrice" component={renderField} label="Oz Price" />
            </Fragment> :
                <Field name="price" component={renderField} label="Price" />}
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Set Pricing</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPRProductPriceFormProps>({
    form: 'DSPRProductPriceForm',
    validate
})(DSPRProductPriceForm);
