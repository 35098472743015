import { CALL_API, Schemas } from '../middleware/api';

import { getOrderFromProps } from '../selectors/orderSelectors';

import { getDSPRDriver } from './driverActions';
import { dsprDriverServiceAreaProfile } from './dsprActions';

export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const COMPLETE_ORDER_SUCCESS = 'COMPLETE_ORDER_SUCCESS';
export const COMPLETE_ORDER_FAILURE = 'COMPLETE_ORDER_FAILURE';

const orderCompleter = (orderId) => {
    const order = {
        id: orderId
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [COMPLETE_ORDER, COMPLETE_ORDER_SUCCESS, COMPLETE_ORDER_FAILURE],
            endPoint: `order/completed`,
            schema: Schemas.ORDER,
            body: order
        }

    }
};

export const completeOrder = (orderId) => (dispatch, getState) => {
    return dispatch(orderCompleter(orderId))
        .then((response) => {
            const order = getOrderFromProps(getState(), { orderId });
            return dispatch(getDSPRDriver(order.dsprDriver)).then(() => {
                return response;    //Wait until getDSPRDriver finishes to return that the order has been fully completed
            });
        });
};

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

const orderCanceler = (orderId) => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CANCEL_ORDER, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE],
            endPoint: 'order/canceled',
            body: { id: orderId },
            schema: Schemas.ORDER
        }
    }
};

export const cancelOrder = (orderId) => (dispatch, getState) => {
    return dispatch(orderCanceler(orderId)).then((response) => {
        const order = getOrderFromProps(getState(), { orderId });
        return dispatch(getDSPRDriver(order.dsprDriver)).then(() => {
            return response;
        });
    });
};

export const MARK_IN_PROCESS = 'MARK_IN_PROCESS';
export const MARK_IN_PROCESS_SUCCESS = 'MARK_IN_PROCESS_SUCCESS';
export const MARK_IN_PROCESS_FAILURE = 'MARK_IN_PROCESS_FAILURE';

const orderInProcessMarker = (orderId) => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [MARK_IN_PROCESS, MARK_IN_PROCESS_SUCCESS, MARK_IN_PROCESS_FAILURE],
            endPoint: 'order/in-process',
            body: { id: orderId },
            schema: Schemas.ORDER
        }
    }
};

export const markOrderInProcess = (orderId) => (dispatch, getState) => {
    return dispatch(orderInProcessMarker(orderId)).then((response) => {
        const order = getOrderFromProps(getState(), { orderId });
        return dispatch(getDSPRDriver(order.dsprDriver)).then(() => {
            return response;
        })
    });
};

export const MODIFY_ORDER = 'MODIFY_ORDER';
export const MODIFY_ORDER_SUCCESS = 'MODIFY_ORDER_SUCCESS';
export const MODIFY_ORDER_FAILURE = 'MODIFY_ORDER_FAILURE';

const orderModifier = (oldOrderId: number, orderDetails: any[], newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => {
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [MODIFY_ORDER, MODIFY_ORDER_SUCCESS, MODIFY_ORDER_FAILURE],
            endPoint: 'order/modified',
            body: {
                orderDetails,
                dsprDriver: { id: newDriverId },
                modifiedOrder: { id: oldOrderId },
                transferInventoryWithOrder: transferInventoryOnDriverchange,
                couponCodes: couponCodes || null,
            },
            schema: Schemas.ORDER,
        }
    }
}

export const modifyOrder = (oldOrderId: number, orderDetails: any[], newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => dispatch => {
    return dispatch(orderModifier(oldOrderId, orderDetails, newDriverId,transferInventoryOnDriverchange, couponCodes));
}

export const GET_ORDER_COST = 'GET_ORDER_COST';
export const GET_ORDER_COST_SUCCESS = 'GET_ORDER_COST_SUCCESS';
export const GET_ORDER_COST_FAILURE = 'GET_ORDER_COST_FAILURE';

const orderCostGetter = order => ({
    [CALL_API]: {
        httpAction: 'POST',
        types: [GET_ORDER_COST, GET_ORDER_COST_SUCCESS, GET_ORDER_COST_FAILURE],
        endPoint: 'order/cost',
        body: order,
        schema: Schemas.ORDER
    }
});

export const getOrderCost = order => dispatch => {
    return dispatch(orderCostGetter(order));
};

export const GET_ORDER_DETAILS_WITH_ID = 'GET_ORDER_DETAILS_WITH_ID';
export const GET_ORDER_DETAILS_WITH_ID_SUCCESS = 'GET_ORDER_DETAILS_WITH_ID_SUCCESS';
export const GET_ORDER_DETAILS_WITH_ID_FAILURE = 'GET_ORDER_DETAILS_WITH_ID_FAILURE';

const orderGetter = (orderId: number) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ORDER_DETAILS_WITH_ID, GET_ORDER_DETAILS_WITH_ID_SUCCESS, GET_ORDER_DETAILS_WITH_ID_FAILURE],
            endPoint: `order/${orderId}`,
            schema: Schemas.ORDER,
        }
    }
}

export const getOrderDetailsWithId = (orderId: number) => dispatch => {
    return dispatch(orderGetter(orderId));
}

export const UNCOMPLETE_ORDER = 'UNCOMPLETE_ORDER';
export const UNCOMPLETE_ORDER_SUCCESS = 'UNCOMPLETE_ORDER_SUCCESS';
export const UNCOMPLETE_ORDER_FAILURE = 'UNCOMPLETE_ORDER_FAILURE';

const orderUncompleter = (orderId: number) => {
    const order = {
        id: orderId
    }

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [UNCOMPLETE_ORDER, UNCOMPLETE_ORDER_SUCCESS, UNCOMPLETE_ORDER_FAILURE],
            endPoint: `order/uncompleted`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const uncompleteOrder = (orderId: number) => dispatch => {
    return dispatch(orderUncompleter(orderId));
}


/**Uncanceling an order */

export const UNCANCEL_ORDER = 'UNCANCEL_ORDER';
export const UNCANCEL_ORDER_SUCCESS = 'UNCANCEL_ORDER_SUCCESS';
export const UNCANCEL_ORDER_FAILURE = 'UNCANCEL_ORDER_FAILURE';

const orderUncanceler = (orderId: number, confirmationState: string) => {
    const orderWithConfirmationState = {
        order: {
            id: orderId
        },
        verificationToken: confirmationState
        
    }
    
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [UNCANCEL_ORDER, UNCANCEL_ORDER_SUCCESS, UNCANCEL_ORDER_FAILURE],
            endPoint: `order/uncanceled`,
            schema: Schemas.ORDER_WITH_CONFIRMATION_STATE,
            body: orderWithConfirmationState
        }
    }
}

export const uncancelOrder = (orderId: number, confirmationState: string) => dispatch => {
    return dispatch(orderUncanceler(orderId, confirmationState));
}

/**Confirm Allotment Of An Order */


export const CONFIRM_ALLOTMENT_ORDER = 'CONFIRM_ALLOTMENT_ORDER';
export const CONFIRM_ALLOTMENT_ORDER_SUCCESS = 'CONFIRM_ALLOTMENT_ORDER_SUCCESS';
export const CONFIRM_ALLOTMENT_ORDER_FAILURE = 'CONFIRM_ALLOTMENT_ORDER_FAILURE';

const orderConfirmAllotment = (orderId: number, confirmAlottment:boolean) => {
    const order = {
        id: orderId,
        markOrderAllotment:confirmAlottment
    }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CONFIRM_ALLOTMENT_ORDER, CONFIRM_ALLOTMENT_ORDER_SUCCESS, CONFIRM_ALLOTMENT_ORDER_FAILURE],
            endPoint: `order/allotment_confirmed`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const confirmAllotmentOrder = (orderId: number, confirmAlottment:boolean) => (dispatch,getState) => {
    return dispatch(orderConfirmAllotment(orderId,confirmAlottment)).then((response) => {
        const order = getOrderFromProps(getState(), { orderId });
        return dispatch(getDSPRDriver(order.dsprDriver)).then(() => {
            return response;
        });
    });
}


/**Notify user of allotment an order */

export const NOTIFY_USER_ALLOTMENT_ISSUE_ORDER = 'NOTIFY_USER_ALLOTMENT_ISSUE_ORDER';
export const NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS = 'NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS';
export const NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_FAILURE = 'NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_FAILURE';

const notifyUserAllotmentIssueForOrderCall = (orderId: number) => {
    const order = {
        id: orderId
    }

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [NOTIFY_USER_ALLOTMENT_ISSUE_ORDER, NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS, NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_FAILURE],
            endPoint: `order/notify_user_allotment_issue`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const notifyUserAllotmentIssueForOrder = (orderId: number) => (dispatch,getState) => {
    return dispatch(notifyUserAllotmentIssueForOrderCall(orderId)).then((response) => {
        const order = getOrderFromProps(getState(), { orderId });
        return dispatch(getDSPRDriver(order.dsprDriver)).then(() => {
            return response;
        });
    });
}

/**Packaging an order */

export const PACKAGE_ORDER = 'PACKAGE_ORDER';
export const PACKAGE_ORDER_SUCCESS = 'PACKAGE_ORDER_SUCCESS';
export const PACKAGE_ORDER_FAILURE = 'PACKAGE_ORDER_FAILURE';

const orderPackager = (orderId: number) => {
    const order = {
        id: orderId
    }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [PACKAGE_ORDER, PACKAGE_ORDER_SUCCESS, PACKAGE_ORDER_FAILURE],
            endPoint: `order/package`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const packageOrder = (orderId: number) => dispatch => {
    return dispatch(orderPackager(orderId));
}
/**Assigning an order to a driver */

export const ASSIGN_DRIVER_ORDER = 'ASSIGN_DRIVER_ORDER';
export const ASSIGN_DRIVER_ORDER_SUCCESS = 'ASSIGN_DRIVER_ORDER_SUCCESS';
export const ASSIGN_DRIVER_ORDER_FAILURE = 'ASSIGN_DRIVER_ORDER_FAILURE';

const assignDriverToOrderer = (orderId: number,dsprDriverIdSavedForPackaging:number) => {
    const assignOrderToDriver = {
        order: {id: orderId },
        dsprDriver: { id: dsprDriverIdSavedForPackaging },
    }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ASSIGN_DRIVER_ORDER, ASSIGN_DRIVER_ORDER_SUCCESS, ASSIGN_DRIVER_ORDER_FAILURE],
            endPoint: `order/assign_driver`,
            schema: Schemas.ORDER,
            body: assignOrderToDriver
        }
    }
}

export const assignDriverToOrder = (orderId: number, dsprDriverIdSavedForPackaging:number) => dispatch => {
    return dispatch(assignDriverToOrderer(orderId,dsprDriverIdSavedForPackaging));
}

/** Queueing an order */

export const QUEUE_ORDER = 'QUEUE_ORDER';
export const QUEUE_ORDER_SUCCESS = 'QUEUE_ORDER_SUCCESS';
export const QUEUE_ORDER_FAILURE = 'QUEUE_ORDER_FAILURE';

const orderQueuer = (orderId: number) => {
    const order = {
        id: orderId
    }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [QUEUE_ORDER, QUEUE_ORDER_SUCCESS, QUEUE_ORDER_FAILURE],
            endPoint: `order/queue`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const queueOrder = (orderId: number) => dispatch => {
    return dispatch(orderQueuer(orderId));
}

/** Routing an order */
export const IN_ROUTE_ORDER = 'IN_ROUTE_ORDER';
export const IN_ROUTE_ORDER_SUCCESS = 'IN_ROUTE_ORDER_SUCCESS';
export const IN_ROUTE_ORDER_FAILURE = 'IN_ROUTE_ORDER_FAILURE';

const orderInRouter = (orderId: number) => {
    const order = {
        id: orderId
    }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [IN_ROUTE_ORDER, IN_ROUTE_ORDER_SUCCESS, IN_ROUTE_ORDER_FAILURE],
            endPoint: `order/in_route`,
            schema: Schemas.ORDER,
            body: order
        }
    }
}

export const inRouteOrder = (orderId: number) => dispatch => {
    return dispatch(orderInRouter(orderId));
}

export const GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY = 'GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY';
export const GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_SUCCESS = 'GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_SUCCESS';
export const GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_FAILURE = 'GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_FAILURE';

export interface complianceSearchObject {
    dsprId: number,
    productId: number,
    metrcTag?: string,
    batchNumber?: string,
    inventoryTransactionId?: number
}
const dsprComplianceNumberHistoryGetter = (complianceObject: complianceSearchObject) => {
    const queryParamsMap = {
        dspr_id: complianceObject.dsprId,
        product_id: complianceObject.productId,
        metrc_tag: complianceObject.metrcTag,
        batch_number: complianceObject.batchNumber,
        inventory_transaction: complianceObject.inventoryTransactionId
    }
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY, GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_SUCCESS, GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_FAILURE],
            endPoint: `order/history/compliance`,
            schema: Schemas.DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_ARRAY,
            queryParamsMap
        }
    }
}

export const getDSPRComplianceNumberHistory = (complianceObject: complianceSearchObject) => (dispatch) => {
    return dispatch(dsprComplianceNumberHistoryGetter(complianceObject));
}