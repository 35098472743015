import React, { useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface CheckboxToggleFormProps {
    handleSubmit: (showInactive: boolean) => any;
    formControlLabel?: string;
    formControlLabelPlacement?: 'top' | 'bottom' | 'end' | 'start';
    size?: 'medium' | 'small';
    labelFontSize?: string;
}


/**Single Checkbox form designed to toggle states
 * (e.g. toggling whether or not to display inactive brands)
 * 
 * **Note: This form is not connected to redux
 */
const CheckboxToggleForm = ({ handleSubmit, formControlLabel = 'Show inactive', formControlLabelPlacement = 'end', size = 'medium', labelFontSize = '1rem' }: CheckboxToggleFormProps) => {
    const [checked, setChecked] = useState(false);

    function handleChange() {
        handleSubmit(!checked);
        setChecked(!checked);
    }

    const font = {
        fontSize: labelFontSize,
    }

    return (
        <div>
            <FormControlLabel
                value="top"
                control={<Checkbox color="primary" onChange={handleChange} checked={checked} size={size} />}
                label={<span style={font}>{formControlLabel}</span>}
                labelPlacement={formControlLabelPlacement}
            />
        </div>
    )
}

export default CheckboxToggleForm;