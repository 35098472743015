import React, {useEffect, useState} from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    Button,
    TextField,
    Checkbox,
    FormControl,
    RadioGroup,
    FormControlLabel, Typography, Select, MenuItem, InputLabel, FormHelperText
} from '@material-ui/core';
import {ApplicableTax, ApplicableTaxesWithDSPR, State} from '../store/reduxStoreState';

import './couponFormStyles.scss';
import AutoComplete, {AutoSelectItemNumberValue} from '../components/Autocomplete';
import {shallowEqual, useSelector} from "react-redux";
import {getDSPRsByName} from "../selectors/dsprSelectors";
import {
    getApplicableTaxesByName
} from "../selectors/applicableTaxesSelectors";
import ApplicableTaxes from "../components/ApplicableTaxes";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'rate'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    return errors;
};


const renderField = ({ input, defaultSelected, label, type, items, handleAutoCompleteSelected,
    meta: { touched, error, form }, children, ...custom }) => {
    switch (type) {
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} />} />
        case 'radioGroup':
            return <RadioGroup
                {...input}
                onChange={(_, value) => input.onChange(value)}
                children={children}
            />
        case 'autoComplete':
            return <AutoComplete
                options={items}
                label={label}
                handleAutoCompleteSelected={handleAutoCompleteSelected}
                touched={touched}
                error={error}
                input={input}
                form={form}
                {...custom}
            />
        default:
            return <TextField
                label={label} type={type} {...input} {...custom} error={!!(touched && error)} helperText={touched && error ? error : ''}
            />;
    }
};

interface DSPRApplicableTaxAssociationFormProps {
    applicableTax?: ApplicableTax;
    interceptAutocompleteSelectedTaxes: (applicableTaxIds: AutoSelectItemNumberValue[]) => any;
    showDSPRApplicableTaxForm:boolean;
    dsprSelect: any;
    dsprSelectName: string;
    applicableTaxesAssociatedWithDSPRForSelect: ApplicableTaxesWithDSPR
}

const DSPRApplicableTaxAssociationForm = (props: DSPRApplicableTaxAssociationFormProps & InjectedFormProps<{}, DSPRApplicableTaxAssociationFormProps>) => {
    const { handleSubmit, interceptAutocompleteSelectedTaxes,showDSPRApplicableTaxForm, dsprSelect, dsprSelectName, applicableTaxesAssociatedWithDSPRForSelect} = props;
    const applicableTaxesForAutoSelect = useSelector<State, { value: number, text: string }[]>((state => getApplicableTaxesByName(state)), shallowEqual);
    const [applicableTaxForSelect, setApplicableTaxForSelect] = useState<{value: number; text: string; }[]>(null);

    useEffect(() => {
        if(showDSPRApplicableTaxForm){
            const objKeysForAllApplicableTaxes = applicableTaxesForAutoSelect.map(tax => tax.value);
            const taxesFromDspr = applicableTaxesAssociatedWithDSPRForSelect ? applicableTaxesAssociatedWithDSPRForSelect.applicableTaxes : [];
            const objKeysForApplicableTaxesDSPR = taxesFromDspr.map(tax => tax['id']);
            const filteredTaxIds = objKeysForAllApplicableTaxes.filter(tax => !objKeysForApplicableTaxesDSPR.includes(tax));
            const finishedArrayOfApplicableTaxes = applicableTaxesForAutoSelect.filter(tax => { if(filteredTaxIds.includes(tax.value)) return tax})
            setApplicableTaxForSelect(finishedArrayOfApplicableTaxes);
            // setApplicableTaxForSelect(null);
        }else{
            const objKeysForAllApplicableTaxes = applicableTaxesForAutoSelect.map(tax => tax.value);
            const taxesFromDspr = applicableTaxesAssociatedWithDSPRForSelect.applicableTaxes;
            const objKeysForApplicableTaxesDSPR = taxesFromDspr.map(tax => tax['id']);
            const filteredTaxIds = objKeysForAllApplicableTaxes.filter(tax => !objKeysForApplicableTaxesDSPR.includes(tax));
            const finishedArrayOfApplicableTaxes = applicableTaxesForAutoSelect.filter(tax => { if(!filteredTaxIds.includes(tax.value)) return tax})
            setApplicableTaxForSelect(finishedArrayOfApplicableTaxes);
            // setApplicableTaxForSelect(null);
        }
    }, [])
    return (
    <form onSubmit={handleSubmit} className="coupon-forms">
        <Typography>
            {dsprSelectName}
        </Typography>
        {applicableTaxForSelect && applicableTaxForSelect ?
            <FormControl component="fieldset">
                <Field name="applicableTaxIds"
                       component={renderField}
                       type="autoComplete"
                       label="Applicable Taxes"
                       handleAutoCompleteSelected={interceptAutocompleteSelectedTaxes}
                       multiple={true}
                       items={applicableTaxForSelect}
            />
            </FormControl> : ''
        }
            <div>
                {showDSPRApplicableTaxForm ?
                    <Button variant="contained"
                            color="primary"
                            onClick={handleSubmit}>
                        Create DSPR Applicable Tax Association
                    </Button>
                    :
                    <Button variant="contained"
                            style={{
                                backgroundColor: "#FB0001",
                                color: "white"
                            }}
                            onClick={handleSubmit}>
                        Delete DSPR Applicable Tax Association
                    </Button>
                }
            </div>
        </form>
    )
};
export default reduxForm<any, DSPRApplicableTaxAssociationFormProps>({
    form: 'DSPRApplicableTaxAssociationForm',
    validate
})(DSPRApplicableTaxAssociationForm);
