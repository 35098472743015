import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    Button,
    TextField,
    Checkbox,
    FormControl,
    FormGroup,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from '@material-ui/core';

import { DspProductCategory, State, User } from '../store/reduxStoreState';
import './couponFormStyles.scss';
import AutoComplete, { AutoSelectItemNumberValue } from '../components/Autocomplete';
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { getAllUsers } from "../actions/userActions";
import { canceledFetchErrorMessage } from "../middleware/api";
import { getUsers, getUsersByName } from "../selectors/userSelectors";
import { CouponWithUserDetails } from "../components/Coupons";
import { isDiscountTypeAbsoluteOrPercentInCouponForm } from "../selectors/reduxFormSelectors";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['code', 'discountType', 'maxDiscountAmount', 'maxPerUserUsageTimes'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    if ((values['discountType'] === 'percent' || values['discountType'] === 'absolute') && !values['discountAmount'])
        errors['discountAmount'] = 'Required for percent or absolute discount type';
    return errors;
};

const renderField = ({
                         input, defaultSelected, label, type, users, handleAutoCompleteSelected, multiple,
                         meta: {touched, error, form}, children, ...custom
                     }) => {

    let defaultValue;
    if (custom && custom.custom && custom.custom.defaultValue && custom.custom.defaultValue.usersForModifyCoupon) {
        defaultValue = custom.custom.defaultValue.usersForModifyCoupon;
    }

    switch (type) {
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange}/>}/>
        case 'radioGroup':
            return <RadioGroup
                {...input}
                onChange={(_, value) => input.onChange(value)}
                children={children}
            />
        case 'autoComplete':
            return <AutoComplete
                options={users ? users : []}
                label={label}
                handleAutoCompleteSelected={handleAutoCompleteSelected}
                touched={touched}
                error={error}
                input={input}
                form={form}
                multiple={multiple}
                {...custom}
                defaultValue={defaultValue}
            />
        default:
            return <TextField
                label={label}
                type={type} {...input} {...custom}
                error={!!(touched && error)}
                helperText={touched && error ? error : ''}
            />;
    }
};

interface CouponFormProps {
    isGeneric: boolean;
    isFlower: boolean;
    categories: DspProductCategory[];
    isUser?: boolean;
    handleAutoCompleteSelected?: (users: AutoSelectItemNumberValue[]) => any;
    coupon?: CouponWithUserDetails; //this prop is used to determine if form is a modify coupon or create coupon form, as well as providing needed values
    handleClose?: () => void;
}

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CouponForm = (props: CouponFormProps & InjectedFormProps<{}, CouponFormProps>) => {
    const {
        handleSubmit: submitNewCoupon,
        categories,
        isFlower,
        isGeneric,
        handleAutoCompleteSelected,
        isUser,
        coupon,
        handleClose,
        pristine
    } = props;

    const [loadingUsers, setLoadingUsers] = useState(true);

    const isDiscountTypeAbsoluteOrPercent = useSelector(isDiscountTypeAbsoluteOrPercentInCouponForm, shallowEqual);

    // const usersForAutoSelect = useSelector<State, { value: number, text: string }[]>((state => !loadingUsers && getUsersByName(state)), shallowEqual);
    const usersForAutoSelect = useSelector<State, { value: number, text: string }[]>((state => !loadingUsers && getUsersByName(state)), shallowEqual);
    const users = useSelector<State, { [key: number]: User }>(getUsers);

    const dispatch = useDispatch();
    const abortController = useMemo(() => new AbortController(), []);
    const {signal} = abortController;

    //fetch all users if the "users" tab is selected in the new coupon form
    useEffect(() => {
        if (isUser) {
            dispatch<any>(getAllUsers(signal))
                .then((response) => {
                    if (!(response.error && response.error.includes(canceledFetchErrorMessage))) {
                        setLoadingUsers(false)
                    }
                });
            return () => abortController.abort();
        }
    }, [abortController, signal, dispatch, isUser]);

    const handleSubmit = (values) => {
        submitNewCoupon(values)
    }

    const usersForModifyCoupon = coupon && coupon.specificallyAllowedUsers ? coupon.specificallyAllowedUsers.map((id, idx) => {
        const user = users[id];
        return ( {value: user.id, text: `${user.firstName} ${user.lastName} ${user.email}`});
    }) : [];

    return (
        <form onSubmit={handleSubmit} className="coupon-forms">
            <Field name="code" component={renderField} label="Coupon Code" style={{marginTop: 8}} />
            <FormControl component="fieldset">
                <FormLabel component="legend">How is the discount to be calculated?</FormLabel>
                {isGeneric ? <Field name="discountType" component={renderField} type="radioGroup">
                        <FormControlLabel value="absolute" control={<Radio/>} label="Fixed Dollars Off"/>
                        <FormControlLabel value="percent" control={<Radio/>} label="Percentage Off"/>
                        <FormControlLabel value="bogo" control={<Radio/>} label="Buy One Get One"/>
                        <FormControlLabel value="boghalf" control={<Radio/>} label="Buy One Get Half Off"/>
                        <FormControlLabel value="btgo" control={<Radio/>} label="Buy Two Get One"/>
                    </Field> :
                    <Field name="discountType" component={renderField} type="radioGroup"> {/* Not generic */}
                        <FormControlLabel value="absolute" control={<Radio/>} label="Fixed Dollars Off"/>
                        <FormControlLabel value="percent" control={<Radio/>} label="Percentage Off"/>
                    </Field>}
                <Field name="discountAmount" component={renderField} label="Amount of Discount (in % or $)" disabled={!isDiscountTypeAbsoluteOrPercent}/>
                <Field name="maxDiscountAmount" component={renderField} label="Maximum allowed Discount (in $)"/>
            </FormControl>

            {isFlower && <FormControl component="fieldset">
                <Field name="quantityRequired" component={renderField} label="Quantity Required" style={{marginTop: 0}}/>
                <Field name="unit" component={renderField} type="radioGroup">
                    <FormControlLabel value="eighth" control={<Radio/>} label="Eighth"/>
                    <FormControlLabel value="quarter" control={<Radio/>} label="Quarter"/>
                    <FormControlLabel value="half" control={<Radio/>} label="Half"/>
                    <FormControlLabel value="oz" control={<Radio/>} label="Oz"/>
                </Field>
            </FormControl>}

            {isGeneric && <FormControl component="fieldset">
                <FormLabel component="legend">Coupon applies to specific categories?</FormLabel>
                <FormGroup>
                    {categories && categories.map(category =>
                        <Field name={`category${category.id}`}
                               label={category.name}
                               key={category.id}
                               component={renderField}
                               type="checkbox"
                               value={category.id}/>
                    )}
                </FormGroup>
            </FormControl>}

            <FormControl component="fieldset">
                <FormLabel component="legend" style={{marginBottom: 0}}>How many times can a user use this coupon?</FormLabel>
                <Field name="maxPerUserUsageTimes" component={renderField} value="1"/>
                {isUser && coupon && <Field name="userIds"
                                  component={renderField}
                                  type="autoComplete"
                                  dataLoaded={!loadingUsers}
                                  users={usersForAutoSelect}
                                  label="Assign Users to Coupon"
                                  handleAutoCompleteSelected={handleAutoCompleteSelected}
                                  multiple={true}
                                  disabled={loadingUsers}
                                  custom={{defaultValue: { usersForModifyCoupon }}}/>}
                {/*Avoids uncontrolled state error when defaultValue changes after modifying a coupon already assigned to users*/}
                {isUser && !coupon && <Field name="userIds"
                                            component={renderField}
                                            type="autoComplete"
                                            dataLoaded={!loadingUsers}
                                            users={usersForAutoSelect}
                                            label="Assign Users to Coupon"
                                            handleAutoCompleteSelected={handleAutoCompleteSelected}
                                            multiple={true}
                                            disabled={loadingUsers}/>}
            </FormControl>

            {!isUser && <Fragment>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Can this coupon only be used by first time users?</FormLabel>
                    <Field name="isFirstTimeUserOnly" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio/>} label="Yes"/>
                        <FormControlLabel value="false" control={<Radio/>} label="No"/>
                    </Field>
                </FormControl>

                <FormControl>
                    <FormLabel component="legend">Can this coupon only be used on specific days?</FormLabel>
                    <FormGroup>
                        {DAYS.map(day => <Field name={`day-${day}`}
                                                label={day}
                                                key={day}
                                                component={renderField}
                                                type="checkbox"
                                                value={day}/>)}
                    </FormGroup>
                </FormControl>
            </Fragment>}

            {props.coupon &&
                <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>Do you want to reset customer usage count for this coupon?</FormLabel>
                    <Field name={`transferUserUsage`} component={renderField} type="radioGroup">
                        {/*user wants to reset count - transfer user usage -> false*/}
                        <FormControlLabel value={'false'} control={<Radio/>} label="Yes, reset usage count to 0"/>

                        {/*user does not want to reset count - transfer user usage -> true*/}
                        <FormControlLabel value={'true'} control={<Radio/>} label="No, keep usage count as is"/>
                    </Field>
                </FormControl>
            }

            <div className={'submit-button-container'}>
                {props.coupon &&
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                }
                <Button variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={pristine}
                >
                    {props.coupon ? 'Modify Coupon' : 'Create Coupon'}
                </Button>
            </div>
        </form>
    )
};

//export default reduxForm<any, CouponFormProps>({
//    form: 'CouponForm',
//    validate
//})(CouponForm);


const CouponFormRedux = reduxForm<any, CouponFormProps>({
    form: 'CouponForm',
    validate,
})(CouponForm);

export default connect(
    (state, props: CouponFormProps) => {
        if (props.coupon) {
            const days = {};
            const categories = {};
            const couponDays = props.coupon.dailyDealDays;
            const couponCategories = props.coupon.specificallyAllowedProductCategories;

            DAYS.forEach(day => {
                const formattedDay = day[0].toUpperCase() + day.substring(1).toLowerCase();
                days[`day-${formattedDay}`] = couponDays.length > 0 && couponDays.includes(day.toUpperCase());
            })

            props.categories.forEach(category => {
                categories[`category${category.id}`] = couponCategories.length > 0 && couponCategories.includes(category.id);
            })

            const {
                code,
                discountType,
                discountAmount,
                maxDiscountAmount,
                quantityRequired,
                unit,
                maxPerUserUsageTimes,
            } = props.coupon;

            return {
                initialValues: {
                    code,
                    discountType,
                    discountAmount: discountAmount ? discountAmount.toString() : undefined,
                    maxDiscountAmount: maxDiscountAmount ? maxDiscountAmount.toString() : undefined,
                    quantityRequired: quantityRequired ? quantityRequired.toString() : undefined,
                    unit,
                    maxPerUserUsageTimes: maxPerUserUsageTimes ? maxPerUserUsageTimes.toString() : undefined,
                    ...days,
                    ...categories,
                    isFirstTimeUserOnly: props.coupon.isFirstTimeUserOnly ? 'true' : 'false',
                    transferUserUsage: 'true',
                    userIds: props.coupon.specificallyAllowedUsers,
                }
            }
        } else {
            return {}
        }
    }
)(CouponFormRedux);