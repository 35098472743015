import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardHeader,
    Select,
    MenuItem,
    CircularProgress,
    Switch,
    CardActions,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Collapse,
    FormControlLabel,
    RadioGroup,
    FormControl,
    FormLabel, Radio, Checkbox, TextField,
} from '@material-ui/core';

import {
    CREATE_NEW_COUPON_FAILURE,
    CREATE_NEW_COUPON_SUCCESS, MODIFY_COUPON_FAILURE,
    MODIFY_COUPON_SUCCESS,
    updateCouponLockTypesProps
} from '../actions/couponActions';
import { DSPR, DspProductCategory, Coupon, User, DSPRCouponLockType } from '../store/reduxStoreState';

import CouponForm from '../containers/CouponForm';
import SearchForm from '../containers/SearchForm';
import NavPills from './NavPills/NavPills';
import CardBody from './Card/CardBody';
import VirtualizedTable from './VirtualizedTable';

import './couponStyles.scss';
import CouponWithDetails from "./CouponWithDetails";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { GET_USER_ID_DOCUMENT_FAILURE, GET_USERS_BY_SEARCH_SUCCESS, getUsersBySearch } from "../actions/userActions";
import { AutoSelectItemNumberValue } from "./Autocomplete";
import DSPRCouponPermissionsForm from "../containers/DSPRCouponPermissionsForm";
import SnackBarAlertSuccess from "./SnackBarAlertSuccess";
import SnackBarAlertError from "./SnackBarAlertError";


type NewCouponValues = {
    code: string;
    discountType: string;
    discountAmount: string;
    maxDiscountAmount: string;
    quantityRequired: string;
    unit: string;
    maxPerUserUsageTimes: string;
    isFirstTimeUserOnly: string;
    userIds: number[];
    startTimestamp: any;
    endTimestamp: any;
    id: any;
    transferUserUsage?: boolean;
}

interface CouponsProps {
    createCoupon: (
        dsprId: number,
        code: string,
        isFlower: boolean,
        discountAmount: string,
        discountType: string,
        maxDiscountAmount: string,
        maxPerUserUsageTimes: string,
        isFirstTimeUserOnly: string,
        startTimestamp: any,
        endTimestamp: any,
        userIds: number[],
        categories: string[],
        days: string[],
        quantityRequired: string,
        unit: string,
        id: any,
        transferUserUsage: boolean,
        couponToModify: CouponWithUserDetails,
    ) => any;
    toggler: (couponId: number) => any;
    dspr: DSPR;
    searchCoupons: (partialCode: string) => any;
    getAllCoupons: () => any;
    coupons: (Omit<Coupon, 'user'> & { user: User })[];
    categories: DspProductCategory[];
    interceptAutocompleteSelected: (users: AutoSelectItemNumberValue[]) => any;
    users?: { value: number, text: string }[];
    tableHeight: number;
    isManagerForCurrentDSPR: boolean;
    isSystemAdmin: boolean;
    updateCoupon: (values: updateCouponLockTypesProps) => any;
    dsprCouponLocks: DSPRCouponLockType[];
}

export type CouponWithUserDetails = Omit<Coupon, 'user'> & { user: User };

const Coupons: React.FC<CouponsProps> = props => {
    const   FILTER_TYPE = {
        SEARCH: 'SEARCH',
        ALL: 'ALL'
    };
    const {
        coupons,
        dspr,
        categories,
        createCoupon,
        toggler,
        searchCoupons,
        getAllCoupons,
        interceptAutocompleteSelected,
        tableHeight,
        isManagerForCurrentDSPR,
        isSystemAdmin,
        updateCoupon,
        dsprCouponLocks,
    } = props;

    const initialFilterDiscountTypeState = {bogo: false, boghalf: false, btgo: false, absolute: false, percent: false};

    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
    const [allCoupons, setAllCoupons] = useState(false);
    const [searchCode, setSearchCode] = useState(null);
    const [filterType, setFilterType] = useState(FILTER_TYPE.SEARCH);
    const [showSearchForm, setShowSearchForm] = useState(true);
    const [showNewCouponForm, setShowNewCouponForm] = useState(false);
    const [showModifyCouponForm, setShowModifyCouponForm] = useState<boolean>(false);
    const [newCouponCode, setNewCouponCode] = useState('');
    const [showCouponDetailDialog, setShowCouponDetailDialog] = useState(false);
    //TODO add type definition for couponToModify state
    const [couponToModify, setCouponToModify] = useState<CouponWithUserDetails>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filterDiscountTypeRadioSelection, setFilterDiscountTypeRadioSelection] = useState<'all' | 'some'>('all');
    const [filterDiscountTypeChecked, setFilterDiscountTypeChecked] = useState(initialFilterDiscountTypeState);
    const [filterDiscountType, setFilterDiscountType] = useState(initialFilterDiscountTypeState);
    const [filterCouponStatus, setFilterCouponStatus] = useState<'all' | 'activeOnly' | 'inactiveOnly'>('activeOnly');
    const [filterCustomerApplicability, setFilterCustomerApplicability] = useState<{ applicability: 'allCoupons' | 'availableToAll' | 'userSpecific', filterType: 'all' | 'ftuOnly' | 'repeatOnly' }>({
        applicability: 'allCoupons',
        filterType: 'all'
    });
    const [userSpecificFilterType, setUserSpecificFilterType] = useState<'all' | 'searchedForUser'>('all');
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [couponsToDisplay, setCouponsToDisplay] = useState(coupons);
    const [searchedUsers, setSearchedUsers] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertTitle, setErrorAlertTitle] = useState<string>('Error Encountered!');
    const [alertText, setAlertText] = useState<string>('');
    const [isPermissionsCardExpanded, setIsPermissionsCardExpanded] = useState<boolean>(false);

    const dispatch = useDispatch();

    const openCouponDetailDialog = (coupon: CouponWithUserDetails) => {
        setShowCouponDetailDialog(true);
        setCouponToModify(coupon)
    }

    const closeCouponDetailDialog = () => {
        setShowCouponDetailDialog(false);
        setCouponToModify(null);
    }

    const closeCouponForm = () => {
        if (showNewCouponForm) {
            setShowNewCouponForm(false);
        }

        if (showModifyCouponForm) {
            setShowModifyCouponForm(false);
        }
    }

    const handleNewOrModifyCoupon = (values: Partial<NewCouponValues>, isFlower: boolean) => {
        const categories = [];
        const days = [];
        Object.keys(values).forEach(key => {
            if (key.startsWith("category") && values[key]) categories.push(key.substr(8));
            if (key.startsWith("day-") && values[key]) days.push(key.substr(4).toUpperCase());
        });

        const oldCoupon = couponToModify ? couponToModify : undefined;

        return createCoupon(dspr.id,
            values.code,
            isFlower,
            values.discountAmount,
            values.discountType,
            values.maxDiscountAmount,
            values.maxPerUserUsageTimes,
            values.isFirstTimeUserOnly,
            values.startTimestamp,
            values.endTimestamp,
            values.userIds,
            categories,
            days,
            values.quantityRequired,
            values.unit,
            values.id,
            values.transferUserUsage,
            oldCoupon
        )
            .then(response => {
                if (response.type === CREATE_NEW_COUPON_SUCCESS) {
                    setNewCouponCode(values.code);
                    setAlertText(`The coupon ${newCouponCode} has been successfully created!`);
                    setShowSuccessAlert(true);
                    setShowNewCouponForm(false);

                    return true;
                }

                if (response.type === MODIFY_COUPON_SUCCESS) {
                    setAlertText(`Coupon - ${couponToModify.code} - Successfully Modified!`);
                    setShowModifyCouponForm(false);
                    setShowSuccessAlert(true);

                    closeCouponDetailDialog();
                    return true;
                }

                if (response.type === CREATE_NEW_COUPON_FAILURE || response.type === MODIFY_COUPON_FAILURE) {
                    setErrorAlertTitle(response.type === CREATE_NEW_COUPON_FAILURE ? 'Coupon Creation Failed' : 'Coupon Modification Failed');
                    setAlertText(response.error ? response.error : 'Please try again');
                    setShowErrorAlert(true);

                    return false;
                }
            });
    };

    const handleSearchCoupons = (values: { searchQuery: string }) => {
        setIsLoading(true);
        setAllCoupons(false);
        searchCoupons(values.searchQuery)
            .then(() => {
                setIsLoading(false);
                setSearchCode(values.searchQuery);
            });
    };

    const handleAllCoupons = () => {
        setIsLoading(true);
        setAllCoupons(true);
        setSearchCode(null);
        getAllCoupons()
            .then(() => setIsLoading(false));
    };

    const handleUpdateCouponLocks = (formValues) => {
        return updateCoupon({dsprCouponLocks: formValues, dsprId: dspr.id});
    }

    const handleFilterChange = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const filterType = event.target.value as string;
        setFilterType(filterType);

        if (filterType === FILTER_TYPE.SEARCH) {
            setShowSearchForm(true);
        } else if (filterType === FILTER_TYPE.ALL) {
            setShowSearchForm(false);
            handleAllCoupons();
        }
    };

    /**Filter coupons to display based upon user selected filters*/
    useEffect(() => {

        const discountTypes = [];
        for (let key in filterDiscountType) {
            if (filterDiscountType[key] === true) discountTypes.push(key);
        }

        const filteredCoupons = coupons.filter(coupon => {
            if (discountTypes.length > 0 && !discountTypes.includes(coupon.discountType)) {
                return false;
            }

            if (filterCouponStatus === 'activeOnly' && coupon.isActive === false) {
                return false;
            }

            if (filterCouponStatus === 'inactiveOnly' && coupon.isActive === true) {
                return false;
            }

            if (filterCustomerApplicability.filterType === 'ftuOnly' && coupon.isFirstTimeUserOnly === false && filterCustomerApplicability.applicability !== "userSpecific") {
                return false;
            }

            if (filterCustomerApplicability.filterType === 'repeatOnly' && coupon.isFirstTimeUserOnly === true && filterCustomerApplicability.applicability !== "userSpecific") {
                return false;
            }

            if (filterCustomerApplicability.applicability === 'availableToAll' && coupon.specificallyAllowedUsers.length > 0) {
                return false;
            }

            if (filterCustomerApplicability.applicability === 'userSpecific' && userSpecificFilterType === 'all' && coupon.specificallyAllowedUsers.length === 0) {
                return false;
            }

            if (filterCustomerApplicability.applicability === 'userSpecific' && userSpecificFilterType === 'searchedForUser') {
                if (coupon.specificallyAllowedUsers.length > 0 && searchedUsers) {
                    for (let userId of coupon.specificallyAllowedUsers) {
                        if (searchedUsers[userId]) {
                            return true;
                        }
                    }
                }
                return false;
            }

            return true;
        })

        setCouponsToDisplay(filteredCoupons);
    }, [coupons, filterDiscountType, filterCouponStatus, filterCustomerApplicability, searchedUsers, userSpecificFilterType]);

    const renderCouponRow = (coupon: CouponWithUserDetails, style: React.CSSProperties) => {
        // console.log(coupon,"inside renderCouponRow");

        let firstTimeString;
        if (coupon.specificallyAllowedUsers.length > 1) {
            firstTimeString = `${coupon.specificallyAllowedUsers.length} users`;
        } else if (coupon.isFirstTimeUserOnly) {
            firstTimeString = 'yes';
        } else if (coupon.user) {
            firstTimeString=`${coupon.user.firstName + ' ' + coupon.user.lastName}`;
        } else if (!coupon.user && coupon.specificallyAllowedUsers.length > 0) {
            // handles case in which coupon does not have a specific user property, but coupon is still assigned to a specific user
            firstTimeString = `${coupon.specificallyAllowedUsers.length} users`;
        } else {
            firstTimeString = 'no';
        }

        let discountAmountString;
        switch (coupon.discountType) {
            case 'absolute':
                discountAmountString = `$${coupon.discountAmount}`;
                break;
            case 'percent':
                discountAmountString = `${coupon.discountAmount}%`;
                break;
            case 'bogo':
            case 'boghalf':
            case 'btgo':
                discountAmountString = ' - ';
                break;
            default:
                discountAmountString = coupon.discountAmount.toString();
        }

        const discountTypeString = (coupon.discountType === 'absolute' || coupon.discountType === 'percent')
            ? coupon.discountType.charAt(0).toUpperCase() + coupon.discountType.slice(1)
            : coupon.discountType.toUpperCase();

        return <div key={coupon.id} style={style} className="coupon-row" onClick={() => openCouponDetailDialog(coupon)}>
            <span>{coupon.code}</span>
            <span>{firstTimeString}</span>
            <span>{discountAmountString}</span>
            <span>{discountTypeString}</span>
            <span>${coupon.maxDiscountAmount}</span>
            <span>{coupon.maxPerUserUsageTimes}</span>
            <span>{coupon.totalUsage}</span>
            <span className={'activation-toggle'} onClick={evt => evt.stopPropagation()}>
                <Switch checked={coupon.isActive}
                        color="primary"
                        disabled={!isManagerForCurrentDSPR}
                        onChange={() => toggler(coupon.id)}/>
            </span>
        </div>
    };

    const title = allCoupons ? 'All Coupons' :
        searchCode ? `Coupons Containing "${searchCode}"` :
            "Search For Coupons";

    const renderCoupons = ({
                               index,
                               style
                           }) => (!couponsToDisplay || (couponsToDisplay && couponsToDisplay.length === 0)) ? null : renderCouponRow(couponsToDisplay[index], style);

    const handleChangeRadioDiscountType = (evt) => {
        const selection = evt.target.value;
        setFilterDiscountTypeRadioSelection(selection);

        //set state so as to not filter by discount type
        if (selection === 'all') {
            setFilterDiscountTypeChecked(initialFilterDiscountTypeState)
            setFilterDiscountType(initialFilterDiscountTypeState)
        }
    }

    const handleChangeCheckboxDiscountType = (evt) => {
        const selection = evt.target.value;
        const isChecked = evt.target.checked;

        if (filterDiscountTypeRadioSelection === 'all') setFilterDiscountTypeRadioSelection('some');

        //update checked state for the checkbox that was just checked
        setFilterDiscountTypeChecked({...filterDiscountTypeChecked, [selection]: isChecked});
        //update state to filter by selected discount value
        setFilterDiscountType({...filterDiscountType, [selection]: isChecked});
    }

    const handleChangeCouponStatus = (evt) => {
        setFilterCouponStatus(evt.target.value);
    }

    const handleChangeCustomerApplicability = (evt) => {
        setFilterCustomerApplicability({...filterCustomerApplicability, applicability: evt.target.value});
    }

    const handleChangeCustomerFilterType = (evt) => {
        setFilterCustomerApplicability({...filterCustomerApplicability, filterType: evt.target.value});
    }

    const handleChangeUserSpecificFilterType = (evt) => {
        setUserSpecificFilterType(evt.target.value);
    }

    const handleChangeUserSearch = (evt) => {
        setUserSearchTerm(evt.target.value);
    }

    const handleSubmitUserSearch = (evt) => {
        evt.preventDefault();
        setIsFetchingUsers(true);
        dispatch<any>(getUsersBySearch(userSearchTerm))
            .then((response) => {

                if (response.type === GET_USERS_BY_SEARCH_SUCCESS) {
                    setSearchedUsers(response.response.entities.searchUsers);
                }

                if (response.type === GET_USER_ID_DOCUMENT_FAILURE) {
                    setShowErrorAlert(true);
                    setErrorAlertTitle('Search Error Encountered!');
                    setAlertText(response.error ? `Error Retrieving users! ${response.error}` : 'Failed to complete user search');
                }
                setIsFetchingUsers(false);
            })
    }


    return <div className="coupons">

        <Card className="coupon-search-expansion-panel">

            <CardHeader title={title}/>

            <CardBody>

                <Select className="coupon-filter-dropdown" value={filterType} onChange={handleFilterChange}>
                    <MenuItem value={FILTER_TYPE.SEARCH}>Search</MenuItem>
                    <MenuItem value={FILTER_TYPE.ALL}>All</MenuItem>
                </Select>
                {showSearchForm && <SearchForm onSubmit={handleSearchCoupons} isCoupon={true}/>}

                {/*Coupon Filters for Table*/}
                {coupons && coupons.length > 0 &&
                <div className={'filters-container'}>

                    <div className={'filters-container-header'} onClick={() => setShowFilters(!showFilters)}>
                        <Typography>Filters</Typography>
                        {showFilters ? <ExpandLess/> : <ExpandMore/>}
                    </div>

                    <Collapse in={showFilters}>
                        {/*Discount Type Filter Form*/}
                        <FormControl component={'fieldset'} className={'filter-fieldset'}>
                            <FormLabel component={'legend'}>Discount Type</FormLabel>
                            <RadioGroup defaultValue={'all'}
                                        value={filterDiscountTypeRadioSelection}
                                        aria-label={'Discount Type'}
                                        name={'discount-type-radios'}
                                        onChange={handleChangeRadioDiscountType}>
                                <FormControlLabel control={<Radio color={'primary'}/>}
                                                  label={'All Types'}
                                                  value={'all'}/>
                                <FormControlLabel control={<Radio color={'primary'}/>}
                                                  label={'Filter For:'}
                                                  value={'some'}/>
                            </RadioGroup>
                            <div className={'nested-form-container'}>
                                <FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.absolute}
                                                       value={'absolute'}
                                                       onChange={handleChangeCheckboxDiscountType}
                                                       name='Absolute'/>}
                                    label={'Absolute'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.percent}
                                                       value={'percent'}
                                                       onChange={handleChangeCheckboxDiscountType}
                                                       name='Percent'/>}
                                    label={'Percent'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.bogo}
                                                       value={'bogo'}
                                                       onChange={handleChangeCheckboxDiscountType}
                                                       name='BOGO'/>}
                                    label={'BOGO'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.boghalf}
                                                       value={'boghalf'}
                                                       onChange={handleChangeCheckboxDiscountType}
                                                       name='BOGHALF'/>}
                                    label={'BOGHALF'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filterDiscountTypeChecked.btgo}
                                                       value={'btgo'}
                                                       onChange={handleChangeCheckboxDiscountType}
                                                       name='BTGO'/>}
                                    label={'BTGO'}
                                />
                            </div>
                        </FormControl>

                        {/*Coupon Status Filter Form*/}
                        <FormControl component={'fieldset'} className={'filter-fieldset'}>
                            <FormLabel component={'legend'}>Coupon Status</FormLabel>
                            <RadioGroup defaultValue={'activeOnly'}
                                        aria-label={'Coupon Status'}
                                        name={'coupon-status-radios'}
                                        onChange={handleChangeCouponStatus}>
                                <FormControlLabel control={<Radio color={'primary'}/>}
                                                  label={'Active Only'}
                                                  value={'activeOnly'}/>
                                <FormControlLabel control={<Radio color={'primary'}/>}
                                                  label={'Inactive Only'}
                                                  value={'inactiveOnly'}/>
                                <FormControlLabel control={<Radio color={'primary'}/>}
                                                  label={'Active & Inactive'}
                                                  value={'all'}/>
                            </RadioGroup>
                        </FormControl>

                        {/*Customer Applicability Filter Form*/}
                        <FormControl component={'fieldset'} className={'filter-fieldset'}>
                            <FormLabel component={'legend'}>Customer Applicability</FormLabel>
                            <RadioGroup
                                defaultValue={'allCoupons'}
                                aria-label={'Customer Applicability'}
                                name={'customer-applicability-radios'}
                            >
                                <FormControlLabel control={<Radio color={'primary'}
                                                                  onChange={handleChangeCustomerApplicability}/>}
                                                  label={'All Coupons'}
                                                  value={'allCoupons'}/>
                                <Collapse in={filterCustomerApplicability.applicability === 'allCoupons'}
                                          className={'nested-form-container'}>
                                    <RadioGroup value={filterCustomerApplicability.filterType}
                                                onChange={handleChangeCustomerFilterType}>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'All Customers'}
                                                          value={'all'}/>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'First-Time Customers Only'}
                                                          value={'ftuOnly'}/>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'Repeat Customers Only'}
                                                          value={'repeatOnly'}/>
                                    </RadioGroup>
                                </Collapse>
                                <FormControlLabel control={<Radio color={'primary'}
                                                                  onChange={handleChangeCustomerApplicability}/>}
                                                  label={'Available to Every User'}
                                                  value={'availableToAll'}/>
                                <Collapse in={filterCustomerApplicability.applicability === 'availableToAll'}
                                          className={'nested-form-container'}>
                                    <RadioGroup value={filterCustomerApplicability.filterType}
                                                onChange={handleChangeCustomerFilterType}>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'All Customers'}
                                                          value={'all'}/>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'First-Time Customers Only'}
                                                          value={'ftuOnly'}/>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'Repeat Customers Only'}
                                                          value={'repeatOnly'}/>
                                    </RadioGroup>
                                </Collapse>
                                <FormControlLabel control={<Radio color={'primary'}
                                                                  onChange={handleChangeCustomerApplicability}/>}
                                                  label={'User Specific Coupons'}
                                                  value={'userSpecific'}/>
                                <Collapse in={filterCustomerApplicability.applicability === 'userSpecific'}
                                          className={'nested-form-container'}>
                                    <RadioGroup value={userSpecificFilterType}
                                                onChange={handleChangeUserSpecificFilterType}>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'Every User-Specific Coupon'}
                                                          value={'all'}/>
                                        <FormControlLabel control={<Radio color={'primary'}/>}
                                                          label={'Search for Particular User'}
                                                          value={'searchedForUser'}/>
                                        <Collapse in={userSpecificFilterType === 'searchedForUser'}
                                                  className={'nested-form-container'}>
                                            <form noValidate
                                                  autoComplete="off"
                                                  onSubmit={handleSubmitUserSearch}
                                                  className={'user-search-form-container'}>
                                                <TextField id="userSearch"
                                                           label="Search Users"
                                                           value={userSearchTerm}
                                                           onChange={handleChangeUserSearch}/>
                                                <Button variant="contained"
                                                        color="primary"
                                                        onClick={handleSubmitUserSearch}
                                                        disabled={isFetchingUsers}
                                                        size={'small'}>Search</Button>
                                            </form>
                                        </Collapse>
                                    </RadioGroup>
                                </Collapse>
                            </RadioGroup>
                        </FormControl>
                    </Collapse>
                </div>
                }

                {/*Coupon Table*/}
                {isLoading
                    ? <Dialog open={isLoading}>
                        <DialogContent>
                            <CircularProgress className="horizontal-center"/>
                        </DialogContent>
                    </Dialog>
                    : couponsToDisplay && couponsToDisplay.length !== 0 &&
                    <VirtualizedTable
                        containerClasses="coupon-table-container"
                        itemCount={couponsToDisplay.length}
                        header={[
                            'Code',
                            'First Time User Only / Specific User',
                            'Discount Amount',
                            'Discount Type',
                            'Max Discount Amount',
                            'Max Usage per User',
                            'Total Usage',
                            'Toggle Activation'
                        ]}
                        renderItems={renderCoupons}
                        maxHeight={tableHeight ? tableHeight : 400}
                    />
                }
                {!isLoading && filterType === FILTER_TYPE.ALL && couponsToDisplay && couponsToDisplay.length === 0 &&
                <div className={'no-results-container'}>
                    <Typography>No Coupons Found</Typography>
                </div>
                }
            </CardBody>

            <CardActions className={'card-actions-button-container'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowNewCouponForm(true)}
                >
                    Create A Coupon
                </Button>
            </CardActions>
        </Card>

        <Card className={'permissions-card-container'}>
            <CardHeader title={
                <div className={'permissions-card-header-container'} onClick={() => setIsPermissionsCardExpanded(!isPermissionsCardExpanded)}>
                    <Typography>Coupon Permissions</Typography>
                    {isPermissionsCardExpanded ? <ExpandLess/> : <ExpandMore/>}
                </div>
            } />

            {/*<Collapse in={isPermissionsCardExpanded}  collapsedHeight={145}>*/}
                <Collapse in={isPermissionsCardExpanded}>
                <CardBody>
                    <DSPRCouponPermissionsForm
                        onSubmit={(values => handleUpdateCouponLocks(values))}
                        isCardExpanded={isPermissionsCardExpanded}
                        dsprCouponLocks={dsprCouponLocks}
                    />

                    <div className={'permissions-explainer-container'}>
                        <Typography variant={'subtitle1'}>What are lock types?</Typography>
                        <dl>
                            <dt>No Lock</dt>
                            <dd>Both managers and users can use this coupon</dd>
                            <dt>User Lock</dt>
                            <dd>Coupons can only be used by managers</dd>
                            <dt>Full Lock</dt>
                            <dd>All active coupons of this type will be deactivated. Coupons of this type cannot be created</dd>
                        </dl>

                    </div>
                </CardBody>
            </Collapse>
        </Card>

        <Dialog open={showNewCouponForm || showModifyCouponForm} onClose={() => closeCouponForm()} className="new-coupon-form">
            <DialogTitle>{showNewCouponForm ? 'Create a Coupon' : 'Modify Coupon'}</DialogTitle>
            <DialogContent>
                <NavPills
                    color="rose"
                    alignCenter
                    tabs={[
                        {
                            tabButton: "Flower",
                            // tabIcon: FlowerIcon,
                            tabContent: <CouponForm
                                onSubmit={(values) => handleNewOrModifyCoupon(values, true)}
                                isFlower={true}
                                isGeneric={false}
                                coupon={couponToModify ? couponToModify : null}
                                handleClose={closeCouponForm}
                                categories={categories ? categories : []}
                            />
                        },
                        {
                            tabButton: "Generic",
                            // tabIcon: GenericIcon,
                            tabContent: <CouponForm
                                onSubmit={(values) => handleNewOrModifyCoupon(values, false)}
                                categories={categories ? categories : []}
                                isFlower={false}
                                isGeneric={true}
                                coupon={couponToModify ? couponToModify : null}
                                handleClose={closeCouponForm}
                            />
                        },
                        {
                            tabButton: "User",
                            // tabIcon: UserIcon,
                            tabContent: <CouponForm
                                onSubmit={(values) => handleNewOrModifyCoupon(values, false)}
                                handleAutoCompleteSelected={interceptAutocompleteSelected}
                                isFlower={false}
                                isGeneric={false}
                                isUser={true}
                                coupon={couponToModify ? couponToModify : null}
                                handleClose={closeCouponForm}
                                categories={categories ? categories : []}
                            />
                        }
                    ]}
                />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showCouponDetailDialog}
            className={'coupon-detail-dialog'}
            maxWidth={'md'}
            onClose={() => closeCouponDetailDialog()}>
            {couponToModify
                ? <CouponWithDetails
                    coupon={couponToModify}
                    toggler={toggler}
                    isManagerForCurrentDSPR={isManagerForCurrentDSPR}
                    isSystemAdmin={isSystemAdmin}
                    handleClose={closeCouponDetailDialog}
                    handleOpenCouponForm={() => setShowModifyCouponForm(true)}
                />
                : <Typography>Coupon Detail is Unavailable</Typography>
            }
        </Dialog>

            <SnackBarAlertSuccess
                showSnackbarAlert={showSuccessAlert}
                setIsVisible={setShowSuccessAlert}
                alertText={alertText}
            />

            <SnackBarAlertError
                showSnackbarAlert={showErrorAlert}
                setIsVisible={setShowErrorAlert}
                alertTitle={errorAlertTitle}
                alertText={alertText}
                snackbarStyle={{width: 'fit-content'}}
            />
    </div>
}

export default Coupons;