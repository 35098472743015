import React, { Fragment, useEffect, useState } from 'react';
import { Button, ListItem, ListItemText, Typography } from "@material-ui/core";
import {
    CONFIRM_ALLOTMENT_ORDER_SUCCESS,
    confirmAllotmentOrder, NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS,
    notifyUserAllotmentIssueForOrder
} from "../../../actions/orderActions";
import { useDispatch } from "react-redux";
import { checkIfUserAlreadyNotified } from "./UserNotifiedAllotmentTime";
import { checkIfPreviousOrderStatusIsValid, orderStatus } from "../../../middleware/api";
import { DSPR, Order } from "../../../store/reduxStoreState";
import { ButtonOrderStatusInterface } from "../OrderStatusButtonInterface";


export const AllotmentButtons: React.FC<ButtonOrderStatusInterface> = props => {
    const {
        disableExtraButtons,
        setExtraOrderFunctionalityErrorCallBack,
        setDisableButtonCallBack,
        closeModal,
        order,
        orderId,
        dspr,
        isManagerForDSPR,
        isDriverForOrder,
        userNotifiedOfAllotment
    } = props;

    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const [showIsUserNotifiedOfAllotment, setShowIsUserNotifiedOfAllotment] = useState(false);
    const dispatch = useDispatch();

    const handleAllotment = (confirmAllotment: boolean) => {
        setDisableButtonCallBack(true)
        dispatch<any>(confirmAllotmentOrder(orderId, confirmAllotment)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === CONFIRM_ALLOTMENT_ORDER_SUCCESS) {
                if (closeModal) closeModal();
            }
        })
    }
    const handleNotifyUserAllotmentIssue = () => {
        const confirmedToSendEmail = window.confirm('This will send an email to the user, are you sure?');
        if (confirmedToSendEmail) {
            setDisableButtonCallBack(true)
            dispatch<any>(notifyUserAllotmentIssueForOrder(orderId)).then(response => {
                setDisableButtonCallBack(false)
                if (response.type === NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS) {
                    if (closeModal) closeModal();
                }
            })
        }
    }

    useEffect(() => {
        if (isManagerForDSPR || isDriverForOrder) return;
        const previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr, order, orderStatus.allotmentConfirmed);
        if (previousOrderStatusIsValid) setShowBasedOnOrderStatus(true);
        if (userNotifiedOfAllotment) setShowIsUserNotifiedOfAllotment(true);

    }, []);

    /** Allotment is for notify registry and occurs on orderStatus received only */
    return showBasedOnOrderStatus
        && <Fragment>
            <Typography style={{ textDecoration: 'underline' }} display="inline">Next Step Action </Typography>
            <div style={{ width: "100%", marginTop: "8px", marginBottom: "1px" }}>
                <Button variant="contained" color="primary" disabled={disableExtraButtons}
                    onClick={() => handleAllotment(true)}>
                    {dspr && dspr.id === 12 ? "Confirm Limit and Potency" : "Confirm Allotment"}
                </Button>
            </div>
            <div style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}>
                {!showIsUserNotifiedOfAllotment ?
                    <Button variant="contained" style={{ backgroundColor: "#F0CD63", color: "white" }}
                        onClick={() => handleNotifyUserAllotmentIssue()}>
                        Notify User Of Bad {dspr && dspr.id === 12 ? "Limit or Potency" : "Allotment"}
                    </Button> :
                    <Button variant="contained" disabled={showIsUserNotifiedOfAllotment}
                        onClick={() => handleNotifyUserAllotmentIssue()}>
                        Notify User Of Bad {dspr && dspr.id === 12 ? "Limit or Potency" : "Allotment"}
                    </Button>
                }
            </div>

        </Fragment>
}
