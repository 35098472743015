import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    getDSPRCurrentInventory,
    getDSPRProductInventoryTransactionHistories
} from "../actions/dsprProductInventoryActions";
import {
    Card,
    CardHeader,
    CircularProgress,
    IconButton,
    Typography,
    CardContent,
    Button,
    DialogTitle, Dialog, DialogContent
} from "@material-ui/core";
import {
    State,
    DSPRProductInventoryTransactionHistory as DSPRProductTransactionHistory,
    TransactionType, DspProduct, DSPR, DsprCurrentInventoryItem, Order
} from "../store/reduxStoreState";
import {
    getCurrentInventoryForSingleDSPRProductFromProps,
    getCurrentInventoryWithFullProductForDSPR,
    getDSPRProductInventoryTransactionHistoryWithProps
} from "../selectors/dsprCurrentInventorySelectors";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import VirtualizedTableDSPRInventoryTransactionHistory from "./VirtualizedTableDSPRInventoryTransactionHistory";
import { RouteComponentProps, useParams } from "react-router-dom";
import { getDSPProductFromProps } from "../selectors/dspProductSelector";
import CardBody from "./Card/CardBody";
import { getProduct } from "../actions/dspProductActions";
import { parseDate } from "../util/util";
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import InventoryTransactionCorrectionFormForModal from "./InventoryTransactionCorrectionFormForModal";
import { complianceSearchObject, getDSPRComplianceNumberHistory, GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_SUCCESS } from '../actions/orderActions';
import OrderWithDetailsAndPrices from '../containers/OrderWithDetailsAndPrices';
import { InsertEmoticon } from '@material-ui/icons';

const DSPRComplianceNumberOrderTransactionHistory = (props: RouteComponentProps) => {
   const {dsprId, productId, complianceNumber} = useParams<{ dsprId: string, productId: string, complianceNumber: string }>();

   const complianceDecoded = useMemo(() => {
        return complianceNumber ? atob(complianceNumber) : undefined;
   }, [complianceNumber])
   const [isLoading, setIsLoading] = useState(true);
   const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, {dsprId}), shallowEqual)
   const [header, setHeader] = useState("History for Compliance Number: ")
   const [complianceOrders, setComplianceOrders] = useState(undefined);
   const [selectedIndex, setSelectedIndex] = useState(-1);

   const [showOrderDialog, setShowOrderDialog] = useState(false);
   const [orderForDetails, setOrderForDetails] = useState(undefined);

   const closeOrderDetailsForm = () => {
       setOrderForDetails(undefined);
       setShowOrderDialog(false);
   }

   const dispatch = useDispatch();
   /**Fetch transaciton history for a given product whenever the component is mounted*/
   useEffect(() => {
        if(!complianceDecoded) props.history.goBack();
        if(!dspr) return;
        else {
            const searchObject:complianceSearchObject = {
                dsprId: parseInt(dsprId),
                productId: parseInt(productId)
            }
            const isMetrcDSPR = !!dspr.metrcLicense;
            if(isMetrcDSPR){
                searchObject['metrcTag'] = complianceDecoded;
                setHeader("History For Metrc Tag: " + complianceDecoded)
            }
            const isBatchBased = dspr.isBatchBased;
            if(isBatchBased) {
                searchObject['batchNumber'] = complianceDecoded;
                setHeader("History For Batch: " + complianceDecoded)
            }
            dispatch<any>(getDSPRComplianceNumberHistory(searchObject))
            .then((response) => {
                if(response.type === GET_DSPR_COMPLIANCE_NUMBER_ORDER_HISTORY_SUCCESS) {
                    const values: Order[] = response.response.entities && response.response.entities.dsprProductInventoryTransactionHistories ? Object.values(response.response.entities.dsprProductInventoryTransactionHistories) : [];
                    setComplianceOrders(values.filter((order:Order) => order.orderStatus === "completed" || order.orderStatus === "in_process" || order.orderStatus === "queued" || order.orderStatus === "allotment_confirmed" || order.orderStatus === "packaged"))
                }
                setIsLoading(false)})
        }       

       
   }, [dispatch, dspr, complianceNumber]);

   const handleRowClick = (item, index) => {
    setOrderForDetails(complianceOrders[index]);
    setShowOrderDialog(true);
   }

   /**Dispatch an action to get information for a product if it is not already in the store*/

//    const handleDialogOpen =() => {
//        setShowTransactionCorrectionForm(true);
//    }

//    const handleDialogClose = () => {
//        setShowTransactionCorrectionForm(false);
//    }

   /**Formats a number into a string, rounded to the hundredths place*/
   const format = (num: number) => num.toLocaleString('en-US', {
       minimumFractionDigits: 2,
       maximumFractionDigits: 2,
   });

   /**Renders a date cell for each transaction as mm-dd-yyyy
    *
    * on hover, a tooltip appears showing the date and time
    * */
   const dateCellRenderer = ({cellData}) => {
       const date = parseDate(cellData);
       const dateToDisplay = date.toLocaleDateString('en-US');
       const timeAndDateToDisplay = date.toLocaleString('en-US');
       return <span title={timeAndDateToDisplay}>{dateToDisplay}</span>
   }

   const columnsForTable = [
       {
           dataKey: 'id',
           label: 'Order #',
           width: 100
       },
       {
           dataKey: 'orderStatus',
           label: 'Status',
           width: 150,
       },
       {
           dataKey: 'lastStatusChangeTime',
           label: 'Last Update',
           width: 150,
           cellRenderer: dateCellRenderer
       },
    //    {
    //        dataKey: 'totalCostToDisplay',
    //        label: 'Total Cost',
    //        width: 100,
    //    },
    //    {
    //        dataKey: 'unitCostToDisplay',
    //        label: 'Unit Cost',
    //        width: 100,
    //    },
    //    {
    //        dataKey: 'cumulativeAverageToDisplay',
    //        label: 'Cumulative Average',
    //        width: 100,
    //    },
    //    {
    //        dataKey: 'cumulativeCostToDisplay',
    //        label: 'Cumulative Cost',
    //        width: 100,
    //    },
    //    {
    //        dataKey: 'cumulativeQuantity',
    //        label: 'Cumulative Quantity',
    //        width: 100,
    //    },
   ]

   return (
       <main className={'inventory-transaction-history'}>
           <h2>{dspr ? `${dspr.name} - Compliance Order History` : `DSPR Compliance Order History`}</h2>
           {(!complianceOrders || complianceOrders.length < 1) && isLoading === true ? 
                <CircularProgress className={'circular-progress'}/> : 
                <Card className={'transaction-history-card-container'}>
                    <CardHeader title={header} titleTypographyProps={{variant: 'h5', component: 'h2'}}/>
                    <CardContent className={'transaction-history-card-body'}>
                       <div className='transaction-history-table-container'>
                           <Typography variant={'h6'} component={'h3'}>Orders (most recent
                               first)</Typography>
                            {complianceOrders && complianceOrders.length > 0 ? <VirtualizedTableDSPRInventoryTransactionHistory
                               transactionHistory={complianceOrders}
                               columns={columnsForTable}
                               customOnRowClick={handleRowClick}
                               parentSelectedIndex={selectedIndex}
                               parentSetSelectedIndex={setSelectedIndex}
                           /> : <p>This Compliance Number doesn't have any Associated Orders</p>}
                           
                       </div>
                    </CardContent>
                </Card>}
                <Dialog
                    open={showOrderDialog}
                    onClose={() => closeOrderDetailsForm()}>
                    {orderForDetails ? (
                        <OrderWithDetailsAndPrices
                            showTripTicketOnly
                            hideNote
                            order={orderForDetails}
                            user={orderForDetails.user}
                            address={orderForDetails.address}
                            closeModal={closeOrderDetailsForm}
                        />
                    )
                        : <p>Order history is unavailable</p>}
                </Dialog>
       </main>
   )
}

export default DSPRComplianceNumberOrderTransactionHistory;