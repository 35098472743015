import React, { useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { CircularProgress, MenuItem } from '@material-ui/core';

import { interceptAutocompleteSelected } from "../actions/formHelperActions";
import { assignDSPRDriver, getAllDriversForDspr } from "../actions/driverActions";
import { getAllUsers, updateLoggedInUserInfo } from "../actions/userActions";
import { getManagedDSPRsForLoggedInUser } from "../selectors/dsprManagerSelectors";
import {
    getDSPRFromProps,
    getFullMenuDriverIdForDSPRFromProps,
    isFullMenuAvailableForDSPRFromProps
} from "../selectors/dsprSelectors";
import {
    DsprDriversWithUserMap,
    getDriversForDSPR,
    getDriversForDSPRAsMap,
} from "../selectors/dsprDriverSelector";
import { getLoggedInUser, getUsersByName } from "../selectors/userSelectors";
import { State, DSPR, User } from '../store/reduxStoreState';

import DSPRDrivers from "../components/DSPRDrivers";
import { canceledFetchErrorMessage } from "../middleware/api";
import { PhoneIphone as OncallIcon } from "@material-ui/icons";
import DSPRFullMenuDriverCard from "../components/DSPRFullMenuDriverCard";
import { DsprDriversWithUser } from "./DSPRInventoryContainer";

const DSPRDriversContainer: React.FC<{}> = () => {
    const { dsprId } = useParams<{dsprId: string}>();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [allUsersLoaded, setAllUsersLoaded] = useState(true);

    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, { dsprId }), shallowEqual);
    const loggedInUserManagedDSPRs = useSelector<State, DSPR[]>(getManagedDSPRsForLoggedInUser, shallowEqual);
    const dsprDrivers = useSelector<State, DsprDriversWithUser>(state =>
        getDriversForDSPR(state, { dsprId }), shallowEqual);
    const dsprDriversMap = useSelector<State, DsprDriversWithUserMap >(state => getDriversForDSPRAsMap(state, {dsprId}));
    const users = useSelector<State, { value: number, text: string }[]>(getUsersByName, shallowEqual);
    const loggedInUser = useSelector<State, User>(getLoggedInUser, shallowEqual);
    const isFullMenuEnabled = useSelector<State, boolean>(state => isFullMenuAvailableForDSPRFromProps(state, { dsprId }), shallowEqual);
    const currentFullMenuDriverId = useSelector<State, number | null>(state => getFullMenuDriverIdForDSPRFromProps(state, { dsprId }), shallowEqual);


    const isAdmin = loggedInUser && loggedInUser.systemAdministrator;
    const isDsprManager = loggedInUser && !!(loggedInUser && loggedInUser.dsprManagers.length);
    const driverId = loggedInUser && loggedInUser.dsprDrivers.length >= 1 && loggedInUser.dsprDrivers[0];
    const abortController = useMemo(() => new AbortController(), []);
    const { signal } = abortController;

    useEffect(() => {
        if (dsprId) {
            dispatch<any>(getAllDriversForDspr(dsprId, signal))
                .then((response) => {
                    //setIsLoading to false only if the response does not include a canceled fetch error message
                    //a canceled fetch error message is generated when the component unmounts. We want to avoid setting state in an unmounted component
                    if (!(response.error && response.error.includes(canceledFetchErrorMessage))) {
                        setIsLoading(false)
                    }
                });
        }

        return () => abortController.abort();
    }, [dsprId, dispatch, abortController, signal]);

    const getUsers = () => {
        setAllUsersLoaded(false);
        dispatch<any>(getAllUsers()).then(() => setAllUsersLoaded(true));
    }

    const isLoggedInUserThisDSPRManager = () => !!(dspr && loggedInUserManagedDSPRs.includes(dspr));

    const handleSubmitDriverForm = (newDriverValues: { userId: number, onCall?: boolean }) => {
        dispatch<any>(assignDSPRDriver(dsprId, newDriverValues.userId, newDriverValues.onCall))
            .then(() => dispatch(updateLoggedInUserInfo()));
    };

    /**Driver menu items for drop down
     * -> -Shows first and last name. Value is driverId*/
    //const dsprDriverMenuItems = dsprDrivers &&
    //    dsprDrivers.map(driver => {
    //        const activeClass = driver.active ? "active" : "inactive";
    //        return <MenuItem
    //            button
    //            key={driver.id}
    //            className={activeClass}
    //            value={driver.id.toString()}
    //        >
    //            {driver.onCall && <span><OncallIcon htmlColor="orange" /></span>}{driver.user.firstName} {driver.user.lastName} - {driver.user.email}
    //        </MenuItem>
    //    });

    const createDsprDriverMenuItems = useCallback((drivers: DsprDriversWithUser) => {
        return drivers.map(driver => {
            const activeClass = driver.active ? "active" : "inactive";
            return <MenuItem
                button
                key={driver.id}
                className={activeClass}
                value={driver.id.toString()}
            >
                {driver.onCall && <span><OncallIcon htmlColor="orange" /></span>}{driver.user.firstName} {driver.user.lastName} - {driver.user.email}
            </MenuItem>
        });
    }, []);

    const dsprDriverMenuItems = useMemo(() => {
        if (dsprDrivers) {
            return createDsprDriverMenuItems(dsprDrivers);
        }
    },[dsprDrivers, createDsprDriverMenuItems]);

    const onCallDriverMenuItems = useMemo(() => {
        if (dsprDrivers) {
            const onCallDrivers = dsprDrivers.filter(driver => driver.onCall);
            return createDsprDriverMenuItems(onCallDrivers)
        }
    }, [dsprDrivers, createDsprDriverMenuItems]);

    return <main className="drivers-tab">
        <h2>{!driverId || !!(isAdmin || isDsprManager) ? 'Drivers' : 'Driver'}</h2>
        {isLoading ? <CircularProgress /> :
            <Fragment>
                <div style={{maxWidth: 1200}}>
                    <DSPRDrivers
                        drivers={dsprDrivers}
                        users={users}
                        loggedInUserIsDSPRManager={isLoggedInUserThisDSPRManager()}
                        handleSubmitDriverForm={handleSubmitDriverForm}
                        interceptAutocompleteSelected={id => dispatch(interceptAutocompleteSelected(id))}
                        dsprId={dsprId}
                        driverId={(isAdmin || isDsprManager) ? undefined : driverId}
                        getAllUsersCallback={getUsers}
                        usersLoaded={allUsersLoaded}
                        dsprDriverMenuItems={dsprDriverMenuItems}
                        currentFullMenuDriverId={currentFullMenuDriverId}
                    />
                </div>
            </Fragment>}

        {isLoading ? null : <div style={{marginTop: 32}}>
            <h3>Full Menu Driver</h3>
                <div style={{maxWidth: 1200}}>
                    <DSPRFullMenuDriverCard
                        dsprId={dsprId}
                        isFullMenuEnabled={isFullMenuEnabled}
                        onCallDriverMenuItems={onCallDriverMenuItems}
                        dsprDrivers={dsprDrivers}
                        dsprDriversMap={dsprDriversMap}
                        currentFullMenuDriverId={currentFullMenuDriverId}
                    />
                </div>
        </div>
        }
    </main>
}

export default DSPRDriversContainer;