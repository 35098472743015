import React, { useEffect, useState } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";

import CustomInput from "../components/CustomInput/CustomInput";
import Button from "../components/CustomButtons/Button";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import CardFooter from "../components/Card/CardFooter";

import login from "../assets/img/highTechLogin.jpg";
import './loginStyles.scss';

interface LoginProps {
    errorMessage: string;
    handleSubmit: (username: string, password: string) => any;
}

const Login: React.FC<LoginProps> = props => {
    const { errorMessage, handleSubmit } = props;

    const [cardAnimaton, setCardAnimation] = useState("card-hidden");
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const timeout = setTimeout(() => setCardAnimation(""), 700);
        return () => clearTimeout(timeout);
    }, []);

    const submitLogin = () => {
        handleSubmit(username, password)
    }

    const handleInputKeyUp = (e: React.KeyboardEvent) => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) submitLogin();
    }

    return (
        <div className="login" style={{ backgroundImage: `url(${login})` }}>
            <div className="login-card">
                <form>
                    <Card login className={cardAnimaton}>
                        <CardHeader
                            className="login-header"
                            color="primary"
                        >
                            <img src='/assets/images/grassp_logistics.png' alt='Grassp Logistics'/>
                        </CardHeader>
                        <CardBody>
                            <CustomInput
                                labelText="Email"
                                id="email"
                                formControlProps={{
                                    fullWidth: true,
                                    error: !!(errorMessage)
                                }}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Email />
                                        </InputAdornment>
                                    ),
                                    type: "email",
                                    value: username,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value),
                                    onKeyUp: (e: React.KeyboardEvent) => handleInputKeyUp(e),
                                }}
                            />
                            <CustomInput
                                labelText="Password"
                                id="password"
                                formControlProps={{
                                    fullWidth: true,
                                    error: !!(errorMessage)
                                }}
                                helperText={errorMessage || ''}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon>lock_outline</Icon>
                                        </InputAdornment>
                                    ),
                                    type: "password",
                                    autoComplete: "off",
                                    value: password,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
                                    onKeyUp: (e: React.KeyboardEvent) => handleInputKeyUp(e),
                                }}
                            />
                        </CardBody>
                        <CardFooter className="login-footer">
                            <Button color="rose" size="lg" block onClick={() => submitLogin()}>
                                Log In
                            </Button>
                        </CardFooter>
                    </Card>
                </form>
            </div>
        </div>
    );
}

export default React.memo(Login);