import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Add } from '@material-ui/icons'
import lightGreen from '@material-ui/core/colors/lightGreen';
import AutoComplete from '../components/Autocomplete';

const lightGreen800 = lightGreen[800];

const validate = (values) => {
    const errors: any = {};
    if (!values.quantity) {
        errors.quantity = 'Required';
    }

    if (!values.productId) {
        errors.productId = 'Required';
    }
    return errors;
};

const renderField = ({ input, label, type, autocomplete, products, handleAutoCompleteSelected, meta: { touched, error, form } }) => (
    autocomplete ?
        <AutoComplete
            options={products}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
        />
        :
        <TextField label={label} type={type} {...input} helperText={touched && error ? error : ''} error={!!(touched && error)} />
)

interface AddDriverInventoryToInventoryPeriodFormProps {
    allProducts: { value: any, text: string }[];
    inStockProducts: { value: any, text: string }[];
    toggleProductsToDisplay: () => any;
    showInStockProductsOnly: boolean;
    handleAutoCompleteSelected: (id: any) => any;
}

const AddDriverInventoryToInventoryPeriodForm = (props: AddDriverInventoryToInventoryPeriodFormProps & InjectedFormProps<{}, AddDriverInventoryToInventoryPeriodFormProps>) => {
    const { handleSubmit, allProducts, inStockProducts, toggleProductsToDisplay, showInStockProductsOnly, handleAutoCompleteSelected } = props;
    return (
        <form onSubmit={handleSubmit} className="add-driver-inventory-to-inventory-period-form">
            <Field name="productId" component={renderField} autocomplete={true} products={showInStockProductsOnly ? inStockProducts : allProducts} label="Name of Product" handleAutoCompleteSelected={handleAutoCompleteSelected} />
            <Field name="quantity" component={renderField} label="Quantity" />
            <Button variant="contained" color="primary" startIcon={<Add htmlColor={lightGreen800} />} onClick={handleSubmit} style={{marginRight: 20}}>Add Item</Button>
            <div style={{marginTop: 12}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showInStockProductsOnly}
                        onChange={toggleProductsToDisplay}
                        color="primary"
                        size={'small'}
                    />
                }
                label="Hide out of stock products"
            />
            </div>
        </form>
    )
};

export default reduxForm<any, AddDriverInventoryToInventoryPeriodFormProps>({
    form: 'AddDriverInventoryToInventoryPeriodForm',
    validate
})(AddDriverInventoryToInventoryPeriodForm);