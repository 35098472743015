import { State } from '../store/reduxStoreState';
import {createSelector} from "reselect";
export const  getAllApplicableTaxesSelector = (state:State) => state.api.entities.applicableTax;

export const  getDSPRApplicableTaxAssociations = (state:State, props: {dsprId: number}) => state.api.entities.applicableTaxesWithDSPR[props.dsprId] || null;

export const getAllApplicableTaxesFromStateInArrayFormat = (state:State) => {
    return state.api.entities.applicableTax ? Object.values(state.api.entities.applicableTax): [];
}

export const getApplicableTaxesByName = createSelector(
    [getAllApplicableTaxesSelector], (taxes) => {
        if (!taxes) return [];
        return Object.keys(taxes)
            .map(key => taxes[key])
            .map(tax => { return { value: tax.id, text: tax.name}; });
    }
);

export const getAllApplicableTaxesForDSPRByDSPRId = createSelector(
    [getAllApplicableTaxesSelector, getDSPRApplicableTaxAssociations], (applicableTaxes, applicableTaxAssociationsForDSPR) => {
        return applicableTaxAssociationsForDSPR ? 
            applicableTaxAssociationsForDSPR.applicableTaxes ?
                {...applicableTaxAssociationsForDSPR, applicableTaxes: applicableTaxAssociationsForDSPR.applicableTaxes.map(taxId => applicableTaxes[taxId])}
            :null
        : null
    }
)
