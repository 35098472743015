import {CALL_API, Schemas} from '../middleware/api';


const ENDPOINT = 'brand';

export const GET_ALL_BRANDS_FOR_DSP = 'GET_ALL_BRANDS_FOR_DSP';
export const GET_ALL_BRANDS_FOR_DSP_SUCCESS = 'GET_ALL_BRANDS_FOR_DSP_SUCCESS';
export const GET_ALL_BRANDS_FOR_DSP_FAILURE = 'GET_ALL_BRANDS_FOR_DSP_FAILURE';

const allBrandsForDSPGetter = (dspId: number) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ALL_BRANDS_FOR_DSP, GET_ALL_BRANDS_FOR_DSP_SUCCESS, GET_ALL_BRANDS_FOR_DSP_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.BRAND_ARRAY,
            queryParamsMap: {delivery_service_provider_id: dspId}
        }
    }
};

export const getAllBrandsForDSP = (dspId: number) => (dispatch) => {
    // We are returning a promise
    return dispatch(allBrandsForDSPGetter(dspId));
};

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE';

const brandGetter = (brandId: number) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_BRAND, GET_BRAND_SUCCESS, GET_BRAND_FAILURE],
            endPoint: `${ENDPOINT}/${String(brandId)}`,
            schema: Schemas.BRAND,
        }
    }
};

export const getBrand = (brandId: number) => (dispatch) => {
    return dispatch(brandGetter(brandId));
};

/**Fetches and adds to store every full brand object for each brand in DSP */
export const getAllBrandsAndBrandProductsForDSP = (dspId: number) => (dispatch) => {
    return dispatch(allBrandsForDSPGetter(dspId)).then(response => {
        if (response.type === GET_ALL_BRANDS_FOR_DSP_SUCCESS) {
            const {result} = response.response;
            return result.forEach(brandId => dispatch(getBrand(brandId)));
        } else {
            return response;
        }
    });
};

export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILURE = 'CREATE_BRAND_FAILURE';

const brandCreator = (dspId: number, name: string, isActive: boolean,id:number, file?) => {
    const body = {
        id,
        deliveryServiceProvider: {
            id: dspId
        },
        name,
        isActive
    }

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_BRAND, CREATE_BRAND_SUCCESS, CREATE_BRAND_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.BRAND,
            body,
            file
        }
    }
};

export const createBrand = (dspId: number, name: string, isActive: boolean,id:number, imageFile) => (dispatch) => {
    return dispatch(brandCreator(dspId, name, isActive, id, imageFile))
};

export const ADD_PRODUCT_TO_BRAND = 'ADD_PRODUCT_TO_BRAND';
export const ADD_PRODUCT_TO_BRAND_SUCCESS = 'ADD_PRODUCT_TO_BRAND_SUCCESS';
export const ADD_PRODUCT_TO_BRAND_FAILURE = 'ADD_PRODUCT_TO_BRAND_FAILURE';

const productToBrandAdder = (productId: number, brandId: number, verificationToken?: string) => {
    const body = {
        product: {id: productId},
        brand: {id: brandId},
        verificationToken
    }

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ADD_PRODUCT_TO_BRAND, ADD_PRODUCT_TO_BRAND_SUCCESS, ADD_PRODUCT_TO_BRAND_FAILURE],
            endPoint: `brand/${String(productId)}/add_brand`,
            schema: Schemas.PRODUCT_WITH_CONFIRMATION_STATE,
            body
        }
    };
};

//Verification token is required if product is currently assigned to another brand
//  -> when assigning a product to a brand that is already assigned to another brand, a verification token is returned by the backend
//  -> if the user confirms they wish to reassign the product, dispatch addProductToBrand with the verification token
export const addProductToBrand = (productId: number, brandId: number, verificationToken?: string) => (dispatch) => {
    return dispatch(productToBrandAdder(productId, brandId, verificationToken))
};


export const REMOVE_PRODUCT_FROM_BRAND = 'REMOVE_PRODUCT_FROM_BRAND';
export const REMOVE_PRODUCT_FROM_BRAND_SUCCESS = 'REMOVE_PRODUCT_FROM_BRAND_SUCCESS';
export const REMOVE_PRODUCT_FROM_BRAND_FAILURE = 'REMOVE_PRODUCT_FROM_BRAND_FAILURE';

const productFromBrandRemover = (productId: number, brandId: number) => {
    const body = {
        id: productId,
        brandId: brandId,
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [REMOVE_PRODUCT_FROM_BRAND, REMOVE_PRODUCT_FROM_BRAND_SUCCESS, REMOVE_PRODUCT_FROM_BRAND_FAILURE],
            endPoint: `brand/${brandId}/remove_product`,
            schema: Schemas.DSP_PRODUCT,
            body,
            sendBody: true
        }
    };
};

export const removeProductFromBrand = (productId: number, brandId: number) => (dispatch) => {
    return dispatch(productFromBrandRemover(productId, brandId))
};


export const GET_DSPR_ANALYTICS_FOR_BRAND = 'GET_DSPR_ANALYTICS_FOR_BRAND';
export const GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS = 'GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS';
export const GET_DSPR_ANALYTICS_FOR_BRAND_FAILURE = 'GET_DSPR_ANALYTICS_FOR_BRAND_FAILURE';

const brandDSPRAnalyticsGetter = (brandId: number | string, dsprId: number | string) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_ANALYTICS_FOR_BRAND, GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS, GET_DSPR_ANALYTICS_FOR_BRAND_FAILURE],
            endPoint: `brand/analytics/${brandId}/dspr/${dsprId}`,
            schema: Schemas.BRAND
        }
    }
};

export const getBrandDSPRAnalytics = (brandId: number | string, dsprId: number | string) => (dispatch) => {
    return dispatch(brandDSPRAnalyticsGetter(brandId, dsprId));
};

export const REBUILD_BRAND_ANALYTICS_FOR_DSPR = 'REBUILD_BRAND_ANALYTICS_FOR_DSPR';
export const REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS = 'REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS';
export const REBUILD_BRAND_ANALYTICS_FOR_DSPR_FAILURE = 'REBUILD_BRAND_ANALYTICS_FOR_DSPR_FAILURE';

const dsprBrandAnalyticsRebuilder = (brandId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [REBUILD_BRAND_ANALYTICS_FOR_DSPR, REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS, REBUILD_BRAND_ANALYTICS_FOR_DSPR_FAILURE],
            endPoint: `brand/analytics/rebuild/${brandId}`,
            schema: Schemas.BRAND
        }
    }
};

export const rebuildDSPRBrandAnalytics = (brandId) => (dispatch) => {
    return dispatch(dsprBrandAnalyticsRebuilder(brandId));
};

export const GET_DSP_ANALYTICS_FOR_BRAND = 'GET_DSP_ANALYTICS_FOR_BRAND';
export const GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS = 'GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS';
export const GET_DSP_ANALYTICS_FOR_BRAND_FAILURE = 'GET_DSP_ANALYTICS_FOR_BRAND_FAILURE';

const brandDSPAnalyticsGetter = (brandId: number | string) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSP_ANALYTICS_FOR_BRAND, GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS, GET_DSP_ANALYTICS_FOR_BRAND_FAILURE],
            endPoint: `brand/analytics/${brandId}`,
            schema: Schemas.BRAND
        }
    }
};

export const getBrandDSPAnalytics = (brandId: number | string) => (dispatch) => {
    return dispatch(brandDSPAnalyticsGetter(brandId));
};