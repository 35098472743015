import React from "react";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";

// core components
import Button from "../CustomButtons/Button";

import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

const useStyles = makeStyles(styles as any);

type ColorOptions = 'primary' | 'info' | 'success' | 'warning' | 'danger';

interface NavBarProps {
    color?: ColorOptions,
    brandText?: string,
    handleDrawerToggle: () => void,
}

export default function Navbar(props: NavBarProps) {
    const classes = useStyles({});
    const { color } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}></div>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu style={{ transform: 'scale(1.75)' }} />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}
