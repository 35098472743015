import React from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {Button, TextField} from '@material-ui/core';
import { User } from '../store/reduxStoreState';

import "./ResetUserInfoFormStyles.scss"

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors['firstName'] = 'Required';
    }
    if (!values.lastName) {
        errors['lastName'] = 'Required';
    }
    if (!values.phoneNumber) {
        errors['phoneNumber'] = 'Required';
    } else if (values.phoneNumber.length < 10) {
        errors['phoneNumber'] = 'Invalid Phone Number'
    }

    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error }, ...custom }) => (
    <TextField {...input} label={label} type={type} {...custom}
                error={!!(touched && error)} helperText={touched && error ? error : ''}/>
);

interface ResetUserInfoFormProps {
}

const ResetUserInfoForm = (props: ResetUserInfoFormProps & InjectedFormProps<User, ResetUserInfoFormProps>) => {
    const { handleSubmit,
        pristine,
        submitting,
        initialValues
    } = props;

    return (
        <form onSubmit={handleSubmit} className="reset-user-info-form">
            <Field name="firstName" type="text" component={renderField} label="First Name" className="field" placeholder={initialValues.firstName}/>
            <Field name="lastName" type="text" component={renderField} label="Last Name" className="field" placeholder={initialValues.lastName}/>
            <Field name="phoneNumber" type="number" component={renderField} label="Phone" className="field phone" placeholder={initialValues.phoneNumber}/>
            <div className="submit-button">
                <Button color="secondary" variant="contained" disabled={pristine || submitting} onClick={handleSubmit}>Update User Info</Button>
            </div>
        </form>
    )
};

export default reduxForm<any, ResetUserInfoFormProps>({
    form: 'ResetUserInfoForm', // a unique identifier for this form
    validate,
    enableReinitialize: true
})(ResetUserInfoForm)