import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { geolocated, GeolocatedProps } from 'react-geolocated';
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { RouteComponentProps, Link } from 'react-router-dom';

import {
    Card, CardContent, CardActions, CardHeader,
    Table, TableBody, TableHead, TableRow, TableCell,
    List, ListItem, ListItemText,
    Radio, RadioGroup,
    Button, Dialog, Select, MenuItem, FormControlLabel, FormControl, InputLabel, DialogTitle, DialogContent, CircularProgress, Input, Typography
} from '@material-ui/core';
import { DoneAll, NoteOutlined } from '@material-ui/icons'
import red from '@material-ui/core/colors/red';
import SweetAlert from 'react-bootstrap-sweetalert';

import { currentDriverInventoryCSVDownloadLink } from '../actions/dsprActions';
import { getDSP } from '../actions/dspActions';
import { getDSPRDriver, setDriverLocation, setDriverOnCallState, toggleDSPRDriverActiveStatus, setUpdateDriverInformation, GET_DSPR_DRIVER_SUCCESS } from '../actions/driverActions';
import {
    createInventoryPeriod,
    getInventoryPeriod,
    removeInventoryItem,
    addInventoryItem,
    transferInventoryPeriod,
    CREATE_INVENTORY_PERIOD_SUCCESS,
    TRANSFER_INVENTORY_PERIOD_SUCCESS, TRANSFER_INVENTORY_PERIOD_FAILURE
} from '../actions/dsprDriverInventoryPeriodActions';
import { interceptAutocompleteSelected } from '../actions/formHelperActions';
import { getDSPRCurrentInventory } from '../actions/dsprProductInventoryActions';
import { getSpecificUser, USER_ID_DOCUMENT } from '../actions/userActions';
import {
    completeOrder, cancelOrder, markOrderInProcess, modifyOrder, getOrderCost,
    MODIFY_ORDER_SUCCESS, MODIFY_ORDER_FAILURE, COMPLETE_ORDER_SUCCESS, CANCEL_ORDER_SUCCESS, GET_ORDER_COST_FAILURE, MARK_IN_PROCESS_FAILURE
} from '../actions/orderActions';
import { getProduct, GET_PRODUCT_FAILURE } from '../actions/dspProductActions'

import {
    getCurrentDriverInventoryPeriodForDriverFromProps, getDSPRDriverWithUserAndOrdersAndServiceAreasAndCurrentRouteFromProps, getDriversForDSPR
} from '../selectors/dsprDriverSelector';
import { getDSPRFromProps } from '../selectors/dsprSelectors';
import { getDSPFromProps } from '../selectors/dspSelectors';
import { getLoggedInUser } from '../selectors/userSelectors';
import {
    getDSPRCurrentInventoryForAutoSelectForDriverItems,
    getInStockDSPRCurrentInventoryForAutoSelectForDriverItems
} from '../selectors/dsprCurrentInventorySelectors';
import { getManagedDSPRsForLoggedInUser } from '../selectors/dsprManagerSelectors';
import { getDSPProductFromProps } from '../selectors/dspProductSelector'
import { getDSPRDriverPageHasUnfinishedAPICall } from '../selectors/component/dsprDriverPage'

import {
    User, DSPR, DsprDriverInventoryPeriod, DsprDriverInventoryItem, DspProduct, State, DeliveryServiceProvider,
    DsprDriver, DsprDriverLocation, Order, OrderWithAddressAndUser, Route, DSPRDriverServiceArea, RouteLeg, RouteMetrics, RouteLegDirection
} from '../store/reduxStoreState';
import { getUserDocumentUrl } from '../middleware/api';
import { parseDate } from '../util/util';

import DriverRouteContainer from './DriverRouteContainer';
import DriverInventoryPeriodForm from './DriverInventoryPeriodForm';
import DriverInformationForm from './DriverInformationForm'
import AddDriverInventoryForm from './AddDriverInventoryItemToInventoryPeriodForm';
import OrderWithDetailsAndPrices from './OrderWithDetailsAndPrices';
import Accordion from '../components/Accordion/Accordion';
import NavPills from '../components/NavPills/NavPills';

import './DriverPageStyles.scss';
import { getOrdersWithAddresses } from '../selectors/orderSelectors';

const red500 = red[500];

export const markerColors = {
    yellow: { url: "/assets/images/yellow_marker.svg", labelOrigin: { x: 14, y: 15 } },
    orange: { url: "/assets/images/orange_marker.svg", labelOrigin: { x: 14, y: 15 } },
    red: { url: "/assets/images/red_marker.svg", labelOrigin: { x: 14, y: 15 } },
    blue: { url: "/assets/images/blue_marker.svg", labelOrigin: { x: 14, y: 15 } },
    green: { url: "/assets/images/green_marker.svg", labelOrigin: { x: 14, y: 15 } },
}

const GettingStartedGoogleMap = props => {
    const [hasClickedMarker, setHasClickedMarker] = useState(false);
    const [map, setMap] = useState<google.maps.Map>(undefined)

    const mapTimeToIcon = (time: string) => {
        const timePassedSinceOrderCreation = (new Date().valueOf() - parseDate(time).valueOf()) / 1000 / 60
        if (timePassedSinceOrderCreation >= 90) {
            return 'red';
        } else if (timePassedSinceOrderCreation >= 45) {
            return 'orange';
        } else {
            return 'yellow';
        }
    }

    const handleOrderClick = order => {
        if (!hasClickedMarker) setHasClickedMarker(true);
        props.handleOrderClick(order);
    }

    const name = props.driver && props.driver.user && props.driver.user.firstName + " " + props.driver.user.lastName;

    const initials = props.driver && props.driver.user && props.driver.firstName && props.driver.lastName &&
        props.driver.user.firstName.substring(0, 1) + props.driver.user.lastName.substring(0, 1);

    const driver = props.driver && props.driver.currentLocation && <Marker
        onClick={props.toggleInfoWindow}
        icon={props.icons["green"]}
        label={initials}
        position={{ lat: props.driver.currentLocation.latitude, lng: props.driver.currentLocation.longitude }}
    >
        {props.showInfoWindow &&
            <InfoWindow
                onCloseClick={props.toggleInfoWindow}
                position={{ lat: props.driver.currentLocation.latitude, lng: props.driver.currentLocation.longitude }}
                options={{ pixelOffset: new google.maps.Size(0, -42) }} // this positions the info window on the top of the marker, not covering it
            >
                <p>
                    {name}<br />Outstanding Orders: {props.driver.currentInProcessOrder ?
                        props.driver.queuedOrders.length + 1 : props.driver.queuedOrders.length}
                </p>
            </InfoWindow>}
    </Marker>

    const markers = props.driver && props.queuedOrders &&
        props.queuedOrders.map((order, index) => {
            if (!order.address) return null;
            return <Marker
                position={{ lat: order.address.latitude, lng: order.address.longitude }}
                {...order}
                icon={props.icons[mapTimeToIcon(order.createdTime)]}
                label={(++index).toString()}
                onClick={() => handleOrderClick(order)}
                key={order.address.id}
            />
        }).filter(marker => marker !== null);

    const inProcessOrder = props.driver.currentInProcessOrder;
    if (inProcessOrder && inProcessOrder.address) {
        markers.push(<Marker
            position={{ lat: inProcessOrder.address.latitude, lng: inProcessOrder.address.longitude }}
            icon={props.icons["blue"]}
            {...inProcessOrder}
            label="IP"
            onClick={() => handleOrderClick(inProcessOrder)}
            key={inProcessOrder.address.id} />);
    }

    // Use Longitude and Latitude of DSPR to set the location of the map if the driver's location isn't known
    const defaultCenter = props.driver ? props.driver.currentLocation ? { lat: props.driver.currentLocation.latitude, lng: props.driver.currentLocation.longitude } : props.dspr ? { lat: props.dspr.centralLatitude, lng: props.dspr.centralLongitude} : null : null;
    return <GoogleMap
            zoom={12}
            onLoad={(map: google.maps.Map)=> setMap(map)}
            onUnmount={(map)=> setMap(undefined)}
            center={(map && map.getCenter()) || defaultCenter}
            mapContainerStyle={{
                height: '500px',
                width: '100%',
            }}
        >
            {driver}
            {markers}
        </GoogleMap>
}

interface QueuedOrderProps {
    order: OrderWithAddressAndUser;
    markOrderInProcess: (orderId: number) => Promise<any>;
    OrderDetails?: JSX.Element;
    numberInQueue: number;
}

const QueuedOrder: React.FC<QueuedOrderProps> = props => {
    const { order, markOrderInProcess, OrderDetails, numberInQueue } = props;
    const [disableInProcessButton, setDisableInProcessButton] = useState(false);
    const [showOrderDetails, setShowOrderDetails] = useState(false);

    const handleMarkOrderAsInProcess = (orderId: number) => {
        setDisableInProcessButton(true);
        markOrderInProcess(orderId).then(response => {
            if (response.type === MARK_IN_PROCESS_FAILURE) setDisableInProcessButton(false);
        });
    };

    return (
        <Fragment>
            <ListItem>
                <ListItemText
                    primary={<Fragment>
                        <span>{numberInQueue}. {order.user.firstName} {order.user.lastName} {order.userFirstTimeOrderWithDSPR && "- FTP"}</span>
                        <span className="queued-order-total">, ${order.cashTotal} {(order.user.userNotes && order.user.userNotes.length > 0) ? <span> - < NoteOutlined className="note-Icon"/></span> : ""}</span>
                    </Fragment>}
                    secondary={`${order.address.street}, ${order.address.zipCode}` + (order.address.aptNumber ? ` - Apt #: ${order.address.aptNumber}` : "")}
                />
                <Button variant="contained" color="primary" onClick={() => setShowOrderDetails(true)}>Details</Button>
                <Button variant="contained" color="primary" disabled={disableInProcessButton} onClick={() => handleMarkOrderAsInProcess(order.id)}>Make In Process</Button>
            </ListItem>
            <Dialog title="Order Details"
                open={showOrderDetails}
                onClose={() => setShowOrderDetails(false)}>
                <Card className="driver-page-order-detail-popup-card">
                    {showOrderDetails && OrderDetails}
                </Card>
            </Dialog>
        </Fragment>
    )
}

interface DriverPageProps extends RouteComponentProps, GeolocatedProps {
    loggedInUser: User,
    dspr: DSPR,
    dsp: DeliveryServiceProvider,
    dsprDriver: Omit<DsprDriver, 'user'> & {
        user: User,
        currentLocation?: DsprDriverLocation,
        queuedOrders?: OrderWithAddressAndUser[],
        currentInProcessOrder?: OrderWithAddressAndUser,
        currentRoute?: Omit<Route, 'legs'> & {
            legs: Omit<RouteLeg, 'order'> & {
                order: OrderWithAddressAndUser
                routeLegDirections: Omit<RouteLegDirection, 'metrics'> & {
                    metrics: RouteMetrics,
                }[]
                overviewPolyline: google.maps.LatLng[]
            }[];
        }
        serviceAreas: DSPRDriverServiceArea[]
    },
    orders: {[key:number]: Order}
    dsprDrivers: (Omit<DsprDriver, 'user'> & { user: User })[],
    currentInventoryPeriod: Omit<DsprDriverInventoryPeriod, 'dsprDriverInventoryItems'> & {
        dsprDriverInventoryItems: (Omit<DsprDriverInventoryItem, 'product'> & { product: DspProduct })[]
    },
    availableInventoryAllProducts: { value: number, text: string }[],
    availableInventoryInStockOnly: { value: number, text: string }[],
    managedDSPRs: DSPR[],
    pollDriverInterval?: number;
    driverPageHasUnfinishedCall: boolean;
    getProductById: (productId: number) => any;

    setDriverLocation: (dsprId: any, latitude: any, longitude: any) => any;
    setDriverOnCallState: (dsprDriverId: any, isOnCall: any) => any;
    toggleDSPRDriverActiveStatus: (dsprDriverId: any) => any;
    getDSP: (dspId: any) => any;
    getDSPRDriver: (dsprDriverId: any) => any;
    createInventoryPeriod: (dsprDriver: any, cashOnHand: any, items: any) => any;
    interceptAutocompleteSelected: (id: any) => any;
    getDSPRCurrentInventory: (dsprId: any) => any;
    getInventoryPeriod: (inventoryPeriodId: any) => any;
    removeInventoryItem: (inventoryPeriodId: any, productId: any) => any;
    addInventoryItem: (inventoryPeriodId: any, productId: any, quantity: any) => any;
    getSpecificUser: (userId: any) => any;
    completeOrder: (orderId: any) => any;
    cancelOrder: (orderId: any) => any;
    markOrderInProcess: (orderId: any) => any;
    transferInventoryPeriod: (transferrerDriverId: any, transfereeDriverId: any) => any;
    setUpdateDriverInformation: (dsprDriverId: number, values: any[]) => any;
    modifyOrder: (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => any;
    getOrderCost: (order: any) => any;
    getProduct: (productId: number) => any;
}

interface DriverPageState {
    showAddInventoryForm: boolean;
    geoLocationPollCount: number;
    showCannotLocate: boolean;
    showPleaseEnableLocation: boolean;
    showIdDocDialog: boolean;
    loading: boolean;
    startup: boolean;
    selectedTransfereeId: string,
    enableMarkAsCompleteButton: boolean;
    disableTransferButton: boolean;
    disableCancelButton: boolean;

    showOrderDetails: boolean;
    order: any;

    showInfoWindow: boolean;
    showConfirmOnCallStateChangeWindow: boolean;
    onCallStateChangeText: string,
    showLoadingOnCallChange: boolean;
    showDriverInformationForm: boolean;

    showSuccessModal: boolean;
    successModalPrimaryText: string;
    successModalSecondaryText: string | JSX.Element;

    showErrorModal: boolean;
    errorModalPrimaryText: string;
    errorModalSecondaryText: string;

    activeAccordianTab: number;

    dsprDriverInventorySearch: string;
    showInStockInventoryOnly: boolean;
}

class DriverPage extends Component<DriverPageProps, DriverPageState> {
    constructor(props) {
        super(props);
        this.state = {
            showAddInventoryForm: true,
            geoLocationPollCount: 0,
            showCannotLocate: undefined,
            showPleaseEnableLocation: undefined,
            showIdDocDialog: false,
            loading: false,
            startup: true,
            selectedTransfereeId: null,
            enableMarkAsCompleteButton: true,
            disableTransferButton: false,
            disableCancelButton: false,

            showOrderDetails: false,
            order: null,

            showInfoWindow: false,
            showConfirmOnCallStateChangeWindow: false,
            onCallStateChangeText: null,
            showLoadingOnCallChange: false,
            showDriverInformationForm: false,

            showSuccessModal: false,
            successModalPrimaryText: undefined,
            successModalSecondaryText: undefined,

            showErrorModal: false,
            errorModalPrimaryText: undefined,
            errorModalSecondaryText: undefined,

            activeAccordianTab: 0,

            dsprDriverInventorySearch: undefined,
            showInStockInventoryOnly: true,
        };
    }

    timeout: NodeJS.Timeout;
    driverTimeout: NodeJS.Timeout;

    handleCompleteOrder = (orderId) => () => {
        this.props.completeOrder(orderId).then((response) => {
            if (response.type === COMPLETE_ORDER_SUCCESS) {
                this.setState({ showSuccessModal: true, successModalPrimaryText: "Order Completed", successModalSecondaryText: "The order has been successfully completed" })
            }
            this.setState({ enableMarkAsCompleteButton: true })
        });
        this.setState({
            enableMarkAsCompleteButton: false,
            //Enable Loading Spinner
        });
    };

    handleCancelOrder = async (orderId) => {
        this.setState({ disableCancelButton: true })
        return this.props.cancelOrder(orderId).then(response => {
            if (response.type === CANCEL_ORDER_SUCCESS) {
                this.setState({
                    showSuccessModal: true,
                    successModalPrimaryText: "Order Cancelled",
                    successModalSecondaryText: "The order has been successfully canceled",
                })
            }
            this.setState({ disableCancelButton: false })
            return response;
        });
    };

    handleOrderClick = (order) => {
        this.setState({ showOrderDetails: true, order });
    };

    handleModifyOrder = (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]): Promise<string> => {
        return this.props.modifyOrder(orderId, orderDetails, newDriverId,transferInventoryOnDriverchange, couponCodes)
            .then(response => {
                if (response.type === MODIFY_ORDER_SUCCESS) {
                    this.setState({ showSuccessModal: true, successModalPrimaryText: "Order Modified", successModalSecondaryText: "The order has been successfully modified!" });
                    if (this.driverTimeout) clearTimeout(this.driverTimeout);
                    this.pollDriverAndInventory();
                    return null;
                } else if (response.type === MODIFY_ORDER_FAILURE) {
                    const errorMessage = response.error;
                    if (errorMessage) {
                        const notSufficientInventoryChecker = new RegExp(/Driver '[0-9]*\.*[0-9]*' does not have sufficient inventory of product '[0-9]*\.*[0-9]*' to fulfill the requested order./g)
                        const splittedErrorMessage = errorMessage.split("'");
                        const insufficientProductId = splittedErrorMessage && splittedErrorMessage[3];
                        if (insufficientProductId && notSufficientInventoryChecker.test(errorMessage)) {
                            return `The new driver does not have sufficient inventory of product "
                            ${this.props.getProductById(insufficientProductId).name}" to fulfill the requested order.`;
                        } else {
                            return errorMessage;
                        }
                    } else {
                        return 'An error occurred during the transfer';
                    }
                }
            });
    }

    handleGetProductRequest = (productId: number) => {
        return this.props.getProduct(productId)
            .then(response => {
                if (response.type === GET_PRODUCT_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.dspProducts &&
                        response.response.entities.dspProducts[productId];
                }
            })
    }

    handleOrderCostCalculateRequest = order => {
        return this.props.getOrderCost(order)
            .then(response => {
                if (response.type === GET_ORDER_COST_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.orders &&
                        response.response.entities.orders[0];
                }
            })
    }

    createInventoryPeriod = (values) => {
        this.props.createInventoryPeriod(this.props.dsprDriver, values.cashOnHand, []).then(response => {
            if(response && response.type === CREATE_INVENTORY_PERIOD_SUCCESS) {
               //const correctExpansionPanel = expansionPanels.(expansionPanel => expansionPanel.innerText === "Current Inventory Period")
               this.setState({activeAccordianTab: 2})
            }
        })
    }

    updateDriverInformation = (values) => {
        const modifiedValues = { ...values, employeeIDExpirationDate: values.employeeIDExpirationDate.toISOString()}
        this.props.setUpdateDriverInformation(this.props.dsprDriver.id, modifiedValues).then(response => {
            this.setState({ showDriverInformationForm: false })
            //Check if the response was good with if(response.type === SET_DRIVER_INFORMATION_SUCCESS) and show confirmation
        })
    }

    pollDriverAndInventory = () => {
        if (this.state.startup) this.setState({ loading: true });
        if (!this.props.driverPageHasUnfinishedCall) {
            this.props.getDSPRDriver((this.props.match.params as { dsprDriverId: string }).dsprDriverId)
            .then(() => {
                if (this.props.dsprDriver) this.props.getSpecificUser(this.props.dsprDriver.user.id);
                if (this.props.dspr && this.isLoggedInUserDSPRManager()) {
                    this.props.getDSP(this.props.dspr.deliveryServiceProvider);
                    this.props.getDSPRCurrentInventory(this.props.dspr.id);
                }
                if (this.props.dsprDriver && this.props.dsprDriver.currentInventoryPeriod)
                    this.props.getInventoryPeriod(this.props.dsprDriver.currentInventoryPeriod);
            })
            .then(() => this.setState({ startup: false, loading: false }));
            this.driverTimeout = setTimeout(this.pollDriverAndInventory, this.props.pollDriverInterval || 30000);
        } else {
            this.driverTimeout = setTimeout(this.pollDriverAndInventory, 1000);
        }
        
    };

    componentDidMount() {
        this.pollDriverAndInventory();
        if (this.props.isGeolocationEnabled) {
            if (this.props.coords) {
                this.updateDriverLocation();
            }
            else {
                this.geolocationPoll();
            }
        } else {
            this.setState({ showPleaseEnableLocation: true });
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearTimeout(this.driverTimeout);
    }

    geolocationPoll = () => {
        if (!this.props.coords) {
            if (this.state.geoLocationPollCount < 20) {
                this.setState((prevState, props) => ({ geoLocationPollCount: prevState.geoLocationPollCount + 1 }));
                this.timeout = setTimeout(this.geolocationPoll, 100);
            }
            else {
                this.setState({ showCannotLocate: true });
            }
        } else {
            this.updateDriverLocation();
        }
    };

    updateDriverLocation = () => {
        if (this.isLoggedInUserDriver()) this.props.setDriverLocation(this.props.dspr.id, this.props.coords.latitude, this.props.coords.longitude);
        this.timeout = setTimeout(this.updateDriverLocation, this.props.pollDriverInterval || 30000);
    };

    removeInventoryItem = (event, productId) => {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();
        if (event.stopImmediatePropagation) event.stopImmediatePropagation();
        this.props.removeInventoryItem(this.props.dsprDriver.currentInventoryPeriod, productId);
    };

    addInventoryItem = (values) => {
        this.setState({ showAddInventoryForm: false });
        this.props.addInventoryItem(this.props.dsprDriver.currentInventoryPeriod, values.productId, values.quantity)
            .then(() => this.setState({ showAddInventoryForm: true }));
    };

    isLoggedInUserDSPRManager = () => {
        if (!this.props.dsprDriver || !this.props.managedDSPRs) return false;
        return this.props.managedDSPRs.filter(dspr => this.props.dsprDriver.dspr === dspr.id).length > 0;
    };

    handleViewId = () => () => {
        this.setState({ showIdDocDialog: true });
    };

    isLoggedInUserDriver = () => {
        return this.props.loggedInUser && this.props.dsprDriver &&
            this.props.loggedInUser.id === this.props.dsprDriver.user.id;
    };

    generateTransferDriverMenuItem = (driver) => {
        //eslint-disable-next-line
        if (driver.id == (this.props.match.params as { dsprDriverId: string }).dsprDriverId) return null;
        return <MenuItem value={driver.id} key={driver.id}>{`${driver.user.firstName} ${driver.user.lastName}`}</MenuItem>
    };

    handleTransfereeSelected = (event) => { this.setState({ selectedTransfereeId: event.target.value }) };

    handleTransferInventoryPeriod = () => {
        this.setState({
            disableTransferButton: true,
        });
        this.props.transferInventoryPeriod((this.props.match.params as { dsprDriverId: string }).dsprDriverId, this.state.selectedTransfereeId)
            .then((response) => {
                if (response.type === TRANSFER_INVENTORY_PERIOD_SUCCESS) {
                    this.setState({
                        showSuccessModal: true,
                        successModalPrimaryText: "Order Cancelled",
                        successModalSecondaryText: (<div>
                            <Typography>The order has been successfully canceled</Typography>
                            <Link to={`/driver/${this.state.selectedTransfereeId}`}>
                                Go to Driver with Transferred Inventory
                            </Link>
                            </div>),
                    })
                }

                if (response.type === TRANSFER_INVENTORY_PERIOD_FAILURE) {
                    this.setState({
                        showErrorModal: true,
                        errorModalPrimaryText: "Inventory Transfer Failed",
                        errorModalSecondaryText: response.error || 'Please try again',
                    })
                }
                //previous action taken after promise resolved:
                //history.push(`/driver/${this.state.selectedTransfereeId}`)
            });
    };

    handleGetDriverRequest = (driverId: number) => {
        return this.props.getDSPRDriver(driverId).then((response) => {
            if (response.type === GET_DSPR_DRIVER_SUCCESS){
                return response;
            } else {
                return response.error;
            }
        })
    }

    updateDSPRDriverInventorySearch = (event) => {
        this.setState({
            dsprDriverInventorySearch: event.target.value,
        })
    }
    removeDSPRDriverInventorySearch = () => {
        this.setState({
            dsprDriverInventorySearch: null
        })
    }

    render() {
        const dsprName = this.props.dspr ? this.props.dspr.name : undefined;
        const dspName = this.props.dsp ? this.props.dsp.name : undefined;
        const dsprDisplayName = dsprName && dspName ? dspName + " - " + dsprName : "Loading";

        const driver = this.props.dsprDriver;
        let name = driver
            ? driver.user
                ? ": " + driver.user.firstName + " " + driver.user.lastName
                : ""
            : "";
        name = dsprDisplayName + name;// + (extra ? " - " + extra : "");

        const driverInformationFormInitialValues = this.props.dsprDriver ? {
            employeeNumber: this.props.dsprDriver.employeeNumber,
            vehicleDescriptiveName: this.props.dsprDriver.vehicleDescriptiveName,
            vehicleLicensePlateNumber: this.props.dsprDriver.vehicleLicensePlateNumber,
            employeeIDExpirationDate: this.props.dsprDriver.employeeIDExpirationDate ? new Date(this.props.dsprDriver.employeeIDExpirationDate) : null
        } : {};
        const loggedInUserIsDriver = this.isLoggedInUserDriver();
        const loggedInUserIsManager = this.isLoggedInUserDSPRManager();

        const onCallToggler = driver ? (
            <RadioGroup
                name="onCallToggler"
                value={driver.onCall ? "on" : "off"}
                onChange={(event) => {
                    this.setState({ showConfirmOnCallStateChangeWindow: true, onCallStateChangeText: event.target.value });
                }}
                row
            >
                <FormControlLabel
                    value="off"
                    control={<Radio />}
                    label="Off"
                />
                <FormControlLabel
                    value="on"
                    control={<Radio />}
                    label="On"
                />
            </RadioGroup>
        ) : null;

        const driverItem = driver ?
            <div className="driver-on-call-toggler">
                <span className="toggler-text">Toggle On Call</span>
                {onCallToggler}
            </div>
            : null;

        const managerAccordionCollapseContent = [
            {
                title: 'Current Inventory Period',
                content: this.state.loading ? <CircularProgress /> : this.props.currentInventoryPeriod ? <Fragment>
                    <div className="current-inventory-period">
                        <section>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={currentDriverInventoryCSVDownloadLink((this.props.match.params as { dsprDriverId: string }).dsprDriverId)}
                                >
                                    Download CSV
                            </Button>
                            </div>
                            <div className="transfer-inventory-form">
                                <FormControl>
                                    <InputLabel id="transfer-inventory-period-select-label">Transfer Inventory Period</InputLabel>
                                    <Select value={this.state.selectedTransfereeId || ''} labelId="transfer-inventory-period-select-label" onChange={this.handleTransfereeSelected}>
                                        {this.props.dsprDrivers.map(driver => this.generateTransferDriverMenuItem(driver))}
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color="primary" disabled={this.state.disableTransferButton} onClick={this.handleTransferInventoryPeriod}>Transfer</Button>
                            </div>
                            {this.state.showAddInventoryForm &&
                                <AddDriverInventoryForm
                                    allProducts={this.props.availableInventoryAllProducts}
                                    inStockProducts={this.props.availableInventoryInStockOnly}
                                    handleAutoCompleteSelected={this.props.interceptAutocompleteSelected}
                                    toggleProductsToDisplay={() => this.setState({showInStockInventoryOnly: !this.state.showInStockInventoryOnly})}
                                    showInStockProductsOnly={this.state.showInStockInventoryOnly}
                                    onSubmit={this.addInventoryItem} />}
                            <div className="product-search-container">
                                <Input value={this.state.dsprDriverInventorySearch || ""} className="product-search-input" placeholder="Search For A Product" onChange={this.updateDSPRDriverInventorySearch}></Input>
                                <Button color="primary" variant="contained" onClick={() => this.removeDSPRDriverInventorySearch()}>cancel</Button>
                            </div>
                        </section>
                        <section>
                            {this.props.currentInventoryPeriod.dsprDriverInventoryItems &&
                                <div className="driver-inventory-table-container">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Quantity Available</TableCell>
                                                <TableCell>Quantity In Process</TableCell>
                                                <TableCell>Quantity Sold</TableCell>
                                                <TableCell>Cost of Quantity Available</TableCell>
                                                <TableCell>Cost of Quantity In Process</TableCell>
                                                <TableCell>Cost of Quantity Sold</TableCell>
                                                <TableCell>Return Available to DSPR</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.currentInventoryPeriod.dsprDriverInventoryItems.filter(item => item.product.name.toLowerCase().includes(this.state.dsprDriverInventorySearch ? this.state.dsprDriverInventorySearch.toLowerCase() : '')).map(item => {
                                                return <TableRow key={item.id}>
                                                    <TableCell
                                                        className={'driver-inventory-productName-cell'}
                                                        onClick={() => { const win = window.open(`/product/${item.product.id}`, '_blank'); win.focus() }}
                                                    >
                                                        {item.product.name}
                                                    </TableCell>
                                                    <TableCell>{item.quantityAvailable}</TableCell>
                                                    <TableCell>{item.quantityInProcess}</TableCell>
                                                    <TableCell>{item.quantitySold}</TableCell>
                                                    <TableCell>${item.costOfQuantityAvailable}</TableCell>
                                                    <TableCell>${item.costOfQuantityInProcess}</TableCell>
                                                    <TableCell>${item.costOfQuantitySold}</TableCell>
                                                    <TableCell>
                                                        <Button variant="contained" color="primary" onClick={(event) => this.removeInventoryItem(event, item.product.id)}>
                                                            <DoneAll htmlColor={red500} />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>}
                        </section>
                    </div>
                </Fragment> : <p>Driver does not currently have an inventory period</p>
            },
            {
                title: 'New Inventory Period',
                content: <DriverInventoryPeriodForm onSubmit={this.createInventoryPeriod} />
            },
        ]

        const accordionCollapses = [
            {
                title: "Route",
                content: <Fragment>
                    <DriverRouteContainer 
                        driver={driver} 
                        dspr={this.props.dspr} 
                        completeOrder={this.props.completeOrder}
                        modifyOrder={{
                            show: !(loggedInUserIsDriver && !this.isLoggedInUserDSPRManager()),
                            dsprDrivers: this.props.dsprDrivers,
                            availableInventory: (this.props.currentInventoryPeriod && this.props.currentInventoryPeriod.dsprDriverInventoryItems) || [],
                            modifyOrder: this.handleModifyOrder,
                            calculateOrderTotal: this.handleOrderCostCalculateRequest,
                            getDSPRDriver: this.handleGetDriverRequest,
                            getProductWithId: this.handleGetProductRequest
                        }}
                        loggedInUserIsDriver={loggedInUserIsDriver}
                        handleMapOrderClick ={this.handleOrderClick}
                        dsprDriverIdForOrderDetails={loggedInUserIsDriver ? parseInt((this.props.match.params as { dsprDriverId: string }).dsprDriverId) : null}
                    />
                </Fragment>
            },
            {
                title: 'Current Orders',
                content: <div style={{width: "100%"}}>
                    <NavPills
                        color="warning"
                        tabs={[
                            {
                                tabButton: "Table View",
                                tabContent: <div>
                                    {driver && driver.currentInProcessOrder &&
                                        <Fragment>
                                            <h3>In Process</h3>
                                            <Card>
                                                <OrderWithDetailsAndPrices
                                                    order={driver.currentInProcessOrder}
                                                    user={driver.currentInProcessOrder.user}
                                                    cancelOrderHandler={(orderId) => this.handleCancelOrder(orderId)}
                                                    address={driver.currentInProcessOrder.address}
                                                    dsprDriverId={loggedInUserIsDriver ? parseInt((this.props.match.params as { dsprDriverId: string }).dsprDriverId) : null}
                                                    modifyOrder={{
                                                        show: !(loggedInUserIsDriver && !this.isLoggedInUserDSPRManager()),
                                                        dsprDrivers: this.props.dsprDrivers,
                                                        availableInventory: (this.props.currentInventoryPeriod && this.props.currentInventoryPeriod.dsprDriverInventoryItems) || [],
                                                        modifyOrder: this.handleModifyOrder,
                                                        calculateOrderTotal: this.handleOrderCostCalculateRequest,
                                                        getDSPRDriver: this.handleGetDriverRequest,
                                                        getProductWithId: this.handleGetProductRequest
                                                    }}
                                                />
                                                {(loggedInUserIsDriver || this.isLoggedInUserDSPRManager()) &&
                                                    <div>
                                                        <div><Button variant="contained" color="primary" style={{ marginBottom: 10 }} disabled={!this.state.enableMarkAsCompleteButton} onClick={this.handleCompleteOrder(driver.currentInProcessOrder.id)}>Mark as Complete</Button></div>
                                                        <div><Button variant="contained" color="primary" style={{ marginBottom: 10 }} onClick={this.handleViewId()}>View ID</Button></div>
                                                    </div>}
                                            </Card>
                                        </Fragment>}
                                    {driver && driver.queuedOrders && driver.queuedOrders.length > 0 &&
                                        <Fragment>
                                            <h3>Queued Orders</h3>
                                            <List className="queued-orders-list">
                                                {(driver.queuedOrders as OrderWithAddressAndUser[]).map((order, index) =>
                                                    <QueuedOrder
                                                        key={order.id}
                                                        order={order}
                                                        markOrderInProcess={this.props.markOrderInProcess}
                                                        numberInQueue={driver.currentInProcessOrder ? (++index + 1) : (++index)}
                                                        OrderDetails={
                                                            <OrderWithDetailsAndPrices
                                                                order={order}
                                                                user={order.user}
                                                                address={order.address}
                                                                cancelOrderHandler={(orderId) => this.handleCancelOrder(orderId)}
                                                                dsprDriverId={loggedInUserIsDriver ? parseInt((this.props.match.params as { dsprDriverId: string }).dsprDriverId) : null}
                                                                modifyOrder={{
                                                                    show: !(loggedInUserIsDriver && !this.isLoggedInUserDSPRManager()),
                                                                    dsprDrivers: this.props.dsprDrivers,
                                                                    availableInventory: (this.props.currentInventoryPeriod && this.props.currentInventoryPeriod.dsprDriverInventoryItems) || [],
                                                                    modifyOrder: this.handleModifyOrder,
                                                                    calculateOrderTotal: this.handleOrderCostCalculateRequest,
                                                                    getDSPRDriver: this.handleGetDriverRequest,
                                                                    getProductWithId: this.handleGetProductRequest
                                                                }}
                                                            />
                                                        }
                                                    />
                                                )}
                                            </List>
                                        </Fragment>}
                                    {driver && !driver.currentInProcessOrder && (!driver.queuedOrders || (driver.queuedOrders.length === 0)) &&
                                        <span>The driver does not have any current orders outstanding</span>}
                                </div>
                            },
                            {
                                tabButton: "Map View",
                                tabContent: <div>
                                    {this.props.dsprDriver && !this.state.loading &&
                                        <GettingStartedGoogleMap
                                            driver={this.props.dsprDriver}
                                            dspr={this.props.dspr}
                                            queuedOrders={this.props.dsprDriver.queuedOrders}
                                            handleOrderClick={this.handleOrderClick}
                                            toggleInfoWindow={() => this.setState({ showInfoWindow: !this.state.showInfoWindow })}
                                            showInfoWindow={this.state.showInfoWindow}
                                            icons={markerColors}
                                        />}
                                    <Dialog title="Order Details"
                                        open={this.state.showOrderDetails}
                                        onClose={() => this.setState({ showOrderDetails: false })}>
                                        <Card className="driver-page-order-detail-popup-card">
                                            {this.state.showOrderDetails &&
                                                <OrderWithDetailsAndPrices
                                                    hideNote
                                                    order={this.state.order}
                                                    user={this.state.order.user}
                                                    address={this.state.order.address}
                                                    cancelOrderHandler={(orderId) => this.handleCancelOrder(orderId)}
                                                    modifyOrder={{
                                                        show: !(loggedInUserIsDriver && !this.isLoggedInUserDSPRManager()),
                                                        dsprDrivers: this.props.dsprDrivers,
                                                        availableInventory: (this.props.currentInventoryPeriod && this.props.currentInventoryPeriod.dsprDriverInventoryItems) || [],
                                                        modifyOrder: this.handleModifyOrder,
                                                        calculateOrderTotal: this.handleOrderCostCalculateRequest,
                                                        getDSPRDriver: this.handleGetDriverRequest,
                                                        getProductWithId: this.handleGetProductRequest
                                                    }}
                                                />}
                                        </Card>
                                    </Dialog>
                                </div>
                            },
                        ]}
                    />
                </div>
            },
        ];

        if (this.isLoggedInUserDSPRManager) {
            accordionCollapses.push(...managerAccordionCollapseContent);
        } else {
            accordionCollapses.push({
                title: 'Manage Yourself',
                content: <CardActions>
                    <Button variant="contained" color="primary" href={currentDriverInventoryCSVDownloadLink((this.props.match.params as { dsprDriverId: string }).dsprDriverId)}>Download CSV</Button>
                </CardActions>
            });
        }

        return this.state.loading ? <CircularProgress /> : (
            <Fragment>
                <Card className="driver-page">
                    <Dialog open={this.state.showIdDocDialog}
                        onClose={() => this.setState({ showIdDocDialog: false })}>
                        {driver && driver.currentInProcessOrder ?
                            <img src={getUserDocumentUrl(USER_ID_DOCUMENT, driver.currentInProcessOrder.user.id)} alt={USER_ID_DOCUMENT} /> : <p>No ID Document</p>}
                    </Dialog>
                    <Dialog
                        open={this.state.showConfirmOnCallStateChangeWindow}
                        onClose={() => this.setState({ showConfirmOnCallStateChangeWindow: false, onCallStateChangeText: null })}>
                        <DialogTitle>Confirm Driver State Change</DialogTitle>
                        <DialogContent>
                            {this.state.showLoadingOnCallChange ? <CircularProgress /> :
                                <Button
                                    className="driver-on-call-confirmation-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.setState({ showLoadingOnCallChange: true });
                                        this.props.setDriverOnCallState((this.props.match.params as { dsprDriverId: string }).dsprDriverId, this.state.onCallStateChangeText)
                                            .then(() => this.setState({
                                                showConfirmOnCallStateChangeWindow: false,
                                                onCallStateChangeText: null,
                                                showLoadingOnCallChange: false
                                            }));
                                    }}>{"Set" + (loggedInUserIsDriver ? " yourself " : " driver ") + this.state.onCallStateChangeText + " call"}</Button>}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showDriverInformationForm}
                        onClose={() => this.setState({ showDriverInformationForm: false })}
                    >
                        <DialogTitle>Edit Driver Information</DialogTitle>
                        <DriverInformationForm driver={this.props.dsprDriver} initialValues={driverInformationFormInitialValues} onSubmit={this.updateDriverInformation} />
                    </Dialog>

                    <CardHeader title={name} />
                    <CardContent>
                        {loggedInUserIsManager && <Button variant='contained' color='primary' onClick={() => this.setState({ showDriverInformationForm: true })}>Update Driver Information</Button>}
                        {loggedInUserIsDriver && <Fragment>
                            <Button variant='contained' color='primary' href='https://appho.st/d/#/To4mwwlZ'>
                                Driver Mobile App
                             </Button>
                            {this.state.showPleaseEnableLocation &&
                                <div>Please enable location services in the browser so that we can track your location.</div>}
                            {this.state.showCannotLocate &&
                                <div>Something is wrong with your location services. We cannot locate you. Please call your manager to address.</div>}
                            {/* {this.state.showPleaseEnableLocation === false && this.state.showCannotLocate === false &&
                                    <List>{driverItem}</List>} */}
                            {driverItem}
                        </Fragment>}
                        <Accordion active={this.state.activeAccordianTab} collapses={accordionCollapses} />
                    </CardContent>
                </Card>
                {this.state.showSuccessModal && <SweetAlert
                    success
                    timeout={2000}
                    style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                    title={this.state.successModalPrimaryText}
                    onConfirm={() => this.setState({ showSuccessModal: false })}
                    showConfirm={false}
                >
                    {this.state.successModalSecondaryText}
                </SweetAlert>}
                {this.state.showErrorModal && <SweetAlert
                    error
                    timeout={2000}
                    style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                    title={this.state.errorModalPrimaryText}
                    onConfirm={() => this.setState({ showSuccessModal: false })}
                    showConfirm={false}
                >
                    {this.state.errorModalSecondaryText}
                </SweetAlert>}
            </Fragment>
        )
    };
}

const mapStateToProps = (state: State, ownProps: DriverPageProps) => {
    const { dsprDriverId } = ownProps.match.params as { dsprDriverId: string };
    //const dsprDriver = getDSPRDriverWithUserAndOrdersAndServiceAreasFromProps(state, { dsprDriverId: dsprDriverId });
    const dsprDriver = getDSPRDriverWithUserAndOrdersAndServiceAreasAndCurrentRouteFromProps(state, {dsprDriverId: dsprDriverId});
    const dspr = dsprDriver ? getDSPRFromProps(state, { dsprId: dsprDriver.dspr }) : undefined;
    const dsprDrivers = dsprDriver ? getDriversForDSPR(state, { dsprId: dsprDriver.dspr }) : undefined;
    const dsp = dspr ? getDSPFromProps(state, { dspId: dspr.deliveryServiceProvider }) : undefined;
    const orders = getOrdersWithAddresses(state);
    const availableInventoryAllProducts = dspr ? getDSPRCurrentInventoryForAutoSelectForDriverItems(state, { dsprId: dsprDriver.dspr }) : [];
    const availableInventoryInStockOnly = dspr ? getInStockDSPRCurrentInventoryForAutoSelectForDriverItems(state, { dsprId: dsprDriver.dspr}) : [];
    const driverPageHasUnfinishedCall = getDSPRDriverPageHasUnfinishedAPICall(state)
    return {
        loggedInUser: getLoggedInUser(state),
        dspr,
        dsp,
        dsprDriver,
        dsprDrivers,
        orders,
        currentInventoryPeriod: getCurrentDriverInventoryPeriodForDriverFromProps(state, { dsprDriverId: dsprDriverId }),
        availableInventoryAllProducts,
        availableInventoryInStockOnly,
        driverPageHasUnfinishedCall,
        managedDSPRs: getManagedDSPRsForLoggedInUser(state),
        getProductById: productId => getDSPProductFromProps(state, { productId }),
    };
};

const mapDispatchToProps = {
    setDriverLocation,
    setDriverOnCallState,
    toggleDSPRDriverActiveStatus,
    getDSP,
    getDSPRDriver,
    createInventoryPeriod,
    interceptAutocompleteSelected,
    getDSPRCurrentInventory,
    getInventoryPeriod,
    removeInventoryItem,
    addInventoryItem,
    getSpecificUser,
    completeOrder,
    cancelOrder,
    markOrderInProcess,
    transferInventoryPeriod,
    setUpdateDriverInformation,
    modifyOrder,
    getOrderCost,
    getProduct,
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    watchPosition: true
})(connect(mapStateToProps, mapDispatchToProps)(DriverPage));