import React, { Fragment, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import { history } from '../index'
import { getLoggedInUser } from '../selectors/userSelectors';
import { DSPR, DsprDriver, State, User, DeliveryServiceProvider } from '../store/reduxStoreState';
import DSPRPage from '../containers/DSPRPage';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbars/Navbar';
import AnalyticsManager from "./AnalyticsManager";
import { navLinks, getDSPsWithStructureForHeaderMenuLinks, navDSPR, navDSP } from '../selectors/headerSelectors';
import { getAllDSPRsForDSP } from "../actions/dsprActions";
import DeliveryServiceProviderComponent from '../containers/DeliveryServiceProvider';
import DSPProductManagement from "../containers/DSPProductManagement";
import { Assessment, Dashboard, Domain, Explore } from "@material-ui/icons";
import { getDSPRs, getDSPRDrivers } from "../selectors/dsprSelectors";
import { getManagedDSPsForLoggedInUser } from "../selectors/dspManagerSelectors";
import SidebarUtil from '../util/SidebarUtil';
import { getManagedDSPRsForLoggedInUser } from "../selectors/dsprManagerSelectors";

/** Renders DSP Sidebar and establishes DSP routes */
function DSPManager() {
    const {dspId} = useParams<{ dspId: string }>();
    const [navOpen, setNavOpen] = useState(false);

    const loggedInUser = useSelector<State, User>(getLoggedInUser, shallowEqual);
    const dsprDrivers = useSelector<State, {[key: number]: DsprDriver}>(getDSPRDrivers, shallowEqual);
    const dsprs = useSelector<State, { [key: string]: DSPR }>(getDSPRs, shallowEqual);
    const navSelector = useSelector<State, navLinks>(getDSPsWithStructureForHeaderMenuLinks, shallowEqual);
    const userManagedDSPs = useSelector<State, DeliveryServiceProvider[]>(getManagedDSPsForLoggedInUser, shallowEqual);
    const userManagedDSPRs = useSelector<State, DSPR[]>(getManagedDSPRsForLoggedInUser, shallowEqual);

    const isDriver = !!(loggedInUser && loggedInUser.dsprDrivers.length)
    const isAdmin = loggedInUser && loggedInUser.systemAdministrator;
    const isDspManager = !!(loggedInUser && loggedInUser.deliveryServiceProviderManagers.length);
    const driverIds = loggedInUser && loggedInUser.dsprDrivers.length > 0 && loggedInUser.dsprDrivers;
    const userManagedActiveDSPs = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active === true) : [];
    const userManagedActiveDSPRs: DSPR[] = userManagedDSPRs ? userManagedDSPRs.filter(dspr => dspr.active): [];
    const isActiveDSPRManager = !!(userManagedActiveDSPRs.length);
    const isManagerForCurrentDSP: boolean = isDspManager && userManagedActiveDSPs && userManagedActiveDSPs.filter(dsp => dsp.id === parseInt(dspId)).length === 1;

    const routes = [];

    useEffect(() => {
        if (!loggedInUser) history.push('/login');
    }, [loggedInUser]);

    //if user is neither a super user nor a manager for the current dsp,
    //the user is not authorized to view page and will be routed to the unauthorized page
    if (!isAdmin && !isManagerForCurrentDSP) history.push('/unauthorized');


    useEffect(() => {
        getAllDSPRsForDSP(dspId);
    }, [dspId]);

    if (dspId && (isAdmin || isDspManager)) {
        routes.push(
            {
                path: `dsp/${dspId}/overview/`,
                name: `Overview`,
                icon: Dashboard,
                component: DeliveryServiceProviderComponent,
                layout: '/'
            }
        );
    }

    if (dspId && (isAdmin || isDspManager)) {
        routes.push(
            {
                path: `dsp/${dspId}/analytics/`,
                name: 'Analytics',
                icon: Assessment,
                component: AnalyticsManager,
                layout: '/'
            },
            {
                path: `dsp/${dspId}/products/`,
                name: 'Products',
                icon: Domain,
                component: DSPProductManagement,
                layout: '/'
            }
        )
    }

    /**Creates a collapsable menu of DSPRs the user manages. Only appears if user manages 1 DSP and at least 1 DSPR*/
    if (loggedInUser && userManagedActiveDSPs.length === 1 && isActiveDSPRManager && navSelector) {
        Object.values(navSelector).forEach((dsp: navDSP) => {
            if (typeof dsp === 'object' && (dsp.id === parseInt(dspId)) && dsp.dsprs) {
                const dropdown = {
                    collapse: true,
                    name: "DSPRs",
                    icon: Explore,
                    initialOpenState: true,
                    state: "dspr-container",
                    views: Object.values(dsp.dsprs).map((dspr: navDSPR) => {
                        if (dspr.isManager) {
                            return ({
                                path: `/${dspr.id}/dashboard/`,
                                name: `${dspr.name}`,
                                mini: `${dspr.id}`,
                                component: DSPRPage,
                                layout: '/dspr'
                            })
                        }
                        return undefined;
                    })
                }
                routes.push(dropdown)
            }
        })
    }

    /**Driver profiles (added if user is a driver) - Collapsable menu item*/
    if (loggedInUser && driverIds.length > 0) {
        routes.push(SidebarUtil.createDriverProfiles(null, driverIds, dsprDrivers, dsprs));
    }

    /**Manager Profiles Sidebar entry*/
    if (loggedInUser && navSelector && ((userManagedActiveDSPs.length + userManagedActiveDSPRs.length) > 1)) {
        routes.push(SidebarUtil.createManagerProfiles((navSelector)));
    }

    const handleNavLinkClick = () => {
        setNavOpen(false);
    }

    const handleDrawerToggle = () => {
        setNavOpen(!navOpen);
    }

    return (
        <Fragment>
            <Navbar
                brandText=""
                handleDrawerToggle={() => handleDrawerToggle()}
            />
            <Sidebar
                open={navOpen}
                logo="/assets/images/grasspLogo.svg"
                logoText={'Grassp Logistics'}
                routes={routes}
                handleDrawerToggle={handleNavLinkClick}
                color='blue'
                bgColor='black'
                isDriver={isDriver}
            >
            </Sidebar>
            <Switch>
                <Route path={'/dsp/:dspId/analytics/'} component={AnalyticsManager}/>
                <Route path={'/dsp/:dspId/overview/'} component={DeliveryServiceProviderComponent}/>
                <Route path={'/dsp/:dspId/products/'} component={DSPProductManagement}/>
                <Route path={'/dspr/:dsprId/dashboard/'} component={DSPRPage}/>
                <Redirect to={(isDriver && !isAdmin && !isDspManager) ? `/dspr/${dspId}/drivers/` : `/dsp/${dspId}/overview/`}/>
            </Switch>
        </Fragment>
    )
}

export default DSPManager;