import React, { useState } from "react";
import {
    Dialog,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CircularProgress,
    DialogTitle,
} from '@material-ui/core';
import { Add as AddIcon, Settings as SettingsIcon } from '@material-ui/icons';
import { DSPR, State } from '../store/reduxStoreState';

import { history } from '../index';

import Info from './Typography/Info'
import DSPRForm from '../containers/DSPRForm';
import './dsprsStyles.scss';
import { shallowEqual, useSelector } from "react-redux";
import { getActiveDSPRManagersForLoggedInUser } from "../selectors/dsprManagerSelectors";

interface DSPRsProps {
    dspId: number;
    dsprs: { id: number, name: string, deliveryServiceProvider: number }[];
    users: { value: number, text: string }[];
    isDSPManager: boolean;
    createDSPR: (name: string, uesrId: number, dspId: number, minimumOrderSize: number, numberOrdersPerRoute: number, centralLatitude: any, centralLongitude: any) => any;
    handleAutoCompleteSelected: (id: number) => any;
    newDSPRCallback?: () => any;
    dsprSettingsCallback?: (dsprId: string, index: string) => any;
    allUsersLoaded?: boolean;
    selectedDSPR?: DSPR;
    selectedDSPRLoaded?: boolean;
    updateDSPR: (name: string, displayName: string, dspId: number, minimumOrderSize: number, numberOrdersPerRoute: number, centralLatitude: any, centralLongitude: any, id: number, imageFile) => any;
}

const DSPRs: React.FC<DSPRsProps> = props => {
    const {
        dspId,
        dsprs,
        users,
        isDSPManager,
        createDSPR,
        handleAutoCompleteSelected,
        newDSPRCallback,
        allUsersLoaded,
        dsprSettingsCallback,
        selectedDSPR,
        selectedDSPRLoaded,
        updateDSPR
    } = props;
    const [isNewDSPRFormOpen, setIsNewDSPRFormOpen] = useState(false);
    const [isEditDSPRFormOpen, setIsEditDSPRFormOpen] = useState(false);
    const managedDSPRs = useSelector<State, any>(getActiveDSPRManagersForLoggedInUser, shallowEqual);
    const managedDSPRIds = managedDSPRs && managedDSPRs.length > 0 ? managedDSPRs.map(managedDSPR => managedDSPR.dspr) : [];

    const handleNewDSPRFormOpen = () => {
        if (newDSPRCallback) newDSPRCallback();
        setIsNewDSPRFormOpen(true);
    };

    const handleEditSubmit = (dsprId: number) => (updatedDSPRValues) => {
        updateDSPR(
            updatedDSPRValues.dsprName,
            updatedDSPRValues.displayName,
            dspId,
            updatedDSPRValues.minimumOrderSize,
            updatedDSPRValues.numberOrdersPerRoute,
            updatedDSPRValues.centralLatitude,
            updatedDSPRValues.centralLongitude,
            dsprId,
            typeof updatedDSPRValues.imageFile === "string" ? null : updatedDSPRValues.imageFile ? updatedDSPRValues.imageFile[0] : null,
        );
        setIsEditDSPRFormOpen(false);
    }

    const handleEditDSPRFormOpen = () => {
        setIsEditDSPRFormOpen(true);
    };

    const handleDSPREdit = (dsprId: string, index) => {
        dsprSettingsCallback(dsprId, index)
        handleEditDSPRFormOpen()
    }

    const handleSubmit = (newDSPRManagerValues) => {
        createDSPR(
            newDSPRManagerValues.dsprName,
            newDSPRManagerValues.userId,
            dspId,
            newDSPRManagerValues.minimumOrderSize,
            newDSPRManagerValues.numberOrdersPerRoute,
            newDSPRManagerValues.centralLatitude,
            newDSPRManagerValues.centralLongitude,
        );
        // Submitting with an image as the last field
        // const file = newDSPRManagerValues.imageFile ? newDSPRManagerValues.imageFile[0] : null;
        // createDSPR(newDSPRManagerValues.dsprName,
        //     newDSPRManagerValues.userId,
        //     dspId,
        //     newDSPRManagerValues.minimumOrderSize,
        //     newDSPRManagerValues.centralLatitude,
        //     newDSPRManagerValues.centralLongitude,
        //     file,
        // );
        setIsNewDSPRFormOpen(false);
    };

    const dsprsCards = dsprs ?
        Object.keys(dsprs).map(dsprId => {
            const isManagedByUser = managedDSPRIds.includes(dsprs[dsprId].id);
            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`${dsprId}-${dsprs[dsprId].name}`}>
                    <Card className="dspr-card">
                        <CardActionArea className="dspr-name"
                                        onClick={() => history.push(`/dspr/${dsprs[dsprId].id}`)}
                                        disabled={!isManagedByUser}>
                            {isManagedByUser
                                ? <h4>{dsprs[dsprId].name}</h4>
                                : <div className={'dspr-not-managed-by-user'}>
                                    <h4>{dsprs[dsprId].name}</h4>
                                    <p>You do not manage this DSPR</p>
                                </div>
                            }

                        </CardActionArea>
                        <CardActionArea className="dspr-settings"
                                        onClick={() => handleDSPREdit(dsprs[dsprId].id, dsprId)}>
                            <SettingsIcon/>
                        </CardActionArea>
                    </Card>
                </Grid>
            )
        })
        : <CircularProgress/>;

    const dsprFormInitialValues = selectedDSPR ? {
        ...selectedDSPR,
        dsprName: selectedDSPR.name,
        imageFile: selectedDSPR.imageLocation
    } : {
        minimumOrderSize: 0,
        centralLatitude: 34.019454,
        centralLongitude: -118.491191
    };

    return (
        <div className="dsprs-container">
            <Grid container spacing={3}>
                {dsprsCards}

                {isDSPManager && <Grid item xs={12} sm={6} md={4} lg={3} key="new-dspr">
                    <Card>
                        <CardActionArea onClick={handleNewDSPRFormOpen}>
                            <CardContent className="dspr-add">
                                <Info><AddIcon/></Info>
                                <Info><h4>New DSPR</h4></Info>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>}
            </Grid>

            {/*Dialog only visible if user is a Manager for the current DSP*/}
            <Dialog
                fullScreen={false}
                open={isNewDSPRFormOpen}
                onClose={() => setIsNewDSPRFormOpen(false)}
            >
                <DialogTitle>Add A New DSPR</DialogTitle>
                <DSPRForm
                    users={users}
                    onSubmit={handleSubmit}
                    onCancel={() => setIsNewDSPRFormOpen(false)}
                    handleAutoCompleteSelected={handleAutoCompleteSelected}
                    usersLoaded={allUsersLoaded}
                />
            </Dialog>
            <Dialog
                fullScreen={false}
                open={!!(isEditDSPRFormOpen && selectedDSPRLoaded)}
                onClose={() => setIsEditDSPRFormOpen(false)}
            >
                <DialogTitle>Edit {selectedDSPR ? selectedDSPR.name : 'DSPR'}</DialogTitle>
                <DSPRForm
                    users={users}
                    editMode
                    onSubmit={handleEditSubmit(selectedDSPR ? selectedDSPR.id : undefined)}
                    onCancel={() => setIsEditDSPRFormOpen(false)}
                    handleAutoCompleteSelected={handleAutoCompleteSelected}
                    usersLoaded={allUsersLoaded}
                    dspr={selectedDSPR}
                    initialValues={dsprFormInitialValues}
                />
            </Dialog>
        </div>
    );
}

export default DSPRs;