import React, { useEffect, useState, createRef, Fragment } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link, NavLink, useParams } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { ExitToApp, ArrowBack } from '@material-ui/icons';

import sidebarStyle from "../assets/jss/material-dashboard-pro-react/components/sidebarStyle";

import { logout, getAppAccessToken } from "../actions/oauthActions";
import { getDSPFromProps } from '../selectors/dspSelectors'
import { history } from "../index";
import { getDSPRs } from "../selectors/dsprSelectors";
import { DeliveryServiceProvider, DSPR, DsprManager, State, User } from "../store/reduxStoreState";

import './sidebarStyles.scss';
import { getLoggedInUser } from "../selectors/userSelectors";
import { getManagedDSPIdsForLoggedInUser, getManagedDSPsForLoggedInUser } from "../selectors/dspManagerSelectors";
import { getActiveDSPRManagersForLoggedInUser } from "../selectors/dsprManagerSelectors";

interface SidebarWrapperProps {
    className: string,
    dspAndDspr: JSX.Element;
    links: JSX.Element;
    logout: JSX.Element;
}

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
const SidebarWrapper: React.FC<SidebarWrapperProps> = React.memo(props => {
    const {className, dspAndDspr, links, logout} = props;
    const sidebarWrapper = createRef<HTMLDivElement>();

    useEffect(() => {
        let ps: PerfectScrollbar;
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebarWrapper.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) ps.destroy();
        }
    }, [sidebarWrapper]);

    return <div className={`${className} sidebar-wrapper`} ref={sidebarWrapper}>
        {dspAndDspr}
        {links}
        {logout}
    </div>
})

export interface SidebarRoute {
    path: string;
    name: string;
    mini?: string;
    icon?: React.ComponentType<any>;
    component?: React.ReactNode;
    layout: string;
    isHeading?: boolean;
    activeRouteOverrideClassName?: string;
    includeSeparator?: boolean;
    activeCollapseOverrideClassName?: string;
    generalRouteMatch?: string;
}

interface SidebarProps {
    bgColor: 'white' | 'black' | 'blue';
    color: 'white' | 'red' | 'orange' | 'green' | 'blue' | 'purple' | 'rose';
    logo: string;
    logoText: string;
    images?: string;
    routes: (Partial<SidebarRoute> & { collapse?: boolean, state?: string, views?: SidebarRoute[] })[];
    open?: boolean;
    handleDrawerToggle?: () => void;
    image?: any;
    isDriver: boolean;
    classes: any;
    isAnalytics?: boolean;
}

const Sidebar: React.FC<SidebarProps> = props => {
    const {classes, logo, logoText, routes, bgColor, color, isDriver, isAnalytics} = props;
    const {dsprId, dspId, dsprDriverId} = useParams<{ dsprId?: string, dspId?: string, dsprDriverId?: string }>();
    const dispatch = useDispatch();

    const dsprs = useSelector<State, { [key: string]: DSPR }>(getDSPRs, shallowEqual);
    const dspr = dsprId ? dsprs[dsprId] : null;
    const dsp = useSelector<State, DeliveryServiceProvider>(state => {
        if (dspr) {
            return getDSPFromProps(state, {dspId: dspr.deliveryServiceProvider});
        } else if (dspId) {
            return getDSPFromProps(state, {dspId: parseInt(dspId)});
        }
    }, shallowEqual);
    const loggedInUser = useSelector<State, User>(getLoggedInUser, shallowEqual);
    const managedDSPIds = useSelector<State, number[]>(getManagedDSPIdsForLoggedInUser, shallowEqual);
    const managedDSPRs = useSelector<State, DsprManager[]>(getActiveDSPRManagersForLoggedInUser, shallowEqual);
    const userManagedDSPs = useSelector<State, DeliveryServiceProvider[]>(getManagedDSPsForLoggedInUser, shallowEqual);

    const isAdmin = loggedInUser && loggedInUser.systemAdministrator;
    const isDSPRManager = !!(loggedInUser && loggedInUser.dsprManagers && loggedInUser.dsprManagers.length);
    const isDSPManager = !!(loggedInUser && loggedInUser.deliveryServiceProviderManagers.length);
    const isManagerForCurrentDSPR = isDSPRManager && managedDSPRs && managedDSPRs.filter(dsprManager => dsprManager.dspr === parseInt(dsprId)).length
    const isManagerForCurrentDSP = managedDSPIds.includes((dspId && parseInt(dspId)) || (dspr && dspr.deliveryServiceProvider));
    const isCurrentDriver = (loggedInUser && dsprDriverId && loggedInUser.dsprDrivers.includes(parseInt(dsprDriverId)));
    const userManagedActiveDSPs = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active === true) : [];

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    const getCollapseInitialState = (routes: (Partial<SidebarRoute> & { collapse?: boolean, state?: string, views?: SidebarRoute[], initialOpenState?: boolean })[]) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through props.routes
    const getCollapseStates = (routes: (Partial<SidebarRoute> & { collapse?: boolean, state?: string, views?: SidebarRoute[], initialOpenState?: boolean })[]) => {
        let initialState = {};
        routes.forEach(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };

                if (prop.initialOpenState) initialState[prop.state] = prop.initialOpenState;
            }
        });
        return initialState;
    };

    const mainPanel = createRef<HTMLDivElement>();
    const [collapseStates, setCollapseStates] = useState(getCollapseStates(props.routes));

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string, generalRouteMatch?: string) => {
        if (generalRouteMatch) return window.location.href.includes(generalRouteMatch);

        return window.location.href.indexOf(routeName) > -1;
    }

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes: (Partial<SidebarRoute> & { collapse?: boolean, state?: string, views?: SidebarRoute[] })[]) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                const st = {};
                st[prop["state"]] = !collapseStates[prop.state];

                const determineClassNameForCollapseState = (views) => {
                    if (getCollapseInitialState(views) && !prop.activeCollapseOverrideClassName) {
                        return classes.collapseActive;
                    }

                    if (getCollapseInitialState(views) && prop.activeCollapseOverrideClassName) {
                        return prop.activeCollapseOverrideClassName;
                    }

                    return '';
                }

                return (
                    <div className={'collapsable-container'} key={`${key}-a`}>
                        {prop.includeSeparator &&
                        <div className={'quick-nav-container'}></div>
                        }

                        <ListItem key={key} className={prop.icon === undefined ? classes.collapseItem : classes.item}>
                            <NavLink
                                to={(prop.path && prop.layout) ? `${prop.layout}${prop.path}` : '#'}
                                //className={classes.itemLink + " sidebar-link " + (getCollapseInitialState(prop.views) ? classes.collapseActive : '')}
                                className={classes.itemLink + " sidebar-link " + determineClassNameForCollapseState(prop.views)}
                                onClick={e => {
                                    !(prop.path && prop.layout) && e.preventDefault();
                                    setCollapseStates(st);
                                }}
                            >
                                {prop.icon !== undefined ? (
                                    typeof prop.icon === "string" ? (
                                        <Icon className={classes.itemIcon}>{prop.icon}</Icon>
                                    ) : <prop.icon className={classes.itemIcon}/>
                                ) : <span className={classes.collapseItemMini}>{prop.mini}</span>}
                                <ListItemText
                                    primary={prop.name}
                                    secondary={
                                        <b className={classes.caret + " " + (collapseStates[prop.state] ? classes.caretActive : "")}/>}
                                    disableTypography={true}
                                    className={prop.icon === undefined ? classes.collapseItemText : classes.itemText}
                                />
                            </NavLink>
                            <Collapse in={collapseStates[prop.state]} unmountOnExit>
                                <List className={classes.list + " " + classes.collapseList}>
                                    {createLinks(prop.views)}
                                </List>
                            </Collapse>
                        </ListItem>
                    </div>
                );
            }

            // if route is active and override class not provided, use classes[color]. If override class provided, use that class
            const innerNavLinkClasses =
                classes.collapseItemLink + " " + (activeRoute(prop.path, prop.generalRouteMatch) && !prop.activeRouteOverrideClassName ? classes[color] : '')
                    + ' ' + (activeRoute(prop.path, prop.generalRouteMatch) && prop.activeRouteOverrideClassName ? prop.activeRouteOverrideClassName : '');
            const navLinkClasses = classes.itemLink + " " + (activeRoute(prop.path, prop.generalRouteMatch) ? classes[color] : '');

            if (prop.isHeading) {
                return (
                    <ListItem
                        key={key}
                        className={`header`}
                    >
                        <span className={classes.collapseItemMini}>{prop.mini}</span>
                        <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            //className={prop.icon === undefined ? classes.collapseItemText : classes.itemText}
                        />
                    </ListItem>
                )
            }

            return (
                <ListItem
                    key={key}
                    className={prop.icon === undefined ? classes.collapseItem : classes.item}
                >
                    <NavLink
                        to={prop.layout + prop.path}
                        className={`${prop.icon === undefined ? innerNavLinkClasses : navLinkClasses} sidebar-link`}
                        onClick={() => props.handleDrawerToggle()}
                    >
                        {prop.icon !== undefined ? (
                            typeof prop.icon === "string" ? (
                                <Icon className={classes.itemIcon}>{prop.icon}</Icon>
                            ) : <prop.icon className={classes.itemIcon}/>
                        ) : <span className={classes.collapseItemMini}>{prop.mini}</span>}
                        <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={prop.icon === undefined ? classes.collapseItemText : classes.itemText}
                        />
                    </NavLink>
                </ListItem>
            );
        });
    };

    const brand = (
        <div className={`${classes.logo}${bgColor === 'white' ? ` ${classes.whiteAfter}` : ''}`}>
            <Link to="/" className={classes.logoMini}>
                <img src={logo} alt="logo" className={classes.img}/>
            </Link>
            <Link to="/" className={classes.logoNormal}>
                {logoText}
            </Link>
        </div>
    );

    const logoutBtn = (<Link
        to={"#"}
        className={`${classes.itemLink} logout-button`}
        onClick={e => {
            e.preventDefault();
            dispatch(logout());
            dispatch(getAppAccessToken());
            history.push('/login');
        }}
    >
        <Icon><ExitToApp/></Icon>
        <span>Logout</span>
    </Link>)

    /**Renders Back Button on Sidebar for various circumstances */
    const renderDSPAndDSPRBackButtons = () => {

        if (isAdmin && dsp && !isAnalytics && !dspr) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dsp.name}</p>
                    <Link to={`/dspManagement/`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to DSP Management</span>
                    </Link>
                </ListItem>
            )
        }

        if (isAdmin && dsprId && dspr && dsp && !isAnalytics) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dspr.name}</p>
                    <Link to={`/dsp/${dsp.id}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dsp.name}</span>
                    </Link>
                </ListItem>
            )
        }

        /**Analytics Redirects*/
        if (isAdmin && dsp && isAnalytics && !dspr) {
            return (
                <ListItem className="return-to-dsp">
                    <p>DSP Analytics</p>
                    {isDriver && (!isAdmin || !isManagerForCurrentDSP) ? <Link to="#">
                        {dsp.name}
                    </Link> : <Link to={`/dsp/${dsp.id}/overview/`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dsp.name}</span>
                    </Link>}
                </ListItem>
            )
        }

        if (isAdmin && dsprId && dspr && dsp && isAnalytics) {
            return (
                <ListItem className="return-to-dsp">
                    <p>DSPR Analytics</p>
                    <Link to={`/dspr/${dsprId}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dspr.name}</span>
                    </Link>
                </ListItem>
            )
        }

        /*Driver Page Redirects */
        if (dsprDriverId && (!isAdmin && !isManagerForCurrentDSPR && !isManagerForCurrentDSP && !isDSPManager && !isDSPRManager)) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dspr.name}</p>
                    <Link to="#"> {dsp.name}</Link>
                </ListItem>
            )
        }

        /*If a DSPR Manager is also a driver, return to the DSPR*/
        if (dsprDriverId && (isAdmin || isManagerForCurrentDSPR) && dspr && dsprId) {
            return (
                <ListItem className={'return-to-dsp'}>
                    <p>{dspr.name}</p>
                    <Link to={`/dspr/${dsprId}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dspr.name}</span>
                    </Link>
                </ListItem>
            )
        }

        /*If DSP Manager is a driver for a dspr under the dsp the driver manages, return to the DSP*/
        if (dsprDriverId && (isAdmin || isManagerForCurrentDSP) && dsp && dspr) {
            return (
                <ListItem className={'return-to-dsp'}>
                    <p>{dspr.name}</p>
                    <Link to={`/dsp/${dsp.id}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dsp.name}</span>
                    </Link>
                </ListItem>
            )
        }

        /*Return to DSP Management if DSP Manager is a driver, but drives for a DSPR that does not fall under the DSP they manage
        * If the DSP Manager only manages 1 dsp, return to that dsp
        * */
        if (dsprDriverId && (isAdmin || isDSPManager) && !isManagerForCurrentDSP && dsp && dspr) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dsp.name}</p>
                    <Link to={`/dspManagement/`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to DSP Management</span>
                    </Link>
                </ListItem>
            )
        }

        /*If user is a dspr manager and a driver for a different dspr, provide a back button to the dspr the user manages*/
        if (dsprDriverId && isDSPRManager && !isManagerForCurrentDSPR && isCurrentDriver && dspr) {
            const managedDSPRId = managedDSPRs[0].dspr
            const managedDSPRName = dsprs[managedDSPRId].name
            return (
                <ListItem className="return-to-dsp">
                    <p>{dspr.name}</p>
                    <Link to={`/dspr/${managedDSPRId}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {managedDSPRName}</span>
                    </Link>
                </ListItem>
            )
        }


        /*Return to DSP Management page if Admin or Manager for Current DSP (displays when on a dsp overview page)*/
        if (!isAnalytics && (isAdmin || isManagerForCurrentDSP) && dspId && dsp) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dsp.name}</p>
                    {userManagedActiveDSPs.length > 1
                        ? <Link to={`/dspManagement/`}>
                            <Icon><ArrowBack/></Icon>
                            <span>Return to DSP Management</span>
                        </Link>
                        : null
                    }
                </ListItem>
            )
        }

        /*Return to DSP page if user is DSP Manager and on DSPR page. If on DSPR page, display DSPR Name*/
        if (!isAnalytics && dsprId && dsp && dspr) {
            return (
                <ListItem className="return-to-dsp">
                    <p>{dspr.name}</p>
                    {(!isAdmin || !isManagerForCurrentDSP) ? <Link to="#">
                        {dsp.name}
                    </Link> : <Link to={`/dsp/${dsp.id}/overview/`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dsp.name}</span>
                    </Link>}
                </ListItem>
            )

        }

        /*Analytics Redirects*/
        /*If user is on a dsp analytics page and is a DSP Manager, return to DSP Overview*/
        if (isAnalytics && dspId && dsp) {
            return (
                <ListItem className="return-to-dsp">
                    <p>DSP Analytics</p>
                    {isDriver && (!isAdmin || !isManagerForCurrentDSP) ? <Link to="#">
                        {dsp.name}
                    </Link> : <Link to={`/dsp/${dsp.id}/overview/`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dsp.name}</span>
                    </Link>}
                </ListItem>
            )
        }

        /*If user is on a dspr analytics page and is a DSPR manager, return to DSPR Overview*/
        if (isAnalytics && dsp && dspr && (isAdmin || isManagerForCurrentDSPR)) {
            return (
                <ListItem className="return-to-dsp">
                    <p>DSPR Analytics</p>
                    <Link to={`/dspr/${dsprId}`}>
                        <Icon><ArrowBack/></Icon>
                        <span>Return to {dspr.name}</span>
                    </Link>
                </ListItem>
            )
        }
    }

    const drawerContent = (<Fragment>
        {brand}
        <SidebarWrapper
            className={classes.sidebarWrapper}
            dspAndDspr={renderDSPAndDSPRBackButtons()}
            links={<List className={`${classes.list} routes`}>{createLinks(routes)}</List>}
            logout={logoutBtn}
        />
    </Fragment>)

    return (
        <div ref={mainPanel}>
            <Hidden mdUp implementation="css">
                <Drawer
                    className="sidebar-container"
                    variant="temporary"
                    anchor="right"
                    open={props.open}
                    classes={{paper: classes.drawerPaper + " " + classes[bgColor + "Background"]}}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{keepMounted: true}} // Better open performance on mobile
                >
                    {drawerContent}
                    {/* {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null} */}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    className="sidebar-container"
                    anchor="left"
                    variant="permanent"
                    open
                    classes={{paper: classes.drawerPaper + " " + classes[bgColor + "Background"]}}
                >
                    {drawerContent}
                    {/* {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null} */}
                </Drawer>
            </Hidden>
        </div>
    );
}

export default withStyles(sidebarStyle as any)(Sidebar);