import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ClearIcon from '@material-ui/icons/Clear';
import CheckboxToggleForm from './CheckboxToggleForm';

import { Brand } from '../store/reduxStoreState';
import { AutoCompleteProduct, ProductsForBrands, ProductsForBrandsAssignment } from '../selectors/dspProductSelector';
import AddProductToBrandForm from '../containers/AddDSPProductToBrandForm';
import SearchOrCreateForm from '../components/SearchOrCreateForm';
import {
    addProductToBrand,
    removeProductFromBrand,
    ADD_PRODUCT_TO_BRAND_SUCCESS,
    REMOVE_PRODUCT_FROM_BRAND_SUCCESS, ADD_PRODUCT_TO_BRAND_FAILURE
} from '../actions/brandActions'
import { interceptAutocompleteSelected } from '../actions/formHelperActions';

import './DSPBrandsTableStyles.scss';

import VirtualizedTable from './VirtualizedTable';
import { Button } from '@material-ui/core';
import { API_HOST } from '../middleware/api';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    productTableRow: {
        width: '100%',
        textAlign: 'left',
    },
    modifyButtonContainer: {
        marginRight: "1.5rem",
        marginLeft: "auto",
        display:"table"
    },
    modifyButton: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingInline: 4,
    },
    statusAndActionsTd: {
        display: "flex"
    }
});

interface RowProps {
    brand: Brand;
    brandProducts: { productId?: number, name?: string, isActive?: boolean }[];
    productsToAddToBrand: AutoCompleteProduct[];
    handleBrandClick: (evt: any) => any;
    filterProductsBySearchTerm: (searchTerm: string, brandId: number) => any;
    handleModifyBrand:(brand:Brand) => any;
}

/**Creates an expandable row for the brands table
 *  expanding the row displays the products associated with the brand */
function Row(props: RowProps) {
    const {name: brandName, products: productIdArray, id: brandId, imageLocation: brandImageLocation, isActive} = props.brand;
    const {brandProducts, handleBrandClick, productsToAddToBrand, filterProductsBySearchTerm, handleModifyBrand} = props

    const [displayInactiveProducts, setDisplayInactiveProducts] = useState(false);
    const [brandProductsToDisplay, setBrandProductsToDisplay] = useState(brandProducts.filter(product => product.isActive));
    const [showAddProductForm, setShowAddProductForm] = useState(true);
    const [showProductAssignmentConfirmation, setShowProductAssignmentConfirmation] = useState(false);
    const [assignedProductName, setAssignedProductName] = useState('');
    const [showProductRemovalConfirmation, setShowProductRemovalConfirmation] = useState(false);
    const [removedProductName, setRemovedProductName] = useState('');
    const [rowOpen, setRowOpen] = useState(false);
    const [clearProductSearch, setClearProductSearch] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [errorAlertTitle, setErrorAlertTitle] = useState<string>('Error encountered!');
    const [errorAlertText, setErrorAlertText] = useState<string>('The action could not be completed');
    const classes = useRowStyles();
    const dispatch = useDispatch();


    /**Update brandProductsToDisplay state whenever props.brandProducts changes */
    useEffect(() => {
        if (displayInactiveProducts) {
            setBrandProductsToDisplay(brandProducts);
        } else {
            setBrandProductsToDisplay(brandProducts.filter(product => product.isActive === true));
        }
    }, [brandProducts, displayInactiveProducts]);

    /**Allow product search form to save after being cleared*/
    useEffect(() => {
        if (clearProductSearch) {
            setClearProductSearch(false);
        }
    }, [clearProductSearch]);

    /**When Inactive Products box is checked, both inactive and active products are displayed.
     * Otherwise, only active products are displayed */
    function handleInactiveProductsToggle(showInactive: boolean) {
        if (showInactive) {
            setBrandProductsToDisplay(brandProducts);
            setDisplayInactiveProducts(true);
        } else {
            setBrandProductsToDisplay(brandProducts.filter(product => product.isActive === true));
            setDisplayInactiveProducts(false);
        }
    }

    /**Fetches products for selected brand if we have not already done so */
    function handleBrandRowClick(brandId) {
        setRowOpen(!rowOpen);

        if (!productIdArray) {
            handleBrandClick(brandId);
        }
    }

    function refreshList(brandId) {
        handleBrandClick(brandId);
    }

    /** Adds product to Brand.*/
    function handleProductSubmit(values: any): any {
        setShowAddProductForm(false);
        dispatch<any>(addProductToBrand(values.productId, brandId))
            .then((response) => {
                // If product is already assigned to a brand, confirm user wishes to reassign, then dispatch product reassignment
                if (response.type === ADD_PRODUCT_TO_BRAND_SUCCESS &&
                    response.response.entities &&
                    response.response.entities.productsWithConfirmationState &&
                    response.response.entities.productsWithConfirmationState[values.productId] &&
                    response.response.entities.productsWithConfirmationState[values.productId].verificationToken) {

                    const confirmation = window.confirm("This product is already assigned to another brand. Do you want to reassign the product?");

                    if (confirmation) {
                        const {productId} = values;
                        const {verificationToken} = response.response.entities.productsWithConfirmationState[productId]
                        dispatch<any>(addProductToBrand(productId, brandId, verificationToken))
                            .then((response) => {
                                if (response.type === ADD_PRODUCT_TO_BRAND_SUCCESS) {
                                    const {name} = response.response.entities.dspProducts[values.productId];
                                    setAssignedProductName(name);
                                    setShowProductAssignmentConfirmation(true);
                                    setShowAddProductForm(true);
                                }
                            });
                    }
                } else {
                    if (response.type === ADD_PRODUCT_TO_BRAND_SUCCESS) {
                        const {name} = response.response.entities.dspProducts[values.productId];
                        setAssignedProductName(name);
                        setShowProductAssignmentConfirmation(true);
                        setShowAddProductForm(true);
                    }

                    if (response.type === ADD_PRODUCT_TO_BRAND_FAILURE) {
                        setShowAddProductForm(true);
                        setShowErrorAlert(true);
                        setErrorAlertTitle('Product Not Added to Brand')
                        setErrorAlertText(response.error && response.error !== 'No message available' ? response.error : 'Product assignment was unsuccessful');
                    }
                }
            })
        // dispatch(reset('AddDSPProductToBrandForm'));
        setClearProductSearch(true);
    }

    /**Remove product from brand*/
    function handleProductDelete(productName, productId, brandId) {
        dispatch<any>(removeProductFromBrand(productId, brandId))
            .then((response) => {
                if (response.type === REMOVE_PRODUCT_FROM_BRAND_SUCCESS) {
                    setRemovedProductName(productName);
                    setShowProductRemovalConfirmation(true);
                }
            });
    }

    /**Updates redux store with form data (currently selected productId || null) */
    function handleAutoCompleteSelectedToAddProduct(id) {
        dispatch<any>(interceptAutocompleteSelected(id));
    }

    /**Generates row for virtualized table */
    const generateProductTableRow = (item, style: React.CSSProperties) => {
        return (
            <div style={style} key={item.id} className="product-row">
                <span onClick={() => window.open(`/product/${item.id}`, '_blank')}
                      style={{textAlign: "left"}} className='product-name'>
                    {item.name}
                </span>
                <IconButton aria-label="remove product"
                            size="small"
                            onClick={() => handleProductDelete(item.name, item.productId, brandId)}
                            className='product-button'>
                    <ClearIcon/>
                </IconButton>
            </div>
        )
    };

    /**Generates rows for virtualized table */
    const productTableRows = ({index, style}: { index: number, style: React.CSSProperties }) => {
        if (brandProductsToDisplay.length === 0) return null;
        return generateProductTableRow(brandProductsToDisplay[index], {...style, cursor: 'pointer'});
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell className={classes.pointer} component="td" scope="row"
                           onClick={() => handleBrandRowClick(brandId)}>
                    <div style={{display: 'flex', alignItems: "center"}}>
                        <img src={brandImageLocation ? API_HOST + brandImageLocation : undefined} style={{marginRight: "1.5rem"}}width={40} height={"auto"}/>
                        <Typography display='inline'>{brandName}</Typography>
                        <Box ml={2} display='inline'>
                            <IconButton aria-label="expand row" size="small">
                                {rowOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Box>
                    </div>
                        
                    
                </TableCell>
                <TableCell component='td' scope='row' className={classes.statusAndActionsTd}>
                    <div className={classes.modifyButtonContainer}>
                        <Button variant="contained" color="secondary" className={classes.modifyButton} style={{marginRight: 12}} onClick={()=>handleModifyBrand(props.brand)}>
                            Modify
                        </Button>
                        <Button variant="contained" color="primary" className={classes.modifyButton} onClick={() => refreshList(brandId)}>Refresh</Button>
                    </div>
                    
                    {isActive ? <Typography align='right'>Active</Typography> :
                        <Typography align='right'>InActive</Typography>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                    <Collapse in={rowOpen} timeout="auto" unmountOnExit>

                        {/* Title and 'Show Inactive Product' Checkbox */}
                        <Box mb={2} p={3} className='product-table-container'>

                            {showErrorAlert &&
                            <div className={'product-table-alert-container'}>
                                <Alert
                                    onClose={() => setShowErrorAlert(false)}
                                    color={'error'}
                                    severity={'error'}
                                    className={'product-table-alert'}
                                >
                                    <AlertTitle> {errorAlertTitle} </AlertTitle>
                                    {errorAlertText}
                                </Alert>
                            </div>
                            }

                            {showProductAssignmentConfirmation &&
                            <Box mb={2}>
                                <Alert onClose={() => setShowProductAssignmentConfirmation(false)}>
                                    The product "{assignedProductName}" has been assigned to {brandName}
                                </Alert>
                            </Box>
                            }

                            {showProductRemovalConfirmation &&
                            <Box mb={2}>
                                <Alert onClose={() => setShowProductRemovalConfirmation(false)} severity='info'>
                                    "{removedProductName}" has been removed from {brandName}
                                </Alert>
                            </Box>
                            }

                            {showAddProductForm &&
                            <Box mb={4} display='flex' justifyContent='space-between' alignItems='flex-end'>
                                <Typography variant='subtitle1'>Add Product</Typography>
                                <Box ml={2}>
                                    <AddProductToBrandForm
                                        productsToAddToBrand={productsToAddToBrand}
                                        handleAutoCompleteSelected={handleAutoCompleteSelectedToAddProduct}
                                        onSubmit={handleProductSubmit}
                                    />
                                </Box>
                            </Box>
                            }

                            {/* Sub-table of Product Information for each brand - clicking on row takes you to product page */}
                            {/* (Table only displays if a product is assigned to the brand) */}
                            {brandProducts && productIdArray && productIdArray.length > 0 &&
                            <React.Fragment>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography variant='subtitle1'>
                                        Current Products ({productIdArray ? productIdArray.length : '0'})
                                    </Typography>

                                    <Box my={2} display='flex' alignItems='flex-end'>
                                        <SearchOrCreateForm
                                            filterBySearchTerm={filterProductsBySearchTerm}
                                            placeholderText='Search Products'
                                            showCreateButton={false}
                                            filterRequiresId={true}
                                            id={brandId}
                                            clearSearch={clearProductSearch}
                                        />
                                        <Box ml={2} p={0}>
                                            <CheckboxToggleForm
                                                handleSubmit={handleInactiveProductsToggle}
                                                formControlLabel='Show Inactive'
                                                size='small'
                                                labelFontSize='.875rem'
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                {/* Virtualized Product Sub-table */}
                                <VirtualizedTable
                                    itemCount={brandProductsToDisplay.length}
                                    renderItems={productTableRows}
                                    header={['']}
                                    // tableContentClasses='product-row'
                                />
                            </React.Fragment>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


interface DSPBrandsTableProps {
    brands: Brand[];
    productsForBrands: ProductsForBrands;
    dspName: string;
    productsForBrandsAssignment: ProductsForBrandsAssignment;
    handleBrandClick: (evt: any) => any;
    filterProductsBySearchTerm: (searchTerm: string, brandId: number) => any;
    createNewActiveBrand: (value) => any;
    showCreateBrandButton: boolean;
    handleModifyBrand: (brand:Brand) => any;
}

function DSPBrandsTable(props: DSPBrandsTableProps) {
    const {
        brands, productsForBrands, handleBrandClick, productsForBrandsAssignment,
        filterProductsBySearchTerm, createNewActiveBrand, handleModifyBrand,
        showCreateBrandButton, dspName
    } = props;

    const [displayInactiveBrands, setDisplayInactiveBrands] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    /**When Inactive Brands box is checked, both inactive and active brands are displayed.
     * Otherwise, only active brands are displayed */
    function handleInactiveBrandsToggle(showInactive: boolean) {
        if (showInactive) {
            // setBrandsToDisplay(brands);
            setDisplayInactiveBrands(true);
        } else {
            // setBrandsToDisplay(brands.filter(brand => brand.isActive === true));
            setDisplayInactiveBrands(false);
        }
    }

    /** handleChange for brand search bar */
    function updateBrandSearchTerm(term: string) {
        setSearchTerm(term);
    }

    /** Upon submission, new brand is created and searchTerm state is reset*/
    function handleSubmitNewBrand(value) {
        createNewActiveBrand(value);
        setSearchTerm('');
    }

    /** Renders brands to display based upon user determined filters */
    function renderBrandRows() {
        const brandsToDisplay = displayInactiveBrands
            ? brands.filter(brand => brand.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : brands.filter(brand => brand.name.toLowerCase().includes(searchTerm.toLowerCase()) && brand.isActive === true);

        return brandsToDisplay.map((brand) => (
            <Row
                key={brand.id}
                brand={brand}
                handleBrandClick={handleBrandClick}
                brandProducts={productsForBrands ? productsForBrands[brand.id] : []}
                productsToAddToBrand={productsForBrandsAssignment[brand.id]}
                filterProductsBySearchTerm={filterProductsBySearchTerm}
                handleModifyBrand={handleModifyBrand}
            />
        ))
    }

    return (
        <div className='BrandsTable'>
            <Box mt={3} mb={2} display='flex' justifyContent='space-between'>
                <Typography variant='h4' component='h1'> {dspName} Brands </ Typography>
                {showCreateBrandButton &&
                <SearchOrCreateForm
                    filterBySearchTerm={updateBrandSearchTerm}
                    createNew={handleSubmitNewBrand}
                    placeholderText='Search Brands'
                    disableOverride={true}
                    buttonText='Create Brand'
                />
                }
            </Box>
            <Box mb={2} display='flex' justifyContent='flex-end'>
                <CheckboxToggleForm
                    handleSubmit={handleInactiveBrandsToggle}
                    formControlLabel='Show Inactive'
                    size='small'
                    labelFontSize='.875rem'
                />
            </Box>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography color='textSecondary' variant='body2'>BRAND NAME</Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography color='textSecondary' variant='body2'>STATUS</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderBrandRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default DSPBrandsTable;