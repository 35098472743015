import React, { useState, Fragment } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardActions,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Snackbar,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { DsprZipCode, DSPR } from '../store/reduxStoreState';

import DSPRZipCodeForm from '../containers/DSPRZipCodeForm';
import DSPRZipCodeComponent from './DSPRZipCodeComponent';
import './dsprZipCodesStyles.scss';
import { ADD_DSPR_ZIP_CODE_FAILURE, ADD_DSPR_ZIP_CODE_SUCCESS, TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_FAILURE, TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS } from "../actions/zipCodeActions";
import { Alert } from "@material-ui/lab";

interface DSPRZipCodesProps {
    dspr: DSPR;
    dsprZipCodes: DsprZipCode[];
    handleSubmitZipCodeForm: (newZipCodeValues: { zipCode: string }) => any;
    loggedInUserIsDSPManager: boolean;
    handleZipCodeMinimumChange: (dsprZipCodeId: number, newMin: string) => any;
    handleToggleDSPRZipCode: (zipCodeId: number) => any;
}

const DSPRZipCodes: React.FC<DSPRZipCodesProps> = React.memo(props => {
    const { dspr, dsprZipCodes, loggedInUserIsDSPManager, handleSubmitZipCodeForm, handleZipCodeMinimumChange, handleToggleDSPRZipCode } = props;

    const [isZipCodeFormOpen, setIsZipCodeFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleSubmitNewZipCodeForm = (newZipCodeValues: { zipCode: string }) => {
        setIsZipCodeFormOpen(false);
        setIsLoading(true);
        handleSubmitZipCodeForm(newZipCodeValues)
            .then(response => {
                if (response.type === ADD_DSPR_ZIP_CODE_SUCCESS) {
                    setShowSuccessMessage(true);
                    setMessageText(`${newZipCodeValues.zipCode} created!`);
                }
                if (response.type === ADD_DSPR_ZIP_CODE_FAILURE) {
                    setShowErrorMessage(true);
                    setMessageText(response.error ? `Error encountered! ${response.error}` : 'Error creating zip code');
                }
                setIsLoading(false);
            });
    };

    const toggleZipCode = (zipCodeId: number) => {
        handleToggleDSPRZipCode(zipCodeId).then((response) => {
            if (response.type === TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_FAILURE) {
                setShowErrorMessage(true);
                setMessageText(response.error ? `Error encountered! ${response.error}` : 'Error creating zip code');
            }
            if (response.type === TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS) {
                setShowSuccessMessage(true);
                setMessageText(`${response.zipCode} Toggled!`);
            }
        })
    }

    return <Card className="zipcodes">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Zipcode</TableCell>
                    <TableCell>Mininum Order Size</TableCell>
                    {loggedInUserIsDSPManager && <TableCell>Toggle Active Status</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {dsprZipCodes && dsprZipCodes.map(zipCode =>
                    <DSPRZipCodeComponent
                        key={zipCode.id}
                        zipCode={zipCode}
                        minimumOrderSizeDefault={dspr ? dspr.minimumOrderSize : null}
                        handleZipCodeMinimumChange={handleZipCodeMinimumChange}
                        handleToggleDSPRZipCode={toggleZipCode}
                        loggedInUserIsDSPManager={loggedInUserIsDSPManager}
                    />)}
            </TableBody>
        </Table>
        {loggedInUserIsDSPManager &&
            <Fragment>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsZipCodeFormOpen(true)}
                        disabled={isLoading}
                    >
                        {isLoading? 'Adding Zip Code...' : 'Add Zip Code'}
                    </Button>
                </CardActions>
                <Dialog
                    open={isZipCodeFormOpen}
                    onClose={() => setIsZipCodeFormOpen(false)}
                >
                    <DialogTitle>New Zip Code</DialogTitle>
                    <DialogContent>
                        <DSPRZipCodeForm
                            onSubmit={handleSubmitNewZipCodeForm}
                            onCancel={() => setIsZipCodeFormOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </Fragment>}

        {/*Success Snackbar*/}
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showSuccessMessage}
            onClose={() => setShowSuccessMessage(false)}
            key={'success-snackbar'}
            autoHideDuration={5000}
        >
            <Alert
                severity={'success'}
                style={{alignItems: 'center'}}
                action={(
                    <IconButton
                        aria-label='close'
                        onClick={() => setShowSuccessMessage(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            >
                {messageText}
            </Alert>
        </Snackbar>

        {/*Error Snackbar*/}
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showErrorMessage}
            onClose={() => setShowErrorMessage(false)}
            key={'error-snackbar'}
        >
            <Alert
                severity={'error'}
                style={{alignItems: 'center'}}
                action={(
                    <IconButton 
                        aria-label='close'
                        onClick={() => setShowErrorMessage(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            >
                {messageText}
            </Alert>
        </Snackbar>
    </Card>
})

export default DSPRZipCodes;