import {
    Button,
    CardActions,
    TextField
} from "@material-ui/core";
import React from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";

const validate = (values) => {
    const errors: any = {};
    if (!values.invoiceNumber)
        errors.invoiceNumber = 'Need a invoice number to lookup ';
    return errors
};

const renderField = ({
                         input,
                         label,
                         type,
                         multiline,
                         style,
                         meta: {touched, error}
                     }) => (
        <TextField label={label}
                   type={type} {...input}
                   error={!!(touched && error)}
                   helperText={touched && error ? error : ''}
                   multiline={multiline > 0}
                   fullWidth={multiline > 0}
                   rows={multiline}
                   style={style}/>
);


interface InvoiceForm {}


const InvoiceForm = (props:  InjectedFormProps<{},InvoiceForm>) => {
    const {
        handleSubmit
    } = props;

    return <form onSubmit={handleSubmit} className={'inventory-transaction-form'}>

            <Field name="invoiceNumber"
                   component={renderField}
                   label={"Invoice Number"}
                   style={{minWidth: '280px'}}/>
            <CardActions className={'submit-button-container'}>
                <Button variant="contained" color="primary"
                    onClick={handleSubmit}
                >Enter</Button>
            </CardActions>
    </form>
};

export default reduxForm<any,InvoiceForm>({
    form: 'InvoiceForm',
    validate
})(InvoiceForm);