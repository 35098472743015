import { State } from '../store/reduxStoreState';
import { createSelector } from 'reselect';

export const getBatchNumbersForDSPRFromProps = (state: State, props) => {
	if (props.dsprId) {
		return state.api.entities.dsprBatchNumbers[props.dsprId];
	}
	return null;
};

export const getBatchNumbersForDSPRForAutoSelectWithValueAsText = createSelector(
	[getBatchNumbersForDSPRFromProps],
	(batchNumbersForDSPR) => {
		return batchNumbersForDSPR
			? Object.values(batchNumbersForDSPR)
					.map((batchNumber) => {
						return { value: batchNumber.batchNumber, text: batchNumber.batchNumber };
					})
					.sort((a, b) => a.text.length - b.text.length)
			: [];
	},
);

export const getUnassignedBatchNumbersForDSPRForAutoSelectWithValueAsText = createSelector(
	[getBatchNumbersForDSPRFromProps],
	(batchNumbersForDSPR) => {
		return batchNumbersForDSPR
			? Object.values(batchNumbersForDSPR)
					.filter((tag) => !tag.product && !tag.isActive)
					.map((tag) => {
						return { value: tag.batchNumber, text: tag.batchNumber };
					})
					.sort((a, b) => a.text.length - b.text.length)
			: [];
	},
);
