import React, { useState, useEffect } from "react";
import { DSPR, DsprDriver, DSPRDriverServiceArea, DSPRDriverServiceAreaProfile, DSPRDriverServiceAreaVertex, User } from "../store/reduxStoreState";
import { GoogleMap } from "@react-google-maps/api";
import {
	Card,
	CardHeader,
	CardContent,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Button, FormControlLabel, Switch, Dialog, DialogTitle, DialogContent
} from "@material-ui/core";
import PolygonController from "../components/PolygonController";
import { PhoneIphone as OncallIcon } from "@material-ui/icons";
import SweetAlert from 'react-bootstrap-sweetalert';

import "./DSPRServiceAreaManagementStyles.scss";
import { CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS, CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS, SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS } from "../actions/dsprActions";
import { MenuMechanism } from "../store/reduxStoreState";
import DSPRDriverServiceAreaProfileForm from "../containers/DSPRDriverServiceAreaProfileForm";

interface GoogleMapProps {
	dspr: DSPR;
	handlePolygonMapClick: (event) => any;
	handlePolygonClick: (index: number) => (id: number, driverId: number, polygonRef: any) => any;
	setSelectedPolyRef: (polyRef: any) => any;
	currentPolygonId: number;
    savedPolygons: any[];
    editProfileMode: boolean;
    setCurrentPolygonEdited: (value: boolean) => any;
}

const GetStartedGoogleMap = (props: GoogleMapProps) => {
	const {
		handlePolygonClick,
        handlePolygonMapClick,
        setCurrentPolygonEdited,
		currentPolygonId,
        savedPolygons,
        editProfileMode
	} = props;
	const defaultCenter = {
		lat: props.dspr.centralLatitude,
		lng: props.dspr.centralLongitude,
	};
	const [map, setMap] = useState(undefined);
	const handleMapClick = (event) => {
        handlePolygonMapClick(event.latLng);
    };

	return (
		<GoogleMap
			zoom={12}
			center={(map && map.getCenter()) || defaultCenter}
			mapContainerStyle={{
				height: "500px",
				width: "100%",
			}}
			onLoad={(map) => setMap(map)}
			onUnmount={(map) => setMap(undefined)}
			onClick={handleMapClick}
		>
			{savedPolygons && savedPolygons.sort((a,b)=>a.id - b.id).map((serviceArea, index) => serviceArea && (
				<PolygonController
					key={`polygon-${serviceArea.id}`}
                    serviceArea={serviceArea}
                    editable={editProfileMode}
					currentServiceAreaId={currentPolygonId}
                    handlePolygonClick={handlePolygonClick(index)}
                    setCurrentPolygonEdited={setCurrentPolygonEdited}
				/>
			))}
		</GoogleMap>
	);
};

interface DSPRServiceAreaManagementProps {
	dspr: DSPR;
	createOrUpdatePolygon: (
		latLngArray,
		id: number,
        currentDriverId,
        selectedServiceAreaProfileId,
        numberOrdersPerRoute,
        cacheable,
		active
	) => any;
	updateDSPRMenuMechanism: (id: number, menuMechanism: MenuMechanism) => any;
    drivers: Partial<Omit<DsprDriver, "user"> & { user: User }>[];
    serviceAreaProfiles: Partial<Omit<DSPRDriverServiceAreaProfile, "dsprDriverServiceAreas"> & { dsprDriverServiceAreas: DSPRDriverServiceArea[]}>[];
    serviceAreaMap: { [key: number]: Partial<Omit<DSPRDriverServiceArea, "dsprDriverServiceAreaVertices"> & {dsprDriverServiceAreaVertices: DSPRDriverServiceAreaVertex[]}>};
    setServiceAreaProfileActive: ( id :number) => any;
    createNewServiceAreaProfile: (id, name, serviceAreaList, dspr, active) => any;
}

const DSPRServiceAreaManagement: React.FC<DSPRServiceAreaManagementProps> = (
	props
) => {
	const {
		dspr,
		createOrUpdatePolygon,
		drivers,
        updateDSPRMenuMechanism,
        serviceAreaProfiles,
        serviceAreaMap,
        createNewServiceAreaProfile,
        setServiceAreaProfileActive
	} = props;

	const [selectedPolyRef, setSelectedPolyRef] = useState(undefined);
    const [currentPolygonId, setCurrentPolygonId] = useState(undefined);
    const [currentPolygonEdited, setCurrentPolygonEdited] = useState(false);
	const [currentSelectedNumberOrders,setCurrentSelectedNumberOrders] = useState(undefined);
    const [currentSelectedDriver, setCurrentSelectedDriver] = useState(undefined);
    const [currentlySelectedIsCacheable, setCurrentlySelectedIsCacheable] = useState(undefined);
    const [serviceAreaCacheabilityHasBeenChanged, setServiceAreaCacheabilityHasBeenChanged] = useState(false)
	const [driversMap, setDriversMap] = useState(undefined);
    const [waitingOnNewPolygonCallback, setWaitingOnNewPolygonCallback] = useState(false);
    const [currentlySelectedProfile, setCurrentlySelectedProfile] = useState(undefined);
    const [serviceAreaProfileIdSelectedToBeEdited, setServiceAreaProfileIdSelectedToBeEdited] = useState(undefined);
    const [editProfileMode, setEditProfileMode] = useState(false);
    const [newProfileDialogOpen, setNewProfileDialogOpen] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [modalPrimaryText, setModalPrimaryText] = useState(undefined);
    const [modalSecondaryText, setModalSecondaryText] = useState(undefined);

    const [disableCreateProfileButton, setDisableCreateProfileButton] = useState(false)

	useEffect(() => {
		const newDriversMap = {};
		drivers.forEach((driver) => {
			newDriversMap[driver.id] = driver;
		});

		setDriversMap(newDriversMap);
    }, [drivers]);

    useEffect(() => {
        setServiceAreaProfileIdSelectedToBeEdited(dspr.currentServiceAreaProfile)
    }, [dspr.currentServiceAreaProfile])

	const handlePolygonClick = (index) => (id, driverId, ref) => {
		if (currentPolygonId && currentPolygonId !== id) {
			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
					.getPath()
					.getArray()
					.map((latLng) => ({
						latitude: latLng.lat(),
						longitude: latLng.lng(),
					})) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				currentSelectedDriver,
                currentSelectedNumberOrders,
                serviceAreaCacheabilityHasBeenChanged ? currentlySelectedIsCacheable : null,
				true
			);
		}
		setCurrentPolygonId(id);
		setCurrentSelectedDriver(driversMap[driverId]);
		setCurrentSelectedNumberOrders(
			serviceAreaProfiles[serviceAreaProfileIdSelectedToBeEdited].dsprDriverServiceAreas[index].numberOrdersPerRoute
		);
        setSelectedPolyRef(ref);
        setCurrentlySelectedIsCacheable(serviceAreaMap[id].cacheable)
        setCurrentPolygonEdited(false);
	};

	const handlePolygonMapClick = (event) => {
		if (!waitingOnNewPolygonCallback) {
			if (currentPolygonId) {
				createOrUpdatePolygon(
					currentPolygonEdited ? selectedPolyRef.current
						.getPath()
						.getArray()
						.map((latLng) => ({
							latitude: latLng.lat(),
							longitude: latLng.lng(),
						})) : null,
                    currentPolygonId,
                    serviceAreaProfileIdSelectedToBeEdited,
					currentSelectedDriver,
                    currentSelectedNumberOrders,
                    serviceAreaCacheabilityHasBeenChanged ? currentlySelectedIsCacheable : null,
					true
				);
				setCurrentPolygonId(null);
				setSelectedPolyRef(null);
				setCurrentSelectedDriver(null);
                setCurrentSelectedNumberOrders(null);
                setCurrentPolygonEdited(false);
			} else {
                if (editProfileMode) {
                    const updatedVertices = [];
				updatedVertices.push({
					latitude: event.lat() + 0.03,
					longitude: event.lng() + 0.03,
				});
				updatedVertices.push({
					latitude: event.lat() + 0.03,
					longitude: event.lng() - 0.03,
				});
				updatedVertices.push({
					latitude: event.lat() - 0.03,
					longitude: event.lng() - 0.03,
				});
				updatedVertices.push({
					latitude: event.lat() - 0.03,
					longitude: event.lng() + 0.03,
				});
				setWaitingOnNewPolygonCallback(true);
				createOrUpdatePolygon(
					updatedVertices,
                    null,
                    serviceAreaProfileIdSelectedToBeEdited,
					null,
                    null,
                    true,
					true
				).then((response) => {
					setWaitingOnNewPolygonCallback(false);
					if (
						response.type ===
						CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS
					) {
						setCurrentSelectedDriver(null);
                        setCurrentSelectedNumberOrders(null);
                        setCurrentPolygonEdited(false);
					}
				});
                }	
			}
		}
	};

    const serviceAreaProfileMenuSelections = serviceAreaProfiles && Object.values(serviceAreaProfiles).map((profile) => <MenuItem button key={`service-area-profile-${profile.id}`} value={profile.id}>
                    {profile.name}
                </MenuItem>
            )

	const generateOrderNumberOverrideSeletions = () => {
		const menuItems = [];
		for (let i = 1; i <= 10; ++i) {
			menuItems.push(
				<MenuItem button key={`order-override-${i}`} value={i}>
					{i} Order{i === 1 ? "" : "s"}
				</MenuItem>
			);
		}

		return menuItems;
	};
	const dsprDrivers =
		drivers &&
		drivers.map((driver) => {
			const activeClass = driver.active ? "active" : "inactive";
			return (
				<MenuItem
					button
					key={driver.id}
					className={activeClass}
					value={driver.id}
				>
					{driver.onCall && (
						<span>
							<OncallIcon htmlColor="orange" />
						</span>
					)}
					{driver.user.firstName} {driver.user.lastName} -{" "}
					{driver.user.email}
				</MenuItem>
			);
		});

	const handleDriverSelection = (event) => {
        setCurrentSelectedDriver(driversMap[event.target.value]);
		if (currentPolygonId) {
			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => ({
                        latitude: latLng.lat(),
                        longitude: latLng.lng(),
                    })) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				driversMap[event.target.value],
                currentSelectedNumberOrders,
                undefined,
				true
			);
		}
    };

    const handleServiceAreaToBeEditedProfileSelection = (event) => {
        setServiceAreaProfileIdSelectedToBeEdited(event.target.value);
        setCurrentPolygonId(null);
        setSelectedPolyRef(null);
        setCurrentSelectedDriver(null);
        setCurrentSelectedNumberOrders(null);
        setCurrentPolygonEdited(false);
        setEditProfileMode(false)
    };

    const openCreateNewServiceAreaProfileModal = () => { 
        setNewProfileDialogOpen(true)
    };

    const handleServiceAreaProfileSelection = (event) => {
        setCurrentlySelectedProfile(event.target.value);
        if(event.target.value !== dspr.currentServiceAreaProfile) {
            // Set new service Area Active
            setServiceAreaProfileActive(event.target.value).then(response => {
                if(response.type === SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS) {
                    setModalPrimaryText("Service Area Profile '"+ serviceAreaProfiles[event.target.value].name +"' Now Active");
                    setModalSecondaryText("The new service area profile is active!");
                    setShowSuccessModal(true);
                    setCurrentPolygonId(null);
                    setSelectedPolyRef(null);
                    setCurrentSelectedDriver(null);
                    setCurrentSelectedNumberOrders(null);
                    setCurrentPolygonEdited(false);
                } else {
                    setCurrentlySelectedProfile(dspr.currentServiceAreaProfile);
                    setModalPrimaryText("Error Setting Service Area Profile To Be Active");
                    setModalSecondaryText(response.error);
                    setShowErrorModal(true);
                }
            })
        }
    };

    const handleCreateNewProfileButton = (values: {name: String, template: number}) => {
        setDisableCreateProfileButton(true);
        const serviceAreas = values.template ? serviceAreaProfiles[values.template].dsprDriverServiceAreas.map(serviceArea => ({id: serviceArea.id})) : null
        createNewServiceAreaProfile(null, values.name, serviceAreas, {id: dspr.id}, true).then(response => {
            if(response.type === CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS) {
                setModalPrimaryText("Profile Created");
                setModalSecondaryText("Profile '" + values.name + "' has been successfully created");
                setShowSuccessModal(true);
                setNewProfileDialogOpen(false);
                setServiceAreaProfileIdSelectedToBeEdited(response.response.result);
                setEditProfileMode(true)
                setCurrentPolygonId(null);
				setSelectedPolyRef(null);
				setCurrentSelectedDriver(null);
                setCurrentSelectedNumberOrders(null);
                setCurrentPolygonEdited(false);
            }
            setDisableCreateProfileButton(false)
        })
    }

    const handleRemoveProfileButton = () => {
        const confirmRemove = window.confirm("Do you really want to remove this service area profile? You cannot undo this action")
        if(confirmRemove) {
            const oldSelectedServiceAreaToBeEdited = serviceAreaProfileIdSelectedToBeEdited
            setServiceAreaProfileIdSelectedToBeEdited(currentlySelectedProfile || dspr.currentServiceAreaProfile)
            createNewServiceAreaProfile(serviceAreaProfileIdSelectedToBeEdited, null, null, {id: dspr.id}, false).then(response=> {
                if(response.type === CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS) {
                    setModalPrimaryText("Profile Removed");
                    setModalSecondaryText("The service Area profile has been sucessfully deleted.");
                    setShowSuccessModal(true);
                    setCurrentPolygonEdited(false);
                    setCurrentPolygonId(null);
                    setSelectedPolyRef(null);
                    setCurrentSelectedDriver(null);
                    setCurrentSelectedNumberOrders(null);
                    setCurrentPolygonEdited(false);
                } else {
                    setModalPrimaryText("Error Removing Profile");
                    setModalSecondaryText(response.error || "There was an error, and no message is available.");
                    setShowErrorModal(true);
                    setServiceAreaProfileIdSelectedToBeEdited(oldSelectedServiceAreaToBeEdited)
                }
            })
        }
    }

    const handleServiceAreaCacheabilitySlider = (event, checked) => {
        if (currentPolygonId) {
            if(serviceAreaMap[currentPolygonId].cacheable === checked) {
                setServiceAreaCacheabilityHasBeenChanged(false);
            } else {
                setServiceAreaCacheabilityHasBeenChanged(true)
            }
            setCurrentlySelectedIsCacheable(checked);

			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => ({
                        latitude: latLng.lat(),
                        longitude: latLng.lng(),
                    })) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				driversMap[event.target.value],
                currentSelectedNumberOrders,
                checked,
				true
			).then(response => {
                if(response.type !== CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS) {
                    setModalPrimaryText("Error Changing Service Area Cacheability!")
                    setModalSecondaryText(response.error || "No error message provided")
                    setShowErrorModal(true)
                }
            });
		}
    }


	const handleNumberOrderSelection = (event) => {
		setCurrentSelectedNumberOrders(event.target.value);
		if (currentPolygonId) {
			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => ({
                        latitude: latLng.lat(),
                        longitude: latLng.lng(),
                    })) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				currentSelectedDriver,
                event.target.value,
                null,
				true
			).then(response => {
                if(response.type !== CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS) {
                    setModalPrimaryText("Error Setting Order Overload!")
                    setModalSecondaryText(response.error || "No error message provided")
                    setShowErrorModal(true)
                }
            });
		}
	};

	const handleDeleteSelectedPolygon = () => {
		if (currentPolygonId && editProfileMode) {
			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => ({
                        latitude: latLng.lat(),
                        longitude: latLng.lng(),
                    })) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				null,
                null,
                null,
				false
			).then(response => {
                if(response.type !== CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS) {
                    setModalPrimaryText("Error Deleting Service Area!")
                    setModalSecondaryText(response.error || "No error message provided")
                    setShowErrorModal(true)
                }
            });
			setCurrentPolygonId(null);
			setSelectedPolyRef(null);
			setCurrentSelectedDriver(null);
            setCurrentSelectedNumberOrders(null);
            setCurrentPolygonEdited(false);
		}
	};

	const handleSave = () => {
		if (currentPolygonId) {
			createOrUpdatePolygon(
				currentPolygonEdited ? selectedPolyRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => ({
                        latitude: latLng.lat(),
                        longitude: latLng.lng(),
                    })) : null,
                currentPolygonId,
                serviceAreaProfileIdSelectedToBeEdited,
				currentSelectedDriver,
                currentSelectedNumberOrders,
                serviceAreaCacheabilityHasBeenChanged ? currentlySelectedIsCacheable : null,
				true
			).then(response => {
                if(response.type !== CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS) {
                    setModalPrimaryText("Error Saving Service Area!")
                    setModalSecondaryText(response.error || "No error message provided")
                    setShowErrorModal(true)
                }
            });
			setCurrentPolygonId(null);
			setSelectedPolyRef(null);
			setCurrentSelectedDriver(null);
            setCurrentSelectedNumberOrders(null);
            setCurrentPolygonEdited(false)
		}
	};

	const handleMenuMechanismSelection = (event) => {
		updateDSPRMenuMechanism(dspr.id, event.target.value);
	};

	return (
		<main className="dspr-driver-service-area-management">
			<h2>Service Area Management</h2>
			<Card>
				<CardHeader
					title="Service Areas"
					action={
						dspr.menuMechanism && (
							<FormControl className="menu-mechanism-select-dropdown">
								<InputLabel id="menu-mechanism-select-label">
									Menu Mechanism
								</InputLabel>
								<Select
									labelId="menu-mechanism-select-label"
									value={dspr.menuMechanism}
									onChange={(value) =>
										handleMenuMechanismSelection(value)
									}
								>
									<MenuItem
										button
										key={"menu_mechanism_0"}
										className={"active"}
										value={"closest_driver"}
									>
										Closest Driver
									</MenuItem>
									<MenuItem
										button
										key={"menu_mechanism_1"}
										className={"active"}
										value={"service_area"}
									>
										Service Area
									</MenuItem>
								</Select>
							</FormControl>
						)
					}
				/>
				<CardContent>
					<div className={"controls-container"}>
						<div className="mobile-menu-mechanism">
							{dspr.menuMechanism && (
								<FormControl className="menu-mechanism-select-dropdown-mobile">
									<InputLabel id="menu-mechanism-select-label">
										Menu Mechanism
									</InputLabel>
									<Select
										labelId="menu-mechanism-select-label"
										value={dspr.menuMechanism}
										onChange={(value) =>
											handleMenuMechanismSelection(value)
										}
									>
										<MenuItem
											button
											key={"mobile_menu_mechanism_0"}
											className={"active"}
											value={"closest_driver"}
										>
											Closest Driver
										</MenuItem>
										<MenuItem
											button
											key={"mobile_menu_mechanism_1"}
											className={"active"}
											value={"service_area"}
										>
											Service Area
										</MenuItem>
									</Select>
								</FormControl>
							)}
                        </div>
                        <div className="active-profile">
                            <h4>Active Profile: </h4>
                            {dspr.currentServiceAreaProfile && (<FormControl className="service-area-profile-selection">
                                <InputLabel id="service-area-profile-select-label">
                                </InputLabel>
                                <Select
                                    labelId="service-area-profile-select-label"
                                    value={currentlySelectedProfile || dspr.currentServiceAreaProfile}
                                    onChange={(value) =>
                                        handleServiceAreaProfileSelection(value)
                                    }
                                >
                                    {Object.keys(serviceAreaProfiles).length > 0 && serviceAreaProfileMenuSelections}
                                </Select>
                            </FormControl>)}
                        </div>
                        <h4>Editing Profiles</h4>
                        <div className="profile-controls"> 
                            <div className="service-area-profile-data-controls">
                                {(serviceAreaProfileIdSelectedToBeEdited || dspr.currentServiceAreaProfile) && ( <FormControl className="service-area-profile-to-be-edited-selection">
                                    <InputLabel id="service-area-profile-to-be-selected-label">
                                        Profile to Edit
                                    </InputLabel>
                                    <Select
                                        labelId="service-area-profile-to-be-selected-label"
                                        value={serviceAreaProfileIdSelectedToBeEdited || dspr.currentServiceAreaProfile}
                                        onChange={(value) =>
                                            handleServiceAreaToBeEditedProfileSelection(value)
                                        }
                                    >
                                        {Object.keys(serviceAreaProfiles).length > 0 && serviceAreaProfileMenuSelections}
                                    </Select>
                                </FormControl>
                                )}
                                <FormControlLabel 
                                    control={<Switch size="small" />}
                                    label="Edit Mode"
                                    checked={editProfileMode}
                                    onChange={() => setEditProfileMode(!editProfileMode)}
                                />
                            </div>
                            <div className="profile-buttons-container">
                                <Button 
                                    color="primary"
                                    onClick={() => handleRemoveProfileButton()}
                                >Remove Profile</Button>
                                <Button 
                                    color="primary"
                                    variant="contained"
                                    onClick={() => openCreateNewServiceAreaProfileModal()}
                                >New Profile</Button>
                            </div>
                        </div>
                        <h4>Editing Service Areas</h4>   
                        <div className="service-area-controls">
                            <div className="service-area-data-controls">
                            <FormControl className="driver-select-dropdown">
                                <InputLabel id="driver-list-select-label">
                                    Select a Driver
                                </InputLabel>
                                <Select
                                    labelId="driver-list-select-label"
                                    value={
                                        currentSelectedDriver
                                            ? currentSelectedDriver.id
                                            : ""
                                    }
                                    onChange={(value) =>
                                        handleDriverSelection(value)
                                    }
                                    disabled={!currentPolygonId}
                                >
                                    <MenuItem
                                        button
                                        key={"no-drivers"}
                                        className={"active"}
                                        value={""}
                                    >
                                        No Driver
                                    </MenuItem>
                                    {dsprDrivers}
                                </Select>
                            </FormControl>
                            <FormControl className="number-order-select-dropdown">
                                <InputLabel id="number-order-select-label">
                                    Orders Per Route
                                </InputLabel>
                                <Select
                                    labelId="number-order-select-label"
                                    value={currentSelectedNumberOrders || ""}
                                    onChange={(value) =>
                                        handleNumberOrderSelection(value)
                                    }
                                    disabled={!currentPolygonId}
                                >
                                    <MenuItem
                                        button
                                        key={"no-override"}
                                        className={"active"}
                                        value={""}
                                    >
                                        No Override
                                    </MenuItem>
                                    {dspr &&
                                        generateOrderNumberOverrideSeletions()}
                                </Select>
                            </FormControl>
                            <FormControlLabel 
                                control={<Switch size="small" />}
                                label="Cacheable"
                                checked={currentlySelectedIsCacheable || false}
                                disabled={!currentPolygonId}
                                onChange={(event, checked) => handleServiceAreaCacheabilitySlider(event, checked)}
                            />
                            </div>
                            
                            <div className="polygon-buttons-container">
                                <Button
                                    color="primary"
                                    variant="text"
                                    disabled={!(currentPolygonId && editProfileMode)}
                                    onClick={() => handleDeleteSelectedPolygon()}
                                >
                                    Remove Polygon
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleSave()}
                                >
                                    Save All Polygons
                                </Button>
                            </div>
                        </div>
							
						
					</div>
					{dspr.centralLatitude && dspr.centralLongitude && Object.keys(serviceAreaProfiles).length > 0 && (
						<GetStartedGoogleMap
							dspr={dspr}
                            handlePolygonMapClick={handlePolygonMapClick}
                            editProfileMode={editProfileMode}
                            currentPolygonId={currentPolygonId}
                            setCurrentPolygonEdited={setCurrentPolygonEdited}
							savedPolygons={serviceAreaProfiles && serviceAreaProfiles[serviceAreaProfileIdSelectedToBeEdited] && serviceAreaProfiles[serviceAreaProfileIdSelectedToBeEdited].dsprDriverServiceAreas}
							handlePolygonClick={handlePolygonClick}
							setSelectedPolyRef={setSelectedPolyRef}
						/>
					)}
				</CardContent>
			</Card>

            <Dialog 
            open={newProfileDialogOpen}
            onClose={() => setNewProfileDialogOpen(false)}
            className="new-profile-dialog">
                <DialogTitle>
                        New Profile
                </DialogTitle>
                <DialogContent>
                        Create a new Service Area Profile:
                        <DSPRDriverServiceAreaProfileForm 
                            onCancel={()=> setNewProfileDialogOpen(false)}
                            selectMenuItems={serviceAreaProfileMenuSelections}
                            onSubmit={handleCreateNewProfileButton}
                            disableCreateProfileButton={disableCreateProfileButton}/>
                </DialogContent>
            </Dialog>
            {showSuccessModal && <SweetAlert
                    success
                    timeout={2000}
                    style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                    title={modalPrimaryText}
                    onConfirm={() => setShowSuccessModal(false)}
                    showConfirm={false}
                >
                    {modalSecondaryText}
                </SweetAlert>}
            {showErrorModal && <SweetAlert
                error
                timeout={4000}
                style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                title={modalPrimaryText}
                onConfirm={() => setShowErrorModal(false) }
                showConfirm={false}
            >
                {modalSecondaryText}
            </SweetAlert>}
            {showWarningModal && <SweetAlert
                    warning
                    timeout={3000}
                    style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                    title={modalPrimaryText}
                    onConfirm={() => setShowWarningModal(false) }
                    showConfirm={false}
                >
                    {modalSecondaryText}
                </SweetAlert>}
		</main>
	);
};

export default DSPRServiceAreaManagement;
