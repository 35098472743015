import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';

import { State, DSPR, Analytic, DeliveryServiceProvider } from '../store/reduxStoreState';
import { getDSPRAnalytics, rebuildDSPRAnalytics } from '../actions/dsprActions';
import { getDSPRFromProps } from '../selectors/dsprSelectors';
import { getMysqlDateString1SecondBeforeEndOfDayFromTimestamp, getMySQLDateString1SecondBehindStartOfDayFromTimestamp, getMySQLDateStringFromTimestamp, parseDate } from "../util/util";
import AnalyticsDisplay from "../components/AnalyticsDisplay";
import { straightLinesChart } from '../variables/charts'
import AnalyticsUtil from '../util/AnalyticsUtil';

import '../components/AnalyticsDisplayStyles.scss';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    TableCell,
    TableRow,
    ListItem,
    CardHeader,
    CardContent
} from '@material-ui/core';
import ChartistGraph from "react-chartist";
import List from "@material-ui/core/List";
import { getDSPFromProps } from "../selectors/dspSelectors";
import { getDSPAnalytics, rebuildDSPAnalytics } from "../actions/dspActions";
import { API_ROOT } from '../middleware/api';
import OrderReportForm from './OrderReportForm';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../actions/oauthActions';
import AnalyticsReportForm from './AnalyticsReportForm';

function DSPAndDSPRAnalyticsContainer() {
    const {formatDateForTable, formatDateForChart} = AnalyticsUtil;
    const FILTER_TYPE = {
        DAY: 'days',
        WEEK: 'weeks',
        MONTH: 'months',
        QUARTER: 'quarters',
        YEAR: 'years'
    };
    const {dsprId, dspId} = useParams<{ dsprId: string, dspId: string }>();
    const dispatch = useDispatch();

    const analyticEntity = useSelector<State, DSPR | DeliveryServiceProvider>(state => {
        if (dsprId) {
            return getDSPRFromProps(state, {dsprId})
        } else if (dspId) {
            return getDSPFromProps(state, {dspId})
        }
    }, shallowEqual);

    const dspr = useSelector<State, DSPR>(state => dsprId && getDSPRFromProps(state, {dsprId: parseInt(dsprId)}), shallowEqual);
    const dsp = useSelector<State, DeliveryServiceProvider>(state => dspId && getDSPFromProps(state, {dspId: parseInt(dspId)}), shallowEqual);

    const [isLoading, setIsLoading] = useState(false);
    const [currentFilterType, setCurrentFilterType] = useState(FILTER_TYPE.DAY);
    const [showRevenueDetail, setShowRevenueDetail] = useState(null);
    const [sortedAnalytics, setSortedAnalytics] = useState(null);
    const [formattedData, setFormattedData] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [tableBody, setTableBody] = useState([]);


    const [showCustomOrderReportDialog, setShowCustomOrderReportDialog] = useState(false);
    const closeCustomOrderReportDialog = () => {
        setShowCustomOrderReportDialog(false);
    }
    const handleShowCustomOrderForm = () => {
        setShowCustomOrderReportDialog(true);
    }
    const handleCustomOrderReport = (formValues) => {
        let rows = undefined;
        let orderStatuses = undefined; 
        let beginningTimestamp = undefined
        let endTimestamp = undefined;

        Object.entries(formValues).map(([key, value]) => {
            if(value) {
                switch (key) {
                    case "endDayTimestamp":
                        endTimestamp=getMySQLDateStringFromTimestamp(value);
                        break;
                    case "beginDayTimestamp":
                        beginningTimestamp=getMySQLDateStringFromTimestamp(value)
                        break;
                    case "queued":
                    case "in-process":
                    case "completed":
                    case "canceled":
                    case "modified":
                        if(orderStatuses) orderStatuses += "," + key;
                        else orderStatuses = key;
                        break;
                    default:
                        if(rows) rows += "," + key;
                        else rows = key;
                        break;
                }
            }
        });
        let DSPROrDSPIdString
        if(dsprId) {
            DSPROrDSPIdString = `dspr_id=${dsprId}`
        } else {
            DSPROrDSPIdString = `dsp_id=${dspId}`
        }
        let url = API_ROOT + `order/history/download-order-report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}&begin_day_timestamp=${encodeURIComponent(beginningTimestamp)}&end_day_timestamp=${encodeURIComponent(endTimestamp)}&${DSPROrDSPIdString}`;
        if(rows != undefined) url += "&rows=" + encodeURIComponent(rows);
        if(orderStatuses != undefined) url += "&order_statuses=" + encodeURIComponent(orderStatuses)
        window.open(url + "&_blank");
        closeCustomOrderReportDialog();
    }

    const [showCustomAnalyticsReportDialog, setShowCustomAnalyticsReportDialog] = useState(false);
    const closeCustomAnalyticsReportDialog = () => {
        setShowCustomAnalyticsReportDialog(false);
    }
    const handleShowCustomAnalyticsForm = () => {
        setShowCustomAnalyticsReportDialog(true);
    }
    const handleCustomAnalyticsReport = (formValues) => {
        let rows = undefined;
        let timeframe = undefined;
        let beginningTimestamp = undefined
        let endTimestamp = undefined;

        Object.entries(formValues);

        Object.entries(formValues).map(([key, value]) => {
            if(value) {
                switch (key) {
                    case "endDayTimestamp":
                        endTimestamp=getMysqlDateString1SecondBeforeEndOfDayFromTimestamp(value);
                        break;
                    case "beginDayTimestamp":
                        beginningTimestamp=getMySQLDateString1SecondBehindStartOfDayFromTimestamp(value)
                        break;
                    case "timeframe":
                        timeframe=value;
                        break;
                    default:
                        if(rows) rows += "," + key;
                        else rows = key;
                        break;
                }
            }
        });
            
        let url = API_ROOT + (dspId ? `delivery_service_provider` : `dspr`) + `/analytics/download_analytics_report?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}&begin_day_timestamp=${encodeURIComponent(beginningTimestamp)}&end_day_timestamp=${encodeURIComponent(endTimestamp)}`;
        if(rows != undefined) url += "&rows=" + encodeURIComponent(rows);
        if(dspId) url += "&dsp_id=" + dspId;
        else if(dsprId) url += "&dspr_id=" + dsprId;
        if(timeframe) url+="&timeframe=" + timeframe;
        window.open(url + "&_blank");
        closeCustomAnalyticsReportDialog();
    }

    /**Dispatch Analytics Request for DSP or DSPR */
    useEffect(() => {
        if (dspId) {
            setIsLoading(true);
            dispatch<any>(getDSPAnalytics(dspId))
                .then(() => setIsLoading(false));
        } else if (dsprId) {
            setIsLoading(true);
            dispatch<any>(getDSPRAnalytics(dsprId))
                .then(() => setIsLoading(false))
        }
    }, [dispatch, dsprId, dspId]);

    /**Temporary handleRebuildAnalytics that just rebuilds DSPR Analytics*/
    const handleRequestAnalytics =
        dsprId ? () => {
                setIsLoading(true);
                dispatch<any>(getDSPRAnalytics(dsprId))
                    .then(() => setIsLoading(false));
            }
            : () => {
                setIsLoading(true);
                dispatch<any>(getDSPAnalytics(dspId))
                .then(() => setIsLoading(false))
            }

    /**Recreates analytic history based upon current context
     * -> useful to run after staging is updated
     * -> to run, uncomment out Rebuild button in AnalyticsDisplay.
     * ->AFTER RUNNING, COMMENT BUTTON OUT :) */
    const handleRebuildAnalytics = () => {
        if (dspId) {
            setIsLoading(true);
            dispatch<any>(rebuildDSPAnalytics(dspId))
                .then(() => setIsLoading(false));
        } else if (dsprId) {
            setIsLoading(true);
            dispatch<any>(rebuildDSPRAnalytics(dsprId))
                .then(() => setIsLoading(false))
        }
    }

    interface dataForCharts {
        date: string[] | number[];
        revenue: number[];
        numPlacedOrders: number[];
        numCompletedOrders: number[];
        numMissedOrders: number[];
        percentageOrdersCompleted: number[];
        weeklyPlacedOrders: number;
        weeklyCompletedOrders: number;
        weeklyAveragePercentageOrdersCompleted: number;
        revenueChartData: { labels: number[] | string[], series: number[][] };
        orderPieData: { labels: string[], series: number[] };
        orderChartData: { labels: number[] | string[], series: number[][] };
    }

    const formatAnalyticsForCharts = useCallback((analytics: Analytic[]) => {
        const formattedData: dataForCharts = {
            date: [],
            revenue: [],
            numPlacedOrders: [],
            numCompletedOrders: [],
            numMissedOrders: [],
            percentageOrdersCompleted: [],
            weeklyPlacedOrders: null,
            weeklyCompletedOrders: null,
            weeklyAveragePercentageOrdersCompleted: null,
            revenueChartData: null,
            orderPieData: null,
            orderChartData: null,
        };

        if (analytics && analytics.length > 0) {
            const mostRecent7AnalyticsEntries: Analytic[] = analytics.slice(0, 7).reverse();

            // Formatting Data for graphs
            if (mostRecent7AnalyticsEntries.length > 0) {
                mostRecent7AnalyticsEntries.forEach((analyticsEntry: Analytic, idx: number) => {
                    formattedData.revenue[idx] = analyticsEntry.revenuesTotal;
                    formattedData.numPlacedOrders[idx] = analyticsEntry.numPlacedOrders;
                    formattedData.numCompletedOrders[idx] = analyticsEntry.numCompletedOrders;
                    formattedData.numMissedOrders[idx] = analyticsEntry.numPlacedOrders - analyticsEntry.numCompletedOrders;
                    formattedData.percentageOrdersCompleted[idx] = Math.round((analyticsEntry.numCompletedOrders / analyticsEntry.numPlacedOrders) * 100);
                    formattedData.weeklyPlacedOrders = formattedData.weeklyPlacedOrders + analyticsEntry.numPlacedOrders;
                    formattedData.weeklyCompletedOrders = formattedData.weeklyCompletedOrders + analyticsEntry.numCompletedOrders;
                    formattedData.weeklyAveragePercentageOrdersCompleted = formattedData.weeklyAveragePercentageOrdersCompleted + formattedData.percentageOrdersCompleted[idx];

                    formattedData.date[idx] = formatDateForChart(analyticsEntry, currentFilterType);
                });

                formattedData.weeklyAveragePercentageOrdersCompleted = Math.round(formattedData.weeklyAveragePercentageOrdersCompleted / 7)

            }

            // Start of Chart Formatting with the data
            formattedData.revenueChartData = {
                labels: formattedData.date,
                series: [formattedData.revenue]
            };

            formattedData.orderChartData = {
                labels: formattedData.date,
                series: [formattedData.numCompletedOrders, formattedData.numMissedOrders, formattedData.numPlacedOrders]
            };

            formattedData.orderPieData = {
                labels: [`${formattedData.weeklyAveragePercentageOrdersCompleted}%`, `${100 - formattedData.weeklyAveragePercentageOrdersCompleted}%`],
                series: [formattedData.weeklyAveragePercentageOrdersCompleted, 100 - formattedData.weeklyAveragePercentageOrdersCompleted]
            };

        }

        setFormattedData(formattedData);
    }, [currentFilterType, formatDateForChart]);

    /**Sort daily, weekly, monthly, quarterly, or yearly analytics data by date*/
    const sortAnalytics = useCallback((analyticsData) => {
        const sortedAnalytics = analyticsData[currentFilterType]
            .sort((a: Analytic, b: Analytic) => parseDate(b.beginDate).valueOf() - parseDate(a.beginDate).valueOf())
            .slice(0, 60);
        setSortedAnalytics(sortedAnalytics);
    }, [currentFilterType])

    const handleFilterChange = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        setCurrentFilterType(event.target.value);
    };

    /**Sorts and formats analytics data whenever analytics entity changes */
    useEffect(() => {
        if (analyticEntity && analyticEntity.analytics) {
            sortAnalytics(analyticEntity.analytics);
        }
    }, [analyticEntity, sortAnalytics, currentFilterType])

    /**Format dates for use in tables */
    const getDatesForAnalytics = useCallback((analytics: Analytic) => {
        if (!analytics) return '';
        return formatDateForTable(analytics, currentFilterType);
    }, [currentFilterType, formatDateForTable]);

    /**Renders table body for selected analytics */
    const renderAnalytics = useCallback((analytics: Analytic) => {
        const {discountsTotal, revenuesTotal} = analytics;
        const discountsPercent = (discountsTotal + revenuesTotal === 0) ? 0 : discountsTotal * 100 / (discountsTotal + revenuesTotal);
        const aosNoTaxes = (analytics.numCompletedOrders === 0) ? 0 : analytics.revenuesTotal / analytics.numCompletedOrders;
        const dateSpan = getDatesForAnalytics(analytics);

        return <TableRow hover key={analytics.beginDate + Math.random().toString(36).substring(7)} onClick={() => {
            setShowRevenueDetail(analytics)
        }} className="analytics-row">
            <TableCell>{dateSpan}</TableCell>
            {/*Cash Collected table cell - uncomment once fixed*/}
            <TableCell>${analytics.revenuesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.productRevenuesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.grossProfit.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{analytics.grossProfitMargin.toLocaleString('en-US', {maximumFractionDigits: 0})}%</TableCell>
            <TableCell>${analytics.deliveryFeesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.taxesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.cashReceivedTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${analytics.discountsTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{discountsPercent.toLocaleString('en-US', {maximumFractionDigits: 0})}%</TableCell>
            <TableCell>{analytics.numCompletedOrders.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{analytics.numPlacedOrders.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>{analytics.numFirstTimeCompletedOrders.toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
            <TableCell>${(aosNoTaxes).toLocaleString('en-US', {maximumFractionDigits: 0})}</TableCell>
        </TableRow>
    }, [getDatesForAnalytics]);

    //Table headers without "Cash Collected" column. Use this once cash collected analytics have been fixed
    //const tableHeaderNames = ['Period', 'Total Revenues', 'Product Revenues', 'Gross Profit', 'Gross Profit Margin', 'Delivery Fees',
    //    'Taxes', 'Cash Collected', 'Discounts', 'Discounts (% Rev)', 'Number of Completed Orders',
    //    'Number of Placed Orders', 'Number of First Time Completed Orders', 'AOS No Taxes'];

    const tableHeaderNames = ['Period', 'Total Revenues', 'Product Revenues', 'Gross Profit', 'Gross Profit Margin', 'Delivery Fees',
        'Taxes', 'Cash Collected', 'Discounts', 'Discounts (% Rev)', 'Number of Completed Orders',
        'Number of Placed Orders', 'Number of First Time Completed Orders', 'AOS No Taxes'];

    /**Submit sorted analytics data to be rendered in a table */
    const tableBodyAnalytics = useCallback(() => {
        return sortedAnalytics.map((analytics: Analytic) => renderAnalytics(analytics));
    }, [sortedAnalytics, renderAnalytics]);

    /**Update tableBody state to be the most recently rendered table body & format data for charts*/
    useEffect(() => {
        if (sortedAnalytics) {
            setTableBody(tableBodyAnalytics());
            formatAnalyticsForCharts(sortedAnalytics);
        }
    }, [sortedAnalytics, tableBodyAnalytics, formatAnalyticsForCharts]);

    /**Renders popup window to display analytics details for a particular date */
    const renderAnalyticsDetailDialog = useCallback(() => {
        return (
            <Dialog
                open={!!showRevenueDetail}
                onClose={() => {
                    setShowRevenueDetail(null);
                }}>
                {showRevenueDetail ? <Fragment>
                    <DialogTitle>Period Beginning: {getDatesForAnalytics(showRevenueDetail)}</DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem>Flower:
                                &nbsp;${showRevenueDetail.flowerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Concentrates:
                                &nbsp;${showRevenueDetail.concentrateRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Edibles:
                                &nbsp;${showRevenueDetail.edibleRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Vaporizers:
                                &nbsp;${showRevenueDetail.vaporizerRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Other:
                                &nbsp;${showRevenueDetail.otherRevenue.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Product Total:
                                &nbsp;${showRevenueDetail.productRevenuesTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Cash Total:
                                &nbsp;${showRevenueDetail.cashReceivedTotal.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Gross Profit:
                                &nbsp;${showRevenueDetail.grossProfit.toLocaleString('en-US', {maximumFractionDigits: 0})}</ListItem>
                            <ListItem>Gross Profit Margin:
                                &nbsp;{showRevenueDetail.grossProfitMargin.toLocaleString('en-US', {maximumFractionDigits: 0})}%</ListItem>
                        </List>
                    </DialogContent>
                </Fragment> : <p>Analytics Unavailable</p>}
            </Dialog>
        )
    }, [showRevenueDetail, getDatesForAnalytics])

    useEffect(() => {
        if (formattedData && formattedData.revenueChartData && formattedData.orderChartData && formattedData.orderPieData) {
            const titleTimeFrame = currentFilterType.charAt(0).toUpperCase() + currentFilterType.slice(1);

            // Abbreviate numbers. e.g. 1000000 -> 1M
            const abbreviateNumberOption = {
                axisY: {
                    labelInterpolationFnc: (value) => numeral(value).format('0a').toUpperCase()
                }
            }

            setChartData([
                {
                    title: `Revenue by ${titleTimeFrame}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Line'}
                        data={formattedData.revenueChartData}
                        options = {abbreviateNumberOption}
                        listener={straightLinesChart.animation}/>,
                    chartLegend: <div className={'legend-container'}>
                        <div className={'legend'}>
                            <span className={'legend-primary'}/><p> - Revenue</p>
                        </div>
                    </div>
                },
                {
                    title: `Orders by ${titleTimeFrame}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Line'}
                        data={formattedData.orderChartData}
                        options = {abbreviateNumberOption}
                        listener={straightLinesChart.animation}/>,
                    chartLegend: <div className="legend-container">
                        <div className='legend'>
                            <span className="legend-tertiary"/><p> - Made</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-primary"/><p> - Completed</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-secondary"/><p> - Missed</p>
                        </div>
                    </div>
                },
                {
                    title: `Order Completion by ${titleTimeFrame}`,
                    chart: <ChartistGraph
                        className={'ct-chart'}
                        type={'Pie'}
                        data={formattedData.orderPieData}/>,
                    chartLegend: <div className="legend-container">
                        <div className='legend'>
                            <span className="legend-primary"/><p> - Completed</p>
                        </div>
                        <div className='legend'>
                            <span className="legend-secondary"/><p> - Missed</p>
                        </div>
                    </div>

                },
            ])
        } else {
            setChartData([]);
        }
    }, [formattedData, currentFilterType]);

    const renderTitle = () => {
        if (dsprId && dspr) {
            return <h2>{dspr.name} Analytics Overview</h2>
        }

        if (dspId && dsp) {
            return <h2>{dsp.name} Analytics Overview</h2>
        }

        return <h2>Analytics Overview</h2>
    }


    return <main className="analytics-tab">
        {renderTitle()}

        <AnalyticsDisplay
            tableHeaderNames={tableHeaderNames}
            tableBody={tableBody}
            chartData={chartData}
            isLoading={isLoading}
            handleReloadAnalytics={handleRequestAnalytics}
            filterTypes={FILTER_TYPE}
            currentFilterType={currentFilterType}
            handleFilterChange={handleFilterChange}
            renderAnalyticsDetailDialog={renderAnalyticsDetailDialog}
            rebuildAnalytics={handleRebuildAnalytics}
            handleShowCustomOrderForm={handleShowCustomOrderForm}
            handleShowCustomAnalyticsForm={handleShowCustomAnalyticsForm}
        />

        <Dialog
            open={showCustomOrderReportDialog}
            onClose={() => closeCustomOrderReportDialog()}>
            <CardHeader title="Order Report" />
            <CardContent>
                <OrderReportForm onSubmit={handleCustomOrderReport} initialValues={{
                    completed: true,
                    order_id: true,
                    completion_date: true,
                    completion_time: true,
                    first_name: true,
                    last_name: true,
                    dhs_number: true,
                    order_details: true,
                    taxes: true,
                    total: true,
                    address: true
                }}/>
            </CardContent>
        </Dialog>
        <Dialog
            open={showCustomAnalyticsReportDialog}
            onClose={() => closeCustomAnalyticsReportDialog()}>
            <CardHeader title="Analytics Report" />
            <CardContent>
                <AnalyticsReportForm onSubmit={handleCustomAnalyticsReport} initialValues={{
                    begin_date: true,
                    cash_collected: true,
                    total_revenues: true,
                    delivery_fees: true,
                    product_revenues: true,
                    taxes: true,
                    discounts: true,
                    number_completed_orders: true,
                    gross_profit: true,
                    gross_profit_margin: true,
                    timeframe: "day"
                }}/>
            </CardContent>
        </Dialog>
    </main>
}

export default DSPAndDSPRAnalyticsContainer;