import React, { useState } from 'react';
import { Field, reduxForm, InjectedFormProps, change } from 'redux-form';
import { TextField, Button, DialogActions, Typography } from '@material-ui/core';
import AutoComplete, { AutoSelectItemStringValue, } from "../components/Autocomplete";
import './DSPRInventoryTransactionFormStyles.scss';
import './DSPRMetrcTagDownloadFormStyles.scss';
import {
    GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE,
    GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS,
    MetrcTagProductAssociationsGetterProps
} from "../actions/metrcTagActions";
import {
    getMetrcTagInMetrcTagOrBarcodeDownloadForm,
    getProductIdInMetrcTagOrBarcodeDownloadForm,
    getMetrcTagDownloadSelectedQRType,
    getBatchNumberInMetrcTagOrBarcodeDownloadForm
} from "../selectors/reduxFormSelectors";
import { BatchNumber, MetrcTag, State } from "../store/reduxStoreState";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getMetrcTagsForDSPRFromProps } from "../selectors/metrcTagSelectors";
import SnackBarAlertError from "../components/SnackBarAlertError";
import "../assets/jss/material-dashboard-pro-react/customSelectStyle"
import { BatchNumberProductAssocationGetterProps, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS } from '../actions/batchNumberActions';
import { getBatchNumbersForDSPRFromProps } from '../selectors/batchNumberSelectors';

const number = (value: string) => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const required = (value: string) => value ? undefined : 'Required';

const renderField = ({
                         input,
                         label,
                         type,
                         autocomplete,
                         select,
                         datasource,
                         handleAutoCompleteSelected,
                         multiline,
                         style,
                         disabled,
                         key,
                         meta: {touched, error, form}
                     }) => (
    autocomplete ?
        <AutoComplete
            options={datasource}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
            className={'field'}
            disabled={disabled ? disabled : false}
            inputStyle={{minWidth: '240px'}}
            key={key}
        />
        :
        <TextField label={label}
                   type={type} {...input}
                   error={!!(touched && error)}
                   helperText={touched && error ? error : ''}
                   multiline={multiline > 0}
                   fullWidth={multiline > 0}
                   rows={multiline}
                   style={style}/>

);

interface DSPRMetrcTagOrQRCodeDownloadFormProps {
    handleMetrcTagAutoCompleteSelected: (tag: string) => any;
    handleBatchNumberAutoCompleteSelected: (batch: string) => any;
    closeRedownloadMetrcModal: () => any;
    products: { value: number, text: string }[];
    barcodeTypes: { value: string, text: string }[];
    handleProductAutoCompleteSelected: (id: number) => any;
    handleQRTypeAutoCompleteSelected: (value: string) => any;
    isMetrcDspr: boolean;
    isBatchDspr: boolean;
    fetchMetrcProductAssociation: (formValues: MetrcTagProductAssociationsGetterProps) => any;
    fetchBatchProductAssociation: (formValues: BatchNumberProductAssocationGetterProps) => any;
    isFetchingMetrcProductAssociations: boolean;
    isFetchingBatchProductAssociations: boolean;
    dsprId: string;
}

const DSPRMetrcTagOrQRCodeDownloadForm = (props: DSPRMetrcTagOrQRCodeDownloadFormProps & InjectedFormProps<{}, DSPRMetrcTagOrQRCodeDownloadFormProps>) => {
    const {
        handleMetrcTagAutoCompleteSelected,
        handleBatchNumberAutoCompleteSelected,
        closeRedownloadMetrcModal,
        handleSubmit,
        products,
        barcodeTypes,
        handleProductAutoCompleteSelected,
        handleQRTypeAutoCompleteSelected,
        isMetrcDspr,
        isBatchDspr,
        fetchMetrcProductAssociation,
        fetchBatchProductAssociation,
        isFetchingBatchProductAssociations,
        isFetchingMetrcProductAssociations,
        dsprId,
        invalid,
    } = props;

    const dispatch = useDispatch();

    const [metrcTagsForAutoSelect, setMetrcTagsForAutoSelect] = useState<AutoSelectItemStringValue[] | null>(null);
    const [batchNumbersForAutoSelect, setBatchNumbersForAutoSelect] = useState<AutoSelectItemStringValue[] | null>(null);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('Please try again');
    const [keyForMetrcAutoComplete, setKeyForMetrcAutoComplete] = useState<number>(1);
    const [keyForBatchNumberAutoComplete, setKeyForBatchNumberAutoComplete] = useState<number>(1);

    const metrcTagsForDSPR = useSelector<State, { [metrcTagId: string]: MetrcTag }>(state => getMetrcTagsForDSPRFromProps(state, {dsprId}), shallowEqual);
    const batchNumbersForDSPR = useSelector<State, { [batchNumber: string]: BatchNumber}>(state => getBatchNumbersForDSPRFromProps(state, {dsprId}), shallowEqual);
    const selectedProductId = useSelector<State, number>(getProductIdInMetrcTagOrBarcodeDownloadForm, shallowEqual);
    const selectedMetrcTag = useSelector<State, string>(getMetrcTagInMetrcTagOrBarcodeDownloadForm, shallowEqual);
    const selectedBatchNumber = useSelector<State, string>(getBatchNumberInMetrcTagOrBarcodeDownloadForm, shallowEqual)
    const selectedQRType = useSelector<State, string>(getMetrcTagDownloadSelectedQRType, shallowEqual)

    const handleFetchMetrcProductAssociation = (selectedProductId) => {
        fetchMetrcProductAssociation({productId: selectedProductId})
            .then(response => {
                if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS) {
                    const metrcTagsForProductForAutoSelect = [];
                    const metrcTagIds: number[] = response.response.result;

                    metrcTagIds.forEach(id => {
                        const metrcTag = metrcTagsForDSPR[id];
                        metrcTagsForProductForAutoSelect.push({value: metrcTag.metrcTag, text: metrcTag.metrcTag});
                    })
                    setMetrcTagsForAutoSelect(metrcTagsForProductForAutoSelect);
                }

                if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE) {
                    setShowErrorAlert(true);
                    if (response.error) {
                        setAlertText(response.error);
                    }
                }
            });
    }

    const handleFetchBatchNumberProductAssociation = (selectedProductId) => {
        fetchBatchProductAssociation({productId: selectedProductId, dsprId: parseInt(dsprId)})
            .then(response => {
                if(response.type === GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS) {
                    const batchNumbersForProductForAutoSelect = [];
                    const batchTagIds: number[] = response.response.result || [];

                    batchTagIds.forEach(id => {
                        const batchNumber = batchNumbersForDSPR[id];
                        if(!batchNumber) return null;
                        if(batchNumber.isActive) batchNumbersForProductForAutoSelect.push({value: batchNumber.batchNumber, text: batchNumber.batchNumber})
                    })

                    setBatchNumbersForAutoSelect(batchNumbersForProductForAutoSelect);
                    if (response.type === GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE) {
                        setShowErrorAlert(true);
                        if (response.error) {
                            setAlertText(response.error);
                        }
                    }
                }
            })
    }

    const handleChangeProductSelection = (formValue) => {
        if (formValue !== selectedProductId) {
            handleProductAutoCompleteSelected(formValue);
            if (isMetrcDspr) {
                dispatch(change('DSPRMetrcTagOrBarcodeDownloadForm', 'metrcTag', null));
                //force autocomplete to reload & remove previous value from input field
                setKeyForMetrcAutoComplete((keyForMetrcAutoComplete) => keyForMetrcAutoComplete += 1);

                handleFetchMetrcProductAssociation(formValue);
            }
            if (isBatchDspr) {
                dispatch(change('DSPRMetrcTagOrBarcodeDownloadForm', 'batchNumber', null));
                setKeyForBatchNumberAutoComplete((keyForMetrcAutoComplete) => keyForMetrcAutoComplete += 1);
                handleFetchBatchNumberProductAssociation(formValue);
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <h3 className={'title'}>{isMetrcDspr ? 'Download Metrc Tags' : 'Download QR Codes'}</h3>
                <Field name="quantity"
                       validate={[required, number]}
                       component={renderField}
                       label={'Label Quantity'}
                       style={{minWidth: '280px'}}
                />

                <Field name="productId"
                       validate={[required]}
                       component={renderField}
                       autocomplete={true}
                       datasource={products}
                       label="Name of Product"
                       handleAutoCompleteSelected={handleChangeProductSelection}
                />

                {isMetrcDspr &&
                <div className={'field'}>
                    <Field name={'metrcTag'}
                           validate={[required]}
                           component={renderField}
                           autocomplete={true}
                           datasource={metrcTagsForAutoSelect ? metrcTagsForAutoSelect : []}
                           label={'Metrc Tag for Product'}
                           helperText={'Enter last 4 digits of tag'}
                           handleAutoCompleteSelected={handleMetrcTagAutoCompleteSelected}
                           disabled={!selectedProductId || !metrcTagsForAutoSelect
                           || (metrcTagsForAutoSelect && metrcTagsForAutoSelect.length === 0)
                           }
                           value={selectedMetrcTag}
                           key={'metrc-autocomplete-' + keyForMetrcAutoComplete} // The key provides a way to clear the component, by remounting it when the key changes
                    />
                    <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                        Enter last 4 digits of tag
                    </Typography>
                </div>
                }

                {isMetrcDspr && !isFetchingMetrcProductAssociations && metrcTagsForAutoSelect && metrcTagsForAutoSelect.length === 0 &&
                <div>
                    <Typography>No Metrc Tags Found for Selected Product</Typography>
                </div>
                }

                {isBatchDspr &&
                    <div className={'field'}>
                        <Field name={'batchNumber'}
                           validate={[required]}
                           component={renderField}
                           autocomplete={true}
                           datasource={batchNumbersForAutoSelect ? batchNumbersForAutoSelect : []}
                           label={'Batch Number for Product'}
                           helperText={'Enter last 4 digits of tag'}
                           handleAutoCompleteSelected={handleBatchNumberAutoCompleteSelected}
                           disabled={!selectedProductId || !batchNumbersForAutoSelect
                           || (batchNumbersForAutoSelect && batchNumbersForAutoSelect.length === 0)
                           }
                           value={selectedBatchNumber}
                           key={'batch-autocomplete-' + keyForMetrcAutoComplete} // The key provides a way to clear the component, by remounting it when the key changes
                    />
                        {/* <Field name={'batchNumber'}
                            validate={[required]}
                            component={renderField}
                            label={'Batch Number'}
                            disabled={!selectedProductId}
                            value={selectedMetrcTag}
                            style={{minWidth: '280px'}}
                        /> */}
                        <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                            Please Double Check Batch Number
                        </Typography>
                    </div>
                }

                {isBatchDspr && !isFetchingBatchProductAssociations && batchNumbersForAutoSelect && batchNumbersForAutoSelect.length === 0 &&
                <div>
                    <Typography>No Batch Numbers Found for Selected Product</Typography>
                </div>
                }

                <div className={'field'}>
                    <Field name={'printLabelType'}
                        validate={[required]}
                        component={renderField}
                        autocomplete={true}
                        datasource={barcodeTypes}
                        label={'Barcode Type'}
                        helperText={'Enter a barcode Type'}
                        handleAutoCompleteSelected={handleQRTypeAutoCompleteSelected}
                        value={selectedQRType || "qrInchAndHalf"}
                        key={"codeType"} // The key provides a way to clear the component, by remounting it when the key changes
                    />
                    <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                        Select A Type
                    </Typography>
                </div>

                {/* <Field name="printLabelType" component="select" label="Label Type" validate={[required]} value="qrInchAndHalf" style={{ border: 0, width: 280}}>
                    <option value="qrInchAndHalf">QR (1.5")</option>
                    <option value="barcodeTwoThirds">Barcode (2/3")</option>
                </Field> */}

                <DialogActions className={'button-container'}>
                    <Button variant={'outlined'} color={'secondary'} onClick={closeRedownloadMetrcModal}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={invalid}>
                        {isMetrcDspr ? 'Download Tags' : 'Download QR Codes'}
                    </Button>
                </DialogActions>
            </form>

            <SnackBarAlertError
                showSnackbarAlert={showErrorAlert}
                setIsVisible={setShowErrorAlert}
                alertTitle={'Failed to Fetch Metrc Tags'}
                alertText={alertText}
            />
        </>
    )
};

export default reduxForm<any, DSPRMetrcTagOrQRCodeDownloadFormProps>({
    form: 'DSPRMetrcTagOrBarcodeDownloadForm',
})(DSPRMetrcTagOrQRCodeDownloadForm);
