import React, { useEffect, useMemo, useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    Button,
    TextField,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    RadioGroup, Grid, Typography
} from '@material-ui/core';
import AutoComplete, { AutoSelectItemAnyValue, AutoSelectItemNumberValue, AutoSelectStringValueItems } from '../components/Autocomplete';

import './DSPFormStyles.scss';
import { Address, DspProduct, DSPR, Order, OrderDetail, State, User } from "../store/reduxStoreState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getApplicableTaxesByName } from "../selectors/applicableTaxesSelectors";
import { interceptOrderDetailsForProductAutoCompleteFormSelected } from "../actions/formHelperActions";
import { getProductInOrderProductAutocompleteForm } from '../selectors/reduxFormSelectors';

const validate = (values) => {
    const errors: any = {};
    if (!values.orderDetailId) {
        errors.orderDetailId = 'Required'
    }
    if (!values.name) {
        errors.name = 'Required'
    }
    return errors
};
const required = (value: string) => value ? undefined : 'Required';

type OrderWithDetails = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User });
const renderField = ({ input, defaultSelected, label, type, items, handleAutoCompleteSelected,
    meta: { touched, error, form }, children, ...custom }) => {
    switch (type) {
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} />} />
        case 'radioGroup':
            return <RadioGroup
                {...input}
                onChange={(_, value) => input.onChange(value)}
                children={children}
            />
        case 'autoComplete':
            return <AutoComplete
                options={items}
                label={label}
                handleAutoCompleteSelected={handleAutoCompleteSelected}
                touched={touched}
                error={error}
                input={input}
                form={form}
                {...custom}
            />
        default:
            return <TextField
                label={label} type={type} {...input} {...custom} error={!!(touched && error)} helperText={touched && error ? error : ''}
            />;
    }
};


interface OrderProductAutocompleteFormProps {
    // interceptOrderDetailsForProductAutoCompleteFormSelected: (orderDetailId: AutoSelectItemNumberValue[]) => any;
    interceptOrderDetailsForProductAutoCompleteFormSelected: (orderDetailId: AutoSelectItemAnyValue[]) => any;
    handleMetrcTagAutoCompleteSelected: (tag: string) => any;
    handleBatchNumberAutoCompleteSelected: (batch: string) => any;
    handleSubmitMetrcProductLookupForm: (product: number) => any;
    handleSubmitBatchNumberProductLookupForm: (product: number) => any;
    metrcTagsForAutoSelect: AutoSelectStringValueItems;
    batchNumbersForAutoSelect: AutoSelectStringValueItems;
    isLoading: boolean;
    order: OrderWithDetails;
    orderDetails: OrderDetail[];
    dspr: DSPR;
}
const mapOrderDetailsForSelect = (orderDetails) => {
    console.log("orderDetails", orderDetails);
    return Object.keys(orderDetails).filter(key => orderDetails[key].product.isCannabisProduct)
        .map(key => orderDetails[key])
        .map(orderDetail => { return { value: orderDetail, text: orderDetail.product.name }; });
}
const OrderProductAutocompleteForm = (props: OrderProductAutocompleteFormProps & InjectedFormProps<{}, OrderProductAutocompleteFormProps>) => {
    const {
        handleSubmit,
        order,
        interceptOrderDetailsForProductAutoCompleteFormSelected,
        orderDetails,
        dspr,
        handleMetrcTagAutoCompleteSelected,
        handleBatchNumberAutoCompleteSelected,
        handleSubmitMetrcProductLookupForm,
        handleSubmitBatchNumberProductLookupForm,
        isLoading,
        metrcTagsForAutoSelect,
        batchNumbersForAutoSelect,
    } = props;
    // const [orderDetailsForSelect, setOrderDetailsForSelect] = useState<{value: any; text: string; }[]>();

    const orderDetailsForSelect = useMemo(() => {
        return mapOrderDetailsForSelect(orderDetails);
    }, [orderDetails])

    const selectedProduct = useSelector<State, DspProduct>(getProductInOrderProductAutocompleteForm, shallowEqual);
    const dispatch = useDispatch();

    useMemo(() => {
        if (selectedProduct) {
            dspr.isBatchBased ? handleSubmitBatchNumberProductLookupForm(selectedProduct.id) : handleSubmitMetrcProductLookupForm(selectedProduct.id);
        }

    }, [selectedProduct])
    return (
        <form onSubmit={handleSubmit} className="dsp-form">
            <Grid container direction={"row"}>
                <Grid item md={9}>
                    <DialogContent>
                        <Field name="orderDetailId"
                            component={renderField}
                            type="autoComplete"
                            label="Product To Scan"
                            loading={!orderDetails}
                            handleAutoCompleteSelected={orderDetails => dispatch(interceptOrderDetailsForProductAutoCompleteFormSelected(orderDetails))}
                            // handleAutoCompleteSelected={interceptOrderDetailsForProductAutoCompleteFormSelected}
                            multiple={false}
                            items={orderDetailsForSelect}
                            options={orderDetailsForSelect}
                        />
                        {(dspr.metrcLicense) &&
                            <div className={'metrc-tag-autocomplete-container'}>
                                <div className={'metrc-tag-autocomplete-field-container'}>
                                    <Field name={'metrcTag'}
                                        validate={selectedProduct && !selectedProduct.isCannabisProduct ? [] : [required]}
                                        component={renderField}
                                        type="autoComplete"
                                        disabled={!selectedProduct || (selectedProduct && !selectedProduct.isCannabisProduct)}
                                        dataLoaded={!isLoading}
                                        items={metrcTagsForAutoSelect}
                                        options={metrcTagsForAutoSelect}
                                        label={selectedProduct && !selectedProduct.isCannabisProduct ? 'Tag Not Required' : 'Metrc Tag'}
                                        handleAutoCompleteSelected={metrcTag => dispatch(handleMetrcTagAutoCompleteSelected(metrcTag))} />
                                    <Typography variant={'body2'} color={'textSecondary'}>
                                        Enter last 4 digits of tag
                                    </Typography>
                                </div>
                            </div>}

                        {(dspr.isBatchBased)
                            && <div className={'batch-number-container'}>
                                <div className={'batch-number-field-container'}>
                                    <Field name={'batchNumber'}
                                        validate={[required]}
                                        component={renderField}
                                        type="autoComplete"
                                        disabled={!selectedProduct}
                                        dataLoaded={!isLoading}
                                        items={batchNumbersForAutoSelect}
                                        options={batchNumbersForAutoSelect}
                                        label={'Batch Number'}
                                        handleAutoCompleteSelected={batchNumber => dispatch(handleBatchNumberAutoCompleteSelected(batchNumber))} />
                                    {/* <Field name={'batchNumber'}
                           validate={[required]}
                           component={renderField}
                           label={'Enter Batch Number'}
                           disabled={!selectedProduct}
                           style={{minWidth: '280px'}}/> */}
                                    <Typography variant={'body2'} color={'textSecondary'}>
                                        Please double check the batch number
                                    </Typography>
                                </div>
                            </div>}
                    </DialogContent>
                </Grid>
                <DialogActions>
                    <Grid item >
                        <Button variant='contained' color='primary'
                            style={{ maxWidth: '100px', maxHeight: '200px' }}
                            onClick={handleSubmit}>Scan</Button>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    )
};

export default reduxForm<any, OrderProductAutocompleteFormProps>({
    form: 'OrderProductAutocompleteForm', // a unique identifier for this form
    validate
})(OrderProductAutocompleteForm)