import React from 'react';
import { FixedSizeList } from 'react-window';

import './VirtualizedTableStyles.scss';

interface VirtualizedTableProps {
    renderItems: (arg: { index: number, style: React.CSSProperties }) => JSX.Element;
    itemCount: number;
    header: string[] | JSX.Element;
    maxItemsPerTable?: number;
    rowHeight?: number;
    maxHeight?: number;
    tableWidth?: string;
    containerClasses?: string | string[];
    tableClasses?: string | string[];
    headerClasses?: string | string[];
    tableContentClasses?: string | string[];
}

const joinClasses = (fixedClassName: string, additionalClassNames: string | string[]) => fixedClassName + (additionalClassNames ?
    Array.isArray(additionalClassNames) ?
        ` ${additionalClassNames.join('')}` : ` ${additionalClassNames}`
    : '');

const VirtualizedTable: React.FC<VirtualizedTableProps> = props => {
    const { renderItems, itemCount, header, rowHeight = 50, maxHeight = 400, tableWidth = '100%',
        containerClasses = '', tableClasses = '', headerClasses = '', tableContentClasses = '' } = props;

    const containerClassNames = joinClasses('virtualized-table-container', containerClasses);
    const tableClassNames = joinClasses('virtualized-table', tableClasses);
    const headerClassNames = joinClasses('', headerClasses);
    const tableContentClassNames = joinClasses('virtualized-table-content', tableContentClasses);

    const totalItemHeight = itemCount * rowHeight;
    const tableHeight = totalItemHeight > maxHeight ? maxHeight : totalItemHeight;

    return (
        <div className={containerClassNames}>
            {itemCount ? <div className={tableClassNames}>
                {Array.isArray(header) ? <header className={headerClassNames}>
                    {header.map((item: string, index: number) => <span key={item + index}>{item}</span>)}
                </header> : header}
                <div className={tableContentClassNames}>
                    <FixedSizeList height={tableHeight} itemSize={rowHeight} width={tableWidth} itemCount={itemCount}>
                        {({ index, style }) => renderItems({ index, style: { ...style, display: 'flex', alignItems: 'center' } })}
                    </FixedSizeList>
                </div>
            </div> : <p>No items</p>}
        </div>
    )
};

export default React.memo(VirtualizedTable);