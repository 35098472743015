import React, { useEffect, useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, DialogActions, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';

import "./DSPRDriverServiceAreaProfileFormStyles.scss"
const validate = (values) => {
    const errors: any = {};
    if (!values.name)
    {
        errors["name"] = "Please provide a name for the profile"
    }
    return errors;
};

const renderField = ({ input, label, type, defaultSelected, children, meta: { touched, error, value }, ...custom }) => {
    switch(type) {
        case "select":
            return <FormControl component="fieldset">
            <InputLabel id={`${input.name}label`}>{label}</InputLabel>
            <Select
                labelId={`${input.name}Label`}
                error={!!(touched && error)}
                {...input}
                onChange={(event, newValue, previousValue, name) =>  input.onChange(event.target.value)}
                {...custom}
            >{children}</Select>
            <FormHelperText>{touched && error ? error : ""}</FormHelperText>
        </FormControl>
        default:
            return (<div>
                <TextField label={label} type={type} {...input} error={!!(touched && error)} helperText={touched && error ? error : ''} />
            </div>)
    }
    
}

interface DSPRDriverServiceAreaProfileFormProps {
    onCancel: () => any;
    selectMenuItems: any;
    disableCreateProfileButton;
}

const DSPRDriverServiceAreaProfileForm = (props: DSPRDriverServiceAreaProfileFormProps & InjectedFormProps<{}, DSPRDriverServiceAreaProfileFormProps>) => {
    const { handleSubmit, onCancel, selectMenuItems, disableCreateProfileButton} = props;
    
    const [templateMenuItems, setTemplateMenuItems] = useState([])
    useEffect(()=> {
        const menuItems = []
        menuItems.push(<MenuItem button key={"no-template"} value={""} >
        No Template
    </MenuItem>)
        setTemplateMenuItems(menuItems.concat(selectMenuItems))

    }, [selectMenuItems])

    return (
        <form onSubmit={handleSubmit} className="dsprDriverServiceAreaProfileForm">
                <Field name="name" component={renderField} label="Name" />
                <Field name="template" component={renderField} type="select" label="Template">
                    {templateMenuItems}
                </Field>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disableCreateProfileButton}>Create</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPRDriverServiceAreaProfileFormProps>({
    form: 'DSPRProductPriceForm',
    validate
})(DSPRDriverServiceAreaProfileForm);
