import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button } from '@material-ui/core';
import AutoComplete from '../components/Autocomplete';

const validate = (values) => {
    const errors: any = {};
    if (!values.cashOnHand) {
        errors.cashOnHand = 'Required'
    }
    if (!values.items || !values.items.length) {
        errors.items = { _error: 'At least one Item must be added' }
    } else {
        const itemsArrayErrors = [];
        values.items.forEach((item, itemIndex) => {
            //TODO: validate product id only here once
            //TODO: validate sufficient quantity available for the dspr
            const itemErrors: any = {};
            if (!item || !item.productId) {
                itemErrors.productId = 'Required';
                itemsArrayErrors[itemIndex] = itemErrors;
            }
            if (!item || !item.quantity) {
                itemErrors.quantity = 'Required';
                itemsArrayErrors[itemIndex] = itemErrors;
            }
            return itemErrors;
        });
        if (itemsArrayErrors.length) {
            errors.items = itemsArrayErrors;
        }
    }
    return errors
};

const renderField = ({ input, label, type, autoComplete, products, handleAutoCompleteSelected, meta: { touched, error, form } }) => (
    autoComplete ?
        <AutoComplete
            options={products}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
        />
        :
        <TextField label={label} type={type} {...input} helperText={touched && error ? error : ''} error={!!(touched && error)} />
)

// const renderItems = ( {fields, meta: {touched, error}, products, handleAutoCompleteSelected }) => {
//     return (
//         <List>
//             {fields.map((item, itemIndex) =>
//                 <ListItem key={itemIndex}>
//                     <Field name={`${item}.productId`} component={renderField} autoComplete={true} products={products} label="Name of Product" handleAutoCompleteSelected={handleAutoCompleteSelected} itemIndex={itemIndex}/>
//                     <Field name={`${item}.quantity`} component={renderField} label="Quantity of Product" />
//                     <RaisedButton secondary={true} icon={<ContentRemove/>} className="raised-button" label="Remove Item" onTouchTap={() => fields.remove(itemIndex)} />
//                 </ListItem>
//             )}
//             <ListItem>
//                 <Subheader>Inventory Items</Subheader>
//                 <RaisedButton icon={<ContentAdd />} className="raised-button" label="Add Inventory Item" onTouchTap={() => fields.push({})} />
//                 {touched && error && <span>{error}</span>}
//             </ListItem>
//         </List>
//
//     )
// };

const DriverInventoryPeriodForm = (props: InjectedFormProps) => {
    // const {handleSubmit, products, handleAutoCompleteSelected} = props;
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit} className="driver-inventory-form">
            <Field name="cashOnHand" component={renderField} label="Initial Cash On Hand" />
            {/*<FieldArray name="items" component={renderItems} products={products} handleAutoCompleteSelected={handleAutoCompleteSelected}/>*/}
            <Button variant="contained" color="primary" className="raised-button" onClick={handleSubmit}>Create New Inventory Period</Button>
        </form>
    )
};

export default reduxForm({
    form: 'DriverInventoryPeriodForm',
    validate
})(DriverInventoryPeriodForm);