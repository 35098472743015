import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, Typography, } from '@material-ui/core';

import AutoComplete, { AutoSelectNumberValueItems, AutoSelectStringValueItems } from './Autocomplete';
import './DSPRMetrcTagProductLookupFormStyles.scss';
import {
    interceptBatchNumberForProductLookupFormAutoCompleteSelected,
    interceptMetrcTagForProductLookupFormAutoCompleteSelected,
    interceptProductForProductLookupFormAutocompleteSelected
} from '../actions/formHelperActions';

const renderField = ({
    input,
    label,
    type,
    autocomplete,
    datasource,
    handleAutoCompleteSelected,
    multiline,
    style,
    meta: { touched, error, form },
}) => (
    autocomplete ?
        <AutoComplete
            options={datasource}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
            className={'field'}
            inputStyle={{ minWidth: '240px' }}
        />
        :
        <TextField label={label}
            type={type} {...input}
            error={!!(touched && error)}
            helperText={touched && error ? error : ''}
            multiline={multiline > 0}
            fullWidth={multiline > 0}
            rows={multiline}
            style={style}
        />
);

interface DSPRMetrcTagProductLookupFormProps {
    metrcTagsForAutoSelect: AutoSelectStringValueItems;
    batchNumbersForAutoSelect: AutoSelectStringValueItems;
    productsForAutoSelect: AutoSelectNumberValueItems;
    handleProductAutoCompleteSelected: (id: number) => void;
    handleMetrcTagAutoCompleteSelected: (tag: string) => void;
    handleBatchNumberAutoCompleteSelected: (batch: string) => void;
    metrcTagValueInMetrcTagProductLookupForm: string;
    batchNumberValueInMetrcTagProductLookupForm: string;
    productValueInMetrcTagProductLookupForm: number;
    isFetchingMetrcProductAssociations: boolean;
    isFetchingBatchNumberProductAssociations: boolean;
    isBatchBased: boolean,
    isMetrcDspr: boolean
}

const DSPRMetrcTagProductLookupForm = ({
    metrcTagsForAutoSelect,
    batchNumbersForAutoSelect,
    productsForAutoSelect,
    handleProductAutoCompleteSelected,
    handleMetrcTagAutoCompleteSelected,
    handleBatchNumberAutoCompleteSelected,
    metrcTagValueInMetrcTagProductLookupForm,
    batchNumberValueInMetrcTagProductLookupForm,
    productValueInMetrcTagProductLookupForm,
    handleSubmit,
    isFetchingMetrcProductAssociations,
    isFetchingBatchNumberProductAssociations,
    isBatchBased,
    isMetrcDspr
}: DSPRMetrcTagProductLookupFormProps & InjectedFormProps<{}, DSPRMetrcTagProductLookupFormProps>) => {

    const dispatch = useDispatch();

    const metrcTagsForValidate = useMemo(() => {
        const metrcTagsMap = {};
        if (metrcTagsForAutoSelect.length > 0) {
            metrcTagsForAutoSelect.forEach(tag => {
                metrcTagsMap[tag.text] = tag.text;
            })
        }
        return metrcTagsMap;
    }, [metrcTagsForAutoSelect]);

    const batchNumbersForValidate = useMemo(() => {
        const batchNumbersMap = {};
        if (batchNumbersForAutoSelect.length > 0) {
            batchNumbersForAutoSelect.forEach(tag => {
                batchNumbersMap[tag.text] = tag.text;
            })
        }
        return batchNumbersMap;
    }, [batchNumbersForAutoSelect]);

    const productsForValidate = useMemo(() => {
        const productsMap = {};
        if (productsForAutoSelect.length > 0) {
            productsForAutoSelect.forEach(product => {
                productsMap[product.value] = product.text
            })
        }
        return productsMap;
    }, [productsForAutoSelect])

    const metrcValidate = useCallback((value) => {
        console.log("Metrc Validate", value, metrcTagsForValidate)
        if (!(value in metrcTagsForValidate)) {
            dispatch(interceptMetrcTagForProductLookupFormAutoCompleteSelected(null));
        }
        return undefined
    }, [metrcTagsForValidate, dispatch]);

    const batchValidate = useCallback((value) => {
        if (!(value in batchNumbersForValidate)) {
            dispatch(interceptBatchNumberForProductLookupFormAutoCompleteSelected(null));
        }
        return undefined
    }, [batchNumbersForValidate, dispatch]);

    const productValidate = useCallback((value) => {
        if (!(value in productsForValidate)) {
            dispatch(interceptProductForProductLookupFormAutocompleteSelected(null));
        }
        return undefined;
    }, [productsForValidate, dispatch]);

    return (
        <form onSubmit={handleSubmit}>
            <Field name="productId"
                component={renderField}
                autocomplete={true}
                datasource={productsForAutoSelect}
                label="Name of Product"
                handleAutoCompleteSelected={handleProductAutoCompleteSelected}
                validate={[productValidate]}
                required={false}
            />

            {isMetrcDspr && <div className={'field'}>
                <Field name={'metrcTag'}
                    component={renderField}
                    autocomplete={true}
                    datasource={metrcTagsForAutoSelect}
                    label={'Metrc Tag'}
                    helperText={'Enter last 4 digits of tag'}
                    handleAutoCompleteSelected={handleMetrcTagAutoCompleteSelected}
                    validate={[metrcValidate]}
                    required={false}
                />
                <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                    Enter last 4 digits of tag
                </Typography>
            </div>}

            {isBatchBased && <div className={'field'}>
                <Field name={'batchNumber'}
                    component={renderField}
                    autocomplete={true}
                    datasource={batchNumbersForAutoSelect}
                    label={'Batch Number'}
                    helperText={'Please Enter the Batch Number'}
                    handleAutoCompleteSelected={handleBatchNumberAutoCompleteSelected}
                    validate={[batchValidate]}
                    required={false}
                />
                <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                    Please Enter the Batch Number
                </Typography>
            </div>}

            <div className='submit-button-container'>
                <Button
                    disabled={isFetchingBatchNumberProductAssociations || isFetchingMetrcProductAssociations}
                    size={'small'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>
        </form>
    )
}

export default reduxForm<any, DSPRMetrcTagProductLookupFormProps>({
    form: 'DSPRMetrcTagProductLookupForm',
})(DSPRMetrcTagProductLookupForm);