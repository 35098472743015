import { CALL_API, Schemas } from '../middleware/api';
import { getDSP } from './dspActions';
import { DspProductCategory } from '../store/reduxStoreState';

export const GET_ALL_PRODUCTS_FOR_DSP = 'GET_ALL_PRODUCTS_FOR_DSP';
export const GET_ALL_PRODUCTS_FOR_DSP_SUCCESS = 'GET_ALL_PRODUCTS_FOR_DSP_SUCCESS';
export const GET_ALL_PRODUCTS_FOR_DSP_FAILURE = 'GET_ALL_PRODUCTS_FOR_DSP_FAILURE';

const ENDPOINT = 'product';

const allProductsForDSPGetter = (dspId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ALL_PRODUCTS_FOR_DSP, GET_ALL_PRODUCTS_FOR_DSP_SUCCESS, GET_ALL_PRODUCTS_FOR_DSP_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.DSP_PRODUCT_ARRAY,
            queryParamsMap: { delivery_service_provider_id: dspId }
        }
    }
};

export const getAllProductsForDSP = (dspId) => (dispatch, getState) => {
    return dispatch(allProductsForDSPGetter(dspId));
};

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

const productGetter = (productId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_PRODUCT, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
            endPoint: `${ENDPOINT}/${productId}`,
            schema: Schemas.DSP_PRODUCT
        }
    }
};

export const getProduct = (productId) => (dispatch, getState) => {
    return dispatch(productGetter(productId));
};

export const GET_PRODUCT_BY_SEARCH = 'GET_PRODUCT_BY_SEARCH';
export const GET_PRODUCT_BY_SEARCH_SUCCESS = 'GET_PRODUCT_BY_SEARCH_SUCCESS';
export const GET_PRODUCT_BY_SEARCH_FAILURE = 'GET_PRODUCT_BY_SEARCH_FAILURE';

const productSearcher = (dspId, flowerTypes, productCategories, partialName) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_PRODUCT_BY_SEARCH, GET_PRODUCT_BY_SEARCH_SUCCESS, GET_PRODUCT_BY_SEARCH_FAILURE],
            endPoint: `${ENDPOINT}/filter`,
            schema: Schemas.DSP_PRODUCT_SEARCH_ARRAY,
            queryParamsMap: {
                delivery_service_provider_id: dspId,
                flower_types: flowerTypes ? flowerTypes.join() : null,
                product_categories: productCategories ? productCategories.join() : null,
                name: partialName
            }
        }
    }
};

export const searchProduct = (dspId, flowerTypes, productCategories, partialName) => (dispatch, getState) => {
    return dispatch(productSearcher(dspId, flowerTypes, productCategories, partialName));
};

export const CREATE_DSP_PRODUCT = 'CREATE_DSP_PRODUCT';
export const CREATE_DSP_PRODUCT_SUCCESS = 'CREATE_DSP_PRODUCT_SUCCESS';
export const CREATE_DSP_PRODUCT_FAILURE = 'CREATE_DSP_PRODUCT_FAILURE';

const productCreatorUpdator = (dspId, name, brandId, isActive, flowerType, productType, isFlower, isExcludedFromCoupons, categories, imageFile, description,
    thcPercentage, cbdPercentage, cbnPercentage, thcMg, cbdMg, isCannabisProduct, id?) => {

    const product = {
        deliveryServiceProvider: {
            id: dspId
        },
        name: name,
        brand: brandId ? {id: brandId} : undefined,
        isActive,
        isFlower,
        productType,
        isExcludedFromCoupons,
        productCategories: categories.map(id => { return { id }; }),
        flowerType,
        description,
        thcPercentage,
        cbdPercentage,
        cbnPercentage,
        thcMg,
        cbdMg,
        isCannabisProduct,
        id
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_DSP_PRODUCT, CREATE_DSP_PRODUCT_SUCCESS, CREATE_DSP_PRODUCT_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.DSP_PRODUCT,
            body: product,
            file: imageFile
        }
    }
};

export const createOrUpdateProduct = (dspId, name, brandId, isActive, flowerType, productType, isFlower, isExcludedFromCoupons, categories, imageFile, description,
    thcPercentage, cbdPercentage, cbnPercentage, thcMg, cbdMg, isCannabisProduct, id?) => (dispatch, getState) => {
        return dispatch(productCreatorUpdator(dspId, name, brandId, isActive, flowerType, productType, isFlower, isExcludedFromCoupons, categories, imageFile, description,
            thcPercentage, cbdPercentage, cbnPercentage, thcMg, cbdMg, isCannabisProduct, id))
            .then(response => {
                if (response.type === "CREATE_DSP_PRODUCT_SUCCESS") {
                    return dispatch(getProduct(response.response.result));
                } else {
                    return response;
                }
            });
    };

export const CREATE_NEW_CATEGORY = 'CREATE_NEW_CATEGORY';
export const CREATE_NEW_CATEGORY_SUCCESS = 'CREATE_NEW_CATEGORY_SUCCESS';
export const CREATE_NEW_CATEGORY_FAILURE = 'CREATE_NEW_CATEGORY_FAILURE';

const categoryCreator = (dspId, values, imageFile?) => {
    const category = {
        deliveryServiceProvider: {
            id: dspId
        },
        name: values.name,
        id: values.id
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_NEW_CATEGORY, CREATE_NEW_CATEGORY_SUCCESS, CREATE_NEW_CATEGORY_FAILURE],
            endPoint: `${ENDPOINT}/category`,
            schema: Schemas.DSP_PRODUCT_CATEGORY,
            body: category,
            file: imageFile || undefined
        }

    }
};

export const createCategory = (dspId: number, values: any, imageFile?) => (dispatch, getState) => {
    return dispatch(categoryCreator(dspId, values, imageFile))
        .then(() => dispatch(getDSP(dspId)));
};

export const GET_PRODUCT_CATEGORIES_FOR_DSP = "GET_PRODUCT_CATEGORIES_FOR_DSP";
export const GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS = "GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FOR_DSP_FAILURE = "GET_PRODUCT_CATEGORIES_FOR_DSP_FAILURE";

const getProductCategoriesForDSP = (dspId: number) => {
   const queryParamsMap = { delivery_service_provider_id: dspId}
   return {
       [CALL_API]: {
           httpAction: "GET",
           types: [GET_PRODUCT_CATEGORIES_FOR_DSP, GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS, GET_PRODUCT_CATEGORIES_FOR_DSP_FAILURE],
           endPoint: `${ENDPOINT}/category`,
           schema: Schemas.DSP_PRODUCT_CATEGORY_ARRAY,
           queryParamsMap
       }
   }
}

export const getAllProductCategoriesForDSP = (dspId:number) => (dispatch) => {
    return dispatch(getProductCategoriesForDSP(dspId))
}

export const GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER = "GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER";
export const GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS = "GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS";
export const GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE = "GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE"; 

const getProductCategoriesForDSPRWithOrder = (dsprId: number) => {
    const queryParamsMap = { dspr_id: dsprId }
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER, GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS, GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE],
            endPoint: `${ENDPOINT}/dspr-category-order`,
            schema: Schemas.DSP_PRODUCT_CATEGORY_ARRAY,
            queryParamsMap
        }
    }
}

export const getAllProductCategoriesForDSPRWithOrder = (dsprId: number) => dispatch => {
    return dispatch(getProductCategoriesForDSPRWithOrder(dsprId))
}

export const POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER = "POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER";
export const POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS = "POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS";
export const POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE = "POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE";

const updateProductCategoryOrderForDSPR = (dsprId: number,  updatedProductCategoryOrder: DspProductCategory[]) => {
    const formattedPorductCategories = updatedProductCategoryOrder.map(productCategoryOrder => {
        return {...productCategoryOrder, deliveryServiceProvider: { id: productCategoryOrder.deliveryServiceProvider}}
    })
    const body = { dsprId: dsprId, productCategories: formattedPorductCategories }
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER, POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS, POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_FAILURE],
            endPoint: `${ENDPOINT}/dspr-category-order`,
            schema: Schemas.DSP_PRODUCT_CATEGORY_ARRAY,
            body
        }
    }
}

export const updateAllProductCategoryOrderForDSPR = (dsprId: number, updatedValues: DspProductCategory[]) => dispatch => {
    return dispatch(updateProductCategoryOrderForDSPR(dsprId, updatedValues))
}