import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import Dropzone from 'react-dropzone';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { API_HOST } from "../middleware/api";
import { addDsprAwayMessage, ADD_DSPR_AWAY_MESSAGE_SUCCESS, ADD_DSPR_AWAY_MESSAGE_FAILURE } from '../actions/dsprActions';
import { getAwayMessageForDSPRWithProps } from '../selectors/dsprSelectors';
import { DSPR, State } from '../store/reduxStoreState';

import AutoComplete from '../components/Autocomplete';
import './DSPRFormStyles.scss';

const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field) => {
    const { input: { value: files, onChange }, meta: { touched, error } } = field;
    const imgsrc = files !== '' ? typeof files === 'string' ? API_HOST + files : URL.createObjectURL(files[0]) : undefined;
    return (
        <Fragment>
            <Dropzone
                onDrop={filesToUpload => onChange(filesToUpload)}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        {imgsrc && <img src={imgsrc} alt="Preview" style={{ objectFit: 'contain' }} width="300px" height="200px" />}
                        <div {...getRootProps()} className="drop-zone">
                            <Fragment>
                                <input {...getInputProps()} />
                                <Button color='primary'>Select an image to upload.</Button>
                            </Fragment>
                        </div>
                    </section>
                )}
            </Dropzone>
            {touched && error && <span className="error">{error}</span>}
        </Fragment>
    );
};

const validate = (values) => {
    const errors: any = {};
    if (!values.userId) errors.userId = 'Required';
    if (!values.dsprName) errors.dsprName = 'Required';
    if (values.minimumOrderSize === undefined) errors.minimumOrderSize = 'Please enter a minimum order size'; // the falsy 0 is valid
    if (!values.numberOrdersPerRoute) errors.numberOrdersPerRoute = 'Please specify how many orders a route can have';

    if(values.numberOrdersPerRoute <= 0 || values.numberOrdersPerRoute > 10) errors.numberOrdersPerRoute = "Number orders per route must be between 1 and 10"
    
    if (!values.centralLatitude) {
        errors.centralLatitude = 'Please enter a latitude to center the map on';
    } else if (values.centralLatitude > 90 || values.centralLatitude < -90) {
        errors.centralLatitude = "Invalid Input, Latitudes range from -90 to 90"
    }

    if (!values.centralLongitude) {
        errors.centralLongitude = 'Please enter a longitude to center the map on';
    } else if (values.centralLongitude > 180 || values.centralLongitude < -180) {
        errors.centralLongitude = "Invalid Input, Longitudes range from -180 to 180"
    }

    return errors;
};

const renderField = ({ input, label, type, autocomplete, users, handleAutoCompleteSelected, meta: { touched, error, form }, ...custom }) => {
    return autocomplete ?
        <AutoComplete
            options={users}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            form={form}
            error={error}
            input={input}
            inputStyle={{ minWidth: '200px' }}
            {...custom}
        />
        :
        <TextField label={label} type={type} {...custom} {...input} error={!!(touched && error)} helperText={touched && error ? error : ''} />
}

interface DSPRFormProps {
    onCancel: () => void;
    users: { value: number, text: string }[];
    handleAutoCompleteSelected: (id: number) => any;
    addManagerMode?: boolean;
    usersLoaded?: boolean;
    editMode?: boolean
    dspr?: DSPR;
}

const DSPRForm: React.FC<DSPRFormProps & InjectedFormProps<any, DSPRFormProps>> = props => {
    const { handleSubmit, onCancel, addManagerMode, users, handleAutoCompleteSelected, editMode, usersLoaded, initialValues, dspr } = props;

    const currentAwayMessage = useSelector<State, string>(state => {
        return dspr ? getAwayMessageForDSPRWithProps(state, { dsprId: dspr.id }) : '';
    }, shallowEqual) || '';
    const dispatch = useDispatch();

    const [enableAwayMessageSubmitButton, setEnableAwayMessageSubmitButton] = useState(true);
    const [showAwayMessageSuccessModal, setShowAwayMessageSuccessModal] = useState(false);
    const [showAdvancedSettingsModal, setShowAdvancedSettingsModal] = useState(false);
    const [awayMessageContent, setAwayMessageContent] = useState(currentAwayMessage);
    const [awayMessageError, setAwayMessageError] = useState(null);


    const submitAwayMessage = () => {
        setEnableAwayMessageSubmitButton(false);
        dispatch<any>(addDsprAwayMessage(awayMessageContent, dspr.id))
            .then(response => {
                if (response.type === ADD_DSPR_AWAY_MESSAGE_SUCCESS) {
                    setShowAwayMessageSuccessModal(true);
                    setShowAdvancedSettingsModal(false);
                    setEnableAwayMessageSubmitButton(true);
                    setTimeout(() => setShowAwayMessageSuccessModal(false), 2000); // autoclose after 2 seconds
                } else if (response.type === ADD_DSPR_AWAY_MESSAGE_FAILURE) {
                    setAwayMessageError(response.error || 'An error occurred when setting the away message. Please try again.');
                    setEnableAwayMessageSubmitButton(true);
                }
            });
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="dspr-form">
                <DialogContent>
                    {!addManagerMode && <Field name={FILE_FIELD_NAME} component={renderDropzoneInput} type="dropzone" label="Upload Image" />}
                    {(!addManagerMode || editMode) && <Field name="dsprName" type="text" component={renderField} label="Name" className="field" />}
                    {!addManagerMode && <Field name="displayName" type="text" component={renderField} label="Display Name" className="field" />}
                    {!editMode && <Field
                        name="userId"
                        component={renderField}
                        autocomplete={true}
                        users={users}
                        label="Name of Manager"
                        handleAutoCompleteSelected={handleAutoCompleteSelected}
                        dataLoaded={usersLoaded}
                        className="field"
                    />}
                    {(!addManagerMode || editMode) && <Field name="minimumOrderSize" type="number" component={renderField} label="Minimum Order Size" className="field half" />}
                    {(!addManagerMode || editMode) && <Field name="numberOrdersPerRoute" type="number" component={renderField} label="Orders Per Route" className="field half"/>}
                    {(!addManagerMode || editMode) && <Field name="centralLatitude" type="number" component={renderField} label="Central Latitude" className="field half" />}
                    {(!addManagerMode || editMode) && <Field name="centralLongitude" type="number" component={renderField} label="Central Longitude" className="field half" />}

                    {(!addManagerMode || editMode) && <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAdvancedSettingsModal(true)}
                    >
                        Configure Closed Message
                    </Button>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>{editMode ? initialValues ? 'Submit' : 'Create' : 'Add'}</Button>
                </DialogActions>
            </form>

            {showAwayMessageSuccessModal && <SweetAlert
                success
                style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
                title="Away Message Updated"
                onConfirm={() => setShowAwayMessageSuccessModal(false)}
                showConfirm={false}
            >
                The away message have been successfully updated!
            </SweetAlert>}
            <Dialog
                open={showAdvancedSettingsModal}
                onClose={() => setShowAdvancedSettingsModal(false)}
                className="dspr-advanced-settings-form"
            >
                <DialogTitle>Advanced Settings</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Away Message"
                        className="away-message-input"
                        value={awayMessageContent}
                        onChange={e => setAwayMessageContent(e.target.value)}
                        multiline
                    />
                    {awayMessageError && <p style={{ color: '#f82030' }}>{awayMessageError}</p>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setShowAdvancedSettingsModal(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!enableAwayMessageSubmitButton}
                        onClick={() => submitAwayMessage()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
};

export default reduxForm<any, DSPRFormProps>({
    form: 'DSPRForm', // a unique identifier for this form
    validate,
})(DSPRForm);