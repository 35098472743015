import { createSelector } from 'reselect';
import { State, DSPRProductInventoryTransactionHistory } from '../store/reduxStoreState';
import { getProductsForDSPAsMap } from './dspProductSelector';

export const getDSPRProductInventoryTransactions = (
	state: State,
): { [p: string]: { [p: number]: DSPRProductInventoryTransactionHistory } } =>
	state.api.entities.dsprProductInventoryTransactionHistories;

// export const getDSPRProductInventoryTransactionHistoryiesByInvoiceNumber = (
// 	state: State,
// 	props: { invoiceNumber: string },
// ): DSPRProductInventoryTransactionHistory[] => {
// 	const transactions = getDSPRProductInventoryTransactions(state);
// 	if (!transactions || !transactions[props.invoiceNumber]) return [];
// 	return Object.values(transactions[props.invoiceNumber]);
// };

// // Expects invoiceNumber and dspId to be in props
// export const getDSPRProductInventoryTransactionHistoryiesByInvoiceNumberWithProduct = createSelector(
// 	[getDSPRProductInventoryTransactionHistoryiesByInvoiceNumber, getProductsForDSPAsMap],
// 	(transactions, products) => {
// 		console.log('Getting products for invoice', transactions, products);
// 		if (!transactions || !products) return [];
// 		return transactions.map((transaction) => {
// 			return { ...transaction, product: products[transaction.product] };
// 		});
// 	},
// );
