import { createSelector } from 'reselect';
import { getDSPFromProps } from './dspSelectors';
import { State } from '../store/reduxStoreState';
import { getAllBrandsForDSPFromProps } from './brandSelectors';

export const getDSPProducts = (state: State) => {
    return state.api.entities.dspProducts;
};

export const getSearchProducts = (state: State) => {
    return state.api.entities.searchProducts;
};

export const getDSPProductFromProps = (state: State, props) => {
    return state.api.entities.dspProducts && props && props.productId ?
        state.api.entities.dspProducts[props.productId] :
        null;
};

export const getProductsForDSP = createSelector(
    [getDSPFromProps, getDSPProducts], (deliveryServiceProvider, dspProducts) => {
        return deliveryServiceProvider ?
            deliveryServiceProvider.products ?
                dspProducts ?
                    deliveryServiceProvider.products.map(productId => dspProducts[productId])
                    : []
                : []
            : undefined;
    }
);


export const getProductsForDSPAsMap = createSelector(
    [getDSPFromProps, getDSPProducts], (deliveryServiceProvider, dspProducts) => {
        const productsMap = {};
        if (deliveryServiceProvider && deliveryServiceProvider.products && dspProducts) {
            deliveryServiceProvider.products.forEach(productId => {
                productsMap[productId] = dspProducts[productId]
            })
        } else {
            return undefined;
        }

        return productsMap;
    }
);

export const getProductsForDSPForAutoSelect = createSelector(
    [getProductsForDSP], (products) => {
        return products ? products.map(product => { return { value: product.id, text: product.name } }) : [];
    }
);

/**Returns an object with brandId as key and array of product objects not assigned to brand as the value
 * 
 * { brandId: [{ value: product.id, text: product.name },... ] }
 */
export const getProductsForDSPForBrandAssignmentForAutoSelect = createSelector(
    [getProductsForDSP, getAllBrandsForDSPFromProps], (products, brands) => {
        const productsForAutoSelectMap = {};

        if (products && brands) {
            brands.forEach(brand => productsForAutoSelectMap[brand.id] = 
                brand.products 
                ? products.filter(product => !(brand.products.includes(product.id))).map(product => { return { value: product.id, text: product.name } })
                : []
                )
        } else {
            return undefined;
        }

        return productsForAutoSelectMap;
    }
);

export interface AutoCompleteProduct {
    value?: number;
    text: string;
}
export interface ProductsForBrandsAssignment {
    [brandId: string]: Array<AutoCompleteProduct>
}


/**Returns an object in which the key is a brandId and the value is an array of product objects belonging to the brand
 * 
 * { brandId: [{productId, name, isActive},...]}
 */
export const getProductsForDSPBrands = createSelector(
    [getDSPProducts, getAllBrandsForDSPFromProps], (products, brands) => {
        const brandsProductsMap = {};
        if (products && brands) {
            brands.forEach(brand => brandsProductsMap[brand.id] = 
                brand.products 
                    ? brand.products.map(productId => { return { id: productId, name: products[productId].name, isActive: products[productId].isActive} })
                    : []
                );
        } else {
            return undefined;
        }

        return brandsProductsMap;
    }
);

export interface BrandProduct {
    productId?: number;
    name?: string;
    isActive?: boolean;
}

export interface ProductsForBrands {
    [key: string]: Array<BrandProduct>;
}


export const getDSPProductCategories = (state: State) => state.api.entities.dspProductCategories;

export const getProductCategoriesForDSP = createSelector(
    [getDSPFromProps, getDSPProductCategories], (dsp, categories) => {
        return dsp ?
            categories ?
                dsp.productCategories ?
                    dsp.productCategories.map(id => categories[id])
                    : []
                : undefined
            : undefined;
    }
);

export const getProductCategoriesForDSPFromProps = createSelector(
    [getDSPFromProps, getDSPProductCategories], (dsp, categories) => {
        return dsp ?
            categories ?
                Object.values(categories).filter(category => category.deliveryServiceProvider === dsp.id)
                : undefined
            : undefined
    }
)