import { SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE, UPDATE_DSPR_PRODUCT_CATEGORY_ORDER, CANCEL_DSPR_PRODUCT_CATEGORY_ORDER_UPDATE } from '../containers/DSPRMenuManagementContainer'
import { LOAD_DSPR_DRIVER_SERVICE_AREAS_INTO_COMPONENT_STATE, CREATE_LOCAL_DSPR_DRIVER_SERVICE_AREA } from "../containers/DSPRServiceAreaContainer"
import {merge} from 'lodash'
import { CREATE_NEW_DSPR_DRIVER_ROUTE, CREATE_NEW_DSPR_DRIVER_ROUTE_FAILURE, CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS, DEACTIVATE_DSPR_DRIVER_ROUTE, DEACTIVATE_DSPR_DRIVER_ROUTE_FAILURE, DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS, PROGRESS_DSPR_DRIVER_ROUTE, PROGRESS_DSPR_DRIVER_ROUTE_FAILURE, PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS } from '../actions/driverActions';
import { COMPLETE_ORDER, COMPLETE_ORDER_SUCCESS, COMPLETE_ORDER_FAILURE } from '../actions/orderActions';

const initialState = { dsprMenuManagementContainer: {}, dsprServiceAreaManagementContainer: {}, dsprDriverPage: {}};

const appendAndUpdateEntitiesFromResponse = (oldState, responseEntities, skipEntityTypes: string[] = []) => {
    let newState = merge({}, oldState);
    Object.keys(responseEntities).forEach(entityType => {
        if (skipEntityTypes.includes(entityType)) return;
        let oldStateTypeEntities = oldState[entityType];
        let entitiesInResponse = responseEntities[entityType];
        newState[entityType] = merge(oldStateTypeEntities, entitiesInResponse);
    });
    return newState;
};

export default (state = initialState, action) =>  {
    switch (action.type) {
        case CREATE_NEW_DSPR_DRIVER_ROUTE:
        case DEACTIVATE_DSPR_DRIVER_ROUTE:
        case PROGRESS_DSPR_DRIVER_ROUTE:
        case COMPLETE_ORDER:
            return {...state, dsprDriverPage: { ...state.dsprDriverPage, loading: true } }
        case CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS:
        case CREATE_NEW_DSPR_DRIVER_ROUTE_FAILURE:
        case DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS:
        case DEACTIVATE_DSPR_DRIVER_ROUTE_FAILURE:
        case PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS:
        case PROGRESS_DSPR_DRIVER_ROUTE_FAILURE:
        case COMPLETE_ORDER_SUCCESS:
        case COMPLETE_ORDER_FAILURE:
            return {...state, dsprDriverPage: { ...state.dsprDriverPage, loading: false }}
        case SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE: 
        case UPDATE_DSPR_PRODUCT_CATEGORY_ORDER:
            return { ...state, dsprMenuManagementContainer: appendAndUpdateEntitiesFromResponse(state.dsprMenuManagementContainer, action.entities) }
        case LOAD_DSPR_DRIVER_SERVICE_AREAS_INTO_COMPONENT_STATE:
        case CREATE_LOCAL_DSPR_DRIVER_SERVICE_AREA:
            return { ...state, dsprServiceAreaManagementContainer: appendAndUpdateEntitiesFromResponse(state.dsprServiceAreaManagementContainer, action.entities)}
        case CANCEL_DSPR_PRODUCT_CATEGORY_ORDER_UPDATE:
            return {...state, dsprMenuManagementContainer: appendAndUpdateEntitiesFromResponse( {...state.dsprMenuManagementContainer, dspProductCategories: {}}, action.entities)}
        default: 
            return state;
    }
}