import { API_ROOT, CALL_API, Schemas } from '../middleware/api';

export const GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS = 'GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS';
export const GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS = 'GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS';
export const GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE = 'GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE';

export interface BatchNumberProductAssocationGetterProps {
    batchNumber?: string;
    productId?: number; 
    activeOnly?: boolean
    dsprId: number
}

const dsprBatchNumberProductAssociationsGetter = ({batchNumber, productId, dsprId, activeOnly}) => {
        const queryParamsMap = { batchNumber, productId, dsprId, activeOnly };
        
        for (let key in queryParamsMap) {
            if (!queryParamsMap[key]) {
                delete queryParamsMap[key];
            }
        }

        return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS, GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_FAILURE],
            endPoint: `batches`,
            schema: Schemas.DSPR_BATCH_NUMBER_ARRAY,
            queryParamsMap
        }
    }
}

export const getBatchNumberProductAssociations = ({batchNumber, productId, dsprId, activeOnly}: BatchNumberProductAssocationGetterProps) => (dispatch) => {
    return dispatch(dsprBatchNumberProductAssociationsGetter({batchNumber, productId, dsprId, activeOnly}));
}