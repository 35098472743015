import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import CardFooter from "../../components/Card/CardFooter";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import { connect } from "react-redux";

import { getLoggedInUser } from "../../selectors/userSelectors";
import { attemptLogin } from "../../actions/oauthActions";
import { history } from "../../index";

const useStyles = makeStyles(styles);

function LoginPage(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const { loggedInUser, attemptLogin } = props;

    useEffect(() => {
        if (loggedInUser) history.push('/');
    });

    useEffect(() => {
        const timeout = setTimeout(function () {
            setCardAnimation("");
        }, 700);

        return () => clearTimeout(timeout);
    }, []);

    const classes = useStyles({});

    const handleSubmit = () => {
        attemptLogin(username, password, '/');
    }

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Log in</h4>
                                {/* <div className={classes.socialLine}>
                                {[
                                    "fab fa-facebook-square",
                                    "fab fa-twitter",
                                    "fab fa-google-plus"
                                ].map((prop, key) => {
                                    return (
                                    <Button
                                        color="transparent"
                                        justIcon
                                        key={key}
                                        className={classes.customButtonClass}
                                    >
                                        <i className={prop} />
                                    </Button>
                                    );
                                })}
                                </div> */}
                            </CardHeader>
                            <CardBody>
                                {/* <CustomInput
                                labelText="First Name.."
                                id="firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Face className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                    )
                                }}
                                /> */}
                                <CustomInput
                                    labelText="Email..."
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                        onChange: e => setUsername(e.target.value),
                                    }}
                                    value={username}
                                />
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                        onChange: e => setPassword(e.target.value),
                                    }}
                                    value={password}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" block onClick={() => handleSubmit()}>
                                    Let{"'"}s Go
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { errorMessage } = state.api;
    const loggedInUser = getLoggedInUser(state);
    return { ...ownProps, loggedInUser, errorMessage };
}

const mapDispatchToProps = { attemptLogin };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);