import { DspProduct, State } from '../store/reduxStoreState';

export const getProductInMetrcTagProductLookupForm = (state: State): number => {
    if (state && state.form && state.form.DSPRMetrcTagProductLookupForm && state.form.DSPRMetrcTagProductLookupForm.values) {
        return state.form.DSPRMetrcTagProductLookupForm.values['productId']
    } else {
        return null;
    }
}

export const getMetrcTagInMetrcTagProductLookupForm = (state: State): string => {
    if (state && state.form && state.form.DSPRMetrcTagProductLookupForm && state.form.DSPRMetrcTagProductLookupForm.values) {
        return state.form.DSPRMetrcTagProductLookupForm.values['metrcTag']
    } else {
        return null;
    }
}

export const getShowActiveOnlyInMetrcTagProductLookupForm = (state: State): boolean => {
    if (state && state.form && state.form.DSPRMetrcTagProductLookupForm && state.form.DSPRMetrcTagProductLookupForm.values) {
        return state.form.DSPRMetrcTagProductLookupForm.values['showActiveOnly']
    } else {
        return false;
    }
}

export const getMetrcTagInMetrcTagModifyProductTagForm = (state: State): string => {
    if (state && state.form && state.form.DSPRModifyProductMetrcTagForm && state.form.DSPRModifyProductMetrcTagForm.values) {
        return state.form.DSPRModifyProductMetrcTagForm.values['metrcTag']
    } else {
        return null;
    }
}

export const getProductIdInMetrcTagOrBarcodeDownloadForm = (state: State): number => {
    if (state && state.form && state.form.DSPRMetrcTagOrBarcodeDownloadForm && state.form.DSPRMetrcTagOrBarcodeDownloadForm.values) {
        return state.form.DSPRMetrcTagOrBarcodeDownloadForm.values['productId'];
    } else {
        return null;
    }
}

export const getProductInOrderProductAutocompleteForm = (state:State): DspProduct => {
    if(state && state.form && state.form.OrderProductAutocompleteForm && state.form.OrderProductAutocompleteForm.values && state.form.OrderProductAutocompleteForm.values['orderDetailId'] && state.form.OrderProductAutocompleteForm.values['orderDetailId']['product']) {
        return state.form.OrderProductAutocompleteForm.values['orderDetailId']['product'];
    } else {
        return null;
    }
}

export const getProductIdInOrderProductAutocompleteForm = (state:State): number => {
    if(state && state.form && state.form.OrderProductAutocompleteForm && state.form.OrderProductAutocompleteForm.values && state.form.OrderProductAutocompleteForm.values['orderDetailId'] && state.form.OrderProductAutocompleteForm.values['orderDetailId']['product']) {
        return state.form.OrderProductAutocompleteForm.values['orderDetailId']['product']['id'];
    } else {
        return null;
    }
}

export const getProductInDSPRInventoryTransactionForm = (state: State): DspProduct => {
    if (state && state.form && state.form.DSPRInventoryTransactionForm && state.form.DSPRInventoryTransactionForm.values) {
        if(state.api.entities.dspProducts && state.api.entities.dspProducts[state.form.DSPRInventoryTransactionForm.values['productId']]) {
            return state.api.entities.dspProducts[state.form.DSPRInventoryTransactionForm.values['productId']];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export const getMetrcTagInMetrcTagOrBarcodeDownloadForm = (state: State): string => {
    if (state && state.form && state.form.DSPRMetrcTagOrBarcodeDownloadForm && state.form.DSPRMetrcTagOrBarcodeDownloadForm.values) {
        return state.form.DSPRMetrcTagOrBarcodeDownloadForm.values['metrcTag'];
    } else {
        return null;
    }
}

export const getBatchNumberInMetrcTagOrBarcodeDownloadForm = (state: State): string => {
    if (state && state.form && state.form.DSPRMetrcTagOrBarcodeDownloadForm && state.form.DSPRMetrcTagOrBarcodeDownloadForm.values) {
        return state.form.DSPRMetrcTagOrBarcodeDownloadForm.values['batchNumber'];
    } else {
        return null;
    }
}

// return state?.form?.DSPRMetrcTagOrBarcodeDownloadForm?.values['printLabelType'] || null;
export const getMetrcTagDownloadSelectedQRType = (state: State): string => {
    if (state && state.form && state.form.DSPRMetrcTagOrBarcodeDownloadForm && state.form.DSPRMetrcTagOrBarcodeDownloadForm.values) {
        return state.form.DSPRMetrcTagOrBarcodeDownloadForm.values['printLabelType'];
    } else {
        return null;
    }
}


export const isDiscountTypeAbsoluteOrPercentInCouponForm = (state: State): boolean => {
    if (state && state.form && state.form.CouponForm && state.form.CouponForm.values) {
        const discountType = state.form.CouponForm.values['discountType'];
        return (discountType === 'absolute' || discountType === 'percent');
    } else {
        return false;
    }
}

export const getBatchNumberInBatchNumberProductLookupForm = (state: State): string => {
    if (state && state.form && state.form.DSPRBatchNumberProductLookupForm && state.form.DSPRBatchNumberProductLookupForm.values) {
        return state.form.DSPRBatchNumberProductLookupForm.values['batchNumber']
    } else {
        return null;
    }
}

export const getProductInBatchNumberProductLookupForm = (state: State): number => {
    if (state && state.form && state.form.DSPRBatchNumberProductLookupForm && state.form.DSPRBatchNumberProductLookupForm.values) {
        return state.form.DSPRBatchNumberProductLookupForm.values['productId']
    } else {
        return null;
    }
}

export const getShowActiveOnlyInBatchNumberProductLookupForm = (state: State): boolean => {
    if (state && state.form && state.form.DSPRBatchNumberProductLookupForm && state.form.DSPRBatchNumberProductLookupForm.values) {
        return state.form.DSPRBatchNumberProductLookupForm.values['showActiveOnly']
    } else {
        return false;
    }
}

export const getBrandInDSPProductForm = (state: State): string => {
    if(state && state.form && state.form.DSPProductForm && state.form.DSPProductForm.values) {
        return state.form.DSPProductForm.values['brandId'];
    } else {
        return null;
    }
}

export const getProductTypeInDSPProductForm = (state: State): string => {
    if(state && state.form && state.form.DSPProductForm && state.form.DSPProductForm.values) {
        return state.form.DSPProductForm.values['productType'];
    } else {
        return null;
    }
}

export const getReportTypeInInventoryReportForm = (state: State): string => {
    if(state && state.form && state.form.CustomInventoryReportForm && state.form.CustomInventoryReportForm.values) {
        return state.form.CustomInventoryReportForm.values['report_type'];
    } else {
        return null;
    }
}