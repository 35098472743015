import { schema, normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import qs from 'query-string';
import { logException } from '../actions/apiUIHelperActions';

import { LOCAL_STORAGE_ACCESS_TOKEN_KEY, logout } from '../actions/oauthActions';

import { history } from '../index';
import { DSPR } from '../store/reduxStoreState';

export const API_HOST = process.env.REACT_APP_API_URL;

export const API_ROOT = API_HOST + 'v1/';

export const getUserDocumentUrl = (document, userId) =>
	API_ROOT +
	`user/${document}/document/${userId}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const getDocumentImage = (document, filename: string) =>
	API_ROOT +
	`user/${document}/documentfile/${btoa(filename)}?access_token=${localStorage.getItem(
		LOCAL_STORAGE_ACCESS_TOKEN_KEY,
	)}`;

export const getImage = (filename: string) =>
	API_HOST + `${filename}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const getAudit = (dsprId, filename) =>
	API_ROOT +
	`dspr/${dsprId}/audit_download/${filename}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const getTripTicketUrl = (orderId) =>
	API_ROOT + `order/trip-ticket-${orderId}.html?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const canceledFetchErrorMessage = 'The user aborted a request.';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (
	httpAction: string,
	endpoint: string,
	schema,
	accessToken,
	body: any = {},
	queryParamsMap: any = {},
	file,
	signal,
) => {
	queryParamsMap.access_token = accessToken;
	const queryParamsString = qs.stringify(queryParamsMap);
	const fullUrl = (endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint) + `?${queryParamsString}`;

	let fetchInit = {};

	if (httpAction !== 'GET') {
		const jsonBody = JSON.stringify(body);
		let formData;
		let headers;
		if (file) {
			formData = new FormData();
			formData.append('file', file);
			formData.append('meta-data', jsonBody);
			headers = new Headers();
		} else {
			headers = new Headers();
			headers.append('Content-Type', 'application/json');
		}
		fetchInit = {
			mode: 'cors',
			method: httpAction,
			headers,
			body: formData ? formData : jsonBody,
		};
	}
	if (signal) fetchInit['signal'] = signal;

	return fetch(fullUrl, fetchInit).then((response) => {
		return response.json().then((json) => {
			if (!response.ok) {
				// return Promise.reject(json) // <-- promise
				throw json;
			}

			// return Promise.resolve(Object.assign({},
			//     normalize(camelizeKeys(json), schema)) // <-- NOW a promise :D
			// )

			return Object.assign({}, normalize(camelizeKeys(json), schema));
		});
	});
};

const userSchema = new schema.Entity(
	'users',
	{},
	{
		idAttribute: (user) => user.id,
	},
);

const searchUserSchema = new schema.Entity(
	'searchUsers',
	{},
	{
		idAttribute: (user) => user.id,
	},
);
const unverifiedUserSchema = new schema.Entity(
	'unverifiedUsers',
	{},
	{
		idAttribute: (user) => user.id,
	},
);

const dspSchema = new schema.Entity(
	'deliveryServiceProviders',
	{},
	{
		idAttribute: (deliveryServiceProvider) => deliveryServiceProvider.id,
	},
);

const dspManagerSchema = new schema.Entity(
	'dspManagers',
	{},
	{
		idAttribute: (dspManager) => dspManager.id,
	},
);

const dsprSchema = new schema.Entity(
	'DSPRs',
	{},
	{
		idAttribute: (dspr) => dspr.id,
	},
);

const dsprManagerSchema = new schema.Entity(
	'dsprManagers',
	{},
	{
		idAttribute: (dsprManager) => dsprManager.id,
	},
);

const dsprDriverSchema = new schema.Entity(
	'dsprDrivers',
	{},
	{
		idAttribute: (dsprDriver, parent) => dsprDriver.id,
	},
);

const dspProductSchema = new schema.Entity(
	'dspProducts',
	{},
	{
		idAttribute: (dspProduct) => dspProduct.id,
	},
);

const searchProductSchema = new schema.Entity(
	'searchProducts',
	{},
	{
		idAttribute: (searchProduct) => searchProduct.id,
	},
);

const dspProductCategorySchema = new schema.Entity(
	'dspProductCategories',
	{},
	{
		idAttribute: (category) => category.id,
	},
);

const dsprDriverLocationSchema = new schema.Entity(
	'dsprDriverLocations',
	{},
	{
		idAttribute: (location) => location.id,
	},
);

const dsprDriverInventoryPeriodSchema = new schema.Entity(
	'dsprDriverInventoryPeriods',
	{},
	{
		idAttribute: (inventoryPeriod) => inventoryPeriod.id,
	},
);

const dsprZipCodeSchema = new schema.Entity(
	'dsprZipCodes',
	{},
	{
		idAttribute: (dsprZipCode) => dsprZipCode.id,
	},
);

const dsprDriverInventoryItemSchema = new schema.Entity(
	'dsprDriverInventoryItems',
	{},
	{
		idAttribute: (item) => item.id,
	},
);

const dsprProductInventoryTransactionSchema = new schema.Entity(
	'dsprProductInventoryTransactions',
	{},
	{
		idAttribute: (transaction) => transaction.id,
	},
);

const dsprCurrentInventoryItemSchema = new schema.Entity(
	'dsprCurrentInventoryItems',
	{},
	{
		idAttribute: (item) => item.id,
	},
);

const userIdDocumentSchema = new schema.Entity(
	'usersIdDocuments',
	{},
	{
		idAttribute: (document) => document.id,
	},
);

const userMedicalRecommendationSchema = new schema.Entity(
	'usersMedicalRecommendations',
	{},
	{
		idAttribute: (document) => document.id,
	},
);

const dsprProductPriceHistorySchema = new schema.Entity(
	'dsprProductPriceHistories',
	{},
	{
		idAttribute: (priceHistory) => priceHistory.id,
	},
);

const couponSchema = new schema.Entity(
	'coupons',
	{},
	{
		idAttribute: (coupon) => coupon.id,
	},
);

const orderSchema = new schema.Entity(
	'orders',
	{},
	{
		idAttribute: (order) => order.id,
	},
);

const assignDriverToOrderSchema = new schema.Entity(
	'assignDriverToOrders',
	{},
	{
		idAttribute: (assignDriverToOrder) => assignDriverToOrder.order.id,
	},
);

const orderWithConfirmationStateSchema = new schema.Entity(
	'ordersWithConfirmationState',
	{},
	{
		idAttribute: (orderWithConfirmationState) => orderWithConfirmationState.order.id,
	},
);

const productWithConfirmationStateSchema = new schema.Entity(
	'productsWithConfirmationState',
	{},
	{
		idAttribute: (productWithConfirmationState) => productWithConfirmationState.product.id,
	},
);

const addressSchema = new schema.Entity(
	'addresses',
	{},
	{
		idAttribute: (address) => address.id,
	},
);

const dsprOrderHistorySchema = new schema.Entity(
	'dsprOrderHistories',
	{},
	{
		idAttribute: (orderHistory) => orderHistory.dspr.id,
	},
);

const textBlastSchema = new schema.Entity(
	'textBlasts',
	{},
	{
		idAttribute: (textBlast) => textBlast.id,
	},
);

const userNoteSchema = new schema.Entity(
	'userNotes',
	{},
	{
		idAttribute: (userNote) => userNote.id,
	},
);

const dsprProductCategoryPromotionSchema = new schema.Entity(
	'dsprProductCategoryPromotions',
	{},
	{
		idAttribute: (productCategoryPromotion) => productCategoryPromotion.id,
	},
);

const metricSchema = new schema.Entity(
	'metrics',
	{},
	{
		idAttribute: (metric) => metric.metric,
	},
);

const dsprDriverServiceAreaSchema = new schema.Entity(
	'dsprDriverServiceAreas',
	{},
	{
		idAttribute: (dsprDriverServiceArea) => dsprDriverServiceArea.id,
	},
);

const dsprDriverServiceAreaProfileSchema = new schema.Entity(
	'dsprDriverServiceAreaProfiles',
	{},
	{
		idAttribute: (dsprDriverServiceAreaProfile) => dsprDriverServiceAreaProfile.id,
	},
);

const dsprDriverServiceAreaVertexSchema = new schema.Entity(
	'dsprDriverServiceAreaVertices',
	{},
	{
		idAttribute: (vertex) => vertex.id,
	},
);

const dsprDriverRouteSchema = new schema.Entity(
	'dsprDriverRoutes',
	{},
	{
		idAttribute: (dsprDriverRoute) => dsprDriverRoute.id,
	},
);

const dsprDriverRouteLegSchema = new schema.Entity(
	'dsprDriverRouteLegs',
	{},
	{
		idAttribute: (dsprDriverRouteLeg) => dsprDriverRouteLeg.id,
	},
);

const dsprDriverRouteLegDirectionSchema = new schema.Entity(
	'dsprDriverRouteLegDirections',
	{},
	{
		idAttribute: (dsprDriverRouteLegDirection) => dsprDriverRouteLegDirection.id,
	},
);

const routeLocationSchema = new schema.Entity(
	'dsprDriverRouteLocations',
	{},
	{
		idAttribute: (routeLocation) => routeLocation.id,
	},
);

const routeMetricSchema = new schema.Entity(
	'dsprDriverRouteMetrics',
	{},
	{
		idAttribute: (routeMetric) => routeMetric.id,
	},
);

const brandSchema = new schema.Entity(
	'brands',
	{},
	{
		idAttribute: (brand) => brand.id,
	},
);

const dsprBrandAnalyticsSchema = new schema.Entity(
	'dsprBrandAnalytics',
	{},
	{
		idAttribute: (dsprBrandAnalytic) => dsprBrandAnalytic.dspr.id,
	},
);

const dspBrandAnalyticsSchema = new schema.Entity(
	'dspBrandAnalytics',
	{},
	{
		idAttribute: (dspBrandAnalytic) => dspBrandAnalytic.deliveryServiceProvider.id,
	},
);

const dsprProductInventoryTransactionHistoriesSchema = new schema.Entity(
	'dsprProductInventoryTransactionHistories',
	{},
	{
		idAttribute: (transactionHistory) => transactionHistory.id,
	},
);

const dsprMetrcTagsSchema = new schema.Entity(
	'dsprMetrcTags',
	{},
	{
		//TODO change idAttribute to dsprId when you get that back
		idAttribute: (metrcTags) => metrcTags.id,
	},
);

const dsprMetrcReturnsSchema = new schema.Entity(
	'dsprMetrcReturnReasons',
	{},
	{
		idAttribute: (metrcReturnReasons) => metrcReturnReasons.name,
	},
);
const dsprBatchNumberSchema = new schema.Entity(
	'dsprBatchNumbers',
	{},
	{
		//TODO change idAttribute to dsprId when you get that back
		idAttribute: (dsprBatchNumbers) => dsprBatchNumbers.id,
	},
);
const applicableTaxSchema = new schema.Entity(
	'applicableTax',
	{},
	{
		idAttribute: (applicableTax) => applicableTax.id,
	},
);
const applicableTaxesWithDSPRSchema = new schema.Entity(
	'applicableTaxesWithDSPR',
	{},
	{
		idAttribute: (applicableTaxesWithDSPR) => applicableTaxesWithDSPR.dspr.id,
	},
);
const auditSchema = new schema.Entity(
	'dsprAudits',
	{},
	{
		idAttribute: (audit) => audit.id,
	},
);
//Process strategy used to avoid a nested objects within the orderScans object
// -> orderDetailId is normalized
// -> if metrcTagProductAssociation: all properties in metrcTagProductAssociation is merged with the top level of the object.
const orderScansSchema = new schema.Entity(
	'orderScans',
	{},
	{
		idAttribute: (orderScan) => orderScan.id,
		processStrategy: (entity) => {
			if (entity.metrcTagProductAssociation) {
				const modifiedEntity = { ...entity, ...entity.metrcTagProductAssociation };
				modifiedEntity.orderDetail = modifiedEntity.orderDetail.id;
				delete modifiedEntity.metrcTagProductAssociation;
				return modifiedEntity;
			} else {
				const modifiedEntity = { ...entity };
				modifiedEntity.orderDetail = modifiedEntity.orderDetail.id;
				return modifiedEntity;
			}
		},
	},
);

const dsprCouponLocksSchema = new schema.Entity(
	'dsprCouponLocks',
	{},
	{
		idAttribute: (couponLocks) => couponLocks.dspr.id,
	},
);

dsprDriverRouteSchema.define({
	dsprDriver: dsprDriverSchema,
	startLocation: routeLocationSchema,
	endLocation: routeLocationSchema,
	metrics: routeMetricSchema,
	initialDriverLocation: dsprDriverLocationSchema,
	finalOrder: orderSchema,
	legs: [dsprDriverRouteLegSchema],
	polylineContainingCoordinates: [routeLocationSchema],
	previousRoute: dsprDriverRouteSchema,
});

dsprDriverRouteLegSchema.define({
	route: dsprDriverRouteSchema,
	startLocation: routeLocationSchema,
	endLocation: routeLocationSchema,
	metrics: routeMetricSchema,
	order: orderSchema,
	routeLegDirections: [dsprDriverRouteLegDirectionSchema],
});

dsprDriverRouteLegDirectionSchema.define({
	routeLeg: dsprDriverRouteLegSchema,
	startLocation: routeLocationSchema,
	endLocation: routeLocationSchema,
	metrics: routeMetricSchema,
});

dsprDriverServiceAreaProfileSchema.define({
	dsprDriverServiceAreas: [dsprDriverServiceAreaSchema],
	dspr: dsprSchema,
});

dsprDriverServiceAreaSchema.define({
	dsprDriverServiceAreaVertices: [dsprDriverServiceAreaVertexSchema],
	dspr: dsprSchema,
	currentDriver: dsprDriverSchema,
	dsprDriverServiceAreaProfile: dsprDriverServiceAreaProfileSchema,
});

dsprDriverServiceAreaVertexSchema.define({
	dspr: dsprSchema,
	dsprDriverServiceArea: dsprDriverServiceAreaSchema,
});

dsprProductCategoryPromotionSchema.define({
	dspr: dsprSchema,
	productCategory: dspProductCategorySchema,
});
assignDriverToOrderSchema.define({
	order: orderSchema,
	dsprDriver: dsprDriverSchema,
});
userNoteSchema.define({
	user: userSchema,
	dsprManager: dspManagerSchema,
	dsprDriver: dsprDriverSchema,
});

textBlastSchema.define({});

dsprOrderHistorySchema.define({
	orders: [orderSchema],
	dspr: dsprSchema,
});

orderSchema.define({
	address: addressSchema,
	dsprDriver: dsprDriverSchema,
	user: userSchema,
	userMedicalRecommendation: userMedicalRecommendationSchema,
	userIdentificationDocument: userIdDocumentSchema,
	dspr: dsprSchema,
	scannedProductOrderDetailAssociationsScans: [orderScansSchema],
});

couponSchema.define({
	dspr: dsprSchema,
	specificallyAllowedProducts: [dspProductSchema],
	specificallyAllowedProductCategories: [dspProductCategorySchema],
	specificallyAllowedUsers: [userSchema],
	modifiedCoupon: couponSchema,
});

dsprProductPriceHistorySchema.define({
	dspr: dsprSchema,
	product: dspProductSchema,
	dsprManager: dsprManagerSchema,
});

userIdDocumentSchema.define({
	user: userSchema,
});

userMedicalRecommendationSchema.define({
	user: userSchema,
});

dsprCurrentInventoryItemSchema.define({
	dspr: dsprSchema,
	product: dspProductSchema,
});

dsprProductInventoryTransactionSchema.define({
	product: dspProductSchema,
	dspr: dsprSchema,
	dsprManager: dsprManagerSchema,
});

dsprDriverInventoryItemSchema.define({
	dspr: dsprSchema,
	driver: dsprDriverSchema,
	inventoryPeriod: dsprDriverInventoryPeriodSchema,
	product: dspProductSchema,
});

dsprDriverInventoryPeriodSchema.define({
	dspr: dsprSchema,
	driver: dsprDriverSchema,
	dsprDriverInventoryItems: [dsprDriverInventoryItemSchema],
});

dsprDriverLocationSchema.define({
	dspr: dsprSchema,
	dsprDriver: dsprDriverSchema,
});

dspProductCategorySchema.define({
	deliveryServiceProvider: dspProductCategorySchema,
});

dspProductSchema.define({
	deliveryServiceProvider: dspSchema,
	currentPrice: dsprProductPriceHistorySchema,
	productCategories: [dspProductCategorySchema],
});

dsprDriverSchema.define({
	dspr: dsprSchema,
	user: userSchema,
	currentLocation: dsprDriverLocationSchema,
	currentInventoryPeriod: dsprDriverInventoryPeriodSchema,
	currentInProcessOrder: orderSchema,
	queuedOrders: [orderSchema],
	serviceAreas: [dsprDriverServiceAreaSchema],
	currentRoute: dsprDriverRouteSchema,
});

dsprManagerSchema.define({
	dspr: dsprSchema,
	user: userSchema,
});

dsprZipCodeSchema.define({
	dspr: dsprSchema,
});

dsprSchema.define({
	deliveryServiceProvider: dspSchema,
	managers: [dsprManagerSchema],
	drivers: [dsprDriverSchema],
	zipCodes: [dsprZipCodeSchema],
	outstandingOrders: [orderSchema],
	currentServiceAreaProfile: dsprDriverServiceAreaProfileSchema,
	currentFullMenuDriver: dsprDriverSchema,
	auditReports: [auditSchema],
});

dspManagerSchema.define({
	managerUser: userSchema,
	deliveryServiceProvider: dspSchema,
});

dspSchema.define({
	managers: [dspManagerSchema],
	products: [dspProductSchema],
	productCategories: [dspProductCategorySchema],
});

userSchema.define({
	deliveryServiceProviderManagers: [dspManagerSchema],
	dsprManagers: [dsprManagerSchema],
	dsprDrivers: [dsprDriverSchema],
	identificationDocument: userIdDocumentSchema,
	medicalRecommendation: userMedicalRecommendationSchema,
	userNotes: [userNoteSchema],
});

orderWithConfirmationStateSchema.define({
	order: orderSchema,
});

productWithConfirmationStateSchema.define({
	product: dspProductSchema,
	brand: brandSchema,
	currentBrand: brandSchema,
});

brandSchema.define({
	deliveryServiceProvider: dspSchema,
	products: [dspProductSchema],
	dsprAnalytics: dsprBrandAnalyticsSchema,
	dsprAnalyticsRebuild: [dsprBrandAnalyticsSchema],
	dspAnalytics: dspBrandAnalyticsSchema,
});

dspBrandAnalyticsSchema.define({
	deliveryServiceProvider: dspSchema,
});

dsprBrandAnalyticsSchema.define({
	dspr: dsprSchema,
});

dsprProductInventoryTransactionHistoriesSchema.define({
	product: dspProductSchema,
	dspr: dsprSchema,
	dsprManager: dsprManagerSchema,
	driver: dsprDriverSchema,
	dsprDriverInventoryItem: dsprDriverInventoryItemSchema,
	dsprProductInventoryTransactionHistories: [orderSchema],
});

dsprMetrcTagsSchema.define({});

dsprBatchNumberSchema.define({
	dspr: dsprSchema,
	product: dspProductSchema,
});

orderScansSchema.define({
	order: orderSchema,
	product: dspProductSchema,
});
applicableTaxSchema.define({
	dsprList: [dsprSchema],
});

dsprCouponLocksSchema.define({
	dspr: dsprSchema,
});

applicableTaxesWithDSPRSchema.define({
	applicableTaxes: [applicableTaxSchema],
	dspr: dsprSchema,
});

// Schemas for Grassp API responses.
export const Schemas = {
	USER: userSchema,
	USER_ARRAY: [userSchema],
	UNVERIFIED_USER: unverifiedUserSchema,
	UNVERIFIED_USER_ARRAY: [unverifiedUserSchema],
	USER_SEARCH_ARRAY: [searchUserSchema],
	DELIVERY_SERVICE_PROVIDER: dspSchema,
	DSP_ARRAY: [dspSchema],
	DSP_MANAGER: dspManagerSchema,
	DSP_MANAGER_ARRAY: [dspManagerSchema],
	DSPR: dsprSchema,
	DSPR_ARRAY: [dsprSchema],
	DSPR_MANAGER: dsprManagerSchema,
	DSPR_MANAGER_ARRAY: [dsprManagerSchema],
	DSPR_DRIVER: dsprDriverSchema,
	DSPR_DRIVER_ARRAY: [dsprDriverSchema],
	DSP_PRODUCT: dspProductSchema,
	DSP_PRODUCT_ARRAY: [dspProductSchema],
	DSP_PRODUCT_SEARCH_ARRAY: [searchProductSchema],
	DSPR_DRIVER_LOCATION: dsprDriverLocationSchema,
	DSPR_DRIVER_LOCATION_ARRAY: [dsprDriverLocationSchema],
	DSPR_DRIVER_INVENTORY_PERIOD: dsprDriverInventoryPeriodSchema,
	DSPR_ZIPCODE: dsprZipCodeSchema,
	DSPR_ZIPCODE_ARRAY: [dsprZipCodeSchema],
	DSPR_DRIVER_INVENTORY_PERIOD_ARRAY: [dsprDriverInventoryPeriodSchema],
	DSPR_DRIVER_INVENTORY_ITEM: dsprDriverInventoryItemSchema,
	DSPR_DRIVER_INVENTORY_ITEM_ARRAY: [dsprDriverInventoryItemSchema],
	DSPR_PRODUCT_INVENTORY_TRANSACTION: dsprProductInventoryTransactionSchema,
	DSPR_PRODUCT_INVENTORY_TRANSACTION_ARRAY: [dsprProductInventoryTransactionSchema],
	DSPR_CURRENT_INVENTORY_ITEM: dsprCurrentInventoryItemSchema,
	DSPR_CURRENT_INVENTORY_ITEM_ARRAY: [dsprCurrentInventoryItemSchema],
	DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY: dsprProductInventoryTransactionHistoriesSchema,
	DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_ARRAY: [dsprProductInventoryTransactionHistoriesSchema],
	USER_ID_DOCUMENT: userIdDocumentSchema,
	USER_ID_DOCUMENT_ARRAY: [userIdDocumentSchema],
	USER_MEDICAL_RECOMMENDATION: userMedicalRecommendationSchema,
	USER_MEDICAL_RECOMMENDATION_ARRAY: [userMedicalRecommendationSchema],
	DSPR_PRODUCT_PRICE_HISTORY: dsprProductPriceHistorySchema,
	DSPR_PRODUCT_PRICE_HISTORY_ARRAY: [dsprProductPriceHistorySchema],
	DSP_PRODUCT_CATEGORY: dspProductCategorySchema,
	DSP_PRODUCT_CATEGORY_ARRAY: [dspProductCategorySchema],
	BRAND: brandSchema,
	BRAND_ARRAY: [brandSchema],
	COUPON: couponSchema,
	COUPON_ARRAY: [couponSchema],
	ASSIGN_DRIVER_TO_ORDER: assignDriverToOrderSchema,
	ASSIGN_DRIVER_TO_ORDER_ARRAY: [assignDriverToOrderSchema],
	ORDER: orderSchema,
	ORDER_ARRAY: [orderSchema],
	ORDER_SCAN: orderScansSchema,
	ORDER_SCAN_ARRAY: [orderScansSchema],
	ORDER_WITH_CONFIRMATION_STATE: orderWithConfirmationStateSchema,
	ORDER_WITH_CONFIRMATION_STATE_ARRAY: [orderWithConfirmationStateSchema],
	PRODUCT_WITH_CONFIRMATION_STATE: productWithConfirmationStateSchema,
	PRODUCT_WITH_CONFIRMATION_STATE_ARRAY: [productWithConfirmationStateSchema],
	DSPR_ORDER_HISTORY: dsprOrderHistorySchema,
	TEXT_BLAST: textBlastSchema,
	TEX_BLAST_ARRAY: [textBlastSchema],
	USER_NOTE: userNoteSchema,
	USER_NOTE_ARRAY: [userNoteSchema],
	DSPR_PRODUCT_CATEGORY_PROMOTION: dsprProductCategoryPromotionSchema,
	DSPR_PRODUCT_CATEGORY_PROMOTION_ARRAY: [dsprProductCategoryPromotionSchema],
	METRIC: metricSchema,
	METRIC_ARRAY: [metricSchema],
	DSPR_DRIVER_SERVICE_AREA: dsprDriverServiceAreaSchema,
	DSPR_DRIVER_SERVICE_AREA_ARRAY: [dsprDriverServiceAreaSchema],
	DSPR_DRIVER_SERVICE_AREA_PROFILE: dsprDriverServiceAreaProfileSchema,
	DSPR_DRIVER_SERVICE_AREA_PROFILE_ARRAY: [dsprDriverServiceAreaProfileSchema],
	DSPR_DRIVER_SERVICE_AREA_VERTEX: dsprDriverServiceAreaVertexSchema,
	DSPR_DRIVER_SERVICE_AREA_VERTEX_ARRAY: [dsprDriverServiceAreaVertexSchema],
	DSPR_DRIVER_ROUTE: dsprDriverRouteSchema,
	DSPR_DRIVER_ROUTE_ARRAY: [dsprDriverRouteSchema],
	DSPR_DRIVER_ROUTE_LEG: dsprDriverRouteLegSchema,
	DSPR_DRIVER_ROUTE_LEG_ARRAY: [dsprDriverRouteLegSchema],
	DSPR_DRIVER_ROUTE_LEG_DIRECTION: dsprDriverRouteLegDirectionSchema,
	DSPR_DRIVER_ROUTE_LEG_DIRECTION_ARRAY: [dsprDriverRouteLegDirectionSchema],
	DSPR_METRC_TAGS: dsprMetrcTagsSchema,
	DSPR_METRC_TAGS_ARRAY: [dsprMetrcTagsSchema],
	DSPR_METRC_RETURN_REASONS: dsprMetrcReturnsSchema,
	DSPR_METRC_RETURN_REASONS_ARRAY: [dsprMetrcReturnsSchema],
	DSPR_BATCH_NUMBER: dsprBatchNumberSchema,
	DSPR_BATCH_NUMBER_ARRAY: [dsprBatchNumberSchema],
	APPLICABLE_TAX: applicableTaxSchema,
	APPLICABLE_TAX_ARRAY: [applicableTaxSchema],
	APPLICABLE_TAXES_WITH_DSPR_ASSOCIATION: applicableTaxesWithDSPRSchema,
	APPLICABLE_TAXES_WITH_DSPR_ASSOCIATION_ARRAY: [applicableTaxesWithDSPRSchema],
	DSPR_COUPON_LOCKS: dsprCouponLocksSchema,
	EMPTY: [], // <-- newly added to satisfy TypeScript. Might cause errors, need to investigate
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
// sendBody property in [CALL_API] used to determine if request body should be sent to reducer
export default (store) => (next) => (action) => {
	const callAPI = action[CALL_API];

	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	let { endPoint } = callAPI;
	const { schema, types, httpAction, body, queryParamsMap, file, sendBody, signal } = callAPI;

	if (typeof endPoint === 'function') {
		endPoint = endPoint(store.getState());
	}

	if (typeof endPoint !== 'string') {
		throw new Error('Specify a string endpoint URL.');
	}

	if (!schema) {
		throw new Error('Specify one of the exported Schemas.');
	}

	if (!Array.isArray(types) || types.length !== 3) {
		throw new Error('Expected an array of three action types.');
	}

	if (!types.every((type) => typeof type === 'string')) {
		throw new Error('Expected action types to be strings.');
	}

	const accessToken = store.getState().api.accessToken;

	if (!accessToken || typeof accessToken !== 'string') {
		store.dispatch(logout());
		history.push('/login');
	}

	const actionWith = (data) => {
		const finalAction = Object.assign({}, action, data);
		delete finalAction[CALL_API];
		return finalAction;
	};

	const [requestType, successType, failureType] = types;
	next(actionWith({ type: requestType }));

	return callApi(httpAction, endPoint, schema, accessToken, body, queryParamsMap, file, signal).then(
		(response) => {
			const data = sendBody
				? { response, type: successType, requestBody: body }
				: { response, type: successType };
			return next(actionWith(data));
		},
		(error) => {
			const errorMessage = error.message ? error.message : error.error;
			logException(errorMessage, {
				action: requestType,
				callApi: callAPI,
				state: store.getState(),
			});
			if (
				error.status === 401 ||
				error.error === 'invalid_token' ||
				error.message.includes('Unexpected token < in JSON at position 0')
			) {
				store.dispatch(logout());
				history.push('/login');
			} else {
				return next(
					actionWith({
						type: failureType,
						error: error.message || 'Something went terribly wrong :( ',
					}),
				);
			}
		},
	);
};

export const orderStatus = Object.freeze({
	all: 'all',
	completed: 'completed',
	modified: 'modified',
	canceled: 'canceled',
	inProcess: 'in_process',
	queued: 'queued',
	received: 'received',
	allotmentConfirmed: 'allotment_confirmed',
	cancelledInsufficientAllotment: 'cancelled_insufficient_allotment',
	packaged: 'packaged',
	inRoute: 'in_route',
});

export const DsprOrderStateMachine = (dspr: DSPR) => {
	let i = 0;
	// const map = new Map();
	if (!dspr.notifyRegistry && dspr.isScanOrderDetail && dspr.allowDriverKits && dspr.isFullMenuAvailable)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (!dspr.notifyRegistry && !dspr.isScanOrderDetail && dspr.allowDriverKits && dspr.isFullMenuAvailable)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (dspr.notifyRegistry && dspr.isScanOrderDetail && dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.allotmentConfirmed, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++], //scanning occurs here
			[orderStatus.completed, i++],
		]);
	else if (dspr.notifyRegistry && !dspr.isScanOrderDetail && dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.allotmentConfirmed, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (dspr.notifyRegistry && dspr.isScanOrderDetail && !dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.allotmentConfirmed, i++],
			[orderStatus.packaged, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (dspr.notifyRegistry && !dspr.isScanOrderDetail && !dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.allotmentConfirmed, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (!dspr.notifyRegistry && dspr.isScanOrderDetail && dspr.allowDriverKits)
		return new Map([
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (!dspr.notifyRegistry && !dspr.isScanOrderDetail && dspr.allowDriverKits)
		return new Map([
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (!dspr.notifyRegistry && dspr.isScanOrderDetail && !dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.packaged, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
	else if (!dspr.notifyRegistry && !dspr.isScanOrderDetail && !dspr.allowDriverKits)
		return new Map([
			[orderStatus.received, i++],
			[orderStatus.queued, i++],
			[orderStatus.inRoute, i++],
			[orderStatus.inProcess, i++],
			[orderStatus.completed, i++],
		]);
};
const getByValue = (map, searchValue) => {
	for (let [key, value] of map.entries()) {
		if (value === searchValue) return key;
	}
};
export const checkIfPreviousOrderStatusIsValid = (dspr, order, orderStatusToBe) => {
	const orderStatusFlow = DsprOrderStateMachine(dspr);
	const idxOfOrderStatus = orderStatusFlow.get(orderStatusToBe);
	const prevIdxOfOrderStatus = idxOfOrderStatus - 1;
	const previousOrderStatusFromDSPR = getByValue(orderStatusFlow, prevIdxOfOrderStatus);

	if (previousOrderStatusFromDSPR === order.orderStatus) return true;

	return false;
};
