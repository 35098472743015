import { createSelector } from 'reselect';
import { getDSPRFromProps } from './dsprSelectors';
import { getDSPProducts } from './dspProductSelector';
import { getDriversForDSPR, getCurrentDriverInventoryPeriodForDriverFromProps } from './dsprDriverSelector';
import { DspProduct, DsprCurrentInventoryItem, State } from '../store/reduxStoreState';

export const getDSPRCurrentInventoryItems = (state: State) => state.api.entities.dsprCurrentInventoryItems;

export const getCurrentInventoryForDSPR = createSelector(
    [getDSPRFromProps, getDSPRCurrentInventoryItems], (dspr, currentInventoryItems) => {
        return dspr ?
            currentInventoryItems ?
                Object.keys(currentInventoryItems).map(key => currentInventoryItems[key]).filter(item => item.dspr === dspr.id)
                : []
            : undefined;
    }
);

export const getCurrentInventoryForSingleDSPRProductFromProps = (state: State, props: {dsprId: number, productId: number}): DsprCurrentInventoryItem => {
    const currentInventory = getDSPRCurrentInventoryItems(state)

    for (let key in currentInventory) {
        const inventoryItem = currentInventory[key];
        if (inventoryItem.dspr === props.dsprId && inventoryItem.product === props.productId) {
            return inventoryItem
        }
    }
    return null;
}

export const getCurrentInventoryWithFullProductForDSPR = createSelector(
    [getDSPProducts, getCurrentInventoryForDSPR], (products, currentInventoryItems) => {
        return products ?
            currentInventoryItems ?
                currentInventoryItems.map(item => { return { ...item, product: products[item.product] } })
                : []
            : undefined;
    }
);

export type CurrentInventory = (Omit<DsprCurrentInventoryItem, 'product'> & { product: DspProduct })[];

export const getDSPRCurrentInventoryForAutoSelectForDriverItems = createSelector(
    [getCurrentInventoryWithFullProductForDSPR], (items) => {
        return items ?
            items.map(item => { return { text: item.product.name + ": " + item.quantity, value: item.product.id } })
            : [];
    }
);

export const getInStockDSPRCurrentInventoryForAutoSelectForDriverItems = createSelector(
    [getCurrentInventoryWithFullProductForDSPR], (items) => {
        return items ?
            items
                .filter(item => item.quantity > 0)
                .map(item => { return { text: item.product.name + ": " + item.quantity, value: item.product.id } })
            : [];
    }
);

const getDSPRProductPriceHistories = (state: State, props) => state.api.entities.dsprProductPriceHistories;

export const getDSPRProductPriceHistoryWithProps = (state: State, props) => state.api.entities.dsprProductPriceHistories && state.api.entities.dsprProductPriceHistories[props.priceHistoryId];

export const getTotalAvailableInventoryAtDSPRByProduct = (state: State, props) => {
    const dspr = getDSPRFromProps(state, props);
    // const products = dspr ? getDSPProducts(state, { dspId: dspr.deliveryServiceProvider }) : {};
    // The above line tries to call a function with one parameter and passes two arguments ...
    const products = dspr ? getDSPProducts(state) : {};
    const priceHistories = getDSPRProductPriceHistories(state, props);

    const returnMap = {};
    const dsprCurrentInventory = getCurrentInventoryForDSPR(state, props);
    if (dsprCurrentInventory)
        dsprCurrentInventory.forEach(item => {
            const product: any = products ? products[item.product] : undefined;
            if (product && product.currentPrice && priceHistories && priceHistories[product.currentPrice]) {
                product.currentPrice = priceHistories[product.currentPrice]
            }
            returnMap[item.product] = { product, dspr: item.quantity }
        });

    const drivers = getDriversForDSPR(state, props);
    if (drivers)
        drivers.forEach(driver => {
            let period = getCurrentDriverInventoryPeriodForDriverFromProps(state, { dsprDriverId: driver.id });
            if (period) period.dsprDriverInventoryItems.forEach(item => {
                const { product } = item;
                if (product && product.currentPrice && priceHistories && priceHistories[product.currentPrice]) product.currentPrice = priceHistories[product.currentPrice];
                if (!returnMap[product.id]) returnMap[product.id] = { product };
                returnMap[product.id][driver.id] = item.quantityAvailable;
            });
        });

    //{productId=>{product, price, avialableDSPR, ...availableDrivers}}
    return returnMap;
};

export const getDSPRProductInventoryTransactionHistoryWithProps = (state: State, props) => state.api.entities.dsprProductInventoryTransactionHistories[props.productId];