import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MedicalRecommendation } from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import './OrderReportFormStyles.scss'

const validate = (values) => {
    const errors = {};
    const requiredFields = ['beginDayTimestamp', 'endDayTimestamp'];
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Required';
    });

    if (!parseDate(values['beginDayTimestamp'])) errors['beginDayTimestamp'] = "Invalid Date";
    if (!parseDate(values['endDayTimestamp'])) errors['endDayTimestamp'] = "Invalid Date";
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error, value }, ...custom }) => {
    switch (type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id={input.name + "-picker"}
                    label={label}
                    value={input.value || null}
                    autoOk
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...custom}
                />
            </MuiPickersUtilsProvider>
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} {...custom}/>} />;
        default:
            return <TextField
                    label={label} placeholder={label} type={type} {...input} {...custom}
                    error={!!(touched && error)}
                    helperText={touched && error ? error : ''}
                />
    }
};
interface AnalyticsReportFormProps extends Partial<InjectedFormProps> {

}

const AnalyticsReportForm = (props: AnalyticsReportFormProps) => {
    const { handleSubmit } = props;

    return <form onSubmit={handleSubmit} className={'custom-order-report-form'}>
            <div className={'fields-container'}>
                <Field name="beginDayTimestamp" type="date" component={renderField} label="Begin Date" className={'field'}/>
                <Field name="endDayTimestamp" type="date" component={renderField} label="End Date" className={'field'}/>
            </div>
            <h5>AnalyticsTimeframe</h5>
            <div className={'order-columns-container'}>
                <Field name="timeframe" component="select">
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="quarter">Quarter</option>
                    <option value="year">Year</option>
                </Field>
            </div>
            <h5>Columns</h5>
            <div className={'order-columns-container'}>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="time_frame" component={renderField} label="Time Frame" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="begin_date" component={renderField} label="Time Frame Begin Date" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="cash_collected" component={renderField} label="Cash Collected" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="total_revenues" component={renderField} label="Total Revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="delivery_fees" component={renderField} label="Delivery Fee" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="product_revenues" component={renderField} label="Product Revenues" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="taxes" component={renderField} label="Taxes" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="discounts" component={renderField} label="Discounts" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="number_completed_orders" component={renderField} label="Number Completed Orders" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="number_placed_orders" component={renderField} label="Number Placed Orders" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="number_first_time_completed_orders" component={renderField} label="Number FTP Orders" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="number_active_users" component={renderField} label="Number Active users" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="flower_revenue" component={renderField} label="Flower Revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="concentrate_revenue" component={renderField} label="Concentrate revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="edible_revenue" component={renderField} label="Edible Revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="vaporizor_revenue" component={renderField} label="Vaporizor Revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="other_revenue" component={renderField} label="Other Revenue" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="gross_profit" component={renderField} label="Gross Profit" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="gross_profit_margin" component={renderField} label="Gross Profit Margin" type="checkbox" />
                </FormControl>
            </div>
            <br/>
            <div className='button-container'>
                <div className="verify-button-container">
                    <Button color="primary" variant="contained" onClick={handleSubmit}>Create CSV</Button>
                </div>
            </div>
        </form>
};

export default reduxForm<any, AnalyticsReportFormProps>({
    form: 'CustomAnalyticsReportForm',
    validate
})(AnalyticsReportForm);