import React from 'react';
import {Card, CardHeader, Grid} from "@material-ui/core";
import CardBody from "../Card/CardBody";
import InvoiceBatchForm from "./InvoiceForm";
import InvoiceTransactionHistoryContainer from "./InvoiceTransactionHistoryContainer";

interface InvoicePresentationalInterface {
    handleInvoiceSubmit:(values: { invoiceNumber: string }) => void;
    showInvoiceTransactionHistory: boolean;
    invoiceNumber: string;
}
const InvoicePresentational:React.FC<InvoicePresentationalInterface> = (props) => {

    const {
        handleInvoiceSubmit,
        showInvoiceTransactionHistory,
        invoiceNumber
        } = props;

    return (
        <Grid style={{margin:"1.2em"}}>
            <h2>Invoices</h2>
        <Card className="card-container">
            <CardHeader title="Enter Invoice Number"/>
            <CardBody>
                <InvoiceBatchForm
                    onSubmit={handleInvoiceSubmit}
                />
            </CardBody>
        </Card>
            {showInvoiceTransactionHistory &&
                <Grid style={{margin:"0.6em"}}>
                    <InvoiceTransactionHistoryContainer
                        invoiceNumber={invoiceNumber}
                    />
                </Grid>
            }
        </Grid>
    )
}


export default InvoicePresentational;