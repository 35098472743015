import { createSelector } from 'reselect';
import { State } from '../store/reduxStoreState';
import { getDSPFromProps } from './dspSelectors';


/**Returns an object of brand objects, with each brand id serving as a key
 * {brandId: {brandObj},...} */
export const getBrands = (state: State) => state.api.entities.brands;

export const getBrandFromPropsById = (state: State, props) => state.api.entities.brands[props.brandId];

/**Returns an array of all brand objects (active and inactive) for a given DSP */
export const getAllBrandsForDSPFromProps = createSelector(
    [getBrands, getDSPFromProps], (brands, dsp) => {
        if (!brands || !dsp) return [];
        return Object.keys(brands)
            .filter(key => brands[key].deliveryServiceProvider === dsp.id)
            .map(key => brands[key]);
    }
);

/**Returns an array of all active brand objects for a given DSP */
export const getAllActiveBrandsForDSPFromProps = createSelector(
    [getBrands, getDSPFromProps], (brands, dsp) => {
        if (!brands || !dsp) return [];
        return Object.keys(brands)
            .filter(key => brands[key].deliveryServiceProvider === dsp.id && brands[key].isActive === true)
            .map(key => brands[key]);
    }
);
