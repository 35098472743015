import React from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {Button, TextField} from '@material-ui/core';

import './ResetPasswordFormStyles.scss'

const validate = values => {
    const errors = {};
    if (!values.newPassword) {
        errors['newPassword'] = 'Required';
    }
    if (!values.newPasswordConfirm) {
        errors['newPasswordConfirm'] = 'Required';
    }
    if (values.newPassword !== values.newPasswordConfirm) {
        errors['newPasswordConfirm'] = 'Password doesn\'t match.';
    }

    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error }, ...custom }) => (
        <TextField label={label} type={type} {...input} {...custom}
                   error={!!(touched && error)} helperText={touched && error ? error : ''}/>
);

interface ResetPasswordFormProps extends Partial<InjectedFormProps> {

}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const { handleSubmit,
        pristine,
        submitting
    } = props;
    return (
        <form onSubmit={handleSubmit} className={'reset-password-form'}>
            <Field name="newPassword" type="password" component={renderField} label="New Password" className="field"/>
            <Field name="newPasswordConfirm" type="password" component={renderField} label="Confirm New Password" className="field confirmation" />
            <div className="submit-button">
                <Button color="secondary" variant="contained" disabled={pristine || submitting} onClick={handleSubmit} >Change Password</Button>
            </div>
            
        </form>
    )
};

export default reduxForm<any, ResetPasswordFormProps>({
    form: 'ResetPasswordForm', // a unique identifier for this form
    validate
})(ResetPasswordForm)