// eslint-disable-next-line
import { navDSP, navDSPR, navLinks } from "../selectors/headerSelectors";
import DSPRPage from "../containers/DSPRPage";
import { AirportShuttle, SvgIconComponent, LocalLibrary, DirectionsCar } from "@material-ui/icons";
import DSPManager from "../layouts/DSPManager";
import DSPRDriversContainer from "../containers/DSPRDriversContainer";
import { DSPR, DsprDriver } from "../store/reduxStoreState";
import { Component } from "react";

class SidebarUtil {

    /**Create collapsable list of DSP and DSPR routes for a user
     * -> DSP routes are links only if the user manages that DSP. Otherwise, the DSP name is simply a heading
     * -> DSPR routes are only created for the DSPRs a user manages
     * */
    static createManagerProfiles(navSelector: navLinks) {
        const navLinks = [];

        Object.values(navSelector).forEach((dsp: navDSP) => {
            if (typeof dsp === "object") {
                navLinks.push({
                    path: dsp.isManager ? `dsp/${dsp.id}/` : null,
                    name: `${dsp.name}`,
                    mini: `${dsp.id}`,
                    component: dsp.isManager ? DSPManager : null,
                    layout: dsp.isManager ? "/" : null,
                    isHeading: !dsp.isManager,
                    activeRouteOverrideClassName: 'manager-profiles-inner-route',
                    generalRouteMatch: `/dsp/${dsp.id}`
                });

                if (dsp.dsprs) {
                    Object.values(dsp.dsprs).forEach((dspr: navDSPR) => {
                        if (dspr.isManager === true) {
                            navLinks.push({
                                path: `/${dspr.id}/dashboard/`,
                                name: `\xa0\xa0\xa0\xa0\xa0\xa0${dspr.name}`,
                                mini: "-",
                                component: DSPRPage,
                                layout: "/dspr",
                                activeRouteOverrideClassName: 'manager-profiles-inner-route',
                                generalRouteMatch:`/dspr/${dspr.id}`,
                            });
                        }
                    })
                }
            }
        })

        return {
            collapse: true,
            name: 'Manager Profiles',
            icon: LocalLibrary,
            initialOpenState: true,
            state: 'manager-profile-nav-links',
            views: navLinks,
            includeSeparator: true,
            activeCollapseOverrideClassName: 'manager-profiles-active-collapse'
        };
    }

    /**Generate driver route objects*/
    static generateDriverRoutes = (navSelector: navLinks) => {
        const driverRoutes = [];
        if (navSelector) {
            Object.values(navSelector).forEach((dsp: navDSP) => {
                if (typeof dsp === 'object' && dsp.dsprs) {
                    Object.values(dsp.dsprs).forEach((dspr: navDSPR) => {
                        if (dspr.driverId) {
                            driverRoutes.push({
                                path: `/${dspr.id}/drivers/${dspr.driverId}`,
                                name: `${dspr.name}`,
                                icon: DirectionsCar,
                                component: DSPRDriversContainer,
                                layout: "/dspr",
                            })
                        }
                    })
                }
            })
        }
        return driverRoutes;
    }

    /**Create a collapsable list of navigable driver routes for the logged in user using driver route objects*/
    static createDriverProfiles(
        driverRoutes?: { path: string, name: string, mini?: string, icon: SvgIconComponent, component: Component, layout: string }[],
        driverIds?: number[],
        dsprDrivers?: { [key: number]: DsprDriver },
        dsprs?: { [key: string]: DSPR }) {

        //create driver routes if pre-composed routes are not provided
        const views = driverRoutes
            ? driverRoutes
            : (driverIds && dsprDrivers && dsprs)
                ? driverIds.map((driverId, idx) => {
                    const dsprId = dsprDrivers[driverId].dspr;
                    return ({
                        path: `/${dsprId}/drivers/${driverId}`,
                        name: `${dsprs[dsprId].name}`,
                        mini: '-',
                        component: DSPRDriversContainer,
                        layout: '/dspr'
                    })
                })
                : [];

        return {
            collapse: true,
            name: 'Driver Profiles',
            icon: AirportShuttle,
            initialOpenState: true,
            state: 'driver-profiles-container',
            views,
            includeSeparator: true,
            activeCollapseOverrideClassName: 'manager-profiles-active-collapse'
        }
    }
}

export default SidebarUtil;