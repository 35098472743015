import React, { useEffect, useMemo, useState } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm, InjectedFormProps} from 'redux-form';
import {Button, FormHelperText}from '@material-ui/core';
import {
    Select, 
    MenuItem, 
    TextField, 
    Checkbox, 
    RadioGroup, 
    Radio, 
    InputLabel,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    DialogActions
 } from '@material-ui/core';

import Dropzone from 'react-dropzone';
import { DspProductCategory, DspProduct, Brand, State } from '../store/reduxStoreState';

import './DSPProductFormStyles.scss';
import AutoComplete from '../components/Autocomplete';
import { getAllBrandsForDSP, GET_ALL_BRANDS_FOR_DSP_SUCCESS } from '../actions/brandActions';
import { interceptAutoCompleteForBrandId } from '../actions/formHelperActions';
import { getAllBrandsForDSPFromProps, getBrandFromPropsById } from '../selectors/brandSelectors';
import { getBrandInDSPProductForm, getProductTypeInDSPProductForm } from '../selectors/reduxFormSelectors';

const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field) => {
    const files = field.input.value;
    return (<div>
            <Dropzone
                onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
                multiple={false}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            {files ? <img src={URL.createObjectURL(files[0])} alt="Preview" style={{objectFit: 'contain'}} width="300px" height="200px"/> 
                                : <div>
                                    <input {...getInputProps()} />
                                    <Button color='primary'>Select an image to upload.</Button>
                                </div>
                            }
                            
                        </div>
                    </section>
                )}
            </Dropzone>
            {field.meta.touched &&
            field.meta.error &&
            <span className="error">{field.meta.error}</span>}
        </div>
    );
};

const validate = (values) => {
    const errors = {};
    let nonCannabis = false;
    if(values['productType'] === "non_cannabis") {
        nonCannabis = true;
        values['isCannabisProduct'] = false;
    }
    const requiredFields = nonCannabis ? ['name', 'category']: ['name', 'category', 'flowerType', 'isFlower'];
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required';
        }
    });

    const percentages = [ "thcPercentage", "cbdPercentage", "cbnPercentage"];
    let total = 0;
    percentages.forEach(field => {
        const currentFieldString = values[ field ];
        if(currentFieldString) {
            const currentFieldValue = parseFloat(currentFieldString);
            if(currentFieldValue || currentFieldValue === 0) {
                currentFieldValue >= 0 && currentFieldValue <= 100 ? total += currentFieldValue : errors[field] = "Value must be between 0-100";
            } else {
                errors[ field ] = "Must be a number";
            }
        }
    })

    if (total > 100) {
        percentages.forEach(field => {
            errors[ field ] = "cumulative percentages cannot be over 100";
        })
    }

    return errors;
};

const renderField = ({ input, label, type,
    autocomplete,
    autocompleteValue,
    datasource,
    handleAutoCompleteSelected,
    multiline,
    style,
    disabled,
    key,
    autoCompleteOptions, autoCompleteValue, children, meta: { touched, error, form }, ...custom }) => {
    switch(type) {
        case 'select':
            return <FormControl component="fieldset">
                <InputLabel id={`${input.name}label`}>{label}</InputLabel>
                <Select
                    labelId={`${input.name}Label`}
                    error={!!(touched && error)}
                    {...input}
                    onChange={(event, newValue, previousValue, name) =>  input.onChange(event.target.value)}
                    {...custom}
                >{children}</Select>
                <FormHelperText>{touched && error ? error : ""}</FormHelperText>
            </FormControl>
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange}/>} />;
        case 'radioGroup':
            return <RadioGroup
                {...input}
                value={input.value}
                onChange={(event, value) => input.onChange(value)}
                error={(touched && error) ? error: ''}
                children={children}
            />;
        case 'autoComplete':
            return <AutoComplete
                options={autoCompleteOptions}
                label={label}
                handleAutoCompleteSelected={handleAutoCompleteSelected}
                touched={touched}
                inputValue={(autoCompleteValue && autoCompleteValue.text) || input.value}
                error={error}
                input={input}
                form={form}
                className={'field'}
                disabled={disabled ? disabled : false}
                inputStyle={{minWidth: '240px'}}
                key={key}
            />
        default:
            return <div><TextField
                label={label} placeholder={label} type={type} {...input} {...custom}
                error={!!(touched && error)}
                helperText={touched && error ? error : ''}
            /></div>;

    }
};

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

interface DSPProductFormProps {
    onCancel: () => any,
    dspId?: number,
    editMode?: boolean,
    categories: DspProductCategory [],
    product?: DspProduct
}

const DSPProductForm: React.FC<DSPProductFormProps & InjectedFormProps<any, DSPProductFormProps>> = (props:any ) => {
    const { handleSubmit, onCancel, editMode, categories, error, dspId
        // submitting
    } = props;

    const [brandsLoaded, setBrandsLoaded] = useState(false);
    const dspBrands = useSelector<State, Brand[]>(state => getAllBrandsForDSPFromProps(state, { dspId: dspId}))
    const selectedBrand = useSelector<State, string>(state => getBrandInDSPProductForm(state), shallowEqual);

    const selectedType = useSelector<State, string>(state => getProductTypeInDSPProductForm(state), shallowEqual)

    const brandFromProps = useSelector<State, Brand>(state => getBrandFromPropsById(state, {brandId: selectedBrand}));

    const dispatch = useDispatch();

    useEffect(() => {
        getAllBrandsForDSP(dspId)(dispatch).then((response) => {
            setBrandsLoaded(true)
        });
    }, [])

    const brandOptions = useMemo(()=> {
        if(dspBrands) {
            return dspBrands.map((brand:Brand) => {
                return ({value: brand.id, text: brand.name})
            })
            // setBrandOptions(response.map((brand:Brand) => {
            //     return ({value: brand.id, text: brand.name})
            // }));
        }
    }, [dspBrands])
    


    const flowerTypes = ['sativa', 'indica', 'hybrid', 'cbd', 'sativa_hybrid', 'indica_hybrid', 'other'].map(flowerType =>
        <MenuItem key={flowerType} value={flowerType}>{capitalize(flowerType)}</MenuItem>
    );

    const productTypes = ['flower', 'concentrate', 'edible', 'vaporizer', 'non_cannabis', 'other'].map(productType =>
        <MenuItem key={productType} value={productType}>{capitalize(productType)}</MenuItem>
    );
 
    return (
        <form onSubmit={handleSubmit} className='dsp-product-form'>
            <Field name={FILE_FIELD_NAME} component={renderDropzoneInput} type="dropzone" label="Upload Image"/>
            <Field name="name" component={renderField} label="Name" className='field'/>
            {brandsLoaded && <Field name="Brand"
                component={renderField}
                type="autoComplete"
                dataLoaded={brandsLoaded}
                autoCompleteOptions={brandOptions}
                autoCompleteValue={brandFromProps ? ({value: brandFromProps.id, text: brandFromProps.name}) : undefined}
                label="Brands"
                handleAutoCompleteSelected={(id) => interceptAutoCompleteForBrandId(id)(dispatch)}
                disabled={!brandsLoaded}/>}
            <Field name="productType" component={renderField} type="select"  label="Product Type" className={'field '+ (selectedType === "non_cannabis" ? '': "half" )}>{productTypes}</Field>
            {selectedType !== "non_cannabis" && <Field name="flowerType"  type="select" component={renderField} label="Flower Type" className='field half'>{flowerTypes}</Field>}
            {selectedType !== "non_cannabis" && <Field name="thcPercentage" component={renderField} label="THC %" className='field'/>}
            {selectedType !== "non_cannabis" && <Field name="cbdPercentage" component={renderField} label="CBD %" className='field'/>}
            {selectedType !== "non_cannabis" && <Field name="cbnPercentage" component={renderField} label="CBN %" className='field'/>}
            {selectedType !== "non_cannabis" && <Field name="thcMg" component={renderField} label="THC Milligrams" className='field'/>}
            {selectedType !== "non_cannabis" && <Field name="cbdMg" component={renderField} label="CBD Milligrams" className='field'/>}
            <Field name="description" component={renderField} multiline rows={3} label="Description" placeholder='Enter a description of the Product' variant='outlined' className='field'/>
            {selectedType !== "non_cannabis" && <div className='fieldset'>
                <FormControl component="fieldset">
                    <FormLabel component="label">Is this product a flower (measured in 1/8ths)?</FormLabel>
                    <Field name="isFlower" component={renderField} type="radioGroup">
                        <FormControlLabel label='yes' control={<Radio value="yes"/>} />
                        <FormControlLabel label='no' control={<Radio value="no"/>} />
                    </Field>
                </FormControl>
            </div>}
            <div className='fieldset'>
                <FormControl component="fieldset">
                    <FormLabel component="label">Is this product to be excluded from coupons?</FormLabel>
                    <Field name="isExcludedFromCoupons" component={renderField} type="radioGroup">
                        <FormControlLabel label='yes' control={<Radio value="yes"/>} />
                        <FormControlLabel label='no' control={<Radio value="no"/>} />
                    </Field>
                </FormControl>
            </div>
            <div className='fieldset'>
                <FormControl component="fieldset">
                    <FormLabel component='label'>Categories</FormLabel>
                    <FormGroup>
                        {categories && categories.map(category =>
                            <Field name={`category${category.id}`} key={category.id} label={category.name} component={renderField} type="checkbox" value={category.id}/>
                        )}
                    </FormGroup>
                </FormControl>
            </div>
            <div className='fieldset'>
                <FormControl component="fieldset">
                    <FormLabel component="label">Is this product active?</FormLabel>
                    <Field name="isActive" component={renderField} type="radioGroup">
                        <FormControlLabel label='yes' control={<Radio value="yes"/>} />
                        <FormControlLabel label='no' control={<Radio value="no"/>} />
                    </Field>
                </FormControl>
            </div>
            <DialogActions className='button-container'>
                {error ? <p style={{color:'#FF0000'}}>{error}</p> : null}
                <Button color='primary' className="raised-button"
                              onClick={onCancel}>Cancel</Button>
                <Button variant='contained' color='primary' className="raised-button" onClick={handleSubmit}>{editMode ? "Update" : "Create"}</Button>
            </DialogActions>
        </form>
    )
};

const DSPProductFormRedux = reduxForm<any, DSPProductFormProps>({
    form: 'DSPProductForm', // a unique identifier for this form
    validate
})(DSPProductForm);

export default connect(
    (state, props: DSPProductFormProps) => {
        let categories = {};
        if(props.product) {
            props.product.productCategories.forEach(cat =>
                categories[`category${cat}`] = true);
        }
        return props.product ? ({
            initialValues: {
                ...props.product,
                isFlower: props.product.isFlower ? "yes" : "no",
                isExcludedFromCoupons: props.product.isExcludedFromCoupons ? "yes" : "no",
                isActive: props.product.isActive? "yes" : "no",
                brandId: props.product.brand ? props.product.brand.id : undefined,
                ...categories
            }
        }) : {
                isExcludedFromCoupons: "no",
                isActive: "yes",
            };
    }
)(DSPProductFormRedux)