import React, {useEffect} from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, DialogActions } from '@material-ui/core';
import AutoComplete from '../components/Autocomplete';
import './DSPRDriverFormStyles.scss'
const validate = (values) => {
    const errors = {};
    if (!values.userId) {
        errors['userId'] = 'Required'
    }
    return errors
};

const renderField = ({ input, label, users, handleAutoCompleteSelected, meta: { touched, error, form }, ...custom }) => (
    <AutoComplete
        options={users}
        label={label}
        handleAutoCompleteSelected={handleAutoCompleteSelected}
        touched={touched}
        error={error}
        input={input}
        form={form}
        {...custom}
    />
);

interface DSPRDriverFormProps {
    onCancel: () => void;
    users: { value: any, text: string }[];
    handleAutoCompleteSelected: (id: number) => any;
    usersLoaded?:boolean;
}

const DSPRDriverForm = (props: DSPRDriverFormProps & InjectedFormProps<{}, DSPRDriverFormProps>) => {
    const { handleSubmit, onCancel, users, handleAutoCompleteSelected, usersLoaded } = props;
    return (
        <form onSubmit={handleSubmit} className="dspr-driver-form">
            <Field name="userId" label="Name of Driver" component={renderField} users={users} handleAutoCompleteSelected={handleAutoCompleteSelected} dataLoaded={usersLoaded} className='field'/>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPRDriverFormProps>({
    form: 'DSPRDriverForm', // a unique identifier for this form
    validate
})(DSPRDriverForm)