import { saveAs } from 'file-saver';

import { API_ROOT, CALL_API, Schemas } from '../middleware/api';
import { MetrcTag } from '../store/reduxStoreState';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "./oauthActions";

export const GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB = 'GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB';
export const GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS = 'GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS';
export const GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_FAILURE = 'GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_FAILURE';

const dsprMetrcTagsFromGrasspGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB, GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS, GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_FAILURE],
            endPoint: `metrc/association/tags/available`,
            schema: Schemas.DSPR_METRC_TAGS_ARRAY,
            queryParamsMap: { dsprId }
        }
    }
}

export const getDSPRMetrcTagsFromGrassp = (dsprId) => (dispatch) => {
    return dispatch(dsprMetrcTagsFromGrasspGetter(dsprId));
}

export const GET_METRC_TAGS_FOR_DSPR_FROM_METRC = 'GET_METRC_TAGS_FOR_DSPR_FROM_METRC';
export const GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS = 'GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS';
export const GET_METRC_TAGS_FOR_DSPR_FROM_METRC_FAILURE = 'GET_METRC_TAGS_FOR_DSPR_FROM_METRC_FAILURE';

const dsprMetrcTagsFromMetrcGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_METRC_TAGS_FOR_DSPR_FROM_METRC, GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS, GET_METRC_TAGS_FOR_DSPR_FROM_METRC_FAILURE],
            endPoint: `metrc/association/tags/update`,
            schema: Schemas.DSPR_METRC_TAGS_ARRAY,
            queryParamsMap: { dsprId }
        }
    }
}

export const getDSPRMetrcTagsFromMetrc = (dsprId) => (dispatch) => {
    return dispatch(dsprMetrcTagsFromMetrcGetter(dsprId));
}

export interface MetrcTagPDFGetterProps {
    metrcTag?: string;
    batchNumber?: string;
    productId: number;
    productName: string;
    dsprId: number;
    requestedQuantityOfStickers: number;
    printLabelType?: string;
}

export const metrcTagPDFGetter = (props: MetrcTagPDFGetterProps) => {
    const requestURL = API_ROOT + `barcode/dspr?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(props)
    };
    return fetch(requestURL, requestOptions)
        .then(res => res.blob())
        .then(blob => {
            if (blob.size > 0) {
                saveAs(blob, `${props.requestedQuantityOfStickers}-${props.productName}.pdf`);
                return { success: 'PDF is downloading'};
            } else {
                return { error: props.metrcTag 
                    ? `The Metrc Tag ${props.metrcTag} is not associated with ${props.productName}.` 
                    : 'An error was encountered. The requested Barcodes cannot be printed.'
                };
            }
        });
}

export const GET_METRC_TAGS_PRODUCT_ASSOCIATIONS = 'GET_METRC_TAGS_PRODUCT_ASSOCIATIONS';
export const GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS = 'GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS';
export const GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE = 'GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE';

export interface MetrcTagProductAssociationsGetterProps {
    metrcTag?: string;
    productId?: number;
}

const dsprMetrcTagProductAssociationsGetter = ({metrcTag, productId}) => {
        const queryParamsMap = { metrcTag, productId };
        
        for (let key in queryParamsMap) {
            if (!queryParamsMap[key]) {
                delete queryParamsMap[key];
            }
        }

        return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_METRC_TAGS_PRODUCT_ASSOCIATIONS, GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS, GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_FAILURE],
            endPoint: `metrc/association/`,
            schema: Schemas.DSPR_METRC_TAGS_ARRAY,
            queryParamsMap
        }
    }
}

export const getDSPRMetrcTagProductAssociations = ({metrcTag, productId}: MetrcTagProductAssociationsGetterProps) => (dispatch) => {
    return dispatch(dsprMetrcTagProductAssociationsGetter({metrcTag, productId}));
}

export const REVISE_METRC_TAGS_PRODUCT_ASSOCIATION = 'REVISE_METRC_TAGS_PRODUCT_ASSOCIATION';
export const REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS = 'REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS';
export const REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_FAILURE = 'REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_FAILURE';

export interface MetrcTagProductAssociationReviserProps {
    metrcTagProductAssociation: MetrcTag;
    newMetrcTag: string;
}

const dsprMetrcTagProductAssociationReviser = ({metrcTagProductAssociation, newMetrcTag}: MetrcTagProductAssociationReviserProps) => {
    const oldMetrcTag = metrcTagProductAssociation.metrcTag
    const responseBody = { ...metrcTagProductAssociation, metrcTag: newMetrcTag, metrcTagToTransfer: { metrcTag: oldMetrcTag } };
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [REVISE_METRC_TAGS_PRODUCT_ASSOCIATION, REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS, REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_FAILURE],
            endPoint: `metrc/association/transfer`,
            schema: Schemas.DSPR_METRC_TAGS_ARRAY,
            body: responseBody,
        }
    }
}

export const reviseDSPRMetrcTagProductAssociation = ({metrcTagProductAssociation, newMetrcTag}: MetrcTagProductAssociationReviserProps) => (dispatch) => {
    return dispatch(dsprMetrcTagProductAssociationReviser({metrcTagProductAssociation, newMetrcTag}));
}


export const GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC = 'GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC';
export const GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS = 'GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS';
export const GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_FAILURE = 'GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_FAILURE';

const dsprMetrcReturnReasonsGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC, GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS, GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_FAILURE],
            endPoint: `metrc/sales/delivery/returnreasons`,
            schema: Schemas.DSPR_METRC_RETURN_REASONS_ARRAY,
            queryParamsMap: { dsprId }
        }
    }
}
export const getDSPRMetrcReturnReasons = (dsprId) => (dispatch) => {
    return dispatch(dsprMetrcReturnReasonsGetter(dsprId));
}
