import { createSelector } from 'reselect';
import { getDSPFromProps } from './dspSelectors';
import { getOrders } from './orderSelectors';
import { getAddresses } from './addressSelectors';
import { getUsers } from './userSelectors';
import { State } from '../store/reduxStoreState';
import {getProductCategoriesForDSPFromProps} from './dspProductSelector'
import {getAllApplicableTaxesFromStateInArrayFormat} from "./applicableTaxesSelectors";
import {getDSPRDriverFromProps} from "./dsprDriverSelector";

export const getDSPRDrivers = (state: State) => state.api.entities.dsprDrivers;

export const getDSPRs = (state: State) => state.api.entities.DSPRs;

// export const getAllApplicableTaxesFromStateInArrayFormat = (state:State) => state.api.entities.applicableTax ? Object.values(state.api.entities.applicableTax): [];
export const getDSPRsByName = createSelector(
    [getDSPRs], (dsprs) => {
        if (!dsprs) return [];
        return Object.keys(dsprs)
            .map(key => dsprs[key])
            .map(dspr => { return { value: dspr.id, text: dspr.name}; });
    }
);
export const getDSPRsFromStateInArrayFormat = (state: State) => state.api.entities.DSPRs ? Object.values(state.api.entities.DSPRs) : [];

export const getDSPIdForDSPRFromProps = (state: State, props) => {
    if (props.dsprId) {
        return state.api.entities.DSPRs[props.dsprId].deliveryServiceProvider;
    }
    return null
}

export const getActiveDSPRs = (state: State) => {
    const activeDSPRs = {};
    Object.values(state.api.entities.DSPRs).forEach(dspr => {
        if (dspr.active) activeDSPRs[dspr.id] = dspr
    });
    return activeDSPRs
}

export const getDSPRFromProps = (state: State, props) => state.api.entities.DSPRs[props.dsprId];

export const getDSPRHasComplianceFromProps = (state: State, props) => {
    if(state.api.entities.DSPRs[props.dsprId]) {
        const dspr = state.api.entities.DSPRs[props.dsprId]
        return !!(dspr.isBatchBased || dspr.metrcLicense)
    } 
    return false;
}

export const getDSPRPromotionsForProductCategories = (state: State, props) => state.api.entities.dsprProductCategoryPromotions ? 
        Object.values(state.api.entities.dsprProductCategoryPromotions).filter(promotion => promotion.dspr === parseInt(props.dsprId)) 
        : undefined;

export const getAwayMessageForDSPRWithProps = (state: State, props) => {
    const dspr = getDSPRFromProps(state, props);
    return dspr && dspr.dsprAwayMessage && dspr.dsprAwayMessage.message;
}

/**Returns true if dspr has a metrc license. Otherwise returns false*/
export const isMetrcLicenseHeldByDSPRFromProps = createSelector(
    [getDSPRFromProps], (dspr) => !!(dspr && dspr.metrcLicense)
)

export const isBatchBasedDSPRFromProps = createSelector(
    [getDSPRFromProps], (dspr) => !!(dspr && dspr.isBatchBased)
)

/**Returns true if dspr requires scanning but does not have a metrc license. Otherwise returns false*/
export const isNonMetrcScanningDSPRFromProps = createSelector(
    [getDSPRFromProps], (dspr) => !!(dspr && dspr.isScanOrderDetail && !dspr.metrcLicense)
)

/**Returns true either if dspr requires scanning. Otherwise returns false*/
export const isScanningRequiredForDSPRFromProps = createSelector(
    [getDSPRFromProps], (dspr) => !!(dspr && dspr.isScanOrderDetail)
)

export const allowDriverKitsAtDSPRFromProps = createSelector(
    [getDSPRFromProps], (dspr) => !!(dspr && dspr.allowDriverKits)
)

const getDSPROrderHistories = (state: State) => state.api.entities.dsprOrderHistories;

export const getDSPRsForDSP = createSelector(
    [getDSPRs, getDSPFromProps], (dsprs, dsp) => {
        if (!dsprs || !dsp) return [];
        return Object.keys(dsprs).filter(key => dsprs[key].deliveryServiceProvider === dsp.id).map(key => dsprs[key]);
    }
);

export const getActiveDSPRsForDSP = createSelector(
    [getDSPRs, getDSPFromProps], (dsprs, dsp) => {
        if (!dsprs || !dsp) return [];
        return Object.keys(dsprs).filter(key => (dsprs[key].deliveryServiceProvider === dsp.id) && dsprs[key].active).map(key => dsprs[key]);
    }
);

export const getOrderOfProductCategoriesFromProps = createSelector(
    [getProductCategoriesForDSPFromProps], (productCategories) => {
        const categoriesOrderMap = {};
        if (productCategories) productCategories.map(productCategory => categoriesOrderMap[productCategory.id] = productCategory.order)
        return categoriesOrderMap;
    }
)

export const getActiveProductCategoriesForDSPFromPropsWithProductCategoryPromotions = createSelector(
    [getProductCategoriesForDSPFromProps, getDSPRPromotionsForProductCategories], (productCategories, productCategoryPromotions) => {
        return productCategories ?
            productCategoryPromotions ?
                productCategories.map(productCategory => ({...productCategory, currentPromotion: productCategoryPromotions.find(promotion=> promotion.current && promotion.productCategory === productCategory.id)})) 
                :undefined
            :undefined
    } 
)

export const getOutstandingOrdersForDSPR = createSelector(
    [getDSPRFromProps, getOrders, getAddresses, getUsers], (dspr, orders, addresses, users) => {
        return dspr ?
            dspr.outstandingOrders ?
                orders ?
                    addresses ?
                        dspr.outstandingOrders.map(orderId => {
                            let order = orders[orderId];
                            return { ...order, address: addresses[order.address], user: users[order.user] }
                        })
                        : []
                    : []
                : []
            : [];
    }
);

export const getOrderHistoryForDSPR = createSelector(
    [getDSPRFromProps, getOrders, getDSPROrderHistories, getAddresses, getUsers, getDSPRDrivers], (dspr, orders, dsprOrderHistories, addresses, users, dsprDrivers) => {
        return dspr ?
            dsprOrderHistories ?
                dsprOrderHistories[dspr.id] ?
                    orders ?
                        addresses ?
                            users ?
                                dsprDrivers ?
                                    dsprOrderHistories[dspr.id].orders.map(orderId => {
                                        const order = orders[orderId];
                                        return { ...order, address: addresses[order.address], user: users[order.user], driver: users[dsprDrivers[order.dsprDriver].user] }
                                    })
                                    : undefined
                                : undefined
                            : undefined
                        : undefined
                    : undefined
                : undefined
            : undefined;
    }
);

export const getFullMenuDriverIdForDSPRFromProps = (state: State, { dsprId }) => {
    const dspr = getDSPRFromProps(state, { dsprId });
    return (dspr.currentFullMenuDriver && dspr.isFullMenuAvailable) ? dspr.currentFullMenuDriver : null;
}
export const getCurrentFullMenuDriverIdForDSPRFromProps = (state: State, { dsprId }) => {
    const dspr = getDSPRFromProps(state, { dsprId });
    if(!dspr.currentFullMenuDriver || !dspr.isFullMenuAvailable) return null;
    const driver = getDSPRDriverFromProps(state, {dsprDriverId: dspr.currentFullMenuDriver});
    return driver;
}

export const isFullMenuAvailableForDSPRFromProps = (state: State, { dsprId }) => {
    const dspr = getDSPRFromProps(state, { dsprId });
    return dspr.isFullMenuAvailable;
}