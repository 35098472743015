import React, {Fragment, useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../middleware/api";
import {QUEUE_ORDER_FAILURE, QUEUE_ORDER_SUCCESS, queueOrder} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";

export const QueueButtons:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack,
        isManagerForDSPR,
        isDriverForOrder,
        dsprDriverIdFromOrder,
        currentFullMenuDriver
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const [isFullMenuDriver, setIsFullMenuDriver] = useState(false);
    const dispatch = useDispatch();

    const handleQueueOrder = () => {
        setDisableButtonCallBack(true)
        dispatch<any>(queueOrder(orderId)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === QUEUE_ORDER_SUCCESS) {
                if(closeModal) closeModal();
            }else if(response.type === QUEUE_ORDER_FAILURE){
                setExtraOrderFunctionalityErrorCallBack(response.error);
            }
        })
    }

    useEffect(() => {

        if(isManagerForDSPR || isDriverForOrder) return;
        const isPreviousOrderStatusValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.queued); // check if driver is still full menu driver on queued
        if(isPreviousOrderStatusValid) setShowBasedOnOrderStatus(true);
        if(dspr.isFullMenuAvailable && currentFullMenuDriver && dsprDriverIdFromOrder === currentFullMenuDriver.id) setIsFullMenuDriver(true);
            }, []);

    return showBasedOnOrderStatus && <Fragment>
            <div style={{width: "100%", marginTop: "8px", marginBottom:"8px"}}>
                <Button variant="contained" color="primary"
                        disabled={
                            disableExtraButtons
                            ||
                            isFullMenuDriver
                        }
                        onClick={() => handleQueueOrder()}>
                    Queue Order
                </Button>
            </div>
        </Fragment>
}