import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { Card, CardContent } from '@material-ui/core';
import { Dialog } from "@material-ui/core";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'

import { getDSP } from '../actions/dspActions';
import { getProduct, GET_PRODUCT_FAILURE } from '../actions/dspProductActions';
import { getDSPR, getDSPRAnalytics } from '../actions/dsprActions';
import { getDSPRDriver, GET_DSPR_DRIVER_FAILURE } from '../actions/driverActions';
import { getDSPRFromProps, getOutstandingOrdersForDSPR, } from '../selectors/dsprSelectors';
import { getProductCategoriesForDSP } from '../selectors/dspProductSelector'
import {
    modifyOrder,
    MODIFY_ORDER_SUCCESS,
    MODIFY_ORDER_FAILURE,
    getOrderCost,
    GET_ORDER_COST_FAILURE,
    assignDriverToOrder, ASSIGN_DRIVER_ORDER_SUCCESS, ASSIGN_DRIVER_ORDER_FAILURE
} from '../actions/orderActions';
import { getDSPProductFromProps } from '../selectors/dspProductSelector';
import { getOnCallDriversForDSPR, getDriversForDSPR } from '../selectors/dsprDriverSelector';
import { State, DSPR, DsprDriver, User, Order, Address, DsprDriverLocation, DspProductCategory, DspProduct } from '../store/reduxStoreState';

import SweetAlert from 'react-bootstrap-sweetalert';
import { parseDate } from '../util/util';

import OrderWithDetailsAndPrices from "./OrderWithDetailsAndPrices";
import DSPRDashboardStats from '../components/DSPRDashboardStats';

export const markerColors: any = {
    yellow: {
        url: "/assets/images/yellow_marker.svg",
        labelOrigin: { x: 14, y: 15 }
    },
    orange: {
        url: "/assets/images/orange_marker.svg",
        labelOrigin: { x: 14, y: 15 }
    },
    red: {
        url: "/assets/images/red_marker.svg",
        labelOrigin: { x: 14, y: 15 }
    },
    blue: {
        url: "/assets/images/blue_marker.svg",
        labelOrigin: { x: 14, y: 15 }
    },
    green: {
        url: "/assets/images/green_marker.svg",
        labelOrigin: { x: 14, y: 15 }
    }
}

interface MapProps {
    drivers: (Omit<DsprDriver, 'user'> & { user: User, location: DsprDriverLocation })[];
    outstandingOrders: (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User })[];
    onMarkerRightClick: (driver) => void;
    handleOrderClick: (order: (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User })) => void;
    dspr: DSPR;
    icons: { [key: string]: google.maps.Icon };
    setInfoWindowState: (id: number) => void;
    infoWindowStates: { [key: number]: any };
}

const GettingStartedGoogleMap: React.FC<MapProps> = React.memo(props => {
    const driverMarkers = [];
    const orderMarkers = [];
    const defaultCenter = { lat: props.dspr.centralLatitude, lng: props.dspr.centralLongitude };
    const [hasClickedMarker, setHasClickedMarker] = useState(false);
    const [map, setMap] = useState(undefined);

    const mapTimeToIcon = (time: string) => {
        const timePassedSinceOrderCreation = (new Date().valueOf() - parseDate(time).valueOf()) / 1000 / 60;
        return timePassedSinceOrderCreation >= 90 ? 'red' : timePassedSinceOrderCreation >= 60 ? 'orange' : 'yellow';
    }

    const handleOrderClick = order => {
        if (!hasClickedMarker) setHasClickedMarker(true);
        props.handleOrderClick(order);
    }

    if (props.drivers && props.drivers.length >= 0) {
        props.drivers.forEach(driver => {
            if (!driver.location) return;

            const name = driver && driver.user && driver.user.firstName + " " + driver.user.lastName;
            const initials = driver && driver.user && driver.user.firstName && driver.user.lastName &&
                driver.user.firstName.substring(0, 1) + driver.user.lastName.substring(0, 1);

            driverMarkers.push(<Marker
                position={{ lat: driver.location.latitude, lng: driver.location.longitude }}
                {...driver}
                icon={props.icons["green"]}
                label={initials}
                onRightClick={() => props.onMarkerRightClick(driver)}
                onClick={() => props.setInfoWindowState(driver.id)}
                key={driver.id}
            >
                {props.infoWindowStates[driver.id] && <InfoWindow
                    onCloseClick={() => props.setInfoWindowState(driver.id)}
                    position={{ lat: driver.location.latitude, lng: driver.location.longitude }}
                    options={{ pixelOffset: new google.maps.Size(0, -42) }} // this positions the info window on the top of the marker, not covering it
                >
                    <p><Link to={`/dspr/${driver.dspr}/drivers/${driver.id}`}>{name}</Link><br />Outstanding Orders: {driver.currentInProcessOrder ? driver.queuedOrders.length + 1 : driver.queuedOrders.length}</p>
                </InfoWindow>}
            </Marker>);
        });
    }

    if (props.outstandingOrders && props.outstandingOrders.length >= 0) {
        props.outstandingOrders.forEach(order => {
            orderMarkers.push(<Marker position={{ lat: order.address.latitude, lng: order.address.longitude }}
                {...order}
                icon={props.icons[mapTimeToIcon(order.createdTime)]}
                onClick={() => handleOrderClick(order)}
                key={order.address.id} />)
        });
    }

    return <GoogleMap
            zoom={12}
            onLoad={(map)=> setMap(map)}
            onUnmount={(map)=> setMap(undefined)}
            center={(map && map.getCenter()) || defaultCenter}
            mapContainerStyle={{
                height: '500px',
                width: '100%',
            }}
        >
            {driverMarkers}
            {orderMarkers}
        </GoogleMap>
})

type OutstandingOrder = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User });
export type OnCallDrivers = (Omit<DsprDriver, 'user'> & { user: User, location: DsprDriverLocation })[];
type DsprDriversWithUserAndOrders = (Omit<DsprDriver, 'user'> & { user: User })[];

const DSPRPage: React.FC<{}> = () => {
    const { dsprId } = useParams<{dsprId:string}>();
    const dispatch = useDispatch();

    const getProductById = useSelector<State, (productId: number) => DspProduct>(state => productId => getDSPProductFromProps(state, { productId }), shallowEqual);
    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, { dsprId }), shallowEqual);
    const outstandingOrders = useSelector<State, OutstandingOrder[]>(state => getOutstandingOrdersForDSPR(state, { dsprId }), shallowEqual);
    const dsprDrivers = useSelector<State, DsprDriversWithUserAndOrders>(state => getDriversForDSPR(state, { dsprId }), shallowEqual)
    const onCallDrivers = useSelector<State, OnCallDrivers>(state => getOnCallDriversForDSPR(state, { dsprId }), shallowEqual);
    const productCategories = useSelector<State, DspProductCategory[]>(state =>
        dspr && dspr.deliveryServiceProvider ? getProductCategoriesForDSP(state, { dspId: dspr.deliveryServiceProvider }) : [], shallowEqual);

    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [order, setOrder] = useState<(Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User }) | undefined>(null);
    const [infoWindowStates, setInfoWindowStates] = useState({});
    const [showModifySuccessModal, setShowModifySuccessModal] = useState(false);
    const [showMap, setShowMap] = useState(true);

    const timeout = useRef<NodeJS.Timeout>(undefined);

    const stringifiedOnCallDriverIds = useMemo(() => {
        return JSON.stringify(onCallDrivers ? onCallDrivers.map(driver => driver.id) : []);
    }, [onCallDrivers]);

    useEffect(() => {
        const onCallDriverIds = JSON.parse(stringifiedOnCallDriverIds);

        const refreshDSPR = (dsprId: string) => {
            dispatch<any>(getDSPR(dsprId))
                .then(() => {
                    onCallDriverIds.forEach((id: number) => dispatch(getDSPRDriver(id)));
                });
        };

        const startPoll = () => {
            refreshDSPR(dsprId);
            timeout.current = setTimeout(startPoll, 60000);
        };

        if (onCallDriverIds.length) {
            startPoll();
        } else {
            refreshDSPR(dsprId);
        }

        return timeout.current ? () => {
            clearTimeout(timeout.current);
        } : () => { };
    }, [dispatch, dsprId, stringifiedOnCallDriverIds]);

    useEffect(() => {
        dispatch(getDSPRAnalytics(dsprId));
    }, [dispatch, dsprId]);

    const handleMarkerRightClick = (targetMarker) => {
    }

    const handleOrderClick = (order: OutstandingOrder) => {
        setOrder(order);
        setShowOrderDetails(true);
    };

    const handleGetProductRequest = (productId: number) => {
        return dispatch<any>(getProduct(productId))
            .then(response => {
                if (response.type === GET_PRODUCT_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.dspProducts &&
                        response.response.entities.dspProducts[productId];
                }
            })
    }

    const handleGetDriverRequest = (driverId: number) => {
        return dispatch<any>(getDSPRDriver(driverId))
            .then(response => {
                if (response.type === GET_DSPR_DRIVER_FAILURE) {
                    return response.error
                } else {
                    return response
                }
            })
    }

    const handleOrderCostCalculateRequest = order => {
        return dispatch<any>(getOrderCost(order))
            .then(response => {
                if (response.type === GET_ORDER_COST_FAILURE) {
                    return response.error;
                } else {
                    return response.response && response.response.entities && response.response.entities.orders &&
                        response.response.entities.orders[0];
                }
            })
    }

    const handleModifyOrder = (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]): Promise<string> => {
        return dispatch<any>(modifyOrder(orderId, orderDetails, newDriverId,transferInventoryOnDriverchange, couponCodes))
            .then(response => {
                if (response.type === MODIFY_ORDER_SUCCESS) {
                    setShowMap(false)
                    setShowModifySuccessModal(true)
                    setShowOrderDetails(false)
                    setShowMap(true)
                    return false;
                } else if (response.type === MODIFY_ORDER_FAILURE) {
                    const errorMessage = response.error;
                    if (errorMessage) {
                        const notSufficientInventoryChecker = new RegExp(/Driver '[0-9]*\.*[0-9]*' does not have sufficient inventory of product '[0-9]*\.*[0-9]*' to fulfill the requested order./g)
                        const splittedErrorMessage = errorMessage.split("'");
                        const insufficientProductId = splittedErrorMessage && splittedErrorMessage[3];
                        if (insufficientProductId && notSufficientInventoryChecker.test(errorMessage)) {
                            return `The new driver does not have sufficient inventory of product "
                            ${getProductById(insufficientProductId).name}" to fulfill the requested order.`;
                        } else {
                            return errorMessage;
                        }
                    } else {
                        return 'An error occurred during the transfer';
                    }
                }
            });
    }


    return <main className="dashboard-tab">
        <h2>Dashboard</h2>

        <Card>
            <CardContent>
                <DSPRDashboardStats
                    drivers={onCallDrivers}
                    outstandingOrders={outstandingOrders}
                    dspr={dspr}
                    productCategories={productCategories}
                    getProductCategories={() => dspr && dispatch(getDSP(dspr.deliveryServiceProvider))}
                />
                {dspr && dspr.centralLatitude && dspr.centralLongitude && showMap &&
                    <GettingStartedGoogleMap
                        drivers={onCallDrivers}
                        outstandingOrders={outstandingOrders}
                        onMarkerRightClick={handleMarkerRightClick}
                        handleOrderClick={handleOrderClick}
                        dspr={dspr}
                        icons={markerColors}
                        setInfoWindowState={(id) => setInfoWindowStates(currentInfoWindowState => ({
                            /** 
                             * You may ask, "why does this work for the first run and not crash?"
                             * the truthy value of null is false, and since the first run of the updated state is setting !null,
                             * that evaluates to true. The infoWindowStates object is appended to when a user's pin is clicked 
                             * for the first time.
                            */
                            ...currentInfoWindowState,
                            [id]: !currentInfoWindowState[id],
                        }))}
                        infoWindowStates={infoWindowStates}
                    />
                }
            </CardContent>
        </Card>
        <Dialog
            open={showOrderDetails}
            onClose={() => setShowOrderDetails(false)}>
            <Card style={{ overflow: 'auto' }}>
                {showOrderDetails &&
                    <OrderWithDetailsAndPrices
                        hideNote
                        order={order}
                        user={order.user}
                        address={order.address}
                        modifyOrder={(order.orderStatus === 'in_process' || order.orderStatus === 'queued') && {
                            show: true,
                            dsprDrivers,
                            modifyOrder: handleModifyOrder,
                            availableInventory:[],
                            getDSPRDriver: handleGetDriverRequest,
                            calculateOrderTotal: handleOrderCostCalculateRequest,
                            getProductWithId: handleGetProductRequest,}}
                            />}
            </Card>
        </Dialog>
        {showModifySuccessModal && <SweetAlert
            success
            timeout={2000}
            style={{ display: 'block', position: 'fixed', maxWidth: 'calc(100% - 40px)' }}
            title="Order Modified"
            onConfirm={() => setShowModifySuccessModal(false)}
            showConfirm={false}
        >
            The order has been successfully modified!
        </SweetAlert>}
    </main>
}

export default DSPRPage;
