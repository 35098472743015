import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { State, DSPR, DsprDriver, User } from "../store/reduxStoreState";
import DSPRServiceAreaManagement from "../components/DSPRServiceAreaManagement";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
	getDSPR,
	createOrUpdateDsprServiceArea,
    updateDSPRMenuMechanism,
    getAllDSPRDriverServiceAreaProfilesForDSPR,
    dsprDriverServiceAreaProfile,
    setDSPRDriverServiceAreaProfileActive
} from "../actions/dsprActions";
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import {  getActiveDSPRServiceAreasForDSPRWithVerticesInMapForm } from "../selectors/dsprDriverServiceAreaSelectors";
import { getDriversForDSPR } from "../selectors/dsprDriverSelector";
import { getAllDriversForDspr } from "../actions/driverActions";
import { getActiveDSPRDriverServiceAreaProfilesForDSPRWithActiveServiceAreasForDSPRDriverServiceAreaProfileWithVertices } from "../selectors/dsprDriverServiceAreaProfileSelectors";

export const LOAD_DSPR_DRIVER_SERVICE_AREAS_INTO_COMPONENT_STATE =
	"LOAD_DSPR_DRIVER_SERVICE_AREAS_INTO_COMPONENT_STATE";
export const CREATE_LOCAL_DSPR_DRIVER_SERVICE_AREA =
	"CREATE_LOCAL_DSPR_DRIVER_SERVICE_AREA";

const DSPRServiceAreaContainer: React.FC<{}> = () => {
	const { dsprId } = useParams<{ dsprId: string }>();
	const dispatch = useDispatch();

	const dspr = useSelector<State, DSPR>(
		(state) => getDSPRFromProps(state, { dsprId }),
		shallowEqual
	);
	const dsprDrivers = useSelector<
		State,
		Partial<Omit<DsprDriver, "user"> & { user: User }>[]
    >((state) => getDriversForDSPR(state, { dsprId }), shallowEqual);
    
    const serviceAreaMap = useSelector<State,any>((state)=> getActiveDSPRServiceAreasForDSPRWithVerticesInMapForm(state, { dsprId }), shallowEqual)
    const serviceAreaProfiles = useSelector<
        State,
        any
    >((state)=> getActiveDSPRDriverServiceAreaProfilesForDSPRWithActiveServiceAreasForDSPRDriverServiceAreaProfileWithVertices(state, { dsprId }))

	useEffect(() => {
		dispatch(getDSPR(dsprId));
        dispatch<any>(getAllDriversForDspr(dsprId));
        dispatch<any>(getAllDSPRDriverServiceAreaProfilesForDSPR(dsprId))
    }, [dispatch, dsprId]);

	const createOrUpdatePolygon = (
		latLngArray: any,
        id: number,
        serviceAreaProfileId,
        currentDriver: Partial<Omit<DsprDriver, "user"> & { user: User }>,
        numberOrdersPerRoute,
        cacheable,
		active: boolean
	) => {
		const dsprServiceArea = {
			id,
            active,
            cacheable,
            currentDriver: currentDriver ? { id: currentDriver.id } : null,
            dsprDriverServiceAreaProfile: serviceAreaProfileId ? { id: serviceAreaProfileId } : null,
			numberOrdersPerRoute: numberOrdersPerRoute
				? numberOrdersPerRoute
				: null,
            dspr: { id: dsprId },
			dsprDriverServiceAreaVertices: latLngArray,
		};
		return createOrUpdateDsprServiceArea(dsprServiceArea)(dispatch);
    };
    
    const createNewServiceAreaProfile = (id,name,dsprDriverServiceAreaList, dspr, active) => { 
        return dispatch(dsprDriverServiceAreaProfile(id,name, dsprDriverServiceAreaList, dspr, active))
    }

    const setServiceAreaProfileActive = (id) => {
        return dispatch(setDSPRDriverServiceAreaProfileActive(id))
    }

	return (
		<DSPRServiceAreaManagement
			dspr={dspr}
            createOrUpdatePolygon={createOrUpdatePolygon}
            createNewServiceAreaProfile={createNewServiceAreaProfile}
            setServiceAreaProfileActive={setServiceAreaProfileActive}
            serviceAreaMap={serviceAreaMap}
            serviceAreaProfiles={serviceAreaProfiles}
			drivers={dsprDrivers}
			updateDSPRMenuMechanism={(id, menuMechanism) =>
				updateDSPRMenuMechanism(id, menuMechanism)(dispatch)
			}
		/>
	);
};

export default DSPRServiceAreaContainer;
