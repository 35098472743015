import React, {Fragment, useEffect, useState} from 'react';
import {Button, ListItem, ListItemText, Typography} from "@material-ui/core";
import {CONFIRM_ALLOTMENT_ORDER_SUCCESS, confirmAllotmentOrder} from "../../../actions/orderActions";
import {useDispatch} from "react-redux";
import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../../middleware/api";


export const UserNotifiedAllotmentTime:React.FC<any> = props => {
    const {
        order,
        dspr
    } = props;

    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        const previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.allotmentConfirmed);
        if(previousOrderStatusIsValid)  setShowBasedOnOrderStatus(true);
    }, []);

    /** Allotment is from notify registry and received only */
    return showBasedOnOrderStatus &&
        <ListItem>
            <ListItemText className='notified-allotment' style={{color:"red"}} primary={` User Notified of Allotment ${timeDifferenceBetweenStamp(order) } minutes ago`}/>
        </ListItem>
}

export const timeDifferenceBetweenStamp = (order) => {
    // order.userNotifiedOnAllotmentIssueTimestamp;
    const userNotifiedDate  = new Date(order.userNotifiedOnAllotmentIssueTimestamp);
    const currentTime = new Date();
    // @ts-ignore
    const newTime = Math.abs(currentTime - userNotifiedDate);
    const newTimeInMinutes = Math.ceil(newTime / 60000);
    return newTimeInMinutes;
}
export const checkIfMinutesGreaterThanTwoHours = (order) => {
    const newTimeInMinutes = timeDifferenceBetweenStamp(order);
    if(newTimeInMinutes >= 120) {
        return false;//if more than two hours button not disabled
    }
    return true;
}
export const checkIfUserAlreadyNotified = (order) => {
    if(order && order.userNotifiedOnAllotmentIssueTimestamp){
        return false; // if user has already been notified then deactivate
    };
    return true;
}
