import { createSelector } from 'reselect';
import { getDSPRFromProps } from './dsprSelectors';
import { State } from '../store/reduxStoreState';

const getDSPRZipCodes = (state: State) => {
    return state.api.entities.dsprZipCodes;
};

export const getDSPRZipCodeFromProps = (state: State, props) => {
    return state.api.entities.dsprZipCodes && props && props.zipCodeId ?
        state.api.entities.dsprZipCodes[props.zipCodeId] :
        null;
};

export const getZipCodesForDSPR = createSelector(
    [getDSPRFromProps, getDSPRZipCodes], (dspr, dsprZipCodes) => {
        return dspr ?
            dspr.zipCodes ?
                dsprZipCodes ?
                    dspr.zipCodes.map(zipCodeId => dsprZipCodes[zipCodeId])
                    : []
                : []
            : undefined;
    }
);