import { createSelector } from 'reselect';

import { getLoggedInUser } from './userSelectors';
import { getDSPRs } from './dsprSelectors';
import { getDSPs } from './dspSelectors';
import { getDrivers } from './dsprDriverSelector';
import { getDSPManagers } from './dspManagerSelectors';
import { getDSPRManagers } from './dsprManagerSelectors';

export interface navLinks {
    [dspId: number]: navDSP
}

export interface navDSP {
    id: number
    name: string,
    isManager: boolean,
    dsprs: {
        [dsprId: number]: navDSPR
    }
}

export interface navDSPR {
    id: number,
    name: string,
    isManager: boolean
    driverId?: number
}

/** Generate nested object of DSPs and DSPRs associated with the user
 *
 * Return Format
 * {dspId: {name, isManager, dsprs: {dsprId: {name, isManager, driverId}}}}
 *  Note regarding the selectors: upon login the store will only be populated with dsps/dsprs that the user is associated with
 *      (thus, if you are a superuser that does not manage anything, no dsps/dsprs will be returned by the navSelector)
 * */
export const getDSPsWithStructureForHeaderMenuLinks = createSelector(
    [getLoggedInUser, getDSPRs, getDSPRManagers, getDSPs, getDSPManagers, getDrivers],
    (loggedInUser, allDSPRs, allDSPRManagers, allDSPs, allDSPManagers, allDrivers) => {
        let dsps = {};
        if (!loggedInUser || !allDSPRs || !allDSPRManagers || !allDSPs || !allDSPManagers || !allDrivers) return dsps;

        if (!loggedInUser.systemAdministrator) {
            let managingMultipleDSPRs = false;
            if (loggedInUser.deliveryServiceProviderManagers)
                loggedInUser.deliveryServiceProviderManagers.forEach(dspManagerId => {
                    const manager = allDSPManagers[dspManagerId];
                    const dsp = allDSPs[manager.deliveryServiceProvider];
                    if (manager.active && dsp.active) dsps[dsp.id] = { id: dsp.id, name: dsp.name, isManager: true }
                });

            if (loggedInUser.dsprManagers)
                if (loggedInUser.dsprManagers.length > 1) managingMultipleDSPRs = true;
            loggedInUser.dsprManagers.forEach(dsprManagerId => {
                const manager = allDSPRManagers[dsprManagerId];
                const dspr = allDSPRs[manager.dspr];
                const dspId = dspr.deliveryServiceProvider;
                const dspFromState = allDSPs[dspId];
                if (dspFromState) {
                    if (manager.active && dspr.active) {
                        if (dspFromState.active) {
                            let dsp = dsps[dspId] ?
                                dsps[dspId] :
                                { id: dspId, name: dspFromState.name, isManager: false };
                            dsp.dsprs = dsp.dsprs ? dsp.dsprs : {};
                            dsp.dsprs[dspr.id] = { id: dspr.id, name: dspr.name, isManager: true };
                            dsps[dspId] = dsp;
                        }
                    }
                }
            });

            if (loggedInUser.dsprDrivers) {
                loggedInUser.dsprDrivers.forEach(driverId => {
                    const driver = allDrivers[driverId];
                    const dsprFromState = allDSPRs[driver.dspr];
                    const dspId = dsprFromState.deliveryServiceProvider;
                    const dspFromState = allDSPs[dspId];
                    if (dspFromState) {
                        let dsp = dsps[dspId] ?
                            dsps[dspId] :
                            {id: dspId, name: dspFromState.name, isManager: false};
                        dsp.dsprs = dsp.dsprs ? dsp.dsprs : {};
                        let dsprToAdd = dsp.dsprs[dsprFromState.id] ?
                            dsp.dsprs[dsprFromState.id] :
                            {id: dsprFromState.id, name: dsprFromState.name};
                        dsprToAdd.driverId = driverId;
                        dsp.dsprs[dsprFromState.id] = dsprToAdd;
                        dsps[dspId] = dsp;
                    }
                    //TODO: find a way to retrieve active property for DSPs, then you can use commented out code
                    //if (dspFromState) {
                    //    if (dspFromState.active) {
                    //        let dsp = dsps[dspId] ?
                    //            dsps[dspId] :
                    //            {id: dspId, name: dspFromState.name, isManager: false};
                    //        dsp.dsprs = dsp.dsprs ? dsp.dsprs : {};
                    //        let dsprToAdd = dsp.dsprs[dsprFromState.id] ?
                    //            dsp.dsprs[dsprFromState.id] :
                    //            {id: dsprFromState.id, name: dsprFromState.name};
                    //        dsprToAdd.driverId = driverId;
                    //        dsp.dsprs[dsprFromState.id] = dsprToAdd;
                    //        dsps[dspId] = dsp;
                    //    }
                    //}
                })
            }

            dsps['managingMultipleDSPRs'] = managingMultipleDSPRs;
            return dsps;
        } else {
            let managingMultipleDSPRs = false;
            Object.values(allDSPs).forEach((dsp) => {
                if (dsp.active) dsps[dsp.id] = { id: dsp.id, name: dsp.name, isManager: true }
            })

            Object.values(allDSPRs).forEach((dspr) => {
                const dspId = dspr.deliveryServiceProvider;
                const dspFromState = allDSPs[dspId];
                if (dspr.active) {
                    let dsp = dsps[dspId] ?
                        dsps[dspId] :
                        { id: dspId, name: dspFromState.name, isManager: false };
                    dsp.dsprs = dsp.dsprs ? dsp.dsprs : {};
                    dsp.dsprs[dspr.id] = { id: dspr.id, name: dspr.name, isManager: true };
                    dsps[dspId] = dsp;
                }
            })

            if (loggedInUser.dsprDrivers) {
                loggedInUser.dsprDrivers.forEach(driverId => {
                    const driver = allDrivers[driverId];
                    const dsprFromState = allDSPRs[driver.dspr];
                    const dspId = dsprFromState.deliveryServiceProvider;
                    const dspFromState = allDSPs[dspId];
                    if (dspFromState) {
                        if (dspFromState.active) {
                            let dsp = dsps[dspId] ?
                                dsps[dspId] :
                                {id: dspId, name: dspFromState.name, isManager: false};
                            dsp.dsprs = dsp.dsprs ? dsp.dsprs : {};
                            let dsprToAdd = dsp.dsprs[dsprFromState.id] ?
                                dsp.dsprs[dsprFromState.id] :
                                {id: dsprFromState.id, name: dsprFromState.name};
                            dsprToAdd.driverId = driverId;
                            dsp.dsprs[dsprFromState.id] = dsprToAdd;
                            dsps[dspId] = dsp;
                        }
                    }
                })
            }
            dsps['managingMultipleDSPRs'] = managingMultipleDSPRs;
            return dsps;
        }
    });