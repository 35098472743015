import React, {Fragment, useEffect, useState} from 'react';
import {Button, Typography} from "@material-ui/core";

import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../middleware/api";
import {
    MARK_IN_PROCESS_FAILURE, MARK_IN_PROCESS_SUCCESS, markOrderInProcess
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";


/**Assign driver will only be called if ther*/
export const InProcessButton:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack,
        isManagerForDSPR,
        isDriverForOrder
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const dispatch = useDispatch();

    const handleInProcessOrder = () => {
        setDisableButtonCallBack(true)
        dispatch<any>(markOrderInProcess(orderId)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === MARK_IN_PROCESS_SUCCESS) {
                if(closeModal) closeModal();
            }else if(response.type === MARK_IN_PROCESS_FAILURE){
                setExtraOrderFunctionalityErrorCallBack(response.error);
            }
        })
    }

    useEffect(() => {

        if(isManagerForDSPR || isDriverForOrder) return;
        // const previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.inProcess);
        if(order.orderStatus === orderStatus.queued || order.orderStatus === orderStatus.inRoute) setShowBasedOnOrderStatus(true);

            }, []);

    return showBasedOnOrderStatus && <Fragment>
            <div style={{width: "100%", marginTop: "8px", marginBottom:"8px"}}>
                <Button variant="contained" color="primary" disabled={disableExtraButtons} onClick={() => handleInProcessOrder()}>
                    Make In Process
                </Button>
            </div>
        </Fragment>
}