import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Collapse, IconButton,
} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import {
    SET_DSPR_FULL_MENU_DRIVER_FAILURE,
    SET_DSPR_FULL_MENU_DRIVER_SUCCESS,
    setDSPRFullMenuDriver,
    UNSET_DSPR_FULL_MENU_DRIVER_FAILURE,
    UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS,
    unsetDSPRFullMenuDriver
} from "../actions/dsprActions";
import './DSPRFullMenuDriverCardStyles.scss';
import {
    DsprDriversWithUserMap,
} from "../selectors/dsprDriverSelector";
import { DsprDriversWithUser } from "../containers/DSPRInventoryContainer";
import { Alert } from "@material-ui/lab";

//valid alert types for Material-UI Alert component
export type AlertTypes = 'success' | 'info' | 'warning' | 'error';

interface DSPRFullMenuDriverFormProps {
    onCallDriverMenuItems: JSX.Element[];
    dsprId: string;
    isFullMenuEnabled: boolean;
    dsprDrivers: DsprDriversWithUser
    dsprDriversMap: DsprDriversWithUserMap;
    currentFullMenuDriverId: number;
}

const DSPRFullMenuDriverCard = ({ onCallDriverMenuItems, dsprId, isFullMenuEnabled, dsprDriversMap, currentFullMenuDriverId }: DSPRFullMenuDriverFormProps) => {
    const currentlyAssignedFullMenuDriverId = currentFullMenuDriverId ? currentFullMenuDriverId.toString() : '';

    const [selectedDriverId, setSelectedDriverId] = useState<string>(currentlyAssignedFullMenuDriverId);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [messageText, setMessageText] = useState<string | any>('');
    const [alertType, setAlertType] = useState<AlertTypes>('success')

    const dispatch = useDispatch();

    const dispatchFullMenuUpdate = (selectedDriverId: string, successMessage: string, errorMessage: string) => {
        setIsLoading(true);
        dispatch<any>(setDSPRFullMenuDriver(selectedDriverId, dsprId))
            .then(response => {
                setIsLoading(false);
                if (response.type === SET_DSPR_FULL_MENU_DRIVER_SUCCESS) {
                    setShowSuccessMessage(true);
                    setMessageText(successMessage);
                    setAlertType('success');
                }

                if (response.type=== SET_DSPR_FULL_MENU_DRIVER_FAILURE) {
                    setShowErrorMessage(true);
                    setMessageText(errorMessage);
                    setAlertType('error');
                }
            })
    }

    const handleDriverSelect = (evt) => {
        const newDriverId = evt.target.value;
        setSelectedDriverId(newDriverId);

        if (newDriverId === currentlyAssignedFullMenuDriverId) return;

        const currentFullMenuDriverName = currentlyAssignedFullMenuDriverId && dsprDriversMap ? `${dsprDriversMap[currentlyAssignedFullMenuDriverId].user.firstName} ${dsprDriversMap[currentlyAssignedFullMenuDriverId].user.lastName}` : '';
        const proposedNewFullMenuDriverName = dsprDriversMap ? `${dsprDriversMap[newDriverId].user.firstName} ${dsprDriversMap[newDriverId].user.lastName}` : '';
        const successMessage = proposedNewFullMenuDriverName ? `${proposedNewFullMenuDriverName} has been assigned as the full menu driver` : '';
        const errorMessage = 'The Driver Assignment Failed';

        // handles case in which there is already an assigned full menu driver
        if (currentlyAssignedFullMenuDriverId && isFullMenuEnabled) {
            const confirmationForDriverChange = window.confirm(`${currentFullMenuDriverName} is already assigned as the Full Menu Driver. Do you want to replace this driver with ${proposedNewFullMenuDriverName}?`);

            if (confirmationForDriverChange) {
                dispatchFullMenuUpdate(newDriverId, successMessage, errorMessage);
            } else {
                setSelectedDriverId(currentlyAssignedFullMenuDriverId);
                return;
            }
        }

        if (!currentlyAssignedFullMenuDriverId && newDriverId) {
            dispatchFullMenuUpdate(newDriverId, successMessage, errorMessage);
        }

    }

    const handleUnassignDriver = () => {
        setIsLoading(true);
        dispatch<any>(unsetDSPRFullMenuDriver(dsprId))
        .then(response => {
            setIsLoading(false);
            if (response.type === UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS) {
                setMessageText(`The full menu driver has been unassigned`);
                setShowSuccessMessage(true);
                setAlertType('success');
                setSelectedDriverId('');
            }

            if (response.type === UNSET_DSPR_FULL_MENU_DRIVER_FAILURE) {
                setMessageText(response.error ? `Failed to unassign driver. ${response.error}` : 'An error occured. Failed to unassign driver');
                setShowErrorMessage(true);
                setAlertType('error');
            }
        })
    }

    return (
        <Card>
        <CardContent className={'dspr-full-menu-driver-card-content'}>
            <Collapse in={showSuccessMessage || showErrorMessage}>
                <div className={'alert-container'}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    if (showSuccessMessage) {
                                        setShowSuccessMessage(false);
                                    } else {
                                        setShowErrorMessage(false);
                                    }
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {messageText}
                    </Alert>
                </div>
            </Collapse>

            <div className={'full-menu-status-container'}>
                <Typography className={'full-menu-status'}>Full Menu Driver is {isFullMenuEnabled ? <span className={'enabled-status'}>Enabled</span> : <span className={'disabled-status'}>Disabled</span>}</Typography>
            </div>
            <div className={'note-container'}>
                <Typography variant={'body2'}>Note: Selected Full Menu Driver must have an Active Inventory Period and be On-Call</Typography>
            </div>

            <div className={'driver-select-container'}>
                <FormControl className="full-menu-driver-select-dropdown">
                    <InputLabel id="full-menu-driver-list-select-label">Full Menu Driver</InputLabel>
                    <Select
                        labelId="full-menu-driver-list-select-label"
                        value={selectedDriverId}
                        onChange={handleDriverSelect}
                    >
                        {onCallDriverMenuItems}
                    </Select>
                </FormControl>

                <Button
                    //button is disabled if full menu feature is disabled or selected driver id does not match current driver id
                    disabled={!selectedDriverId || selectedDriverId !== currentlyAssignedFullMenuDriverId}
                    onClick={handleUnassignDriver}
                    variant={'outlined'}
                    color={'primary'}
                    size={'small'}
                >
                    Unassign Driver
                </Button>
            </div>

            {isLoading &&
            <div className={'loading-container'}>
                <LinearProgress/>
            </div>
            }
        </CardContent>
        </Card>
    )

}

export default DSPRFullMenuDriverCard;