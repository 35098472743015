import React, { Fragment } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    CardContent,
    Card,
    Typography,
    CardActions, Button, Tooltip
} from '@material-ui/core';
import {ApplicableTax} from "../store/reduxStoreState";
import './ApplicableTaxWithDetailsStyles.scss';

interface ApplicableTaxesWithDetails {
    applicableTax: ApplicableTax;
    isSystemAdmin: boolean;
    closeApplicableTaxDetailDialog: () => void;
    handleOpenApplicableTaxForm: () => void;
    typeOfUserYesOrNo:(bool:boolean)=> string;
    deleteApplicableTaxById:(values: any) => void;
}

const ApplicableTaxWithDetails = (props: ApplicableTaxesWithDetails) => {
    const {applicableTax, typeOfUserYesOrNo,deleteApplicableTaxById,closeApplicableTaxDetailDialog,handleOpenApplicableTaxForm} = props;
    return <Fragment>
        <Card className={'ApplicableTaxWithDetailsCard'}>

            <div className={'card-header'}>
                <Typography variant={'h5'}><p>{applicableTax.name}</p></Typography>
            </div>

            <CardContent>
                <List>

                    <ListItem>
                        <ListItemText primary={'Name'} secondary={applicableTax.name}/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={'Rate'} secondary={applicableTax.rate}/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={'Recreational User Only'} secondary={typeOfUserYesOrNo(applicableTax.appliedToRecUserOnly)}/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={'Medical User Only'} secondary={typeOfUserYesOrNo(applicableTax.appliedToMedUserOnly)}/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={'Non State Issued Card Only'} secondary={typeOfUserYesOrNo(applicableTax.appliedToNonStateIssuedCardOnly)}/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={'Applied To All Other Taxes'} secondary={typeOfUserYesOrNo(applicableTax.appliedToAllOtherTaxes)}/>
                    </ListItem>
                </List>

                <CardActions className={'button-container'}>
                    <Button size="small" color="secondary" variant={'outlined'} onClick={closeApplicableTaxDetailDialog}>
                        Close
                    </Button>
                    <Tooltip title={applicableTax.name ? <Typography>This is to change the existing applicable tax</Typography> : ''} placement={'top'} arrow>
                        <span>
                            <Button size="small" color="primary" variant={'contained'} onClick={handleOpenApplicableTaxForm}>
                                Modify Applicable Tax
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={applicableTax.name ? <Typography>This is to delete the applicable tax</Typography> : ''} placement={'top'} arrow>
                        <span>
                            <Button size="small"style={{
                                backgroundColor:"#FB0001",
                                color:"white"
                            }} variant={'contained'} onClick={() => deleteApplicableTaxById(applicableTax)}>
                                Delete Applicable Tax
                            </Button>
                        </span>
                    </Tooltip>
                </CardActions>
            </CardContent>
        </Card>
    </Fragment>
}

export default ApplicableTaxWithDetails;