import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {TextField, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DspProductCategory, DspProduct } from '../store/reduxStoreState';

import './DSPProductCategoryOrBrandFormStyles.scss';
import Dropzone from 'react-dropzone';


const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors['name'] = 'Required';
    }
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error }, ...custom }) => {
    switch (type) {
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} />} />
        default:
            return <TextField label={label} type={type} {...input} error={!!(touched && error)}
                helperText={touched && error ? error : ''} {...custom}/>
    }
};

const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field) => {
    const files = field.input.value;
    return (<div>
            <Dropzone
                onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
                multiple={false}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            {files ? <img src={URL.createObjectURL(files[0])} alt="Preview" style={{objectFit: 'contain'}} width="300px" height="200px"/> 
                                : <div>
                                    <input {...getInputProps()} />
                                    <Button color='primary'>Select an image to upload.</Button>
                                </div>
                            }
                            
                        </div>
                    </section>
                )}
            </Dropzone>
            {field.meta.touched &&
            field.meta.error &&
            <span className="error">{field.meta.error}</span>}
        </div>
    );
};

interface DSPProductCategoryOrBrandFormProps {
    fieldLabel?: string,
    buttonText?: string,
    showActive?: boolean
}

/**Form used to create a new category or brand. 
 * 
 * Accepts props using an intersection type. Intersection types combine multiple types into one. 
 *  In the case of thie form, props is a combination of DSPProductCategoryOrBrandFormProps and InjectedFormProps 
 *      (InjectedForm Props comes from redux-form)
 */
const DSPProductCategoryOrBrandForm = ({handleSubmit, fieldLabel = 'Name', buttonText = 'Submit', showActive, ...props}: DSPProductCategoryOrBrandFormProps & InjectedFormProps<{}, DSPProductCategoryOrBrandFormProps>) => {
    return (
        <form onSubmit={handleSubmit} className='dsp-product-category-form'>
            <Field name={FILE_FIELD_NAME} component={renderDropzoneInput} type="dropzone" label="Upload Image"/>
            <Field name="name" component={renderField} label={fieldLabel} className='field' />
            {showActive && <Field name="active" component={renderField} type="checkbox" label={"Active"} className='field' />}
            <Button variant='contained' color='primary' className="raised-button" onClick={handleSubmit} >{buttonText}</Button>
        </form>
    )
};

export default reduxForm<any, DSPProductCategoryOrBrandFormProps>({
    form: 'DSPProductCategoryOrBrandForm',
    validate
})(DSPProductCategoryOrBrandForm);