import React, { useState, useRef, useEffect } from 'react';
import { ExpandMore, ExpandLess } from "@material-ui/icons"
import 'react-virtualized/styles.css';

import {
    AutoSizer,
    Column,
    Table,
    defaultTableRowRenderer,
} from "react-virtualized";



interface VirtualizedExpandableTableColumnProps {
    label: string;
    dataKey: string;
    width: number;
    cellDataGetter?: () => any;
    cellRenderer?: () => any;
}


/**Virtualized Table with Expandable Rows
*
* Notes for props:
*  - data prop must have expandedRowContent property. This provides the content for the expanded row.
*  - if providing parentSelectedIndex, intial value of state in parent should be -1
*
* --based on: https://codesandbox.io/s/71l1zkrqmx?file=/src/Component.js
* */

const InvoiceVirtualizedTable = (props) => {
    const { transactionHistory, columns, customOnRowClick, parentSelectedIndex, parentSetSelectedIndex,
        collapsedRowHeight = 48, expandedRowHeight = 96, optionalTableProps = {}, disableSort = true, optionalExpansion = false,
        idxOfComponent
    } = props;

    const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(-1);
    const [showHeader, setShowHeader] = useState(false);
    const [tableHeight, setTableHeight] = useState(transactionHistory.length * 65);

    // Either this component's state, or state in the parent, may be used to store the selected index.
    // selectedIndex and setSelectedIndex are used to avoid additional conditional statements throughout
    const selectedIndex = parentSelectedIndex ? parentSelectedIndex : defaultSelectedIndex;
    const setSelectedIndex = parentSetSelectedIndex ? parentSetSelectedIndex : setDefaultSelectedIndex;

    const tableRef = useRef<any>();

    //TODO: look into replacing with CellMeasurer
    const _getRowHeight = ({ index }) => index === selectedIndex ? expandedRowHeight : collapsedRowHeight;
    const rowGetter = ({ index }) => transactionHistory[index % transactionHistory.length];



    useEffect(() => {
        tableRef.current.recomputeRowHeights();
    }, [selectedIndex]);


    const defaultExpansionCellRenderer = ({ rowIndex }) => {
        return rowIndex !== selectedIndex ? <ExpandMore /> : <ExpandLess />
    };

    //if row is selected, renders row with defaultTableRowRenderer + additional information
    const rowRenderer = props => {
        const { index, style, className, key, rowData } = props;
        if (index === selectedIndex) {
            return (
                <div
                    style={{ ...style, display: 'flex', flexDirection: 'column', borderBottom: '1px solid black' }}
                    className={className}
                    key={key}
                >
                    {defaultTableRowRenderer({
                        ...props,
                        style: { width: style.width, height: 48 }
                    })}
                    <div
                        style={{
                            marginRight: 'auto',
                            marginLeft: 80,
                            height: 48,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {rowData.expandedRowContent}
                    </div>
                </div>
            )
        }
        return defaultTableRowRenderer(props);
    }

    /**Default click handler for a table row*/
    const defaultOnRowClick = ({ event, index, rowData }) => {
        if (selectedIndex === index) {
            //row is already expanded - click will close the row
            setSelectedIndex(-1);
        } else {
            //click will expand the row
            setSelectedIndex(index);
        }
    }

    const onRowClick = customOnRowClick
        ? ({ event, index, rowData }) => customOnRowClick(event, index, rowData)
        : defaultOnRowClick;


    /**Default accessor for returning a cell value for a given attribute.
     * This function expects to operate on either a vanilla Object or an Immutable Map.
     *
     * (from React-Virtualized)
     * */
    const defaultCellDataGetter = ({ dataKey, rowData }) => {
        if (typeof rowData.get === 'function') {
            return rowData.get(dataKey);
        } else {
            return rowData[dataKey];
        }
    }

    /**Default cell renderer that displays an attribute as a simple string
     * You should override the column's cellRenderer if your data is some other type of object.
     *
     * (from React-Virtualized)
     */
    const defaultCellRenderer = ({ cellData }): string => {
        if (cellData == null) {
            return '';
        } else {
            return String(cellData);
        }
    }
    useEffect(() => {
        if (idxOfComponent > 0) { setShowHeader(true); }

        if (idxOfComponent === 0) {
            setTableHeight(60);
        }

    }, [])

    return (
        <div style={{ height: tableHeight, width: "100%" }}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        headerHeight={showHeader ? 0 : 50}
                        overscanRowCount={10}
                        rowHeight={_getRowHeight}
                        rowGetter={rowGetter}
                        rowCount={transactionHistory.length}
                        minWidth={600}
                        width={width}
                        height={height}
                        ref={tableRef}
                        rowRenderer={rowRenderer}
                        // onRowClick={onRowClick}
                        {...optionalTableProps}
                    >
                        {columns.map(column => (
                            <Column
                                key={column.dataKey}
                                dataKey={column.dataKey}
                                disableSort={disableSort}
                                style={{ display: "flex", flexGrow: 1 }}
                                label={column.label}
                                width={column.width}
                                minWidth={column.minWidth}
                                cellDataGetter={column.cellDataGetter || defaultCellDataGetter}
                                cellRenderer={column.cellRenderer || defaultCellRenderer}
                            />
                        ))}

                        {/* <Column
                            label={''}
                            //TODO comment cellDataGetter out to see what it does
                            //We do not want cellDataGetter to actually display any data, which is why we set it so that data at this index does not exist
                            cellDataGetter={({ rowData }) => rowData.length}
                            cellRenderer={defaultExpansionCellRenderer}
                            dataKey={'index'}
                            disableSort
                            width={60}
                        /> */}

                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}

export default InvoiceVirtualizedTable;