import React, {Fragment, useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {
   UNCOMPLETE_ORDER_FAILURE, uncompleteOrder
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";


export const UncompleteOrderButton:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const dispatch = useDispatch();

    const uncompleteCompletedOrder = (order) => {
        setDisableButtonCallBack(true)
        dispatch<any>(uncompleteOrder(order.id)).then((response)=> {
            setDisableButtonCallBack(false);
            if(response.type === UNCOMPLETE_ORDER_FAILURE) {
                setExtraOrderFunctionalityErrorCallBack(response.error);
            } else {
                setExtraOrderFunctionalityErrorCallBack("");
                if(closeModal) closeModal();
            }
        })
    }

    useEffect(() => {
        if(order.orderStatus === "completed") setShowBasedOnOrderStatus(true);
            }, []);

    return showBasedOnOrderStatus && <Fragment>
            <Button variant="contained" color="primary" disabled={disableExtraButtons} onClick={()=> uncompleteCompletedOrder(order)}>
                Uncomplete
            </Button>
        </Fragment>
}