import { CALL_API, Schemas } from '../middleware/api';
import { getDSPR } from './dsprActions';

export const GET_ALL_ZIP_CODES_FOR_DSPR = 'GET_ALL_ZIP_CODES_FOR_DSPR';
export const GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS = 'GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS';
export const GET_ALL_ZIP_CODES_FOR_DSPR_FAILURE = 'GET_ALL_ZIP_CODES_FOR_DSPR_FAILURE';

const zipCodesGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ALL_ZIP_CODES_FOR_DSPR, GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS, GET_ALL_ZIP_CODES_FOR_DSPR_FAILURE],
            endPoint: 'dspr/zip_code',
            schema: Schemas.DSPR_ZIPCODE_ARRAY,
            queryParamsMap: { dspr_id: dsprId }
        }
    };
};

export const getAllZipCodesForDSPR = (dsprId) => (dispatch, getState) => {
    return dispatch(zipCodesGetter(dsprId));
};

export const ADD_DSPR_ZIP_CODE = 'ADD_DSPR_ZIP_CODE';
export const ADD_DSPR_ZIP_CODE_SUCCESS = 'ADD_DSPR_ZIP_CODE_SUCCESS';
export const ADD_DSPR_ZIP_CODE_FAILURE = 'ADD_DSPR_ZIP_CODE_FAILURE';

const dsprZipCodeCreator = (dsprId, zipCode) => {
    const dsprZipCode = {
        dspr: {
            id: dsprId
        },
        zipCode: zipCode
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ADD_DSPR_ZIP_CODE, ADD_DSPR_ZIP_CODE_SUCCESS, ADD_DSPR_ZIP_CODE_FAILURE],
            endPoint: 'dspr/zip_code',
            schema: Schemas.DSPR_ZIPCODE,
            body: dsprZipCode
        }
    };
};

export const createDSPRZipCode = (dsprId, zipCode) => (dispatch, getState) => {
    return dispatch(dsprZipCodeCreator(dsprId, zipCode))
        .then((response) => {
            dispatch(getDSPR(dsprId))
            return response;
        });
};

export const TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS = 'TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS';
export const TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS = 'TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS';
export const TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_FAILURE = 'TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_FAILURE';

const dsprZipCodeActiveStatusToggler = (dsprZipCodeId, isCurrentlyActive) => {
    const dsprZipCode = {
        id: dsprZipCodeId
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS, TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS, TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_FAILURE],
            endPoint: isCurrentlyActive ? 'dspr/zip_code/deactivate' : 'dspr/zip_code/activate',
            schema: Schemas.DSPR_ZIPCODE,
            body: dsprZipCode
        }
    };
};

export const toggleDSPRZipCodeActiveStatus = (dsprZipCodeId) => (dispatch, getState) => {
    return dispatch(dsprZipCodeActiveStatusToggler(dsprZipCodeId, getState().api.entities.dsprZipCodes[dsprZipCodeId].active));
};

export const ZIP_CODE_MINIMUM_CHANGE = 'ZIP_CODE_MINIMUM_CHANGE';
export const ZIP_CODE_MINIMUM_CHANGE_SUCCESS = 'ZIP_CODE_MINIMUM_CHANGE_SUCCESS';
export const ZIP_CODE_MINIMUM_CHANGE_FAILURE = 'ZIP_CODE_MINIMUM_CHANGE_FAILURE';

const zipCodeMinChanger = (dsprZipCodeId, newMin) => {
    const dsprZipCode = {
        id: dsprZipCodeId,
        minimumOrderSizeOverride: newMin
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ZIP_CODE_MINIMUM_CHANGE, ZIP_CODE_MINIMUM_CHANGE_SUCCESS, ZIP_CODE_MINIMUM_CHANGE_FAILURE],
            endPoint: 'dspr/zip_code/change-min',
            schema: Schemas.DSPR_ZIPCODE,
            body: dsprZipCode
        }
    };

};

export const changeZipCodeMin = (dsprZipCodeId, newMin) => (dispatch, getState) => {
    return dispatch(zipCodeMinChanger(dsprZipCodeId, newMin));
};