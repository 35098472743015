import React, { Dispatch, SetStateAction } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import './SnackBarAlertStyles.scss';

interface SnackBarAlertErrorProps {
    showSnackbarAlert: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    alertTitle?: string;
    alertText?: string;
    verticalAnchorOrigin?: 'top' | 'bottom';
    horizontalAnchorOrigin?: 'center' | 'left' | 'right';
    snackbarStyle?: any;
}

const SnackBarAlertError = ({
                                showSnackbarAlert,
                                setIsVisible,
                                alertTitle = 'Error Encountered!',
                                alertText = 'Success!',
                                verticalAnchorOrigin = 'top',
                                horizontalAnchorOrigin = 'center',
                                snackbarStyle,
                            }: SnackBarAlertErrorProps) => {

    return (
        <Snackbar
            open={showSnackbarAlert}
            anchorOrigin={{vertical: verticalAnchorOrigin, horizontal: horizontalAnchorOrigin}}
            className={'snackbar-alert-container'}
            onClose={(evt, reason) => {
                //prevent alert from being closed when clicking outside of alert
                if (reason === 'clickaway') return;
                setIsVisible(false);
            }}
            style={{...snackbarStyle}}
        >
            <>
                {/*prevents alert from re-rendering*/}
                <Alert severity={'error'} onClose={() => setIsVisible(false)} className={'snackbar-alert'}>
                    <div className={'snackbar-alert-message'}>
                        <AlertTitle>{alertTitle}</AlertTitle>
                        {alertText}
                    </div>
                </Alert>
            </>
        </Snackbar>
    )
}

export default SnackBarAlertError;