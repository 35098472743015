import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card,
    CardMedia,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Dialog,
    CircularProgress,
    Grid,
} from '@material-ui/core';

import { RouteComponentProps } from 'react-router-dom';

import { getDocumentImage } from '../middleware/api';
import { User, IdDocument, MedicalRecommendation, Order } from '../store/reduxStoreState'

import {
    getSpecificUser,
    getUserIdDocument,
    getUserMedicalRecommendation,
    verifyUserDocument,
    adminChangesPassword,
    uploadUserDocument,
    USER_ID_DOCUMENT,
    USER_MEDICAL_RECOMMENDATION,
    adminChangesPhone,
    getOrderHistoryForUser,
    createUserNote,
    getAllUserIdDocuments,
    setUserIdDocument,
    getAllUserMedicalRecommendations,
    setUserMedicalRecommendation,
    hideUserNote,
    unhideUserNote,
    adminChangesUserInfo,
    hideUserDocument,
    unhideUserDocument,
} from '../actions/userActions';

import { getUserFromProps, getLoggedInUser, getUserNotesFromProps } from '../selectors/userSelectors';
import { getUserIdDocumentFromProps, getUserMedicalRecommendationFromProps, getUserIdDocuments, getUserMedicalRecommendations } from '../selectors/userDocumentsSelector';
import { getOrdersForUser } from "../selectors/orderSelectors";

import { parseDate } from '../util/util';

import NavPills from "../components/NavPills/NavPills"
import UserIdDocumentationVerificationForm from './UserIdDocumentVerificationForm';
import UserMedicalRecommendationVerificationForm from './UserMedicalRecommendationVerificationForm';
import ResetPasswordForm from '../components/ResetPasswordForm';
import UserDocumentUploadForm from '../components/UserDocumentUploadForm';
import UserNotes from "../components/UserNotes";
import OrderWithDetailsAndPrices from "./OrderWithDetailsAndPrices";
import ResetUserInfoForm from "./ResetUserInfoForm";

import "./UserPageStyles.scss"

interface UserPageProps extends RouteComponentProps {
    user: User,
    idDocument: IdDocument,
    allIdDocuments: { [key:number] : IdDocument }
    allMedicalRecommendations: { [key:number] : MedicalRecommendation }
    medicalRecommendation: MedicalRecommendation,
    orders: Order[],
    loggedInUser: User,
    userNotes: any[],
    getSpecificUser: (userId: number) => any,
    getUserIdDocument: (userId: number) => any,
    getUserMedicalRecommendation: (userId: number) => any,
    getOrderHistoryForUser: (userId: number) => any,
    verifyUserDocument: (userId: number, documentId: string, documentType: string, document: any) => any,
    uploadUserDocument: (userId: number, docType: string, imageFile: any) => any,
    adminChangesPassword: (userId: number, newPassword: string) => any,
    adminChangesPhone: (userId: number, newPhone: string) => any,
    adminChangesUserInfo: (userId: number, firstName: string, lastName: string, newPhone: string) => any,
    createUserNote: (userId: number, note: string, dsprDriverId: number, dsprManagerId: number) => any,
    setUserIdDocument: (documentId: number) => any,
    getAllUserIdDocuments: (userId: number) => any,
    getAllUserMedicalRecommendations: (userId: number) => any,
    setUserMedicalRecommendation: (documentId:number) => any,
    hideUserNote: (noteId: number) => any,
    unhideUserNote: (noteId: number) => any,
    hideUserDocument: (documentId: number, docType: string) => any,
    unhideUserDocument: (documentId: number, docType: string) => any,
}

interface UserPageState {
    loading: boolean,
    changePasswordMessage: string | null,
    changePhoneMessage: string | null,
    showChangePasswordForm: boolean,
    showChangePhoneForm: boolean,
    showDialog: boolean,
    uploading: boolean,
    phoneNumber: number | undefined,
    orderForDialog: any | undefined,
    showPreviousIds: boolean,
    showPreviousMedicalRecommendations: boolean
}

class UserPage extends Component<UserPageProps, UserPageState> {

    constructor(props: UserPageProps) {
        super(props);
        this.state = {
            loading: false,
            changePasswordMessage: null,
            changePhoneMessage: null,
            showChangePasswordForm: true,
            showChangePhoneForm: true,
            showDialog: false,
            uploading: false,
            phoneNumber: undefined,
            orderForDialog: undefined,
            showPreviousIds:false,
            showPreviousMedicalRecommendations: false,
        };
        //this.handleChangeUserPhone = this.handleChangeUserPhone.bind(this);
    }

    componentDidMount() {
        const { userId } = this.props.match.params as { userId: number };
        this.props.getSpecificUser(userId);
        this.props.getUserIdDocument(userId);
        this.props.getUserMedicalRecommendation(userId);
        this.setState({ loading: true });
        this.props.getOrderHistoryForUser(userId)
            .then(() => this.setState({ loading: false }));
    }

    componentWillReceiveProps(newProps) {
        if (newProps.user) {
            this.setState({ phoneNumber: newProps.user.phoneNumber });
        }
    }

    handleDocumentForm = (userId, documentId, documentType) => (document) => {
        const modifiedDocument = {};
        Object.keys(document).forEach((key) => modifiedDocument[key] = (document[key] instanceof Date) ? document[key].toISOString() : document[key])
        this.props.verifyUserDocument(userId, documentId, documentType, modifiedDocument);
    };

    handleUploadUserDocument = (userId, docType) => (formValues) => {
        const imageFile = formValues.imageFile[0];
        this.setState({ uploading: true });
        this.props.uploadUserDocument(userId, docType, imageFile)
            .then((response) => {
                if (response && response.error) {
                    alert(response.error);
                    this.setState({ uploading: false });
                }
                else {
                    this.setState({ uploading: false });
                }
            }, (error) => {
                const errorMessage = error.message ? error.message : error.error;
                alert(errorMessage);
                this.setState({ uploading: false });
            });
    };

    handleDocumentHide = (documentId, docType) => {
        this.props.hideUserDocument(documentId, docType)
    }

    handleDocumentUnhide = (documentId, docType) => {
        this.props.unhideUserDocument(documentId, docType)
    }

    handleChangeUserInfo = (userId) => (values) => {
        this.props.adminChangesUserInfo(userId, values.firstName, values.lastName, values.phoneNumber)
    }

    handleChangeUserPassword = (userId) => (values) => {
        this.setState({ showChangePasswordForm: false });
        this.props.adminChangesPassword(userId, values.newPassword)
            .then(
                (response) => {
                    if (response && !response.error) this.setState({
                        showChangePasswordForm: true,
                        changePasswordMessage: "A text has been sent to the user with their new password."
                    });
                    else {
                        if (!response) {
                            this.setState({
                                showChangePasswordForm: true,
                                changePasswordMessage: "Something went wrong. Please contact Tyler."
                            });
                        }
                        else {
                            this.setState({
                                showChangePasswordForm: true,
                                changePasswordMessage: response.error
                            });
                        }
                    }
                },
                (error) => {
                    this.setState({
                        showChangePasswordForm: true,
                        changePasswordMessage: error
                    });
                });
    };

    handleChangeUserPhone = (userId) => (values) => {
        this.setState({ showChangePhoneForm: false });
        this.props.adminChangesPhone(userId, values.newPhone)
            .then(
                (response) => {
                    if (response && !response.error) {
                        this.setState({
                            showChangePhoneForm: true,
                            changePhoneMessage: "The phone number has been updated.",
                            phoneNumber: values.newPhone
                        });
                    } else {
                        if (!response) {
                            this.setState({
                                showChangePhoneForm: true,
                                changePhoneMessage: "Something went wrong."
                            });
                        }
                        else {
                            this.setState({
                                showChangePhoneForm: true,
                                changePhoneMessage: response.error
                            });
                        }
                    }
                },
                (error) => {
                    this.setState({
                        showChangePhoneForm: true,
                        changePhoneMessage: error
                    });
                });
    };

    handleSetCurrentClick (documentId: number, documentType: string) {
        switch(documentType){
            case USER_MEDICAL_RECOMMENDATION:
                this.props.setUserMedicalRecommendation(documentId);
                break;
            case USER_ID_DOCUMENT:
                this.props.setUserIdDocument(documentId);
                break;
        }
        
    }

    createDocumentCard (userDocument: IdDocument | MedicalRecommendation, documentType: string, user: User) {
        const current = userDocument && documentType === USER_ID_DOCUMENT ?
            userDocument.id === user.identificationDocument :
            userDocument.id === user.medicalRecommendation;
        return userDocument && userDocument.fileName ? (<Card>
            <CardHeader title={`${current ? 'CURRENT,' : ''} ${userDocument && !userDocument.verified ? 'UN' : ''}VERIFIED`}/>
            {!userDocument.fileName.toLowerCase().includes(".pdf") &&
                    <CardMedia style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={getDocumentImage(documentType, userDocument.fileName)} alt={documentType} style={{ width: "90%"}} />
                    </CardMedia>}
                {userDocument.fileName.toLowerCase().includes(".pdf") &&
                    <CardActions>
                        <span>Right click to open in a new tab</span>
                        <Button href={getDocumentImage(documentType, userDocument.fileName)}>View PDF</Button>
                    </CardActions>
                }
            <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant='contained' 
                    color='primary' 
                    style={{width: '100%'}}
                    disabled={documentType === USER_ID_DOCUMENT ? userDocument.id === user.identificationDocument : userDocument.id === user.medicalRecommendation}
                    onClick={() => this.handleSetCurrentClick(userDocument.id, documentType)}>Set as Current</Button>
            </CardActions>
        </Card>) : null
    }

    handleGetPastUserDocumentsClick = () => {
        this.props.getAllUserIdDocuments(this.props.user.id).then(()=> this.setState({showPreviousIds: true}))
    }

    handleGetPastUserMedicalRecommendationsClick = () => {
        this.props.getAllUserMedicalRecommendations(this.props.user.id).then(()=> this.setState({showPreviousMedicalRecommendations: true}))
    }

    closeOrderDetailsDialog = () => {
        this.setState({ showDialog: false, orderForDialog: null });
    }

    getOrderRow = (order) => {
        const date = parseDate(order.createdTime);
        const { driver } = order;
        const statusChangeMinutes = Math.round((parseDate(order.updatedTime).valueOf() - parseDate(order.createdTime).valueOf()) / 1000 / 60);
        const promoCodes = order.coupons ? order.coupons.map(coupon => coupon.code).join("\n") : "";

        return <TableRow key={order.id} onClick={() => { this.setState({ showDialog: true, orderForDialog: order }) }}>
            <TableCell>{order.id}</TableCell>
            <TableCell>{date.toLocaleString("en-us", { month: "long" })} {date.getDate()}, {date.getFullYear()}</TableCell>
            <TableCell>{date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
            <TableCell>{order.orderStatus}</TableCell>
            <TableCell>{driver.firstName} {driver.lastName}</TableCell>
            <TableCell>{statusChangeMinutes}</TableCell>
            <TableCell>${order.cashTotal}</TableCell>
            <TableCell>{promoCodes.toUpperCase()}</TableCell>
            <TableCell>${order.discountTotal}</TableCell>
        </TableRow>
    };

    render() {

        const { user, idDocument, medicalRecommendation, allIdDocuments, allMedicalRecommendations } = this.props;
        const onLoggedInUserPage = user && user.id === this.props.loggedInUser.id
        const filteredIdDocuments = user && Object.values(allIdDocuments).filter(idDocument => !onLoggedInUserPage ? this.props.loggedInUser.identificationDocument !== idDocument.id : idDocument)
        const filteredMedicalRecommendationDocuments = user && Object.values(allMedicalRecommendations).filter(medicalRecommendation => !onLoggedInUserPage ? this.props.loggedInUser.medicalRecommendation !== medicalRecommendation.id : medicalRecommendation)
        const idDocumentVerifiedLabel = `${idDocument && !idDocument.verified ? 'UN' : ''}VERIFIED${idDocument && !idDocument.verified && !idDocument.visible ? ' AND HIDDEN' : ''}`; //only add 'And Hidden' if document is not verified - the hidden attribute only pertains to unverified Ids
        const identificationDocumentCard = idDocument && idDocument.fileName && <div className={'user-document-container'}>
            <Card className="user-document">
                <CardHeader title={idDocumentVerifiedLabel} className={'identification-document-header'}/>
                {!idDocument.fileName.toLowerCase().includes(".pdf") &&
                    <CardMedia>
                        <img src={getDocumentImage(USER_ID_DOCUMENT, idDocument.fileName)} alt={USER_ID_DOCUMENT} />
                    </CardMedia>}
                {idDocument.fileName.toLowerCase().includes(".pdf") &&
                    <CardActions>
                        <span>Right click to open in a new tab</span>
                        <Button href={getDocumentImage(USER_ID_DOCUMENT, idDocument.fileName)}>View PDF</Button>
                    </CardActions>
                }
                <CardContent>
                    <UserIdDocumentationVerificationForm
                        identificationDocument={idDocument ? idDocument : null}
                        onSubmit={this.handleDocumentForm(user.id, idDocument.id, USER_ID_DOCUMENT)}
                        handleHide={(id) => this.handleDocumentHide(id, USER_ID_DOCUMENT)}
                        handleUnhide={(id) => this.handleDocumentUnhide(id, USER_ID_DOCUMENT)}
                    />
                </CardContent>
            </Card>
        </div>;

        const medicalRecommendationVerifiedLabel = `${medicalRecommendation && !medicalRecommendation.verified ? 'UN' : ''}VERIFIED${medicalRecommendation && !medicalRecommendation.verified && !medicalRecommendation.visible ? ' AND HIDDEN' : '' }`; // the hidden attribute only pertains to unverified recs
        const medicalRecommendationCard = medicalRecommendation && medicalRecommendation.fileName && <div className={'user-document-container'}>
            <Card className={'user-document'}>
                <CardHeader title={medicalRecommendationVerifiedLabel} className={'medical-recommendation-header'}/>
                {!medicalRecommendation.fileName.toLowerCase().includes(".pdf") &&
                    <CardMedia>
                        <img src={getDocumentImage(USER_MEDICAL_RECOMMENDATION, medicalRecommendation.fileName)} alt={USER_MEDICAL_RECOMMENDATION} />
                    </CardMedia>}
                {medicalRecommendation.fileName.toLowerCase().includes(".pdf") &&
                    <CardActions>
                        <span>Right click to open in a new tab</span>
                        <Button href={getDocumentImage(USER_MEDICAL_RECOMMENDATION, medicalRecommendation.fileName)}>View PDF</Button>
                    </CardActions>
                }
                <CardContent>
                    <UserMedicalRecommendationVerificationForm
                        medicalRecommendation={medicalRecommendation}
                        onSubmit={this.handleDocumentForm(user.id, medicalRecommendation.id, USER_MEDICAL_RECOMMENDATION)}
                        handleHide={(id) => this.handleDocumentHide(id, USER_MEDICAL_RECOMMENDATION)}
                        handleUnhide={(id)=> this.handleDocumentUnhide(id, USER_MEDICAL_RECOMMENDATION)}
                    />
                </CardContent>
            </Card>
        </div>;

        const dsprManagerId = this.props.loggedInUser ?
            this.props.loggedInUser.dsprManagers ?
                this.props.loggedInUser.dsprManagers[0]
                : null
            : null;
        
        const userInfoInitialValues = this.props.user && this.props.user.firstName ? {
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            phoneNumber: this.state.phoneNumber
        } : {};
        return <main className="user-page">
            <h2>User</h2>
            <Card>
                {this.props.user && <CardHeader title={`${this.props.user.firstName} ${this.props.user.lastName}, ${this.props.user.email}, ${this.state.phoneNumber}`} />}
                {this.props.user && <CardContent>
                    <NavPills
                        color='rose'
                        tabs={[
                            {
                                tabButton: "Manage User Info",
                                tabContent: <div>
                                    <h3>Change User Info</h3>
                                    <Card className="user-page-card">
                                        <CardContent>
                                            <ResetUserInfoForm onSubmit={this.handleChangeUserInfo(this.props.user.id)} initialValues={userInfoInitialValues} />
                                            <div>{this.state.changePasswordMessage}</div>
                                        </CardContent>
                                    </Card>
                                    <h3>Change Password</h3>
                                    <Card className="user-page-card">
                                        <CardContent>
                                            {this.state.showChangePasswordForm && <ResetPasswordForm onSubmit={this.handleChangeUserPassword(this.props.user.id)} />}
                                        </CardContent>
                                    </Card>
                                    <h3>User Notes</h3>
                                    <UserNotes
                                        createUserNote={this.props.createUserNote}
                                        userId={this.props.user.id}
                                        dsprManagerId={dsprManagerId}
                                        userNotes={this.props.userNotes}
                                        hideUserNote={this.props.hideUserNote}
                                        unhideUserNote={this.props.unhideUserNote}
                                        refreshUser={() => this.props.getSpecificUser(this.props.user.id)}
                                        showTitle={false}
                                    />
                                </div>
                            },
                            {
                                tabButton: "Identification Document",
                                tabContent: <div style={{overflow: 'hidden'}}>
                                    {!this.state.uploading &&
                                        <div>
                                            {user.identificationDocument && <h3>Current Identification Document</h3>}
                                            {identificationDocumentCard}
                                            <h3>Upload Identification Document</h3>
                                            <Card className={'user-page-card'}>
                                                <CardContent>
                                                    <UserDocumentUploadForm onSubmit={this.handleUploadUserDocument(this.props.user.id, USER_ID_DOCUMENT)} submitLabel="Upload" />
                                                </CardContent>
                                            </Card>
                                        </div>}
                                    {user.identificationDocument && <h3>Past Identification Documents</h3>}
                                    {user.identificationDocument && <div className="get-previous-documents-button">
                                        <Button variant='contained' color='primary' onClick={() => this.handleGetPastUserDocumentsClick()}>Get Past Id Documents</Button>
                                    </div>}
                                    {this.state.showPreviousIds && <div className='previous-ids-container'>
                                        {user && <Card className='user-page-card'>
                                            <CardContent>
                                                <Grid container spacing={3}>                                        
                                                    {filteredIdDocuments && filteredIdDocuments.sort((a,b) => b.id - a.id).map(document => <Grid item xs={12} sm={12} md={12} lg={6} key={USER_ID_DOCUMENT + '-' + document.id}>
                                                        {this.createDocumentCard(document, USER_ID_DOCUMENT, user)}
                                                    </Grid>)}
                                                </Grid>
                                            </CardContent>
                                        </Card>} 
                                    </div>}
                                </div>,
                            },
                            {
                                tabButton: "Medical Recommendation",
                                tabContent: <div style={{overflow: 'hidden'}}>
                                    {!this.state.uploading &&
                                        <div>
                                            {user.medicalRecommendation && <h3>Current Recommendation</h3>}
                                            {medicalRecommendationCard}
                                            <h3>Upload Recommendation</h3>
                                            <Card className={'user-page-card'}>
                                                <CardContent>
                                                    <UserDocumentUploadForm onSubmit={this.handleUploadUserDocument(this.props.user.id, USER_MEDICAL_RECOMMENDATION)} submitLabel="Upload" />
                                                </CardContent>
                                            </Card>
                                        </div>}
                                        {user.medicalRecommendation && <h3>Past Medical Recommendations</h3>} 
                                    {user.medicalRecommendation &&<div className={'get-previous-documents-button'}>
                                        <Button variant='contained' color='primary' onClick={() => this.handleGetPastUserMedicalRecommendationsClick()}>Get Past Medical Recommendations</Button>
                                    </div>} 
                                    {this.state.showPreviousMedicalRecommendations && <div className='previous-recommendations-container'>
                                        {user && <Card className='user-page-card'>
                                            <CardContent>
                                            {filteredMedicalRecommendationDocuments && filteredMedicalRecommendationDocuments.length > 0 && <Grid container spacing={3}>                                        
                                                    {filteredMedicalRecommendationDocuments.sort((a,b) => b.id - a.id).map(document => <Grid item xs={12} sm={12} md={12} lg={6} key={USER_MEDICAL_RECOMMENDATION + '-' + document.id}>
                                                        {this.createDocumentCard(document, USER_MEDICAL_RECOMMENDATION, user)}
                                                    </Grid>)}
                                                </Grid>}
                                                {filteredMedicalRecommendationDocuments && filteredMedicalRecommendationDocuments.length === 0 && <p>User Does Not Have Any Previous Medical Recommendations</p>}
                                            </CardContent>
                                        </Card>}
                                    </div>}
                                </div>,
                            },
                            {
                                tabButton: "Order History",
                                tabContent: <div>
                                    {this.state.loading &&
                                        <CircularProgress />
                                    }
                                    {!this.state.loading &&
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Time</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Driver</TableCell>
                                                    <TableCell>Delivery Minutes</TableCell>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Promo Code(s)</TableCell>
                                                    <TableCell>Discount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.props.orders &&
                                                <TableBody>
                                                    {this.props.orders &&
                                                        this.props.orders.map(order => this.getOrderRow(order))}
                                                </TableBody>}
                                        </Table>
                                    }
                                    <Dialog open={this.state.showDialog}
                                        scroll='body'
                                        onClose={this.closeOrderDetailsDialog}>
                                        {this.state.orderForDialog ?
                                            <OrderWithDetailsAndPrices
                                                order={this.state.orderForDialog}
                                                user={user}
                                                address={this.state.orderForDialog.address} 
                                                closeModal={this.closeOrderDetailsDialog}
                                            /> : <p>This User has no open orders</p>}
                                                
                                    </Dialog>
                                </div>,
                            },
                        ]} />
                </CardContent>}
            </Card>
        </main>
    }
}

const mapStateToProps = (state, ownProps) => {
    const { userId } = ownProps.match.params as { userId: number };
    return {
        user: getUserFromProps(state, { userId }),
        idDocument: getUserIdDocumentFromProps(state, { userId }),
        medicalRecommendation: getUserMedicalRecommendationFromProps(state, { userId }),
        allIdDocuments: getUserIdDocuments(state),
        allMedicalRecommendations: getUserMedicalRecommendations(state),
        orders: getOrdersForUser(state, { userId }),
        loggedInUser: getLoggedInUser(state),
        userNotes: getUserNotesFromProps(state, { userId })
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getSpecificUser,
        getUserIdDocument,
        setUserIdDocument,
        getAllUserIdDocuments,
        getUserMedicalRecommendation,
        setUserMedicalRecommendation,
        getAllUserMedicalRecommendations,
        verifyUserDocument,
        adminChangesPassword,
        adminChangesPhone,
        adminChangesUserInfo,
        uploadUserDocument,
        getOrderHistoryForUser,
        createUserNote,
        hideUserNote,
        unhideUserNote,
        hideUserDocument,
        unhideUserDocument,
    },
        dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);