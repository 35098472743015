import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import numeral from 'numeral';


import { getDriverAnalytics, getDSPR, getDSPRAudits, getAuditDetails } from '../actions/dsprActions';
import { getAllDriversForDspr, rebuildDriverAnalytics } from '../actions/driverActions'
import { getDriversForDSPR } from "../selectors/dsprDriverSelector";
import { State, DsprDriver, User, Analytic, DSPR, Audit, InventoryAuditReportRow, DSPRProductInventoryTransactionHistory } from '../store/reduxStoreState';
import { parseDate } from "../util/util";
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "../variables/charts";
import List from "@material-ui/core/List";
import AnalyticsDisplay from "../components/AnalyticsDisplay";
import GridContainer from '../components/Grid/GridContainer';

import "./AuditContainerStyles.scss"

import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Input,
    ListItem,
    MenuItem,
    TableCell,
    TableRow
} from '@material-ui/core';

import '../components/AnalyticsDisplayStyles.scss';
import AnalyticsUtil from "../util/AnalyticsUtil";
import { getDSPRFromProps, getDSPRHasComplianceFromProps } from "../selectors/dsprSelectors";
import CardHeader from '../components/Card/CardHeader';
import GridItem from '../components/Grid/GridItem';
import { getDSPRAuditFromProps, getDSPRAuditsFromProps } from '../selectors/auditSelectors';
import { CheckBox, GetApp, LocalActivity } from '@material-ui/icons';
import { getAudit } from '../middleware/api';
import VirtualizedTable from '../components/VirtualizedTable';

import "./AuditViewContainerStyles.scss"
import CheckboxToggleForm from '../components/CheckboxToggleForm';

/**Audit Container*/
function AuditViewContainer() {

    const {formatDateForTable, formatDateForChart} = AnalyticsUtil;
    const [search, setSearch] = useState<string>('');
    const [showOnlyIssueRows, setShowOnlyIssueRows] = useState<boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const {dsprId, auditId} = useParams<{ dsprId: string, auditId: string }>();

    const dsprDrivers = useSelector<State, (Partial<Omit<DsprDriver, 'user'> & { user: User }>)[]>(state => (
        getDriversForDSPR(state, {dsprId})), shallowEqual);

    const dspr = useSelector<State, DSPR>(state => dsprId && getDSPRFromProps(state, {dsprId: parseInt(dsprId)}), shallowEqual);
    const hasCompliance = useSelector<State, Boolean>(state => dsprId && getDSPRHasComplianceFromProps(state, {dsprId: parseInt(dsprId)}))
    const audit = useSelector<State, Audit>(state => auditId && getDSPRAuditFromProps(state, {auditId: parseInt(auditId)}), shallowEqual);

    const [isLoading, setIsLoading] = useState(false);
    const [tableBody, setTableBody] = useState([]);
    const [name, setName] = useState<string>('');

    useEffect(() => {
        if (dsprId && auditId && (!audit || !audit.productRows)) {
            if(dspr && dspr.name) {
                setName(dspr.name + " Audit: Loading Details")
            } else {
                setName("View Audit")
            }
            refreshReport();
        } else {
            setName(dspr.name + " Audit: " +audit.name)
        }

    }, [dsprId,audit,dspr,dispatch]);

    const filteredProductRows = useMemo(() => {
        if(!audit || !audit.productRows) return null;
        if(!search && !showOnlyIssueRows) return audit.productRows
        return audit.productRows.filter(row => !showOnlyIssueRows || row.unaccountedInventory != 0).filter((row) => row.product.name.toLocaleLowerCase().includes(search) || 
            row.complianceNumber.toLocaleLowerCase().includes(search) || 
            row.id.toLocaleString().includes(search))
        
    }, [audit, search, showOnlyIssueRows])

    const refreshReport = () => {
        setIsLoading(true);
            Promise.all([dispatch<any>(getAuditDetails(auditId))]).then(() => setIsLoading(false))
    }

    const getDatesForAnalytics = useCallback((analytics: Analytic) => {
        if (!analytics) return '';
        return formatDateForTable(analytics);
    }, [formatDateForTable]);

    const headers = useMemo(() => {
            return <div key="audit-header" className='audit-header-row'>
                <span className='number'>Product Id</span>
                {hasCompliance ? <span>Compliance #</span> : null}
                <span>Product Name</span>
                <span className='number'>Start Inventory</span>
                <span className='number'>End Inventory</span>
                <span className='number'>Net Change</span>
                <span className='number'>Quantity Added</span>
                <span className='number'>Quantity Sold</span>
                <span className='number'>Calculated Change</span>
                <span className='transactions'>Transactions</span>
                <span className="sales">Sales</span>
                <span className='number'>Inv. Off By</span>
            </div>
        
    }, [dspr, hasCompliance])

    const createTransactions = (transactionsArray : DSPRProductInventoryTransactionHistory[]) => {
        if(transactionsArray && transactionsArray.length > 0) {
            console.log(transactionsArray)
            return (<ul>
                {transactionsArray.map((transaction) => (
                <li>{transaction.quantity}:{transaction.transactionType} - {transaction.notes ? transaction.notes : "No note"}</li>))}
            </ul>)
        }
        return "No Transactions"
    }

    const renderProductAuditRow = (auditProductRow: InventoryAuditReportRow, style: React.CSSProperties) => {
        return <div key={auditProductRow.id} style={style} className="product-audit-row">
            <span className='number'>{auditProductRow.product.id}</span>
            {hasCompliance ? <span>{auditProductRow.complianceNumber}</span> : null}
            <span>{auditProductRow.product.name}</span>
            <span className='number'>{auditProductRow.productStartingInventory}</span>
            <span className='number'>{auditProductRow.productEndingInventory}</span>
            <span className='number'>{auditProductRow.netInventoryChange}</span>
            <span className='number'>{auditProductRow.quantityAdded}</span>
            <span className='number'>{auditProductRow.quantitySold}</span>
            <span className='number'>{auditProductRow.calculatedInventoryChange}</span>
            <span className='transactions'>{auditProductRow.transactions ? createTransactions(auditProductRow.transactions): "No Transactions"}</span>
            {/* <span className='transactions'>{auditProductRow.transactions ? <a>{auditProductRow.transactions.length} Transaction{auditProductRow.transactions.length > 1 ? "s":""}</a>:"No Transactions"}</span> */}
            <span className="sales">{auditProductRow.sales ? <a>{auditProductRow.sales.length} Order{auditProductRow.sales.length > 1 ? "s":""}</a> : "No Sales"}</span>
            <span className='number'>{auditProductRow.unaccountedInventory}</span>
        </div>
    }

    const renderProductAuditRows = ({
                               index,
                               style
                           }) => (filteredProductRows && filteredProductRows.length > 0) ?  renderProductAuditRow(filteredProductRows[index], style): null

    return <main className="audit-view-page">
        <h2>{name}</h2>
        <Card>
            <CardContent>
                <section className="audit-control-section">
                    <div className='audit-action-bar'>
                        <div className='audit-search-bar'>
                            <Input value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search"/>
                            <Button variant='text' color='primary' disabled={search === ""} onClick={() => setSearch("")} className="clear-search">Clear</Button>
                        </div>
                        <div className='audit-actions'>
                            <Button variant='outlined' color="primary" className="audit-action-button" onClick={()=> window.open(getAudit(dsprId, encodeURIComponent(audit.fileName)))} startIcon={<GetApp className='audit-button-icon' color='primary'/>}>
                                Download Report
                            </Button>
                            <Button variant='contained' color='primary' className="audit-action-button" disabled={isLoading} onClick={() => refreshReport()}>Refetch Details</Button>
                        </div>
                    </div>
                    <CheckboxToggleForm handleSubmit={(value) => setShowOnlyIssueRows(value)} formControlLabel={"Only show Issue Rows"}/>
                </section>
                
               

                {isLoading && <div className='audit-view-loading'>
                    <CircularProgress className="horizontal-center"/> Loading Inventory Audit, please wait
                    </div>}
                
                {!isLoading && filteredProductRows && <VirtualizedTable
                rowHeight={100}
                maxHeight={600}
                tableClasses="inventory-audit-table"
                itemCount={filteredProductRows.length}
                renderItems={renderProductAuditRows}
                header={headers}
            />}
            </CardContent>    
        </Card>        
    </main>
}

export default AuditViewContainer;