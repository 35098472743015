import { CALL_API, Schemas } from '../middleware/api';
import { getSpecificUser } from './userActions';

export const GET_DSP = 'GET_DSP';
export const GET_DSP_SUCCESS = 'GET_DSP_SUCCESS';
export const GET_DSP_FAILURE = 'GET_DSP_FAILURE';

const dsp = (dspId, signal: any) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSP, GET_DSP_SUCCESS, GET_DSP_FAILURE],
            endPoint: `delivery_service_provider/${dspId}`,
            schema: Schemas.DELIVERY_SERVICE_PROVIDER,
            signal: signal
        }
    }
};

export const getDSP = (dspId, signal?: any) => (dispatch, getState) => {
    return dispatch(dsp(dspId, signal));
};

export const CREATE_DSP = 'CREATE_DSP';
export const CREATE_DSP_SUCCESS = 'CREATE_DSP_SUCCESS';
export const CREATE_DSP_FAILURE = 'CREATE_DSP_FAILURE';

const dspCreator = (name, managerUserId) => {
    const dspManager = {
        deliveryServiceProvider: {
            name
        },
        managerUser: {
            id: managerUserId
        }
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_DSP, CREATE_DSP_SUCCESS, CREATE_DSP_FAILURE],
            endPoint: 'delivery_service_provider',
            schema: Schemas.DELIVERY_SERVICE_PROVIDER,
            body: dspManager
        }
    };
};

export const createDSP = (name, managerUserId) => (dispatch, getState) => {
    return dispatch(dspCreator(name, managerUserId)).then(() => dispatch(getSpecificUser(managerUserId)));
};

export const ASSIGN_DSP_MANAGER = 'ASSIGN_DSP_MANAGER';
export const ASSIGN_DSP_MANAGER_SUCCESS = 'ASSIGN_DSP_MANAGER_SUCCESS';
export const ASSIGN_DSP_MANAGER_FAILURE = 'ASSIGN_DSP_MANAGER_FAILURE';

const dspManagerAssigner = (dspId, managerUserId) => {
    const dspManager = {
        deliveryServiceProvider: {
            id: dspId
        },
        managerUser: {
            id: managerUserId
        }
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ASSIGN_DSP_MANAGER, ASSIGN_DSP_MANAGER_SUCCESS, ASSIGN_DSP_MANAGER_FAILURE],
            endPoint: 'delivery_service_provider/manager',
            schema: Schemas.DSP_MANAGER,
            body: dspManager
        }
    };
};

export const assignDSPManager = (dspId, managerUserId) => (dispatch, getState) => {
    return dispatch(dspManagerAssigner(dspId, managerUserId))
        .then(() => dispatch(getDSP(dspId))).then(() => dispatch(getSpecificUser(managerUserId)));
};

export const TOGGLE_DSP_MANAGER_ACTIVE_STATUS = 'TOGGLE_DSP_MANAGER_ACTIVE_STATUS';
export const TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS = 'TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS';
export const TOGGLE_DSP_MANAGER_ACTIVE_STATUS_FAILURE = 'TOGGLE_DSP_MANAGER_ACTIVE_STATUS_FAILURE';

const dspManagerActiveStatusToggler = (dspManagerId, isCurrentlyActive) => {
    const dspManager = {
        id: dspManagerId
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [TOGGLE_DSP_MANAGER_ACTIVE_STATUS, TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS, TOGGLE_DSP_MANAGER_ACTIVE_STATUS_FAILURE],
            endPoint: isCurrentlyActive ? 'delivery_service_provider/manager/deactivate' : 'delivery_service_provider/manager/activate',
            schema: Schemas.DSP_MANAGER,
            body: dspManager
        }
    };
};

export const toggleDSPManagerActiveStatus = (dspManagerId) => (dispatch, getState) => {
    return dispatch(dspManagerActiveStatusToggler(dspManagerId, getState().api.entities.dspManagers[dspManagerId].active));
};

export const GET_ALL_DSPS = 'GET_ALL_DSPS';
export const GET_ALL_DSPS_SUCCESS = 'GET_ALL_DSPS_SUCCESS';
export const GET_ALL_DSPS_FAILURE = 'GET_ALL_DSPS_FAILURE';

const allDSPsGetter = () => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ALL_DSPS, GET_ALL_DSPS_SUCCESS, GET_ALL_DSPS_FAILURE],
            endPoint: 'delivery_service_provider/list',
            schema: Schemas.DSP_ARRAY
        }
    }
};

export const getAllDSPs = () => (dispatch, getState) => {
    return dispatch(allDSPsGetter());
};

export const GET_ANALYTICS_FOR_DSP = 'GET_ANALYTICS_FOR_DSP';
export const GET_ANALYTICS_FOR_DSP_SUCCESS = 'GET_ANALYTICS_FOR_DSP_SUCCESS';
export const GET_ANALYTICS_FOR_DSP_FAILURE = 'GET_ANALYTICS_FOR_DSP_FAILURE';

const dspAnalyticsGetter = (dspId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ANALYTICS_FOR_DSP, GET_ANALYTICS_FOR_DSP_SUCCESS, GET_ANALYTICS_FOR_DSP_FAILURE],
            endPoint: `delivery_service_provider/analytics/${dspId}`,
            schema: Schemas.DELIVERY_SERVICE_PROVIDER
        }
    }
};

export const getDSPAnalytics = (dspId) => (dispatch, getState) => {
    return dispatch(dspAnalyticsGetter(dspId));
};

export const REBUILD_ANALYTICS_FOR_DSP = 'REBUILD_ANALYTICS_FOR_DSP';
export const REBUILD_ANALYTICS_FOR_DSP_SUCCESS = 'REBUILD_ANALYTICS_FOR_DSP_SUCCESS';
export const REBUILD_ANALYTICS_FOR_DSP_FAILURE = 'REBUILD_ANALYTICS_FOR_DSP_FAILURE';

const dspAnalyticsRebuilder = (dspId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [REBUILD_ANALYTICS_FOR_DSP, REBUILD_ANALYTICS_FOR_DSP_SUCCESS, REBUILD_ANALYTICS_FOR_DSP_FAILURE],
            endPoint: `delivery_service_provider/analytics/rebuild/${dspId}`,
            schema: Schemas.DELIVERY_SERVICE_PROVIDER
        }
    }
};

export const rebuildDSPAnalytics = (dspId) => (dispatch, getState) => {
    return dispatch(dspAnalyticsRebuilder(dspId));
};
