import React, { useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    Button,
    FormControl, FormControlLabel, FormGroup,
    MenuItem,
    Select, Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

import { DiscountType, DSPRCouponLockType } from "../store/reduxStoreState";
import './DSPRCouponPermissionsFormStyles.scss';
import { connect } from "react-redux";
import SnackBarAlertError from "../components/SnackBarAlertError";
import { UPDATE_DSPR_COUPON_LOCK_TYPES_FAILURE, UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS } from "../actions/couponActions";
import SnackBarAlertSuccess from "../components/SnackBarAlertSuccess";

const renderSelectField = ({
                               input,
                               label,
                               disabled,
                               meta: {touched, error},
                               children,
                               ...custom
                           }) => (
    <Select
        value={input.value}
        {...input}
        onChange={input.onChange}
        disabled={disabled}
        children={children}
        {...custom}
    />
);


const COUPON_TYPES: DiscountType[] = ['absolute', 'percent', 'bogo', 'boghalf', 'btgo'];

interface DSPRCouponPermissionsFormProps {
    isCardExpanded: boolean;
    dsprCouponLocks: DSPRCouponLockType[];
}

const DSPRCouponPermissionsForm = ({
                                       pristine,
                                       reset,
                                       handleSubmit: updateLockTypes,
                                       isCardExpanded,
                                   }: DSPRCouponPermissionsFormProps & InjectedFormProps<{}, DSPRCouponPermissionsFormProps>) => {

    const theme = useTheme();
    const primaryColor = theme.palette.text.primary;
    const secondaryColor = theme.palette.text.disabled;

    const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');

    const areButtonsDisabled: boolean = !isEditingEnabled || pristine || isLoading;

    const renderSelectMenu = (couponType) => {
        return (
            <FormControl className={'select-form-control'}>
                <Field
                    name={couponType}
                    component={renderSelectField}
                    label="Favorite Color"
                    disabled={!isEditingEnabled}
                >
                    <MenuItem value={'none'} className={'permissions-menu-item'}>No Lock</MenuItem>
                    <MenuItem value={'user'} className={'permissions-menu-item'}>User Lock</MenuItem>
                    <MenuItem value={'all'} className={'permissions-menu-item'}>Full Lock</MenuItem>
                </Field>
            </FormControl>
        )
    }

    const handleSubmit = (formValues) => {
        setIsLoading(true);
        updateLockTypes(formValues)
            .then(response => {

                if (response.type === UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS) {
                    setShowSuccessAlert(true);
                    setAlertText('Coupon Locks Updated!');
                    setIsEditingEnabled(false);
                }

                if (response.type === UPDATE_DSPR_COUPON_LOCK_TYPES_FAILURE) {
                    setShowErrorAlert(true);
                    setAlertText(response.error ? response.error : 'Please try again');
                }

                setIsLoading(false);
                reset();
            })
    }

    return (
        <>
            <form className={'dspr-coupon-permissions-form'} onSubmit={handleSubmit}>

                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isEditingEnabled}
                                onChange={() => setIsEditingEnabled(!isEditingEnabled)}
                                name="isEditingEnabled"
                                disabled={!isCardExpanded}
                            />
                        }
                        label="Edit Coupon Locks"
                    />
                </FormGroup>

                <Table aria-label="coupon lock form">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{color: isEditingEnabled ? primaryColor : secondaryColor}}
                            >
                                Coupon Type
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{color: isEditingEnabled ? primaryColor : secondaryColor}}
                            >
                                Lock Type
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {COUPON_TYPES.map((couponType) => (
                            <TableRow key={couponType}>
                                <TableCell
                                    component="td"
                                    scope="row"
                                    style={{color: isEditingEnabled ? primaryColor : secondaryColor}}
                                >
                                    {couponType.charAt(0) === 'b'
                                        ? couponType.toUpperCase()
                                        : couponType.charAt(0).toUpperCase() + couponType.slice(1)
                                    }
                                </TableCell>
                                <TableCell
                                    align="right"
                                    style={{color: isEditingEnabled ? primaryColor : secondaryColor}}
                                >
                                    {renderSelectMenu(couponType)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div className={'button-container'}>
                    <Button
                        variant={'outlined'}
                        size={'small'} color={'secondary'}
                        disabled={areButtonsDisabled}
                        onClick={reset}
                    >
                        Reset Form
                    </Button>
                    <Button
                        variant={'contained'}
                        size={'small'}
                        color={'primary'}
                        disabled={areButtonsDisabled}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </form>

            <SnackBarAlertSuccess
                showSnackbarAlert={showSuccessAlert}
                setIsVisible={setShowSuccessAlert}
                alertText={alertText}
            />

            <SnackBarAlertError
                showSnackbarAlert={showErrorAlert}
                setIsVisible={setShowErrorAlert}
                alertTitle={'Failed to Update Coupon Locks'}
                alertText={alertText}
            />
        </>
    )
}

const CouponPermissionFormRedux = reduxForm<any, DSPRCouponPermissionsFormProps>({
    form: 'DSPRCouponPermissionsForm',
    enableReinitialize: true
    //validate
})(DSPRCouponPermissionsForm);

export default connect((state, props: DSPRCouponPermissionsFormProps) => {
    const previouslySetLocks = {};

    props.dsprCouponLocks.forEach(couponLock => {
        previouslySetLocks[couponLock.discountType] = couponLock.lockType;
    })

    const initialCouponLocks = {};

    COUPON_TYPES.forEach(couponType => {
        if (couponType in previouslySetLocks) {
            initialCouponLocks[couponType] = previouslySetLocks[couponType];
        } else {
            initialCouponLocks[`${couponType}`] = 'none';
        }
    });

    return {
        initialValues: {
            ...initialCouponLocks,
        }
    }
})(CouponPermissionFormRedux);