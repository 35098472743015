import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {routerReducer as routing} from 'react-router-redux';
import api from './apiReducer';
import componentState from './componentStateReducer';
import {
    AUTOCOMPLETE_SELECTED,
    DRIVER_AUTOCOMPLETE_SELECTED,
    METRC_TAG_AUTOCOMPLETE_SELECTED,
    METRC_TAG_FOR_MODIFY_PRODUCT_TAG_FORM_AUTOCOMPLETE_SELECTED,
    METRC_TAG_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED,
    BATCH_NUMBER_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED,
    PRODUCT_FOR_PRODUCT_LOOKUP_FORM_AUTOCOMPLETE_SELECTED,
    PRODUCT_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
    METRC_TAG_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
    BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
    BARCODE_TYPE_FOR_BARCODE_DOWNLOAD_FORM_AUTCOMPLETE_SELECTED,
    USER_ID_FOR_COUPON_FORM_AUTOCOMPLETE_SELECTED,
    DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED,
    APPLICABLE_TAX_ASSOCIATION_FORM_AUTOCOMPLETE_SELECTED,
    ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED,
    BATCH_NUMBER_AUTOCOMPLETE_SELECTED,
    METRC_RETURN_REASON_FORM_AUTOCOMPLETE_SELECTED,
} from '../actions/formHelperActions';


//INFO: Actions that could be loaded on the same page have to be unique
const userAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, userId: action.id
                }
            };
        default:
            return state;
    }
};

const couponUserAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case USER_ID_FOR_COUPON_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    userIds: action.userIds,
                }
            }
        default:
            return state;
    }
};

const applicableTaxAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED || AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    dsprIds: action.dsprIds,
                }
            }
        default:
            return state;
    }
};
const orderDetailsCompleteSelected = (state, action) => {
    switch (action.type) {
        // case ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED || AUTOCOMPLETE_SELECTED:
        case ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    orderDetailId: action.orderDetailId,
                }
            };
        case METRC_TAG_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    metrcTag: action.tag
                }
            };
        case BATCH_NUMBER_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    batchNumber: action.batch,
                }
            };
        default:
            return state;
    }
};

const dsprApplicableTaxAssociationAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    dsprId: action.dsprId
                }
            }
            // case DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED:
            // return {
            //     ...state, values: {
            //         ...state.values,
            //         // applicableTaxIds: action.applicableTaxIds,
            //         dsprId: action.dsprId
            //     }
            // }
        default:
            return state;
    }
};

const applicableTaxAssociationAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case APPLICABLE_TAX_ASSOCIATION_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    applicableTaxIds: action.applicableTaxIds,
                }
            }
        default:
            return state;
    }
};

const productAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, productId: action.id
                }
            };
        case DRIVER_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, driverId: action.id
                }
            };
        case METRC_TAG_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                        metrcTag: action.tag
                }
            }
        case BATCH_NUMBER_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    batchNumber: action.batch,
                }
            }
        case METRC_RETURN_REASON_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    metrcReturnReason: action.reason,
                }
            }
        default:
            return state;
    }
};

const productCategoryAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, productCategoryId: action.id
                }
            };
        default:
            return state;
    }
}

const metrcTagProductLookupAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case PRODUCT_FOR_PRODUCT_LOOKUP_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, productId: action.id
                }
            };
        case METRC_TAG_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                        metrcTag: action.tag
                }
            };
        case BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    batchNumber: action.batchNumber
                }
            }
        case BATCH_NUMBER_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    batchNumber: action.batchNumber
                }
            }
        default:
            return state;

    }
}

const metrcTagOrBarcodeDownloadFormAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case PRODUCT_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, productId: action.id
                }
            };
        case METRC_TAG_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, metrcTag: action.tag
                }
            };
        case BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                    batchNumber: action.batchNumber
                }
            }
        case BARCODE_TYPE_FOR_BARCODE_DOWNLOAD_FORM_AUTCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, printLabelType: action.printLabelType
                }
            }
        default:
            return state;
    }
}

// You may want to change this if other forms in the component conflict / alter the AUTOCOMPLETE_SELECTED/id value
const modifyProductMetrcTagAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, productId: action.id
                }
            };
        case METRC_TAG_FOR_MODIFY_PRODUCT_TAG_FORM_AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values,
                        metrcTag: action.tag
                }
            }
        default:
            return state;

    }
}

const DSPProductFormAutoCompleteSelected = (state, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SELECTED:
            return {
                ...state, values: {
                    ...state.values, brandId: action.brandId
                }
            }
        default:
            return state;
    }
}
// const productForDriverInventoryAutoCompleteSelected = (state, action) => {
//     switch(action.type) {
//         case AUTOCOMPLETE_SELECTED:
//             const item = merge({}, state.values.items[action.itemIndex], {productId: action.id});
//             let items = [...state.values.items];
//             items[action.itemIndex] = item;
//             return {
//                 ...state, values: {
//                     ...state.values, items
//                 }
//             };
//         default:
//             return state;
//     }
// };

const rootReducer = combineReducers({
    // DriverInventoryPeriodForm: (state, action) => {if(state) return productForDriverInventoryAutoCompleteSelected(state, action)}
    form: formReducer.plugin({
        DSPForm: (state, action) => {
            if (state) return userAutoCompleteSelected(state, action)
        },
        DSPRForm: (state, action) => {
            if (state) return userAutoCompleteSelected(state, action)
        },
        CouponForm: (state, action) => {
            if (state) return couponUserAutoCompleteSelected(state, action)
        },
        DSPRZipCodeForm: (state, action) => {
            if (state) return userAutoCompleteSelected(state, action)
        },
        DSPRDriverForm: (state, action) => {
            if (state) return userAutoCompleteSelected(state, action)
        },
        DSPRInventoryTransactionForm: (state, action) => {
            if (state) return productAutoCompleteSelected(state, action)
        },
        AddDriverInventoryToInventoryPeriodForm: (state, action) => {
            if (state) return productAutoCompleteSelected(state, action)
        },
        DSPRCreateProductCategoryPromotionForm: (state, action) => {
            if (state) return productCategoryAutoCompleteSelected(state, action)
        },
        AddDSPProductToBrandForm: (state, action) => {
            if (state) return productAutoCompleteSelected(state, action)
        },
        DSPRMetrcTagOrBarcodeDownloadForm: (state, action) => {
            if (state) return metrcTagOrBarcodeDownloadFormAutoCompleteSelected(state, action)
        },
        DSPRMetrcTagProductLookupForm: (state, action) => {
            if (state) return metrcTagProductLookupAutoCompleteSelected(state, action)
        },
        DSPRModifyProductMetrcTagForm: (state, action) => {
            if (state) return modifyProductMetrcTagAutoCompleteSelected(state, action);
        },
        ApplicableTaxForm: (state, action) => {
            if (state) return applicableTaxAutoCompleteSelected(state, action);
        },
        OrderProductAutocompleteForm: (state, action) => {
            if (state) return orderDetailsCompleteSelected(state, action);
        },
        DSPRApplicableTaxAssociationForm: (state, action) => {
            if (state) state = applicableTaxAssociationAutoCompleteSelected(state, action);
            if (state) return dsprApplicableTaxAssociationAutoCompleteSelected(state, action);
        },
        DSPProductForm: (state, action) => {
            if (state) return DSPProductFormAutoCompleteSelected(state, action);
        }

    }),
    routing,
    api,
    componentState

});

export default rootReducer;