import { CALL_API, Schemas } from '../middleware/api';

const ENDPOINT = 'marketing';

export const SEND_TEXT_BLAST = 'SEND_TEXT_BLAST';
export const SEND_TEXT_BLAST_SUCCESS = 'SEND_TEXT_BLAST_SUCCESS';
export const SEND_TEXT_BLAST_FAILURE = 'SEND_TEXT_BLAST_FAILURE';

const textBlastSender = (csvFile, message, contactInfo, mediaURL) => {
    const textBlast = {
        message, mediaURL, contactInfo
    };
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [SEND_TEXT_BLAST, SEND_TEXT_BLAST_SUCCESS, SEND_TEXT_BLAST_FAILURE],
            endPoint: ENDPOINT + "/text-blast",
            schema: Schemas.TEXT_BLAST,
            body: textBlast,
            file: csvFile
        }
    }

};

export const sendTextBlast = (csvFile, message, contactInfo = null, mediaURL = null) => (dispatch, getState) => {
    return dispatch(textBlastSender(csvFile, message, contactInfo, mediaURL));
};