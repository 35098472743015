import React from 'react';
import { ListItem, List, Switch, Tooltip, withStyles } from '@material-ui/core';
import { DsprDriver, User } from '../store/reduxStoreState';
import { useDispatch } from 'react-redux';
import { setDriverOnCallState } from '../actions/driverActions';

interface DSPRDriverOncallFormProps {
    drivers: (Partial<Omit<DsprDriver, 'user'> & { user: User }>)[];
    currentFullMenuDriverId: number;
}

const StyledToolTip = withStyles({
    tooltipPlacementBottom: {
        margin: '4px 0',
    },
})(Tooltip);

const DSPRDriverOncallForm: React.FC<DSPRDriverOncallFormProps> = props => {
    const { drivers } = props;
    const dispatch = useDispatch();

    return drivers ?
        <List>
            {drivers.map(driver => <ListItem disableGutters key={driver.id}>
                <span>{driver.user.firstName} {driver.user.lastName} - {driver.user.email}</span>
                <StyledToolTip title={driver.id === props.currentFullMenuDriverId ? 'Full menu driver must remain on call' : ''} placement={'bottom'} arrow>
                <span>
                    <Switch
                        checked={driver.onCall}
                        onChange={() => dispatch(setDriverOnCallState(driver.id.toString(), driver.onCall ? 'off' : 'on'))}
                        color="primary"
                        disabled={driver.id === props.currentFullMenuDriverId}
                    />
                </span>
                </StyledToolTip>
            </ListItem>)}
        </List>
        : null;
}

export default DSPRDriverOncallForm;