import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { getDSPR } from "../actions/dsprActions";
import { getDSP } from "../actions/dspActions";
import { getAllProductsForDSP } from "../actions/dspProductActions";
import {
    createInventoryTransaction,
    getDSPRCurrentInventory,
    setProductPrice
} from "../actions/dsprProductInventoryActions";
import {
    interceptAutocompleteSelected,
    interceptDriverAutoCompleteSelected,
    interceptMetrcTagAutoCompleteSelected,
    updateShowActiveOnlyValue,
    interceptMetrcTagForProductLookupFormAutoCompleteSelected,
    interceptBatchNumberForProductLookupFormAutoCompleteSelected,
    interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected,
    interceptProductForProductLookupFormAutocompleteSelected,
    interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected,
    interceptMetrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected,
    interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected,
    interceptQRTypeForBarcodeDownloadFormAutocompleteSelected,
    interceptBatchNumberAutoCompleteSelected, interceptMetrcReturnReasonAutocompleteSelected
} from "../actions/formHelperActions";
import {
    getDSPRMetrcTagsFromGrassp,
    getDSPRMetrcTagProductAssociations,
    MetrcTagProductAssociationsGetterProps, getDSPRMetrcReturnReasons
} from "../actions/metrcTagActions";
import { getDSPRDriver } from "../actions/driverActions";
import {
    getDSPRFromProps,
    isBatchBasedDSPRFromProps,
    isMetrcLicenseHeldByDSPRFromProps,
    isNonMetrcScanningDSPRFromProps,
} from "../selectors/dsprSelectors";
import { getProductsForDSPAsMap, getProductsForDSPForAutoSelect } from "../selectors/dspProductSelector";
import {
    getCurrentInventoryWithFullProductForDSPR,
    getTotalAvailableInventoryAtDSPRByProduct
} from "../selectors/dsprCurrentInventorySelectors";
import { getDriversForDSPR } from "../selectors/dsprDriverSelector";
import {
    getMetrcReturnReasonsForAutoSelect,
    // getMetrcReturnReason,
    getMetrcTagsForDSPRForAutoSelectWithValueAsText,
    getUnassignedMetrcTagsForDSPRForAutoSelectWithValueAsText
} from "../selectors/metrcTagSelectors";
import {
    getBatchNumberInBatchNumberProductLookupForm,
    getMetrcTagInMetrcTagProductLookupForm,
    getProductInBatchNumberProductLookupForm,
    getProductInMetrcTagProductLookupForm,
    getShowActiveOnlyInBatchNumberProductLookupForm,
    getShowActiveOnlyInMetrcTagProductLookupForm
} from '../selectors/reduxFormSelectors';

import {
    State,
    DSPR,
    DsprCurrentInventoryItem,
    DspProduct,
    DsprDriver,
    User,
} from '../store/reduxStoreState';

import DSPRInventory from "../components/DSPRInventory";
import { AutoSelectStringValueItems } from '../components/Autocomplete';
import { BatchNumberProductAssocationGetterProps, getBatchNumberProductAssociations } from '../actions/batchNumberActions';
import { getBatchNumbersForDSPRForAutoSelectWithValueAsText, getUnassignedBatchNumbersForDSPRForAutoSelectWithValueAsText } from '../selectors/batchNumberSelectors';

type CurrentInventory = (Omit<DsprCurrentInventoryItem, 'product'> & { product: DspProduct })[];
export type DsprDriverWithUser = (Partial<Omit<DsprDriver, 'user'> & { user: User }>)
export type DsprDriversWithUser = DsprDriverWithUser[];

const DSPRInventoryContainer: React.FC<{}> = () => {
    const {dsprId} = useParams<{ dsprId: string }>();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingMetrcTags, setIsFetchingMetrcTags] = useState<boolean>(false);
    const [isFetchingMetrcProductAssociations, setIsFetchingMetrcProductAssociations] = useState<boolean>(false);

    const [isFetchingBatchNumbers, setIsFetchingBatchNumbers] = useState<boolean>(false);
    const [isFetchingBatchNumberAssociations, setIsFetchingBatchNumberAssociations] = useState<boolean>(false);

    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, {dsprId}), shallowEqual);
    const currentInventory = useSelector<State, CurrentInventory>(state => getCurrentInventoryWithFullProductForDSPR(state, {dsprId}), shallowEqual);
    const dspProductsForAutoselect = useSelector<State, { value: number, text: string }[]>(state =>
        dspr ? getProductsForDSPForAutoSelect(state, {dspId: dspr.deliveryServiceProvider}) : [], shallowEqual);
    const products = useSelector<State, { [key: number]: DspProduct }>(state => dspr ? getProductsForDSPAsMap(state, {dspId: dspr.deliveryServiceProvider}) : undefined, shallowEqual);
    const productAvailabilityMap = useSelector<State, any>(state => getTotalAvailableInventoryAtDSPRByProduct(state, {dsprId}), shallowEqual);
    const dsprDrivers = useSelector<State, DsprDriversWithUser>(state => getDriversForDSPR(state, {dsprId}), shallowEqual);
    
    const isMetrcDspr = useSelector<State, boolean>(state => dsprId && isMetrcLicenseHeldByDSPRFromProps(state, {dsprId}), shallowEqual);
    const isBatchDspr = useSelector<State, boolean>(state => dsprId && isBatchBasedDSPRFromProps(state, {dsprId}), shallowEqual);
    const idNonMetrcScaningDSPR = useSelector<State, boolean>(state => dsprId && isNonMetrcScanningDSPRFromProps(state, {dsprId}), shallowEqual);
    
    const metrcTagsForAutoSelect = useSelector<State, AutoSelectStringValueItems>(state => isMetrcDspr && getMetrcTagsForDSPRForAutoSelectWithValueAsText(state, {dsprId}), shallowEqual);

    const metrcReturnReasons = useSelector<State, any>(state => isMetrcDspr && getMetrcReturnReasonsForAutoSelect(state));
    const unassignedMetrcTagsForAutoSelect = useSelector<State, AutoSelectStringValueItems>(state => isMetrcDspr && getUnassignedMetrcTagsForDSPRForAutoSelectWithValueAsText(state, {dsprId}), shallowEqual);
    const metrcTagValueInMetrcTagProductLookupForm = useSelector<State, string>(getMetrcTagInMetrcTagProductLookupForm, shallowEqual);
    const productValueInMetrcTagProductLookupForm = useSelector<State, number>(getProductInMetrcTagProductLookupForm, shallowEqual);
    const showActiveOnlyMetrcTagsInMetrcProductForm = useSelector<State, boolean>(state => isMetrcDspr && getShowActiveOnlyInMetrcTagProductLookupForm(state), shallowEqual);

    const batchNumbersForAutoSelect = useSelector<State, AutoSelectStringValueItems>(state => isBatchDspr && getBatchNumbersForDSPRForAutoSelectWithValueAsText(state, {dsprId}), shallowEqual);
    const unassignedBatchNumbersForAutoSelect = useSelector<State, AutoSelectStringValueItems>(state => isBatchDspr && getUnassignedBatchNumbersForDSPRForAutoSelectWithValueAsText(state, {dsprId}), shallowEqual);
    const batchNumberValueInBatchNumberProductLookupForm = useSelector<State, string>(getBatchNumberInBatchNumberProductLookupForm, shallowEqual);
    const productValueInBatchNumberProductLookupForm = useSelector<State, number>(getProductInBatchNumberProductLookupForm, shallowEqual);
    const showActiveOnlyBatchNumberInMetrcProductForm = useSelector<State, boolean>(state => isBatchDspr && getShowActiveOnlyInBatchNumberProductLookupForm(state), shallowEqual);
    const stringifiedDsprDriverIds = useMemo(() => JSON.stringify(dsprDrivers ? dsprDrivers.map(driver => driver.id) : []), [dsprDrivers]);

    const dspId = dspr && dspr.deliveryServiceProvider;

    useEffect(() => {
        dispatch(getDSPRCurrentInventory(dsprId));
    }, [dispatch, dsprId]);

    useEffect(() => {
        dspId && dispatch<any>(getDSPR(dsprId))
            .then(() => {
                dispatch(getDSP(dspId));
                dispatch<any>(getAllProductsForDSP(dspId))
                    .then(() => setIsLoading(false));
            });
    }, [dispatch, dspId, dsprId]);

    useEffect(() => {
        const dsprDriverIds: number[] = JSON.parse(stringifiedDsprDriverIds);
        dsprDriverIds.forEach(id => dispatch(getDSPRDriver(id)));
    }, [dispatch, stringifiedDsprDriverIds]);

    useEffect(() => {
        if ((isMetrcDspr)) {
            setIsFetchingMetrcTags(true);
            dispatch<any>(getDSPRMetrcTagsFromGrassp(dsprId))
                .then(() => setIsFetchingMetrcTags(false));
        }
        if((isBatchDspr)) {
            setIsFetchingBatchNumbers(true);
            dispatch<any>(getBatchNumberProductAssociations({ dsprId: parseInt(dsprId)}))
                .then(() => setIsFetchingBatchNumbers(false));
        }
    }, [dsprId, isMetrcDspr, isBatchDspr, dispatch]);

    useEffect(()=> {
        if ((isMetrcDspr)) {
            dispatch<any>(getDSPRMetrcReturnReasons(dsprId))
        }
    },[isMetrcDspr])

    useEffect(()=>{
        console.log(metrcReturnReasons,"METRC_RETURN_REASONS");
    },[metrcReturnReasons])

    const handleSubmitInventoryTransactionForm = (transactionType, transactionValues) => {
        return dispatch(createInventoryTransaction({dsprId, transactionType, transactionValues}));
    };

    const fetchMetrcProductAssociation = (formValues: MetrcTagProductAssociationsGetterProps) => {
        setIsFetchingMetrcProductAssociations(true);
        return dispatch<any>(getDSPRMetrcTagProductAssociations(formValues))
            .then(response => {
                setIsFetchingMetrcProductAssociations(false);
                return response
            });
    }

    const fetchBatchNumberProductAssociation = (formValues: BatchNumberProductAssocationGetterProps) => {
        setIsFetchingBatchNumberAssociations(true);
        return dispatch<any>(getBatchNumberProductAssociations(formValues))
            .then(response => {
                setIsFetchingBatchNumberAssociations(false);
                return response
            });
    }

    const dispatchMetrcTagForProductLookupFormAutoCompleteSelected = useCallback((tag: string) => {
        dispatch(interceptMetrcTagForProductLookupFormAutoCompleteSelected(tag))
    }, [dispatch]);

    const dispatchProductForProductLookupFormAutocompleteSelected = useCallback((id: number) => {
        dispatch(interceptProductForProductLookupFormAutocompleteSelected(id))
    }, [dispatch]);

    const dispatchMetrcTagForModifyProductTagFormForAutoCompleteSelected = useCallback((tag: string) => {
        dispatch(interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected(tag))
    }, [dispatch]);

    const dispatchBatchNumberForProductLookupFormAutoCompleteSelected = useCallback((tag: string) => {
        dispatch(interceptBatchNumberForProductLookupFormAutoCompleteSelected(tag))
    }, [dispatch]);

    return <main className="inventory-tab">
        <h2>Inventory</h2>
        {isLoading ? <CircularProgress/> : <DSPRInventory dspProductsForAutoselect={dspProductsForAutoselect}
                                                          products={products}
                                                          interceptAutocompleteSelected={id => dispatch(interceptAutocompleteSelected(id))}
                                                          interceptDriverAutocompleteSelected={id => dispatch(interceptDriverAutoCompleteSelected(id))}
                                                          interceptMetrcTagAutoCompleteSelected={tag => dispatch(interceptMetrcTagAutoCompleteSelected(tag))}
                                                          interceptBatchNumberAutoCompleteSelected={batch=> dispatch(interceptBatchNumberAutoCompleteSelected(batch))}
                                                          interceptMetrcReturnReasonsAutoCompleteSelected={reason => dispatch(interceptMetrcReturnReasonAutocompleteSelected(reason))}
                                                          interceptQRTypeForBarcodeDownloadFormAutocompleteSelected={value => dispatch(interceptQRTypeForBarcodeDownloadFormAutocompleteSelected(value))}
                                                          interceptMetrcTagForProductLookupFormAutoCompleteSelected={dispatchMetrcTagForProductLookupFormAutoCompleteSelected}
                                                          interceptBatchNumberForProductLookupFormAutoCompleteSelected={dispatchBatchNumberForProductLookupFormAutoCompleteSelected}
                                                          interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected={dispatchMetrcTagForModifyProductTagFormForAutoCompleteSelected}
                                                          interceptProductForProductLookupFormAutocompleteSelected={dispatchProductForProductLookupFormAutocompleteSelected}
                                                          interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected={id => dispatch(interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected(id))}
                                                          interceptMetrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected={name => dispatch(interceptMetrcReturnReasonAutocompleteSelected(name))}
                                                          interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected={batchNumber => dispatch(interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected(batchNumber))}
                                                          handleSubmitInventoryTransactionForm={handleSubmitInventoryTransactionForm}
                                                          currentInventory={currentInventory}
                                                          productAvailabilityMap={productAvailabilityMap}
                                                          drivers={dsprDrivers}
                                                          handleSubmitProductPricingForm={(dsprId, productId, priceObject) => dispatch(setProductPrice(dsprId, productId, priceObject))}
                                                          dsprId={dsprId}
                                                          isMetrcDspr={isMetrcDspr}
                                                          isBatchDspr={isBatchDspr}
                                                          isNonMetrcScanningDspr={idNonMetrcScaningDSPR}
                                                          metrcTagsForAutoSelect={metrcTagsForAutoSelect}
                                                          batchNumbersForAutoSelect={batchNumbersForAutoSelect}
                                                          metrcReturnReasonsForAutoSelect={metrcReturnReasons}
                                                          unassignedBatchNumbersForAutoSelect={unassignedBatchNumbersForAutoSelect}
                                                          unassignedMetrcTagsForAutoSelect={unassignedMetrcTagsForAutoSelect}
                                                          isFetchingBatchNumbers={isFetchingBatchNumbers}
                                                          isFetchingMetrcTags={isFetchingMetrcTags}
                                                          metrcTagValueInMetrcTagProductLookupForm={metrcTagValueInMetrcTagProductLookupForm}
                                                          batchNumberValueInBatchNumberProductLookupForm={batchNumberValueInBatchNumberProductLookupForm}
                                                          productValueInBatchNumberProductLookupForm={productValueInBatchNumberProductLookupForm}
                                                          productValueInMetrcTagProductLookupForm={productValueInMetrcTagProductLookupForm}
                                                          fetchMetrcProductAssociation={fetchMetrcProductAssociation}
                                                          fetchBatchNumberProductAssociation={fetchBatchNumberProductAssociation}
                                                          updateShowActiveOnly={showActiveOnly => dispatch(updateShowActiveOnlyValue(showActiveOnly))}
                                                          showActiveOnlyBatchNumbersInBatchNumberProductForm={showActiveOnlyBatchNumberInMetrcProductForm}
                                                          showActiveOnlyMetrcTagsInMetrcProductForm={showActiveOnlyMetrcTagsInMetrcProductForm}
                                                          isFetchingBatchNumberProductAssociations={isFetchingBatchNumberAssociations}
                                                          isFetchingMetrcProductAssociations={isFetchingMetrcProductAssociations}
        />}
    </main>
}

export default DSPRInventoryContainer;