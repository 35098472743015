import React, { useEffect, useState} from 'react';
import AssignDriverToOrderForm from "../../containers/AssignDriverToOrderForm";
import {checkIfPreviousOrderStatusIsValid, orderStatus} from "../../middleware/api";
import {
    ASSIGN_DRIVER_ORDER_FAILURE,
    ASSIGN_DRIVER_ORDER_SUCCESS,
    assignDriverToOrder
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {GET_DSPR_DRIVER_FAILURE, getDSPRDriver} from "../../actions/driverActions";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";

export const AssignDriverButtons:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        disableExtraButtons,
        setExtraOrderFunctionalityErrorCallBack,
        setDisableButtonCallBack,
        closeModal,
        order,
        orderId,
        dspr,
        onCallDrivers,
        user,
        isManagerForDSPR,
        isDriverForOrder,
        dsprDriverIdFromOrder,
        currentFullMenuDriver,


    } = props;

    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const [isFullMenuDriver, setIsFullMenuDriver] = useState(false);
    const [onCallDriversWithoutFullMenuDriver, setOnCallDriversWithoutFullMenuDriver] = useState(null);
    const dispatch = useDispatch();

    const handleAssignDriverOrder = (orderId: number, dsprDriverIdSavedForPackaging: number): Promise<string> => {
        setDisableButtonCallBack(true)
        return dispatch<any>(assignDriverToOrder(orderId,dsprDriverIdSavedForPackaging)).then(response => {
            setDisableButtonCallBack(false)
            if (response.type === ASSIGN_DRIVER_ORDER_SUCCESS) {
                if(closeModal) closeModal();
                return false;
            }else if(response.type === ASSIGN_DRIVER_ORDER_FAILURE){
                const errorMessage = response.error;
                setExtraOrderFunctionalityErrorCallBack(response.error);
                return errorMessage;
            }
        })
    }
    const handleGetDriverRequest = (driverId: number) => {
        return dispatch<any>(getDSPRDriver(driverId))
            .then(response => {
                if (response.type === GET_DSPR_DRIVER_FAILURE) {
                    return response.error
                } else {
                    return response
                }
            })
    }

    useEffect(() => {

        if(isManagerForDSPR || isDriverForOrder) return;
        const previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr,order, orderStatus.queued);

        if(previousOrderStatusIsValid)  setShowBasedOnOrderStatus(true);// check if driver is still full menu driver on queued
        if(dspr.isFullMenuAvailable && currentFullMenuDriver && dsprDriverIdFromOrder === currentFullMenuDriver.id) setIsFullMenuDriver(true);

        setOnCallDriversWithoutFullMenuDriver(onCallDrivers.filter(driver => dspr.currentFullMenuDriver !== driver.id))

            }, []);

    return (showBasedOnOrderStatus && isFullMenuDriver)
        && <div style={{width: "100%", marginTop: "8px", marginBottom: "1px"}}>
            <AssignDriverToOrderForm
                dsprDrivers={onCallDriversWithoutFullMenuDriver}
                assignDriverToOrder={handleAssignDriverOrder}
                getDSPRDriver={handleGetDriverRequest}
                disableButton={disableExtraButtons}
                dspr={dspr}
                currentOrder={{ ...order, user }}  />
        </div>
}
