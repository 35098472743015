import {
    PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE, GET_APP_ACCESS_TOKEN_SUCCESS, LOGIN_SUCCESS,
    LOGIN_FAILURE, LOGOUT, UPDATE_ACCESS_TOKEN
} from '../actions/oauthActions';
import {
    LOGGED_IN_USER_INFO_SUCCESS, GET_ALL_USERS_SUCCESS, GET_SPECIFIC_USER_SUCCESS,
    GET_USERS_WITH_UNVERIFIED_DOCUMENTS_SUCCESS, GET_USER_ID_DOCUMENT_SUCCESS,
    GET_USER_MEDICAL_RECOMMENDATION_SUCCESS, VERIFY_USER_DOCUMENT_SUCCESS, UPLOAD_USER_DOCUMENT_SUCCESS,
    GET_USERS_BY_SEARCH_SUCCESS, GET_ORDER_HISTORY_FOR_USER_SUCCESS, CREATE_USER_NOTE_SUCCESS, GET_ALL_USER_ID_DOCUMENTS_SUCCESS,
    SET_CURRENT_USER_ID_SUCCESS, GET_ALL_USER_MEDICAL_RECOMMENDATIONS_SUCCESS, SET_CURRENT_USER_MEDICAL_RECOMMENDATION_SUCCESS,
    HIDE_USER_NOTE_SUCCESS,
    UNHIDE_USER_NOTE_SUCCESS,
    ADMIN_CHANGES_USER_DETAILS_SUCCESS,
    HIDE_USER_DOCUMENT_SUCCESS,
    UNHIDE_USER_DOCUMENT_SUCCESS
} from '../actions/userActions';
import {
    CREATE_DSP_SUCCESS, ASSIGN_DSP_MANAGER_SUCCESS, TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS,
    GET_ALL_DSPS_SUCCESS, GET_DSP_SUCCESS, GET_ANALYTICS_FOR_DSP_SUCCESS
} from '../actions/dspActions';
import {
    CREATE_DSPR_SUCCESS,
    UPDATE_DSPR_SUCCESS,
    ASSIGN_DSPR_MANAGER_SUCCESS,
    TOGGLE_DSPR_MANAGER_ACTIVE_STATUS_SUCCESS,
    GET_ALL_ON_CALL_DRIVERS_FOR_DSPR_SUCCESS,
    GET_ALL_DSPRS_FOR_DSP_SUCCESS,
    GET_DSPR_SUCCESS,
    TOGGLE_DSPR_MANAGER_ORDER_NOTIFICATION_STATUS_SUCCESS,
    TOGGLE_DSPR_MANAGER_DRIVER_NOTIFICATION_STATUS_SUCCESS,
    GET_ORDER_HISTORY_FOR_DSPR_SUCCESS,
    GET_ANALYTICS_FOR_DSPR_SUCCESS,
    GET_ANALYTICS_FOR_DRIVER_SUCCESS,
    CREATE_DSPR_PROMOTION_FOR_PRODUCT_CATEGORY_SUCCESS,
    GET_DSPR_PROMOTION_FOR_PRODUCT_CATEGORIES_SUCCESS,
    HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS,
    GET_DSPR_DRIVER_SERVICE_AREAS_SUCCESS,
    CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS,
    UPDATE_DSPR_MENU_MECHANISM_SUCCESS,
    GET_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS,
    CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS,
    SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS,
    SET_DSPR_FULL_MENU_DRIVER_SUCCESS, UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS, GET_DSPR_AUDITS_SUCCESS, GET_AUDIT_DETAILS_SUCCESS
} from '../actions/dsprActions';
import {
    ASSIGN_DSPR_DRIVER_SUCCESS, TOGGLE_DSPR_DRIVER_ACTIVE_STATUS_SUCCESS, SET_DRIVER_LOCATION_SUCCESS,
    SET_ON_CALL_STATE_FOR_DRIVER_SUCCESS, GET_DSPR_DRIVER_SUCCESS, SET_DRIVER_INFORMATION_SUCCESS, GET_ALL_DRIVERS_FOR_DSPR_SUCCESS,
    CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS, PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS, CREATE_NEW_DSPR_DRIVER_ROUTE_WITHOUT_NOTIFICATIONS_SUCCESS, DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS
} from '../actions/driverActions';
import { 
    CREATE_DSP_PRODUCT_SUCCESS, GET_PRODUCT_SUCCESS, GET_ALL_PRODUCTS_FOR_DSP_SUCCESS, CREATE_NEW_CATEGORY_SUCCESS, 
    GET_PRODUCT_BY_SEARCH_SUCCESS, CREATE_DSP_PRODUCT_FAILURE, GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS, GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS,
    POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS
} from '../actions/dspProductActions';
import { CLEAR_API_ERROR_MESSAGE } from '../actions/apiUIHelperActions';
import {
    CREATE_INVENTORY_PERIOD_SUCCESS, GET_DRIVER_INVENTORY_PERIOD_SUCCESS, REMOVE_INVENTORY_ITEM_FROM_PERIOD_SUCCESS,
    ADD_INVENTORY_ITEM_TO_PERIOD_SUCCESS, TRANSFER_INVENTORY_PERIOD_SUCCESS
} from '../actions/dsprDriverInventoryPeriodActions';
import {
    GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS, ADD_DSPR_ZIP_CODE_SUCCESS, TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS,
    ZIP_CODE_MINIMUM_CHANGE_SUCCESS
} from '../actions/zipCodeActions';
import {
    CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS,
    GET_DSPR_CURRENT_INVENTORY_SUCCESS,
    SET_DSPR_PRODUCT_PRICE_SUCCESS,
    GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS,
    GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS
} from '../actions/dsprProductInventoryActions';
import {
    CREATE_NEW_COUPON_SUCCESS, GET_DSPR_COUPON_LOCK_TYPES_SUCCESS, GET_DSPR_COUPONS_SUCCESS, MODIFY_COUPON_SUCCESS,
    SEARCH_DSPR_COUPONS_SUCCESS, TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS, UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS
} from '../actions/couponActions';
import {
    CREATE_NEW_APPLICABLE_TAX_SUCCESS, DELETE_APPLICABLE_TAX_SUCCESS,
    GET_ALL_APPLICABLE_TAXES_SUCCESS
} from "../actions/applicableTaxActions";
import {
    GET_ALL_BRANDS_FOR_DSP_SUCCESS,
    GET_BRAND_SUCCESS,
    CREATE_BRAND_SUCCESS,
    ADD_PRODUCT_TO_BRAND_SUCCESS,
    REMOVE_PRODUCT_FROM_BRAND_SUCCESS,
    GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS,
    REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS,
    GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS,
} from '../actions/brandActions';
import {
    COMPLETE_ORDER_SUCCESS,
    CANCEL_ORDER_SUCCESS,
    MODIFY_ORDER_SUCCESS,
    GET_ORDER_DETAILS_WITH_ID_SUCCESS,
    UNCOMPLETE_ORDER_SUCCESS,
    UNCANCEL_ORDER_SUCCESS,
    PACKAGE_ORDER_SUCCESS,
    NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS,
    CONFIRM_ALLOTMENT_ORDER_SUCCESS, ASSIGN_DRIVER_ORDER_SUCCESS, IN_ROUTE_ORDER_SUCCESS, QUEUE_ORDER_SUCCESS
} from '../actions/orderActions';
import { SEND_TEXT_BLAST_SUCCESS } from '../actions/marketingActions';
import { GET_METRICS_FOR_USERS_SUCCESS } from '../actions/metricsActions'

import merge from 'lodash/merge';
import entitiesReducer, { initialState as entitiesInitialState } from './entitiesReducer';
import {
    GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS,
    GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS,
    GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS,
    GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS,
    REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS,
} from "../actions/metrcTagActions";
import {
    CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
    DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
    GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS
} from "../actions/dsprApplicableTaxAssociationActions";
import {
    GET_CURRENT_ORDER_SCANS_FOR_ORDER_SUCCESS,
    ORDER_SCAN_SUBMIT_SUCCESS,
    RESET_ORDER_SCANS_SUCCESS
} from "../actions/scanActions";
import { GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS } from '../actions/batchNumberActions';

const initialState = { accessToken: '', loggedInUserId: '', errorMessage: '', entities: entitiesInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_APP_ACCESS_TOKEN_SUCCESS:
        case UPDATE_ACCESS_TOKEN:
        case LOGIN_SUCCESS:
        case PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE:
            return { ...state, accessToken: action.accessToken };
        case LOGOUT:
            return { ...state, accessToken: '', loggedInUserId: '' };
        case LOGGED_IN_USER_INFO_SUCCESS:
            let entities = action.response.entities;
            let usersFromResponse = entities.users;
            let loggedInUserId = Object.keys(usersFromResponse)[0];
            return merge({}, state,
                { loggedInUserId, entities: entitiesReducer(state.entities, action) });
        case LOGIN_FAILURE:
            return { ...state, errorMessage: 'Incorrect email/password combination' };
        case CREATE_DSP_PRODUCT_FAILURE:
            return { ...state, errorMessage: action.error };
        case GET_USERS_BY_SEARCH_SUCCESS:
        case GET_ALL_USERS_SUCCESS:
        case CREATE_DSP_SUCCESS:
        case CREATE_DSPR_SUCCESS:
        case UPDATE_DSPR_SUCCESS:
        case ASSIGN_DSPR_MANAGER_SUCCESS:
        case ASSIGN_DSP_MANAGER_SUCCESS:
        case TOGGLE_DSPR_MANAGER_ACTIVE_STATUS_SUCCESS:
        case TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS:
        case ASSIGN_DSPR_DRIVER_SUCCESS:
        case TOGGLE_DSPR_DRIVER_ACTIVE_STATUS_SUCCESS:
        case GET_ALL_ON_CALL_DRIVERS_FOR_DSPR_SUCCESS:
        case GET_ALL_DSPS_SUCCESS:
        case GET_DSP_SUCCESS:
        case GET_ALL_DSPRS_FOR_DSP_SUCCESS:
        case GET_DSPR_SUCCESS:
        case GET_SPECIFIC_USER_SUCCESS:
        case SET_DRIVER_LOCATION_SUCCESS:
        case SET_ON_CALL_STATE_FOR_DRIVER_SUCCESS:
        case ASSIGN_DRIVER_ORDER_SUCCESS:
        case GET_PRODUCT_SUCCESS:
        case CREATE_DSP_PRODUCT_SUCCESS:
        case GET_DSPR_DRIVER_SUCCESS:
        case CREATE_INVENTORY_PERIOD_SUCCESS:
        case GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS:
        case ADD_DSPR_ZIP_CODE_SUCCESS:
        case TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS:
        case GET_ALL_PRODUCTS_FOR_DSP_SUCCESS:
        case CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS:
        case GET_DSPR_CURRENT_INVENTORY_SUCCESS:
        case GET_DRIVER_INVENTORY_PERIOD_SUCCESS:
        case GET_USERS_WITH_UNVERIFIED_DOCUMENTS_SUCCESS:
        case REMOVE_INVENTORY_ITEM_FROM_PERIOD_SUCCESS:
        case ADD_INVENTORY_ITEM_TO_PERIOD_SUCCESS:
        case GET_USER_ID_DOCUMENT_SUCCESS:
        case GET_USER_MEDICAL_RECOMMENDATION_SUCCESS:
        case VERIFY_USER_DOCUMENT_SUCCESS:
        case SET_DSPR_PRODUCT_PRICE_SUCCESS:
        case CREATE_NEW_CATEGORY_SUCCESS:
        case CREATE_NEW_COUPON_SUCCESS:
        case GET_DSPR_COUPONS_SUCCESS:
        case TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS:
        case COMPLETE_ORDER_SUCCESS:
        case CANCEL_ORDER_SUCCESS:
        case ORDER_SCAN_SUBMIT_SUCCESS:
        case GET_CURRENT_ORDER_SCANS_FOR_ORDER_SUCCESS:
        case RESET_ORDER_SCANS_SUCCESS:
        case QUEUE_ORDER_SUCCESS:
        case IN_ROUTE_ORDER_SUCCESS:
        case CONFIRM_ALLOTMENT_ORDER_SUCCESS:
        case NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS:
        case PACKAGE_ORDER_SUCCESS:
        case TOGGLE_DSPR_MANAGER_ORDER_NOTIFICATION_STATUS_SUCCESS:
        case TOGGLE_DSPR_MANAGER_DRIVER_NOTIFICATION_STATUS_SUCCESS:
        case GET_ORDER_HISTORY_FOR_DSPR_SUCCESS:
        case UPLOAD_USER_DOCUMENT_SUCCESS:
        case SEND_TEXT_BLAST_SUCCESS:
        case ZIP_CODE_MINIMUM_CHANGE_SUCCESS:
        case TRANSFER_INVENTORY_PERIOD_SUCCESS:
        case GET_ORDER_HISTORY_FOR_USER_SUCCESS:
        case GET_ANALYTICS_FOR_DSPR_SUCCESS:
        case GET_ANALYTICS_FOR_DRIVER_SUCCESS:
        case SEARCH_DSPR_COUPONS_SUCCESS:
        case GET_PRODUCT_BY_SEARCH_SUCCESS:
        case CREATE_USER_NOTE_SUCCESS:
        case SET_DRIVER_INFORMATION_SUCCESS:
        case SET_CURRENT_USER_ID_SUCCESS:
        case SET_CURRENT_USER_MEDICAL_RECOMMENDATION_SUCCESS:
        case GET_ALL_DRIVERS_FOR_DSPR_SUCCESS:
        case MODIFY_ORDER_SUCCESS:
        case GET_ORDER_DETAILS_WITH_ID_SUCCESS:
        case HIDE_USER_NOTE_SUCCESS:
        case UNHIDE_USER_NOTE_SUCCESS:
        case HIDE_USER_DOCUMENT_SUCCESS:
        case UNHIDE_USER_DOCUMENT_SUCCESS:
        case ADMIN_CHANGES_USER_DETAILS_SUCCESS:
        case GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS:
        case CREATE_DSPR_PROMOTION_FOR_PRODUCT_CATEGORY_SUCCESS:
        case GET_DSPR_PROMOTION_FOR_PRODUCT_CATEGORIES_SUCCESS:
        case HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS:
        case GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS:
        case POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS:
        case GET_METRICS_FOR_USERS_SUCCESS:
        case GET_DSPR_DRIVER_SERVICE_AREAS_SUCCESS:
        case CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS:
        case UPDATE_DSPR_MENU_MECHANISM_SUCCESS:
        case CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS:
        case CREATE_NEW_DSPR_DRIVER_ROUTE_WITHOUT_NOTIFICATIONS_SUCCESS:
        case DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS:
        case PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS:
        case GET_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS:
        case CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS:
        case UNCOMPLETE_ORDER_SUCCESS:
        case UNCANCEL_ORDER_SUCCESS:
        case SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS:
        case GET_ALL_BRANDS_FOR_DSP_SUCCESS:
        case GET_BRAND_SUCCESS:
        case CREATE_BRAND_SUCCESS:
        case ADD_PRODUCT_TO_BRAND_SUCCESS:
        case REMOVE_PRODUCT_FROM_BRAND_SUCCESS:
        case GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS:
        case REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS:
        case GET_ANALYTICS_FOR_DSP_SUCCESS:
        case GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS:
        case GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS:
        case GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS:
        case GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS:
        case GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS:
        case SET_DSPR_FULL_MENU_DRIVER_SUCCESS:
        case UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS:
        case GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS:
        case REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS:
        case GET_DSPR_COUPON_LOCK_TYPES_SUCCESS:
        case UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS:
        case GET_ALL_APPLICABLE_TAXES_SUCCESS:
        case CREATE_NEW_APPLICABLE_TAX_SUCCESS:
        case GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
        case DELETE_APPLICABLE_TAX_SUCCESS:
        case DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
        case CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
        case MODIFY_COUPON_SUCCESS:
        case GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS:
        case GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS:
        case GET_AUDIT_DETAILS_SUCCESS:
        case GET_DSPR_AUDITS_SUCCESS:
            const newState = { ...state, entities: entitiesInitialState };
            return merge({}, newState, { entities: entitiesReducer(state.entities, action) });
        case GET_ALL_USER_ID_DOCUMENTS_SUCCESS:
            const newEntitiesState = { ...state, entities: {...state.entities, usersIdDocuments: {}}};
            return merge({}, newEntitiesState, {entities: entitiesReducer(newEntitiesState.entities, action)})
        case GET_ALL_USER_MEDICAL_RECOMMENDATIONS_SUCCESS:
            const newStateWithoutMedicalRecommendations = { ...state, entities: {...state.entities, usersMedicalRecommendations: {}}};
            return merge({}, newStateWithoutMedicalRecommendations, {entities: entitiesReducer(newStateWithoutMedicalRecommendations.entities, action)})
        case CLEAR_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '' };
        // case MODIFY_ORDER_SUCCESS:
        //     return merge({}, state, { entities: entitiesReducer(state.entities, action) });
        default:
            return state;
    }
}