import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button } from '@material-ui/core';
import Dropzone from 'react-dropzone';

import './UserDocumentUploadStyles.scss'
const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field) => {
    const files = field.input.value;
    return (<div className={'file-upload'}>
        <Dropzone
            onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
            multiple={false}
            maxSize={10485760}
        >
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        {files ? <img src={URL.createObjectURL(files[0])} alt="Preview" style={{objectFit: 'contain'}} width="300px" height="200px" />
                            : <div>
                                <input {...getInputProps()} />
                                <Button color='primary'>Select an image to upload.</Button>
                            </div>
                        }
                    </div>
                </section>
            )}
        </Dropzone>

        {field.meta.touched &&
            field.meta.error &&
            <span className="error">{field.meta.error}</span>}
    </div>
    );
};

const validate = (values) => {
    const errors = {};
    const requiredFields = [FILE_FIELD_NAME];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'An image is required.'
        }
    });
    return errors;
};

interface UserDocumentUploadFormProps extends Partial<InjectedFormProps> {
    submitLabel: string,
    showBackButton?: boolean,
    onCancel?: () => any
}

const UserDocumentUploadForm = (props: UserDocumentUploadFormProps & InjectedFormProps<{}, UserDocumentUploadFormProps>) => {
    const { handleSubmit, onCancel, submitLabel, showBackButton, pristine } = props;

    return (
        <form onSubmit={handleSubmit} className={'user-document-upload-form'}>
            <Field name={FILE_FIELD_NAME} component={renderDropzoneInput} type="dropzone" label="Upload Image" />
            <Button color='primary' variant="contained" className="raised-button"
                onClick={handleSubmit}
                disabled={pristine}
                fullWidth={!showBackButton}>{submitLabel}</Button>
            {showBackButton &&
                <Button color='secondary' className="raised-button"
                    onClick={onCancel}>Back</Button>
            }
        </form>
    )
};

export default reduxForm<any, UserDocumentUploadFormProps>({
    form: 'UserDocumentUploadForm', // a unique identifier for this form
    validate
})(UserDocumentUploadForm);