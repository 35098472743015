import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import numeral from 'numeral';


import { getDriverAnalytics, getDSPR, getDSPRAudits, getAuditDetails } from '../actions/dsprActions';
import { getAllDriversForDspr, rebuildDriverAnalytics } from '../actions/driverActions'
import { getDriversForDSPR } from "../selectors/dsprDriverSelector";
import { State, DsprDriver, User, Analytic, DSPR, Audit } from '../store/reduxStoreState';
import { parseDate } from "../util/util";
import ChartistGraph from "react-chartist";
import { straightLinesChart } from "../variables/charts";
import List from "@material-ui/core/List";
import AnalyticsDisplay from "../components/AnalyticsDisplay";
import GridContainer from '../components/Grid/GridContainer';

import "./AuditContainerStyles.scss"

import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Input,
    ListItem,
    MenuItem,
    TableCell,
    TableRow
} from '@material-ui/core';

import '../components/AnalyticsDisplayStyles.scss';
import AnalyticsUtil from "../util/AnalyticsUtil";
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import CardHeader from '../components/Card/CardHeader';
import GridItem from '../components/Grid/GridItem';
import { getDSPRAuditsFromProps } from '../selectors/auditSelectors';
import { GetApp, LocalActivity } from '@material-ui/icons';
import { getAudit } from '../middleware/api';

/**Audit Container*/
function AuditContainer() {
    const {formatDateForTable, formatDateForChart} = AnalyticsUtil;
    const [search, setSearch] = useState<string>('');

    const history = useHistory();

    const {dsprId} = useParams<{ dsprId: string }>();
    const dispatch = useDispatch();

    const dsprDrivers = useSelector<State, (Partial<Omit<DsprDriver, 'user'> & { user: User }>)[]>(state => (
        getDriversForDSPR(state, {dsprId})), shallowEqual);

    const dspr = useSelector<State, DSPR>(state => dsprId && getDSPRFromProps(state, {dsprId: parseInt(dsprId)}), shallowEqual);
    const audits = useSelector<State, Audit[]>(state => dsprId && getDSPRAuditsFromProps(state, {dsprId: parseInt(dsprId)}), shallowEqual);

    const [isLoading, setIsLoading] = useState(false);
    const [tableBody, setTableBody] = useState([]);

    useEffect(() => {
        if (dsprId) {
            setIsLoading(true);
            Promise.all([dispatch<any>(getDSPRAudits(dsprId))]).then(() => setIsLoading(false))
        }
    }, [dsprId, dispatch]);


    const getDatesForAnalytics = useCallback((analytics: Analytic) => {
        if (!analytics) return '';
        return formatDateForTable(analytics);
    }, [formatDateForTable]);

    const filteredAudits = useMemo(() => {
        if(audits) {
            const lowerCase = search.toLocaleLowerCase();
            return audits.filter((audit) => audit.name.toLocaleLowerCase().includes(lowerCase) || audit.startDate.includes(search) || audit.endDate.includes(search)).sort((a, b) => b.id - a.id)
        } else {
            return null;
        }
    }, [audits, search])

    return <main className="analytics-tab">
        {dsprId && dspr
            ? <h2>{dspr.name} Audits</h2>
            : <h2>Audits</h2>
        }

        <Card>
            <CardContent>
                <Dialog open={isLoading}>
                    <DialogContent>
                        <CircularProgress className="horizontal-center"/>
                    </DialogContent>
                </Dialog>
                <div>
                    <h5>About Audits:</h5>
                    <p>Audit reports are an indication of accuracy for Grassp's POS system. Every month, an automated report is created by analyzing the starting inventory and ending inventory of the DSPR, and analyzing all of the inventory transactions and sales that occured between the time period covered by the report.
                        If <strong>Starting Inventory + transactions - sales != Ending Inventory</strong>, that would be unaccounted inventory. 
                    </p>
                </div>
                <div className='audit-search-bar'>
                    <Input value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search"/>
                    <Button variant='text' color='primary' disabled={search === ""} onClick={() => setSearch("")} className="clear-search">Clear</Button>
                </div>

                {audits && (audits.length === 0) && <p className='no-items'>No Audits Found</p>}
                {audits && (filteredAudits.length === 0) && <p className='no-items'>No Audits Match Your Search Criteria!</p>}
               {!isLoading && <GridContainer>
                {audits && filteredAudits && filteredAudits.map((audit) => {
                    return (<GridItem key={"audit-report-"+audit.id}>
                        <Card className='audit-card'>
                            <CardActionArea onClick={() => history.push(`/dspr/${dsprId}/analytics/audit/${audit.id}`)} className="audit-action-area">
                                <CardContent>
                                    <h5>{audit.name}</h5>
                                    <p>Start: {audit.startDate}</p>
                                    <p>End: {audit.endDate}</p>
                                   
                                </CardContent>
                            </CardActionArea>
                            <div className='audit-buttons-container'>
                                <IconButton onClick={()=> window.open(getAudit(dsprId, encodeURIComponent(audit.fileName)))}>
                                    <GetApp className='audit-button' color='primary'/>
                                </IconButton>
                            </div>
                            
                        </Card>
                    </GridItem>)
                })}
                
               </GridContainer>}
            </CardContent>    
        </Card>        
    </main>
}

export default AuditContainer;