import React from 'react'
import { useDispatch } from "react-redux"
import { DsprDriver, User, DsprDriverLocation, OrderWithAddressAndUser, Route, DSPRDriverServiceArea, DSPR, RouteLeg, RouteMetrics, RouteLegDirection } from '../store/reduxStoreState'
import {createDSPRDriverRoute, progressDSPRDriverRoute, createDSPRDriverRouteWithoutNotifications, deactivateDSPRDriverRoute} from "../actions/driverActions"
import DriverRoutePage from '../components/DriverRoutePage';

interface DriverRouteContainerProps {
    driver: Omit<DsprDriver, 'user'> & {
        user: User,
        currentLocation?: DsprDriverLocation,
        queuedOrders?: OrderWithAddressAndUser[],
        currentInProcessOrder?: OrderWithAddressAndUser,
        currentRoute?: Omit<Route, 'legs'> & {
            legs: Omit<RouteLeg, 'order'> & {
                order: OrderWithAddressAndUser
                routeLegDirections: Omit<RouteLegDirection, 'metrics'> & {
                    metrics: RouteMetrics,
                }[],
                overviewPolyline: google.maps.LatLng[]
            }[];
        }
        serviceAreas?: DSPRDriverServiceArea[]
    };
    completeOrder: (orderId: number) => any;
    dspr: DSPR;
    modifyOrder: any;
    loggedInUserIsDriver: boolean;
    dsprDriverIdForOrderDetails: number;
    handleMapOrderClick: (order: any) => any;
}

const DriverRouteContainer: React.FC<DriverRouteContainerProps> = props => {
    const { driver, dspr, completeOrder, modifyOrder, loggedInUserIsDriver, dsprDriverIdForOrderDetails, handleMapOrderClick} = props;

    const dispatch = useDispatch();

    const createNewRoute = (driverId: number, waypoints: Array<OrderWithAddressAndUser | {id: string}>, finalDestination: any, usingFinalDestinationInRoute: boolean, sendNotifications: boolean) => {
        const orderIds = waypoints.map(order => ({ id: order.id}));
        if (!finalDestination) {
            finalDestination = orderIds.pop();
            usingFinalDestinationInRoute = true;
        } else {
            finalDestination = {id: finalDestination.id}
        }
        if(sendNotifications) {
            return dispatch(createDSPRDriverRoute(driverId, orderIds, finalDestination, usingFinalDestinationInRoute))
        } else {
            return dispatch(createDSPRDriverRouteWithoutNotifications(driverId, orderIds, finalDestination, usingFinalDestinationInRoute))
        }
    }

    const deactivateRoute = (routeId: number) => {
        return dispatch(deactivateDSPRDriverRoute(routeId))
    }

    const progressRoute = (routeId: number) => {
        return dispatch(progressDSPRDriverRoute(routeId))
    }
    return (
        <DriverRoutePage 
            driver={driver} 
            dspr={dspr} 
            createRoute={createNewRoute} 
            handleMapOrderClick={handleMapOrderClick} 
            completeOrder={completeOrder}
            progressRoute={progressRoute}
            modifyOrder={modifyOrder}
            loggedInUserIsDriver={loggedInUserIsDriver}
            dsprDriverIdForOrderDetails={dsprDriverIdForOrderDetails}
            deactivateRoute={deactivateRoute}/>
    )
}

export default DriverRouteContainer;