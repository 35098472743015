import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, DialogActions } from '@material-ui/core';

const validate = (values) => {
    const errors: any = {};
    if (!values.zipCode) {
        errors.zipCode = 'Required'
    }
    return errors
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <TextField label={label} type={type} {...input} error={!!(touched && error)} helperText={touched && error ? error : ''} />
    </div>
);

interface DSPRZipCodeFormProps {
    onCancel: () => void;
}

const DSPRZipCodeForm = (props: DSPRZipCodeFormProps & InjectedFormProps<{}, DSPRZipCodeFormProps>) => {
    const { handleSubmit, onCancel } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field name="zipCode" component={renderField} label="ZipCode" />
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, DSPRZipCodeFormProps>({
    form: 'DSPRZipCodeForm', // a unique identifier for this form
    validate
})(DSPRZipCodeForm)