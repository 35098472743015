import React, { useEffect, useState } from 'react';
import { Divider, Typography } from "@material-ui/core";

interface InvoiceHorizontalLine {
    idxOfComponent: number;
    batchNumber: string;
}

const InvoiceHorizontalLine = (props) => {
    const {
        idxOfComponent, batchNumber
    } = props;

    const [showHR, setShowHR] = useState(true);

    // useEffect(() => {
    //     if(idxOfComponent > 0) setShowHR(true);
    // },[])

    return showHR && <>
        <Typography variant={"subtitle2"}>{batchNumber}</Typography><Divider />
    </>

};

export default InvoiceHorizontalLine;