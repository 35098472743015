import React, { useState } from "react";
import { connect, shallowEqual, useSelector } from 'react-redux';
import { State, DeliveryServiceProvider, User } from '../store/reduxStoreState';
import Info from "../components/Typography/Info";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    CircularProgress,
    Dialog,
    Button,
    DialogTitle,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
// eslint-disable-next-line
import { pressSpecialAdminButton, pressSecondSpecialAdminButton, pressThirdSpecialAdminButton, pressCleanDatabaseAdminButton } from "../actions/systemAdminActions"


import DSPForm from './DSPForm';

import { history } from "../index";
import { getAllUsers } from "../actions/userActions";
import { interceptAutocompleteSelected } from "../actions/formHelperActions";
import { createDSP } from "../actions/dspActions";

import "./DSPManagementStyles.scss";
import { getUsersByName, getLoggedInUser } from "../selectors/userSelectors";
import { getManagedDSPsForLoggedInUser } from "../selectors/dspManagerSelectors";
import ApplicableTaxContainer from "./ApplicableTaxContainer";
import { API_ROOT } from "../middleware/api";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../actions/oauthActions";

interface Props {
    deliveryServiceProviders: { [key: number]: DeliveryServiceProvider },
    users: { value: number, text: string }[],
    loggedInUser: User;
    interceptAutocompleteSelected: (id: any) => any,
    getAllUsers: () => any,
    createDSP: (name: string, manager: number) => any,
    pressSpecialAdminButton: () => any,
    pressSecondSpecialAdminButton: () => any,
    pressThirdSpecialAdminButton: () => any,
    pressCleanDatabaseAdminButton: () => any
    //invalidateStateAccessToken: () => any;
}

function AdminPage(props: Props) {
    // eslint-disable-next-line
    const { users, getAllUsers, createDSP, interceptAutocompleteSelected, loggedInUser, pressSpecialAdminButton, pressSecondSpecialAdminButton, pressThirdSpecialAdminButton, pressCleanDatabaseAdminButton } = props;
    const [showNewDSPForm, setShowNewDSPForm] = useState(false);
    const [allUsersLoaded, setAllUsersLoaded] = useState(true)
    const userManagedDSPs = useSelector<State, DeliveryServiceProvider[]>(getManagedDSPsForLoggedInUser, shallowEqual);

    const isSystemAdmin: boolean = loggedInUser && loggedInUser.systemAdministrator;
    const userManagedActiveDSPs: DeliveryServiceProvider[] = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active === true) : [];
    const isDspManager: boolean = !!(loggedInUser && loggedInUser.deliveryServiceProviderManagers.length);

    //TODO: Where should we route the user if they only manage an inactive DSP?

    /**If user is not a system admin and manages only 1 active DSP, push the user to the overview page for the active DSP they manage
     * (if user manages multiple DSPs, user will select DSP they wish to view)*/
    if (!isSystemAdmin && isDspManager && (userManagedActiveDSPs.length === 1)) {
        const dspId = userManagedActiveDSPs[0].id;
        history.push(`/dsp/${dspId}/overview`);
    }

    function handleNewDSPFormOpen() {
        setAllUsersLoaded(false);
        getAllUsers().then(() => {
            setAllUsersLoaded(true)
        }); //TODO: Logic so we don't get all users every time a modal is clicked
        setShowNewDSPForm(true);
    }

    function handleNewDSPFormClose() {
        setShowNewDSPForm(false);
    }

    function handleDSPClick(dspId: string) {
        history.push(`/dsp/${dspId}`);
    }

    function handleSubmit(newDSPFormValues) {
        createDSP(newDSPFormValues.name, newDSPFormValues.userId);
        handleNewDSPFormClose();
    }

    function handleCSVDownloadButton() {
        const url = API_ROOT + `data_consistency_controller?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`
        var win = window.open(url, '_blank');
        win.focus();
    }

    const dspCards = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active).map(dsp => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={dsp.id}>
            <Card className="dsp-card">
                <CardActionArea onClick={() => handleDSPClick(dsp.id.toString())}>
                    <CardContent>
                        <h4>{dsp.name}</h4>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>)
    ) : undefined

    return (
        <main className='dsp-management'>
            <h2>DSP Management</h2>
            {/* <h4>Metrics</h4>
            <section className='dsp-metrics'>
                <Grid container spacing={3}>
                    <StatCard
                        mainText="Total Users"
                        mainIcon={<People/>}
                        mainValue={"Value 1"}
                        color='info'
                        grid
                        footerIcon={<Info/>}
                        footerText='Description 1'/>
                    <StatCard
                        mainText="New Users"
                        mainIcon={<GroupAdd/>}
                        mainValue={"Value 2"}
                        color='success'
                        grid
                        footerIcon={<Info/>}
                        footerText='Description 2'/>
                    <StatCard
                        mainText="Unverified Users"
                        mainIcon={<Description/>}
                        mainValue={"Value 3"}
                        color='warning'
                        grid
                        footerIcon={<Info/>}
                        footerText='Description 3'/>
                </Grid>
            </section> */}
            <h4>Delivery Service Providers</h4>
            <section className="dsp-card-container">
                <Grid container spacing={3}>
                    {dspCards}
                    {isSystemAdmin ?
                        dspCards ?
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <CardActionArea onClick={handleNewDSPFormOpen}>
                                    <CardContent className="dsp-add">
                                        <Info><Add /></Info>
                                        <Info><h4>New DSP</h4></Info>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        : <CircularProgress />
                    :null}
                </Grid>
                {/* <Button variant="contained" color="primary" onClick={() => invalidateToken()}>Invalidate Token</Button> */}
                <Dialog
                    title="New Delivery Service Provider"
                    fullScreen={false}
                    open={showNewDSPForm}
                    onClose={handleNewDSPFormClose}
                >
                    <DialogTitle>Add A New DSP</DialogTitle>
                    <DSPForm
                        editMode={false}
                        users={users}
                        onSubmit={handleSubmit}
                        onCancel={handleNewDSPFormClose}
                        handleAutoCompleteSelected={interceptAutocompleteSelected}
                        usersLoaded={allUsersLoaded}
                    />
                </Dialog>
            </section>
            <section className="applicable-taxes">
                {loggedInUser && (loggedInUser.id === 1 || loggedInUser.id === 18874) && <Card>
                    <CardHeader title="Special Admin Button"/>
                    <CardContent>
                        <div className="admin-buttons">
                            <Button variant="contained" color="primary" onClick={() => pressSpecialAdminButton()}>Admin Button A</Button>
                            <Button variant="contained" color="primary" onClick={() => pressSecondSpecialAdminButton()}>Admin Button B</Button>
                            <Button variant="contained" color="primary" onClick={() => pressThirdSpecialAdminButton()}>Admin Button C</Button>
                            <Button variant="contained" color="primary" onClick={() => pressCleanDatabaseAdminButton()}>Clean Database</Button>
                        </div> 
                    </CardContent>
                </Card>}
            </section>
            <section className="applicable-taxes">
                {loggedInUser && (loggedInUser.id === 1 || loggedInUser.id === 18874) && <ApplicableTaxContainer/>}
            </section>
            

        </main>
    )
}

const mapStateToProps = (state: State) => {
    const users = getUsersByName(state);
    return {
        users,
        loggedInUser: getLoggedInUser(state),
    };
};

const mapDispatchToProps = {
    getAllUsers,
    interceptAutocompleteSelected,
    createDSP,
    pressSpecialAdminButton,
    pressSecondSpecialAdminButton,
    pressThirdSpecialAdminButton,
    pressCleanDatabaseAdminButton
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)