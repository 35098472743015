import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    Button,
    TextField,
    Checkbox,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel
} from '@material-ui/core';
import {ApplicableTax} from '../store/reduxStoreState';

import './couponFormStyles.scss';
import AutoComplete from '../components/Autocomplete';
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'rate'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    if (values['appliedToRecUserOnly'] === "true" && values['appliedToMedUserOnly'] === "true") {
        errors['appliedToRecUserOnly'] = 'Required either med user or rec user, cannot be both';
    }
    if (values['appliedToNonStateIssuedCardOnly'] === true && values['appliedToStateIssuedCardOnly'] === true) {
        errors['appliedToNonStateIssuedCardOnly'] = 'Cannot apply to both State Issued Medical Documents and Non State Issued Medical Documents';
    }
    return errors;
};

const renderField = ({ input, defaultSelected, label, type, dsprs, handleAutoCompleteSelected,
    meta: { touched, error, form }, children, ...custom }) => {
    switch (type) {
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} />} />
        case 'radioGroup':
            return <RadioGroup
                {...input}
                onChange={(_, value) => input.onChange(value)}
                children={children}
            />
        case 'autoComplete':
            return <AutoComplete
                options={dsprs}
                label={label}
                handleAutoCompleteSelected={handleAutoCompleteSelected}
                touched={touched}
                error={error}
                input={input}
                form={form}
                {...custom}
            />
        default:
            return <TextField
                label={label} type={type} {...input} {...custom} error={!!(touched && error)} helperText={touched && error ? error : ''}
            />;
    }
};
const renderError = ({input, meta, ...props}) => (
    <span {...props}>
            {meta.error ?
                <Alert {...props} severity="error">
                    {`Error: ${meta.error}`}</Alert> :<span></span>
            }
        </span>
)

interface ApplicableTaxFormProps {
    applicableTax?: ApplicableTax;
    showApplicableTaxForm: boolean;
}

const ApplicableTaxForm = (props: ApplicableTaxFormProps & InjectedFormProps<{}, ApplicableTaxFormProps>) => {
    const { handleSubmit, applicableTax,showApplicableTaxForm} = props;

    return (
        <form onSubmit={handleSubmit} className="coupon-forms">
            <div className='errorContainer'>
                <Field name='appliedToRecUserOnly' component={renderError} />
                <Field name='appliedToMedUserOnly' component={renderError} />
            </div>
             <Field name="name" component={renderField} label="Name Of Applicable Tax"/>

            <Field name="rate" component={renderField} type="number" label="Rate" />

                <FormControl component="fieldset">
                    <FormLabel component="legend">Is this tax applied to only recreational users?</FormLabel>
                    <Field name="appliedToRecUserOnly" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Field>
                </FormControl>

                <FormControl component="fieldset">
                    <FormLabel component="legend">Is this tax applied to only medical users?</FormLabel>
                    <Field name="appliedToMedUserOnly" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Field>
                </FormControl>

                <FormControl component="fieldset">
                    <FormLabel component="legend">Is this tax only applied to NON state-issued medical cards?</FormLabel>
                    <Field name="appliedToNonStateIssuedCardOnly" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Field>
                </FormControl>

                <FormControl component="fieldset">
                    <FormLabel component="legend">Is this tax only applied to state-issued medical cards?</FormLabel>
                    <Field name="appliedToStateIssuedCardOnly" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Field>
                </FormControl>

                <FormControl component="fieldset">
                    <FormLabel component="legend">Can this be applied To All Other Taxes?</FormLabel>
                    <Field name="appliedToAllOtherTaxes" component={renderField} type="radioGroup">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Field>
                </FormControl>
            <div>

                <Button variant="contained" color="primary" onClick={handleSubmit}>
                           {showApplicableTaxForm ? 'Create Applicable Tax' : 'Modify Tax'}
                </Button>
            </div>
        </form>
    )
};
const ApplicableTaxFormRedux = reduxForm<any, ApplicableTaxFormProps>({
    form: 'ApplicableTaxForm',
    validate
})(ApplicableTaxForm);
export default connect(
    (state, props: ApplicableTaxFormProps) => {
    if (props.applicableTax) {
        const {
            id,
            name,
            rate,
            appliedToRecUserOnly,
            appliedToMedUserOnly,
            appliedToNonStateIssuedCardOnly,
            appliedToAllOtherTaxes
        }
            = props.applicableTax;

        return {
            initialValues: {
                id,
                name,
                rate,
                appliedToRecUserOnly: appliedToRecUserOnly ? 'true' : 'false',
                appliedToMedUserOnly: appliedToMedUserOnly ? 'true' : 'false',
                appliedToNonStateIssuedCardOnly: appliedToNonStateIssuedCardOnly ? 'true' : 'false',
                appliedToAllOtherTaxes: appliedToAllOtherTaxes ? 'true' : 'false',
            }

        }
    }else {
        return {}
    }
}
)(ApplicableTaxFormRedux);