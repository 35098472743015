import React, {useState, useEffect} from 'react';
import './SearchOrCreateFormStyles.scss';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';

interface SearchOrCreateProps {
    filterBySearchTerm: (...args: any) => any;
    createNew?: (formData) => any;
    placeholderText?: string;
    buttonText?: string;
    inputLabel?: string;
    showCreateButton?: boolean;
    filterRequiresId?: boolean;
    id?: number;
    disableOverride?:boolean
    clearSearch?: boolean
}

function SearchOrCreateForm({
                                filterBySearchTerm,
                                createNew,
                                id,
                                disableOverride,
                                filterRequiresId = false,
                                placeholderText = 'Search',
                                buttonText = 'Create',
                                inputLabel = '',
                                showCreateButton = true,
                                clearSearch = false,
                            }: SearchOrCreateProps) {
    const [searchTerm, setSearchTerm] = useState('');

    function handleChange(evt) {
        setSearchTerm(evt.target.value);
        if (filterRequiresId) {
            filterBySearchTerm(evt.target.value, id);
        } else {
            filterBySearchTerm(evt.target.value);
        }
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        createNew(searchTerm);
        setSearchTerm('');
    }

    useEffect(() => {
        if (clearSearch) {
            setSearchTerm('')
        }
    }, [clearSearch]);

    return (
        <form onSubmit={handleSubmit} className='Search-Or-Create-Form'>
            <FormControl>
                <Box className='search-form-container'>
                    {inputLabel &&
                    <InputLabel htmlFor="search-or-create-brand">{inputLabel}</InputLabel>
                    }
                    <Input
                        id="search-or-create-brand"
                        value={searchTerm}
                        onChange={handleChange}
                        placeholder={placeholderText}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                    />
                    {showCreateButton &&
                    <Button color='primary' variant='contained' onClick={handleSubmit} disabled={disableOverride ? false : searchTerm.length === 0}>{buttonText}</Button>
                    }
                </Box>
            </FormControl>
        </form>
    );
}

export default SearchOrCreateForm;