import React, { useMemo } from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
    TextField,
    Button,
    CardActions,
    Typography,
} from '@material-ui/core';

import { TRANSACTION_TYPES } from '../actions/dsprProductInventoryActions';
import AutoComplete, { AutoSelectStringValueItems } from '../components/Autocomplete';
import './DSPRInventoryTransactionFormStyles.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { getProductInDSPRInventoryTransactionForm } from '../selectors/reduxFormSelectors';

const number = (value: string) => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const required = (value: string) => value ? undefined : 'Required';

const renderField = ({
    input,
    label,
    type,
    autocomplete,
    datasource,
    handleAutoCompleteSelected,
    multiline,
    style,
    meta: { touched, error, form }
}) => (
    autocomplete ?
        <AutoComplete
            options={datasource}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
            inputStyle={{ minWidth: '240px' }} />
        :
        <TextField label={label}
            type={type} {...input}
            error={!!(touched && error)}
            helperText={touched && error ? error : ''}
            multiline={multiline > 0}
            fullWidth={multiline > 0}
            rows={multiline}
            style={style} />
);

interface DSPRInventoryTransactionFormProps {
    products: { value: number, text: string }[];
    metrcTagsForAutoSelect: AutoSelectStringValueItems;
    handleProductAutoCompleteSelected: (id: number) => any;
    handleDriverAutoCompleteSelected: (id: number) => any;
    handleMetrcTagAutoCompleteSelected: (tag: string) => any;
    handleMetrcReturnReasonsAutoCompleteSelected: (returnReason: string) => any;
    batchNumbersForAutoSelect: AutoSelectStringValueItems;
    metrcReturnReasonsForAutoSelect: AutoSelectStringValueItems;
    handleBatchNumberAutoCompleteSelected: (batch: string) => any;
    drivers: { value: number, text: string }[];
    isStock: boolean;
    isCost: boolean;
    transactionType: string;
    isMetrcDspr: boolean;
    isBatchDspr: boolean;
    isLoading: boolean;
    handleUpdateMetrcTagsFromMetrc: () => void;
}

const DSPRInventoryTransactionForm = (props: DSPRInventoryTransactionFormProps & InjectedFormProps<{}, DSPRInventoryTransactionFormProps>) => {
    const {
        handleSubmit,
        products,
        handleProductAutoCompleteSelected,
        handleDriverAutoCompleteSelected,
        drivers,
        isStock,
        isCost,
        transactionType,
        isMetrcDspr,
        isBatchDspr,
        handleMetrcTagAutoCompleteSelected,
        handleUpdateMetrcTagsFromMetrc,
        handleBatchNumberAutoCompleteSelected,
        isLoading,
        metrcTagsForAutoSelect,
        metrcReturnReasonsForAutoSelect,
        batchNumbersForAutoSelect,
        handleMetrcReturnReasonsAutoCompleteSelected
    } = props;

    // Gets the selcted Product from the form, and checks if it is a cannabis product
    const selectedProduct = useSelector(getProductInDSPRInventoryTransactionForm, shallowEqual);
    const is_cannabis_product = useMemo(() => {
        return selectedProduct && selectedProduct.productType === 'non_cannabis' ? false : true;
    }, [selectedProduct]);


    const costString = transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT ? "Amount by which to Adjust Stock Cost Basis (negative for decrease)" : "Cost of Inventory";
    const quantityString = transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT ? "Amount by which to Adjust Stock Quantity (negative for decrease)" : "Quantity of Product";
    return (
        <form onSubmit={handleSubmit} className={'inventory-transaction-form'}>


            {isStock && <Field name="invoiceNumber"
                component={renderField}
                label={"Invoice Number (Optional)"}
                style={{ minWidth: '280px' }} />}

            {!isStock && <Field name="driverId"
                validate={[required]}
                component={renderField}
                autocomplete={true}
                datasource={drivers}
                label="Driver"
                handleAutoCompleteSelected={handleDriverAutoCompleteSelected} />}


            <Field name="productId"
                validate={[required]}
                component={renderField}
                autocomplete={true}
                datasource={products}
                label="Name of Product"
                handleAutoCompleteSelected={handleProductAutoCompleteSelected} />

            {(transactionType !== TRANSACTION_TYPES.STOCK_ADJUSTMENT || !isCost) && <Field name="quantity"
                validate={[required, number]}
                component={renderField}
                label={quantityString}
                style={{ minWidth: '280px' }} />}

            {(transactionType === TRANSACTION_TYPES.STOCK || (transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT && isCost)) &&
                <Field validate={[required, number]}
                    name="cost"
                    component={renderField}
                    label={costString}
                    style={{ minWidth: '280px' }} />}

            {((transactionType === TRANSACTION_TYPES.STOCK && isMetrcDspr) || (transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT && isStock && isMetrcDspr))
                && <div className={'metrc-tag-autocomplete-container'}>
                    <div className={'metrc-tag-autocomplete-field-container'}>
                        <Field name={'metrcTag'}
                            validate={is_cannabis_product ? [required] : []}
                            component={renderField}
                            autocomplete={true}
                            datasource={metrcTagsForAutoSelect}
                            label={'Metrc Tag'}
                            handleAutoCompleteSelected={handleMetrcTagAutoCompleteSelected} />
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Enter last 4 digits of tag
                        </Typography>
                    </div>
                    <div className={'metrc-tag-autocomplete-button-container'}>
                        <Typography variant={'body2'} color={'textSecondary'}>Can't find tag?</Typography>
                        <Button variant={'outlined'} size={'small'} onClick={handleUpdateMetrcTagsFromMetrc}>{isLoading ? 'Fetching...' : 'Fetch Tags'}</Button>
                    </div>
                </div>}

            {((transactionType === TRANSACTION_TYPES.STOCK && isBatchDspr))
                && <div className={'batch-number-container'}>
                    <div className={'batch-number-field-container'}>
                        <Field name={'batchNumber'}
                            validate={[required]}
                            component={renderField}
                            label={'Batch Number'}
                            style={{ minWidth: '280px' }}
                        />
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Please double check the batch number
                        </Typography>
                    </div>
                </div>}

            {((transactionType === TRANSACTION_TYPES.RETURN_TO_VENDOR && isBatchDspr) || (transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT && isStock && isBatchDspr))
                && <div className={'batch-number-container'}>
                    <div className={'batch-number-field-container'}>
                        <Field name={'batchNumber'}
                            validate={[required]}
                            component={renderField}
                            autocomplete={true}
                            datasource={batchNumbersForAutoSelect}
                            label={'Batch Number'}
                            handleAutoCompleteSelected={handleBatchNumberAutoCompleteSelected} />
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Please double check the batch number
                        </Typography>
                    </div>
                </div>}
            {((transactionType === TRANSACTION_TYPES.RETURN_TO_VENDOR && isMetrcDspr) || (transactionType === TRANSACTION_TYPES.STOCK_ADJUSTMENT && isStock && isMetrcDspr))
                && <div className={'batch-number-container'}>
                    <div className={'batch-number-field-container'}>
                        <Field name={'metrcReturnReason'}
                            validate={metrcReturnReasonsForAutoSelect && metrcReturnReasonsForAutoSelect.length > 0 ? [required] : []}
                            component={renderField}
                            autocomplete={true}
                            datasource={metrcReturnReasonsForAutoSelect}
                            label={metrcReturnReasonsForAutoSelect && metrcReturnReasonsForAutoSelect.length > 0 ? 'Metrc Return Reason' : "Metrc Return Reason (Not Required, none given)"}
                            handleAutoCompleteSelected={handleMetrcReturnReasonsAutoCompleteSelected} />
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Please Select Your Metrc Return Reason.
                        </Typography>
                    </div>
                </div>}

            {transactionType !== TRANSACTION_TYPES.STOCK &&
                <Field validate={[required]} name="notes" multiline={2} component={renderField} label="Notes" />}
            <CardActions className={'submit-button-container'}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Create</Button>
            </CardActions>

        </form>
    )
};

export default reduxForm<any, DSPRInventoryTransactionFormProps>({
    form: 'DSPRInventoryTransactionForm'
})(DSPRInventoryTransactionForm)