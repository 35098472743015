import {
	ADMIN_CHANGES_USER_DETAILS_SUCCESS,
	CREATE_USER_NOTE_SUCCESS,
	GET_ALL_USER_ID_DOCUMENTS_SUCCESS,
	GET_ALL_USER_MEDICAL_RECOMMENDATIONS_SUCCESS,
	GET_ALL_USERS_SUCCESS,
	GET_ORDER_HISTORY_FOR_USER_SUCCESS,
	GET_SPECIFIC_USER_SUCCESS,
	GET_USER_ID_DOCUMENT_SUCCESS,
	GET_USER_MEDICAL_RECOMMENDATION_SUCCESS,
	GET_USERS_BY_SEARCH_SUCCESS,
	GET_USERS_WITH_UNVERIFIED_DOCUMENTS_SUCCESS,
	HIDE_USER_DOCUMENT_SUCCESS,
	HIDE_USER_NOTE_SUCCESS,
	LOGGED_IN_USER_INFO_SUCCESS,
	SET_CURRENT_USER_ID_SUCCESS,
	SET_CURRENT_USER_MEDICAL_RECOMMENDATION_SUCCESS,
	UNHIDE_USER_DOCUMENT_SUCCESS,
	UNHIDE_USER_NOTE_SUCCESS,
	UPLOAD_USER_DOCUMENT_SUCCESS,
	VERIFY_USER_DOCUMENT_SUCCESS,
} from '../actions/userActions';
import {
	ASSIGN_DSP_MANAGER_SUCCESS,
	CREATE_DSP_SUCCESS,
	GET_ALL_DSPS_SUCCESS,
	GET_ANALYTICS_FOR_DSP_SUCCESS,
	GET_DSP_SUCCESS,
	TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS,
} from '../actions/dspActions';
import {
	ASSIGN_DSPR_MANAGER_SUCCESS,
	CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS,
	CREATE_DSPR_PROMOTION_FOR_PRODUCT_CATEGORY_SUCCESS,
	CREATE_DSPR_SUCCESS,
	CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS,
	GET_ALL_DSPRS_FOR_DSP_SUCCESS,
	GET_ALL_ON_CALL_DRIVERS_FOR_DSPR_SUCCESS,
	GET_ANALYTICS_FOR_DRIVER_SUCCESS,
	GET_ANALYTICS_FOR_DSPR_SUCCESS,
	GET_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS,
	GET_DSPR_DRIVER_SERVICE_AREAS_SUCCESS,
	GET_DSPR_PROMOTION_FOR_PRODUCT_CATEGORIES_SUCCESS,
	GET_DSPR_SUCCESS,
	GET_ORDER_HISTORY_FOR_DSPR_SUCCESS,
	HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS,
	SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS,
	SET_DSPR_FULL_MENU_DRIVER_SUCCESS,
	TOGGLE_DSPR_MANAGER_ACTIVE_STATUS_SUCCESS,
	TOGGLE_DSPR_MANAGER_DRIVER_NOTIFICATION_STATUS_SUCCESS,
	TOGGLE_DSPR_MANAGER_ORDER_NOTIFICATION_STATUS_SUCCESS,
	UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS,
	UPDATE_DSPR_MENU_MECHANISM_SUCCESS,
	GET_DSPR_AUDITS_SUCCESS,
	GET_AUDIT_DETAILS_SUCCESS,
} from '../actions/dsprActions';
import {
	ASSIGN_DSPR_DRIVER_SUCCESS,
	CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS,
	CREATE_NEW_DSPR_DRIVER_ROUTE_WITHOUT_NOTIFICATIONS_SUCCESS,
	DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS,
	GET_ALL_DRIVERS_FOR_DSPR_SUCCESS,
	GET_DSPR_DRIVER_SUCCESS,
	PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS,
	SET_DRIVER_INFORMATION_SUCCESS,
	SET_DRIVER_LOCATION_SUCCESS,
	SET_ON_CALL_STATE_FOR_DRIVER_SUCCESS,
	TOGGLE_DSPR_DRIVER_ACTIVE_STATUS_SUCCESS,
} from '../actions/driverActions';
import {
	CREATE_DSP_PRODUCT_SUCCESS,
	CREATE_NEW_CATEGORY_SUCCESS,
	GET_ALL_PRODUCTS_FOR_DSP_SUCCESS,
	GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS,
	GET_PRODUCT_BY_SEARCH_SUCCESS,
	GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS,
	GET_PRODUCT_SUCCESS,
	POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS,
} from '../actions/dspProductActions';
import {
	ADD_INVENTORY_ITEM_TO_PERIOD_SUCCESS,
	CREATE_INVENTORY_PERIOD_SUCCESS,
	GET_DRIVER_INVENTORY_PERIOD_SUCCESS,
	REMOVE_INVENTORY_ITEM_FROM_PERIOD_SUCCESS,
	TRANSFER_INVENTORY_PERIOD_SUCCESS,
} from '../actions/dsprDriverInventoryPeriodActions';
import {
	ADD_DSPR_ZIP_CODE_SUCCESS,
	GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS,
	TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS,
	ZIP_CODE_MINIMUM_CHANGE_SUCCESS,
} from '../actions/zipCodeActions';
import {
	CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS,
	GET_DSPR_CURRENT_INVENTORY_SUCCESS,
	GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS,
	SET_DSPR_PRODUCT_PRICE_SUCCESS,
	GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS,
} from '../actions/dsprProductInventoryActions';
import {
	CREATE_NEW_COUPON_SUCCESS,
	GET_DSPR_COUPON_LOCK_TYPES_SUCCESS,
	GET_DSPR_COUPONS_SUCCESS,
	MODIFY_COUPON_SUCCESS,
	SEARCH_DSPR_COUPONS_SUCCESS,
	TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS,
	UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS,
} from '../actions/couponActions';
import {
	CREATE_NEW_APPLICABLE_TAX_SUCCESS,
	DELETE_APPLICABLE_TAX_SUCCESS,
	GET_ALL_APPLICABLE_TAXES_SUCCESS,
} from '../actions/applicableTaxActions';
import {
	ASSIGN_DRIVER_ORDER_SUCCESS,
	CANCEL_ORDER_SUCCESS,
	COMPLETE_ORDER_SUCCESS,
	CONFIRM_ALLOTMENT_ORDER_SUCCESS,
	GET_ORDER_DETAILS_WITH_ID_SUCCESS,
	IN_ROUTE_ORDER_SUCCESS,
	MODIFY_ORDER_SUCCESS,
	NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS,
	PACKAGE_ORDER_SUCCESS,
	QUEUE_ORDER_SUCCESS,
	UNCANCEL_ORDER_SUCCESS,
	UNCOMPLETE_ORDER_SUCCESS,
} from '../actions/orderActions';
import {
	ADD_PRODUCT_TO_BRAND_SUCCESS,
	CREATE_BRAND_SUCCESS,
	GET_ALL_BRANDS_FOR_DSP_SUCCESS,
	GET_BRAND_SUCCESS,
	GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS,
	GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS,
	REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS,
	REMOVE_PRODUCT_FROM_BRAND_SUCCESS,
} from '../actions/brandActions';
import { SEND_TEXT_BLAST_SUCCESS } from '../actions/marketingActions';
import { GET_METRICS_FOR_USERS_SUCCESS } from '../actions/metricsActions';

import { isArray, merge, mergeWith } from 'lodash';
import {
	GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS,
	GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS,
	GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS,
	GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS,
	REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS,
} from '../actions/metrcTagActions';
import {
	CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
	DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
	GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS,
} from '../actions/dsprApplicableTaxAssociationActions';
import ApplicableTaxes from '../components/ApplicableTaxes';
import {
	GET_CURRENT_ORDER_SCANS_FOR_ORDER_SUCCESS,
	ORDER_SCAN_SUBMIT_SUCCESS,
	RESET_ORDER_SCANS_SUCCESS,
} from '../actions/scanActions';
import { GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS } from '../actions/batchNumberActions';

export const initialState = {
	users: {},
	unverifiedUsers: {},
	searchUsers: {},
	deliveryServiceProviders: {},
	dspManagers: {},
	DSPRs: {},
	dsprManagers: {},
	dsprDrivers: {},
	dsprDriverLocations: {},
	dsprDriverInventoryPeriods: {},
	dsprDriverInventoryItems: {},
	dsprProductInventoryTransactions: {},
	dsprProductInventoryTransactionHistories: {},
	dsprCurrentInventoryItems: {},
	dsprZipCodes: {},
	usersIdDocuments: {},
	usersMedicalRecommendations: {},
	dsprProductPriceHistories: {},
	dspProductCategories: {},
	dsprProductCategoryPromotions: {},
	coupons: {},
	orders: {},
	addresses: {},
	dsprOrderHistories: {},
	textBlasts: {},
	userNotes: {},
	searchProducts: {},
	dsprDriverServiceAreas: {},
	dsprDriverServiceAreaVertices: {},
	metrics: {},
	dsprDriverRoutes: {},
	dsprDriverRouteLegs: {},
	dsprDriverRouteLegDirections: {},
	dsprDriverRouteLocations: {},
	dsprDriverRouteMetrics: {},
	dsprDriverServiceAreaProfiles: {},
	brands: {},
	dspProducts: {},
	dsprMetrcTags: {},
	dsprBatchNumbers: {},
	applicableTax: {},
	applicableTaxesWithDSPR: {},
	orderScans: {},
	dsprCouponLockTypes: {},
	dsprAudits: {},
};

const overwriteArray = (objValue, srcValue) => {
	if (isArray(srcValue)) return srcValue;
};

const appendAndUpdateEntitiesFromResponse = (oldState, responseEntities, skipEntityTypes: string[] = []) => {
	let newState = merge({}, oldState);
	Object.keys(responseEntities).forEach((entityType) => {
		if (skipEntityTypes.includes(entityType)) return;
		let oldStateTypeEntities = oldState[entityType];
		let entitiesInResponse = responseEntities[entityType];
		newState[entityType] = merge({}, oldStateTypeEntities, entitiesInResponse);
	});
	return newState;
};

const appendAndUpdateEntitiesFromResponseWithArrayOverwrite = (
	oldState,
	responseEntities,
	skipEntityTypes: string[] = [],
) => {
	let newState = merge({}, oldState);
	Object.keys(responseEntities).forEach((entityType) => {
		if (skipEntityTypes.includes(entityType)) return;
		let oldStateTypeEntities = oldState[entityType];
		let entitiesInResponse = responseEntities[entityType];
		newState[entityType] = mergeWith({}, oldStateTypeEntities, entitiesInResponse, overwriteArray);
	});
	return newState;
};

export default (state = initialState, action) => {
	let responseEntities = action.response.entities;
	switch (action.type) {
		case LOGGED_IN_USER_INFO_SUCCESS:
		case GET_ALL_USERS_SUCCESS:
		case CREATE_DSP_SUCCESS:
		case CREATE_DSPR_SUCCESS:
		case TOGGLE_DSP_MANAGER_ACTIVE_STATUS_SUCCESS:
		case ASSIGN_DSP_MANAGER_SUCCESS:
		case TOGGLE_DSPR_MANAGER_ACTIVE_STATUS_SUCCESS:
		case ASSIGN_DSPR_MANAGER_SUCCESS:
		case ASSIGN_DSPR_DRIVER_SUCCESS:
		case TOGGLE_DSPR_DRIVER_ACTIVE_STATUS_SUCCESS:
		case GET_ALL_ON_CALL_DRIVERS_FOR_DSPR_SUCCESS:
		case GET_ALL_DSPS_SUCCESS:
		case GET_DSP_SUCCESS:
		case GET_ALL_DSPRS_FOR_DSP_SUCCESS:
		case GET_SPECIFIC_USER_SUCCESS:
		case GET_PRODUCT_SUCCESS:
		case CREATE_DSP_PRODUCT_SUCCESS:
		case SET_ON_CALL_STATE_FOR_DRIVER_SUCCESS:
		case SET_DRIVER_LOCATION_SUCCESS:
		case CREATE_INVENTORY_PERIOD_SUCCESS:
		case GET_ALL_ZIP_CODES_FOR_DSPR_SUCCESS:
		case ADD_DSPR_ZIP_CODE_SUCCESS:
		case TOGGLE_DSPR_ZIP_CODE_ACTIVE_STATUS_SUCCESS:
		case GET_ALL_PRODUCTS_FOR_DSP_SUCCESS:
		case CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS:
		case GET_DSPR_CURRENT_INVENTORY_SUCCESS:
		case GET_DRIVER_INVENTORY_PERIOD_SUCCESS:
		case ADD_INVENTORY_ITEM_TO_PERIOD_SUCCESS:
		case GET_USER_ID_DOCUMENT_SUCCESS:
		case GET_USER_MEDICAL_RECOMMENDATION_SUCCESS:
		case VERIFY_USER_DOCUMENT_SUCCESS:
		case SET_DSPR_PRODUCT_PRICE_SUCCESS:
		case CREATE_NEW_CATEGORY_SUCCESS:
		case CREATE_NEW_COUPON_SUCCESS:
		case GET_DSPR_COUPONS_SUCCESS:
		case TOGGLE_COUPON_ACTIVE_STATUS_SUCCESS:
		case COMPLETE_ORDER_SUCCESS:
		case CANCEL_ORDER_SUCCESS:
		case CONFIRM_ALLOTMENT_ORDER_SUCCESS:
		case NOTIFY_USER_ALLOTMENT_ISSUE_ORDER_SUCCESS:
		case PACKAGE_ORDER_SUCCESS:
		case TOGGLE_DSPR_MANAGER_ORDER_NOTIFICATION_STATUS_SUCCESS:
		case TOGGLE_DSPR_MANAGER_DRIVER_NOTIFICATION_STATUS_SUCCESS:
		case UPLOAD_USER_DOCUMENT_SUCCESS:
		case SEND_TEXT_BLAST_SUCCESS:
		case TRANSFER_INVENTORY_PERIOD_SUCCESS:
		case GET_ANALYTICS_FOR_DSPR_SUCCESS:
		case GET_ANALYTICS_FOR_DRIVER_SUCCESS:
		case CREATE_USER_NOTE_SUCCESS:
		case SET_DRIVER_INFORMATION_SUCCESS:
		case GET_ORDER_DETAILS_WITH_ID_SUCCESS:
		case HIDE_USER_NOTE_SUCCESS:
		case UNHIDE_USER_NOTE_SUCCESS:
		case HIDE_USER_DOCUMENT_SUCCESS:
		case GET_METRC_RETURN_REASONS_FOR_DSPR_FROM_METRC_SUCCESS:
		case UNHIDE_USER_DOCUMENT_SUCCESS:
		case ADMIN_CHANGES_USER_DETAILS_SUCCESS:
		case GET_PRODUCT_CATEGORIES_FOR_DSP_SUCCESS:
		case CREATE_DSPR_PROMOTION_FOR_PRODUCT_CATEGORY_SUCCESS:
		case GET_DSPR_PROMOTION_FOR_PRODUCT_CATEGORIES_SUCCESS:
		case HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS:
		case GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS:
		case POST_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS:
		case GET_METRICS_FOR_USERS_SUCCESS:
		case GET_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS:
		case CREATE_DSPR_DRIVER_SERVICE_AREA_PROFILES_SUCCESS:
		case SET_DSPR_DRIVER_SERVICE_AREA_PROFILE_ACTIVE_SUCCESS:
		case UNCOMPLETE_ORDER_SUCCESS:
		case GET_ALL_BRANDS_FOR_DSP_SUCCESS:
		case GET_BRAND_SUCCESS:
		case CREATE_BRAND_SUCCESS:
		case GET_ANALYTICS_FOR_DSP_SUCCESS:
		case MODIFY_COUPON_SUCCESS:
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case GET_DSPR_SUCCESS:
		case REMOVE_INVENTORY_ITEM_FROM_PERIOD_SUCCESS:
		case GET_ORDER_HISTORY_FOR_DSPR_SUCCESS:
		case GET_ORDER_HISTORY_FOR_USER_SUCCESS:
		case GET_ALL_USER_ID_DOCUMENTS_SUCCESS:
		case GET_ALL_USER_MEDICAL_RECOMMENDATIONS_SUCCESS:
		case GET_DSPR_DRIVER_SERVICE_AREAS_SUCCESS:
		case UPDATE_DSPR_MENU_MECHANISM_SUCCESS:
		case CREATE_NEW_DSPR_DRIVER_ROUTE_SUCCESS:
		case CREATE_NEW_DSPR_DRIVER_ROUTE_WITHOUT_NOTIFICATIONS_SUCCESS:
		case PROGRESS_DSPR_DRIVER_ROUTE_SUCCESS:
		case DEACTIVATE_DSPR_DRIVER_ROUTE_SUCCESS:
		case ORDER_SCAN_SUBMIT_SUCCESS:
		case CREATE_NEW_APPLICABLE_TAX_SUCCESS:
		case DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
		case GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
		case ASSIGN_DRIVER_ORDER_SUCCESS:
		case IN_ROUTE_ORDER_SUCCESS:
		case QUEUE_ORDER_SUCCESS:
		case CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS:
		case GET_DSPR_AUDITS_SUCCESS:
		case GET_ALL_DRIVERS_FOR_DSPR_SUCCESS:
		case GET_AUDIT_DETAILS_SUCCESS:
			return appendAndUpdateEntitiesFromResponseWithArrayOverwrite(state, responseEntities);
		case CREATE_OR_UPDATE_DSPR_DRIVER_SERVICE_AREA_SUCCESS:
			const dsprServiceAreaFromResponse: any =
				responseEntities && responseEntities.dsprDriverServiceAreas
					? Object.values(responseEntities.dsprDriverServiceAreas)[0]
					: undefined;
			const stateWithoutDSPRDriverServiceArea = dsprServiceAreaFromResponse
				? {
						...state,
						dsprDriverServiceAreas: {
							...state.dsprDriverServiceAreas,
							[dsprServiceAreaFromResponse.id]: {
								...dsprServiceAreaFromResponse,
							},
						},
				  }
				: state;
			return appendAndUpdateEntitiesFromResponseWithArrayOverwrite(
				stateWithoutDSPRDriverServiceArea,
				responseEntities,
			);

		//case GET_ALL_DRIVERS_FOR_DSPR_SUCCESS:
		//const dsprFromResponse =
		//    responseEntities && responseEntities.DSPRs
		//        ? (Object.values(responseEntities.DSPRs)[0] as { id: number })
		//        : undefined;
		//const driverIdsForDspr = action.response.result;
		//const updatedAllDriversForDSPRResponse = responseEntities && responseEntities.DSPRs ? {
		//    ...responseEntities,
		//    DSPRs: {
		//        [dsprFromResponse.id]: {
		//            ...dsprFromResponse,
		//            drivers: driverIdsForDspr
		//        }
		//    }
		//} : responseEntities
		//return appendAndUpdateEntitiesFromResponseWithArrayOverwrite(
		//    state,
		//    updatedAllDriversForDSPRResponse
		//);
		case ZIP_CODE_MINIMUM_CHANGE_SUCCESS:
			const zipCodeId = Object.keys(responseEntities.dsprZipCodes)[0];
			let oldZipCodes = state.dsprZipCodes;
			oldZipCodes[zipCodeId] = {};
			state = { ...state, dsprZipCodes: oldZipCodes };
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case GET_DSPR_DRIVER_SUCCESS:
			const dsprDriverId = action.response.result;
			let oldDsprDrivers = state.dsprDrivers;
			oldDsprDrivers[dsprDriverId] = {};
			state = { ...state, dsprDrivers: oldDsprDrivers };
			return appendAndUpdateEntitiesFromResponseWithArrayOverwrite(state, responseEntities);
		case SET_CURRENT_USER_ID_SUCCESS:
			const idUserKey = Object.keys(responseEntities.users)[0];
			responseEntities.users[idUserKey].identificationDocument = action.response.result;
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case SET_CURRENT_USER_MEDICAL_RECOMMENDATION_SUCCESS:
			const recommendationUserKey = Object.keys(responseEntities.users)[0];
			responseEntities.users[recommendationUserKey].medicalRecommendation = action.response.result;
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case MODIFY_ORDER_SUCCESS:
			const modifiedState = merge({}, state);
			const oldOrderId = responseEntities.orders[action.response.result].modifiedOrder.id;
			const dsprId = responseEntities.orders[action.response.result].dspr;
			const transfereeDriverId = modifiedState.orders[oldOrderId].dsprDriver;
			const currentInProcessOrder = modifiedState.dsprDrivers[transfereeDriverId].currentInProcessOrder;
			if (oldOrderId === currentInProcessOrder) {
				delete modifiedState.dsprDrivers[transfereeDriverId].currentInProcessOrder;
			} else {
				const queuedOrders = modifiedState.dsprDrivers[transfereeDriverId].queuedOrders;
				const deletionIndex = modifiedState.dsprDrivers[transfereeDriverId].queuedOrders.indexOf(oldOrderId);
				queuedOrders.splice(deletionIndex, 1);
				queuedOrders.push(action.response.result);
			}
			modifiedState.orders[oldOrderId].orderStatus = 'modified';
			if (
				modifiedState.dsprOrderHistories[dsprId] &&
				modifiedState.dsprOrderHistories[dsprId].orders.indexOf(oldOrderId)
			) {
				const ordersHistoryForDspr = modifiedState.dsprOrderHistories[dsprId];
				ordersHistoryForDspr.orders.push(action.response.result);
			}
			return appendAndUpdateEntitiesFromResponse(modifiedState, responseEntities);
		case GET_CURRENT_ORDER_SCANS_FOR_ORDER_SUCCESS:
			if (responseEntities && Object.keys(responseEntities).length > 0) {
				const modifiedState = { ...state };
				const orderId = Object.keys(responseEntities.orders)[0];
				if (
					modifiedState.orders[orderId] &&
					modifiedState.orders[orderId].scannedProductOrderDetailAssociationsScans
				)
					modifiedState.orders[orderId].scannedProductOrderDetailAssociationsScans = action.response.result;
				return appendAndUpdateEntitiesFromResponse(modifiedState, responseEntities);
			}
			return state;
		case RESET_ORDER_SCANS_SUCCESS:
			if (responseEntities) {
				const modifiedState = { ...state };
				const orderId = action.response.result;
				const orderScanIdsToDelete = modifiedState.orders[orderId].scannedProductOrderDetailAssociationsScans;

				//delete orderScan objects in orderScans
				if (orderScanIdsToDelete) {
					orderScanIdsToDelete.forEach((scanId) => {
						delete modifiedState.orderScans[scanId];
					});
				}

				//replace scannedProductOrderDetailAssociationsScans for order with empty array
				modifiedState.orders[orderId].scannedProductOrderDetailAssociationsScans = [];
				return modifiedState;
			}
			return state;
		case GET_USERS_WITH_UNVERIFIED_DOCUMENTS_SUCCESS:
			return { ...state, unverifiedUsers: responseEntities.unverifiedUsers };
		case GET_USERS_BY_SEARCH_SUCCESS:
			return { ...state, searchUsers: responseEntities.searchUsers };
		case GET_PRODUCT_BY_SEARCH_SUCCESS:
			return { ...state, searchProducts: responseEntities.searchProducts };
		case SEARCH_DSPR_COUPONS_SUCCESS:
			return { ...state, coupons: responseEntities.coupons };
		case GET_ALL_APPLICABLE_TAXES_SUCCESS:
		case DELETE_APPLICABLE_TAX_SUCCESS:
			return { ...state, applicableTax: responseEntities.applicableTax };
		case UNCANCEL_ORDER_SUCCESS:
			if (Object.keys(responseEntities.orders).length > 0) {
				delete responseEntities.ordersWithConfirmationState;
				return appendAndUpdateEntitiesFromResponse(state, responseEntities);
			}
			return state;
		case ADD_PRODUCT_TO_BRAND_SUCCESS:
			const productId = Object.keys(responseEntities.dspProducts)[0];
			const brandId = Object.keys(responseEntities.brands)[0];
			const { verificationToken } = responseEntities.productsWithConfirmationState[productId];

			// If a verification token is sent, add confirmation state data to store (data needed for reassignment)
			if (verificationToken) {
				return { ...state, productsWithConfirmationState: responseEntities.productsWithConfirmationState };
			}

			// Handles case where verification token has not been sent
			//  -> either product has not previously been assigned, or the user has confirmed brand reassignment
			if (!verificationToken) {
				const modifiedState = merge({}, state);
				// Update brand.products array with new productId and make product active in dspProducts
				if (modifiedState.brands[brandId].products && modifiedState.brands[brandId].products.length > 0) {
					modifiedState.brands[brandId].products.push(Number(productId));
					modifiedState.brands[brandId].products.sort((a, b) => a - b);
					modifiedState.dspProducts[productId].isActive = true;
				} else {
					modifiedState.brands[brandId].products = [Number(productId)];
					modifiedState.dspProducts[productId].isActive = true;
				}
				// Remove from previous brand if product has been reassigned
				const productsWithConfirmationState = 'productsWithConfirmationState';
				if (
					modifiedState[productsWithConfirmationState] &&
					modifiedState[productsWithConfirmationState][productId]
				) {
					const { currentBrand: oldBrandId, product } = modifiedState[productsWithConfirmationState][
						productId
					];
					const oldBrand = modifiedState.brands[oldBrandId];
					if (oldBrand.products) {
						const oldProductIndex = oldBrand.products.indexOf(product);
						if (oldProductIndex !== -1) {
							modifiedState.brands[oldBrandId].products.splice(oldProductIndex, 1);
						}
					}
				}
				delete modifiedState[productsWithConfirmationState];
				return modifiedState;
			}
			return state;
		case REMOVE_PRODUCT_FROM_BRAND_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const { id: productId, brandId } = action.requestBody;
				const { brands } = modifiedState;

				// Remove productId from relevant brand.product array
				if (brands[brandId].products) {
					const indexToRemove = brands[brandId].products.indexOf(productId);
					if (indexToRemove !== -1) {
						brands[brandId].products.splice(indexToRemove, 1);
					}
				}
				return modifiedState;
			}
			return state;
		case GET_DSPR_ANALYTICS_FOR_BRAND_SUCCESS:
		case GET_DSP_ANALYTICS_FOR_BRAND_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const brandId = action.response.result;
				const brand = modifiedState.brands[brandId];

				if (responseEntities.brands[brandId].dsprAnalytics) {
					const dsprId = responseEntities.brands[brandId].dsprAnalytics;
					brand['dsprAnalytics'] = {
						...brand['dsprAnalytics'],
						[dsprId]: responseEntities.dsprBrandAnalytics[dsprId],
					};
					return modifiedState;
				} else if (responseEntities.brands[brandId].dspAnalytics) {
					const dspId = responseEntities.brands[brandId].dspAnalytics;
					brand['dspAnalytics'] = {
						...brand['dspAnalytics'],
						[dspId]: responseEntities.dspBrandAnalytics[dspId],
					};
					return modifiedState;
				}
			}
			return state;
		case REBUILD_BRAND_ANALYTICS_FOR_DSPR_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const brandId = action.response.result;
				const brand = modifiedState.brands[brandId];
				brand.dsprAnalytics = {};

				//Updating analytics for each dspr
				const dsprIds = responseEntities.brands[brandId].dsprAnalyticsRebuild;
				dsprIds.forEach((dsprId) => {
					brand.dsprAnalytics[dsprId] = responseEntities.dsprBrandAnalytics[dsprId];
				});

				return modifiedState;
			}
			return state;
		case GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const productId = Object.keys(responseEntities.dspProducts)[0];
				console.log('Response Entities', responseEntities);

				modifiedState.dsprProductInventoryTransactionHistories = {
					...modifiedState.dsprProductInventoryTransactionHistories,
					[productId]: responseEntities.dsprProductInventoryTransactionHistories,
				};

				delete responseEntities.dsprProductInventoryTransactionHistories;
				return appendAndUpdateEntitiesFromResponse(modifiedState, responseEntities);
			}
			return state;
		case GET_METRC_TAGS_FOR_DSPR_FROM_GRASSP_DB_SUCCESS:
		case GET_METRC_TAGS_FOR_DSPR_FROM_METRC_SUCCESS:
			if (
				responseEntities &&
				responseEntities.dsprMetrcTags &&
				action.response.result &&
				action.response.result[0]
			) {
				const modifiedState = merge({}, state);

				//The response entities should only be returning tags belonging to one DSPR
				//Therefore, we take the dsprId from the first object, and use that to create a property on dsprMetrcTags, with the metrcTag objects serving as the value
				const firstTagId = action.response.result[0];
				if (!responseEntities.dsprMetrcTags || !firstTagId) {
					return modifiedState;
				}
				const dsprId = responseEntities.dsprMetrcTags[firstTagId].dsprId;
				modifiedState.dsprMetrcTags[dsprId] = responseEntities.dsprMetrcTags;
				return modifiedState;
			}
			return state;
		case GET_BATCH_NUMBER_PRODUCT_ASSOCIATIONS_SUCCESS:
			if (
				responseEntities &&
				responseEntities.dsprBatchNumbers &&
				action.response.result &&
				action.response.result[0]
			) {
				const modifiedState = merge({}, state);

				//The response entities should only be returning tags belonging to one DSPR
				//Therefore, we take the dsprId from the first object, and use that to create a property on dsprMetrcTags, with the metrcTag objects serving as the value
				const firstTagId = action.response.result[0];
				if (!responseEntities.dsprBatchNumbers || !firstTagId) {
					return modifiedState;
				}
				const dsprId = responseEntities.dsprBatchNumbers[firstTagId].dspr;
				modifiedState.dsprBatchNumbers[dsprId] = merge(
					{},
					modifiedState.dsprBatchNumbers[dsprId],
					responseEntities.dsprBatchNumbers,
				);
				return modifiedState;
			}
			return state;
		case GET_METRC_TAGS_PRODUCT_ASSOCIATIONS_SUCCESS:
		case REVISE_METRC_TAGS_PRODUCT_ASSOCIATION_SUCCESS:
			if (responseEntities && responseEntities.dsprMetrcTags) {
				const modifiedState = merge({}, state);
				const firstTagId = action.response.result[0];
				const dsprId = responseEntities.dsprMetrcTags[firstTagId].dsprId;
				const dsprMetrcTags = modifiedState.dsprMetrcTags[dsprId] ? modifiedState.dsprMetrcTags[dsprId] : {};
				const metrcTagIdsToUpdate = action.response.result;

				metrcTagIdsToUpdate.forEach((tagId) => {
					dsprMetrcTags[tagId] = responseEntities.dsprMetrcTags[tagId];
				});

				return modifiedState;
			}
			return state;
		case SET_DSPR_FULL_MENU_DRIVER_SUCCESS:
			if (responseEntities && action.requestBody) {
				//necessary to get the dsprId from the request body because the response object always supplies 0 as the id.
				const { id: dsprIdToUpdate, fullMenuDriver, isFullMenu } = action.requestBody;
				const modifiedState = merge({}, state);
				modifiedState.DSPRs[dsprIdToUpdate] = {
					...state.DSPRs[dsprIdToUpdate],
					fullMenuDriver: parseInt(fullMenuDriver.id),
					isFullMenuAvailable: isFullMenu,
				};
				return modifiedState;
			}
			return state;
		case UNSET_DSPR_FULL_MENU_DRIVER_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const dsprIdToUpdate = action.response.result;
				modifiedState.DSPRs[dsprIdToUpdate] = { ...responseEntities.DSPRs[dsprIdToUpdate] };
				return modifiedState;
			}
			return state;
		case GET_DSPR_COUPON_LOCK_TYPES_SUCCESS:
		case UPDATE_DSPR_COUPON_LOCK_TYPES_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const dsprIdToUpdate = action.response.result;

				modifiedState.dsprCouponLockTypes[dsprIdToUpdate] = responseEntities.dsprCouponLocks[
					dsprIdToUpdate
				].dsprCouponLocks.map((couponLock) => {
					const { discountType, couponLockType } = couponLock;
					return { discountType, lockType: couponLockType };
				});

				return modifiedState;
			}
			return state;
		case GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS:
			if (responseEntities) {
				const modifiedState = merge({}, state);
				const dsprProdInvTransId = action.response.result[0];
				if (dsprProdInvTransId) {
					const invoiceNumber =
						responseEntities.dsprProductInventoryTransactionHistories[dsprProdInvTransId].invoiceNumber;
					modifiedState.dsprProductInventoryTransactionHistories = {
						[invoiceNumber]: responseEntities.dsprProductInventoryTransactionHistories,
					};

					delete responseEntities.dsprProductInventoryTransactionHistories;
					return appendAndUpdateEntitiesFromResponse(modifiedState, responseEntities);
				} else {
					modifiedState.dsprProductInventoryTransactionHistories = {};
					return modifiedState;
				}
			}
			return state;
		default:
			return state;
	}
};
