import React, { Fragment, useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window';
import { SubmissionError } from "redux-form";

import { history } from '../index'
import { API_HOST } from '../middleware/api';
import ProductSearchForm from './ProductSearchForm';
import DSPProductForm from './DSPProductForm';
import DSPProductCategoryOrBrandForm from '../components/DSPProductCategoryOrBrandForm';
import DSPBrands from './DSPBrands';
import NavPills from "../components/NavPills/NavPills";
import {
    GET_PRODUCT_SUCCESS,
    searchProduct,
    createOrUpdateProduct,
    createCategory
} from '../actions/dspProductActions';
import { DeliveryServiceProvider, DspProductCategory, State } from "../store/reduxStoreState";
import { getDSPFromProps } from "../selectors/dspSelectors";
import { getDSP } from "../actions/dspActions";
import { getProductCategoriesForDSP, getSearchProducts } from "../selectors/dspProductSelector";

import '../components/DSPProductStyles.scss';
import {
    Card,
    CardContent,
    List,
    ListItem,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Input,
    InputAdornment,
} from "@material-ui/core";
import SnackBarAlertSuccess from "../components/SnackBarAlertSuccess";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import { Category, Business, List as ListIcon, Search as SearchIcon } from "@material-ui/icons";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import LocationOn from "@material-ui/icons/LocationOn";


const styles = {
    gridCon: {
        maxWidth: "100%",
        padding: 0
    },
    cardHeader: {
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between" as const
    },
    cardTitle: {
        color: "#3C4858",
        textDecoration: "none",
    },
    pageSubcategoriesTitle: {
      color: "#3C4858",
      textDecoration: "none",
    },
    cardCategory: {
      margin: "0",
      color: "#999999"
    },
    categorySearchForm: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 8,
    },
    searchButton: {
        marginLeft: 8,
    },
    searchInput: {
        width: 300,
    }
  };
  
  const useStyles = makeStyles(styles);

function DSPProductManagement() {
    const classes = useStyles();
    const {dspId} = useParams<{ dspId: string }>();

    const dsp = useSelector<State, DeliveryServiceProvider>(state => dspId && getDSPFromProps(state, {dspId}), shallowEqual);
    const categories = useSelector<State, DspProductCategory[]>(state => dspId && getProductCategoriesForDSP(state, {dspId}), shallowEqual);
    const products = useSelector<State, any>(getSearchProducts, shallowEqual);

    const [showProductSearchLoader, setShowProductSearchLoader] = useState(false)
    const [showCreateCategoryButton, setShowCreateCategoryButton] = useState(true);
    const [searchButtonText, setSearchButtonText] = useState('Hide Search Form');
    const [showSearchForm, setShowSearchForm] = useState(true);
    

    const [showNewProductForm, setShowNewProductForm] = useState(false);
    const [showNewProductCategoryForm, setShowNewProductCategoryForm] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<DspProductCategory>(undefined)

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [alertText, setAlertText] = useState<string>('');
    const [productName, setProductName] = useState('');
    const [categorySearchValue, setCategorySearchValue] = useState<string>('')
    

    const dispatch = useDispatch();

    //TODO: Do we need to dispatch a new action to get the DSP - or will dsp always be in the store from DeliveryServiceProvider? - Thinking about the case when people may simply type in the url bar
    useEffect(() => {
        if (!dsp || dsp.id !== parseInt(dspId)) {
            dispatch(getDSP(dspId))
        }
    }, [dispatch, dspId, dsp]);

    function handleButtonToggle(value: boolean) {
        setShowSearchForm(value);
        setSearchButtonText(value ? 'Hide Search Form' : 'Show Search Form')
    }

    function handleNewProductFormButtonClick() {
        setShowNewProductForm(true);
    }


    function handleNewProductCategoryFormButtonClick() {
        setShowNewProductCategoryForm(true);
    }

    function handleCategorySearchChange(event) {
        setCategorySearchValue(event.target.value)
    }

    function clearProductCategorySearch() {
        setCategorySearchValue('')
    }

    function handleNewDSPProductSubmit(formValues) {

        const imageFile = formValues.imageFile ? formValues.imageFile[0] : null;
        let categories = [];
        Object.keys(formValues).forEach(key => {
            if (key.startsWith("category")) categories.push(key.substr(8));
        });

        if(formValues.isCannabisProduct === false) {
            formValues.isFlower = null;
            formValues.thcPercentage = null;
            formValues.cbdPercentage = null;
            formValues.cbnPercentage = null;
            formValues.thcMg = null;
            formValues.cbdMg = null;
        }

        dispatch<any>(createOrUpdateProduct(parseInt(dspId),
            formValues.name,
            formValues.brandId,
            formValues.isActive === "yes",
            formValues.flowerType,
            formValues.productType,
            formValues.isFlower === "yes",
            formValues.isExcludedFromCoupons === "yes",
            categories,
            imageFile,
            formValues.description,
            formValues.thcPercentage,
            formValues.cbdPercentage,
            formValues.cbnPercentage,
            formValues.thcMg,
            formValues.cbdMg,
            formValues.isCannabisProduct !== undefined ? formValues.isCannabisProduct : null)).then(response => {
            if (response.error) {
                throw new SubmissionError({ name: response.error, _error: response.error });
            } else {
                return response
            }
        })
        .then(response => {
            if (response.type === GET_PRODUCT_SUCCESS) {
                setProductName(formValues.name);
                setShowSuccessAlert(true);
                setAlertText(`The Product "${productName}" has been successfully created!`)

                closeNewProductForm()
                return true;
            }
        })
    }

    


    function closeNewProductForm() {
        setShowNewProductForm(false);
    }

    
    function closeNewProductCategoryForm() {
        setSelectedCategory(undefined);
        setShowNewProductCategoryForm(false);
    }
    function hideOrShowSearch() {
        handleButtonToggle(!showSearchForm);
    }

    function handleProductSelection(productId) {
        history.push(`/product/${productId}`)
    }

    function handleCategorySelection(category) {
        setSelectedCategory(category)
        setShowNewProductCategoryForm(true);
    }

    function handleSubmitNewProductCategory(values) {
        const imageFile = values.imageFile ? values.imageFile[0] : null;
        const categoryObject = {
            name: values.name,
            id: selectedCategory ? selectedCategory.id : undefined
        }
        setShowCreateCategoryButton(false);
        dispatch<any>(createCategory(parseInt(dspId), categoryObject, imageFile))
            .then(() => {
                setShowCreateCategoryButton(true);
                closeNewProductCategoryForm();
                setSelectedCategory(undefined);
            });
    }

    function handleSearchSubmit(values) {
        setShowProductSearchLoader(true)

        const categories = [];
        Object.keys(values).forEach(key => {
            if (key.startsWith("category")) categories.push(key.substr(8))
        });

        const flowerTypes = [];
        Object.keys(values).forEach(key => {
            if (key.startsWith("flowerType")) flowerTypes.push(key.substr(10))
        });

        dispatch<any>(searchProduct(dspId, flowerTypes, categories, values.partialName))
            .then(() => setShowProductSearchLoader(false))

        handleButtonToggle(false);
    }

    // function toggleDSPProductFormOpen() {
    //     setShowNewProductForm(false)
    // }

    function categoryFromEntity(categoryEntity) {
        return <ListItem key={categoryEntity.id}>{categoryEntity.name}</ListItem>;
    }

    function generateGridObjects({columnIndex, rowIndex, data, style}) {
        const product = data[Object.keys(data)[(rowIndex * 2) + columnIndex]]
        return product ? (
            <div style={style} onClick={() => handleProductSelection(product.id)} className='product'>
                <img alt={`${product.name} :${product.description}`}
                     src={`${API_HOST}${product.imageLocation}`}/>
                <div className="grow"/>
                <div className='title-bar'>
                    <h4>{product.name}</h4>
                    <p>{`${product.productType}`}</p>
                </div>
            </div>
        ) : (<div style={style}></div>)
    }

    function generateGridCategories({columnIndex, rowIndex, data, style}) {
        const category = data[Object.keys(data)[(rowIndex * 2) + columnIndex]]
        return category ? (
            <div style={style} onClick={() => handleCategorySelection(category)} className='product'>
            <img alt={`${category.name}`}
                 src={`${API_HOST}${category.imageLocation}`}/>
            <div className="grow"/>
            <div className='title-bar'>
                <h4>{category.name}</h4>
            </div>
        </div>
    ) : (<div style={style}></div>)
    }

    return (
        <main className={'dsp-product-management'}>
            <h2>{dsp.name} Product Management</h2>

            <GridContainer className={classes.gridCon}>
                <GridItem className={classes.gridCon}>
                    <NavPills
                        color="rose"
                        tabs={[
                        {
                            tabButton: "Products",
                            tabIcon: ListIcon,
                            tabContent: (
                            <Card className="card" style={{minHeight: 1000}}>
                                <CardHeader className={classes.cardHeader}>
                                <h4 className={classes.cardTitle}>
                                    Search for a product, or create a new product
                                </h4>
                                <p className={classes.cardCategory}>
                                    <Button color='primary' variant="contained" onClick={() => handleNewProductFormButtonClick()}>Create A Product</Button>
                                </p>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <Fragment>
                                            <div>
                                                <Button className="raised-button"
                                                        onClick={() => hideOrShowSearch()}>{searchButtonText}</Button>
                                                {showSearchForm && <div>
                                                    <ProductSearchForm
                                                        categories={categories}
                                                        onSubmit={handleSearchSubmit}
                                                    />
                                                </div>}
                                            </div>
                                            {showProductSearchLoader ? <CircularProgress/> :
                                                <Autosizer>
                                                    {({height, width}) => (
                                                        <div>
                                                            {products &&
                                                            <FixedSizeGrid
                                                                itemData={products}
                                                                columnCount={2}
                                                                columnWidth={(width - 18) / 2}
                                                                height={600}
                                                                rowHeight={300}
                                                                rowCount={Math.ceil(Object.keys(products).length / 2)}
                                                                width={width}
                                                            >
                                                                {generateGridObjects}
                                                            </FixedSizeGrid>}
                                                        </div>

                                                    )}
                                                </Autosizer>
                                            }
                                        </Fragment>
                                    </div>
                                </CardBody>
                            </Card>
                            )
                        },
                        {
                            tabButton: "Categories",
                            tabIcon: Category,
                            tabContent: (
                            <Card className="card" style={{minHeight: 1000}}>
                                <CardHeader className={classes.cardHeader}>
                                <h4 className={classes.cardTitle}>
                                    DSP Categories
                                </h4>
                                <p className={classes.cardCategory}>
                                <Button color='primary' variant="contained" onClick={() => handleNewProductCategoryFormButtonClick()}>Create A Category</Button>
                                </p>
                                </CardHeader>
                                <CardBody>
                                    <Fragment>
                                        <span className={classes.categorySearchForm}>
                                            <Input
                                                id="search-Categories"
                                                className={classes.searchInput}
                                                value={categorySearchValue}
                                                onChange={handleCategorySearchChange}
                                                placeholder={"Search Categories"}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                }
                                            />
                                            <Button className={classes.searchButton} color='primary' variant="contained" onClick={() => clearProductCategorySearch()}>Clear Search</Button>
                                        </span>
                                        <Autosizer>
                                            {({height, width}) => (
                                                <div>
                                                    {categories &&
                                                    <FixedSizeGrid
                                                        itemData={categories.filter(category => category.name.toLowerCase().includes(categorySearchValue.toLowerCase()))}
                                                        columnCount={2}
                                                        columnWidth={(width - 18) / 2}
                                                        height={600}
                                                        rowHeight={300}
                                                        rowCount={Math.ceil(Object.keys(categories.filter(category => category.name.toLowerCase().includes(categorySearchValue.toLowerCase()))).length / 2)}
                                                        width={width}
                                                    >
                                                        {generateGridCategories}
                                                    </FixedSizeGrid>}
                                                </div>

                                            )}
                                        </Autosizer>
                                        {/* <List>
                                            {categories ? categories.filter(category => category.name.includes(categorySearchValue)).map(categoryEntity => categoryFromEntity(categoryEntity)) : null}
                                        </List> */}
                                    </Fragment>
                                </CardBody>
                            </Card>
                            )
                        },
                        {
                            tabButton: "Brands",
                            tabIcon: Business,
                            tabContent: (
                            <Card>
                                <CardBody>
                                    <Fragment>
                                        <DSPBrands dspName={dsp.name}/>
                                    </Fragment>
                                </CardBody>
                            </Card>
                            )
                        }
                        ]}
                    />
                </GridItem>
            </GridContainer>

            <Dialog
                open={showNewProductForm}
                onClose={closeNewProductForm}>
                <DialogTitle>Create A Product</DialogTitle>
                <DialogContent>
                    <DSPProductForm
                        onSubmit={handleNewDSPProductSubmit}
                        dspId={parseInt(dspId)}
                        onCancel={closeNewProductForm}
                        categories={categories}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showNewProductCategoryForm}
                onClose={closeNewProductCategoryForm}>
                <DialogTitle>Create A Product Category</DialogTitle>
                <DialogContent>
                <DSPProductCategoryOrBrandForm
                    onSubmit={handleSubmitNewProductCategory}
                    initialValues={{
                        name: selectedCategory ? selectedCategory.name : undefined
                    }}
                    fieldLabel='Category Name'
                    buttonText='Create Category'
                />
                </DialogContent>
            </Dialog>

            <SnackBarAlertSuccess
                showSnackbarAlert={showSuccessAlert}
                setIsVisible={setShowSuccessAlert}
                alertText={alertText}
            />
        </main>
    )
}


export default DSPProductManagement