import React from 'react';
import { Field, reduxForm, InjectedFormProps, reset } from 'redux-form';
import AutoComplete from '../components/Autocomplete';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import lightGreen from '@material-ui/core/colors/lightGreen';

import './AddDSPProductToBrandFormStyles.scss';

const lightGreen800 = lightGreen[800];

const validate = (values) => {
    const errors: any = {};

    if (!values.productId) {
        errors.productId = 'Required';
    }

    return errors;
};

//TODO Remove
const resetForm = (result, dispatch) => {
    dispatch(reset('AddDSPProductToBrandForm'));
}

const renderField = ({ input, label, type, autocomplete, productsForBrand, handleAutoCompleteSelected, meta: { touched, error, form } }) => (
    autocomplete ?
        <AutoComplete
            options={productsForBrand}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
            className='autocomplete'
        />
        :
        <TextField label={label} type={type} {...input} helperText={touched && error ? error : ''} error={!!(touched && error)} />
)

interface AddDSPProductToBrandFormProps {
    productsToAddToBrand: { value?: number, text?: string }[];
    handleAutoCompleteSelected: (id: any) => any;
}

const AddDSPProductToBrandForm = (props: AddDSPProductToBrandFormProps & InjectedFormProps<{}, AddDSPProductToBrandFormProps>) => {
    const { handleSubmit, productsToAddToBrand, handleAutoCompleteSelected } = props;

    return (
        <form onSubmit={handleSubmit} className='add-dsp-product-to-brand-form'>
            <Field className='field' name='productId' component={renderField} autocomplete={true} productsForBrand={productsToAddToBrand} label='Name of Product' handleAutoCompleteSelected={handleAutoCompleteSelected} />
            <Button onClick={handleSubmit} variant='contained' color='primary' startIcon={<Add htmlColor={lightGreen800} />}> Add Product </Button>
        </form>
    )
};

export default reduxForm<any, AddDSPProductToBrandFormProps>({
    form: 'AddDSPProductToBrandForm',
    validate,
    onSubmitSuccess: resetForm,
})(AddDSPProductToBrandForm);