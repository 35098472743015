import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {TextField, Checkbox, Button} from '@material-ui/core';
import { DspProductCategory } from '../store/reduxStoreState';

import './ProductSearchFormStyles.scss';

const validate = (values) => {
    const errors = {};
    if (!values.searchQuery) {
        errors['searchQuery'] = 'Must Enter a Search Query'
    }

    return errors;
};

const renderField = ({ input, label, type, defaultSelected, meta: { touched, error }, children, ...custom }) => {
    switch(type) {
        case 'checkbox':
            return <span><Checkbox label={label} {...input} onCheck={input.onChange}/></span>;
        default:
            return <div><TextField
                label={label} type={type} {...input} {...custom}
                error={!!(touched && error)}
                helperText={touched && error ? error : ''}
            /></div>;

    }
};

interface ProductSearchFormProps {
    categories?: DspProductCategory[]
}

const ProductSearchForm = (props: ProductSearchFormProps & InjectedFormProps<{}, ProductSearchFormProps>) => {
    const { handleSubmit,
        // categories
    } = props;
    const label = "Search Products" ;

    // const flowerTypes = ['sativa', 'indica', 'hybrid', 'cbd', 'sativa_hybrid', 'indica_hybrid', 'other'];

    return (
        <form onSubmit={handleSubmit} className='product-search-form'>
            <Field name="partialName" type="text" component={renderField} label={label}/>
            {/*<div>*/}
            {/*    <h2>Categories</h2>*/}
            {/*    {categories && categories.map(category =>*/}
            {/*        <Field name={`category${category.id}`} label={category.name} key={category.id} component={renderField} type="checkbox" value={category.id}/>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <h2>Flower Types</h2>*/}
            {/*    {flowerTypes.map(type =>*/}
            {/*        <Field name={`flowerType${type}`} label={type} key={type} component={renderField} type="checkbox" value={type}/>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div>
                <Button variant="contained" color="primary" className="raised-button" onClick={handleSubmit}>Search</Button>
            </div>
        </form>
    )
};

export default reduxForm<any, ProductSearchFormProps>({
    form: 'SearchForm',
    validate
})(ProductSearchForm)