import { Audit, State } from '../store/reduxStoreState';
import {createSelector} from "reselect";

export const getDSPRAuditsFromProps= (state, props): Audit[] => {
    return state && 
        state.api.entities.dsprAudits && 
            Object.values(state.api.entities.dsprAudits) && 
                Object.values<Audit>(state.api.entities.dsprAudits).filter((audit) => {return audit && audit.dspr && audit.dspr.id && audit.dspr.id === props.dsprId}) || null;
}

export const getDSPRAuditFromProps= (state, props): Audit => {
    return state && 
        state.api.entities.dsprAudits && 
            props.auditId && 
                state.api.entities.dsprAudits[props.auditId];
}