import React, { useState, useEffect } from 'react';
// import { DragDropContext, } from 'react-beautiful-dnd';

// import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Divider, Button, List, ListItem } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
// import { DateRange, DirectionsCar, Assignment, AttachMoney, Info, Edit, LocalOffer } from '@material-ui/icons';
import { DateRange, DirectionsCar, Assignment, AttachMoney, Info } from '@material-ui/icons';
import { DsprDriver, Order, DsprDriverLocation, Address, User, DSPR, DspProductCategory } from '../store/reduxStoreState';

import { parseDate } from '../util/util';
import { history } from '../index';

import StatCard from './StatCard';
import './dsprDashboardStatsStyles.scss';

interface DSPRDashboardStatsProps {
    drivers: (Omit<DsprDriver, 'user'> & { user: User, location: DsprDriverLocation })[];
    outstandingOrders: (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User })[];
    dspr: DSPR;
    productCategories: DspProductCategory[];
    getProductCategories: () => void;
}

const DSPRDashboardStats: React.FC<DSPRDashboardStatsProps> = props => {
    // const { drivers, outstandingOrders, dspr, productCategories, getProductCategories } = props;
    const { drivers, outstandingOrders, dspr } = props;
    const [loadedTime, setLoadedTime] = useState<Date>(undefined);
    // const [promoFormOpen, setPromoFormOpen] = useState(false);
    // const [categoryFormOpen, setCategoryFormOpen] = useState(false);

    // const openCategoryForm = () => {
    //     getProductCategories();
    //     setCategoryFormOpen(true);
    // }

    // const handleCategoryReorder = e => {
    //     debugger;
    // }

    useEffect(() => {
        if (drivers && outstandingOrders) setLoadedTime(new Date());
    }, [drivers, outstandingOrders]);

    const newestAnalytics = dspr && dspr.analytics && dspr.analytics.days &&
        dspr.analytics.days[0];
    const revenuesForToday = newestAnalytics ?
        loadedTime && (loadedTime.valueOf() - parseDate(newestAnalytics.beginDate).valueOf()) < (1000 * 60 * 60 * 24) ?
            newestAnalytics.revenuesTotal
            : 0
        : undefined;

    return <section className="dspr-dashboard-stats-container">
        <StatCard
            onClick={() => history.push(`/dspr/${dspr.id}/drivers`)}
            color="warning"
            mainIcon={<DirectionsCar />}
            mainText="On Call Drivers"
            mainValue={(dspr && drivers) ? drivers.length : <CircularProgress size="20" />}
            footerIcon={<DateRange />}
            footerText={loadedTime ? `Updated at ${loadedTime.toLocaleTimeString()}` : null} />
        <StatCard
            onClick={() => history.push(`/dspr/${dspr.id}/orders/`)}
            color='success'
            mainIcon={<Assignment />}
            mainText='Current Orders'
            mainValue={(dspr && outstandingOrders) ? outstandingOrders.length : <CircularProgress size="20" />}
            footerIcon={<DateRange />}
            footerText={loadedTime ? `Updated at ${loadedTime.toLocaleTimeString()}` : null} />
        <StatCard
            onClick={() => history.push(`/dspr/${dspr.id}/analytics/`)}
            color="info"
            mainIcon={<AttachMoney />}
            mainText="Daily Revenue"
            mainValue={revenuesForToday !== undefined ? `$${revenuesForToday}` : <CircularProgress size="20" />}
            footerIcon={<Info />}
            footerText="Excludes taxes" />
        {/* <StatCard
            // onClick={() => openPromoForm()}
            color="rose"
            mainIcon={<LocalOffer />}
            mainText="Promotions"
            mainValue="None"
            footerIcon={<Edit />}
            footerText={<span>Edit <button onClick={() => setPromoFormOpen(true)}>promos</button> and <button onClick={() => openCategoryForm()}>categories</button></span>} />

        <Dialog open={promoFormOpen} onClose={() => setPromoFormOpen(false)} className="dspr-promotion-form">
            <DialogTitle>DSPR Promotions</DialogTitle>
            <DialogContent>
                <h5>Current Promotions</h5>
                <Divider />
                <h5>New Promotion</h5>
                <TextField label="Enter the promotion content" multiline />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => setPromoFormOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={categoryFormOpen} onClose={() => setCategoryFormOpen(false)} className="dspr-category-form">
            <DialogTitle>Procuduct Categories</DialogTitle>
            <DialogContent>
                {productCategories && 
                    <List>
                        {productCategories.map(category =>
                            <ListItem>{category.name}</ListItem>
                        )}
                    </List>}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => setCategoryFormOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog> */}
    </section>
}

export default React.memo(DSPRDashboardStats);