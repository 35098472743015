import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MedicalRecommendation } from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import './OrderReportFormStyles.scss'

const validate = (values) => {
    const errors = {};
    const requiredFields = ['beginDayTimestamp', 'endDayTimestamp'];
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Required';
    });

    if (!parseDate(values['beginDayTimestamp'])) errors['beginDayTimestamp'] = "Invalid Date";
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error, value }, ...custom }) => {
    switch (type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id={input.name + "-picker"}
                    label={label}
                    value={input.value || null}
                    autoOk
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...custom}
                />
            </MuiPickersUtilsProvider>
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} {...custom}/>} />;
        default:
            return <TextField
                    label={label} placeholder={label} type={type} {...input} {...custom}
                    error={!!(touched && error)}
                    helperText={touched && error ? error : ''}
                />
    }
};
interface UserMedicalRecommendationVerificationFormProps extends Partial<InjectedFormProps> {

}

const UserMedicalRecommendationVerificationForm = (props: UserMedicalRecommendationVerificationFormProps) => {
    const { handleSubmit } = props;

    return <form onSubmit={handleSubmit} className={'custom-order-report-form'}>
            <div className={'fields-container'}>
                <Field name="beginDayTimestamp" type="date" component={renderField} label="Begin Date" className={'field'}/>
                <Field name="endDayTimestamp" type="date" component={renderField} label="End Date" className={'field'}/>
            </div>
            <h5>Order Statuses</h5>
            <p>No Selection Will Give All Statuses</p>
            <div className={'order-columns-container'}>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="queued" component={renderField} label="Queued" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="in-process" component={renderField} label="in-process" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="completed" component={renderField} label="Completed" type="checkbox"/>
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="canceled" component={renderField} label="Canceled" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="modified" component={renderField} label="Modified" type="checkbox" />
                </FormControl>
            </div>
            <h5>Columns</h5>
            <div className={'order-columns-container'}>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="order_id" component={renderField} label="Order Id" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="order_date" component={renderField} label="Order Creation Date" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="order_time" component={renderField} label="Order Creation Time" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="original_order_date" component={renderField} label="Original Order Creation Date" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="original_order_time" component={renderField} label="Original Order Creation Time" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="completion_date" component={renderField} label="Order Completion Date" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="completion_time" component={renderField} label="Order Completion Time" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="name" component={renderField} label="Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="first_name" component={renderField} label="First Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="last_name" component={renderField} label="Last Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="dhs_number" component={renderField} label="DHS Number" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="driver_name" component={renderField} label="Driver Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="delivery_minutes" component={renderField} label="Delivery Minutes" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="order_details" component={renderField} label="Order Details" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="coupon_codes" component={renderField} label="Coupon Codes" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="discount_amount" component={renderField} label="Discount Amount" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="taxes" component={renderField} label="Taxes" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="total" component={renderField} label="Total Cost" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="address" component={renderField} label="Address" type="checkbox" />
                </FormControl>
            </div>
            <br/>
            <div className='button-container'>
                <div className="verify-button-container">
                    <Button color="primary" variant="contained" onClick={handleSubmit}>Create CSV</Button>
                </div>
            </div>
        </form>
};

export default reduxForm<any, UserMedicalRecommendationVerificationFormProps>({
    form: 'CustomOrderReportForm',
    validate
})(UserMedicalRecommendationVerificationForm);