import { CALL_API, Schemas } from '../middleware/api';

const ENDPOINT = 'dspr/applicable_tax';

export const GET_DSPR_APPLICABLE_TAXES_ASSOCIATION = 'GET_DSPR_APPLICABLE_TAXES_ASSOCIATION';
export const GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS = 'GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS';
export const GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE = 'GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE';

const dsprApplicableTaxAssociationGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_APPLICABLE_TAXES_ASSOCIATION, GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS, GET_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.APPLICABLE_TAXES_WITH_DSPR_ASSOCIATION,
            queryParamsMap: { dspr_id: dsprId }
        }
    }
};

export const getDSPRApplicableTaxAssociationGetter = (values) => (dispatch, getState) => {
    return dispatch(dsprApplicableTaxAssociationGetter(values));
};

export const CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION = 'CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION';
export const CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS = 'CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS';
export const CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE = 'CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE';

const DSPRApplicableTaxAssociationsCreator = ({dsprId, applicableTaxIds},dsprSelect) => {
    let dsprApplicableTaxAssociation = {
        dspr: { id: dsprSelect },
    };
    dsprApplicableTaxAssociation['applicableTaxes'] = applicableTaxIds.map(id => { return { id }});
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION, CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS, CREATE_NEW_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.APPLICABLE_TAXES_WITH_DSPR_ASSOCIATION,
            body: dsprApplicableTaxAssociation
        }
    }
};

export const createDSPRApplicableTaxAssociations = (values,dsprSelect) => (dispatch, getState) => {
        return dispatch(DSPRApplicableTaxAssociationsCreator(values,dsprSelect))
            .then(response => {
                return response;
            });
    };

export const DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION = 'DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION';
export const DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS = 'DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS';
export const DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE = 'DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE';


const dsprApplicableTaxAssociationDeleter = ({dsprId, applicableTaxIds},dsprSelect) => {

    let dsprApplicableTaxAssociation = {
        dspr: { id: dsprSelect }
    };
    dsprApplicableTaxAssociation['applicableTaxes'] = applicableTaxIds.map(id => { return { id }});
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION, DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_SUCCESS, DELETE_DSPR_APPLICABLE_TAXES_ASSOCIATION_FAILURE],
            endPoint: ENDPOINT+'/remove',
            schema: Schemas.APPLICABLE_TAXES_WITH_DSPR_ASSOCIATION,
            body: dsprApplicableTaxAssociation
        }
    }
};

export const deleteDSPRApplicableTaxAssociationByDSPR_ID = (values,dsprSelect) => (dispatch, getState) => {
    return dispatch(dsprApplicableTaxAssociationDeleter(values,dsprSelect))
        .then(response => {
            return response;
        });
};
