import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    getDSPRCurrentInventory,
    getDSPRProductInventoryTransactionHistories
} from "../actions/dsprProductInventoryActions";
import {
    Card,
    CardHeader,
    CircularProgress,
    IconButton,
    Typography,
    CardContent,
    Button,
    DialogTitle, Dialog, DialogContent
} from "@material-ui/core";
import {
    State,
    DSPRProductInventoryTransactionHistory as DSPRProductTransactionHistory,
    TransactionType, DspProduct, DSPR, DsprCurrentInventoryItem
} from "../store/reduxStoreState";
import {
    getCurrentInventoryForSingleDSPRProductFromProps,
    getCurrentInventoryWithFullProductForDSPR,
    getDSPRProductInventoryTransactionHistoryWithProps
} from "../selectors/dsprCurrentInventorySelectors";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import VirtualizedTableDSPRInventoryTransactionHistory from "./VirtualizedTableDSPRInventoryTransactionHistory";
import { useParams } from "react-router-dom";
import { getDSPProductFromProps } from "../selectors/dspProductSelector";
import CardBody from "./Card/CardBody";
import { getProduct } from "../actions/dspProductActions";
import { parseDate } from "../util/util";
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import InventoryTransactionCorrectionFormForModal from "./InventoryTransactionCorrectionFormForModal";

type CurrentInventory = (Omit<DsprCurrentInventoryItem, 'product'> & { product: DspProduct })[];

export interface TransactionHistoryToDisplay extends DSPRProductTransactionHistory {
    transactionTitle?: string;
    productName?: string;
    possibleError?: boolean;
    unitCost?: number;
    currentAverage?: number;
    cumulativeCost?: number;
    cumulativeQuantity?: number;
    expandedRowContent?: any;
    quantityToDisplay?: number | '-';
    totalCostToDisplay?: string;
    unitCostToDisplay?: string;
    averageToDisplay?: string;
    cumulativeCostToDisplay?: string;
    cumulativeAverageToDisplay?: string;
}


const DSPRProductInventoryTransactionHistory = () => {
    const { dsprId, productId } = useParams<{ dsprId: string, productId: string }>();

    const [isLoading, setIsLoading] = useState(true);
    const [finalQuantity, setFinalQuantity] = useState(0);
    const [finalCost, setFinalCost] = useState(0);
    const [finalAverage, setFinalAverage] = useState(0);
    const [transactionHistoryToDisplay, setTransactionHistoryToDisplay] = useState<TransactionHistoryToDisplay[]>(null);
    const [transactionRowIndex, setTransactionRowIndex] = useState<number>(-1);
    const [showTransactionCorrectionForm, setShowTransactionCorrectionForm] = useState<boolean>(false);
    const [correctionType, setCorrectionType] = useState<'cost' | 'quantity'>(null);

    const productTransactionHistory = useSelector<State, { [key: number]: DSPRProductTransactionHistory }>(
        state => productId && getDSPRProductInventoryTransactionHistoryWithProps(state, { productId }
        ), shallowEqual);
    const product = useSelector<State, DspProduct>(
        state => productId && getDSPProductFromProps(state, { productId }), shallowEqual);
    const dspr = useSelector<State, DSPR>(state => dsprId && getDSPRFromProps(state, { dsprId }), shallowEqual);
    const currentInventoryForProduct = useSelector<State, DsprCurrentInventoryItem>(
        state => dsprId && productId &&
            getCurrentInventoryForSingleDSPRProductFromProps(state, {
                dsprId: parseInt(dsprId),
                productId: parseInt(productId)
            }), shallowEqual)

    const dispatch = useDispatch();

    /**Fetch transaciton history for a given product whenever the component is mounted*/
    useEffect(() => {

        dispatch<any>(getDSPRProductInventoryTransactionHistories(productId, dsprId))
            .then(() => setIsLoading(false))
    }, [dispatch, dsprId, productId]);

    /**Dispatch an action to get information for a product if it is not already in the store*/
    useEffect(() => {

        if (!product && productId) {
            setIsLoading(true);
            dispatch<any>(getProduct(productId))
                .then(() => setIsLoading(false));
        }
    }, [product, productId]);

    /**Dispatch an action to get the current inventory for a dspr if this information is not already in the store*/
    useEffect(() => {

        if (!currentInventoryForProduct) {
            setIsLoading(true);
            dispatch<any>(getDSPRCurrentInventory(dsprId))
                .then(() => setIsLoading(false));
        }
    }, [currentInventoryForProduct, dsprId]);

    const handleDialogOpen = () => {
        setShowTransactionCorrectionForm(true);
    }

    const handleDialogClose = () => {
        setShowTransactionCorrectionForm(false);
    }

    /**Return the appropriate transaction title given a particular transaction type, quantity, and cost*/
    const determineTransactionTitle = (transaction: TransactionHistoryToDisplay): string => {
        if (transaction.transactionType === 'stock') {
            return 'Purchase Order';
        }

        if (transaction.transactionType === 'stockAdjustment') {
            return 'Transaction Correction';
        }

        if (transaction.transactionType === 'driver' && transaction.quantity < 0) {
            return 'Transfer to Driver';
        }

        if (transaction.transactionType === 'driver' && transaction.quantity > 0) {
            return 'Return from Driver';
        }

        if (transaction.transactionType === 'foundLost') {
            return 'Found Lost';
        }

        if (transaction.transactionType === 'foundOrderMistake') {
            return 'Found Order Mistake';
        }

        if (transaction.transactionType === 'returnToVendor') {
            return 'Return to Vendor';
        }

        return transaction.transactionType.charAt(0).toUpperCase() + transaction.transactionType.slice(1);
    }

    /**Create transaction history objects with all properties needed for the Transaction History table
     * -> Additional properties: unitCost, currentAverage, possibleError
     *
     * Returns an array of Transaction History Objects
     * */
    const analyzeTransactionHistory = useCallback((): TransactionHistoryToDisplay[] => {
        let cumulativeQuantity = 0;
        let cumulativeCost = 0;
        let cumulativeAverage = 0;
        const history = [] as TransactionHistoryToDisplay[];

        for (let key in productTransactionHistory) {
            const transaction: TransactionHistoryToDisplay = { ...productTransactionHistory[key] };

            transaction.transactionTitle = determineTransactionTitle(transaction);

            transaction.unitCost = transaction.totalCost / transaction.quantity

            //If totalCost and Quantity are 0, there is most likely a problem with the data.
            // in this case, we do not want to set the possibleError property to true (this should only indicate an error in
            //  user-entered data).
            if (transaction.totalCost === 0 && transaction.quantity === 0) {
                transaction.currentAverage = cumulativeAverage;
                transaction.cumulativeCost = cumulativeCost
                transaction.cumulativeQuantity = cumulativeQuantity;
                history.push(formatTransactionForDisplay(transaction));
                continue;
            }

            //Update cumulativeCost and cumulativeQuantity, then calculate the current average for the transaction
            //if the current average is significantly different from the cumulative average calculated for the previous row,
            //set the possible error property for the transaction object as true. This property will then determine how to style the row
            cumulativeCost += transaction.totalCost;
            cumulativeQuantity += transaction.quantity;

            transaction.cumulativeCost = cumulativeCost
            transaction.cumulativeQuantity = cumulativeQuantity;
            transaction.currentAverage = cumulativeCost / cumulativeQuantity;

            //TODO: continue researching other statistical methods of finding outliers
            transaction.possibleError = history.length > 0 ? (Math.abs(transaction.unitCost - cumulativeAverage) > (.5 * cumulativeAverage)) : false;

            cumulativeAverage = transaction.currentAverage;

            //TODO: Update with actual expansion text
            //e.g. transaction.notes ? transaction.notes : null
            transaction.expandedRowContent = (
                <div>
                    <div>
                        <h6 style={{ display: 'inline' }}>Unit Cost:</h6>
                        <p style={{ display: 'inline' }}>{transaction.unitCost}</p>
                    </div>
                    <div>
                        <h6 style={{ display: 'inline' }}>Quantity:</h6>
                        <p style={{ display: 'inline' }}>{transaction.quantity}</p>
                    </div>
                    <div>
                        <h6 style={{ display: 'inline' }}>Cost:</h6>
                        <p style={{ display: 'inline' }}>{transaction.totalCost}</p>
                    </div>
                </div>
            )
            history.push(formatTransactionForDisplay(transaction));
        }

        setFinalQuantity(cumulativeQuantity);
        setFinalCost(cumulativeCost);
        setFinalAverage(cumulativeAverage);

        //return reversed array (most recent transaction will return first)
        return history.reverse();
    }, [productTransactionHistory]);

    /**Generate an array of transaction history objects for use in the transaction history table whenever
     * productTransactionHistory updates*/
    useEffect(() => {
        if (productTransactionHistory) {
            setTransactionHistoryToDisplay(analyzeTransactionHistory());
        }
    }, [productTransactionHistory])

    const handleSubmitTransactionCorrection = () => {
        return;
    }

    /**Formats a number into a string, rounded to the hundredths place*/
    const format = (num: number) => num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatTransactionForDisplay = (transaction: TransactionHistoryToDisplay) => {

        //    test if typeof unitCost is a number. If so, use toLocaleString to format it
        //    Transaction Correction has the transaction type of stockAdjustment. This is used for both quantity and cost
        //    if transactionType === stockAdjustment and totalCost === 0 -> put this in the quantity column quantity, otherwise put it in totalCost
        //          -> Remaining columns: Unit Cost, Average are '-'

        //    if totalCost && quantity === 0, render '-' for totalCost, quantity, and current average

        transaction.quantityToDisplay = '-';
        transaction.totalCostToDisplay = '-';
        transaction.unitCostToDisplay = '-';
        transaction.averageToDisplay = '-';

        //TODO add on hover text for row

        if (transaction.totalCost !== 0 && transaction.quantity !== 0) {
            transaction.quantityToDisplay = transaction.quantity;
            transaction.totalCostToDisplay = `$${format(transaction.totalCost)}`;
            transaction.unitCostToDisplay = `$${format(transaction.unitCost)}`;
            transaction.averageToDisplay = `$${format(transaction.currentAverage)}`;
        } else if (transaction.totalCost === 0 && transaction.transactionType === 'stockAdjustment') {
            //Transaction quantity correction
            transaction.quantityToDisplay = transaction.quantity;
            transaction.averageToDisplay = `$${format(transaction.currentAverage)}`;
        } else if (transaction.quantity === 0 && transaction.transactionType === 'stockAdjustment') {
            //Transaction cost correction
            transaction.totalCostToDisplay = `$${format(transaction.totalCost)}`;
            transaction.averageToDisplay = `$${format(transaction.currentAverage)}`;
        }

        transaction.cumulativeAverageToDisplay = `$${format(transaction.currentAverage)}`
        transaction.cumulativeCostToDisplay = `$${format(transaction.cumulativeCost)}`

        return transaction;
    }

    /**Renders a date cell for each transaction as mm-dd-yyyy
     *
     * on hover, a tooltip appears showing the date and time
     * */
    const dateCellRenderer = ({ cellData }) => {
        const date = parseDate(cellData);
        const dateToDisplay = date.toLocaleDateString('en-US');
        const timeAndDateToDisplay = date.toLocaleString('en-US');
        return <span title={timeAndDateToDisplay}>{dateToDisplay}</span>
    }

    const columnsForTable = [
        {
            dataKey: 'createdTimestamp',
            label: 'Date',
            width: 100,
            cellRenderer: dateCellRenderer
        },
        {
            dataKey: 'transactionTitle',
            label: 'Transaction',
            width: 150,
        },
        {
            dataKey: 'quantityToDisplay',
            label: 'Quantity',
            width: 100,
        },
        {
            dataKey: 'totalCostToDisplay',
            label: 'Total Cost',
            width: 100,
        },
        {
            dataKey: 'unitCostToDisplay',
            label: 'Unit Cost',
            width: 100,
        },
        {
            dataKey: 'cumulativeAverageToDisplay',
            label: 'Cumulative Average',
            width: 100,
        },
        {
            dataKey: 'cumulativeCostToDisplay',
            label: 'Cumulative Cost',
            width: 100,
        },
        {
            dataKey: 'cumulativeQuantity',
            label: 'Cumulative Quantity',
            width: 100,
        },
    ]

    const transactionHistoryOnRowClick = (event, index, rowData) => {

        if (transactionRowIndex === index) {
            setTransactionRowIndex(-1)
        } else if (transactionRowIndex !== index && rowData.notes) {
            setTransactionRowIndex(index);
        }
    }

    const header = product ? `${product.name} Transaction History` : 'Transaction History'
    const actualAverage = useMemo(() => (currentInventoryForProduct && currentInventoryForProduct.costOfInventory > 0 && currentInventoryForProduct.quantity > 0
        ? `$${format(currentInventoryForProduct.costOfInventory / currentInventoryForProduct.quantity)}`
        : 'N/A'), [currentInventoryForProduct]);

    const renderTransactionSummary = () => {
        if (currentInventoryForProduct) {
            return (
                <div className={'transaction-summary-container'}>
                    <Typography variant={'h6'} component={'h3'}>Summary</Typography>

                    <div className={'cost-container'}>
                        <div className={'calculated-cost-container'}>
                            <p><span>Calculated Cost:</span> {`$${format(finalCost)}`}</p>
                            <Button
                                size={'small'}
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    handleDialogOpen();
                                    setCorrectionType('cost');
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                        <p><span>Actual Cost:</span> {`$${format(currentInventoryForProduct.costOfInventory)}`}</p>
                    </div>

                    <div className={'quantity-container'}>
                        <div className={'calculated-quantity-container'}>
                            <p><span>Calculated Quantity:</span> {finalQuantity}</p>
                            <Button
                                size={'small'}
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    handleDialogOpen();
                                    setCorrectionType('quantity');
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                        <p><span>Actual Quantity:</span> {currentInventoryForProduct.quantity}</p>
                    </div>

                    <div className={'average-container'}>
                        <p><span>Calculated Average: </span> {`$${format(finalAverage)}`}</p>
                        <p><span>Actual Average: </span> {actualAverage}</p>
                    </div>
                </div>
            )
        }

        return <p>Transaction Summary Loading...</p>;
    }

    return (
        <main className={'inventory-transaction-history'}>
            <h2>{dspr ? `${dspr.name} - Inventory Transaction History` : `DSPR Inventory Transaction History`}</h2>
            {!transactionHistoryToDisplay || !currentInventoryForProduct
                ? <CircularProgress className={'circular-progress'} />
                : <Card className={'transaction-history-card-container'}>

                    <CardHeader title={header} titleTypographyProps={{ variant: 'h5', component: 'h2' }} />

                    <CardContent className={'transaction-history-card-body'}>

                        {renderTransactionSummary()}

                        {transactionHistoryToDisplay && transactionHistoryToDisplay.length > 0 &&
                            <div className='transaction-history-table-container'>
                                <Typography variant={'h6'} component={'h3'}>All Transactions (most recent
                                    first)</Typography>

                                {/*TODO: create responsive container to determine width of table*/}
                                <VirtualizedTableDSPRInventoryTransactionHistory
                                    transactionHistory={transactionHistoryToDisplay}
                                    columns={columnsForTable}
                                    customOnRowClick={transactionHistoryOnRowClick}
                                    parentSelectedIndex={transactionRowIndex}
                                    parentSetSelectedIndex={setTransactionRowIndex}
                                />
                            </div>
                        }
                    </CardContent>
                </Card>
            }

            <Dialog
                open={showTransactionCorrectionForm}
                onClose={() => {
                    handleDialogClose()
                    setCorrectionType(null);
                }}
                className={'transaction-correction-form'}
            >
                <DialogTitle>Transaction Correction</DialogTitle>
                <DialogContent>
                    <InventoryTransactionCorrectionFormForModal
                        correctionType={correctionType}
                        amountToCorrect={correctionType === 'cost' ? format(finalCost) : finalQuantity.toString()}
                        onClose={handleDialogClose}
                    />
                </DialogContent>
            </Dialog>
        </main >
    )
}

// export default DSPRProductInventoryTransactionHistory;

// const DSPRProductInventoryTransactionHistory = () => {
//     return;
// }

export default DSPRProductInventoryTransactionHistory;