import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";

import { DeliveryServiceProvider, DSPR, State, User } from "../store/reduxStoreState";
import { getLoggedInUser } from "../selectors/userSelectors";

import BrandAnalyticsContainer from "../containers/BrandAnalyticsContainer";
import DriverAnalyticsContainer from "../containers/DriverAnalyticsContainer";
import Navbar from '../components/Navbars/Navbar';
import Sidebar from "../components/Sidebar";
import { Assessment, DirectionsCar, Policy, Style } from "@material-ui/icons";
import DSPAndDSPRAnalyticsContainer from "../containers/DSPAndDSPRAnalyticsContainer";
import {
    getActiveDSPRManagersForLoggedInUser,
    getManagedDSPRsForLoggedInUser
} from '../selectors/dsprManagerSelectors';
import { getManagedDSPsForLoggedInUser } from "../selectors/dspManagerSelectors";
import SidebarUtil from "../util/SidebarUtil";
import { getDSPsWithStructureForHeaderMenuLinks, navLinks } from "../selectors/headerSelectors";
import { history } from "../index";
import AuditContainer from '../containers/AuditContainer';
import AuditViewContainer from '../containers/AuditViewContainer';


/** Renders Analytics Sidebar and establishes Analytics routes */
function AnalyticsManager() {
    const [navOpen, setNavOpen] = useState(false);
    const {dsprId, dspId, dsprDriverId} = useParams<{ dsprId?: string, dspId?: string, dsprDriverId?: string }>();

    const loggedInUser = useSelector<State, User>(getLoggedInUser, shallowEqual);
    const dsprManagers = useSelector<State, any>(getActiveDSPRManagersForLoggedInUser, shallowEqual);
    const navSelector = useSelector<State, navLinks>(getDSPsWithStructureForHeaderMenuLinks, shallowEqual);
    const userManagedDSPRs = useSelector<State, DSPR[]>(getManagedDSPRsForLoggedInUser, shallowEqual);
    const userManagedDSPs = useSelector<State, DeliveryServiceProvider[]>(getManagedDSPsForLoggedInUser, shallowEqual);

    const isAdmin = loggedInUser && loggedInUser.systemAdministrator;
    const isManagerForCurrentDSP = userManagedDSPs.filter(dsp => dsp.id === parseInt(dspId)).length === 1;
    const isDsprManager = !!(loggedInUser && loggedInUser.dsprManagers && loggedInUser.dsprManagers.length);
    const isManagerForCurrentDSPR = isDsprManager && dsprManagers && dsprManagers.filter(dsprManager => dsprManager.dspr === parseInt(dsprId)).length
    const isDriver = dspId ? false : !!(loggedInUser && loggedInUser.dsprDrivers.length)
    const userManagedActiveDSPs: DeliveryServiceProvider[] = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active) : [];
    const userManagedActiveDSPRs: DSPR[] = userManagedDSPRs ? userManagedDSPRs.filter(dspr => dspr.active) : [];


    //if user is not a super user, or a manager for the current dspr, or a manager for current dsp (depending upon route parameters)
    // the user is not authorized to view page and will be routed to the unauthorized page
    if (!isAdmin && ((dsprId && !isManagerForCurrentDSPR) && (dspId && !isManagerForCurrentDSP))) {
        history.push('/unauthorized');
    }

    useEffect(() => {
        if (!loggedInUser) history.push('/login');
    }, [loggedInUser]);

    const generateDriverRoutes = () => {
        if (navSelector) {
            return SidebarUtil.generateDriverRoutes(navSelector);
        }
    }

    const routes = [];

    // Routes for DSPR analytics
    if ((isAdmin || isManagerForCurrentDSPR) && dsprId) {
        routes.push(
            {
                path: `/${dsprId}/analytics/overview/`,
                name: 'Overview',
                icon: Assessment,
                component: DSPAndDSPRAnalyticsContainer,
                layout: '/dspr'

            },
            {
                path: `/${dsprId}/analytics/drivers/`,
                name: 'Driver Analytics',
                icon: DirectionsCar,
                component: DriverAnalyticsContainer,
                layout: '/dspr'
            },
            {
                path: `/${dsprId}/analytics/brands/`,
                name: 'Brand Analytics',
                icon: Style,
                component: BrandAnalyticsContainer,
                layout: '/dspr'
            },
            {
                path: `/${dsprId}/analytics/audits/`,
                name: "Audit Reports",
                icon: Policy,
                component: AuditContainer,
                layout: `/dspr`
            }
        )
    }

    // Routes for DSP Analytics
    if ((isAdmin || isManagerForCurrentDSP) && dspId) {
        routes.push(
            {
                path: `/${dspId}/analytics/overview/`,
                name: 'Overview',
                icon: Assessment,
                component: DSPAndDSPRAnalyticsContainer,
                layout: '/dsp'
            },
            {
                path: `/${dspId}/analytics/brands/`,
                name: 'Brand Analytics',
                icon: Style,
                component: BrandAnalyticsContainer,
                layout: '/dsp'
            }
        )
    }

    /**Driver Profiles Sidebar Entry (added if user is manager and driver) */
    if (!dsprDriverId && dsprId && isDriver && (isAdmin || isDsprManager)) {
        const driverRoutes = generateDriverRoutes().map(driverRoute => {
            delete driverRoute.icon;
            return ({
                ...driverRoute,
                mini: '-'
            })
        });

        routes.push(SidebarUtil.createDriverProfiles(driverRoutes));
    }

    /**Manager Profiles Sidebar entry*/
    if (loggedInUser && navSelector && ((userManagedActiveDSPs.length + userManagedActiveDSPRs.length) > 1)) {
        routes.push(SidebarUtil.createManagerProfiles((navSelector)));
    }

    function handleDrawerToggle() {
        setNavOpen(!navOpen);
    }

    function handleNavLinkClick() {
        setNavOpen(false);
    }

    return (
        <Fragment>
            <Navbar handleDrawerToggle={() => handleDrawerToggle()} brandText=''/>
            <Sidebar
                open={navOpen}
                logo='/assets/images/grasspLogo.svg'
                logoText={'Grassp Logistics'}
                routes={routes}
                handleDrawerToggle={handleNavLinkClick}
                color={'blue'}
                bgColor={'black'}
                isDriver={isDriver}
                isAnalytics={true}
            >
            </Sidebar>
            <div>
                {dsprId &&
                <Switch>
                    <Route path={`/dspr/:dsprId/analytics/overview/`} component={DSPAndDSPRAnalyticsContainer}/>
                    <Route path={`/dspr/:dsprId/analytics/drivers/`} component={DriverAnalyticsContainer}/>
                    <Route path={`/dspr/:dsprId/analytics/brands/`} component={BrandAnalyticsContainer}/>
                    <Route path={`/dspr/:dsprId/analytics/audits/`} component={AuditContainer}/>
                    <Route path={`/dspr/:dsprId/analytics/audit/:auditId`} component={AuditViewContainer}/>
                    <Redirect
                        to={(isAdmin || isManagerForCurrentDSPR) ? `/dspr/:dsprId/analytics/overview/` : `/dspr/:dsprId/`}
                    />
                </Switch>
                }
                {dspId &&
                <Switch>
                    <Route path={`/dsp/:dspId/analytics/overview/`} component={DSPAndDSPRAnalyticsContainer}/>
                    <Route path={`/dsp/:dspId/analytics/brands/`} component={BrandAnalyticsContainer}/>
                    <Redirect to={(isAdmin || isManagerForCurrentDSP) ? `/dsp/:dspId/analytics/overview/` : '/dsp/:dspId/'}/>
                </Switch>
                }
            </div>
        </Fragment>
    )
}

export default AnalyticsManager