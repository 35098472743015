import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { TextField, CircularProgress, Paper } from '@material-ui/core';
import { CommonFieldProps, touch } from 'redux-form';
import { useDispatch } from 'react-redux';

import "./AutocompleteStyles.scss"

export type AutoSelectItemAnyValue = { value: any, text: string };
export type AutoSelectAnyValueItems = AutoSelectItemAnyValue[];

export type AutoSelectItemNumberValue = { value: number, text: string };
export type AutoSelectNumberValueItems = AutoSelectItemNumberValue[];

export type AutoSelectItemStringValue = { value: string, text: string };
export type AutoSelectStringValueItems = AutoSelectItemStringValue[];

interface AutoCompleteProps {
    options: AutoSelectAnyValueItems;
    handleAutoCompleteSelected: (id: any) => any;
    input: CommonFieldProps;
    label: string
    touched: boolean;
    error: string;
    inputStyle?: React.CSSProperties;
    form?: string;
    dataLoaded?: boolean;
    className?: any;
    value?: any;
    sendCurrentSearchValue?: (value: string) => void;
    clearOnEscape?: boolean;
    clearOnBlur?: boolean;
    disabled?: boolean;
    inputValue?: any;
    key?: string | number;
    defaultValue?: AutoSelectItemNumberValue;
    multiple?: boolean;
}

const AutoComplete = (props: AutoCompleteProps) => {
    const {
        options,
        handleAutoCompleteSelected,
        input,
        label,
        touched,
        error,
        inputStyle,
        form,
        dataLoaded,
        className,
        value,
        sendCurrentSearchValue,
        clearOnEscape = true,
        clearOnBlur = true,
        disabled = false,
        inputValue,
        key,
        defaultValue,
        multiple = false,
    } = props;

    const dispatch = useDispatch();
    const finalLabel = dataLoaded === undefined ? label : dataLoaded ? label : "Loading..."
    const hideLoader = dataLoaded === undefined ? true : dataLoaded

    return <Autocomplete
        key={key ? key : ''}
        options={options}
        PopperComponent={({ children }) => (
            <Paper style={{ zIndex: 1500 }}>{children}</Paper>
          )}
        getOptionLabel={(option: { value: number, text: string }) => option ? option.text : ''}
        renderOption={(option: { value: number, text: string }, {inputValue}) => {
            const matches = match(option.text, inputValue);
            const parts = parse(option.text, matches);

            return <span>
                {parts.map((part, index) => {
                    return <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                        {part.text}
                    </span>
                })}
            </span>
        }}
        onChange={(_, val: any | { value: number, text: string }) => {
            if (sendCurrentSearchValue) {
                if (val) sendCurrentSearchValue(val.text)
                else sendCurrentSearchValue(null)
            }

            //when multiple is true, formValues are sent as [{value, text},...]
            if (multiple) {
                return val.length > 0 ? handleAutoCompleteSelected(val) : handleAutoCompleteSelected([]);
            }

            return val ? handleAutoCompleteSelected(val.value) : handleAutoCompleteSelected(null)
        }}
        onInputChange={(event, newInputValue) => {
            if (sendCurrentSearchValue) {
                sendCurrentSearchValue(newInputValue);
            }
        }}
        renderInput={params => <div className={"autocomplete-container " + className}>
            <TextField
                {...params}
                {...input}
                error={!!(touched && error)}
                helperText={touched && error ? error : ''}
                label={finalLabel}
                disabled={!hideLoader}
                onBlur={e => {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    if (input) dispatch(touch(form, input.name)); // to trigger redux-form's touch detection
                }}
                className="autocomplete-textfield"
                style={inputStyle}
            />{hideLoader ? null : <CircularProgress size="32px"/>}
        </div>}
        filterOptions={createFilterOptions({limit: 5})}
        value={value}
        getOptionSelected={(option, value) => option.value === value.value}
        clearOnBlur={clearOnBlur}
        clearOnEscape={clearOnEscape}
        disabled={disabled}
        inputValue={inputValue}
        defaultValue={defaultValue}
        multiple={multiple}
    />
}

export default AutoComplete;