import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    getBrand,
    getAllBrandsForDSP,
    createBrand,
    CREATE_BRAND_SUCCESS, CREATE_BRAND_FAILURE
} from '../actions/brandActions';
import { getAllBrandsForDSPFromProps } from '../selectors/brandSelectors';
import { getProductsForDSPBrands, getProductsForDSPForBrandAssignmentForAutoSelect, ProductsForBrands, ProductsForBrandsAssignment } from '../selectors/dspProductSelector';
import DSPBrandsTable from '../components/DSPBrandsTable';
import { State, Brand } from '../store/reduxStoreState';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DSPProductCategoryOrBrandForm from '../components/DSPProductCategoryOrBrandForm';


interface DSPBrandsProps {
    dspName: string;
}

/**DSPBrands Container. 
 * Retrieves and displays information for a DSP's assigned brands.
 * Also allows users to assign new brands to a DSP
 * 
 * Rendered by DeliverServiceProvider.tsx */
const DSPBrands: React.FC<DSPBrandsProps> = ({ dspName }) => {
    const { dspId } = useParams<{ dspId: any}>();
    const dispatch = useDispatch();
    
    const brands = useSelector<State, Brand[]>(state => getAllBrandsForDSPFromProps(state, { dspId }), shallowEqual);
    const products = useSelector<State, ProductsForBrands>(state => getProductsForDSPBrands(state, { dspId }), shallowEqual);
    const productsForBrandsAssignmentForm = useSelector<State, ProductsForBrandsAssignment>(state => getProductsForDSPForBrandAssignmentForAutoSelect(state, { dspId }, shallowEqual));

    const [isLoading, setIsLoading] = useState(true);
    const [showCreateBrandButton, setShowCreateBrandButton] = useState(true);
    const [productsForBrandsTable, setProductsForBrandsTable] = useState(productNameSort(products));
    const [showBrandCreationConfirmation, setShowBrandCreationConfirmation] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [newBrandName, setNewBrandName] = useState('');
    const [brandsForTable, setBrandsForTable] = useState(brandNameSort(brands));

    const [showBrandForm, setShowBrandForm] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState<Brand>(undefined)

    /**Sort an array of brand objects alphabetically */
    function brandNameSort(brands) {
        return brands.sort((a,b) => {
            return a.name.localeCompare(b.name,'en-US', {ignorePunctuation: true})
        })
    }

    /**Sort product names alphabetically*/
    function productNameSort(products) {
        for (let brandId in products) {
            products[brandId].sort((a,b) => {
                return a.name.localeCompare(b.name,'en-US', {ignorePunctuation: true})
            })
        }
        return products
    }

    /**Place brands in Redux Store on render and whenever dspId changes */
    useEffect(() => {
        setIsLoading(true);
        dispatch<any>(getAllBrandsForDSP(dspId))
            .then(() => setIsLoading(false));
    }, [dispatch, dspId]);

    /**Update brandsForTable whenever brands selector updates */
    useEffect(() => {
        setBrandsForTable(brands);
    }, [brands]);

    /**Update productsForBrandsTable whenever products selector updates */
    useEffect(() => {
        setProductsForBrandsTable(products);
    }, [products]);

    /**Upon clicking on a brand, the Redux store updates the selected brand object with its assigned products */
    function handleBrandClick(brandId: number) {
        dispatch<any>(getBrand(brandId));
    }

    /**Filters products sent to brands table */
    function filterProductsBySearchTerm(searchTerm: string, brandId: number) {
        if (searchTerm) {
            const filtered = products[brandId].filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()));

            // Previously filtered product arrays are maintained
            setProductsForBrandsTable(products => {
                return {...products, [brandId]: filtered }
            })
        } else {
            setProductsForBrandsTable(products);
        }
    }

    function handleSubmitBrand(formValues) {
        const imageFile = formValues.imageFile ? formValues.imageFile[0] : undefined;
        const brandId = selectedBrand? selectedBrand.id : undefined;
        closeBrandForm();
        dispatch<any>(createBrand(dspId, formValues.name, formValues.active, brandId, imageFile ))
            .then((response) => {
                if (response.type === CREATE_BRAND_SUCCESS) {
                    setNewBrandName(formValues.name);
                    setShowBrandCreationConfirmation(true);
                } else if (response.type === CREATE_BRAND_FAILURE) {
                    setErrorMessageText(`Cannot successfully create the brand "${formValues.name}". It may already exist`);
                    setShowErrorMessage(true);
                    setBrandsForTable(brands);
                }
                setShowCreateBrandButton(true);
            })  
    }

    function closeBrandForm() {
        setShowCreateBrandButton(true);
        setSelectedBrand(undefined);
        setShowBrandForm(false);
    }

    function openBrandForm(brand) {
        setSelectedBrand(brand);
        setShowBrandForm(true);
    }

    /**Creates new brand */
    function createNewActiveBrand(brand) {
        setShowCreateBrandButton(false);
        openBrandForm(brand);
    }

    return (
        <section className='DSPBrands'>
            {isLoading ? <CircularProgress /> :
                <React.Fragment>

                    {/* Alert for successful brand creation */}
                    {showBrandCreationConfirmation &&
                        <Alert onClose={() => setShowBrandCreationConfirmation(false)}>
                            The brand "{newBrandName}" has been successfully created!
                        </Alert>
                    }

                    {/* Alert for brand creation error */}
                    {showErrorMessage &&
                        <Alert severity='error' onClose={() => setShowErrorMessage(false)}>
                            {errorMessageText}
                        </Alert>
                    }

                    <DSPBrandsTable
                        brands={brandsForTable}
                        dspName={dspName}
                        handleBrandClick={handleBrandClick}
                        productsForBrands={productsForBrandsTable}
                        productsForBrandsAssignment={productsForBrandsAssignmentForm}
                        filterProductsBySearchTerm={filterProductsBySearchTerm}
                        createNewActiveBrand={createNewActiveBrand}
                        showCreateBrandButton={showCreateBrandButton}
                        handleModifyBrand={openBrandForm}
                    />
                </React.Fragment>
            }
            <Dialog
                open={showBrandForm}
                onClose={closeBrandForm}>
                <DialogTitle>{selectedBrand ? "Modify Brand" : "Create Brand"}</DialogTitle>
                <DialogContent>
                <DSPProductCategoryOrBrandForm
                    onSubmit={handleSubmitBrand}
                    showActive={true}
                    initialValues={{
                        name: selectedBrand ? selectedBrand.name : undefined,
                        active: selectedBrand ? selectedBrand.isActive : true
                    }}
                    fieldLabel='Brand Name'
                    buttonText={selectedBrand ? "Modify Brand" : "Create Brand"}
                />
                </DialogContent>
            </Dialog>
        </section>

        
    )
}

export default DSPBrands