/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import {
    LOCAL_STORAGE_ACCESS_TOKEN_KEY,
    LOCAL_STORAGE_ACCESS_TOKEN_TYPE,
    PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE,
    ACCESS_TOKEN_TYPES,
    setAccessToken
} from "./actions/oauthActions";
import { updateLoggedInUserInfo } from "./actions/userActions";
import configureStore from './store/configureStore'

import DSPRManager from './layouts/DSPRManager';
import SystemAdminManager from './layouts/SystemAdminManager';
import LoginContainer from './containers/LoginContainer';
import Admin from './layouts/Admin';
import "./assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import './assets/scss/styles.scss';
import { LoadScript } from "@react-google-maps/api";
import UnauthorizedAccessPage from './components/UnauthorizedAccessPage';

export const history = createBrowserHistory();

const App = () => {
    useLayoutEffect(() => {
        // Set custom CSS variable to address vh problems with address/menu bars on mobile
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }, []);

    return (
        <Provider store={store}>
            <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyAhweisTbmdd8Bs_tv3wWYiO-l67MoXWWc"
            >
                <Router history={history}>
                    <Switch>
                        <Route path="/login" component={LoginContainer} />
                        <Route path="/dspr/:dsprId/drivers/:dsprDriverId" component={DSPRManager} />
                        <Route path="/dspr/:dsprId" component={DSPRManager} />
                        <Route path='/test' component={Admin} />
                        <Route path={'/unauthorized'} component={UnauthorizedAccessPage} />
                        <Route path="/" component={SystemAdminManager} />
                        <Redirect to="/login" />
                    </Switch>
                </Router>
            </LoadScript>
        </Provider>
    )
}

const store = configureStore();
const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
const accessTokenType = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_TYPE);
if (accessToken) {
    store.dispatch(setAccessToken(PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE, accessToken, accessTokenType));
    // This will happen in the right order because setAccesToken doesn't do anything asynchronously
    if (accessTokenType === ACCESS_TOKEN_TYPES.user) store.dispatch(updateLoggedInUserInfo()).then((response) => ReactDOM.render(<App />, document.getElementById("root")));
    else ReactDOM.render(<App />, document.getElementById("root"));
} else {
    ReactDOM.render(<App />, document.getElementById("root"));
}
