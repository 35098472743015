import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Button, List, ListItem, Dialog, DialogContent, DialogTitle, CardActions, Checkbox, FormControlLabel } from '@material-ui/core';

import NewUserNoteForm from './NewUserNoteForm';

import './UserNotesStyles.scss'

interface UserNotesProps {
    createUserNote: (userId: number, note: any, dsprDriverId: number, dsprManagerId: number) => any;
    hideUserNote: (noteId:number) => any;
    unhideUserNote: (noteId: number) => any;
    userId: number;
    dsprDriverId?: number;
    userNotes: any[];
    refreshUser: () => any;
    dsprManagerId?: number;
    showTitle?: boolean;
}

const UserNotes = (props: UserNotesProps) => {
    const { userId, dsprDriverId, userNotes, refreshUser, dsprManagerId, createUserNote, hideUserNote, unhideUserNote, showTitle} = props;
    const [showNotes, setShowNotes] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [canEditNotes, setCanEditNotes] = useState(false)

    const handleNewNoteSubmit = (values) => {
        createUserNote(userId, values.note, dsprDriverId, dsprManagerId)
            .then(() => refreshUser());
        setShowNotes(false)
    };
    const handleCheckbox = (event) => {
        setShowHidden(event.target.checked)
    }

    useEffect(()=> {
        if(!!(dsprDriverId || dsprManagerId)){
            setCanEditNotes(true)
        } else {
            setCanEditNotes(false)
        }
    },[dsprDriverId, dsprManagerId])
    return <Card className={'user-notes-card'}>
        {showTitle===undefined ? <CardHeader title="Notes" /> :  showTitle ? <CardHeader title="Notes" /> : null}
        {canEditNotes && (
            <CardActions className='user-notes-actions-container'>
                <Button variant="contained" color="primary" onClick={() => setShowNotes(true)}>Create Note</Button>
                <FormControlLabel
                    control={
                        <Checkbox checked={showHidden} onChange={handleCheckbox} name="Show Hidden"></Checkbox>
                    }
                    label="Show Hidden"
                />
            </CardActions>
        )}
        <CardContent>
            <List>
                {userNotes && userNotes.filter(userNote => showHidden ? userNote : userNote.isVisible).map(userNote => <ListItem key={userNote.id}>{userNote.note} 
                        <span style={{display: "flex", flexGrow: 1}}/>
                        {canEditNotes ? userNote.isVisible ? <Button color='primary' 
                                variant='contained' 
                                title="HIDE" 
                onClick={()=> hideUserNote(userNote.id)}>HIDE</Button> : <Button color='primary'
                                variant='contained'
                                title="UNHIDE"
                                onClick={()=> unhideUserNote(userNote.id)}>UNHIDE</Button> : null}</ListItem>)}
            </List>
        </CardContent>
        <Dialog open={showNotes} onClose={() => setShowNotes(false)}>
            <DialogTitle>New Note</DialogTitle>
            <DialogContent>
                <NewUserNoteForm
                    closeDialog={() => setShowNotes(false)}
                    onSubmit={handleNewNoteSubmit}
                />
            </DialogContent>
        </Dialog>
    </Card>
}

export default UserNotes;