import React, { useState } from 'react'
import { DspProductCategory, DsprProductCategoryPromotion } from '../store/reduxStoreState'
import { List, ListItem, Card, CardContent, CardHeader, Dialog, ListItemIcon, ListItemText, IconButton } from '@material-ui/core'
import DSPRCreateProductCategoryPromotionForm from '../containers/DSPRCreateProductCategoryPromotionForm'
import { HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS } from '../actions/dsprActions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DragIndicator, RadioButtonUnchecked, RadioButtonChecked } from "@material-ui/icons"
import lightGreen from '@material-ui/core/colors/lightGreen';
const lightGreen800 = lightGreen[800];
interface DSPRMenuManagementProps {
    categories: Array<DspProductCategory & { currentPromotion: DsprProductCategoryPromotion}>;
    handleProductCategoryPromotionSubmission: (promotionalText: string, productCategoryId: number, file:any) => any;
    handleProductCategoryPromotionHide: (DsprProductCategoryPromotionId: number) => any;
    changeCategoryOrder: (startingIndex: number, endingIndex: number)=> any;   
}

const DSPRMenuManagement: React.FC<DSPRMenuManagementProps> = props => {
    const { categories, handleProductCategoryPromotionSubmission, handleProductCategoryPromotionHide, changeCategoryOrder} = props;
    const [currentlySelectedCategory, setCurrentlySelectedCategory] = useState<DspProductCategory & { currentPromotion: DsprProductCategoryPromotion}>(undefined);
    const [showCurrentlySelectedCategory, setShowCurrentlySelectedCategory] = useState(false);

    const handleCategoryClick = (category: DspProductCategory & { currentPromotion: DsprProductCategoryPromotion}) => {
        setCurrentlySelectedCategory(category)
        setShowCurrentlySelectedCategory(true)
    }

    const handleCloseCurrentlySelectedCategoryModal = () => {
        setShowCurrentlySelectedCategory(false);
        setCurrentlySelectedCategory(undefined);
    }

    const handleProductCategoryPromotionFormSubmission = (values) => {
        handleProductCategoryPromotionSubmission(values.promotionalText, currentlySelectedCategory.id, values.imageFile[0])
        handleCloseCurrentlySelectedCategoryModal();
    }
    
    const handleProductCategoryPromotionHideButtonPress = () => {
        handleProductCategoryPromotionHide(currentlySelectedCategory.currentPromotion.id).then(response=> {
            if (response.type === HIDE_DSPR_PRODUCT_CATEGORY_PROMOTION_SUCCESS) handleCloseCurrentlySelectedCategoryModal();
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        changeCategoryOrder(result.source.index, result.destination.index)
    }

    return (
        <main className="dspr-menu-management">
            <h2>DSPR Menu Management</h2>
            <Card>
                <CardHeader title="Categories"/>
                {/* {categories.length >= 15 && <CardActions className="actions-container">
                    <Button color="primary" variant="text" disabled={productCategoryOrderNotChanged}>cancel</Button>
                    <Button color="primary" variant="contained" disabled={productCategoryOrderNotChanged}>Save Order</Button>
                </CardActions>} */}
                <CardContent>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(provided, snapshot) => (
                                <List
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {categories && categories.sort((a,b)=> a.order - b.order).map((category, index) => {
                                        return (
                                        <Draggable key={"draggable-category-" + category.id} draggableId={"draggable-list-item-"+category.id} index={index}>
                                            {(provided, snapshot) => (
                                                <ListItem
                                                    button
                                                    onClick={() => handleCategoryClick(category)} 
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <ListItemIcon {...provided.dragHandleProps}>
                                                        <DragIndicator />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {(index+1)+'.'} {category.name}
                                                    </ListItemText> 
                                                    {category.currentPromotion ?
                                                        <IconButton edge="end" aria-label="active" style={{color: lightGreen800}}>
                                                            <RadioButtonChecked/>
                                                        </IconButton> : <IconButton edge="end" aria-label="inactive">
                                                            <RadioButtonUnchecked />
                                                        </IconButton>}
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    )})}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                </CardContent>
            </Card>
            <Dialog
                open={showCurrentlySelectedCategory}
                >
                <DSPRCreateProductCategoryPromotionForm 
                    onCancel={handleCloseCurrentlySelectedCategoryModal} 
                    onSubmit={handleProductCategoryPromotionFormSubmission}
                    handleHide={handleProductCategoryPromotionHideButtonPress}
                    initialValues={currentlySelectedCategory && currentlySelectedCategory.currentPromotion}/>
            </Dialog>
        </main>
    )
}

export default DSPRMenuManagement