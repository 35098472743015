import { grayColor } from "../../../jss/material-dashboard-pro-react";

const gridSystemStyle = {
  title: {
    color: grayColor[2],
    textDecoration: "none"
  }
};

export default gridSystemStyle;
