import { CALL_API, Schemas } from '../middleware/api';

const ENDPOINT = 'applicable_tax';

export const GET_ALL_APPLICABLE_TAXES = 'GET_ALL_APPLICABLE_TAXES';
export const GET_ALL_APPLICABLE_TAXES_SUCCESS = 'GET_ALL_APPLICABLE_TAXES_SUCCESS';
export const GET_ALL_APPLICABLE_TAXES_FAILURE = 'GET_ALL_APPLICABLE_TAXES_FAILURE';

const applicableTaxGetter = () => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_ALL_APPLICABLE_TAXES, GET_ALL_APPLICABLE_TAXES_SUCCESS, GET_ALL_APPLICABLE_TAXES_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.APPLICABLE_TAX_ARRAY,
        }
    }
};

export const getAllApplicableTaxesForAll = () => (dispatch, getState) => {
    return dispatch(applicableTaxGetter());
};

export const CREATE_NEW_APPLICABLE_TAX = 'CREATE_NEW_APPLICABLE_TAX';
export const CREATE_NEW_APPLICABLE_TAX_SUCCESS = 'CREATE_NEW_APPLICABLE_TAX_SUCCESS';
export const CREATE_NEW_APPLICABLE_TAX_FAILURE = 'CREATE_NEW_APPLICABLE_TAX_FAILURE';

const applicableTaxCreator = ({name, rate, appliedToRecUserOnly, appliedToMedUserOnly, appliedToNonStateIssuedCardOnly, appliedToStateIssuedCardOnly, appliedToAllOtherTaxes, id,dsprIds}) => {
    let applicableTax = {
        name, rate, appliedToRecUserOnly, appliedToMedUserOnly, appliedToNonStateIssuedCardOnly, appliedToStateIssuedCardOnly, appliedToAllOtherTaxes, id,dsprIds
    };
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_NEW_APPLICABLE_TAX, CREATE_NEW_APPLICABLE_TAX_SUCCESS, CREATE_NEW_APPLICABLE_TAX_FAILURE],
            endPoint: ENDPOINT,
            schema: Schemas.APPLICABLE_TAX_ARRAY,
            body: applicableTax
        }
    }
};

export const createApplicableTax = (values) => (dispatch, getState) => {
        return dispatch(applicableTaxCreator(values))
            .then(response => {
                return response;
            });
    };

export const DELETE_APPLICABLE_TAX = 'DELETE_APPLICABLE_TAX';
export const DELETE_APPLICABLE_TAX_SUCCESS = 'DELETE_APPLICABLE_TAX_SUCCESS';
export const DELETE_APPLICABLE_TAX_FAILURE = 'DELETE_APPLICABLE_TAX_FAILURE';


const applicableTaxDELETER = ({id}) => {
    const idToInt = parseInt(id);
    let applicableTax = {
            id :idToInt
    };
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [DELETE_APPLICABLE_TAX, DELETE_APPLICABLE_TAX_SUCCESS, DELETE_APPLICABLE_TAX_FAILURE],
            endPoint: ENDPOINT+'/remove',
            schema: Schemas.APPLICABLE_TAX_ARRAY,
            body: applicableTax
        }
    }
};

export const deleteApplicableTaxById = (values) => (dispatch, getState) => {
    return dispatch(applicableTaxDELETER(values))
        .then(response => {
            return response;
        });
};
