import React, { Fragment } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Switch, ListItem, DialogTitle, DialogContent } from '@material-ui/core';

import { toggleDSPRManagerActiveStatus, toggleDSPRManagerOrderNotificationStatus, toggleDSPRManagerDriverNotificationStatus } from '../actions/dsprActions'
import { getDSPRManagerWithUser } from '../selectors/dsprManagerSelectors';
import { getDSPRFromProps } from '../selectors/dsprSelectors';
import { State, User, DSPR, DsprManager } from '../store/reduxStoreState';

type Manager = Omit<DsprManager, 'user'> & { user: User };
interface DSPRManagerFormProps {
    dsprManagerId: number;
}

const DSPRManagerForm: React.FC<DSPRManagerFormProps> = React.memo(props => {
    const { dsprManagerId } = props;
    const dispatch = useDispatch();
    const manager = useSelector<State>(state => getDSPRManagerWithUser(state, { dsprManagerId }), shallowEqual) as Manager;
    const dspr = useSelector<State, DSPR>(state => manager && getDSPRFromProps(state, { dsprId: manager.dspr }), shallowEqual);
    const dsprNameTitle = dspr ? dspr.name + " - " : null;

    return manager ? <Fragment>
        <DialogTitle>{`${dsprNameTitle}${manager.user.firstName} ${manager.user.lastName}`}</DialogTitle>
        <DialogContent>
            {/*TODO: validate permissions*/}
            <ListItem>
                <span>Toggle Active Status</span>
                <Switch checked={manager.active} onChange={() => dispatch(toggleDSPRManagerActiveStatus(manager.id))} />
            </ListItem>
            <ListItem>
                <span>Toggle Receiving Notifications on Order Status Changes</span>
                <Switch checked={manager.shouldNotifyOnOrderStatusChanges} onChange={() => dispatch(toggleDSPRManagerOrderNotificationStatus(manager.id, !manager.shouldNotifyOnOrderStatusChanges))} />
            </ListItem>
            <ListItem>
                <span>Toggle Receiving Notifications on Driver Status changes</span>
                <Switch checked={manager.shouldNotifyOnDriverStatusChanges} onChange={() => dispatch(toggleDSPRManagerDriverNotificationStatus(manager.id, !manager.shouldNotifyOnDriverStatusChanges))}/>
            </ListItem>
        </DialogContent>
    </Fragment> : null;
})

export default DSPRManagerForm;