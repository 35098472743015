import React, { useMemo, useCallback } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector, shallowEqual } from 'react-redux';

import { State } from '../store/reduxStoreState';
import AutoComplete, { AutoSelectStringValueItems } from './Autocomplete';
import { ProductInfoForModifyProductMetrcTag } from './DSPRInventory';
import { getMetrcTagInMetrcTagModifyProductTagForm } from '../selectors/reduxFormSelectors';
import './DSPRModifyProductMetrcTagFormStyles.scss';

const renderField = ({
                         input,
                         label,
                         type,
                         autocomplete,
                         datasource,
                         handleAutoCompleteSelected,
                         multiline,
                         style,
                         meta: {touched, error, form},
                         disabled,
                     }) => (
    autocomplete ?
        <AutoComplete
            options={datasource}
            label={label}
            handleAutoCompleteSelected={handleAutoCompleteSelected}
            touched={touched}
            error={error}
            input={input}
            form={form}
            className={'field'}
            inputStyle={{minWidth: '240px'}}
            disabled={disabled}
        />
        :
        <TextField label={label}
                   type={type} {...input}
                   error={!!(touched && error)}
                   helperText={touched && error ? error : ''}
                   multiline={multiline > 0}
                   fullWidth={multiline > 0}
                   rows={multiline}
                   style={style}/>
);

interface DSPRModifyProductMetrcTagFormProps {
    unassignedMetrcTagsForAutoSelect: AutoSelectStringValueItems;
    handleMetrcTagAutoCompleteSelected: (tag: string) => any;
    handleUpdateMetrcTagsFromMetrc: () => void;
    isLoading: boolean;
    productInfo: ProductInfoForModifyProductMetrcTag;
    // for development:
    // productInfo: any;
    onCancel: () => void;
}

const DSPRModifyProductMetrcTagForm = ({
    unassignedMetrcTagsForAutoSelect,
    handleMetrcTagAutoCompleteSelected,
    handleUpdateMetrcTagsFromMetrc,
    isLoading,
    productInfo,
    onCancel,
    handleSubmit,
}: DSPRModifyProductMetrcTagFormProps & InjectedFormProps<{}, DSPRModifyProductMetrcTagFormProps>) => {

    const costOfInventory = `$${productInfo.costOfInventory ? productInfo.costOfInventory : 'Unavailable'}`;
    const metrcTagInRedux = useSelector<State, string>(getMetrcTagInMetrcTagModifyProductTagForm, shallowEqual);

    const metrcTagsForValidate = useMemo(() => {
        const metrcTagsMap = {};
        if (unassignedMetrcTagsForAutoSelect.length > 0) {
            unassignedMetrcTagsForAutoSelect.forEach(tag => {
                metrcTagsMap[tag.text] = tag.text;
            })
        }
        return metrcTagsMap;
    }, [unassignedMetrcTagsForAutoSelect]);

    const metrcValidate = useCallback((value) => {
        if (!(value in metrcTagsForValidate)) {
            handleMetrcTagAutoCompleteSelected(null)
        }
        return undefined
    }, [metrcTagsForValidate, handleMetrcTagAutoCompleteSelected]);

    return ( <>
        {productInfo &&
        <form>
            <Typography component='h1' variant='h5'>Modify a Product's Tag</Typography>

            <div className='product-info-container'>

                <div className='product-name-container'>
                    <div className='product-item'>
                        <Typography className='underline'>Product</Typography>
                        <Typography variant='body2' className='attribute-text'>{productInfo.product.name}</Typography>
                    </div>

                    <div className='product-item'>
                        <Typography className='underline'>Current Metrc Tag</Typography>
                        <Typography variant='body2' className='attribute-text'>{productInfo.metrcProductAssociation.metrcTag}</Typography>
                    </div>
                </div>

                <div className='product-attributes-container'>
                    <div className='attribute-item'>
                        <Typography className='underline'>Quantity</Typography>
                        <Typography variant='body2' className='attribute-text'>{productInfo.metrcProductAssociation.quantityOfEighths}</Typography>
                    </div>

                    <div className='attribute-item'>
                        <Typography className='underline'>Cost of Inventory</Typography>
                        <Typography variant='body2' className='attribute-text'>{costOfInventory}</Typography>
                    </div>
                </div>

            </div>

            <Typography className='actions-text'>Assign New Tag</Typography>

            <div className='actions-container'>

                <div className={'field'}>
                    <Field name={'metrcTag'}
                            component={renderField}
                            autocomplete={true}
                            datasource={unassignedMetrcTagsForAutoSelect}
                            label={'New Metrc Tag for Product'}
                            helperText={'Enter last 4 digits of tag'}
                            handleAutoCompleteSelected={handleMetrcTagAutoCompleteSelected}
                            validate={[metrcValidate]}
                            required={true}
                    />
                    <Typography variant={'body2'} color={'textSecondary'} component={'h5'}>
                        Enter last 4 digits of tag
                    </Typography>
                </div>

                <div className={'fetch-metrc-tag-button-container'}>
                        <Typography variant={'body2'} color={'textSecondary'}>Can't find tag?</Typography>
                        <Button variant={'outlined'} size={'small'} onClick={handleUpdateMetrcTagsFromMetrc}>{isLoading ? 'Fetching...' : 'Fetch Tags'}</Button>
                </div>

            </div>

            <div className='button-container'>
                <Button 
                    size={'small'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onCancel}
                >
                    Cancel
                </Button>

                <Button 
                    size={'small'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSubmit}
                    disabled={!metrcTagInRedux}
                >
                    Confirm
                </Button>

            </div>

        </form>}

        {!productInfo &&
        <Typography>Associated product cannot be found</Typography>}
        </>
    )
}

export default reduxForm<any, DSPRModifyProductMetrcTagFormProps>({
    form: 'DSPRModifyProductMetrcTagForm',
})(DSPRModifyProductMetrcTagForm);