import { State } from '../store/reduxStoreState';
import { createSelector } from "reselect";

export const getMetrcTagsForDSPRFromProps = (state: State, props) => {
    if (props.dsprId) {
        return state.api.entities.dsprMetrcTags[props.dsprId];
    }
    return null;
}

export const getMetrcTagsForDSPRForAutoSelectWithValueAsText = createSelector(
    [getMetrcTagsForDSPRFromProps], (metrcTagsForDSPR) => {
        return metrcTagsForDSPR ? Object.values(metrcTagsForDSPR).map((tag) => {
            return {value: tag.metrcTag, text: tag.metrcTag}
        }) : [];
    }
)

export const getMetrcReturnReasonsForAutoSelect = (state: State) => {
    const dsprMetrcReturnReasons = state.api.entities.dsprMetrcReturnReasons;
    return dsprMetrcReturnReasons ? Object.values(dsprMetrcReturnReasons).map((reason) => {
        return {value:reason.name, text:reason.name}
    }) : [];
}

export const getUnassignedMetrcTagsForDSPRForAutoSelectWithValueAsText = createSelector(
    [getMetrcTagsForDSPRFromProps], (metrcTagsForDSPR) => {
        return metrcTagsForDSPR ? Object.values(metrcTagsForDSPR)
            .filter(tag => !tag.productId && !tag.isActive)
            .map((tag) => {
                return {value: tag.metrcTag, text: tag.metrcTag}
            }) : [];
    }
)