import React, { useMemo, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader, Divider, Grid,
    Typography
} from "@material-ui/core";
import InvoiceVirtualizedTable from "./InvoiceVirtualizedTable";
import InvoiceHorizontalLine from "./InvoiceHorizontalLine";
import TransactionsDisplayUtil, { DictionaryTransactionHistory } from "../../util/TransactionsDisplayUtil";

interface InvoiceTransactionHistoryInterface {
    finalQuantity: number;
    finalCost: number;
    transactionHistoryToDisplayDictionary: DictionaryTransactionHistory;
    invoiceNumber: string;
}
const InvoiceTransactionHistoryPresentational: React.FC<InvoiceTransactionHistoryInterface> = (props) => {
    const
        {
            invoiceNumber,
            finalQuantity,
            finalCost,
            transactionHistoryToDisplayDictionary
        } = props;
    const {
        formatToHundrethsPlace,
        dateCellRenderer
    } = TransactionsDisplayUtil;
    const [transactionRowIndex, setTransactionRowIndex] = useState<number>(-1);

    const columnsForTable = [
        {
            dataKey: 'productName',
            label: 'Product Name',
            width: 350,
            minWidth: 100,
        },
        {
            dataKey: 'createdTimestamp',
            label: 'Date',
            width: 100,
            minWidth: 75,
            cellRenderer: dateCellRenderer
        },
        {
            dataKey: 'transactionType',
            label: 'Transaction',
            width: 150,
            minWidth: 75,
        },
        {
            dataKey: 'quantityToDisplay',
            label: 'Quantity',
            width: 100,
            minWidth: 50,
        },
        {
            dataKey: 'totalCostToDisplay',
            label: 'Total Cost',
            width: 100,
            minWidth: 75,
        },
        {
            dataKey: 'unitCostToDisplay',
            label: 'Unit Cost',
            width: 100,
            minWidth: 75,
        }

    ]

    // if transactionHistoryToDisplayDictionary is not empty, add an empty key at the beginning of the keys array to ensure that the first batch number is displayed
    const keys = useMemo(() =>
        transactionHistoryToDisplayDictionary && Object.keys(transactionHistoryToDisplayDictionary).length > 0 ? [''].concat(Object.keys(transactionHistoryToDisplayDictionary)) : []
        , [transactionHistoryToDisplayDictionary]);
    /**
     * Returns an array of Transaction History Objects With a Key Of batch numbers
     * If batch number does not exist will create a no Batch Number table,
     * if batch number is not for dspr then will default to one table
     * */
    const renderTransactionSummary = () => {
        if (transactionHistoryToDisplayDictionary && Object.keys(transactionHistoryToDisplayDictionary).length === 0) return; //our res from JAPI is empty -> return nothing
        if (transactionHistoryToDisplayDictionary) {
            return (
                <div className={'transaction-summary-container'}>
                    <Typography variant={'h5'} component={'h3'}><strong>SUMMARY</strong></Typography>
                    <Divider />
                    <Grid container direction={'column'}>
                        <Grid item style={{ marginTop: "0.4em" }}>
                            <div className={'cost-container'}>
                                <div className={'calculated-cost-container'}>
                                    <Typography><span>Calculated Total Cost:</span> {`$${formatToHundrethsPlace(finalCost)}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <div className={'quantity-container'}>*/}
                        {/*        <div className={'calculated-quantity-container'}>*/}
                        {/*            <Typography><span>Calculated Total Quantity:</span> {finalQuantity}</Typography>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>

                </div>
            )
        }


        return <p>Transaction Summary Loading...</p>;
    }

    const header = <div><strong>INVOICE:</strong> {invoiceNumber}</div>

    let noTransactionsPresent;
    if (transactionHistoryToDisplayDictionary && Object.keys(transactionHistoryToDisplayDictionary).length === 0) {
        noTransactionsPresent = <p>No transactions present for invoice with number: {invoiceNumber}.
            The invoice may not exist. </p>;
    }

    return <Card className={'transaction-history-card-container'}>

        <CardHeader title={header} />

        <CardContent className={'transaction-history-card-body'}>

            {transactionHistoryToDisplayDictionary && Object.keys(transactionHistoryToDisplayDictionary).length > 0 &&
                < div className='transaction-history-table-container'>
                    {keys.map((key, idx) =>
                        <li
                            key={key}
                            style={{ listStyle: "none" }}
                        >
                            {key !== '' &&
                                <InvoiceHorizontalLine
                                    idxOfComponent={idx}
                                    batchNumber={key}
                                />}
                            <InvoiceVirtualizedTable
                                transactionHistory={transactionHistoryToDisplayDictionary[key] || []}
                                columns={columnsForTable}
                                idxOfComponent={idx}

                                // customOnRowClick={transactionHistoryOnRowClick}
                                parentSelectedIndex={transactionRowIndex}
                            />
                        </li>
                    )}
                </div>
            }
            {noTransactionsPresent}
            {renderTransactionSummary()}
        </CardContent>
    </Card >

}

export default InvoiceTransactionHistoryPresentational;