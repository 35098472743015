import { createSelector } from 'reselect';
import { getDSPRs, getDSPRFromProps } from './dsprSelectors';
import { getLoggedInUser, getUsers } from './userSelectors'
import { State } from '../store/reduxStoreState';
import { getDrivers } from './dsprDriverSelector';

export const getDSPRManagers = (state: State) => state.api.entities.dsprManagers;
export const getDSPRManagerFromProps = (state: State, props) => state.api.entities.dsprManagers[props.dsprManagerId];

export const getDSPRManagerWithUser = createSelector(
    [getDSPRManagerFromProps, getUsers], (manager, users) => {
        return manager ?
            users ?
                { ...manager, user: users[manager.user] }
                : manager
            : undefined;
    }
);

export const getActiveDSPRManagersForLoggedInUser = createSelector(
    [getDSPRManagers, getLoggedInUser], (dsprManagers, loggedInUser) => {
        return loggedInUser && loggedInUser.dsprManagers ?
            dsprManagers ? loggedInUser.dsprManagers.filter(managerId => dsprManagers[managerId].active).map(managerId => dsprManagers[managerId])
                : undefined
            : undefined
    }
);

export const getManagedDSPRsForLoggedInUser = createSelector(
    [getActiveDSPRManagersForLoggedInUser, getDSPRs], (activeManagers, dsprs) => {
        if (!activeManagers) return undefined;
        return activeManagers.map(manager => dsprs[manager.dspr]);
    }
);

export const getManagersForDSPR = createSelector(
    [getDSPRFromProps, getDSPRManagers, getUsers], (dspr, dsprManagers, users) => {
        return dspr ?
            dspr.managers ?
                dsprManagers ?
                    dspr.managers.map(managerId => dsprManagers[managerId])
                        .map(manager => {
                            return { user: users[manager.user], active: manager.active, id: manager.id }
                        })
                    : []
                : []
            : undefined;
    }
);

export const getUserWithDSPRManagersAndDSPRDrivers = createSelector(
    [getLoggedInUser, getDSPRManagers, getDrivers], (loggedInUser, dsprManagers, dsprDrivers) => {
        return loggedInUser ?
            dsprManagers ?
                dsprDrivers ?
                {...loggedInUser, dsprManagers: loggedInUser.dsprManagers.map(dsprManagerId => dsprManagers[dsprManagerId]), dsprDrivers: loggedInUser.dsprDrivers.map(dsprDriverId => dsprDrivers[dsprDriverId])}
                : {...loggedInUser, dsprManagers: loggedInUser.dsprManagers.map(dsprManagerId => dsprManagers[dsprManagerId])} 
            : loggedInUser
        : null;
    }
)