import React, {Fragment, useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {
    UNCANCEL_ORDER_FAILURE, uncancelOrder
} from "../../actions/orderActions";
import {useDispatch} from "react-redux";
import {ButtonOrderStatusInterface} from "./OrderStatusButtonInterface";

export const UncancelOrderButton:React.FC<ButtonOrderStatusInterface> = props => {
    const {
        setDisableButtonCallBack,
        disableExtraButtons,
        closeModal,
        order,
        dspr,
        orderId,
        setExtraOrderFunctionalityErrorCallBack,
        isManagerForDSPR
    } = props;
    const [showBasedOnOrderStatus, setShowBasedOnOrderStatus] = useState(false);
    const dispatch = useDispatch();

    const uncancelCanceledOrder = (order) => {
        setDisableButtonCallBack(true)
        dispatch<any>(uncancelOrder(order.id, undefined)).then((response)=> {
            setDisableButtonCallBack(false);
            if(response.type === UNCANCEL_ORDER_FAILURE) {
                setExtraOrderFunctionalityErrorCallBack(response.error);
            } else {
                setExtraOrderFunctionalityErrorCallBack("");
                if(closeModal) closeModal();
            }
        })
    }

    useEffect(() => {
        if(order.orderStatus === "canceled") setShowBasedOnOrderStatus(true);
            }, []);

    return showBasedOnOrderStatus && <Fragment>
                <Button variant="contained" color="primary" disabled={disableExtraButtons} onClick={() => uncancelCanceledOrder(order)}>
                    Uncancel Order
                </Button>

        </Fragment>
}