import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { getAllUsers, updateLoggedInUserInfo } from "../actions/userActions";
import { assignDSPRManager, getDSPR } from "../actions/dsprActions";
import { interceptAutocompleteSelected } from "../actions/formHelperActions";

import { getManagedDSPIdsForLoggedInUser } from "../selectors/dspManagerSelectors";
import { getManagersForDSPR } from "../selectors/dsprManagerSelectors";
import { getUsersByName } from "../selectors/userSelectors";
import { getDSPRFromProps } from "../selectors/dsprSelectors";
import { DSPR, User, State } from '../store/reduxStoreState';

import DSPRManagers from "../components/DSPRManagers";

type DsprManager = { user: User, active: boolean, id: number };

const DSPRManagersContainer: React.FC<{}> = () => {
    const { dsprId } = useParams<{ dsprId: string }>();
    const dispatch = useDispatch();

    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, { dsprId }), shallowEqual);
    const dsprManagers = useSelector<State, DsprManager[]>(state => getManagersForDSPR(state, { dsprId }), shallowEqual);
    const loggedInUserManagedDSPs = useSelector<State, number[]>(getManagedDSPIdsForLoggedInUser, shallowEqual);
    const users = useSelector<State, { value: number, text: string }[]>(getUsersByName, shallowEqual);
    const [isLoading, setIsLoading] = useState(true);
    const [allUsersLoaded, setAllUsersLoaded] = useState(true);

    const getUsers = () => {
        setAllUsersLoaded(false);
        dispatch<any>(getAllUsers())
            .then(()=> setAllUsersLoaded(true));
    }

    useEffect(() => {
        dispatch<any>(getDSPR(dsprId))
            .then(() => setIsLoading(false));
    }, [dispatch, dsprId]);

    const isLoggedInUserThisDSPManager = () => !!(dspr && loggedInUserManagedDSPs.includes(dspr.deliveryServiceProvider));

    const handleSubmitManagerForm = (newManagerValues: { userId: string }) => {
        dispatch<any>(assignDSPRManager(dsprId, newManagerValues.userId))
            .then(() => dispatch(updateLoggedInUserInfo()));
    };

    return <main className="managers-tab">
        <h2>Managers</h2>
        {isLoading ? <CircularProgress /> :
            <DSPRManagers
                dsprManagers={dsprManagers}
                users={users}
                loggedInUserIsDSPManager={isLoggedInUserThisDSPManager()}
                handleSubmitManagerForm={handleSubmitManagerForm}
                interceptAutocompleteSelected={id => dispatch(interceptAutocompleteSelected(id))}
                getAllUsersCallback={getUsers}
                usersLoaded={allUsersLoaded}
            />}
    </main>
}

export default DSPRManagersContainer;
