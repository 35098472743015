import { CALL_API, Schemas } from '../middleware/api';
import { getDSPRDriver } from './driverActions';
import {DspProduct, MetrcTag, TransactionType} from "../store/reduxStoreState";

export const TRANSACTION_TYPES = {
    STOCK: 'stock',
    STOCK_ADJUSTMENT: 'stockAdjustment',
    LOST: 'lost',
    FOUND_LOST: 'foundLost',
    FOUND_ORDER_MISTAKE: 'foundOrderMistake',
    RETURN_TO_VENDOR: 'returnToVendor'
};


export const GET_DSPR_CURRENT_INVENTORY = 'GET_DSPR_CURRENT_INVENTORY';
export const GET_DSPR_CURRENT_INVENTORY_SUCCESS = 'GET_DSPR_CURRENT_INVENTORY_SUCCESS';
export const GET_DSPR_CURRENT_INVENTORY_FAILURE = 'GET_DSPR_CURRENT_INVENTORY_FAILURE';

const currentDSPRInventoryGetter = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_CURRENT_INVENTORY, GET_DSPR_CURRENT_INVENTORY_SUCCESS, GET_DSPR_CURRENT_INVENTORY_FAILURE],
            endPoint: `dspr/inventory`,
            schema: Schemas.DSPR_CURRENT_INVENTORY_ITEM_ARRAY,
            queryParamsMap: { dspr_id: dsprId }
        }
    }
};

export const getDSPRCurrentInventory = (dsprId) => (dispatch, getState) => {
    return dispatch(currentDSPRInventoryGetter(dsprId));
};

export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY';
export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS';
export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_FAILURE = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_FAILURE';

const dsprProductInventoryTransactionHistoriesGetter = (productId: string, dsprId: string) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY, GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_SUCCESS, GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_FAILURE],
            endPoint: `product/${productId}/transactions`,
            schema: Schemas.DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_ARRAY,
            queryParamsMap: { dspr_id: dsprId }
        }
    }
}

export const getDSPRProductInventoryTransactionHistories = (productId: string, dsprId: string) => (dispatch) => {
    return dispatch(dsprProductInventoryTransactionHistoriesGetter(productId, dsprId));
}

export const CREATE_NEW_INVENTORY_TRANSACTION = 'CREATE_NEW_INVENTORY_TRANSACTION';
export const CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS = 'CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS';
export const CREATE_NEW_INVENTORY_TRANSACTION_FAILURE = 'CREATE_NEW_INVENTORY_TRANSACTION_FAILURE';

export interface TransactionValues {
    quantity: string;
    cost: string;
    productId: number;
    notes?: string;
    metrcTag?: string;
    driverId?: number;
    batchNumber?: string;
    invoiceNumber?:string;
    metrcReturnReason?:string;
}

const inventoryTransactionCreator = (dsprId, transactionType, transactionValues: TransactionValues) => {
    const driver = transactionValues.driverId ? {
        id: transactionValues.driverId
    } : undefined;

    const dsprProductInventoryTransaction = {
        product: {
            id: transactionValues.productId
        },
        dspr: {
            id: dsprId
        },
        transactionType,
        quantity: transactionValues.quantity,
        totalCost: transactionValues.cost,
        notes: transactionValues.notes,
        metrcTag: transactionValues.metrcTag,
        batchNumber: transactionValues.batchNumber,
        metrcReturnReason: transactionValues.metrcReturnReason,
        invoiceNumber: transactionValues.invoiceNumber,
        driver
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CREATE_NEW_INVENTORY_TRANSACTION, CREATE_NEW_INVENTORY_TRANSACTION_SUCCESS,
                CREATE_NEW_INVENTORY_TRANSACTION_FAILURE],
            endPoint: 'dspr/inventory',
            schema: Schemas.DSPR_PRODUCT_INVENTORY_TRANSACTION,
            body: dsprProductInventoryTransaction
        }
    };
};

interface CreateInventoryTransactionProps {
    dsprId: string | number;
    transactionType: string;
    transactionValues: TransactionValues;
}

export const createInventoryTransaction = (props: CreateInventoryTransactionProps) => (dispatch, getState) => {
    const { dsprId, transactionType, transactionValues } = props;
    return dispatch(inventoryTransactionCreator(dsprId, transactionType, transactionValues))
        .then((response) => {
            if (transactionValues.driverId) {
                dispatch(getDSPRDriver(transactionValues.driverId))
            }
            else {
                dispatch(getDSPRCurrentInventory(dsprId))
            }
            return response;
        });
};

export const SET_DSPR_PRODUCT_PRICE = 'SET_DSPR_PRODUCT_PRICE';
export const SET_DSPR_PRODUCT_PRICE_SUCCESS = 'SET_DSPR_PRODUCT_PRICE_SUCCESS';
export const SET_DSPR_PRODUCT_PRICE_FAILURE = 'SET_DSPR_PRODUCT_PRICE_FAILURE';

const productPriceSetter = (dsprId, productId, priceObject) => {
    let dsprProductPriceHistory = {
        product: {
            id: productId
        },
        dspr: {
            id: dsprId
        }
    };

    dsprProductPriceHistory = { ...dsprProductPriceHistory, ...priceObject };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [SET_DSPR_PRODUCT_PRICE, SET_DSPR_PRODUCT_PRICE_SUCCESS,
                SET_DSPR_PRODUCT_PRICE_FAILURE],
            endPoint: 'dspr/product/price',
            schema: Schemas.DSP_PRODUCT,
            body: dsprProductPriceHistory
        }
    }
};

export const setProductPrice = (dsprId, productId, priceObject) => (dispatch, getState) => {
    return dispatch(productPriceSetter(dsprId, productId, priceObject));
};

export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE';
export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS';
export const GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_FAILURE = 'GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_FAILURE';
export interface productInventoryTransactionsByInvoiceInterface {
    dsprId:number;
    invoiceNumber:string;
}
const productInventoryTransactionsByInvoiceGetter = (props:productInventoryTransactionsByInvoiceInterface) => {
    const {
        dsprId,
        invoiceNumber
    } = props;

    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE, GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_SUCCESS, GET_DSPR_PRODUCT_INVENTORY_TRANSACTION_INVOICE_FAILURE],
            endPoint: `dspr/inventory/invoice/${invoiceNumber}`,
            schema: Schemas.DSPR_PRODUCT_INVENTORY_TRANSACTION_HISTORY_ARRAY,
            queryParamsMap: {
                dspr_id: dsprId
            }
        }
    }
};

export const getProductInventoryTransactionsByInvoice = (props) => (dispatch, getState) => {
    return dispatch(productInventoryTransactionsByInvoiceGetter(props));
};
