import React, { useEffect, useMemo, useState } from 'react';

import { ListItem, ListItemText } from '@material-ui/core';
import { Address, CalculatedOrderDetail, DSPR, Order, OrderDetail, OrderScan, State, User } from '../store/reduxStoreState';
import { getOrderScanCountForOrderFromProps, getOrderScansForOrderDetailFromProps } from "../selectors/scanSelectors";
import { shallowEqual, useSelector } from "react-redux";
import OrderWithDetailsAndPrices from "../containers/OrderWithDetailsAndPrices";
import { checkIfPreviousOrderStatusIsValid, orderStatus } from "../middleware/api";
type OrderWithDetails = (Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User });
interface OrderDetailListItemProps {
    calculatedOrderDetail: Partial<CalculatedOrderDetail>;
    orderDetail: Partial<OrderDetail>;
    orderScans: { [orderDetailId: number]: OrderScan[] };
    order: OrderWithDetails;
    dspr: DSPR;
}

const OrderDetailListItem: React.FC<OrderDetailListItemProps> = props => {
    const { calculatedOrderDetail, orderDetail, orderScans, order, dspr } = props;
    const { product, id, } = calculatedOrderDetail;
    const [orderDetailScanCount, setOrderDetailScanCount] = useState(0);
    const [showOrderDetailScanCount, setShowOrderDetailScanCount] = useState(false);

    const unit = calculatedOrderDetail && calculatedOrderDetail.unit ? calculatedOrderDetail.unit + ' oz of' : ' x ';
    //just do the other way of getting all order scans
    // const currentNumberOfScansForOrderDetail = useSelector<State, { orderId: number; id: number; }>(state => getOrderScansForOrderDetailFromProps(state, {orderId,id}), shallowEqual);

    useEffect(() => {
        if (orderScans && Object.keys(orderScans).length !== 0) {
            if (orderScans[calculatedOrderDetail.orderDetail.id]) {
                setOrderDetailScanCount(orderScans[calculatedOrderDetail.orderDetail.id].length);
            }
        }
        let previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr, order, orderStatus.packaged);
        if (previousOrderStatusIsValid && !dspr.allowDriverKits && dspr.isScanOrderDetail) setShowOrderDetailScanCount(true);

        previousOrderStatusIsValid = checkIfPreviousOrderStatusIsValid(dspr, order, orderStatus.completed);
        if (previousOrderStatusIsValid && dspr.allowDriverKits && dspr.isScanOrderDetail) setShowOrderDetailScanCount(true);
    }, [orderScans])

    const [scanQuantity, isCannabisProduct] = useMemo(() => {

        if (!orderDetail.product.isCannabisProduct) return [0, false];

        let quantity = 0;

        switch (orderDetail.unit) {
            case "eighth":
                quantity = orderDetail.quantity;
                break;
            case "quarter":
                quantity = orderDetail.quantity * 2;
                break;
            case "half":
                quantity = orderDetail.quantity * 4;
                break;
            case "oz":
                quantity = orderDetail.quantity * 8;
                break;
            default:
                quantity = orderDetail.quantity;
                break;
        }

        return [quantity, true];

    }, [orderDetail])


    return (
        <ListItem>
            {calculatedOrderDetail ? <ListItemText
                primary={`${calculatedOrderDetail.quantity} ${unit} ${product.name}`}
                secondary={<span><span className={product.flowerType}>{product.flowerType.replace("_", " ").toUpperCase()}</span><span>{(calculatedOrderDetail.appliedCoupon ? ` - ${calculatedOrderDetail.appliedCoupon.code.toUpperCase()}` : "")}</span></span>}
            /> : null}
            {orderScans && showOrderDetailScanCount ? <ListItemText style={{ color: isCannabisProduct ? (orderDetailScanCount === scanQuantity ? "green" : "red") : "gray" }} primary={isCannabisProduct ? `${orderDetailScanCount} / ${scanQuantity} scan${scanQuantity != 1 ? 's' : ''}` : "No Scan Necessary"} /> : null}
            <span className={"product-price " + (calculatedOrderDetail.discount !== 0 ? 'strikethrough' : "")}>${calculatedOrderDetail.pricePreDiscount.toFixed(2)}</span>
            {calculatedOrderDetail.discount !== 0 && <span className="product-price">${(calculatedOrderDetail.pricePreDiscount - calculatedOrderDetail.discount).toFixed(2)}</span>}
        </ListItem>
    )
}

export default OrderDetailListItem;