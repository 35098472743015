import React from "react";
// react components used to create a google map
import {
  // withScriptjs,
  // withGoogleMap,
  GoogleMap,
  Marker,
  LoadScript
} from "@react-google-maps/api";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Map from "@material-ui/icons/Map";
import AddLocation from "@material-ui/icons/AddLocation";
import Place from "@material-ui/icons/Place";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardIcon from "../../components/Card/CardIcon";
import CardHeader from "../../components/Card/CardHeader";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const SatelliteMap = () => <LoadScript
  googleMapsApiKey="AIzaSyAhweisTbmdd8Bs_tv3wWYiO-l67MoXWWc"
>
  <GoogleMap
    zoom={3}
    mapTypeId={"satellite"}
    mapContainerStyle={{
      height: '280px',
    }}
    center={{ lat: 40.748817, lng: -73.985428 }}
    defaultOptions={{
      scrollwheel: false
    }}
  >
    <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
  </GoogleMap>
</LoadScript>

const RegularMap = () => <LoadScript
  googleMapsApiKey="AIzaSyAhweisTbmdd8Bs_tv3wWYiO-l67MoXWWc"
>
  <GoogleMap
    zoom={8}
    center={{ lat: 40.748817, lng: -73.985428 }}
    mapContainerStyle={{
      height: '280px',
    }}
    defaultOptions={{
      scrollwheel: false
    }}
  >
    <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
  </GoogleMap>
</LoadScript>

const CustomSkinMap = () => <LoadScript
  googleMapsApiKey="AIzaSyAhweisTbmdd8Bs_tv3wWYiO-l67MoXWWc"
>
  <GoogleMap
    zoom={13}
    center={{ lat: 40.748817, lng: -73.985428 }}
    mapContainerStyle={{
      height: '280px',
    }}
    defaultOptions={{
      scrollwheel: false,
      disableDefaultUI: true,
      zoomControl: true,
      styles: [
        {
          featureType: "water",
          stylers: [
            { saturation: 43 },
            { lightness: -11 },
            { hue: "#0088ff" }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { hue: "#ff0000" },
            { saturation: -100 },
            { lightness: 99 }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }]
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }]
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }]
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }]
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }]
        }
      ]
    }}
  >
    <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
  </GoogleMap>
</LoadScript>

export default function GoogleMaps() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Map />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Satellite Map</h4>
          </CardHeader>
          <CardBody>
            <SatelliteMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: `280px`,
                    borderRadius: "6px",
                    overflow: "hidden"
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <AddLocation />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Regular Map</h4>
          </CardHeader>
          <CardBody>
            <RegularMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: `280px`,
                    borderRadius: "6px",
                    overflow: "hidden"
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Custom Skin & Settings Map
            </h4>
          </CardHeader>
          <CardBody>
            <CustomSkinMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: `280px`,
                    borderRadius: "6px",
                    overflow: "hidden"
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
