import React from "react";

import InvoiceTransactionHistoryPresentational from "./InvoiceTransactionHistoryPresentational";
import {
    useProductInventoryTransactionDisplay2D
} from "../../util/hooks/useProductInventoryTransactionDisplay2D";
import { desiredKeyForProductInventoryTransactions } from "../../util/TransactionsDisplayUtil";



interface InvoiceTransactionHistoryInterface {
    invoiceNumber: string;
}
const InvoiceTransactionHistoryContainer: React.FC<InvoiceTransactionHistoryInterface> = (props) => {

    const {
        invoiceNumber
    } = props;

    const [transactionHistoryToDisplayDictionary, finalValues] = useProductInventoryTransactionDisplay2D(desiredKeyForProductInventoryTransactions.invoiceBatchNumber);
    const { finalCost, finalQuantity } = finalValues;

    return <InvoiceTransactionHistoryPresentational
        finalQuantity={finalQuantity}
        finalCost={finalCost}
        transactionHistoryToDisplayDictionary={transactionHistoryToDisplayDictionary}
        invoiceNumber={invoiceNumber}
    />

}

export default InvoiceTransactionHistoryContainer;