import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Button, Dialog, Card, CardActions, CardContent, DialogTitle, DialogContent, Select, FormControl, InputLabel } from '@material-ui/core';

import { history } from '../index';
import DSPRDriverForm from '../containers/DSPRDriverForm';
import DriverPage from '../containers/DriverPage';
import { DsprDriver, User } from '../store/reduxStoreState';

import './dsprDriversStyles.scss';
import DSPRDriverOncallForm from '../containers/DSPRDriverOncallForm';

interface DSPRDriversProps {
    handleSubmitDriverForm: (newDriverValues: { userId: number, onCall?: boolean }) => void;
    drivers: (Partial<Omit<DsprDriver, 'user'> & { user: User }>)[];
    loggedInUserIsDSPRManager: boolean;
    users: { value: number, text: string }[];
    interceptAutocompleteSelected: (id: number) => any;
    dsprId: string;
    dsprDriverMenuItems: JSX.Element[];
    driverId?: number;
    getAllUsersCallback?: () => any;
    usersLoaded?: boolean;
    currentFullMenuDriverId: number;
}

const DSPRDrivers = (props: DSPRDriversProps) => {
    const { handleSubmitDriverForm, drivers, loggedInUserIsDSPRManager, users, interceptAutocompleteSelected, 
        dsprId, driverId, getAllUsersCallback, usersLoaded, dsprDriverMenuItems, currentFullMenuDriverId } = props;
    const location = useLocation();
    const splittedLocation = location.pathname.split('/');
    const driverIdFromUrl = splittedLocation && splittedLocation.length === 5 && splittedLocation[4];
    const [isDriverFormOpen, setIsDriverFormOpen] = useState(false);
    const [isDriverOncallFormOpen, setIsDriverOncallFormOpen] = useState(false)
    const [selectedDriver, setSelectedDriver] = useState(driverIdFromUrl);

    const handleAddDriverButtonClick = () => {
        getAllUsersCallback();
        setIsDriverFormOpen(true);
    }
    const handleDriverSelect = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        const selectedDriverId = event.target.value;
        history.push(`/dspr/${dsprId}/drivers/${selectedDriverId}`);
    }

    const handleSubmit = (newDriverValues: { userId: number, onCall?: boolean }) => {
        handleSubmitDriverForm(newDriverValues);
        setIsDriverFormOpen(false);
    };

    useEffect(() => {
        setSelectedDriver(driverIdFromUrl);
    }, [driverIdFromUrl]);

    return <Card>
        <CardContent className="drivers-container">
            {!driverId && <CardActions>
                {loggedInUserIsDSPRManager &&
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className="add-driver-button"
                            onClick={() => handleAddDriverButtonClick()}
                        >
                            Add Driver
                        </Button>
                        <Dialog
                            open={isDriverFormOpen}
                            onClose={() => setIsDriverFormOpen(false)}
                        >
                            <DialogTitle>Add Driver</DialogTitle>
                            <DialogContent>
                                <DSPRDriverForm
                                    users={users}
                                    onSubmit={handleSubmit}
                                    onCancel={() => setIsDriverFormOpen(false)}
                                    handleAutoCompleteSelected={interceptAutocompleteSelected}
                                    usersLoaded={usersLoaded}
                                />
                            </DialogContent>
                        </Dialog>

                        <Button
                            variant="contained"
                            color="primary"
                            className="add-driver-button"
                            onClick={() => setIsDriverOncallFormOpen(true)}
                        >
                            Toggle Drivers Oncall
                        </Button>
                        <Dialog
                            open={isDriverOncallFormOpen}
                            onClose={() => setIsDriverOncallFormOpen(false)}
                            className="drivers-oncall-dialog"
                        >
                            <DialogTitle>Toggle Drivers Oncall</DialogTitle>
                            <DialogContent>
                                <DSPRDriverOncallForm
                                    drivers={drivers}
                                    currentFullMenuDriverId={currentFullMenuDriverId}
                                />
                            </DialogContent>
                        </Dialog>

                    </div>}
                <FormControl className="driver-select-dropdown">
                    <InputLabel id="driver-list-select-label">Select a Driver</InputLabel>
                    <Select
                        labelId="driver-list-select-label"
                        value={selectedDriver}
                        onChange={handleDriverSelect}
                    >
                        {dsprDriverMenuItems}
                    </Select>
                </FormControl>


            </CardActions>}
            <div className={`drivers-content${driverId ? ' full' : ''}`}>
                <Switch>
                    <Route path={`/dspr/${dsprId}/drivers/:dsprDriverId`} render={props => <DriverPage {...props as any} key={driverIdFromUrl}/>} />
                </Switch>
            </div>
        </CardContent>
    </Card>
}

export default DSPRDrivers;