import React, { Fragment } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, Checkbox, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MedicalRecommendation, State } from '../store/reduxStoreState';
import { parseDate } from '../util/util';

import './OrderReportFormStyles.scss'
import { shallowEqual, useSelector } from 'react-redux';
import { getReportTypeInInventoryReportForm } from '../selectors/reduxFormSelectors';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['report_type'];
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Required';
    });

    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error, value }, children, ...custom }) => {
    switch (type) {
        case "date":
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id={input.name + "-picker"}
                    label={label}
                    value={input.value || null}
                    autoOk
                    onChange={input.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...custom}
                />
            </MuiPickersUtilsProvider>
        case 'checkbox':
            return <FormControlLabel label={label} control={<Checkbox {...input} onChange={input.onChange} {...custom}/>} />;
        case 'radioGroup':
            return <RadioGroup
                {...input}
                onChange={(_, value) => input.onChange(value)}
                children={children}
            />
        default:
            return <TextField
                    label={label} placeholder={label} type={type} {...input} {...custom}
                    error={!!(touched && error)}
                    helperText={touched && error ? error : ''}
                />
    }
};
interface InventoryReportFormProps extends Partial<InjectedFormProps> {
    canBreakIntoBatches:boolean
}

const InventoryReportForm = (props: InventoryReportFormProps) => {
    const { handleSubmit, canBreakIntoBatches } = props;

    const currentReportType = useSelector<State, string>(state => getReportTypeInInventoryReportForm(state), shallowEqual);

    return <form onSubmit={handleSubmit} className={'custom-order-report-form'}>
        <h5>Options</h5>
        <p>Some basic options to determine how the report will be ordered</p>
            { canBreakIntoBatches && <Field name="report_type" component={renderField} type="radioGroup"> {/* Not generic */}
                <FormControlLabel value="product_based" control={<Radio/>} label="By Product"/>
                <FormControlLabel value="batch_based" control={<Radio/>} label="By Batch Number"/>
            </Field>}
            
            {currentReportType === "product_based" && <FormControl component='fieldset' className={'state-issued-checkbox'}>
                <Field name="include_driver_inventories" component={renderField} label="Include Driver Inventories" type="checkbox" />
            </FormControl>}
            <h5>Columns</h5>
            <div className={'order-columns-container'}>
                {currentReportType === "batch_based" && <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="batch_number" component={renderField} label="Batch Number" checked type="checkbox" disabled/>
                </FormControl>}
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="product_id" component={renderField} label="Product Id" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="name" component={renderField} label="Product Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="brand" component={renderField} label="Brand Name" type="checkbox" />
                </FormControl>
                <FormControl component='fieldset' className={'state-issued-checkbox'}>
                    <Field name="quantity" component={renderField} label="Quantity" type="checkbox" />
                </FormControl>
                {currentReportType === "product_based" && <Fragment>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="quantity_lost" component={renderField} label="Quantity Lost" type="checkbox" />
                    </FormControl>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="quantity_returned" component={renderField} label="Quantity Returned" type="checkbox" />
                    </FormControl>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="inventory_value" component={renderField} label="Inventory Cost" type="checkbox" />
                    </FormControl>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="item_value" component={renderField} label="Unit Cost" type="checkbox" />
                    </FormControl>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="lost_inventory_value" component={renderField} label="Lost Inventory Value" type="checkbox" />
                    </FormControl>
                    <FormControl component='fieldset' className={'state-issued-checkbox'}>
                        <Field name="returned_inventory_value" component={renderField} label="Returned Inventory Value" type="checkbox" />
                    </FormControl>
                </Fragment> 
                }
            </div>
            <br/>
            <div className='button-container'>
                <div className="verify-button-container">
                    <Button color="primary" variant="contained" onClick={handleSubmit} disabled={currentReportType === null}>Create CSV</Button>
                </div>
            </div>
        </form>
};

export default reduxForm<any, InventoryReportFormProps>({
    form: 'CustomInventoryReportForm',
    validate
})(InventoryReportForm);