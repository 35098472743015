export const AUTOCOMPLETE_SELECTED = 'AUTOCOMPLETE_SELECTED';
export const DRIVER_AUTOCOMPLETE_SELECTED = 'DRIVER_AUTOCOMPLETE_SELECTED';
export const METRC_TAG_AUTOCOMPLETE_SELECTED = 'METRC_TAG_AUTOCOMPLETE_SELECTED';
export const BATCH_NUMBER_AUTOCOMPLETE_SELECTED = 'BATCH_NUMBER_AUTOCOMPLETE_SELECTED';
export const SHOW_ACTIVE_ONLY_UPDATED = 'SHOW_ACTIVE_ONLY_UPDATED';
export const METRC_TAG_FOR_MODIFY_PRODUCT_TAG_FORM_AUTOCOMPLETE_SELECTED = 'METRC_TAG_FOR_MODIFY_PRODUCT_TAG_FORM_AUTOCOMPLETE_SELECTED';
export const BATCH_NUMBER_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED = 'BATCH_NUMBER_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED'
export const METRC_TAG_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED = 'METRC_TAG_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED';
export const PRODUCT_FOR_PRODUCT_LOOKUP_FORM_AUTOCOMPLETE_SELECTED = 'PRODUCT_FOR_PRODUCT_LOOKUP_FORM_AUTOCOMPLETE_SELECTED';
export const PRODUCT_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED = 'PRODUCT_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED';
export const METRC_TAG_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED = 'METRC_TAG_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED';
export const BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED = 'BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED';
export const METRC_RETURN_REASON_FORM_AUTOCOMPLETE_SELECTED = 'METRC_RETURN_REASON_FORM_AUTOCOMPLETE_SELECTED';
export const BARCODE_TYPE_FOR_BARCODE_DOWNLOAD_FORM_AUTCOMPLETE_SELECTED = 'BARCODE_TYPE_FOR_BARCODE_DOWNLOAD_FORM_AUTCOMPLETE_SELECTED';
export const USER_ID_FOR_COUPON_FORM_AUTOCOMPLETE_SELECTED = 'USER_ID_FOR_COUPON_FORM_AUTOCOMPLETE_SELECTED';
export const DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED = 'DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED';
export const APPLICABLE_TAX_ASSOCIATION_FORM_AUTOCOMPLETE_SELECTED = 'APPLICABLE_TAX_ASSOCIATION_FORM_AUTOCOMPLETE_SELECTED';
export const ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED =  'ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED';

const autocompleteSelected = (id) => {
    return {
        type: AUTOCOMPLETE_SELECTED,
        id
    };
};

export const interceptAutocompleteSelected = (id) => (dispatch) => {
    return dispatch(autocompleteSelected(id));
};

const driverAutoCompleteSelected = (id) => {
    return {
        type: DRIVER_AUTOCOMPLETE_SELECTED,
        id
    };
};

export const interceptDriverAutoCompleteSelected = (id) => (dispatch, getstate) => {
    return dispatch(driverAutoCompleteSelected(id));
};

const autoCompleteForInternalForm = (itemIndex, id) => {
    return {
        type: AUTOCOMPLETE_SELECTED,
        itemIndex,
        id
    };
};

export const interceptAutoCompleteForInternalForm = (itemIndex, id) => (dispatch, getState) => {
    return dispatch(autoCompleteForInternalForm(itemIndex, id));
};

const metrcTagAutoCompleteSelected = (tag) => {
    return {
        type: METRC_TAG_AUTOCOMPLETE_SELECTED,
        tag
    };
}

export const interceptMetrcTagAutoCompleteSelected = (tag: string) => (dispatch) => {
    return dispatch(metrcTagAutoCompleteSelected(tag));
}

const batchNumberAutoCompleteSelected = (batch) => {
    return {
        type: BATCH_NUMBER_AUTOCOMPLETE_SELECTED,
        batch
    }
}

export const interceptBatchNumberAutoCompleteSelected = (batch: string) => (dispatch) => {
    return dispatch(batchNumberAutoCompleteSelected(batch))
}

const showActiveOnlyUpdater = (showActiveOnly) => {
    return {
        type: SHOW_ACTIVE_ONLY_UPDATED,
        showActiveOnly
    }
}

export const updateShowActiveOnlyValue = (showActiveOnly: boolean) => (dispatch) => {
    return dispatch(showActiveOnlyUpdater(showActiveOnly));
}

const metrcTagForModifyProductTagFormForAutoCompleteSelected = (tag) => {
    return {
        type: METRC_TAG_FOR_MODIFY_PRODUCT_TAG_FORM_AUTOCOMPLETE_SELECTED,
        tag
    };
}

export const interceptMetrcTagForModifyProductTagFormForAutoCompleteSelected = (tag: string) => (dispatch) => {
    return dispatch(metrcTagForModifyProductTagFormForAutoCompleteSelected(tag));
}

const metrcTagForProductLookupFormAutoCompleteSelected = (tag) => {
    return {
        type: METRC_TAG_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED,
        tag
    };
}

export const interceptMetrcTagForProductLookupFormAutoCompleteSelected = (tag: string) => (dispatch) => {
    return dispatch(metrcTagForProductLookupFormAutoCompleteSelected(tag));
}

const batchNumberForProductLookupFormAutoCompleteSelected = (batchNumber) => {
    return {
        type: BATCH_NUMBER_FOR_PRODUCT_LOOKUP_AUTOCOMPLETE_SELECTED,
        batchNumber
    };
}

export const interceptBatchNumberForProductLookupFormAutoCompleteSelected = (batchNumber: string) => (dispatch) => {
    return dispatch(batchNumberForProductLookupFormAutoCompleteSelected(batchNumber));
}

const productForProductLookupFormAutocompleteSelected = (id: number) => {
    return {
        type: PRODUCT_FOR_PRODUCT_LOOKUP_FORM_AUTOCOMPLETE_SELECTED,
        id
    }
}

export const interceptProductForProductLookupFormAutocompleteSelected = (id: number) => (dispatch) => {
    return dispatch(productForProductLookupFormAutocompleteSelected(id));
}

const productForMetrcTagOrBarcodeDownloadFormAutocompleteSelected = (id: number) => {
    return {
        type: PRODUCT_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
        id
    }
}

export const interceptProductForMetrcTagOrBarcodeDownloadFormAutocompleteSelected = (id: number) => (dispatch) => {
    return dispatch(productForMetrcTagOrBarcodeDownloadFormAutocompleteSelected(id));
}

const metrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected = (tag: string) => {
    return {
        type: METRC_TAG_FOR_METRC_TAG_OR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
        tag
    }
}

export const interceptMetrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected = (tag: string) => (dispatch) => {
    return dispatch(metrcTagForMetrcTagOrBarcodeDownloadFormAutocompleteSelected(tag));
}

const batchNumberForBarcodeDownloadFormAutocompleteSelected = (batchNumber: string) => {
    return {
        type: BATCH_NUMBER_FOR_BARCODE_DOWNLOAD_FORM_AUTOCOMPLETE_SELECTED,
        batchNumber
    }
}

export const interceptBatchNumberForBarcodeDownloadFormAutocompleteSelected = (name: string) => (dispatch) => {
    return dispatch(batchNumberForBarcodeDownloadFormAutocompleteSelected(name));
}

const metrcReturnReasonFormAutoCompleteSelected = (reason: string) => {
    return {
        type: METRC_RETURN_REASON_FORM_AUTOCOMPLETE_SELECTED,
        reason
    }
}

export const interceptMetrcReturnReasonAutocompleteSelected = (batchNumber: string) => (dispatch) => {
    return dispatch(metrcReturnReasonFormAutoCompleteSelected(batchNumber));
}
const qrTypeForBarcodeDownloadFormAutocompleteSelected = (value: string) => {
    return {
        type: BARCODE_TYPE_FOR_BARCODE_DOWNLOAD_FORM_AUTCOMPLETE_SELECTED,
        printLabelType: value
    }
}
export const interceptQRTypeForBarcodeDownloadFormAutocompleteSelected = (value: string) => (dispatch) => {
    return dispatch(qrTypeForBarcodeDownloadFormAutocompleteSelected(value))
}

const usersForCouponFormAutocompleteSelected = (userIds: number[]) => {
    return {
        type: USER_ID_FOR_COUPON_FORM_AUTOCOMPLETE_SELECTED,
        userIds
    }
}

export const interceptUserForCouponFormAutocompleteSelected = (users: {value: number, text: string}[]) => (dispatch) => {
    const userIds = users.map(user => user.value);
    return dispatch(usersForCouponFormAutocompleteSelected(userIds));
}

const dsprsForApplicableTaxFormAutocompleteSelected = (dsprIds: number[]) => {
    return {
        type: DSPR_ID_FOR_APPLICABLE_TAX_FORM_AUTOCOMPLETE_SELECTED,
        dsprIds
    }
}

export const interceptDSPRsForApplicableTaxFormAutocompleteSelected = (dsprs: {value: number, text: string}[]) => (dispatch) => {
    const dsprIds = dsprs.map(dspr => dspr.value);
    return dispatch(dsprsForApplicableTaxFormAutocompleteSelected(dsprIds));
}


const dsprsForApplicableTaxAssociationFormAutocompleteSelected = (applicableTaxIds: number[]) => {
    return {
        type: APPLICABLE_TAX_ASSOCIATION_FORM_AUTOCOMPLETE_SELECTED,
        applicableTaxIds
    }
}

export const interceptDSPRForApplicableTaxAssociationFormAutocompleteSelected = (applicableTaxes: {value: number, text: string}[]) => (dispatch) => {
    const applicableTaxIds = applicableTaxes.map(applicableTax => applicableTax.value);
    return dispatch(dsprsForApplicableTaxAssociationFormAutocompleteSelected(applicableTaxIds));
}

// export const interceptOrderDetailsForProductAutoCompleteFormSelected = (orderDetails: {value: number, text: string}[]) => (dispatch) => {
export const interceptOrderDetailsForProductAutoCompleteFormSelected = (id) => (dispatch) => {
    // const orderDetailIds = orderDetails.map(orderDetail => orderDetail.value);
    return dispatch(orderDetailsForProductAutoCompleteFormSelected(id));
    // return dispatch(orderDetailsForProductAutoCompleteFormSelected(orderDetailIds));
}

const orderDetailsForProductAutoCompleteFormSelected = (orderDetailId: number) => {
    return {
        type: ORDER_DETAILS_FOR_AUTOCOMPLETE_SELECTED,
        orderDetailId
    }
}

// export const interceptOrderDetailsForProductAutoCompleteFormSelected = (orderDetails: {value: number, text: string}[]) => (dispatch) => {
    export const interceptAutoCompleteForBrandId = (id) => (dispatch) => {
        // const orderDetailIds = orderDetails.map(orderDetail => orderDetail.value);
        return dispatch(brandIdForDSPProductFormSelected(id));
        // return dispatch(orderDetailsForProductAutoCompleteFormSelected(orderDetailIds));
    }
    
    const brandIdForDSPProductFormSelected = (brandId: number) => {
        return {
            type: AUTOCOMPLETE_SELECTED,
            brandId
        }
    }


// export const interceptorderDetailsForProductAutoCompleteFormSelected = (applicableTaxes: {value: number, text: string}[]) => (dispatch) => {
//     const applicableTaxIds = applicableTaxes.map(applicableTax => applicableTax.value);
//     return dispatch(dsprsForApplicableTaxAssociationFormAutocompleteSelected(applicableTaxIds));
// }
