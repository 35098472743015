import React, { useState } from 'react';
import { Button, TextField } from "@material-ui/core";
import { isNumeric } from "../util/util";

interface TransactionCorrectionFormProps {
    correctionType: 'cost' | 'quantity';
    amountToCorrect: string;
    handleSubmit?: any;
    onClose: () => any;
}

const InventoryTransactionCorrectionFormForModal = (props: TransactionCorrectionFormProps) => {
    const {correctionType, amountToCorrect, onClose, handleSubmit: submitTransactionCorrection} = props;

    const [correctionValue, setCorrectionValue] = useState<string>('');
    const [noteText, setNoteText] = useState<string>('')
    const [isError, setIsError] = useState<boolean>(false);

    const handleCorrectionValueChange = (evt) => {
        setCorrectionValue(evt.target.value);

        //error handling - test if entered stromg can be a number
        if (isNumeric(evt.target.value) === false) {
            setIsError(true)
        } else if (isNumeric(evt.target.value) === true && isError === true) {
            setIsError(false);
        }
    }

    const handleNoteChange = (evt) => {
        setNoteText(evt.target.value);
    }

    const handleSubmit = () => {
        //submitTransactionCorrection(correctionValue, noteText);
    }

    //TODO: clicking outside of dialog defaults to Quantity correctionType and quantity amountToCorrect - why?
    //look into how these values are set
    //TODO: Perhaps useLayoutEffect vs useEffect to fix this - https://daveceddia.com/useeffect-vs-uselayouteffect/
    // ( useLayoutEffect runs before component is visually updated)
    return (
        <main className={'transaction-correction-container'}>
            <h3>{correctionType === 'cost' ? 'Correct Total Cost' : 'Correct Quantity'}</h3>
            <form className={'transaction-correction-form'}>
                <TextField
                    label={'Correction Amount'}
                    value={correctionValue}
                    placeholder={amountToCorrect}
                    onChange={handleCorrectionValueChange}
                    helperText={amountToCorrect}
                    required
                    error={isError}
                />

                <TextField
                    label={'Notes'}
                    value={noteText}
                    onChange={handleNoteChange}
                />

                <div className={'button-container'}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </div>

            </form>
        </main>
    )
}

export default InventoryTransactionCorrectionFormForModal;