import React, { Fragment, useState, useEffect, useRef } from 'react';

import {
    Button,
    Dialog, DialogContent, DialogTitle, DialogActions,
    FormControl, InputLabel, Select, MenuItem,
    CircularProgress
} from '@material-ui/core';
import { PhoneIphone as OncallIcon } from '@material-ui/icons';

import {Order, Address, User, DsprDriver, DsprDriverInventoryItem, DspProduct, DSPR} from '../store/reduxStoreState';

import './ModifyOrderStyles.scss';

type OrderWithAddressAndUser = Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User };
type AvailableInventoryItem = Omit<DsprDriverInventoryItem, 'product'> & { product: DspProduct };

interface AssignDriverToOrderFormProps {
    dsprDrivers: (Omit<DsprDriver, 'user'> & { user: User })[];
    disableButton?: boolean;
    currentOrder?: OrderWithAddressAndUser;
    assignDriverToOrder: (orderId: number, dsprDriverIdSavedForPackaging: number) => Promise<string>;
    // modifyOrder: (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => Promise<string>;
    // availableInventory: AvailableInventoryItem[];
    // calculateOrderTotal: (order: any) => Promise<string | Order>;
    // getProductWithId: (productId: number) => Promise<string | DspProduct>;
    getDSPRDriver: (driverId: number) => Promise<any>;
    dspr:DSPR;
}

const AssignDriverToOrderForm: React.FC<AssignDriverToOrderFormProps> = props => {
    const {
        currentOrder,
        dsprDrivers,
        assignDriverToOrder,
        getDSPRDriver,
        dspr
    } = props;
    //const currentDriver = dsprDrivers.find(driver => driver.id === currentOrder.dsprDriver);
    const [currentDriver, setCurrentDriver] = useState(dsprDrivers.find(driver => driver.id === currentOrder.dsprDriver))
    const [currentDriverId, setCurrentDriverId] = useState((currentDriver && currentDriver.id) || currentOrder.dsprDriver)


    const mounted = useRef(true);

    const [showModifyOrderForm, setShowModifyOrderForm] = useState(false);
    const [getDSPRDriverResponse, setgetDSPRDriverResponse] = useState(undefined)
    const [selectedDriver, setSelectedDriver] = useState(currentDriver ? currentDriver.id.toString() : '');
    const [submitting, setSubmitting] = useState(false);
    const [modifyErrorMessage, setModifyErrorMessage] = useState(null);



    const handleDriverSelect = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        setSelectedDriver(event.target.value);
    }

    // const handleTransferInventoryCheckbox = (event) => {
    //     setTransferInventoryOnDriverChange(!transferInventoryOnDriverchange);
    // }

    const handleAssignDriverToOrder = () => {
        setSubmitting(true);
        assignDriverToOrder(currentOrder.id,  parseInt(selectedDriver))
            .then(errMsg => {
                if (mounted.current) {
                    setSubmitting(false);
                    if (errMsg) {
                        setModifyErrorMessage(errMsg);
                    } else {
                        setShowModifyOrderForm(false);
                    }
                }
            });
    }


    const handleFormClose = () => {
        setShowModifyOrderForm(false);
    }

    useEffect(() => () => mounted.current = false, []);

    useEffect(()=> {
        if (showModifyOrderForm && currentDriverId) {
            getDSPRDriver(currentDriverId).then((response)=> {
                if (response) setgetDSPRDriverResponse(response)
            })
        }
        // eslint-disable-next-line
    }, [showModifyOrderForm, currentDriverId])

    useEffect(()=> {
        if(currentOrder) {
            setCurrentDriver(dsprDrivers.find(
                driver => driver.id === currentOrder.dsprDriver
            ))
        }
    }, [currentOrder, dsprDrivers])

    useEffect(()=> {
        if(currentDriver) {
            if(currentDriver.id !== currentDriverId) {
                setCurrentDriverId(currentDriver.id)
                setSelectedDriver(currentDriver.id.toString())
            } 
            if(!selectedDriver) {
                setSelectedDriver(currentDriver.id.toString())
            }
        }
    }, [currentDriver, currentDriverId, selectedDriver])
    return (
        <Fragment>
            <Button variant="contained" color="primary"
                    // disabled={disableButton}
                    onClick={() => setShowModifyOrderForm(true)}>
                Assign Driver
            </Button>
            <Dialog
                open={showModifyOrderForm}
                onClose={() => handleFormClose()}
                className="modify-order-form"
            >
                <DialogTitle>Assign Driver</DialogTitle>
                <DialogContent>
                    {getDSPRDriverResponse === undefined ? <CircularProgress/> : getDSPRDriverResponse.type ?
                    <Fragment>
                        <p>If the driver needs to be changed, successful modification of this order
                            requires the new driver's inventory to be sufficient for the current order.</p>
                        {/*{currentOrder && <OrderSummary />}*/}
                        {dsprDrivers && <section>
                            {/*use this as an example for assigning a new driver to an order*/}
                            <FormControl className="driver-select-dropdown">
                                <InputLabel id="driver-list-select-label">Select a Driver</InputLabel>
                                <Select
                                    labelId="driver-list-select-label"
                                    value={selectedDriver}
                                    onChange={handleDriverSelect}
                                >
                                    {dsprDrivers.map(transferrableDrivers => (
                                        <MenuItem
                                            button
                                            key={transferrableDrivers.id}
                                            value={transferrableDrivers.id.toString()}>
                                            <span>{transferrableDrivers.onCall ? <OncallIcon htmlColor="orange" /> : null}</span>
                                            {transferrableDrivers.user.firstName} {transferrableDrivers.user.lastName} - {transferrableDrivers.user.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </section>}

                        {!submitting && <p className="modify-error">{modifyErrorMessage}</p>}
                    </Fragment> : <p>There was an error: {getDSPRDriverResponse}</p>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleFormClose()}>Cancel</Button>
                    <Button color="primary" variant="contained" disabled={submitting} onClick={() => handleAssignDriverToOrder()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    )
}

export default AssignDriverToOrderForm;