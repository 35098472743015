import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { history } from '../index';
import { bindActionCreators } from 'redux';

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { TableRow, TableCell, Select, MenuItem, Button, Grid, CircularProgress } from '@material-ui/core';
import { Card, CardContent } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { People, Info, Description, GroupAdd } from '@material-ui/icons';
import StatCard from "./StatCard"
import { formatPhone, parseDate } from '../util/util';

import {
    getAllUsers,
    getAllUsersWithUnverifiedDocuments,
    getUsersBySearch,
    userListCSVDownloadLink,
    downloadUnverifiedUsersList,
    downloadCSVUserListExpiredMedicalID
} from '../actions/userActions';
import { getAllUserMetrics } from "../actions/metricsActions"
import { getUsers, getUnverifiedUsers, getSearchUsers } from '../selectors/userSelectors';
import { getUserMetricsFromProps } from "../selectors/metricsSelectors";

import UserSearchForm from '../containers/SearchForm';
import { User, UnverifiedUser, UsersMetrics } from '../store/reduxStoreState';

import './UsersManagementStyles.scss';
import VirtualizedTable from './VirtualizedTable';
import { canceledFetchErrorMessage } from "../middleware/api";

interface UsersManagementProps {
    searchUsers: { [key: number]: User }
    unverifiedUsers: { [key: number]: UnverifiedUser }
    users: { [key: number]: User }
    usersMetrics: UsersMetrics
    getUsersBySearch: (searchQuery: string) => any,
    getAllUsersWithUnverifiedDocuments: () => any,
    getAllUsers: (signal?: any) => any,
    getAllUserMetrics: (newUsersSinceDate: Date) => any;
}

interface State {
    filterType: string,
    loadedSystemUsers: { [key: number]: User },
    listedUsers: { [key: number]: User } | { [key: number]: UnverifiedUser },
    listedUsersKeys: string[],
    loadingUsers: boolean,
    loadingUnverifiedUsers: boolean,
    loading: boolean,
    weeklySignups: number,
    startDate: any,
    endDate: any,
    unverifiedUsersKeys: string[],
    creatingCSV: boolean,
    pageRef: any,
    pageHeight: number,
    tableHeight: number
}

class UsersManagement extends Component<UsersManagementProps, State> {

    FILTER_TYPE = {
        TO_BE_VERIFIED: 'TO_BE_VERIFIED',
        SEARCH: 'SEARCH',
        DOWNLOAD_CSV: 'DOWNLOAD_CSV',
        EXPIRED_MEDICAL_ID: 'EXPIRED_MEDICAL_ID',
        ALL: 'ALL'
    };

    abortController = new AbortController();

    constructor(props: UsersManagementProps) {
        super(props);
        this.state = {
            filterType: this.FILTER_TYPE.SEARCH,
            loadedSystemUsers: null,
            listedUsers: {},
            listedUsersKeys: [],
            loadingUsers: false,
            weeklySignups: undefined,
            loadingUnverifiedUsers: true,
            loading: false,
            startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
            endDate: new Date(),
            unverifiedUsersKeys: [],
            creatingCSV: false,
            pageRef: createRef(),
            pageHeight: window.innerHeight,
            tableHeight: undefined
        };
    }

    handleUserSearch = (values) => {
        this.setState({ loading: true });
        this.props.getUsersBySearch(values.searchQuery)
            .then(() => { this.setState({ loading: false, listedUsers: this.props.searchUsers, listedUsersKeys: Object.keys(this.props.searchUsers).reverse() }) });
    };

    handleGenerateCSV = () => {
        this.setState({creatingCSV: true})
        setTimeout(()=> {
            this.setState({creatingCSV: false})
        },5000 )
    }

    handleGenerateExpiredMedicalCardCSV = () => {
        this.setState({creatingCSV: true})
        setTimeout(()=> {
            this.setState({creatingCSV: false})
        },60000 )
    }

    componentDidMount() {
        //this.grabUserInfo() //Grabbing all users on load
        const tableHeightCalculation = (this.state.pageHeight - (this.state.pageRef.current.clientHeight + 180))
        this.setState({tableHeight:  tableHeightCalculation < 400 ? 400 : tableHeightCalculation})
        this.props.getAllUsersWithUnverifiedDocuments().then((unverifiedUsersKeysInOrder) => this.setState({ loadingUnverifiedUsers: false, unverifiedUsersKeys: unverifiedUsersKeysInOrder }))
        const current:Date = new Date();
        const weekAgo = new Date(current.setDate(current.getDate()-7));
        this.props.getAllUserMetrics(weekAgo)
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    handleFilterChange = (event) => {
        switch (event.target.value) {
            case this.FILTER_TYPE.TO_BE_VERIFIED:
                this.setState({ loading: false, filterType: event.target.value, listedUsers: this.props.unverifiedUsers, listedUsersKeys: this.state.unverifiedUsersKeys});
                break;
            case this.FILTER_TYPE.SEARCH:
                this.setState({ filterType: event.target.value, listedUsers: this.props.searchUsers, listedUsersKeys: Object.keys(this.props.searchUsers).reverse() });
                break;
            case this.FILTER_TYPE.DOWNLOAD_CSV:
                this.setState({ filterType: event.target.value, listedUsers: [], listedUsersKeys: [], loading: false });
                break;
            case this.FILTER_TYPE.ALL:
                this.setState({ loading: true, filterType: event.target.value })
                this.props.getAllUsers(this.abortController.signal).then((response) => {
                    // if fetch request is canceled, the component has unmounted (and we do not want to set state).
                    if (!(response.error === canceledFetchErrorMessage)) {
                        this.setState({ loading: false, filterType: event.target.value, listedUsers: this.props.users, listedUsersKeys: Object.keys(this.props.users).reverse() });
                    }
                })
                break;
            case this.FILTER_TYPE.EXPIRED_MEDICAL_ID:
                this.setState({ filterType: event.target.value, listedUsers: [], listedUsersKeys: [], loading: false });
                break;
            default:
                return;
        }
    };

    handleUserTap = (user) => () => {
        history.push(`/user/${user.id}`);
    };


    // Thomas: the function below is not used anywhere
    renderUser = (user) => {
        const signupDate = parseDate(user.createdTimestamp);
        const birthDate = user.birthDate ? parseDate(user.birthDate) : null;
        const birthDateString = birthDate ? `${birthDate.toLocaleString("en-us", { month: "long" })} ${birthDate.getDate()}, ${birthDate.getFullYear()}` : null;

        return <TableRow key={user.id} onClick={this.handleUserTap(user)}>
            <TableCell>{signupDate.toLocaleString("en-us", { month: "long" })} {signupDate.getDate()}, {signupDate.getFullYear()}</TableCell>
            <TableCell>{user.firstName} {user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{formatPhone(user.phoneNumber)}</TableCell>
            <TableCell>{birthDateString}</TableCell>
            <TableCell>{user.signupZipCode}</TableCell>
        </TableRow>
    };

    generateUserRow = (user: User | UnverifiedUser, style: any) => {
        const signupDate = parseDate(user.createdTimestamp);
        const birthDate = user.birthDate ? parseDate(user.birthDate) : null;
        const birthDateString = birthDate ? `${birthDate.toLocaleString("en-us", {month: "long"})} ${birthDate.getDate()}, ${birthDate.getFullYear()}` : null;

        return <div
            style={style}
            className='user-row'
            key={user.id}
            onClick={this.handleUserTap(user)}
        >
            <span>{signupDate.toLocaleString("en-us", {month: "long"})} {signupDate.getDate()}, {signupDate.getFullYear()}</span>
            <span>{user.firstName} {user.lastName}</span>
            <span>{user.email}</span>
            <span>{formatPhone(user.phoneNumber)}</span>
            <span>{birthDateString}</span>
            <span>{user.signupZipCode}</span>
        </div>
    }

    userRows = ({ index, style }, listedUsers, listedUsersKeys) => {
        if (!listedUsers || (listedUsers && Object.keys(listedUsers).length === 0)) return null;
        return this.generateUserRow(listedUsers[listedUsersKeys[index]], style)
    }

    render() {
        const { listedUsers, loadingUsers, loadingUnverifiedUsers, listedUsersKeys } = this.state;

        return (
            <main className='users-management'>
                <div id="restOfPageContainer" ref={this.state.pageRef}>
                    <h2>System Users</h2>
                    <h4>Metrics</h4>
                    <section className='user-metrics'>
                        <Grid container spacing={3}>
                            <StatCard
                                mainText="Total Users"
                                mainIcon={<People />}
                                mainValue={this.props.usersMetrics ? this.props.usersMetrics.totalUsers : "Loading"}
                                color='info'
                                grid
                                footerIcon={<Info />}
                                footerText='Total Number of Users' />
                            <StatCard
                                mainText="New Users"
                                mainIcon={<GroupAdd />}
                                mainValue={this.props.usersMetrics ? this.props.usersMetrics.newUsersSinceTimestamp : "Loading"}
                                color='success'
                                grid
                                footerIcon={<Info />}
                                footerText='New Signups in the past week' />
                            <StatCard
                                mainText="Unverified Users"
                                mainIcon={<Description />}
                                mainValue={loadingUnverifiedUsers ? "loading" : Object.keys(this.props.unverifiedUsers).length}
                                color='warning'
                                grid
                                footerIcon={<Info />}
                                footerText='Users with Unverified Docs' />
                        </Grid>
                    </section>
                    <h4>Management</h4>
                </div>
                
                <Card className="card">
                    <CardContent>
                        <div>
                            <Select value={this.state.filterType} onChange={this.handleFilterChange} disabled={loadingUsers}>
                                <MenuItem value={this.FILTER_TYPE.SEARCH}>Search</MenuItem>
                                <MenuItem value={this.FILTER_TYPE.DOWNLOAD_CSV}>User List CSV</MenuItem>
                                <MenuItem value={this.FILTER_TYPE.TO_BE_VERIFIED}>Docs To Be Verified</MenuItem>
                                <MenuItem value={this.FILTER_TYPE.EXPIRED_MEDICAL_ID}>Expired Medical IDs CSV</MenuItem>
                                <MenuItem value={this.FILTER_TYPE.ALL}>All</MenuItem>
                            </Select>
                            {loadingUsers && <CircularProgress />}
                        </div>
                        {this.state.filterType === this.FILTER_TYPE.SEARCH &&
                            <UserSearchForm onSubmit={this.handleUserSearch} /> //TODO: Add back 'loader' 
                        }
                        {this.state.loading && <CircularProgress />}
                        {this.state.filterType === this.FILTER_TYPE.DOWNLOAD_CSV &&
                            <div className='date-form'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    {/* KNOWN BUG: selecting a date from the popout may cause timezone issues. No issues with manually entered date */}
                                    <div className='date-range'>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="start-date"
                                            label="Start Date"
                                            className="start-date"
                                            maxDate={this.state.endDate || null}
                                            value={this.state.startDate || null}
                                            onChange={(newDate: any) => this.setState({
                                                startDate: (!parseDate(newDate) || !this.state.endDate || newDate < this.state.endDate) ? newDate : null,
                                            })}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change start date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="end-date"
                                            label="End Date"
                                            className="end-date"
                                            minDate={this.state.startDate || null}
                                            value={this.state.endDate || null}
                                            onChange={(newDate: any) => this.setState({
                                                endDate: (!parseDate(newDate) || !this.state.startDate || newDate > this.state.startDate) ? newDate : null,
                                            })}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end date',
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>

                                {this.state.startDate && this.state.endDate && parseDate(this.state.startDate) && parseDate(this.state.endDate) ? (
                                    <Button variant="contained"
                                            color="primary"
                                            onClick={()=> this.handleGenerateCSV()}
                                            disabled={this.state.creatingCSV}
                                            href={userListCSVDownloadLink(this.state.startDate.toISOString(), this.state.endDate.toISOString())}>
                                        Download CSV
                                    </Button>
                                ) : <Button variant="contained" color="primary" href="" disabled>Download CSV</Button>}
                            </div>
                        }
                        {!this.state.loading && this.state.filterType === this.FILTER_TYPE.TO_BE_VERIFIED &&
                            <div className={'download-csv-button-container'} style={{marginBottom: 16}}>
                                <Button variant='contained'
                                        color='primary'
                                        disabled={this.state.creatingCSV}
                                        onClick={()=> this.handleGenerateCSV()}
                                        href={downloadUnverifiedUsersList()}>
                                    Download CSV
                                </Button>
                            </div>}
                        {!this.state.loading && this.state.filterType === this.FILTER_TYPE.EXPIRED_MEDICAL_ID &&
                            <div className={'download-csv-button-container'}>
                                <Button variant={'contained'}
                                        color={'primary'}
                                        disabled={this.state.creatingCSV}
                                        onClick={() => this.handleGenerateExpiredMedicalCardCSV()}
                                        href={downloadCSVUserListExpiredMedicalID()}
                                >
                                    Download CSV
                                </Button>
                            </div>
                        }
                        {!this.state.loading && this.state.filterType !== this.FILTER_TYPE.DOWNLOAD_CSV && this.state.filterType !== this.FILTER_TYPE.EXPIRED_MEDICAL_ID && <VirtualizedTable
                            tableClasses="users-table"
                            itemCount={listedUsers ? Object.keys(listedUsers).length : 0}
                            header={[
                                'Signup Date',
                                'Name',
                                'Email',
                                'Phone',
                                'Birth Date',
                                'Signup Zip Code'
                            ]}
                            maxHeight={this.state.tableHeight ? this.state.tableHeight: 400}
                            renderItems={(props) => this.userRows(props, listedUsers, listedUsersKeys)}
                        />}
                    </CardContent>
                </Card>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: getUsers(state),
        unverifiedUsers: getUnverifiedUsers(state),
        searchUsers: getSearchUsers(state),
        usersMetrics: getUserMetricsFromProps(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllUsers, getAllUsersWithUnverifiedDocuments, getUsersBySearch, getAllUserMetrics },
        dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement);