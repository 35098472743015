import React from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, DialogActions } from '@material-ui/core';

import './NewUserNoteFormStyles.scss'

const validate = values => {
    const errors: any = {};
    if (!values.note) {
        errors.note = 'Required';
    }
    return errors;
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error }, ...custom }) => (
    <TextField label={label} type={type} {...input} {...custom} error={!!(touched && error)} helperText={touched && error ? error : ''} />
);

interface NewUserNoteFormProps {
    closeDialog: () => void;
}

const NewUserNoteForm = (props: NewUserNoteFormProps & InjectedFormProps<{}, NewUserNoteFormProps>) => {
    const {
        closeDialog,
        handleSubmit,
        // submitting
    } = props;
    return (
        <form onSubmit={handleSubmit} className='user-note-form'>
            <Field name="note" component={renderField} multiline rows={3} variant='outlined' label="Note" className='field'/>
            <DialogActions>
                <Button variant="contained" color="primary" className="raised-button" onClick={handleSubmit}>Create Note</Button>
                <Button color="secondary" className="raised-button" onClick={closeDialog}>Cancel</Button>
            </DialogActions>
        </form>
    )
};

export default reduxForm<any, NewUserNoteFormProps>({
    form: 'NewUserNoteForm', // a unique identifier for this form
    validate
})(NewUserNoteForm)
