import { createSelector } from 'reselect';
import { getDSPRFromProps } from './dsprSelectors';
import { getUsers } from './userSelectors';
import { State } from '../store/reduxStoreState';

export const getDSPRCoupons = (state: State) => state.api.entities.coupons;

export const getCouponsForDSPR = createSelector(
    [getDSPRFromProps, getDSPRCoupons, getUsers], (dspr, coupons, users) => {
        return dspr ?
            coupons ?
                users ?
                    Object.keys(coupons).map(key => {
                        const coupon = coupons[key];
                        const user = coupon.specificallyAllowedUsers.length === 1 ?
                            users[coupon.specificallyAllowedUsers[0]] : undefined;
                        return { ...coupon, user }
                    }).filter(item => item.dspr === dspr.id)
                    : []
                : []
            : undefined;
    }
);


export const getCouponLocksForDSPRFromProps = (state: State, dsprId) => state.api.entities.dsprCouponLockTypes[dsprId];