import React from 'react';
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import { Button, Typography } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { history } from '../index';
import login from "../assets/img/login.jpeg";

import './UnauthorizedAccessPageStyles.scss';


const UnauthorizedAccessPage = () => {

    /**Route user to previous page in history, or highest level page they are authorized to access*/
    const routeUser = () => {
        if (history.length > 2) {
            history.go(-2);
        } else {
            history.push('/');
        }
    }

    return (
        <div className={'Unauthorized-Access-Page'} style={{backgroundImage: `url(${login})`}}>

            <Card className={'unauthorized-card'}>
                <CardHeader className={'unauthorized-card-header'}>
                    <img src='/assets/images/grassp_logistics.png' alt='Grassp Logistics'/>
                </CardHeader>
                <CardBody className={'card-body'}>
                    <Typography component={'h1'}
                                variant={'h5'}
                                align={'center'}
                                className={'card-title'}
                    >
                        You are not authorized to view this page
                    </Typography>

                    <Typography className={'card-message'}>
                        If you believe this is a mistake, please contact tech support.
                    </Typography>

                    <Button variant={'outlined'} color={'primary'} onClick={routeUser} className={'back-button'}>
                        <ArrowBackIcon/>
                        <Typography className={'button-text'}>Back to Grassp</Typography>
                    </Button>
                </CardBody>
            </Card>

        </div>
    )
}

export default UnauthorizedAccessPage;