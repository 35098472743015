import React, { Fragment, useState, useEffect, useRef } from 'react';

import {
    Button, Card, CardContent,
    Dialog, DialogContent, DialogTitle, DialogActions,
    FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, TextField, 
    ButtonBase, CircularProgress, Chip, Checkbox, Typography
} from '@material-ui/core';
import { PhoneIphone as OncallIcon, Clear as DeleteIcon } from '@material-ui/icons';

import { Order, Address, User, DsprDriver, DsprDriverInventoryItem, DspProduct, State } from '../store/reduxStoreState';

import { parseDate } from '../util/util';

import AutoComplete from '../components/Autocomplete';
import Incrementer from '../components/Incrementer';

import './ModifyOrderStyles.scss';
import { useSelector } from 'react-redux';
import { allowDriverKitsAtDSPRFromProps } from '../selectors/dsprSelectors';

type OrderWithAddressAndUser = Omit<Omit<Order, 'address'>, 'user'> & { address: Address, user: User };
type AvailableInventoryItem = Omit<DsprDriverInventoryItem, 'product'> & { product: DspProduct };

interface ModifyOrderFormProps {
    dsprDrivers: (Omit<DsprDriver, 'user'> & { user: User })[];
    disableButton?: boolean;
    currentOrder?: OrderWithAddressAndUser;
    modifyOrder: (orderId: number, orderDetails: any, newDriverId: number,transferInventoryOnDriverchange:boolean, couponCodes?: string[]) => Promise<string>;
    availableInventory: AvailableInventoryItem[];
    calculateOrderTotal: (order: any) => Promise<string | Order>;
    getProductWithId: (productId: number) => Promise<string | DspProduct>;
    getDSPRDriver: (driverId: number) => Promise<any>;
}

const ModifyOrderForm: React.FC<ModifyOrderFormProps> = props => {
    const { currentOrder, disableButton, dsprDrivers, modifyOrder, availableInventory, calculateOrderTotal, getProductWithId, getDSPRDriver } = props;
    //const currentDriver = dsprDrivers.find(driver => driver.id === currentOrder.dsprDriver);
    const [currentDriver, setCurrentDriver] = useState(dsprDrivers.find(driver => driver.id === currentOrder.dsprDriver))
    const [currentDriverId, setCurrentDriverId] = useState((currentDriver && currentDriver.id) || currentOrder.dsprDriver)
    const [transferInventoryOnDriverchange, setTransferInventoryOnDriverChange] = useState<boolean>(false);

    const mounted = useRef(true);

    const [showModifyOrderForm, setShowModifyOrderForm] = useState(false);
    const [getDSPRDriverResponse, setgetDSPRDriverResponse] = useState(undefined)
    const [selectedDriver, setSelectedDriver] = useState(currentDriver ? currentDriver.id.toString() : '');
    const [orderDetails, setOrderDetails] = useState<any[]>(currentOrder.orderDetails);
    const [submitting, setSubmitting] = useState(false);
    const [modifyErrorMessage, setModifyErrorMessage] = useState(null);

    const driverKits = useSelector<State, boolean>(state => currentDriver ? allowDriverKitsAtDSPRFromProps(state, {dsprId: currentDriver.dspr}): null);

    const [newItem, setNewItem] = useState<AvailableInventoryItem>(null);
    const [newItemQuantity, setNewItemQuantity] = useState(0);
    const [newItemQuantityAvailable, setNewItemQuantityAvailable] = useState(0);
    const [newFlowerItemUnit, setNewFlowerItemUnit] = useState('eighth');
    
    const [couponToBeAdded, setCouponToBeAdded] = useState("");

    const [couponCodes, setCouponCodes] = useState((currentOrder && currentOrder.coupons && currentOrder.coupons.map(coupon => coupon.code)) || []);

    const [orderTotal, setOrderTotal] = useState('$' + currentOrder.cashTotal.toString());

    const handleDriverSelect = (event: React.ChangeEvent<{ name?: string, value: any }>) => {
        setSelectedDriver(event.target.value);
    }

    const handleTransferInventoryCheckbox = (event) => {
        // setTransferInventoryOnDriverChange(event.target.value);
        setTransferInventoryOnDriverChange(!transferInventoryOnDriverchange);
    }

    const handleModifyOrder = () => {
        setSubmitting(true);
        modifyOrder(currentOrder.id, orderDetails, parseInt(selectedDriver),transferInventoryOnDriverchange, couponCodes)
            .then(errMsg => {
                if (mounted.current) {
                    setSubmitting(false);
                    if (errMsg) {
                        setModifyErrorMessage(errMsg);
                    } else {
                        setShowModifyOrderForm(false);
                    }
                }
            });
    }

    const OrderSummary = () => {
        const currentOrderItemCount = currentOrder && currentOrder.orderDetails && currentOrder.orderDetails.length;
        return (
            <Card>
                <CardContent>
                    <h4>Customer: <b>{currentOrder.user.firstName} {currentOrder.user.lastName}</b></h4>
                    <p>Current Driver: <b>{currentDriver.user.firstName} {currentDriver.user.lastName}</b></p>
                    <p>Created at {parseDate(currentOrder.createdTime).toLocaleString()}</p>
                    {currentOrderItemCount && <b>Total: ${currentOrder.cashTotal}, {currentOrderItemCount} item{currentOrderItemCount > 1 && 's'}</b>}
                </CardContent>
            </Card>
        )
    }

    const handleRemoveItem = (index: number) => {
        setOrderTotal('Please calculate');
        setOrderDetails([
            ...orderDetails.slice(0, index),
            ...orderDetails.slice(index + 1)
        ]);
    }

    const handleUpdateItemQuantity = (index: number, newQuantity: number) => {
        setOrderTotal('Please calculate');
        setOrderDetails([
            ...orderDetails.slice(0, index),
            { ...orderDetails[index], quantity: newQuantity },
            ...orderDetails.slice(index + 1),
        ]);
    }

    const handleUpdateFlowerItemUnit = (index: number, newUnit: string) => {
        setOrderTotal('Please calculate');
        setOrderDetails([
            ...orderDetails.slice(0, index),
            { ...orderDetails[index], unit: newUnit },
            ...orderDetails.slice(index + 1),
        ]);
    }

    const handleNewItemSelect = (newItemId: number) => {
        const newItem = availableInventory.find(item => item.product.id === newItemId);
        setNewItem(newItem);
        setNewItemQuantityAvailable(newItem.quantityAvailable);
    }

    const handleAddNewItem = () => {
        const addedItemProductId = newItem.product.id;
        if (orderDetails.find(item => item.product.id === addedItemProductId)) {
            setModifyErrorMessage('This product is in the original order. Please adjust its quantity instead of adding it as a new product.');
            return;
        }

        getProductWithId(addedItemProductId)
            .then(product => {
                if (typeof product === 'string') {
                    setModifyErrorMessage(product);
                } else {
                    const addedItem = {
                        product: {
                            ...product,
                            deliveryServiceProvider: { id: product.deliveryServiceProvider },
                            productCategories: product.productCategories.map(categoryId => ({ id: categoryId })),
                        },
                        quantity: newItemQuantity,
                    }
                    if (newItem.product.isFlower) addedItem['unit'] = newFlowerItemUnit;

                    setOrderTotal('Please calculate');
                    setOrderDetails([
                        ...orderDetails,
                        addedItem,
                    ]);
                    resetNewItem();
                }
            });
    }

    const resetNewItem = () => {
        setModifyErrorMessage(null);
        setNewItemQuantity(0);
        setNewItem(null);
        setNewFlowerItemUnit('eighth');
    }

    const recalculateOrderTotal = (couponList?: string[]) => {
        const payload = {
            orderDetails,
            dsprDriver: { id: currentOrder.dsprDriver },
            modifiedOrder: {id: currentOrder.id}
        }
        if (couponList) payload['couponCodes'] = couponList;
        else if (couponCodes) payload['couponCodes'] = couponCodes;

        calculateOrderTotal(payload)
            .then(calculatedOrder => {
                if (typeof calculatedOrder === 'string') {
                    setModifyErrorMessage(calculatedOrder);
                } else {
                    setOrderTotal('$' + calculatedOrder.cashTotal.toString());
                }
            })
    }

    const removeCouponFromOrder = (couponCode) => {
        let newCouponList;
        const codeIndex = couponCodes.indexOf(couponCode)
        if(codeIndex !== -1) {
            newCouponList = couponCodes.slice(0, codeIndex).concat(couponCodes.slice(codeIndex + 1))
            const payload = {
                orderDetails,
                dsprDriver: { id: currentOrder.dsprDriver },
                modifiedOrder: {id: currentOrder.id}
            }
            if (newCouponList) payload['couponCodes'] = newCouponList;
            calculateOrderTotal(payload)
            .then(calculatedOrder => {
                if (typeof calculatedOrder === 'string') {
                    setModifyErrorMessage(calculatedOrder);
                } else {
                    setCouponCodes(newCouponList);
                    setOrderTotal('$' + calculatedOrder.cashTotal.toString());
                }
            })
        }    
    }

    const addCouponToOrder = () => {
        if(couponToBeAdded !== "") {
            if (couponCodes.includes(couponToBeAdded)) {
                setModifyErrorMessage(`The coupon code '${couponToBeAdded}' has already been applied to this order.`)
            }
            const newCouponList = [...couponCodes]
            newCouponList.push(couponToBeAdded);
            const payload = {
                orderDetails,
                dsprDriver: { id: currentOrder.dsprDriver },
                modifiedOrder: {id: currentOrder.id}
            }
            if (newCouponList) payload['couponCodes'] = newCouponList;

            calculateOrderTotal(payload)
            .then(calculatedOrder => {
                if (typeof calculatedOrder === 'string') {
                    setModifyErrorMessage(calculatedOrder);
                } else {
                    setCouponCodes(calculatedOrder.coupons.map(coupon => coupon.code));
                    setOrderTotal('$' + calculatedOrder.cashTotal.toString());
                }
                setCouponToBeAdded("")
            })
        }
    }

    const handleFormClose = () => {
        resetNewItem();
        setShowModifyOrderForm(false);
    }

    useEffect(() => () => mounted.current = false, []);

    useEffect(()=> {
        if (showModifyOrderForm && currentDriverId) {
            getDSPRDriver(currentDriverId).then((response)=> {
                if (response) setgetDSPRDriverResponse(response)
            })
        }
        // eslint-disable-next-line
    }, [showModifyOrderForm, currentDriverId])

    useEffect(()=> {
        if(currentOrder) {
            setCurrentDriver(dsprDrivers.find(driver => driver.id === currentOrder.dsprDriver))
        }
    }, [currentOrder, dsprDrivers])

    useEffect(()=> {
        if(currentDriver) {
            if(currentDriver.id !== currentDriverId) {
                setCurrentDriverId(currentDriver.id)
                setSelectedDriver(currentDriver.id.toString())
                
            } 
            if(!selectedDriver) {
                setSelectedDriver(currentDriver.id.toString())
            }
        }
    }, [currentDriver, currentDriverId, selectedDriver])
    return (
        <Fragment>
            <Button variant="contained" color="primary" disabled={disableButton} onClick={() => setShowModifyOrderForm(true)}>
                Modify Order
            </Button>
            <Dialog
                open={showModifyOrderForm}
                onClose={() => handleFormClose()}
                className="modify-order-form"
            >
                <DialogTitle>Modify Order</DialogTitle>
                <DialogContent>
                    {getDSPRDriverResponse === undefined ? <CircularProgress/> : getDSPRDriverResponse.type ?
                    <Fragment>
                        <p>If the driver needs to be changed, successful modification of this order
                            requires the new driver's inventory to be sufficient for the current order.</p>
                        {currentOrder && <OrderSummary />}
                        {dsprDrivers && <section>
                            {/* as an example for assigning a new driver to an order*/}
                            <FormControl className="driver-select-dropdown">
                                <InputLabel id="driver-list-select-label">Select a Driver</InputLabel>
                                <Select
                                    labelId="driver-list-select-label"
                                    value={selectedDriver}
                                    onChange={handleDriverSelect}
                                >
                                    {dsprDrivers.map(transferrableDrivers => (
                                        <MenuItem
                                            button
                                            key={transferrableDrivers.id}
                                            value={transferrableDrivers.id.toString()}>
                                            <span>{transferrableDrivers.onCall ? <OncallIcon htmlColor="orange" /> : null}</span>
                                            {transferrableDrivers.user.firstName} {transferrableDrivers.user.lastName} - {transferrableDrivers.user.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className="transfer-driver-inventory-checkbox">
                                <Checkbox disabled={currentDriverId === parseInt(selectedDriver) || !driverKits} onChange={handleTransferInventoryCheckbox} value={transferInventoryOnDriverchange}></Checkbox><Typography variant={'body1'}>Transfer Inventory To Cover Order To New Driver</Typography>
                            </FormControl>
                        </section>}
                        <section className="new-order-details">
                            <List>
                                {orderDetails && orderDetails.map((item, index) => {
                                    const unit = item && item.unit ? (item.unit === 'oz' ? '' : item.unit) + ' oz' : ' x ';

                                    return <ListItem key={`${item.product.id}-${(item.unit || '0')}`}>
                                        <ListItemText primary={item.product.name} secondary={<span>{item.quantity} {unit}</span>} />
                                        <Incrementer value={item.quantity} updateValue={quantity => handleUpdateItemQuantity(index, quantity)} />
                                        {item.product.isFlower && <Select value={item.unit} onChange={e => handleUpdateFlowerItemUnit(index, e.target.value as string)}>
                                            <MenuItem value="eighth">Eighth</MenuItem>
                                            <MenuItem value="quarter">Quarter</MenuItem>
                                            <MenuItem value="half">Half</MenuItem>
                                            <MenuItem value="oz">Oz</MenuItem>
                                        </Select>}
                                        <ButtonBase onClick={() => handleRemoveItem(index)}>
                                            <DeleteIcon className="delete-product-icon" />
                                        </ButtonBase>
                                    </ListItem>
                                })}
                            </List>
                            <FormControl className="new-item-form-control">
                                <AutoComplete
                                    error=""
                                    touched={false}
                                    label="Choose a Product to Add"
                                    input={null}
                                    options={availableInventory.map(item => ({ value: item.product.id, text: `${item.product.name}: ${item.quantityAvailable}` }))}
                                    handleAutoCompleteSelected={handleNewItemSelect}
                                    value={(newItem && { value: newItem.product.id, text: `${newItem.product.name}: ${newItem.quantityAvailable}` })}
                                />
                                <Incrementer value={newItemQuantity} updateValue={setNewItemQuantity} max={newItemQuantityAvailable} />
                                {newItem && newItem.product && newItem.product.isFlower && (
                                    <Select value={newFlowerItemUnit} onChange={e => setNewFlowerItemUnit(e.target.value as string)}>
                                        <MenuItem value="eighth">Eighth</MenuItem>
                                        <MenuItem value="quarter">Quarter</MenuItem>
                                        <MenuItem value="half">Half</MenuItem>
                                        <MenuItem value="oz">Oz</MenuItem>
                                    </Select>
                                )}
                                <Button color="primary" className="reset-new-item-button" disabled={newItemQuantity === 0} onClick={() => resetNewItem()}>Reset</Button>
                                <Button color="primary" variant="contained" disabled={newItemQuantity === 0} onClick={() => handleAddNewItem()}>Add Product</Button>
                            </FormControl>
                        </section>
                        <section className="bottom-padding">
                            <p>{couponCodes && couponCodes.length > 0 ? (couponCodes.length > 1 ? "Coupon Codes:": "Coupon Code:") : "No Coupons Applied"}</p>
                            {couponCodes && <div>
                                {couponCodes.map(coupon => <Chip label={coupon} key={coupon + "-key"} onDelete={() => removeCouponFromOrder(coupon)}></Chip>)}
                            </div>}
                        </section>
                        <section className="add-coupon-section">
                            <TextField label="Coupon code" value={couponToBeAdded} onChange={e => setCouponToBeAdded(e.target.value)} />
                            <Button color="primary" variant="contained" onClick={() => addCouponToOrder()}>Add Coupon</Button>
                        </section>
                        <Card className="order-total">
                            <CardContent>
                                <p>New Order Total: <b>{orderTotal}</b></p>
                                <Button color="primary" variant="contained" onClick={() => recalculateOrderTotal()}>Calculate</Button>
                            </CardContent>
                        </Card>
                        {!submitting && <p className="modify-error">{modifyErrorMessage}</p>}
                    </Fragment> : <p>There was an error: {getDSPRDriverResponse}</p>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleFormClose()}>Cancel</Button>
                    <Button color="primary" variant="contained" disabled={submitting} onClick={() => handleModifyOrder()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    )
}

export default ModifyOrderForm;