import React, { Fragment, useState } from 'react';
import { List, ListItem, Button, Dialog, DialogTitle, DialogContent, Card, CardActions } from '@material-ui/core';
import DSPRForm from '../containers/DSPRForm';
import { User } from '../store/reduxStoreState';
import DSPRManagerForm from '../containers/DSPRManagerForm';

import './DSPRManagersStyles.scss';

interface DSPRManagersProps {
    dsprManagers: { user: User, active: boolean, id: number }[];
    loggedInUserIsDSPManager: boolean;
    interceptAutocompleteSelected: (id: number) => any;
    users: { value: number, text: string }[];
    handleSubmitManagerForm: (newManagerValues: any) => any;
    getAllUsersCallback?: () => any;
    usersLoaded?: boolean;
}

const DSPRManagers: React.FC<DSPRManagersProps> = props => {
    const { dsprManagers, loggedInUserIsDSPManager, users, interceptAutocompleteSelected, handleSubmitManagerForm, getAllUsersCallback, usersLoaded } = props;
    const [isNewManagerFormOpen, setIsNewManagerFormOpen] = useState(false);
    const [isManagerTogglerFormOpen, setIsManagerTogglerFormOpen] = useState(false);
    const [managerId, setManagerId] = useState<number>(null);

    const handleNewManagerFormOpen = () => {
        getAllUsersCallback();
        setIsNewManagerFormOpen(true);
    };

    const handleNewManagerFormClose = () => {
        setIsNewManagerFormOpen(false);
    };

    const handleManagerTogglerFormOpen = (id: number) => {
        setManagerId(id);
        setIsManagerTogglerFormOpen(true);
    }

    const handleManagerTogglerFormClose = () => {
        setManagerId(null);
        setIsManagerTogglerFormOpen(false);
    }

    const handleSubmitNewManagerForm = (newManagerValues) => {
        handleSubmitManagerForm(newManagerValues);
        setIsNewManagerFormOpen(false);
    };

    return <Card className="managers">
        <List>
            {dsprManagers &&
                dsprManagers.map(manager => {
                    const activeClass = manager.active ? "active" : "inactive";
                    return <ListItem
                        key={manager.id}
                        className={activeClass}
                        onClick={() => handleManagerTogglerFormOpen(manager.id)}
                        button
                    >
                        {manager.user.firstName} {manager.user.lastName} - {manager.user.email}
                    </ListItem>
                })}
        </List>
        {loggedInUserIsDSPManager &&
            <Fragment>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        className="raised-button"
                        onClick={handleNewManagerFormOpen}>
                        Add Manager
                        </Button>
                </CardActions>
                <Dialog
                    open={isNewManagerFormOpen}
                    onClose={handleNewManagerFormClose}
                >
                    <DialogTitle>New Manager</DialogTitle>
                    <DSPRForm
                        addManagerMode={true}
                        users={users}
                        onSubmit={handleSubmitNewManagerForm}
                        onCancel={handleNewManagerFormClose}
                        handleAutoCompleteSelected={interceptAutocompleteSelected}
                        usersLoaded={usersLoaded}
                    />
                </Dialog>
                <Dialog
                    className="dspr-manager-toggler-form"
                    open={isManagerTogglerFormOpen}
                    onClose={handleManagerTogglerFormClose}
                >
                    <DialogContent>
                        <DSPRManagerForm dsprManagerId={managerId} />
                    </DialogContent>
                </Dialog>
            </Fragment>}
    </Card>
}

export default DSPRManagers;
