import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllProductCategoriesForDSPRWithOrder, GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS, updateAllProductCategoryOrderForDSPR } from '../actions/dspProductActions';
import { getDSPR, GET_DSPR_SUCCESS, getActiveDsprPromotionsForProductCategories, createDsprPromotionForProductCategory, hideActiveDsprPromotionForProductCategory } from "../actions/dsprActions";

import DSPRMenuManagement from '../components/DSPRMenuManagement'
import { getDSPRFromProps } from '../selectors/dsprSelectors';
import { getActiveProductCategoriesForDSPFromComponentPropsWithProductCategoryPromotions } from "../selectors/component/dsprMenuContainerSelectors"
import { State, DSPR, DspProductCategory, DsprProductCategoryPromotion } from '../store/reduxStoreState';

export const SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE = "SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE";
export const UPDATE_DSPR_PRODUCT_CATEGORY_ORDER = "UPDATE_DSPR_PRODUCT_CATEGORY_ORDER";
export const CANCEL_DSPR_PRODUCT_CATEGORY_ORDER_UPDATE = "CANCEL_DSPR_PRODUCT_CATEGORY_ORDER_UPDATE";

const DSPRMenuMangementContainer: React.FC<{}> = props => {

    const { dsprId } = useParams<{dsprId: string}>();
    const dspr = useSelector<State, DSPR>(state => getDSPRFromProps(state, {dsprId}), shallowEqual);
    const categories = useSelector<State, Array<DspProductCategory & { currentPromotion: DsprProductCategoryPromotion}>>(state => getActiveProductCategoriesForDSPFromComponentPropsWithProductCategoryPromotions(state, { dspId: dspr && dspr.deliveryServiceProvider, dsprId: dspr && dspr.id }), shallowEqual);
    const dispatch = useDispatch();

    const [initialLoadingDone, setInitialLoadingDone] = useState(false)

    const handleProductCategoryPromotionSubmission = (promotionalText: string, productCategoryId: number, imageFile:any) => {
        dispatch(createDsprPromotionForProductCategory( dspr.id, productCategoryId, promotionalText, imageFile ))
    }

    const changeMenuOrder = (startIndex:number, endIndex:number) => {
        const newCategories:Array<DspProductCategory & { currentPromotion: DsprProductCategoryPromotion}> = Array.from(categories);
        const [removed] = newCategories.splice(startIndex, 1);
        newCategories.splice(endIndex, 0, removed);

        const categoriesMap = {};

        newCategories.forEach((category, index) => {
            delete category.currentPromotion;
            category.order = index
            categoriesMap[category.id] = category
        })

        dispatch({
            type: UPDATE_DSPR_PRODUCT_CATEGORY_ORDER,
            entities: { dspProductCategories: categoriesMap }
        })

        dispatch(updateAllProductCategoryOrderForDSPR(dspr.id, newCategories))
    }

    useEffect(()=> {
        dispatch(getActiveDsprPromotionsForProductCategories(parseInt(dsprId)))
        
        if (!(dspr && dspr.deliveryServiceProvider)){
            getDSPR(dsprId)(dispatch).then(response => {
                if(response.type === GET_DSPR_SUCCESS) {
                    getAllProductCategoriesForDSPRWithOrder(response.response.entities.DSPRs[dsprId].id)(dispatch).then((response)=> {
                        if(response.type === GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS){
                            dispatch({
                                type: SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE,
                                entities: response.response.entities
                            })
                            setInitialLoadingDone(true)
                        }
                    })
                }
            })
        } else {
            getAllProductCategoriesForDSPRWithOrder(parseInt(dsprId))(dispatch).then((response)=> {
                if(response.type === GET_DSPR_PRODUCT_CATEGORIES_WITH_ORDER_SUCCESS){
                    dispatch({
                        type: SET_DSPR_PRODUCT_CATEGORIES_IN_COMPONENT_STATE,
                        entities: response.response.entities
                    })
                    setInitialLoadingDone(true)
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        initialLoadingDone && <DSPRMenuManagement categories={categories} 
                            handleProductCategoryPromotionSubmission={handleProductCategoryPromotionSubmission}
                            changeCategoryOrder={changeMenuOrder}
                            handleProductCategoryPromotionHide = {(productCategoryId) => dispatch(hideActiveDsprPromotionForProductCategory(productCategoryId))}/>
    )
}

export default DSPRMenuMangementContainer;