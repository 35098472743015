import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import { Card, CardHeader, CardMedia, CardContent, Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';

import { getProduct, createOrUpdateProduct } from '../actions/dspProductActions';
import { getDSP } from '../actions/dspActions';

import { getDSPProductFromProps, getProductCategoriesForDSP } from '../selectors/dspProductSelector';

import DSPProductForm from './DSPProductForm';
import { API_HOST } from '../middleware/api';
import { DspProduct, DspProductCategory } from '../store/reduxStoreState';
import { RouteComponentProps } from 'react-router-dom';

interface DSPProductProps {
    dspProduct: DspProduct,
    categories: DspProductCategory[],
    getDSP: (dspId: string | number) => any,
    getProduct: (id: string | number) => any,
    createOrUpdateProduct: (dspId: string | number,
        name: string,
        brandId: string,
        isActive: boolean,
        flowerType: string,
        productType: string,
        isFlower: boolean,
        isExcludedFromCoupons: boolean,
        categories: any,
        imageFile: any,
        description: string,
        thcPercentage: string,
        cbdPercentage: string,
        cbnPercentage: string,
        thcMg: string,
        cbdMg: string,
        isCannabisProduct,
        id: any) => any,
}

interface State {
    editFormExpanded: boolean
}

class DSPProduct extends Component<DSPProductProps & RouteComponentProps, State> {

    constructor(props) {
        super(props);
        this.state = {
            editFormExpanded: false,
        };
    }

    handleEditProductFormOpen = () => {
        this.setState({ editFormExpanded: true })
    };

    handleEditProductFormClose = () => {
        this.setState({ editFormExpanded: false })
    };


    // TODO: Do we need a way to store permissions in the state?
    isLoggedInUserThisDSPManager() {
        return true;
    }

    handleDSPProductSubmit = (newProductValues) => {
        let categories = [];
        Object.keys(newProductValues).forEach(key => {
            if (key.startsWith("category"))
                if (newProductValues[key]) categories.push(key.substr(8))
        });
        if(newProductValues.isCannabisProduct === false) {
            newProductValues.isFlower = null;
            newProductValues.thcPercentage = null;
            newProductValues.cbdPercentage = null;
            newProductValues.cbnPercentage = null;
            newProductValues.thcMg = null;
            newProductValues.cbdMg = null;
        }

        const file = newProductValues.imageFile ? newProductValues.imageFile[0] : null;
        return this.props.createOrUpdateProduct(this.props.dspProduct.deliveryServiceProvider,
            newProductValues.name,
            newProductValues.brandId,
            newProductValues.isActive === "yes",
            newProductValues.flowerType,
            newProductValues.productType,
            newProductValues.isFlower === "yes",
            newProductValues.isExcludedFromCoupons === "yes",
            categories,
            file,
            newProductValues.description,
            newProductValues.thcPercentage,
            newProductValues.cbdPercentage,
            newProductValues.cbnPercentage,
            newProductValues.thcMg,
            newProductValues.cbdMg,
            newProductValues.isCannabisProduct !== undefined ? newProductValues.isCannabisProduct : null,
            this.props.dspProduct.id).then(response => {
                if (response.error) {
                    throw new SubmissionError({ name: response.error, _error: response.error });
                } else {
                    this.handleEditProductFormClose();
                }
            })
        // TODO: Need up update the image on edit.
    };

    componentDidMount() {
        const { productId } = this.props.match.params as { productId: string };
        this.props.getProduct(productId)
            .then(() => this.props.getDSP(this.props.dspProduct.deliveryServiceProvider));
    }

    render() {
        const { dspProduct } = this.props;
        let stats = "";
        if (dspProduct) {
            stats = `ProductType: ${dspProduct.productType}, FlowerType: ${dspProduct.flowerType}`;
            if (dspProduct.thcPercentage) stats += `, THC: ${dspProduct.thcPercentage}%`;
            if (dspProduct.cbdPercentage) stats += `, CBD: ${dspProduct.cbdPercentage}%`;
            if (dspProduct.cbnPercentage) stats += `, CBN: ${dspProduct.cbnPercentage}%`;
            if (dspProduct.thcMg) stats += `, THC: ${dspProduct.thcMg}mg`;
            if (dspProduct.cbdMg) stats += `, CBD: ${dspProduct.cbdMg}mg`;
        }

        return dspProduct ?
            (
                <div>
                    <Card>
                        <CardHeader title={dspProduct.name} />
                        <CardContent>
                            <div>{stats}</div>
                            <Button color='primary' onClick={this.handleEditProductFormOpen}>Edit</Button>
                        </CardContent>
                        <CardMedia>
                            <img alt={`${dspProduct.name} :${dspProduct.description}`}
                                src={`${API_HOST}${dspProduct.imageLocation}`} />
                        </CardMedia>
                    </Card>
                    {this.isLoggedInUserThisDSPManager() &&
                        <Dialog
                            title="Edit Product"
                            fullScreen={false}
                            open={this.state.editFormExpanded}
                            onClose={this.handleEditProductFormClose}
                        >
                            <DialogTitle>Edit Product</DialogTitle>
                            <DialogContent>
                                <DSPProductForm editMode={true}
                                    dspId={this.props.dspProduct.deliveryServiceProvider}
                                    onSubmit={this.handleDSPProductSubmit}
                                    onCancel={this.handleEditProductFormClose}
                                    product={dspProduct}
                                    categories={this.props.categories}
                                />
                            </DialogContent>
                            
                        </Dialog>
                    }
                </div>
            ) :
            null;
    }

}

const mapStateToProps = (state, ownProps) => {
    const { productId } = ownProps.match.params;
    const dspProduct = getDSPProductFromProps(state, { productId });
    const categories = dspProduct ? getProductCategoriesForDSP(state, { dspId: dspProduct.deliveryServiceProvider }) : null;
    return {
        dspProduct,
        categories
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProduct,
        createOrUpdateProduct,
        getDSP
    },
        dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DSPProduct);