import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { history } from '../index'
import { getLoggedInUser } from '../selectors/userSelectors';
import { DeliveryServiceProvider, DSPR, DsprDriver, DsprManager, State, User } from '../store/reduxStoreState';
import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import { makeStyles } from '@material-ui/core/styles';
import {
    Dashboard,
    Assessment,
    History,
    WhereToVote,
    SupervisorAccount,
    Domain,
    DirectionsCar,
    DataUsage,
    FormatListNumbered,
    Explore
} from '@material-ui/icons';

import DSPRManagersContainer from '../containers/DSPRManagersContainer';
import DSPRZipCodesContainer from '../containers/DSPRZipCodesContainer';
import DSPRInventoryContainer from '../containers/DSPRInventoryContainer';
import DSPROrderHistoryContainer from '../containers/DSPROrderHistoryContainer';
import DSPRCouponsContainer from '../containers/DSPRCouponsContainer';
import DSPRDriversContainer from '../containers/DSPRDriversContainer';
import DSPRMenuManagementContainer from '../containers/DSPRMenuManagementContainer';
import DSPRServiceAreaContainer from '../containers/DSPRServiceAreaContainer';
import DriverPage from '../containers/DriverPage';
import DSPRPage from '../containers/DSPRPage';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbars/Navbar';
import AnalyticsManager from "./AnalyticsManager";
import { navLinks, getDSPsWithStructureForHeaderMenuLinks, } from '../selectors/headerSelectors';
import {
    getActiveDSPRManagersForLoggedInUser,
    getManagedDSPRsForLoggedInUser
} from "../selectors/dsprManagerSelectors";
import { getDSPRDrivers } from "../selectors/dsprSelectors";
import SidebarUtil from "../util/SidebarUtil";
import { getManagedDSPsForLoggedInUser } from "../selectors/dspManagerSelectors";
import DSPRProductInventoryTransactionHistory from '../components/DSPRProductInventoryTransactionHistory';
import InvoiceContainer from "../components/Invoice/InvoiceContainer";
import DSPRComplianceNumberOrderTransactionHistory from '../components/DSPRComplianceNumberOrderTransactionHistory';

const useStyles = makeStyles(styles as any);

const DSPRManager: React.FC<RouteComponentProps> = props => {
    const loggedInUser = useSelector<State, User>(getLoggedInUser);
    const activeDSPRManagers = useSelector<State, DsprManager[]>(getActiveDSPRManagersForLoggedInUser, shallowEqual);
    const dsprDrivers = useSelector<State, { [key: number]: DsprDriver }>(getDSPRDrivers);
    const {match} = props;
    const navSelector = useSelector<State, navLinks>(getDSPsWithStructureForHeaderMenuLinks, shallowEqual);
    const userManagedDSPs = useSelector<State, DeliveryServiceProvider[]>(getManagedDSPsForLoggedInUser, shallowEqual);
    const userManagedDSPRs = useSelector<State, DSPR[]>(getManagedDSPRsForLoggedInUser, shallowEqual);

    const [navOpen, setNavOpen] = useState(false);

    const dsprId = match.params['dsprId'];
    const driverId = match.params['dsprDriverId'];
    const classes = useStyles({});
    const isAdmin: boolean = loggedInUser && loggedInUser.systemAdministrator;
    const isDsprManager: boolean = !!(loggedInUser && loggedInUser.dsprManagers.length);
    const isManagerForCurrentDSPR: boolean = isDsprManager && activeDSPRManagers && activeDSPRManagers.filter(dsprManager => dsprManager.dspr === parseInt(dsprId)).length > 0
    const isDriver: boolean = !!(loggedInUser && loggedInUser.dsprDrivers.length);
    const isDriverForCurrentDSPR: boolean = isDriver && dsprDrivers && loggedInUser.dsprDrivers.filter(driverId => dsprDrivers[driverId] && dsprDrivers[driverId].dspr === parseInt(dsprId)).length > 0;
    const isDriverIdInDSPR: boolean = (driverId && dsprDrivers && dsprId && dsprDrivers[driverId] && dsprDrivers[driverId].dspr === parseInt(dsprId));
    const userManagedActiveDSPs: DeliveryServiceProvider[] = userManagedDSPs ? userManagedDSPs.filter(dsp => dsp.active) : [];
    const userManagedActiveDSPRs: DSPR[] = userManagedDSPRs ? userManagedDSPRs.filter(dspr => dspr.active) : [];

    //if user is neither a super user, nor a current manager for the current dspr, nor a driver for the current dspr
    // the user is not authorized to view page and will be routed to the unauthorized page
    if (!isAdmin && !isManagerForCurrentDSPR && (!isDriverForCurrentDSPR)) history.push('/unauthorized');

    useEffect(() => {
        if (!loggedInUser) history.push('/login');
    }, [loggedInUser]);

    const generateDriverRoutes = () => {
        if (navSelector) {
            return SidebarUtil.generateDriverRoutes(navSelector);
        }
    }


    const routes = dsprId ? (isDriver && !isAdmin && !isManagerForCurrentDSPR) ? generateDriverRoutes()
        : [
            {
                path: `/${dsprId}/dashboard/`,
                name: "Dashboard",
                icon: Dashboard,
                component: DSPRPage,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/analytics/`,
                name: "Analytics",
                icon: Assessment,
                component: AnalyticsManager,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/menu/`,
                name: "Menu",
                icon: FormatListNumbered,
                component: DSPRMenuManagementContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/orders/`,
                name: "Orders",
                icon: History,
                component: DSPROrderHistoryContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/coupons/`,
                name: "Coupons",
                icon: DataUsage,
                component: DSPRCouponsContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/inventory/`,
                name: "Inventory",
                icon: Domain,
                component: DSPRInventoryContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/drivers/`,
                name: "Drivers",
                icon: DirectionsCar,
                component: DSPRDriversContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/serviceAreas/`,
                name: "Service Areas",
                icon: Explore,
                component: DSPRServiceAreaContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/zipCodes/`,
                name: "Zip Codes",
                icon: WhereToVote,
                component: DSPRZipCodesContainer,
                layout: "/dspr"
            },
            {
                path: `/${dsprId}/managers/`,
                name: "Managers",
                icon: SupervisorAccount,
                component: DSPRManagersContainer,
                layout: "/dspr"
            },
        ] : [];

    /**Driver Profiles Sidebar Entry (added if user is manager and driver) */
    if (!driverId && dsprId && isDriver && (isAdmin || isDsprManager)) {
        const driverRoutes = generateDriverRoutes().map(driverRoute => {
            delete driverRoute.icon;
            return ({
                ...driverRoute,
                mini: '-'
            })
        });

        routes.push(SidebarUtil.createDriverProfiles(driverRoutes));
    }

    /**Manager Profiles Sidebar entry*/
    if (loggedInUser && navSelector && ((userManagedActiveDSPs.length + userManagedActiveDSPRs.length) > 1)) {
        routes.push(SidebarUtil.createManagerProfiles((navSelector)));
    }

    const handleNavLinkClick = () => {
        setNavOpen(false);
    }

    const handleDrawerToggle = () => {
        setNavOpen(!navOpen);
    }

    const getRenderRoutes = () => routes.map((route, index) => {
        if (!route.path) return undefined;
        //Redirect if driverId in url is not associated with DSPR in url
        if (route.path.includes(`/${dsprId}/drivers/`) && driverId && !isDriverIdInDSPR && !isManagerForCurrentDSPR && !isAdmin) history.push('/');

        const path = route.layout + route.path.replace(/(\/\d+\/)([A-z]+)/, (match, g1, g2) => `/:dsprId/${g2}`);
        return <Route key={index} path={path} component={route.component}/>
    });

    const renderRedirects = () => {
        if (isDriver && !isAdmin && !isDsprManager && dsprDrivers) {
            const driverId = loggedInUser.dsprDrivers[0]
            const dsprId = dsprDrivers[driverId].dspr
            return <Redirect to={`/dspr/${dsprId}/drivers/${driverId}`}/>
        }

        if (isManagerForCurrentDSPR || isAdmin) {
            return <Redirect to={`/dspr/${dsprId}/dashboard/`}/>
        }

        return <Redirect to={'/'}/>
    }

    /**Only render driver route if driverId is associated with DSPR in url params AND
     * user is specified driver, or DSPR Manager, or Admin */
    const renderDriverRoute = () => {
        if ((isDriverForCurrentDSPR || isManagerForCurrentDSPR || isAdmin) && isDriverIdInDSPR) {
            return <Route path={`/dspr/:dsprId/drivers/:dsprDriverId`} component={DriverPage}/>
        }
    }

    return (
        <Fragment>
            <Navbar
                brandText=""
                handleDrawerToggle={() => handleDrawerToggle()}
            />
            <Sidebar
                open={navOpen}
                logo="/assets/images/grasspLogo.svg"
                logoText={'Grassp Logistics'}
                routes={routes}
                handleDrawerToggle={handleNavLinkClick}
                color='blue'
                bgColor='black'
                isDriver={isDriver}
            >
            </Sidebar>
            <div className={classes.mainPanel}>
                {dsprId && <Switch>
                    <Route exact path={`/dspr/:dsprId/inventory/product/:productId/transaction-history`} component={DSPRProductInventoryTransactionHistory} />
                    <Route exact path={`/dspr/:dsprId/inventory/compliance/:complianceNumber/:productId/order-history`} component={DSPRComplianceNumberOrderTransactionHistory} />
                    <Route exact path={`/dspr/:dsprId/inventory/invoice/batch/transaction-history`} component={InvoiceContainer} />
                    {getRenderRoutes()}
                    {renderDriverRoute()}   
                    {/*{(isDriverForCurrentDSPR || isManagerForCurrentDSPR || isAdmin) && isDriverIdInDSPR && <Route path={`/dspr/:dsprId/drivers/:dsprDriverId`} component={DriverPage} />}*/}
                    {renderRedirects()}
                    {/*<Redirect to={(isDriver && !isAdmin && !isDsprManager) ? `/dspr/${dsprId}/drivers/${loggedInUser.dsprDrivers[0]}`: `/dspr/${dsprId}/dashboard/`} />*/}
                </Switch>}
            </div>
        </Fragment>
    )
}
export default DSPRManager;